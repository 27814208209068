import { ApolloError } from "@apollo/client";
import { Alert, AlertIcon, AlertProps, Flex } from "@chakra-ui/react";
import React from "react";
import { FieldError } from "react-hook-form";
import { IconType } from "react-icons";
import { MdWarning } from "react-icons/md";

type ErrorProps = {
  error?: ApolloError | Error | FieldError | string | false | null;
  icon?: IconType;
  errorId?: string | null; // a ZeetError returned by Anchor may have an errorId which can be used to uniquely identify an instance of an error
};

export const ZAlert: React.FC<ErrorProps & AlertProps> = ({
  error,
  children,
  status = "info",
  icon,
  errorId,
  ...props
}) => {
  let message: string | undefined | null = null;

  if (!error) {
    message = null;
  } else if (typeof error === "string") {
    message = error;
  } else if (error instanceof ApolloError) {
    message = error?.message;
  } else {
    message = error?.message;
  }

  return message || children ? (
    <Alert borderRadius="md" shadow="sm" status={status} {...props}>
      <Flex alignItems="center">
        <AlertIcon as={icon} />
        {message}
      </Flex>
      {errorId && (
        <span style={{ fontFamily: "courier" }}>
          &nbsp;(Error ID: {errorId})
        </span>
      )}
      {children}
    </Alert>
  ) : null;
};

export const ZWarn: React.FC<ErrorProps & AlertProps> = (props) => {
  return <ZAlert status="warning" icon={MdWarning} {...props} />;
};

export const ZError: React.FC<ErrorProps & AlertProps> = (props) => {
  return <ZAlert status="error" {...props} />;
};
