import { Text } from "@chakra-ui/react";
import { DetailItem, DetailLabel } from "@zeet/web-ui";
import { useCallback } from "react";
import ReactTimeago from "react-timeago";

interface DurationDetailItemProps {
  startDate: Date;
  endDate?: Date | null;
  hasEnded?: boolean;
}

export const DurationDetailItem = ({
  startDate,
  endDate,
  hasEnded,
}: DurationDetailItemProps) => {
  const getDate = (d: string | Date) =>
    typeof d === "string" ? new Date(d) : d;
  const getNow = useCallback(
    () => (hasEnded && endDate ? getDate(endDate).getTime() : Date.now()),
    [hasEnded, endDate]
  );

  return (
    <DetailItem>
      <DetailLabel>Total duration</DetailLabel>
      <Text>
        <ReactTimeago
          date={startDate}
          now={getNow}
          formatter={(value, unit) =>
            `${value} ${unit}${value === 1 ? "" : "s"}`
          }
        />
      </Text>
    </DetailItem>
  );
};
