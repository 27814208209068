import { Box, Heading } from "@chakra-ui/react";
import {
  DeploymentStatus,
  useProjectDeployRunDetailQuery,
} from "@zeet/web-api/dist/graphqlv1";
import {
  CenterLoading,
  DetailSidebar,
  SidebarLayout,
  useCurrentTeamUser,
} from "@zeet/web-ui";
import { useMemo } from "react";
import { SearchableListLayout } from "~/components/SearchableList";
import { StatusDetailItem } from "../../components/StatusDetailItem";
import { WorkflowTriggerDetailItem } from "../../components/WorkflowTriggerDetailItem";
import { deploymentStatusMap } from "../../utils";
import { columns, defaultColumnIds, formatDataRow } from "./utils";

const DeploymentDetail = ({ deployRunId }: { deployRunId: string }) => {
  const currentUser = useCurrentTeamUser();
  const { data, loading } = useProjectDeployRunDetailQuery({
    variables: {
      teamId: currentUser?.id,
      deployRunId,
    },
  });

  const deployRun = data?.team?.deployRun;

  const dataToRender = useMemo(() => {
    return formatDataRow(deployRun?.result?.resources.nodes);
  }, [deployRun]);

  if (loading) {
    return <CenterLoading />;
  }

  const status = DeploymentStatus.Unknown;

  return (
    <SidebarLayout>
      <Box>
        <Heading size="md">Deployment #{deployRun?.sequence}</Heading>
        <Box mt="8">
          <Heading size="md">Resources</Heading>
          <SearchableListLayout
            id="deployment-resources"
            data={dataToRender}
            columns={columns}
            defaultColumnIds={defaultColumnIds}
          />
        </Box>
      </Box>
      <DetailSidebar>
        <StatusDetailItem statusInfo={deploymentStatusMap[status]} />
        <WorkflowTriggerDetailItem parentRun={{ id: "?", sequence: 1 }} />
      </DetailSidebar>
    </SidebarLayout>
  );
};

export default DeploymentDetail;
