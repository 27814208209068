export const config = {
  ENV: process.env.NODE_ENV,
  CAPTAIN_URL: process.env.REACT_APP_CAPTAIN_URL || "https://zeet.co",
  ANCHOR_URL: process.env.REACT_APP_ANCHOR_URL || "https://anchor.zeet.co",
  WS_ANCHOR_URL: process.env.REACT_APP_WS_ANCHOR_URL || "wss://anchor.zeet.co",
  KRAKEN_URL: process.env.REACT_APP_KRAKEN_URL || "https://kraken.zeet.co",
  STRIPE_KEY:
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ||
    "pk_live_0VgULRxpLuZmkuEGEGlrCpdd00ZO00azft",
  BRAND_COLOR: process.env.REACT_APP_BRAND_COLOR || "#35A642",
  // used to identify the current zeet deployment environment (staging, zorp, production, etc.)
  ZEET_ENVIRONMENT: process.env.REACT_APP_ZEET_ENVIRONMENT,

  isDev: process.env.NODE_ENV === "development",
  gettingStartedGuideURL: "https://blog.zeet.co",
  docsURL: "https://docs.zeet.co",
  discordURL: "https://discord.gg/F6hWtCV",
  twitterUrl: "https://twitter.com/zeet_co",
  POSTHOG_API_KEY:
    process.env.REACT_APP_POSTHOG_API_KEY ||
    "phc_s6pDKHIpWjJdmxYJIW72e4VJhwzW0nbbdVA8wFBhtJC",
  POSTHOG_API_HOST:
    process.env.REACT_APP_POSTHOG_API_HOST ||
    "https://staging-posthog.staging.zeetco.app",
  GOOGLE_OAUTH_CLIENT_ID:
    process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID ||
    "523360233057-tatmos7tacu08io1a71citg0afi4nudk.apps.googleusercontent.com",
  UPLOADCARE_API_KEY:
    process.env.REACT_APP_UPLOADCARE_API_KEY || "0ccd675f0cfe0668421f",
};
