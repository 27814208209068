import {
  UserBillingOverviewQuery,
  UserDetailFragment,
  UserProductCatalogQuery,
} from "@zeet/web-api/dist/graphql";
import { ZProductsType } from "./Providers/utils";

interface IBillingOnClick {
  onOpen: () => void;
  userBillingOverview: UserBillingOverviewQuery | undefined;
  onClickFunction: () => void;
  usageUnit: string;
}

export type Usage = NonNullable<
  NonNullable<
    NonNullable<
      UserBillingOverviewQuery["user"]["billingOverview"]
    >["currentProducts"]
  >[0]["usage"]
>[0];

export const maybeOpenBillingModal = (input: IBillingOnClick) => {
  // make sure userBillingOverview is loaded
  if (input.userBillingOverview) {
    if (
      input.userBillingOverview?.user.billingOverview?.subscriptionUsageAllowed[
        input.usageUnit
      ]
    ) {
      return input.onClickFunction();
    } else {
      return input.onOpen();
    }
  }
};

export const getHeaderText = (
  userProductCatalogQuery: UserProductCatalogQuery | undefined,
  productSectionName: string
) => {
  const section = userProductCatalogQuery?.productCatalog.products.find(
    (p) => p.sectionName === productSectionName
  );

  const allProductsIndex =
    section?.allProducts.findIndex((ap) => ap.status === "CURRENT") || 0;

  return `Upgrade to ${
    section?.allProducts[
      Math.min(allProductsIndex + 1, section.allProducts.length - 1)
    ]?.product.name
  }`;
};

export const billingModalText = {
  projects: {
    title: "Add additional projects for your team",
    body: "Ready to deploy? Add projects to your account so your team can continue building.",
  },
  clouds: {
    title: "Unlock more Cloud Accounts for your team",
    body: "Ready to scale up? Upgrade to unleash the power of multi-cloud!",
  },
  blueprints: {
    title: "Unlock more Custom Blueprints for your team",
    body: "Custom Blueprints make deploying compliant infrastructure and applications easier. Upgrade to give your team access to create more Custom Blueprints!",
  },
  users: {
    title: "Unlock unlimited team members",
    body: "Ready to scale your team? Collaborate with your team like never before by upgrading to a Pro plan with unlimited team members!",
  },
  clusters: {
    title: "Unlock more Clusters for your team",
    body: "Ready to scale up? Upgrade to unleash the power of multi-cluster workloads!",
  },
  autoscaling: {
    title: "Unlock Advanced Autoscaling",
    body: "Scaling up? Manage application autoscaling like never before by upgrading to a Pro plan with Advanced Autoscaling through Prometheus and KEDA!",
  },
  staticEgress: {
    title: "Unlock Static Egress",
    body: "Need dedicated egress IPs? Upgrade to a Pro plan with static egress IP.",
  },
};

export const getCurrentUsage = (
  usage: Usage
): number | string | null | undefined => {
  if (usage.unit === "active project") {
    if (!usage.maximumUsage) {
      return usage.currentUsage || 0;
    }
  }

  if (!usage.maximumUsage) {
    if (usage.currentUsage === 0) {
      return `0 of unlimited`;
    }
    return `${usage.currentUsage} of unlimited`;
  }

  return `${usage.currentUsage} of ${usage.maximumUsage}`;
};

export const getCostUnit = (
  usage: Usage,
  allProduct: ZProductsType
): string => {
  return `${
    usage.maximumUsage
      ? usage.maximumUsage
      : allProduct.product.productTypeMetadata?.productType === "PROJECT_PACK"
      ? "Pay as you go"
      : "Unlimited"
  } ${
    !usage.maximumUsage
      ? ""
      : usage.maximumUsage && usage.maximumUsage > 1
      ? usage.unitPlural
      : usage.unit
  }`;
};

export const getUsagePercentage = (usage: Usage) => {
  if (!usage.maximumUsage && usage.currentUsage !== 0) {
    return 70;
  }
  if (usage.maximumUsage && usage.currentUsage) {
    return Math.min((usage.currentUsage / usage.maximumUsage) * 100, 100);
  }
  return 0;
};

export enum ProductTypeByUnitPlural {
  PROJECTS = "active projects",
}

export enum BillingModalSource {
  PROJECTS = "projects",
  CLOUDS = "clouds",
  CLUSTERS = "clusters",
  MEMBERS = "members",
  BLUEPRINTS = "blueprints",
  AUTOSCALING = "autoscaling",
  STATIC_EGRESS = "staticEgress",
}
export const getButtonLink = (
  isOwnerOrAdmin: boolean | undefined,
  userBillingOverview: UserBillingOverviewQuery | undefined,
  currentTeamUser: UserDetailFragment | undefined
): string => {
  return isOwnerOrAdmin
    ? `${userBillingOverview?.user.billingOverview?.externalLinks?.managePaymentMethod.url}/payment-methods`
    : `/${currentTeamUser?.login}/account/billing/overview`;
};
