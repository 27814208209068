import { useToast } from "@chakra-ui/react";
import { useEffect, useRef } from "react";
import { SettingsUnsavedChanges } from "~/components/Layouts/Settings";

export const useUnsavedChangesToast = ({
  show,
  loading,
  onSave,
}: {
  show: boolean;
  loading: boolean;
  onSave(): unknown;
}) => {
  const toast = useToast();
  const saveCallbackRef = useRef(onSave);

  useEffect(() => {
    saveCallbackRef.current = onSave;
  }, [onSave]);

  useEffect(() => {
    const toastId = "unsaved-project-settings";

    if (show) {
      // note that "loading" won't work at the moment since it's not a ref and calling
      // toast twice just hides the toast :/
      toast({
        id: toastId,
        title: "You have unsaved changes",
        duration: null,
        render: () => (
          <SettingsUnsavedChanges
            isSubmitting={loading}
            onSave={() => saveCallbackRef.current()}
          />
        ),
      });
    } else {
      toast.close(toastId);
    }

    return () => toast.close(toastId);
  }, [show, toast, loading]);
};
