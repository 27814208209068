import { Text } from "@chakra-ui/react";
import { FC } from "react";

interface MaxWidthTextCellProps {
  text: string;
}

export const MaxWidthTextCell: FC<MaxWidthTextCellProps> = ({ text }) => (
  <Text
    maxWidth="250px"
    textOverflow="ellipsis"
    overflow="hidden"
    whiteSpace="nowrap"
    paddingRight="6px"
  >
    {text}
  </Text>
);
