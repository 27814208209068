import { Box } from "@chakra-ui/react";
import {
  useWorkflowRunDetailLogsQuery,
  WorkflowRunStepDetailFragment,
  WorkflowRunStepStatus,
} from "@zeet/web-api/dist/graphqlv1";
import { DetailSidebar, SidebarLayout, useCurrentTeamUser } from "@zeet/web-ui";
import { PropsWithChildren } from "react";
import { LogView } from "~/components/LogView";
import { BuildArtifactsDetailItem } from "../../components/BuildArtifactDetailItem";
import { DurationDetailItem } from "../../components/DurationDetailItem";
import { StatusDetailItem } from "../../components/StatusDetailItem";
import { workflowRunStepStatusMap } from "../../utils";
import { workflowActionTitleMap } from "../utils";

interface GenericStepRunDetailProps extends PropsWithChildren {
  step: WorkflowRunStepDetailFragment;
  runId: string;
  projectId: string;
}

export const GenericStepRunDetail = ({
  step,
  projectId,
  runId,
  children,
}: GenericStepRunDetailProps) => {
  const currentTeamUser = useCurrentTeamUser();
  const statusInfo = workflowRunStepStatusMap[step.status];
  const stepCompleted = step.status !== WorkflowRunStepStatus.Running;
  return (
    <SidebarLayout pr="0">
      <Box>
        {(step.__typename === "JobRunStep" ||
          step.__typename === "BuildRunStep") && (
          <LogView
            title={`${workflowActionTitleMap[step.action]} Logs`}
            query={useWorkflowRunDetailLogsQuery}
            variables={{
              teamId: currentTeamUser.id,
              projectId,
              runId,
              stepId: step.id,
            }}
            logsResolver={(r) => {
              if (
                r.team?.project?.workflow?.run?.step.__typename ===
                  "JobRunStep" ||
                r.team?.project?.workflow?.run?.step.__typename ===
                  "BuildRunStep"
              ) {
                return r.team?.project?.workflow?.run?.step?.logs;
              }
            }}
          />
        )}
      </Box>
      <DetailSidebar>
        <StatusDetailItem statusInfo={statusInfo} />
        <DurationDetailItem
          startDate={step.createdAt}
          endDate={step.updatedAt}
          hasEnded={stepCompleted}
        />
        {step.__typename === "BuildRunStep" && step?.outputs && (
          <BuildArtifactsDetailItem
            artifacts={step.outputs}
            runCompleted={stepCompleted}
          />
        )}
        {children}
      </DetailSidebar>
    </SidebarLayout>
  );
};
