import {
  DeploymentStatus,
  RepoDetailFragment,
  useUserRepoBranchesV2Query,
} from "@zeet/web-api/dist/graphql";
import { Link, ZWarn } from "@zeet/web-ui";
import { DeploymentDetail } from ".";
import { repoPath } from "../util";

interface DeploymentBannersProps {
  repo: RepoDetailFragment;
  deployment?: DeploymentDetail | null;
}

export const DeploymentBanners = ({
  repo,
  deployment,
}: DeploymentBannersProps) => {
  const { data } = useUserRepoBranchesV2Query({
    variables: {
      id: repo.id,
      branch: deployment?.branch,
      withBranch: !!deployment?.branch,
      first: 10, // grab the first ~10, we're just checking for blocking deploys.
      after: "0",
    },
    pollInterval: 3000,
    errorPolicy: "all",
  });

  const blockingDeployment =
    deployment &&
    data?.currentUser.repo?.branch?.deployments?.nodes.find(
      (d) =>
        Date.parse(d.createdAt as unknown as string) <
          Date.parse(deployment.createdAt as unknown as string) &&
        (d.status === DeploymentStatus.BuildPending ||
          d.status === DeploymentStatus.BuildInProgress ||
          d.status === DeploymentStatus.DeployInProgress ||
          d.status === DeploymentStatus.DeployPending ||
          d.status === DeploymentStatus.ReleaseInProgress)
    );

  const isDeploymentBlocked =
    blockingDeployment && deployment.status !== DeploymentStatus.BuildAborted;

  const isNotLatestDeployment =
    repo.productionBranch === deployment?.branch &&
    repo.productionDeployment?.id !== deployment?.id;

  return (
    <>
      {isDeploymentBlocked && (
        <ZWarn mt="4">
          Another deployment is blocking this one from executing.{" "}
          <Link
            to={`/${repoPath(repo)}/deployments/${blockingDeployment?.id}`}
            textDecoration="underline"
          >
            View blocking deployment
          </Link>
        </ZWarn>
      )}

      {isNotLatestDeployment && (
        <ZWarn mt="4">
          This is not the latest deployment.{" "}
          <Link
            to={`/${repoPath(repo)}/deployments/${
              repo?.productionDeployment?.id
            }`}
            textDecoration="underline"
          >
            View latest deployment
          </Link>
        </ZWarn>
      )}
    </>
  );
};
