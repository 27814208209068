import {
  ChevronDownIcon,
  ChevronRightIcon,
  ExternalLinkIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Button,
  Collapse,
  Flex,
  Heading,
  Link,
  Spacer,
  Stack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  DeploymentDetailFragment,
  DeploymentStatus,
  DeployTarget,
  DeployType,
  RepoSourceType,
  useBuildTemplatesExistQuery,
  useDeployTemplatesExistQuery,
  useGenerateDownloadableLogLinkMutation,
  UserRepoQuery,
} from "@zeet/web-api/dist/graphql";
import {
  Card,
  SearchButton,
  Tooltip,
  Link as ZLink,
  ZWarn,
} from "@zeet/web-ui";
import React, { useMemo, useState } from "react";
import { MdDownload } from "react-icons/md";
import { isSampleProject } from "~/features/Project/New/makeCreateSampleProjectInput";
import { isGit, isKube } from "../../utils/project";
import { DeploymentLogs } from "../Logs";
import { BuildMetrics } from "../Metrics/BuildMetrics";
import { getBuildImagesArray } from "../util";
import { DeploymentBuildLogs } from "./Build";
import { BuildArtifact } from "./BuildArtifact";
import { BuildSectionHeader } from "./BuildSectionHeader";
import { DockerfileModal } from "./DockerfileModal";
import { ManifestModal } from "./ManifestModal";
import { DeploymentDeployLogs, DeploymentReleaseLogs } from "./Release";

interface DeploymentLogSectionProps {
  deployment?: DeploymentDetailFragment | null;
  repo?: UserRepoQuery["currentUser"]["repo"];
  isGithubProjectOnboarding?: boolean;
  isLogsTab?: boolean;
}

export const DeploymentLogSection: React.FC<DeploymentLogSectionProps> = ({
  deployment,
  repo,
  isGithubProjectOnboarding,
  isLogsTab,
}) => {
  const realRepo = deployment?.repo || repo;

  const [showArtifacts, setShowArtifacts] = useState(false);
  const buildImages = useMemo(
    () =>
      getBuildImagesArray(
        deployment?.build?.image,
        deployment?.alternativeImages
      ),
    [deployment]
  );

  const [showBuild, setShowBuild] = useState(
    [
      DeploymentStatus.BuildPending,
      DeploymentStatus.BuildInProgress,
      DeploymentStatus.BuildFailed,
    ].includes(deployment?.status as DeploymentStatus) ||
      realRepo?.source.type === RepoSourceType.Helm ||
      isSampleProject(deployment?.repo?.name)
  );
  const [showRelease, setShowRelease] = useState(
    [
      DeploymentStatus.BuildPending, // auto open on transition
      DeploymentStatus.BuildInProgress,
      DeploymentStatus.DeployInProgress,
      DeploymentStatus.DeployFailed,
    ].includes(deployment?.status as DeploymentStatus)
  );

  const [showDeploy, setShowDeploy] = useState(
    [
      DeploymentStatus.BuildPending, // auto open on transition
      DeploymentStatus.BuildInProgress,

      DeploymentStatus.DeployInProgress,
      DeploymentStatus.DeployFailed,
    ].includes(deployment?.status as DeploymentStatus) ||
      realRepo?.deployTarget === DeployTarget.Terraform ||
      realRepo?.source.type === RepoSourceType.Helm ||
      realRepo?.deployType === DeployType.Kubectl
  );

  const {
    onOpen: onOpenBuild,
    onClose: onCloseBuild,
    isOpen: isOpenBuild,
  } = useDisclosure();
  const {
    onOpen: onOpenDeploy,
    onClose: onCloseDeploy,
    isOpen: isOpenDeploy,
  } = useDisclosure();

  const { data: deployData } = useDeployTemplatesExistQuery({
    errorPolicy: "all",
    variables: {
      id: deployment?.id as string,
    },
  });

  const { data: buildData } = useBuildTemplatesExistQuery({
    errorPolicy: "all",
    variables: {
      id: deployment?.id as string,
    },
  });

  const toast = useToast();
  const [
    generateDownloadableLogLink,
    { loading: generateDownloadableLogLinkLoading },
  ] = useGenerateDownloadableLogLinkMutation({
    onError: (err) => {
      if (err) {
        toast({
          title: err.message ?? "Unknown error when downloading logs",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    },
    onCompleted: (data) => {
      if (data) {
        toast({
          title: "Downloading log file",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        const link = document.createElement("a");
        link.href = data.generateDownloadableLogLink;
        document.body.appendChild(link);
        link.click();
      }
    },
  });

  const [isSearchingBuildLogs, setIsSearchingBuildLogs] = useState(false);
  const [isSearchingReleaseLogs, setIsSearchingReleaseLogs] = useState(false);
  const [isSearchingDeployLogs, setIsSearchingDeployLogs] = useState(false);
  const [isSearchingRuntimeLogs, setIsSearchingRuntimeLogs] = useState(false);

  if (!realRepo || !deployment) {
    return null;
  }

  const hasBuildLog =
    (isGit(realRepo.source.type) || !!deployment?.buildStage?.id) &&
    realRepo.deployType !== DeployType.Kubectl;
  const hasReleaseLog = !!deployment?.releaseStage?.id;
  const hasDeployLog = !!deployment?.deployStage?.id;

  const hasRuntimeLog =
    (deployment.deployStatus?.active ||
      deployment.status === DeploymentStatus.DeploySucceeded ||
      deployment.status === DeploymentStatus.DeployInProgress ||
      deployment.status === DeploymentStatus.DeployFailed) &&
    realRepo.source.type !== RepoSourceType.Terraform &&
    realRepo.source.type !== RepoSourceType.Helm &&
    realRepo.deployType !== DeployType.Kubectl;

  return (
    <Flex flexDir="column">
      {hasBuildLog && !isLogsTab && (
        <Card mb={4}>
          <Flex flexDir="column">
            <Flex
              alignItems="center"
              as="button"
              onClick={() => {
                setShowBuild(!showBuild);
                setShowArtifacts(false);
              }}
              p={3}
              minHeight="56px"
            >
              <Flex alignItems="center">
                {showBuild ? (
                  <ChevronDownIcon fontSize="lg" />
                ) : (
                  <ChevronRightIcon fontSize="lg" />
                )}
                <Heading size="sm">Build</Heading>
              </Flex>
              <Spacer />
              {showBuild && (
                <SearchButton
                  searching={isSearchingBuildLogs}
                  setSearching={setIsSearchingBuildLogs}
                />
              )}
              {(buildData?.currentUser.deployment?.buildStage?.buildTemplates
                ?.length ?? 0) > 0 && (
                <Button
                  ml={2}
                  fontWeight="bold"
                  onClick={(e) => {
                    e.stopPropagation();
                    onOpenBuild();
                  }}
                  size="sm"
                >
                  View Dockerfile
                </Button>
              )}
              <DockerfileModal
                isOpen={isOpenBuild}
                onClose={onCloseBuild}
                deployment={deployment}
              />
            </Flex>
          </Flex>
          <Collapse in={showBuild}>
            <Box borderTop="1px solid var(--chakra-colors-chakra-border-color)">
              <DeploymentBuildLogs
                deployment={deployment}
                searching={isSearchingBuildLogs}
                setSearching={setIsSearchingBuildLogs}
              />
              {deployment.build?.image && (
                <BuildMetrics deployment={deployment} />
              )}
            </Box>
            {buildImages.length > 0 && (
              <Box
                pt="2"
                px="3"
                borderTop="1px solid var(--chakra-colors-chakra-border-color)"
              >
                <BuildSectionHeader
                  showDropDown={showArtifacts}
                  setShowDropdown={setShowArtifacts}
                  title="Artifacts"
                />
                <Box my="12px">
                  <Collapse in={showArtifacts}>
                    {showArtifacts &&
                      buildImages.map((bi, i) => (
                        <BuildArtifact
                          buildImage={bi}
                          showDockerImage={true}
                          key={i}
                        />
                      ))}
                  </Collapse>
                </Box>
              </Box>
            )}
          </Collapse>
        </Card>
      )}
      {hasReleaseLog && !isLogsTab && (
        <Card flexDir="column" mb={4}>
          <Flex
            as="button"
            alignItems="center"
            p={3}
            minHeight="56px"
            width="100%"
            onClick={() => setShowRelease(!showRelease)}
          >
            {showRelease ? (
              <ChevronDownIcon fontSize="lg" />
            ) : (
              <ChevronRightIcon fontSize="lg" />
            )}
            <Heading size="sm">Release Logs</Heading>
            <Tooltip text="The commands used to release your project. You can change this in the advanced settings" />
            <Spacer />
            {showRelease && (
              <SearchButton
                searching={isSearchingReleaseLogs}
                setSearching={setIsSearchingReleaseLogs}
              />
            )}
          </Flex>
          <Collapse in={showRelease}>
            <Box borderTop="1px solid var(--chakra-colors-chakra-border-color)">
              {showRelease && (
                <DeploymentReleaseLogs
                  deployment={deployment}
                  searching={isSearchingReleaseLogs}
                  setSearching={setIsSearchingReleaseLogs}
                />
              )}
            </Box>
          </Collapse>
        </Card>
      )}
      {(hasDeployLog || isKube(realRepo)) && !isLogsTab && (
        <Card mb={4}>
          <Flex flexDir="column">
            <Flex
              alignItems="center"
              _hover={{ cursor: hasDeployLog ? "pointer" : "" }}
              onClick={() => {
                if (hasDeployLog) {
                  setShowDeploy(!showDeploy);
                }
              }}
              p={3}
              minHeight="56px"
            >
              <Flex alignItems="center">
                {hasDeployLog &&
                  (showDeploy ? (
                    <ChevronDownIcon fontSize="lg" />
                  ) : (
                    <ChevronRightIcon fontSize="lg" />
                  ))}
                <Heading size="sm">
                  {hasDeployLog ? "Deploy Logs" : "Deploy"}
                </Heading>
              </Flex>
              <Spacer />
              {hasDeployLog && showDeploy && (
                <SearchButton
                  searching={isSearchingDeployLogs}
                  setSearching={setIsSearchingDeployLogs}
                />
              )}
              {(deployData?.currentUser.deployment?.deployTemplates?.length ??
                0) > 0 && (
                <Button
                  ml={2}
                  fontWeight="bold"
                  onClick={(e) => {
                    e.stopPropagation();
                    onOpenDeploy();
                  }}
                  size="sm"
                >
                  View Manifest
                </Button>
              )}
              <ManifestModal
                isOpen={isOpenDeploy}
                onClose={onCloseDeploy}
                deployment={deployment}
              />
            </Flex>
            {hasDeployLog && (
              <Collapse in={showDeploy}>
                <Box borderTop="1px solid var(--chakra-colors-chakra-border-color)">
                  {showDeploy && (
                    <DeploymentDeployLogs
                      deployment={deployment}
                      searching={isSearchingDeployLogs}
                      setSearching={setIsSearchingDeployLogs}
                    />
                  )}
                </Box>
              </Collapse>
            )}
          </Flex>
        </Card>
      )}
      {hasRuntimeLog && !isGithubProjectOnboarding && (
        <>
          <Card flexDir="column">
            <Flex alignItems="center" p={3} pl="4" minHeight="56px">
              <Heading size="sm">Runtime Logs</Heading>
              <Flex ml="auto" gap="2">
                <SearchButton
                  searching={isSearchingRuntimeLogs}
                  setSearching={setIsSearchingRuntimeLogs}
                />
                <Button
                  size="lg"
                  p={1}
                  width={8}
                  minWidth={8}
                  height={8}
                  fontSize={"1em"}
                  variant={"primary"}
                  isLoading={generateDownloadableLogLinkLoading}
                  onClick={() => {
                    generateDownloadableLogLink({
                      errorPolicy: "none",
                      variables: {
                        deploymentID: deployment.id,
                      },
                    });
                  }}
                >
                  <MdDownload />
                </Button>
                {deployment?.awsLinks?.cloudwatchLogs && (
                  <Button
                    size="sm"
                    as={ZLink}
                    _hover={{ textDecoration: "none" }}
                    to={deployment?.awsLinks?.cloudwatchLogs}
                    rightIcon={<ExternalLinkIcon />}
                  >
                    View in AWS CloudWatch
                  </Button>
                )}
                {deployment?.gcpLinks?.cloudLogging && (
                  <Button
                    size="sm"
                    as={ZLink}
                    _hover={{ textDecoration: "none" }}
                    to={deployment?.gcpLinks?.cloudLogging}
                    rightIcon={<ExternalLinkIcon />}
                  >
                    View in GCP Cloud Logging
                  </Button>
                )}
                <Button
                  size="sm"
                  as={Link}
                  _hover={{ textDecoration: "none" }}
                  target="_blank"
                  href={`/popout/logs/${deployment.id}`}
                  rightIcon={<ExternalLinkIcon />}
                >
                  View in new tab
                </Button>
              </Flex>
            </Flex>
            <Stack borderTop="1px solid var(--chakra-colors-chakra-border-color)">
              {!deployment.deployStatus?.active && (
                <ZWarn
                  error="Logs for this deployment have expired. Logs are only available on active deployments."
                  borderRadius={0}
                />
              )}
              <DeploymentLogs
                repo={realRepo}
                deploymentID={deployment.id}
                searching={isSearchingRuntimeLogs}
                setSearching={setIsSearchingRuntimeLogs}
              />
            </Stack>
          </Card>
        </>
      )}
    </Flex>
  );
};
