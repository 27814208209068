import { Dispatch, useEffect, useRef, useState } from "react";

const useEventListener = (
  eventName,
  handler: (any) => void,
  element = global,
  options: AddEventListenerOptions = {}
) => {
  const savedHandler = useRef<(any) => void>();
  const { capture, passive, once } = options;

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = element && element.addEventListener;
    if (!isSupported) {
      return;
    }

    const eventListener = (event) =>
      savedHandler.current && savedHandler.current(event);
    const opts = { capture, passive, once };
    element.addEventListener(eventName, eventListener, opts);
    return () => {
      element.removeEventListener(eventName, eventListener, opts);
    };
  }, [eventName, element, capture, passive, once]);
};

export function useLocalStorageOnce(
  key: string,
  initialValue = ""
): [string, Dispatch<string>] {
  const [value, setValue] = useState(
    () => window.localStorage.getItem(key) || initialValue
  );

  const setItem = (newValue: string) => {
    setValue(newValue);
    window.localStorage.setItem(key, newValue);
  };

  return [value, setItem];
}

export function useLocalStorage(
  key: string,
  initialValue = ""
): [string, Dispatch<string>] {
  const [value, setValue] = useState(
    () => window.localStorage.getItem(key) || initialValue
  );

  const setItem = (newValue: string) => {
    setValue(newValue);
    window.localStorage.setItem(key, newValue);
  };

  useEventListener("storage", (event) => {
    if (event.key === key && event.newValue !== value) {
      setValue(event.newValue || initialValue);
    }
  });

  return [value, setItem];
}
