import {
  AspectRatio,
  Flex,
  Switch,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {
  DeploymentDetailFragment,
  useDeploymentBuildMetricsSubscription,
} from "@zeet/web-api/dist/graphql";
import { ECOption, ZChart } from "@zeet/web-ui";
import { format } from "date-fns";
import { useMemo } from "react";
import { getEmptyMetricsArray } from "../../utils/array";
import { baseOptions } from "./index";

export const BuildMetrics: React.FC<{
  deployment: DeploymentDetailFragment;
}> = ({ deployment }) => {
  const { data } = useDeploymentBuildMetricsSubscription({
    variables: { deploymentID: deployment?.id || "" },
    skip: !deployment?.id,
  });

  const { isOpen, onToggle } = useDisclosure();

  const options: ECOption = useMemo(() => {
    return {
      ...baseOptions,

      yAxis: [
        {
          type: "value",
          boundaryGap: [0, "33%"],
          axisLabel: {
            formatter: "{value}%",
          },
          name: "CPU",
        },
        {
          type: "value",
          boundaryGap: [0, "33%"],
          axisLabel: {
            formatter: "{value} MB",
          },
          name: "Memory",
        },
      ],
      dataZoom: [
        {
          type: "inside",
          start: 0,
          end: 100,
        },
        {
          start: 0,
          end: 100,
          labelFormatter: (value) => format(value || 0, "MMM dd - HH:mm"),
        },
      ],
      title: {
        text: "Build Resource Usage",
      },
      series: [
        {
          name: "CPU",
          smooth: true,
          symbol: "none",
          areaStyle: {},
          data:
            data?.deploymentUpdated.build?.cpuMetrics &&
            data.deploymentUpdated.build.cpuMetrics.length
              ? data.deploymentUpdated.build.cpuMetrics.map((d) => [
                  new Date(d.timestamp),
                  ((d.value || 0) * 100).toFixed(2),
                ])
              : getEmptyMetricsArray(),
        },
        {
          name: "Memory",
          smooth: true,
          symbol: "none",
          areaStyle: {},
          data:
            data?.deploymentUpdated.build?.memoryMetrics &&
            data.deploymentUpdated.build.memoryMetrics.length
              ? data.deploymentUpdated.build.memoryMetrics.map((d) => [
                  new Date(d.timestamp),
                  ((d.value || 0) / 1024 / 1024).toFixed(2),
                ])
              : getEmptyMetricsArray(),
        },
      ],
    };
  }, [data]);

  return (
    <Flex flexDirection="column">
      <Flex alignItems="center">
        <Text>Show Build Metrics</Text>
        <Switch isChecked={isOpen} onChange={onToggle} ml={4} />
      </Flex>
      {isOpen && (
        <AspectRatio minHeight="300px" width="100%" ratio={16 / 9}>
          <ZChart options={options} />
        </AspectRatio>
      )}
    </Flex>
  );
};
