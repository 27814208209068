import { Flex } from "@chakra-ui/react";
import { CloudProvider } from "@zeet/web-api/dist/graphql";
import {
  AWSLogo,
  CoreWeaveLogo,
  DigitalOceanLogo,
  GCPLogo,
  K8SLogo,
  LinodeLogo,
} from "@zeet/web-ui";
import { SiMicrosoftazure, SiVultr } from "react-icons/si"; // cspell:disable-line
import { ProviderOption } from "../../features/Project/New/context";

export const ProviderLogo = ({
  option,
}: {
  option: Partial<ProviderOption>;
}) => {
  const getIcon = () => {
    if (option.provider === CloudProvider.Aws) {
      return <AWSLogo height="0.7rem" />;
    }
    if (option.provider === CloudProvider.Linode) {
      return <LinodeLogo height="1rem" />;
    }
    if (option.provider === CloudProvider.Do) {
      return <DigitalOceanLogo height="1rem" />;
    }
    if (option.provider === CloudProvider.Gcp) {
      return <GCPLogo height="0.85rem" />;
    }
    if (option.provider === CloudProvider.Coreweave) {
      return <CoreWeaveLogo height="1rem" />;
    }
    if (option.provider === CloudProvider.Azure) {
      return <SiMicrosoftazure height="1rem" />; // cspell:disable-line
    }
    if (option.provider === CloudProvider.Vultr) {
      return <SiVultr height="1rem" />;
    }
    if (option.kubernetes) {
      return <K8SLogo height="1rem" />;
    }
    return null;
  };

  return (
    <Flex width="6" justifyContent="center">
      {getIcon()}
    </Flex>
  );
};
