import { FormControl, Heading, Input, Stack, useToast } from "@chakra-ui/react";
import Editor from "@monaco-editor/react";
import {
  RepoDetailFragment,
  useProjectHelmChartQuery,
  useUpdateProjectSettingsMutation,
} from "@zeet/web-api/dist/graphql";
import { Loading, ZError } from "@zeet/web-ui";
import React, { useEffect, useState } from "react";
import { FormCard } from "../../FormCard";
import { shouldDisplayError } from "../util";

interface HelmProjectSettingsProps {
  repo: RepoDetailFragment;
}

export const HelmProjectSettings: React.FC<HelmProjectSettingsProps> = ({
  repo,
}) => {
  const toast = useToast();
  const [isDirty, setIsDirty] = useState(false);

  const [updateSettings, { error, loading, data: updateProjectData }] =
    useUpdateProjectSettingsMutation({
      onCompleted: (data) => {
        if (data) {
          toast({
            title: "Helm Settings Saved",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      },
    });

  const { data, loading: hcL } = useProjectHelmChartQuery({
    variables: {
      id: repo.id,
    },
  });

  const chart = data?.currentUser?.repo?.helmChart;
  const [version, setVersion] = useState(chart?.version || "");
  const [values, setValues] = useState(repo?.helmValues || "");
  useEffect(() => {
    setVersion(chart?.version || "latest");
  }, [chart?.version]);

  if (hcL) {
    return <Loading />;
  }

  return (
    <FormCard
      isDisabled={!isDirty}
      isLoading={loading || hcL}
      title="Helm Setup"
      onSubmit={(e) => {
        e.stopPropagation();
        e.preventDefault();
        updateSettings({
          variables: {
            input: {
              id: repo.id,
              helmVersion: version,
              helmValues: values,
            },
          },
        });
      }}
    >
      <FormControl>
        <Stack spacing={2} mt={2}>
          {repo.githubIntegration && (
            <>
              <Heading as="h4" size="sm">
                GitHub Repository
              </Heading>
              <Input
                isDisabled={true}
                value={
                  repo.githubIntegration.githubRepository?.owner +
                  "/" +
                  repo.githubIntegration.githubRepository?.name
                }
              />
              {repo.buildMethod?.workingDirectory && (
                <>
                  <Heading as="h4" size="sm">
                    Working Directory
                  </Heading>
                  <Input
                    isDisabled={true}
                    value={repo.buildMethod?.workingDirectory}
                  />
                </>
              )}
            </>
          )}
          {chart && (
            <>
              <Heading as="h4" size="sm">
                Repository
              </Heading>
              <Input isDisabled={true} value={chart?.repository?.url || ""} />
              <Heading as="h4" size="sm">
                Chart
              </Heading>
              <Input isDisabled={true} value={chart?.name || ""} />
              <Heading as="h4" size="sm">
                Version
              </Heading>
              <Input
                value={version}
                onChange={(e) => {
                  setIsDirty(true);
                  setVersion(e.target.value);
                }}
                placeholder="latest"
              />
            </>
          )}
          <Heading as="h4" size="sm">
            Namespace
          </Heading>
          <Input isDisabled={true} value={repo?.namespace || repo?.owner?.id} />
          <Heading as="h4" size="sm">
            Release Name
          </Heading>
          <Input isDisabled={true} value={repo?.appID || ""} />
          <Heading as="h4" size="sm">
            Values
          </Heading>
          <Editor
            theme="vs-dark"
            value={values}
            defaultLanguage="yaml"
            options={{
              lineNumbers: "off",
              glyphMargin: false,
              lineDecorationsWidth: 0,
              lineNumbersMinChars: 0,
              minimap: {
                enabled: false,
              },
            }}
            height="20rem"
            width="100%"
            onChange={(e) => {
              setIsDirty(true);
              setValues(e || "");
            }}
          />
          {shouldDisplayError(error, updateProjectData) && (
            <ZError error={error} />
          )}
        </Stack>
      </FormControl>
    </FormCard>
  );
};
