import {
  Alert,
  AlertIcon,
  Button,
  Flex,
  Text,
  useToast,
} from "@chakra-ui/react";
import {
  DeployStrategy,
  RepoDetailFragment,
  useUpdateProjectSettingsMutation,
} from "@zeet/web-api/dist/graphql";
import { FormSelect } from "@zeet/web-ui";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { shouldDisplayError } from "../util";
import { ZFormLabel } from "./Build";

export const DeployStrategySettings: React.FC<{ repo: RepoDetailFragment }> = ({
  repo,
}) => {
  const toast = useToast();

  const [changed, setChanged] = useState(false);
  const { handleSubmit, register } = useForm({
    defaultValues: {
      strategy: repo.deployStrategy || DeployStrategy.Rolling,
    },
  });

  const [updateSettings, { error, loading, data }] =
    useUpdateProjectSettingsMutation({
      onCompleted: (data) => {
        setChanged(false);
        if (data) {
          toast({
            title: "Deploy Settings Saved",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      },
    });

  const onSubmit = (values): void => {
    updateSettings({
      variables: {
        input: {
          id: repo.id,
          deployStrategy: values.strategy,
        },
      },
    });
  };

  if (error) {
    console.error(error);
  }

  const errorMsg = !changed && error?.message;

  return (
    <form
      onSubmit={(e) => {
        e.stopPropagation();
        e.preventDefault();
        handleSubmit(onSubmit)(e);
      }}
    >
      <Flex flexDirection="column">
        <Text fontWeight="bold" fontSize="1.2rem" mb={3}>
          Deploy Strategy
        </Text>
        <Flex flex={1} alignItems="center">
          <ZFormLabel>Deploy Strategy</ZFormLabel>
          <FormSelect
            {...register("strategy")}
            defaultValue={repo.deployStrategy || DeployStrategy.Rolling}
            ml={"5px"}
            flex="5 0"
            options={[
              ["Rolling Deployment", DeployStrategy.Rolling],
              ["Restart", DeployStrategy.Restart],
            ].map(([name, value]) => {
              return { value: value as DeployStrategy, label: name };
            })}
          />
        </Flex>

        <Flex flexDirection="column" my={4}>
          <Text>
            Rolling Deployment will automatically scale up and down new
            deployment without causing downtime.
          </Text>
          <Text>
            Restart should be used for single instance resource like chat bots
            and databases.
          </Text>
        </Flex>

        {shouldDisplayError(error, data) && (
          <Alert status="error">
            <AlertIcon />
            {errorMsg}
          </Alert>
        )}

        <Button
          mt={2}
          ml={"auto"}
          colorScheme="brand"
          isLoading={loading}
          type="submit"
        >
          Save
        </Button>
      </Flex>
    </form>
  );
};
