import { useBuildArtifactsQuery } from "@zeet/web-api/dist/graphqlv1";
import { CenterLoading, Container, useCurrentTeamUser } from "@zeet/web-ui";
import { useCallback, useMemo, useState } from "react";
import { SearchableListLayout } from "~/components/SearchableList";
import { ProjectDetailTabProps } from "..";
import { useProjectPath } from "../hooks/useProjectPath";
import { parseDockerImage } from "../utils";
import {
  BuildArtifactColumnId,
  buildArtifactColumnIds,
  buildArtifactColumns,
} from "./listview";

const PAGE_SIZE = 20;

const ArtifactsTab = ({ project }: ProjectDetailTabProps) => {
  const path = useProjectPath();
  const currentTeamUser = useCurrentTeamUser();
  const [after, setAfter] = useState(0);
  const { data: buildArtifacts, loading } = useBuildArtifactsQuery({
    variables: {
      teamId: currentTeamUser?.id,
      projectId: project.id,
      page: {
        first: PAGE_SIZE,
        after: after.toString(),
      },
    },
  });

  const build = buildArtifacts?.team?.project?.build;

  // parse the docker image and attach the parent run
  const formattedArtifacts = useMemo(
    () =>
      build?.runs.nodes.flatMap(
        (r) =>
          r.artifacts?.map((a) => {
            const { image, repository } = parseDockerImage(a.image);
            return {
              [BuildArtifactColumnId.Image]: {
                text: image,
              },
              [BuildArtifactColumnId.Repository]: {
                text: repository,
              },
              [BuildArtifactColumnId.CreatedAt]: {
                date: a.createdAt,
              },
              [BuildArtifactColumnId.Run]: {
                text: "Run #" + r.sequence,
                link: `${path}/builds?runId=${r.id}`,
              },
            };
          }) || []
      ),
    [build?.runs.nodes, path]
  );

  const allData = useMemo(
    () => ({
      columns: buildArtifactColumns,
      data: formattedArtifacts || [],
      skipReset: false,
    }),
    [formattedArtifacts]
  );

  const pageChanged = useCallback(
    (p: number) => {
      setAfter((p - 1) * PAGE_SIZE);
    },
    [setAfter]
  );

  if (loading) return <CenterLoading />;

  return (
    <Container fullWidth>
      <SearchableListLayout
        id="artifacts"
        data={allData}
        columns={buildArtifactColumns}
        defaultColumnIds={buildArtifactColumnIds}
        pageChanged={pageChanged}
        hasNextPage={!loading && build?.runs.pageInfo.hasNextPage}
      />
    </Container>
  );
};

export default ArtifactsTab;
