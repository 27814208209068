import { Flex } from "@chakra-ui/react";
import {
  ClusterDetailV1Fragment,
  Maybe,
  ObservabilityConfig,
} from "@zeet/web-api/dist/graphqlv1";
import { NotFound404 } from "@zeet/web-ui";
import { ResourceCluster } from "./ResourceCluster";
import { ResourceDetailTopView } from "./ResourceDetailTopView";

interface ResourceClusterListProps {
  projectId: string;
  clusters: ClusterDetailV1Fragment[];
  config?: Maybe<ObservabilityConfig>;
  refetchConfig: () => void;
}

export const ResourceClusterList: React.FC<ResourceClusterListProps> = ({
  projectId,
  clusters,
  config,
  refetchConfig,
}) => {
  if (!clusters?.length) {
    return (
      <NotFound404
        heading="No cluster found"
        text="Please check if your cluster is connected"
      />
    );
  }

  return (
    <Flex direction="column" ml="5">
      <ResourceDetailTopView projectId={projectId} />
      {clusters?.map((cluster, i) => (
        <ResourceCluster
          key={i}
          projectId={projectId}
          cluster={cluster}
          showSettingButton={i === 0}
          config={config}
          refetchConfig={refetchConfig}
        />
      ))}
    </Flex>
  );
};
