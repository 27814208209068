import { CopyIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Heading,
  IconButton,
  Text,
  useClipboard,
  useToast,
} from "@chakra-ui/react";
import { RepoDetailFragment } from "@zeet/web-api/dist/graphql";
import { Card, useTrack } from "@zeet/web-ui";
import React from "react";
import { CoolInput } from "../Project/Settings/CoolInput";

export const DatabaseEnvs: React.FC<{
  repo: RepoDetailFragment;
  placement: string;
}> = ({ repo, placement }) => {
  return (
    <Card py={4} my={4} px={placement === "settings" ? 6 : 4}>
      <Heading size="md" marginBottom={3}>
        Database Connection Info
      </Heading>
      <Text fontSize="sm" mb={3}>
        Database connection environment variables are automatically generated
        and updated. You may use these variables to connect to your database.
      </Text>
      {repo.databaseEnvs.map((element, index) => (
        <Flex
          as="dl"
          direction={{ base: "column", sm: "row" }}
          paddingBottom={3}
          alignItems="center"
          key={index}
        >
          <Flex
            fontWeight="semibold"
            as="dt"
            minWidth="180px"
            marginRight="4rem"
            height="2rem"
            alignItems="center"
          >
            {element.name}
          </Flex>
          <Box as="dd" flex="1">
            {element.name.toLowerCase().includes("password") ||
            element.name.toLowerCase().includes("url") ? (
              <Flex alignItems="center" height="2rem">
                <CoolInput
                  width="50%"
                  isDisabled={false}
                  value={element.value}
                  isReadOnly={true}
                  height="100%"
                />
                <CopyEnv env={element.value} />
              </Flex>
            ) : (
              <Flex as="dd" alignItems="center" flex="1" height="2rem">
                <Box>
                  {element.value}
                  <CopyEnv env={element.value} />
                </Box>
              </Flex>
            )}
          </Box>
        </Flex>
      ))}
    </Card>
  );
};

const CopyEnv: React.FC<{
  env: string;
}> = ({ env }) => {
  const { onCopy } = useClipboard(env);
  const { track } = useTrack();
  const toast = useToast();

  const onCopyClick = (): void => {
    track("click_copy_database_env");
    onCopy();
    toast({
      title: "Database Env Copied",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  return (
    <IconButton
      marginLeft="0.5rem"
      onClick={onCopyClick}
      icon={<CopyIcon height="1.5rem" />}
      aria-label="copy"
      height="100%"
    />
  );
};
