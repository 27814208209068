import { Box, Flex, Text } from "@chakra-ui/react";
import {
  ProjectContextFragment,
  ProjectContextWithoutEnvironmentFragment,
  ProjectPermissionsQuery,
  useProjectPermissionsQuery,
} from "@zeet/web-api/dist/graphql";
import {
  DataTypes,
  IDataRow,
  IZeetData,
  Loading,
  roleNameMap,
  ZError,
} from "@zeet/web-ui";
import { Column } from "react-table";
import {
  LIST_VIEW_TABLE_VIEW_TYPE,
  ListView,
  ListViewTable,
} from "~/components/ListViewV2";

const AccessControl: React.FC<{
  project: ProjectContextFragment | ProjectContextWithoutEnvironmentFragment;
}> = ({ project }) => {
  return <TeamMemberPermissionsList project={project} />;
};

const TeamMemberPermissionsList: React.FC<{
  project: ProjectContextFragment | ProjectContextWithoutEnvironmentFragment;
}> = ({ project }) => {
  const {
    data: users,
    error,
    loading,
  } = useProjectPermissionsQuery({
    variables: {
      id: project.id,
    },
  });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ZError error={error} />;
  }

  if (!users) {
    return <ZError error={"Unable to load team members"} />;
  }

  const formattedUserData = makeNewProjectPermissionsData(project, users);

  return (
    <Box width="100%" mt="3">
      <Text fontWeight="bold" fontSize="1.2rem">
        Group Access Control
      </Text>

      <Text mt={2}>
        If a Group Permission is assigned, it will take priority over the user's
        Role permission to all projects in this Group.
      </Text>

      <Flex mt={2} maxWidth={"100%"} flexDirection={"column"}>
        <ListView
          id="groupAccessControl"
          columns={columns}
          defaultColumnIds={defaultColumnIds}
          formattedData={formattedUserData}
          filterValue={""}
          handleFilterChange={() => {}}
          defaultViewMode={LIST_VIEW_TABLE_VIEW_TYPE}
        >
          <ListViewTable mt={2} />
        </ListView>
      </Flex>
    </Box>
  );
};

enum ColumnIds {
  ZEET_USERS_LOGIN = "zeet_users_login",
  ZEET_USERS_PROJECT_ROLE_NAME = "zeet_users_project_role_name",
  ZEET_USERS_ROLE_NAME = "zeet_users_role_name",
  PROJECT_ROLE_UPDATE_ACTION = "project_role_update_action",
}

const defaultColumnIds = [
  ColumnIds.ZEET_USERS_LOGIN,
  ColumnIds.ZEET_USERS_PROJECT_ROLE_NAME,
  ColumnIds.ZEET_USERS_ROLE_NAME,
  ColumnIds.PROJECT_ROLE_UPDATE_ACTION,
];

type ColumnsType = Column<IDataRow>[];

const columns: ColumnsType = [
  {
    id: ColumnIds.ZEET_USERS_LOGIN,
    label: "Name",
    minWidth: 300,
    dataType: DataTypes.TEXT,
    options: [],
    order: 1,
  },
  {
    id: ColumnIds.ZEET_USERS_ROLE_NAME,
    label: "Global Role",
    minWidth: 100,
    dataType: DataTypes.TEXT,
    options: [],
    order: 2,
  },
  {
    id: ColumnIds.ZEET_USERS_PROJECT_ROLE_NAME,
    label: "Group Permission",
    minWidth: 100,
    dataType: DataTypes.CODE,
    options: [],
    order: 3,
  },
  {
    id: ColumnIds.PROJECT_ROLE_UPDATE_ACTION,
    label: "Update",
    minWidth: 100,
    dataType: DataTypes.PROJECT_ROLE_UPDATE_ACTION,
    options: [],
    order: 3,
  },
];

function makeNewProjectPermissionsData(
  project: ProjectContextFragment | ProjectContextWithoutEnvironmentFragment,
  permissions: ProjectPermissionsQuery
): IZeetData {
  const availableRoles = permissions.project?.permissions?.availableRoles || [];
  const formattedData: IDataRow[] =
    permissions.project?.permissions?.users?.map((user) => ({
      [ColumnIds.ZEET_USERS_LOGIN]: {
        text: user.user?.login || "",
      },
      [ColumnIds.ZEET_USERS_PROJECT_ROLE_NAME]: {
        text: user.projectRole?.name || "",
      },
      [ColumnIds.ZEET_USERS_ROLE_NAME]: {
        text: user.teamRole ? roleNameMap[user.teamRole] : "",
        textAs: user.projectRole ? "s" : "",
        tooltip: user.projectRole ? "Group Role overrides Global Role" : null,
      },
      [ColumnIds.PROJECT_ROLE_UPDATE_ACTION]: {
        project: project,
        user: user,
        availableRoles: availableRoles,
      },
    })) || [];

  return {
    columns,
    data: formattedData,
    skipReset: false,
  };
}

export default AccessControl;
