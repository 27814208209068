import { Box, Flex, IconButton } from "@chakra-ui/react";
import {
  BridgeBlueprintInput,
  BridgeBlueprintInputId,
  BridgeBlueprintInputType,
  Button,
  EnvironmentVariable,
  FormLabel,
  Tooltip,
  useEffectOnce,
} from "@zeet/web-ui";
import { useCallback } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { FiMinusCircle } from "react-icons/fi";
import { FileUpload } from "~/components/FileUpload";
import { useParseEnv } from "~/hooks/useParseEnv";
import { generateRandomPassword } from "~/utils/generators";
import { NewResourceValues } from "../../context";
import InputControl from "./InputControl";

interface EnvironmentVariablesInputProps {
  input: BridgeBlueprintInput;
  pathMap: (value: any) => string;
}

type EnvironmentVariableInput = {
  [BridgeBlueprintInputId.EnvironmentVariableKey]: string;
  [BridgeBlueprintInputId.EnvironmentVariableValue]: string;
  sensitive?: boolean;
};

export const EnvironmentVariablesInput: React.FC<
  EnvironmentVariablesInputProps
> = ({ input }) => {
  const {
    formState: { errors },
    resetField,
  } = useFormContext<NewResourceValues>();
  const { fields, append, remove, update } = useFieldArray<{
    variables: EnvironmentVariableInput[];
  }>({
    name: `variables.${input.id}` as "variables",
  });
  const keyId = BridgeBlueprintInputId.EnvironmentVariableKey;
  const valueId = BridgeBlueprintInputId.EnvironmentVariableValue;

  const makeInitialValues = (
    input: BridgeBlueprintInput
  ): EnvironmentVariableInput[] | undefined => {
    if (Array.isArray(input.default)) {
      return input.default.map(
        (d: EnvironmentVariable): EnvironmentVariableInput => ({
          [keyId]: d.key ?? "",
          [valueId]: d.value || (d.sensitive ? generateRandomPassword() : ""),
          sensitive: d.sensitive,
        })
      );
    }
  };

  const defaultValues = {
    [keyId]: "",
    [valueId]: "",
  };

  useEffectOnce(() => {
    if (fields.length === 0) {
      append(makeInitialValues(input) ?? defaultValues);
    } else {
      fields.forEach((field, index) => {
        if (field[keyId] === "") {
          remove(index);
        }
      });
    }
  });

  const removeOrReset = (index: number) => {
    if (fields.length === 1) {
      update(0, defaultValues);
    } else {
      remove(index);
    }
  };

  const getError = (index: number, id: string) => {
    if (errors.variables) {
      const inputErrors = errors.variables[input.id] ?? {};
      if (inputErrors[index]) {
        return inputErrors[index][id];
      }
    }
  };

  const onEnvs = useCallback(
    (envs: { key: string; value: string }[]) => {
      resetField(`variables.${input.id}`, {
        defaultValue: [],
      });
      envs.forEach((env) => {
        append({
          [keyId]: env.key,
          [valueId]: env.value,
        });
      });
    },
    [append, input.id, keyId, valueId, resetField]
  );

  const { parseEnv } = useParseEnv(onEnvs);

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        pr="9"
      >
        <Box display="inline-flex" alignItems="center" flex="1">
          <FormLabel width="100%">Key</FormLabel>
        </Box>
        <Box display="inline-flex" alignItems="center" flex="1">
          <FormLabel width="100%">Value</FormLabel>
        </Box>
      </Box>
      {fields.map((field, index) => (
        <Box
          key={field.id}
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          gap="3"
        >
          <InputControl
            input={{
              type: BridgeBlueprintInputType.String,
              id: `${input.id}.${index}.${keyId}`,
              name: "",
              required: false,
            }}
            error={getError(index, keyId)?.message}
          />
          <InputControl
            input={{
              type: BridgeBlueprintInputType.String,
              id: `${input.id}.${index}.${valueId}`,
              name: "",
              required: false,
              sensitive: field.sensitive,
            }}
            error={getError(index, valueId)?.message}
          />
          <IconButton
            aria-label="delete"
            icon={<FiMinusCircle />}
            borderRadius="md"
            variant="outline"
            onClick={() => removeOrReset(index)}
            mb="2"
          />
        </Box>
      ))}
      <Flex mt="3">
        <Button
          variant="secondary"
          size="sm"
          mb={input.name ? 0 : 4}
          onClick={() => append(defaultValues)}
          mr={4}
        >
          Add item
        </Button>
        {input.id === BridgeBlueprintInputId.EnvironmentVariables && (
          <FileUpload name="dotenv" onChange={parseEnv} width="auto">
            <Button variant="secondary" size="sm">
              Upload .env file
              <Tooltip text="Not seeing your .env file? If you're on Mac, try ⌘ + Shift + ." />
            </Button>
          </FileUpload>
        )}
      </Flex>
    </>
  );
};
