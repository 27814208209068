import { LogEntry } from "@zeet/web-api/dist/graphql";
import { useCallback, useEffect, useMemo, useState } from "react";
import stripAnsi from "strip-ansi";
export const useFind = (query: string, isRegex: boolean, lines: LogEntry[]) => {
  const [matchIndex, setMatchIndex] = useState(0);
  const matches = useMemo(() => {
    if (query.trim() === "") return [];
    const results: {
      lineIndex: number;
      startIndex: number;
      endIndex: number;
    }[] = [];
    const regex = new RegExp(isRegex ? query : escapeRegex(query), "gi");
    lines.forEach((l, i) => {
      regex.lastIndex = 0;
      let out: RegExpExecArray | null;
      const stripped = stripAnsi(l.text).replace(/\r/g, "");
      while ((out = regex.exec(stripped)) != null) {
        results.push({
          lineIndex: i,
          startIndex: out.index,
          endIndex: out.index + out[0].length,
        });
      }
    });
    return results;
  }, [lines, query, isRegex]);

  useEffect(() => setMatchIndex(0), [query, matches]);

  return {
    total: matches.length,
    currentIndex: matchIndex,
    currentMatch: matches.length < matchIndex ? null : matches[matchIndex],
    getMatchesForLineIndex: useCallback(
      (l: number) => {
        if (matches.length < matchIndex) return [];
        return matches
          .filter((m) => m.lineIndex === l)
          .map((m) => ({ ...m, isActive: matches[matchIndex] === m }));
      },
      [matches, matchIndex]
    ),
    nextMatch: useCallback(
      () => setMatchIndex((i) => (i + 1 >= matches.length ? 0 : i + 1)),
      [matches.length]
    ),
    prevMatch: useCallback(
      () => setMatchIndex((i) => (i - 1 < 0 ? matches.length - 1 : i - 1)),
      [matches.length]
    ),
  };
};

const escapeRegex = (regex) => {
  return regex.replace(/([()[{*+.$^\\|?])/g, "\\$1");
};
