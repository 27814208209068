import { Box, Flex, Text } from "@chakra-ui/react";
import { ClusterDetailV1Fragment } from "@zeet/web-api/dist/graphqlv1";
import { K8SLogo, Link, useCurrentTeamUser } from "@zeet/web-ui";
import { CloudProviderLogos } from "~/components/Console/ConsoleClouds/Details";

interface ResourceClusterInfoProps {
  cluster: ClusterDetailV1Fragment;
  showLogo?: boolean;
  showLink?: boolean;
}

export const ResourceClusterInfo: React.FC<ResourceClusterInfoProps> = ({
  cluster,
  showLogo,
  showLink,
}) => {
  const { login: teamLogin } = useCurrentTeamUser();

  return (
    <Flex alignItems="center" gap="1">
      {cluster.cloudProvider && showLogo && (
        <Box mr="2" height="20px">
          {CloudProviderLogos[cluster.cloudProvider]}
        </Box>
      )}
      {!cluster.cloudProvider && showLogo && <K8SLogo boxSize="20px" />}
      {showLink ? (
        <Link
          to={`/${teamLogin}/console/clusters/${cluster.id}`}
          target="_blank"
          hoverColor="brand.100"
        >
          {cluster.displayName}
        </Link>
      ) : (
        <Text>{cluster.displayName}</Text>
      )}
    </Flex>
  );
};
