import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Badge,
  Box,
  Divider,
  Flex,
  FlexProps,
  Grid,
  Heading,
  HStack,
  Icon,
  LinkBox,
  LinkOverlay,
  Stack,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  GroupListItemFragment,
  SubgroupListItemFragment,
} from "@zeet/web-api/dist/graphql";
import {
  Card,
  Link,
  ProjectStatus,
  Skeleton,
  TimeBox,
  Tip,
  useColors,
  useCurrentTeamUser,
} from "@zeet/web-ui";
import Color from "color";
import { MdAddCircle, MdFolder } from "react-icons/md";
import { useHistory, useParams } from "react-router-dom";
import { ListItemActionPill } from "~/features/Status/lists/List";
import {
  isSampleProject,
  SAMPLE_PROJECT_STATUS,
} from "../../features/Project/New/makeCreateSampleProjectInput";
import { useListViewContext } from "../ListViewV2/Provider";
import { makeLink } from "../utils/link";
import { ProjectTreeEmptyState } from "./ProjectEmptyState";
import {
  makeSourceLink,
  makeSourceName,
  ProjectIcon,
  ProjectListItem,
  ProjectSourceIcon,
} from "./ProjectV3Utils";

export const PROJECT_CARDS_VIEW_TYPE = "PROJECT_CARDS_VIEW_TYPE";

interface ProjectCardsViewProps {
  projects?: ProjectListItem[];
  groups?: GroupListItemFragment[];
  sortingStrategy?: "lastUpdated" | "name";
}

export const ProjectCardsView = ({
  projects,
  groups,
  sortingStrategy,
}: ProjectCardsViewProps) => {
  const { loading } = useListViewContext();

  const { projectName: groupName, envName: subgroupName } = useParams<{
    projectName: string;
    envName: string;
  }>();

  if (loading) {
    return (
      <CardLayout>
        {Array.from({ length: 12 }).map((_, i) => (
          <Box key={i} borderRadius="md" overflow="hidden">
            <Skeleton height="203px" />
          </Box>
        ))}
      </CardLayout>
    );
  }

  const CardsComponent = subgroupName
    ? SubgroupCardsView
    : groupName
    ? GroupCardsView
    : RootCardsView;

  return (
    <CardsComponent
      projects={projects}
      groups={groups}
      sortingStrategy={sortingStrategy}
    />
  );
};

const BaseCard = ({ children }: { children: React.ReactNode }) => {
  const { bgHover } = useColors();

  return (
    <LinkBox
      as={Card}
      display="flex"
      flexDir="column"
      _hover={{
        borderShadow: "lg",
        borderColor: "#11f72d",
        boxShadow: "0 0 0 1px #11f72d",
        bg: bgHover,
      }}
    >
      {children}
    </LinkBox>
  );
};

const BaseCardFooter = ({
  children,
  updatedAt,
  ...props
}: {
  children: React.ReactNode;
  updatedAt?: Date | null;
} & FlexProps) => {
  const { bg } = useColors();

  return (
    <Flex flexDirection="column" as="footer" minHeight="48px">
      <Divider />
      <Flex
        px={4}
        py={3}
        bg={bg}
        alignItems="center"
        justify="space-between"
        justifySelf="flex-end"
        opacity={0.5}
        gap="2"
        {...props}
      >
        {children}
        {updatedAt && (
          <Text fontSize="sm" minW="fit-content">
            <TimeBox prefix="Updated" time={updatedAt} />
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

const BaseCardHeader = ({
  to,
  children,
}: {
  to: string;
  children: React.ReactNode;
}) => {
  return (
    <Flex p={4} align="center" justify="space-between" minW="100%">
      <LinkOverlay
        as={Link}
        to={to}
        display="flex"
        flex="1"
        justifyContent="space-between"
        alignItems="flex-start"
        _hover={{ textDecoration: "none" }}
        textDecoration="none"
      >
        {children}
      </LinkOverlay>
    </Flex>
  );
};

const BaseCardContent = ({ children }: { children?: React.ReactNode }) => {
  return (
    <Flex px={4} pb={4} h={24} flexGrow={1}>
      {children}
    </Flex>
  );
};

const getUniqueEndpoints = (project: ProjectListItem) => {
  const endpoints = project.repo?.productionDeployment?.endpoints ?? [];
  return [...new Set(endpoints)];
};

const PublicEndpoints = ({ project }: { project: ProjectListItem }) => {
  const teamUser = useCurrentTeamUser();
  const publicEndpoints = getUniqueEndpoints(project);
  const primaryPublicEndpoint = publicEndpoints?.[0];

  if (primaryPublicEndpoint && primaryPublicEndpoint !== "deploying") {
    return (
      <Box display="inline-flex" gap={2} alignItems="center">
        <Link
          to={makeLink(primaryPublicEndpoint)}
          noOfLines={1}
          wordBreak="break-all"
          isExternal
        >
          {primaryPublicEndpoint}
        </Link>
        <ExternalLinkIcon fontSize="sm" opacity=".8" />
        <Tooltip
          placement="top"
          hasArrow
          label="Public endpoints are exposed to the public internet"
        >
          <Badge cursor="help" variant="outline" colorScheme="brand">
            Public
          </Badge>
        </Tooltip>
        {(publicEndpoints?.length ?? 0) > 1 && (
          <Badge
            as={Link}
            to={`/${teamUser.login}/${project.project?.name}/${project.projectEnvironment?.name}/${project.name}/settings/networking`}
            textTransform="lowercase"
            variant="outline"
            colorScheme="gray"
          >
            +{(publicEndpoints?.length ?? 0) - 1} more
          </Badge>
        )}
      </Box>
    );
  }
  return null;
};

const InternalEndpoints = ({ project }: { project: ProjectListItem }) => {
  if (project.repo?.productionDeployment?.privateEndpoint) {
    const internalEndpoint = project.repo.productionDeployment.privateEndpoint;

    return (
      <Box as={Flex} gap={2} alignItems="center">
        <Text noOfLines={1} wordBreak="break-all">
          {internalEndpoint}
        </Text>
        <Tooltip
          placement="top"
          hasArrow
          label={
            <>
              Internal endpoints are available <strong>only</strong> to other
              projects in your account and <strong>not</strong> over the public
              internet.
            </>
          }
        >
          <Badge cursor="help" variant="outline" colorScheme="blue">
            Internal
          </Badge>
        </Tooltip>
      </Box>
    );
  }
  return null;
};

const ProjectCard = ({ project }: { project: ProjectListItem }) => {
  const teamUser = useCurrentTeamUser();

  return (
    <BaseCard>
      <BaseCardHeader
        to={`/${teamUser.login}/${project.project?.name}/${project.projectEnvironment?.name}/${project.name}`}
      >
        <Heading size="md" display="flex" alignItems="flex-start" gap={2}>
          <ProjectIcon project={project} />
          {project.name}
        </Heading>
        <ProjectStatus
          clusterState={project.repo?.cluster?.state}
          status={
            isSampleProject(project.name)
              ? SAMPLE_PROJECT_STATUS
              : project.status
          }
          loading={!project.status}
        />
      </BaseCardHeader>

      <BaseCardContent>
        <Stack>
          <PublicEndpoints project={project} />
          <InternalEndpoints project={project} />
        </Stack>
      </BaseCardContent>

      <BaseCardFooter updatedAt={project.updatedAt}>
        <Flex
          alignItems="center"
          gap="2"
          as={Link}
          _hover={{ textDecoration: "none" }}
          to={makeSourceLink(teamUser.login, project)}
        >
          <ProjectSourceIcon project={project} />
          <Text noOfLines={1} wordBreak="break-all">
            {makeSourceName(project)}
          </Text>
        </Flex>
      </BaseCardFooter>
    </BaseCard>
  );
};

const GroupCard = ({
  group,
  projects,
  groupsWithProjects,
}: {
  group: GroupListItemFragment;
  projects?: ProjectListItem[];
  groupsWithProjects?: ((GroupListItemFragment | SubgroupListItemFragment) & {
    projects?: ProjectListItem[];
  })[];
}) => {
  const { brand, fg, fg3, bg } = useColors();
  const teamUser = useCurrentTeamUser();
  const history = useHistory();
  const subgroups = group.environments;

  const subgroupFolderColor = useColorModeValue(
    Color(fg3).lighten(1.5).hex(),
    Color(fg).darken(0.65).hex()
  );

  const shouldShowGroupTip =
    groupsWithProjects?.every((g) => (g.projects?.length ?? 0) <= 2) &&
    groupsWithProjects?.filter(
      (g) => (g.projects?.length ?? 0) === 2 && g.id === group.id
    ).length === 1;

  const subgroupsWithProjects = subgroups?.map((subgroup) => {
    const projectsInSubgroup = projects?.filter(
      (project) => project?.projectEnvironment?.id === subgroup.id
    );
    return { ...subgroup, projects: projectsInSubgroup };
  });

  return (
    <BaseCard>
      <BaseCardHeader to={`/${teamUser?.login}/${group.name}`}>
        <Heading
          size="md"
          display="inline-flex"
          alignItems="center"
          gap={2}
          _hover={{ color: brand }}
        >
          <Avatar
            borderRadius="md"
            size="xs"
            icon={<Icon as={MdFolder} boxSize={6} color={fg} />}
            bg="transparent"
            opacity={0.5}
          />
          {group.name}
        </Heading>
      </BaseCardHeader>

      <BaseCardContent>
        <Box
          borderLeft="1px solid var(--accents-2)"
          opacity={0.5}
          position="absolute"
          top="2.5rem"
          height="calc(100% - 5.5rem)"
          left="1.75rem"
        />
        {subgroups.length > 0 && (
          <Box
            borderLeft="1px solid var(--accents-2)"
            opacity={0.5}
            position="absolute"
            top="4.75rem"
            height="calc(100% - 7.75rem)"
            left="3.75rem"
            zIndex={1}
          />
        )}
        <Stack ml="8" mt="-0.5" gap="3" position="relative" zIndex={2}>
          {subgroupsWithProjects?.slice(0, 2).map((e, i) => (
            <HStack key={e.id} alignItems="center">
              <Icon as={MdFolder} boxSize={6} color={subgroupFolderColor} />
              <Tooltip placement="bottom" hasArrow>
                <Link to={`/${teamUser?.login}/${group.name}/${e.name}`}>
                  {e.name}
                </Link>
              </Tooltip>
              {subgroupsWithProjects.length > 2 && i === 1 && (
                <ListItemActionPill
                  onClick={() =>
                    history.push(`/${teamUser?.login}/${group.name}`)
                  }
                >
                  <Text fontSize="xs" p="0.5" noOfLines={1}>
                    +{(subgroupsWithProjects.length ?? 0) - 2} more
                  </Text>
                </ListItemActionPill>
              )}
            </HStack>
          ))}
          {subgroupsWithProjects.length === 1 && (
            <HStack ml="8" textTransform="lowercase">
              {subgroupsWithProjects[0]?.projects
                ?.slice(0, 1)
                .map((project) => (
                  <>
                    <Link
                      _hover={{ textDecoration: "none" }}
                      to={`/${teamUser?.login}/${group.name}/${subgroupsWithProjects[0]?.name}/${project.name}`}
                    >
                      <HStack px="1" py="0.5" alignItems="center">
                        <ProjectIcon project={project} boxSize={6} />
                        <Text
                          noOfLines={1}
                          wordBreak="break-all"
                          _hover={{ textDecoration: "underline" }}
                        >
                          {project.name}
                        </Text>
                        <ProjectStatus
                          clusterState={project.repo?.cluster?.state}
                          status={
                            isSampleProject(project.name)
                              ? SAMPLE_PROJECT_STATUS
                              : project.status
                          }
                          loading={!project.status}
                        />
                      </HStack>
                    </Link>
                    {(subgroupsWithProjects[0]?.projects?.length ?? 0) > 1 && (
                      <ListItemActionPill
                        onClick={() =>
                          history.push(
                            `/${teamUser?.login}/${group.name}/${subgroupsWithProjects[0]?.name}`
                          )
                        }
                      >
                        <Text fontSize="xs" p="0.5" noOfLines={1}>
                          +
                          {(subgroupsWithProjects[0]?.projects?.length ?? 0) -
                            1}{" "}
                          more
                        </Text>
                      </ListItemActionPill>
                    )}
                  </>
                ))}
            </HStack>
          )}
        </Stack>
      </BaseCardContent>

      {shouldShowGroupTip && (
        <Box bg={bg} position="absolute" zIndex="99" bottom="0" width="100%">
          <Tip id="group-onboarding" m="0">
            Click this Group to see your Projects
          </Tip>
        </Box>
      )}

      <BaseCardFooter updatedAt={projects?.[0]?.updatedAt ?? group.updatedAt}>
        <Flex gap={4}>
          <Text>
            <Text as="span" fontWeight="bold">
              {subgroups?.length}
            </Text>{" "}
            Sub-Group
            {subgroups?.length !== 1 ? "s" : ""}
          </Text>

          <Text>
            <Text as="span" fontWeight="bold">
              {projects?.length}
            </Text>{" "}
            Project
            {projects?.length !== 1 ? "s" : ""}
          </Text>
        </Flex>
      </BaseCardFooter>
    </BaseCard>
  );
};

const SubgroupCard = ({
  subgroup,
  projects,
}: {
  subgroup: SubgroupListItemFragment;
  projects?: ProjectListItem[];
}) => {
  const { brand, fg } = useColors();
  const history = useHistory();
  const teamUser = useCurrentTeamUser();

  const { projectName: groupName } = useParams<{
    projectName: string;
    envName: string;
  }>();

  return (
    <BaseCard>
      <BaseCardHeader to={`/${teamUser?.login}/${groupName}/${subgroup.name}`}>
        <Heading
          size="md"
          display="inline-flex"
          alignItems="center"
          gap={2}
          _hover={{ color: brand }}
        >
          <Avatar
            borderRadius="md"
            size="xs"
            icon={<Icon as={MdFolder} boxSize={6} color={fg} />}
            bg="transparent"
            opacity={0.5}
          />
          {subgroup.name}
        </Heading>
      </BaseCardHeader>

      <BaseCardContent>
        <Box
          borderLeft="1px solid var(--accents-2)"
          opacity={0.5}
          position="absolute"
          top="2.5rem"
          height="calc(100% - 5.5rem)"
          left="1.75rem"
        />
        <Stack mt="-0.5">
          {projects?.slice(0, 2).map((project, i) => (
            <HStack ml="8" textTransform="lowercase">
              <Link
                _hover={{ textDecoration: "none" }}
                to={`/${teamUser?.login}/${groupName}/${subgroup.name}/${project.name}`}
              >
                <HStack px="1" py="0.5" alignItems="center">
                  <ProjectIcon project={project} boxSize={6} />
                  <Text
                    noOfLines={1}
                    wordBreak="break-all"
                    _hover={{ textDecoration: "underline" }}
                  >
                    {project.name}
                  </Text>
                  <ProjectStatus
                    clusterState={project.repo?.cluster?.state}
                    status={
                      isSampleProject(project.name)
                        ? SAMPLE_PROJECT_STATUS
                        : project.status
                    }
                    loading={!project.status}
                  />
                </HStack>
              </Link>
              {(projects.length ?? 0) > 2 && i == 1 && (
                <ListItemActionPill
                  onClick={() =>
                    history.push(
                      `/${teamUser?.login}/${groupName}/${subgroup.name}`
                    )
                  }
                >
                  <Text fontSize="xs" p="0.5" noOfLines={1}>
                    +{(projects.length ?? 0) - 2} more
                  </Text>
                </ListItemActionPill>
              )}
            </HStack>
          ))}
        </Stack>
      </BaseCardContent>

      <BaseCardFooter
        updatedAt={projects?.[0]?.updatedAt ?? subgroup.updatedAt}
      >
        <Flex gap={4}>
          <Text>
            <Text as="span" fontWeight="bold">
              {projects?.length}
            </Text>{" "}
            Project{projects?.length !== 1 ? "s" : ""}
          </Text>
        </Flex>
      </BaseCardFooter>
    </BaseCard>
  );
};

const CardLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <Grid
      templateColumns={{
        base: "repeat(auto-fill, 1fr)",
        sm: "repeat(auto-fill, minmax(400px, 1fr))",
      }}
      gap={9}
      mt={9}
    >
      {children}
    </Grid>
  );
};

const SubgroupCardsView = ({
  projects,
  sortingStrategy,
}: ProjectCardsViewProps) => {
  return (
    <BaseCardsView projects={projects} sortingStrategy={sortingStrategy} />
  );
};

const GroupCardsView = ({
  projects,
  groups,
  sortingStrategy,
}: ProjectCardsViewProps) => {
  const { projectName: groupName } = useParams<{
    projectName: string;
    envName: string;
  }>();

  const subgroups = groups?.find((e) => e.name === groupName)?.environments;

  const subgroupsWithProjects = subgroups?.map((subgroup) => {
    const projectsInSubgroup = projects?.filter(
      (project) => project?.projectEnvironment?.id === subgroup.id
    );
    return { ...subgroup, projects: projectsInSubgroup };
  });

  return (
    <BaseCardsView
      projects={projects}
      groupsWithProjects={subgroupsWithProjects}
      sortingStrategy={sortingStrategy}
      useSubgroupCard
    />
  );
};

const RootCardsView = ({
  projects,
  groups,
  sortingStrategy,
}: ProjectCardsViewProps) => {
  const groupsWithProjects = groups?.map((group) => {
    const projectsInGroup = projects?.filter(
      (project) => project?.project?.id === group.id
    );
    return { ...group, projects: projectsInGroup };
  });

  return (
    <BaseCardsView
      projects={projects}
      groupsWithProjects={groupsWithProjects}
      sortingStrategy={sortingStrategy}
    />
  );
};

interface BaseCardsViewProps {
  projects?: ProjectListItem[];
  sortingStrategy?: "name" | "lastUpdated";
  groupsWithProjects?: ((GroupListItemFragment | SubgroupListItemFragment) & {
    projects?: ProjectListItem[];
  })[];
  useSubgroupCard?: boolean;
}

const NewProjectCard = () => {
  return (
    <BaseCard>
      <Flex minH="100%" minW="100%" opacity="0.5" height="200px">
        <BaseCardHeader to="/new">
          <Stack justifyContent="center" alignItems="center" width="100%">
            <Icon as={MdAddCircle} boxSize={8} />
            <Heading size="md">New Project</Heading>
          </Stack>
        </BaseCardHeader>
      </Flex>
    </BaseCard>
  );
};

const BaseCardsView = ({
  projects,
  groupsWithProjects,
  sortingStrategy,
  useSubgroupCard,
}: BaseCardsViewProps) => {
  const { filterValue } = useListViewContext();
  const sortingMethod = sortingMethods[sortingStrategy || "lastUpdated"];

  if (filterValue) {
    const filteredGroups = groupsWithProjects?.filter((group) =>
      group.name.includes(filterValue)
    );
    const filteredProjects = projects?.filter((project) =>
      project.name?.includes(filterValue)
    );

    if (
      (filteredProjects?.length ?? 0) < 1 &&
      (filteredGroups?.length ?? 0) < 1
    ) {
      return (
        <ProjectTreeEmptyState level={useSubgroupCard ? "group" : "global"} />
      );
    }

    return (
      <CardLayout>
        {filteredGroups
          ?.sort(sortingMethod)
          .map((group) =>
            useSubgroupCard ? (
              <SubgroupCard
                subgroup={group as SubgroupListItemFragment}
                projects={group.projects}
              />
            ) : (
              <GroupCard
                group={group as GroupListItemFragment}
                projects={group.projects}
                groupsWithProjects={groupsWithProjects}
              />
            )
          )}
        {filteredProjects?.sort(sortingMethod).map((project) => (
          <ProjectCard project={project} />
        ))}
      </CardLayout>
    );
  }

  if ((groupsWithProjects?.length ?? 0) < 1 && (projects?.length ?? 0) < 1) {
    return (
      <ProjectTreeEmptyState level={useSubgroupCard ? "group" : "global"} />
    );
  }

  return (
    <CardLayout>
      {groupsWithProjects?.sort(sortingMethod).map((group) => {
        return useSubgroupCard ? (
          <SubgroupCard
            key={group.id}
            subgroup={group as SubgroupListItemFragment}
            projects={group.projects}
          />
        ) : (
          <GroupCard
            key={group.id}
            group={group as GroupListItemFragment}
            projects={group.projects}
            groupsWithProjects={groupsWithProjects}
          />
        );
      })}
      {!groupsWithProjects && (
        <>
          {projects?.sort(sortingMethod).map((p) => (
            <ProjectCard key={p.id} project={p} />
          ))}
          <NewProjectCard />
        </>
      )}
    </CardLayout>
  );
};

type ProjectOrGroup =
  | ProjectListItem
  | ((GroupListItemFragment | SubgroupListItemFragment) & {
      projects?: ProjectListItem[];
    });

const sortingMethods = {
  name: (a: ProjectOrGroup, b: ProjectOrGroup) => a.name.localeCompare(b.name),
  lastUpdated: (a: ProjectOrGroup, b: ProjectOrGroup) =>
    "projects" in a
      ? sortGroupByUpdatedAt(
          a,
          b as GroupListItemFragment & { projects: ProjectListItem[] }
        )
      : sortProjectByUpdatedAt(a as ProjectListItem, b as ProjectListItem),
};

const sortProjectByUpdatedAt = (
  a: ProjectListItem,
  b: ProjectListItem
): number => {
  return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
};

const sortGroupByUpdatedAt = (
  a: (GroupListItemFragment | SubgroupListItemFragment) & {
    projects?: ProjectListItem[];
  },
  b: (GroupListItemFragment | SubgroupListItemFragment) & {
    projects?: ProjectListItem[];
  }
) => {
  const aUpdatedAt = Math.max(
    new Date(a.updatedAt).getTime(),
    new Date(a.projects?.[0]?.updatedAt ?? 0).getTime()
  );
  const bUpdatedAt = Math.max(
    new Date(b.updatedAt).getTime(),
    new Date(b.projects?.[0]?.updatedAt ?? 0).getTime()
  );
  return bUpdatedAt - aUpdatedAt;
};
