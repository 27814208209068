import { Box, Flex, Text } from "@chakra-ui/react";
import {
  JobRunState,
  useJobRunDetailQuery,
  useJobRunLogsQuery,
} from "@zeet/web-api/dist/graphqlv1";
import {
  CenterLoading,
  DetailSidebar,
  SidebarLayout,
  useCurrentTeamUser,
  ZError,
} from "@zeet/web-ui";
import { LogView } from "~/components/LogView";
import { DurationDetailItem } from "../components/DurationDetailItem";
import { StatusDetailItem } from "../components/StatusDetailItem";
import { jobRunStatusMap } from "../utils";

interface JobDetailProps extends React.PropsWithChildren {
  jobRunId: string;
  title?: string;
}

export const JobRunDetail = ({ jobRunId, children, title }: JobDetailProps) => {
  const { id: teamId } = useCurrentTeamUser();
  const runVariables = {
    teamId,
    id: jobRunId,
  };
  const { data, loading } = useJobRunDetailQuery({
    variables: runVariables,
    pollInterval: 5000,
  });

  const run = data?.team?.jobRun;

  if (loading) return <CenterLoading />;
  if (!run) return <ZError m={4}>Failed to fetch job run.</ZError>;

  return (
    <SidebarLayout data-testid="job-run-detail">
      <Flex direction={"column"}>
        <Text fontSize="1.25em" mb={4} fontWeight={500}>
          {title || `${run.definition.jobName} Run #${run.sequence}`}
        </Text>
        {run.errorMessage && <ZError mb={4}>{run.errorMessage}</ZError>}
        <Box>
          <LogView
            title="Logs"
            query={useJobRunLogsQuery}
            variables={runVariables}
            logsResolver={(r) => r.team?.jobRun?.logs}
          />
        </Box>
      </Flex>
      <DetailSidebar>
        <StatusDetailItem
          statusInfo={jobRunStatusMap[run.state]}
          data-testid="job-run-status"
        />
        <DurationDetailItem
          startDate={run.createdAt}
          endDate={run.updatedAt}
          hasEnded={
            run.state === JobRunState.JobRunSucceeded ||
            run.state === JobRunState.JobRunFailed
          }
        />
        {children}
      </DetailSidebar>
    </SidebarLayout>
  );
};
