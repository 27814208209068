import { GitSourceInput } from "@zeet/web-api/dist/graphqlv1";
import { GitlabRepositorySelect } from "~/components/RepositorySelect/gitlab";
import { SourceTabPanelProps } from ".";
import { GitBranch } from "../GitBranch";
import { GitWorkingDirectory } from "../GitWorkingDirectory";
import { makeGitlabSource } from "./utils";

export const GitLabSource = ({
  onSourceChange,
  currentSource,
}: SourceTabPanelProps) => {
  const ref = currentSource?.git?.ref ?? "";
  const path = currentSource?.git?.path ?? "";

  const onSelect = (integrationId: string, repository: string) => {
    const gitSource: GitSourceInput = {
      ...currentSource?.git,
      repository,
      integration: {
        gitlabIntegrationId: integrationId,
      },
    };
    onSourceChange(makeGitlabSource(gitSource));
  };

  const onRefChange = (ref: string) => {
    const gitSource: GitSourceInput = {
      ...currentSource?.git,
      repository: currentSource?.git?.repository ?? "",
      ref,
    };
    onSourceChange(makeGitlabSource(gitSource));
  };

  const onPathChange = (path: string) => {
    const gitSource: GitSourceInput = {
      ...currentSource?.git,
      repository: currentSource?.git?.repository ?? "",
      path,
    };
    onSourceChange(makeGitlabSource(gitSource));
  };

  return (
    <>
      <GitlabRepositorySelect onSelect={onSelect} source={currentSource} />
      <GitWorkingDirectory value={path} onChange={onPathChange} />
      <GitBranch value={ref} onChange={onRefChange} />
    </>
  );
};
