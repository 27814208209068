import { DeleteIcon } from "@chakra-ui/icons";
import { Button, Flex, Input, Stack, Text, useToast } from "@chakra-ui/react";
import {
  RepoDetailFragment,
  useUpdateProjectSettingsMutation,
} from "@zeet/web-api/dist/graphql";
import { PrometheusLogo, ZError } from "@zeet/web-ui";
import React from "react";
import { useForm } from "react-hook-form";
import { shouldDisplayError } from "../../util";
import { ZFormLabel } from "../Build";

export const PrometheusSettings: React.FC<{ repo: RepoDetailFragment }> = ({
  repo,
}) => {
  const toast = useToast();

  type FormType = {
    prometheusPath: string;
    prometheusPort: number;
  };
  const { handleSubmit, register, watch, reset } = useForm<FormType>({
    defaultValues: {
      prometheusPath: repo?.prometheusScrape?.path,
      prometheusPort: repo?.prometheusScrape?.port,
    },
  });

  const watchForm = watch();

  const [updateSettings, { error, loading, data }] =
    useUpdateProjectSettingsMutation({
      onCompleted: (data) => {
        if (data) {
          toast({
            title: "Prometheus Settings Saved",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      },
    });

  const onSubmit = (values: FormType) => {
    updateSettings({
      variables: {
        input: {
          id: repo.id,
          prometheusScrape: {
            path: values.prometheusPath,
            port: values.prometheusPort,
          },
        },
      },
    });
  };

  return (
    <form
      onSubmit={(e) => {
        e.stopPropagation();
        e.preventDefault();
        handleSubmit(onSubmit)(e);
      }}
    >
      <Flex flexDirection="column">
        <Flex alignItems="center" mb={2}>
          <PrometheusLogo width="1.2rem" height="1.2rem" />
          <Text ml={1} fontWeight="bold" fontSize="1.2rem">
            Prometheus Settings
          </Text>
        </Flex>

        <Stack>
          <Text>Expose custom metrics to Prometheus metrics server</Text>

          <Flex alignItems="center">
            <ZFormLabel>HTTP Path</ZFormLabel>
            <Input
              flex={5}
              fontFamily={"mono"}
              {...register("prometheusPath")}
              placeholder={"e.g. /metrics"}
              defaultValue={watchForm.prometheusPath || ""}
            />
          </Flex>
          <Flex alignItems="center">
            <ZFormLabel>HTTP Port</ZFormLabel>
            <Input
              flex={5}
              fontFamily={"mono"}
              type="number"
              {...register("prometheusPort", {
                required: true,
                valueAsNumber: true,
              })}
              placeholder={"e.g. 8000"}
              defaultValue={watchForm.prometheusPort || ""}
            />
          </Flex>
          {shouldDisplayError(error, data) && <ZError error={error} />}
          <Flex justifyContent="flex-end">
            {repo?.prometheusScrape?.port && (
              <Button
                mr={2}
                colorScheme="red"
                leftIcon={<DeleteIcon />}
                isLoading={loading}
                onClick={() => {
                  updateSettings({
                    variables: {
                      input: {
                        id: repo.id,
                        prometheusScrape: {
                          path: "",
                          port: 0,
                        },
                      },
                    },
                  });
                  reset();
                }}
              >
                Disable
              </Button>
            )}
            <Button colorScheme="brand" isLoading={loading} type="submit">
              Save
            </Button>
          </Flex>
        </Stack>
      </Flex>
    </form>
  );
};
