import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from "@chakra-ui/react";
import { BridgeBlueprintInput, BridgeBlueprintInputType } from "@zeet/web-ui";
import { useEffect, useMemo, useState } from "react";
import { Path, useFormContext } from "react-hook-form";
import { NewResourceValues } from "../../context";
import InputControl from "./InputControl";
import { getError } from "./utils";

interface GroupInputProps<T> {
  input: BridgeBlueprintInput;
  pathMap: (value: any) => Path<T>;
}

const GroupInput: React.FC<GroupInputProps<NewResourceValues>> = ({
  input,
}) => {
  const isOpenByDefault =
    input.required ||
    input.variables?.some(
      (variable) =>
        variable.default &&
        variable.type === BridgeBlueprintInputType.EnvironmentVariables
    );

  const [isOpen, setIsOpen] = useState(isOpenByDefault);

  const {
    formState: { errors },
  } = useFormContext<NewResourceValues>();

  // all of my child variables
  const myVars = useMemo(() => {
    const getVars = (i: BridgeBlueprintInput): string[] => {
      if (i.variables) return [i.id, ...i.variables.flatMap((v) => getVars(v))];
      return [i.id];
    };

    return getVars(input);
  }, [input]);

  useEffect(() => {
    // do any of my children have errors?
    if (myVars.some((v) => !!errors.variables?.[v]?.message)) {
      setIsOpen(true);
    }
  }, [errors, myVars, isOpen]);

  return (
    <Accordion
      border="1px solid"
      borderColor="inherit"
      allowToggle
      defaultIndex={isOpenByDefault ? [0] : undefined}
      mt="3"
      borderRadius="md"
      index={isOpen ? 0 : []}
      onChange={(i) =>
        setIsOpen(i === 0 || (i instanceof Array && i.includes(0)))
      }
    >
      <AccordionItem border="none">
        <AccordionButton pl="2" pt="4" pb="4">
          <AccordionIcon />
          <Box as="span" flex="1" textAlign="left" fontWeight="medium">
            {input.name}
          </Box>
        </AccordionButton>
        <AccordionPanel py="4" px="7">
          {input.variables?.map((i) => (
            <InputControl key={i.id} input={i} error={getError(errors, i)} />
          ))}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default GroupInput;
