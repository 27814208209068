import {
  Box,
  Button,
  Center,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import {
  CloudResourceType,
  useUserBranchResourcesQuery,
} from "@zeet/web-api/dist/graphql";
import {
  CenterLoading,
  DataTypes,
  IDataRow,
  IZeetData,
  ZError,
} from "@zeet/web-ui";
import React, { useMemo } from "react";
import { Column } from "react-table";
import {
  LIST_VIEW_TABLE_VIEW_TYPE,
  ListView,
  ListViewTable,
} from "../ListViewV2";
import { makeAwsConsoleUrl } from "./util";

const columns: Column<IDataRow>[] = [
  {
    id: "resourceId",
    label: "ID",
    minWidth: 300,
    dataType: DataTypes.LINK,
    options: [],
    order: 1,
  },
  {
    id: "resourceType",
    label: "Type",
    minWidth: 250,
    dataType: DataTypes.MAX_WIDTH_TEXT,
    options: [],
    order: 2,
  },
  {
    id: "shared",
    label: "Shared with other projects",
    minWidth: 220,
    dataType: DataTypes.BOOLEAN,
    options: [],
    order: 4,
  },
];

interface Resource {
  id: string;
  resourceType: CloudResourceType;
  shared: boolean;
  region: string;
}

const makeData = (resources: Resource[]): IZeetData => {
  const newData: IDataRow[] = [];

  resources.map((r) => {
    const row = {
      resourceId: {
        simpleId: "",
        text: r.id,
        link: makeAwsConsoleUrl(r.resourceType, r.id, r.region),
      },
      resourceType: { text: r.resourceType },
      shared: { value: r.shared },
    };

    newData.push(row);
  });

  return {
    data: newData,
    columns,
    skipReset: false,
  };
};

interface Props {
  onClose: () => void;
  branch: string;
  repoId: string;
}

const Resources: React.FC<Props> = ({ onClose, branch, repoId }) => {
  const { data, loading, error } = useUserBranchResourcesQuery({
    variables: { branch, repoId },
    pollInterval: 3000,
    errorPolicy: "none",
  });
  const resources = data?.currentUser?.repo?.branch?.resources;

  const formattedData = useMemo(
    () => resources && makeData(resources),
    [resources]
  );

  return (
    <Modal isOpen onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW={{ base: "50rem", md: "60rem" }} pt={6}>
        <ModalHeader pl={{ base: 8, md: 20, lg: 24 }}>
          AWS Resources for {branch} branch
        </ModalHeader>

        {loading && <CenterLoading />}

        {error && (
          <Center w={"100%"} px={20} py={10}>
            <ZError error={error.message} />
          </Center>
        )}

        {!error && formattedData && (
          <ModalBody w={"100%"}>
            <Box w={"100%"}>
              <ListView
                id="resources"
                filterValue={""}
                formattedData={formattedData}
                columns={columns}
                defaultColumnIds={[
                  "resourceId",
                  "resourceType",
                  "resourceLink",
                  "shared",
                ]}
                handleFilterChange={() => {}}
                defaultViewMode={LIST_VIEW_TABLE_VIEW_TYPE}
              >
                <ListViewTable />
              </ListView>
            </Box>
          </ModalBody>
        )}

        {!formattedData && !loading && (
          <ModalBody w={"100%"}>
            <Center w={"100%"} px={20} py={10}>
              <Text>No resources found</Text>
            </Center>
          </ModalBody>
        )}

        <ModalFooter>
          <Button mr={{ base: 0, md: 16 }} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default Resources;
