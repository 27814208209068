import {
  ArrowBackIcon,
  ArrowForwardIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import {
  Box,
  BoxProps,
  Button,
  Collapse,
  Flex,
  Heading,
  IconButton,
  LinkBox,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  CloudProvider,
  DeploymentCommonFragment,
  DeploymentStatus,
  RepoCommonFragment,
  RepoDetailFragment,
  useAbortBuildMutation,
  useBuildRepoMutation,
  useDeployRepoBranchMutation,
  useProjectActiveBranchesCountQuery,
  useRollbackToDeploymentMutation,
  useUserDeploymentQuery,
  useUserRepoBranchesV2Query,
} from "@zeet/web-api/dist/graphql";
import { ClusterState } from "@zeet/web-api/dist/graphqlv1";
import {
  Container,
  deploymentStatus,
  EmptyBox,
  Link,
  NotFound404,
  StatusBadge,
  useColors,
  useCurrentTeamUser,
  useQueryParams,
  useTrack,
  Button as ZButton,
  ZError,
} from "@zeet/web-ui";
import React, { useMemo, useState } from "react";
import { FaRedo } from "react-icons/fa";
import { GiCheckMark } from "react-icons/gi";
import { useHistory, useParams } from "react-router-dom";
import TimeAgo from "react-timeago";
import { TabWrapper } from ".";
import {
  isSampleProject,
  SAMPLE_PROJECT_STATUS,
} from "../../features/Project/New/makeCreateSampleProjectInput";
import { isKube } from "../utils/project";
import { BranchButton, BranchView } from "./Branches";
import { DeploymentDetailView, SmallDeploymentItem } from "./Deployment";
import { BuildArtifact } from "./Deployment/BuildArtifact";
import { DeploymentAlertsList } from "./Deployment/DeploymentAlertsList";
import { DeploymentsSkeleton } from "./DeploymentsSkeleton";
import Resources from "./Resources";
import { getBuildImagesArray, repoPath } from "./util";

const DeploymentView: React.FC<{
  repo: RepoCommonFragment;
  deployment: DeploymentCommonFragment;
}> = ({ repo, deployment }) => {
  const { bg2 } = useColors();
  const [showTime, setShowTime] = useState<boolean>(false);

  const status = deploymentStatus(repo, deployment);
  const [enabled, setEnabled] = useState<boolean>(true);
  const buildImages = useMemo(
    () =>
      getBuildImagesArray(
        deployment?.build?.image,
        deployment?.alternativeImages
      ),
    [deployment]
  );

  const [rollbackToDeploymentMutation] = useRollbackToDeploymentMutation({
    onCompleted: (data) => {
      setEnabled(true);
      if (data) {
        toast({
          title: "Redeploying older commit",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Unable to rollback",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    },
  });
  const [abortBuildMutation, { loading }] = useAbortBuildMutation({
    onCompleted: (data) => {
      if (data) {
        toast({
          title: "Deployment Cancelled",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    },
  });

  const toast = useToast();
  const { track } = useTrack();
  const [showArtifacts, setShowArtifacts] = useState(false);

  const doRedeploy = () => {
    track("redeploy", { repo_id: repo.id, deployment_id: deployment.id });
    setEnabled(false);
    rollbackToDeploymentMutation({
      variables: {
        projectID: repo.id,
        deploymentID: deployment.id,
      },
    });
  };

  return (
    <LinkBox bg={bg2} p={4} mt={4} shadow="md" borderRadius="md">
      <Flex alignItems="center" justifyContent="space-between" mb={3}>
        <Heading size="md">
          <Flex
            as={Link}
            to={`/${repoPath(repo)}/deployments/${deployment.id}`}
          >
            {deployment?.id?.slice(0, 4) + " ("}
            <Text
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
              maxWidth="30rem"
            >
              {deployment.branch}
            </Text>
            {")"}
          </Flex>
        </Heading>

        <Flex>
          {[
            DeploymentStatus.BuildPending,
            DeploymentStatus.BuildInProgress,
            DeploymentStatus.DeployPending,
            DeploymentStatus.DeployInProgress,
            DeploymentStatus.ReleaseInProgress,
          ].includes(deployment.status) ? (
            <Button
              mr="12px"
              height="auto"
              borderRadius="2px"
              colorScheme="red"
              isLoading={loading}
              onClick={() =>
                abortBuildMutation({
                  variables: {
                    id: deployment.id,
                  },
                })
              }
            >
              {deployment.status === DeploymentStatus.ReleaseInProgress
                ? "Cancel Release"
                : "Cancel Deploy"}
            </Button>
          ) : status.toLowerCase() === "inactive" ||
            status.toLowerCase() === "stopped" ? (
            <>
              <Tooltip
                label="Need to rollback? Redeploy an old deployment, instantly"
                placement="top"
                aria-label=""
              >
                <IconButton
                  icon={<FaRedo />}
                  onClick={() => doRedeploy()}
                  aria-label="redeploy"
                  ml="auto"
                  mr={1}
                  variant="ghost"
                  isDisabled={!enabled}
                />
              </Tooltip>
            </>
          ) : null}
          <StatusBadge
            ml="auto"
            fontSize="md"
            status={isSampleProject(repo.name) ? SAMPLE_PROJECT_STATUS : status}
          />
        </Flex>
      </Flex>
      <SmallDeploymentItem repo={repo} deployment={deployment} />
      {deployment.description && (
        <Flex opacity={0.9} mt={1}>
          {deployment.description}
        </Flex>
      )}
      {deployment.build?.image && (
        <Box>
          <Flex
            onClick={() => setShowArtifacts(!showArtifacts)}
            alignItems="center"
            as="button"
          >
            {showArtifacts ? (
              <ChevronDownIcon fontSize="24px" p={0} ml="-8px" />
            ) : (
              <ChevronRightIcon fontSize="24px" p={0} ml="-8px" />
            )}
            <Text>Artifacts</Text>
          </Flex>
          <Collapse in={showArtifacts}>
            <Box>
              {buildImages.map((bi, i) => (
                <BuildArtifact
                  buildImage={bi}
                  showDockerImage={false}
                  key={i}
                />
              ))}
            </Box>
          </Collapse>
        </Box>
      )}
      <Flex justifyContent="space-between" marginTop={2}>
        <Text
          fontSize="sm"
          onMouseEnter={() => setShowTime(true)}
          onMouseLeave={() => setShowTime(false)}
        >
          {!showTime && "Created"}
          <Box marginLeft={1} as="span">
            {showTime ? (
              new Date(deployment?.createdAt || 0).toLocaleString()
            ) : (
              <TimeAgo date={deployment?.createdAt || 0} />
            )}
          </Box>
        </Text>
        <Text
          fontSize="sm"
          onMouseEnter={() => setShowTime(true)}
          onMouseLeave={() => setShowTime(false)}
        >
          {!showTime && "Updated"}
          <Box marginLeft={1} as="span">
            {showTime ? (
              new Date(deployment?.updatedAt || 0).toLocaleString()
            ) : (
              <TimeAgo date={deployment?.updatedAt || 0} />
            )}
          </Box>
        </Text>
      </Flex>
    </LinkBox>
  );
};

enum DeploymentViewPaths {
  ALERTS = "alerts",
}

const DeploymentViewWithID: React.FC<{
  repo: RepoDetailFragment;
  deploymentID: string;
  path?: DeploymentViewPaths; // optional subpath
}> = ({ repo, deploymentID, path }) => {
  const { data: deploymentData, loading } = useUserDeploymentQuery({
    variables: {
      id: deploymentID,
    },
    errorPolicy: "all",
    pollInterval: 3000,
  });

  if (loading) {
    return <DeploymentsSkeleton />;
  }

  return (
    <Box pb={4} mt={4}>
      {path === DeploymentViewPaths.ALERTS ? (
        deploymentData?.currentUser.deployment && (
          <DeploymentAlertsList
            deployment={deploymentData?.currentUser.deployment}
            backNavLinkTo={"./"}
          />
        )
      ) : (
        <DeploymentDetailView
          repo={repo}
          deployment={deploymentData?.currentUser.deployment}
        />
      )}
    </Box>
  );
};

const UnhealthyClusterModal: React.FC<
  {
    repo: RepoDetailFragment;
    isOpen: boolean;
    onClose: () => void;
    clusterId?: string;
  } & BoxProps
> = ({ isOpen, onClose, repo, clusterId, ...props }) => {
  const history = useHistory();
  const { login: teamLogin } = useCurrentTeamUser();
  const handleClick = () => {
    history.push(`/${teamLogin}/console/clusters/${clusterId ?? "view"}`);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      preserveScrollBarGap={true}
      size="lg"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody pb="8">
          <Container px={8}>
            <EmptyBox
              justifyContent="center"
              alignItems="center"
              paddingBottom="2rem"
              flexDirection="column"
              mr="3"
              border="none"
              {...props}
            >
              {isSampleProject(repo.name) ? (
                <>
                  <Heading size="md" mt="4" textAlign="center">
                    Deployments not available for sample projects
                  </Heading>
                  <Text
                    fontSize="md"
                    mt="4"
                    opacity="0.6"
                    maxW="500px"
                    textAlign="center"
                  >
                    Ready to deploy a real project?
                  </Text>
                  <ZButton variant="primary" mt="6" asLink to="/new">
                    New Project
                  </ZButton>
                </>
              ) : (
                <>
                  <Heading size="md" mt="4">
                    Cluster is not connected
                  </Heading>
                  <Text
                    fontSize="md"
                    mt="4"
                    opacity="0.6"
                    maxW="500px"
                    textAlign="center"
                  >
                    Zeet uses a direct connection to your cluster to deploy your
                    projects, giving you full control over your resources. The
                    current cluster linked to this project is not connected.
                  </Text>
                  <Flex gap="2">
                    <ZButton variant="primary" mt="6" onClick={handleClick}>
                      Setup Cluster
                    </ZButton>
                  </Flex>
                </>
              )}
            </EmptyBox>
          </Container>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const pageItems = 10;

const DeploymentListView: React.FC<{
  repo: RepoDetailFragment;
  branch?: string | null;
}> = ({ repo: repoDetail, branch }) => {
  const toast = useToast();
  const { track } = useTrack();

  const [showResourcesModal, setShowResourcesModal] = useState(false);

  const [buildRepo, { error: buildError }] = useBuildRepoMutation({
    onCompleted: () => {
      toast({
        title: "Redeploy with Build in Progress",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const [deployRepoBranch, { error: deployRepoBranchError }] =
    useDeployRepoBranchMutation({
      onCompleted: () => {
        toast({
          title: "Redeploy Without Build in Progress",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      },
    });

  const [afterCursor, setAfterCursor] = useState("0");

  const { data, loading } = useUserRepoBranchesV2Query({
    variables: {
      id: repoDetail.id,
      branch,
      withBranch: !!branch,
      first: pageItems,
      after: afterCursor,
    },
    pollInterval: 3000,
    errorPolicy: "all",
  });

  const [lastDeployType, setLastDeployType] = useState<string>("");

  const {
    isOpen: isNewCloudOpen,
    onOpen: onNewCloudOpen,
    onClose: onNewCloudClose,
  } = useDisclosure();

  if (buildError) {
    console.error(buildError);
  }

  if (loading) {
    return <DeploymentsSkeleton />;
  }

  const repo = data?.currentUser?.repo;
  const deploymentsConnection =
    repo?.branch?.deployments || repo?.productionBranchV2?.deployments;

  if (!repo) {
    return null;
  }

  const actualBranch = branch || repoDetail.productionBranch;
  const resourcesButtonEnabled =
    !!actualBranch &&
    deploymentsConnection?.nodes?.find((d) => d.deployStatus?.active) &&
    repoDetail.cluster?.cloudProvider?.includes(CloudProvider.Aws);

  const handleUnhealthyClusterAction = (callback: () => void) => {
    if (!isKube(repoDetail)) {
      callback();
      return;
    }

    if (
      repoDetail.cluster?.state !== ClusterState.Healthy ||
      !repoDetail.cluster.connected
    ) {
      onNewCloudOpen();
      return;
    }

    callback();
  };

  return (
    <Box pb={4} mt={4}>
      {showResourcesModal && (
        <Resources
          repoId={repoDetail.id}
          branch={actualBranch as string}
          onClose={() => setShowResourcesModal(false)}
        />
      )}
      <UnhealthyClusterModal
        repo={repoDetail}
        isOpen={isNewCloudOpen}
        onClose={onNewCloudClose}
        clusterId={repoDetail.cluster?.id}
      />
      <Flex mb={2} justifyContent="space-between">
        <Flex gap={4}>
          <Menu>
            <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
              {`Deploy [${actualBranch}] Branch`}
            </MenuButton>
            <MenuList zIndex={2}>
              <MenuItem
                onClick={() => {
                  handleUnhealthyClusterAction(() =>
                    buildRepo({
                      variables: {
                        id: repo.id,
                        branch: branch,
                        noCache: true,
                      },
                    })
                  );
                  setLastDeployType("dr");
                }}
              >
                <Flex>
                  <Flex
                    width="12px"
                    justifyContent="center"
                    alignItems="center"
                    mr="8px"
                  >
                    {lastDeployType === "dr" && (
                      <GiCheckMark color="var(--brand)" />
                    )}
                  </Flex>{" "}
                  <Text>build from scratch</Text>
                </Flex>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleUnhealthyClusterAction(() =>
                    buildRepo({
                      variables: {
                        id: repo.id,
                        branch: branch,
                        noCache: false,
                      },
                    })
                  );
                  setLastDeployType("drc");
                }}
              >
                <Flex>
                  <Flex
                    width="12px"
                    justifyContent="center"
                    alignItems="center"
                    mr="8px"
                  >
                    {lastDeployType === "drc" && (
                      <GiCheckMark color="var(--brand)" />
                    )}{" "}
                  </Flex>
                  <Text>build with cache</Text>
                </Flex>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleUnhealthyClusterAction(() =>
                    deployRepoBranch({
                      variables: {
                        input: {
                          id: repo.id,
                          branch:
                            branch || (repoDetail.productionBranch as string),
                        },
                      },
                    })
                  );
                  setLastDeployType("d");
                }}
              >
                <Flex>
                  <Flex
                    width="12px"
                    justifyContent="center"
                    alignItems="center"
                    mr="8px"
                  >
                    {lastDeployType === "d" && (
                      <GiCheckMark color="var(--brand)" />
                    )}{" "}
                  </Flex>{" "}
                  <Text>
                    {repoDetail.manualDeploy ? "deploy changes" : "restart"}
                  </Text>
                </Flex>
              </MenuItem>
            </MenuList>
          </Menu>
          {repoDetail.cluster?.cloudProvider?.includes(CloudProvider.Aws) && (
            <Tooltip
              label={`This feature requires an active deployment on branch ${branch}`}
              isDisabled={resourcesButtonEnabled}
              shouldWrapChildren
            >
              <Button
                isDisabled={!resourcesButtonEnabled}
                onClick={() => {
                  setShowResourcesModal(true);
                  track("click_view_aws_resources");
                }}
              >
                View AWS Resources
              </Button>
            </Tooltip>
          )}
        </Flex>

        <BranchButton repo={repoDetail} />
      </Flex>
      <ZError error={deployRepoBranchError || buildError} />
      {deploymentsConnection?.nodes?.length ? (
        <Stack spacing={4}>
          {deploymentsConnection?.nodes?.map((item) => (
            <DeploymentView key={item.id} repo={repoDetail} deployment={item} />
          ))}
        </Stack>
      ) : (
        <Box my={2}>No deployments yet</Box>
      )}

      <Flex justifyContent="space-between" mt="32px">
        {deploymentsConnection?.pageInfo.hasPreviousPage ? (
          <Button
            onClick={() => {
              setAfterCursor((Number(afterCursor) - pageItems).toString());
            }}
          >
            <ArrowBackIcon />
            <Text ml="16px">Back</Text>
          </Button>
        ) : (
          <Box />
        )}
        {deploymentsConnection?.pageInfo.hasNextPage ? (
          <Button
            onClick={() => {
              setAfterCursor((Number(afterCursor) + pageItems).toString());
            }}
          >
            <Text mr="16px">Next</Text>
            <ArrowForwardIcon />
          </Button>
        ) : (
          <Box />
        )}
      </Flex>
    </Box>
  );
};

export const DeploymentsTab = () => {
  return <TabWrapper component={Deployments} />;
};

const Deployments: React.FC<{
  repo: RepoDetailFragment;
}> = ({ repo: repoDetail }) => {
  const { subTab, path: extraPath } = useParams<{
    subTab: string;
    path: string;
  }>();

  const qs = useQueryParams();
  const branch = qs.get("branch");

  const { data, loading } = useProjectActiveBranchesCountQuery({
    variables: {
      id: repoDetail.id,
    },
  });

  if (loading) {
    return <DeploymentsSkeleton />;
  }

  if (!data?.currentUser.repo) {
    return null;
  }

  const branchCount = data?.currentUser.repo?.branchesV2?.totalCount || 1;

  if (subTab && subTab !== "all" && subTab !== "branches") {
    //if a deploymentID is provided as a sub tab, open that deployment
    const path = extraPath as DeploymentViewPaths;
    if (path && !Object.values(DeploymentViewPaths).includes(path)) {
      return (
        <NotFound404
          heading="404, page not found"
          text="Oops! We couldn't find the page you're looking for."
        />
      );
    } else {
      return (
        <DeploymentViewWithID
          repo={repoDetail}
          deploymentID={subTab}
          path={path}
        />
      );
    }
  }

  if (subTab === "branches" || (!subTab && !branch && branchCount > 1)) {
    //if explicitly navigated to /branches, or (no route info is provided and the repo has multiple running branches) open branch view
    return <BranchView repo={repoDetail} />;
  }

  return <DeploymentListView repo={repoDetail} branch={branch} />;
};
