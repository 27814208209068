import { Text } from "@chakra-ui/react";
import { ParsedSpan } from "ansicolor";
import stripAnsi from "strip-ansi";
import { extractColor } from "./util";

interface ParsedSpanViewProps {
  span: ParsedSpan;
  themeColors: Record<string, string>;
}

export const ParsedSpanView = ({
  span: { bold, text, bgColor, color, italic },
  themeColors,
}: ParsedSpanViewProps) => {
  return (
    <Text
      as="span"
      fontWeight={bold ? "bold" : ""}
      fontStyle={italic ? "italic" : ""}
      color={extractColor(color, themeColors)}
      backgroundColor={extractColor(bgColor, themeColors)}
    >
      {stripAnsi(text)}
    </Text>
  );
};
