import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Switch,
  Text,
} from "@chakra-ui/react";
import { Button, useColors } from "@zeet/web-ui";
import React from "react";
import { useListViewContext } from "./Provider";

export const ListViewTableColumns: React.FC = () => {
  const { columns, propertyState, showAllColumns, toggleMenuItem } =
    useListViewContext();
  const { bg } = useColors();

  return (
    <Menu closeOnSelect={false}>
      <MenuButton
        variant="secondary"
        as={Button}
        rightIcon={<ChevronDownIcon />}
        flexShrink={0}
        fontSize="14px"
      >
        Columns
      </MenuButton>

      <MenuList maxHeight="70vh" zIndex="2" overflowY="auto" fontSize="12px">
        <MenuItem
          display="flex"
          justifyContent="flex-end"
          onClick={showAllColumns}
        >
          <Text textDecoration="underline">Show All</Text>
        </MenuItem>

        <MenuDivider />

        {columns.map((element) => (
          <MenuItem
            display="flex"
            justifyContent="space-between"
            flexDirection="row-reverse"
            cursor="pointer"
            key={element.id}
            as={Switch}
            isChecked={propertyState[element.id]}
            _hover={{ background: bg }}
            onChange={() => toggleMenuItem(element.id)}
          >
            {element.label}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
