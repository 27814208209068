import { Flex, Icon } from "@chakra-ui/react";
import { Blueprint } from "@zeet/web-api/dist/graphql";
import { FC } from "react";
import { MdVerified } from "react-icons/md";

export const BlueprintOwner: FC<{ blueprint?: Blueprint }> = ({
  blueprint,
}) => {
  return (
    <Flex alignItems="center">
      {blueprint?.isOfficial && <Icon as={MdVerified} mr="1" />}
      <Flex>{blueprint?.owner?.name}</Flex>
    </Flex>
  );
};
