import {
  EnvironmentEnvironmentVariablesQuery,
  EnvVar,
} from "@zeet/web-api/dist/graphql";
import update from "immutability-helper";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";
import { useParseEnv } from "~/hooks/useParseEnv";

type ReducerAction =
  | {
      type: "add";
    }
  | { type: "addPopulate"; envs: Array<{ key: string; value: string }> }
  | { type: "delete"; index: number }
  | { type: "seal"; index: number }
  | { type: "change"; index: number; key: string; value: string }
  | {
      type: "set";
      envs?:
        | Array<
            Pick<EnvVar, "id" | "name" | "value" | "visible"> | null | undefined
          >
        | null
        | undefined;
    };

interface EnvVarSettingsContextValues {
  envs: EnvVar[];
  changed: boolean;
  environmentVariables?: EnvironmentEnvironmentVariablesQuery["project"];
}

interface EnvVarSettingsContextActions {
  dispatch: React.Dispatch<ReducerAction>;
  setChanged: (status: boolean) => void;
  parseEnv(e: React.ChangeEvent<HTMLInputElement> | string): void;
}

type EnvVarSettingsContext = [
  EnvVarSettingsContextValues,
  EnvVarSettingsContextActions
];

const noop = () => null;

const envVarSettingsContext = createContext<EnvVarSettingsContext>([
  // default values
  {
    envs: [],
    changed: false,
  },
  {
    setChanged: noop,
    dispatch: noop,
    parseEnv: noop,
  },
]);

export const EnvVarSettingsProvider: React.FC<{
  children?: React.ReactNode;
  envsData: NonNullable<
    EnvironmentEnvironmentVariablesQuery["project"]
  >["envs"];
}> = ({ children, envsData }) => {
  const [environmentVariables] = useState<
    EnvironmentEnvironmentVariablesQuery["project"] | undefined
  >();
  const [changed, setChanged] = useState(false);

  const [envs, dispatch] = useReducer((envs, action: ReducerAction) => {
    const newBase = () => ({
      id: uuidv4(),
      name: "",
      value: "",
      sealed: false,
      visible: true,
    });
    switch (action.type) {
      case "add": {
        return update(envs, { $push: [newBase()] });
      }
      case "delete": {
        setChanged(true);
        if (envs.length === 1) {
          return update(envs, { $set: [newBase()] });
        }
        return update(envs, { $splice: [[action.index, 1]] });
      }
      case "seal": {
        setChanged(true);
        const newSealed = !envs[action.index].sealed;
        return update(envs, {
          [action.index]: {
            visible: { $set: !newSealed },
            sealed: { $set: newSealed },
          },
        });
      }
      case "change": {
        setChanged(true);
        return update(envs, {
          [action.index]: { [action.key]: { $set: action.value } },
        });
      }
      case "set": {
        return update(envs, {
          $set:
            action.envs && action.envs?.length > 0 ? action.envs : [newBase()],
        });
      }
      case "addPopulate": {
        return update(envs, {
          $unshift: action.envs.map((env) => {
            return {
              id: uuidv4(),
              name: env.key,
              value: env.value,
              visible: true,
            };
          }),
        });
      }
    }
  }, []);

  const onEnv = useCallback(
    (envs: { key: string; value: string }[]) => {
      setChanged(true);
      dispatch({
        type: "addPopulate",
        envs,
      });
    },
    [dispatch]
  );
  const { parseEnv } = useParseEnv(onEnv);

  useEffect(() => {
    dispatch({ type: "set", envs: envsData });
  }, [envsData, dispatch]);

  const actions: EnvVarSettingsContextActions = useMemo(
    () => ({
      dispatch: (reducerAction: ReducerAction) => {
        dispatch(reducerAction);
      },
      setChanged: (status: boolean) => {
        setChanged(status);
      },
      parseEnv,
    }),
    [parseEnv]
  );

  return (
    <envVarSettingsContext.Provider
      value={[
        {
          environmentVariables,
          envs,
          changed,
        },
        actions,
      ]}
    >
      {children}
    </envVarSettingsContext.Provider>
  );
};

export function useEnvVarSettingsContext(): EnvVarSettingsContext {
  return useContext(envVarSettingsContext);
}
