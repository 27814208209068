import { Checkbox, Text, useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import { DeleteAction } from "../DeleteAction";
import { DeleteDialog } from "../DeleteDialog";
interface DestroyAction {
  name: string;
  onAction(force: boolean): Promise<void>;
}

export const DestroyAction = ({ onAction, name }: DestroyAction) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [force, setForce] = useState(false);

  return (
    <DeleteAction
      title="Destroy this Project"
      message="Destroy all cloud resources for this project, and remove project from Zeet. This cannot be undone."
      action="Destroy Project"
      onAction={onOpen}
    >
      <DeleteDialog
        onClose={onClose}
        onDelete={() => onAction(force)}
        isLoading={false}
        isOpen={isOpen}
        type="project"
        action={{ pastTense: "destroyed", presentTense: "destroy" }}
        actionMessage="Destroy this project"
        warningMessage={
          <Text>
            This will attempt to permanently destroy the cloud resources related
            to the{" "}
            <Text fontWeight={"bold"} as="span">
              {name}
            </Text>{" "}
            project and remove the project from Zeet. This action cannot be
            undone.
          </Text>
        }
        confirmationString={name}
      >
        <Checkbox
          onChange={(e) => setForce(e.target.checked)}
          checked={force}
          mt={4}
        >
          Force destroy project even if cloud resources can&apos;t be destroyed
        </Checkbox>
      </DeleteDialog>
    </DeleteAction>
  );
};
