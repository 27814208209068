import { useQueryParams } from "@zeet/web-ui";
import { ProjectDetailTabProps } from "..";
import { BackNavLayout } from "../components/BackNavLayout";
import { useProjectPath } from "../hooks/useProjectPath";
import { BuildDetail } from "./BuildDetail";
import { BuildList } from "./BuildList";

const BuildTab = ({ project }: ProjectDetailTabProps) => {
  const query = useQueryParams();
  const runId = query.get("runId");
  const path = useProjectPath();
  if (!runId) return <BuildList projectId={project.id} />;

  return (
    <BackNavLayout linkText="Back to builds" linkTo={`${path}/builds`}>
      <BuildDetail projectId={project.id} runId={runId} />
    </BackNavLayout>
  );
};

export default BuildTab;
