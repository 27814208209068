import {
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Icon,
  Image,
  ListItem,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useHelmChartQuery } from "@zeet/web-api/dist/graphql";
import { ComboBox, FormLabel, Input, Link, useColors } from "@zeet/web-ui";
import { useEffect } from "react";
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from "react-hook-form";
import { MdLaunch } from "react-icons/md";
import { SiHelm } from "react-icons/si";
import { useClusterParams } from "~/components/Console/ConsoleClusters/Details/useClusterParams";
import { SourceTabPanelProps } from ".";
import { SourceKind } from "..";

const makeSourceFromHelmRepository = (
  repositoryUrl: string,
  chart: string,
  version?: string
) => {
  return {
    kind: SourceKind.HelmRepository,
    helmRepository: {
      repositoryUrl,
      chart,
      version,
    },
  };
};

export const HelmRepositorySource = (props: SourceTabPanelProps) => {
  const query = useClusterParams();

  const { bg } = useColors();
  const methods = useForm({
    defaultValues: {
      repositoryUrl: query.repositoryUrl,
      chart: query.chart,
      version: query.version,
    },
  });

  const {
    register,
    formState: { errors },
    watch,
  } = methods;

  const { repositoryUrl, chart, version } = watch();

  useEffect(() => {
    if (repositoryUrl && chart) {
      props.onSourceChange(
        makeSourceFromHelmRepository(repositoryUrl, chart, version)
      );
    }
  }, [repositoryUrl, chart, version, props]);

  return (
    <FormProvider {...methods}>
      <Stack mt="4" gap="4">
        <FormControl isInvalid={!!errors.repositoryUrl?.message} isRequired>
          <Flex alignItems="center" justifyContent="space-between">
            <FormLabel>URL to public Helm repository</FormLabel>
            <Link
              to="https://docs.zeet.co/reference/application/helm/"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              fontSize="sm"
              opacity={0.8}
            >
              Learn more <Icon as={MdLaunch} ml="1" />
            </Link>
          </Flex>
          <Input
            placeholder="https://grafana.github.io/helm-charts"
            _placeholder={{ opacity: 0.5 }}
            bg={bg}
            data-testid="helm-public-repository-input"
            {...register("repositoryUrl", {
              validate: {
                requiredWhenPublic: (value) => {
                  if (!value) {
                    return "Helm repository URL is required";
                  }
                  return true;
                },
              },
            })}
          />
          <FormErrorMessage>{errors.repositoryUrl?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.chart?.message} isRequired>
          <FormLabel mb="0">Chart name</FormLabel>
          <HelmChartCombobox />
          <FormErrorMessage mt="0">{errors.chart?.message}</FormErrorMessage>
        </FormControl>
        <FormControl mt="-2">
          <FormLabel>Chart version</FormLabel>
          <Input
            placeholder="1.0.0"
            _placeholder={{ opacity: 0.5 }}
            bg={bg}
            {...register("version")}
          />
          <FormErrorMessage mt="0">{errors.version?.message}</FormErrorMessage>
        </FormControl>
      </Stack>
    </FormProvider>
  );
};

const HelmChartCombobox = () => {
  const { brand } = useColors();
  const { getValues, control, watch } = useFormContext();
  const { data, loading } = useHelmChartQuery({
    variables: {
      url: watch("repositoryUrl"),
      name: watch("chart"),
    },
  });

  return (
    <Controller
      name="chart"
      control={control}
      rules={{
        validate: {
          requiredWhenPublic: async (value) => {
            if (getValues("chart")) {
              return true;
            } else if (!value) {
              return "Helm chart is required";
            } else if (!data?.helmRepository.chart.name) {
              return "Helm chart not found";
            }
            return true;
          },
        },
      }}
      render={({ field: { onChange, onBlur, value } }) => (
        <ComboBox
          isPrivateRegistry={false}
          inputProps={{
            placeholder: "grafana",
            _placeholder: { opacity: 0.5 },
          }}
          isRelative
          renderListItem={(item, props, isHighlighted) => {
            return (
              <ListItem
                key={item?.id}
                display="flex"
                justifyContent="flex-start"
                bg={isHighlighted ? brand : ""}
                color={isHighlighted ? "white" : ""}
                px={4}
                mx={-2}
                my={2}
                py={2}
                cursor="pointer"
                borderRadius="md"
                transition="background-color 150ms, color 150ms"
                {...props}
              >
                <Flex flexShrink={0} mr={8}>
                  {data?.helmRepository.chart.logoImage ? (
                    <Image
                      src={data?.helmRepository.chart.logoImage}
                      w="2.5rem"
                      h="2.5rem"
                    />
                  ) : (
                    <SiHelm size="2.5rem" />
                  )}
                </Flex>
                <Flex direction="column" flexShrink={1}>
                  <Heading maxW="100%" noOfLines={1} size="sm" as="h4">
                    {data?.helmRepository.chart.name}
                  </Heading>
                  {data?.helmRepository.chart.description && (
                    <Text maxW="100%" noOfLines={2} fontSize="sm">
                      {data?.helmRepository.chart.description}
                    </Text>
                  )}
                </Flex>
              </ListItem>
            );
          }}
          inputValue={value}
          items={data?.helmRepository.chart ? [data?.helmRepository.chart] : []}
          isLoading={loading}
          onInputValueChange={({ inputValue }) => {
            onChange(inputValue);
          }}
          onSelectedItemChange={({ selectedItem }) => {
            onChange(selectedItem?.name ?? "");
            onBlur();
          }}
          itemToString={(item) => item?.name ?? ""}
        />
      )}
    />
  );
};
