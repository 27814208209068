import {
  Container,
  Pageview,
  RequireAuth,
  useAnalyticsUser,
  useCurrentTeamUser,
  useIsLoggedIn,
} from "@zeet/web-ui";
import React, { lazy, useEffect } from "react";
import { Redirect, Route, Switch, useParams } from "react-router-dom";
import { makeCreateResourceRedirect } from "../features/Project/New/redirect";
import { Close } from "./Close";
import { ConfirmationPage } from "./ConfirmationPage";
import { ErrorPage } from "./ErrorPage";
import { JobView } from "./Job";
import { LoginPage } from "./Login";
import { ProjectRouter } from "./Project/Project/Router";
import {
  LegacyRepoDeploymentIdRedirect,
  LegacyRepoIdRedirect,
} from "./Project/Redirect";
import BodyWrapper from "./Sidebar/BodyWrapper";
import { SignupPage } from "./Signup";
import Support from "./Support";
import { RequireTeam } from "./Team/redirect";

const Billing = lazy(() => import("./Billing"));
const TeamInvite = lazy(() => import("./Team/Invite"));
const AccountPage = lazy(() => import("./Account"));
const ProfilePage = lazy(() => import("./Profile"));
const CareersPage = lazy(() => import("./Careers"));
const Console = lazy(() => import("./Console"));
const NewProjectPage = lazy(() => import("../features/Project/New"));
const BillingUpgradeWrapper = lazy(() => import("./Account/BillingV2/Upgrade"));

const getNewResourceWorkflow = (location: Location) => {
  const redirect = makeCreateResourceRedirect(location);
  return (
    <Route exact path={["/new", "/new/:owner", "/new/:owner/:slug+"]}>
      <RequireTeam>
        <BodyWrapper showSidebar={true}>
          <>
            {redirect && <Redirect to={redirect} />}
            <Pageview pageName="New Project" />
            <NewProjectPage />
          </>
        </BodyWrapper>
      </RequireTeam>
    </Route>
  );
};

const OldReferrerRedirect: React.FC = () => {
  const { referrer } = useParams<{ referrer: string }>();
  return <Redirect to={{ search: `?r=${referrer}` }} />;
};

const DashboardRedirect: React.FC = () => {
  const currentTeamUser = useCurrentTeamUser();
  const { teamName } = useParams<{ teamName?: string }>();
  const isUserLoggedIn = useIsLoggedIn();

  if ((teamName || currentTeamUser) && isUserLoggedIn) {
    return (
      <Redirect
        to={{
          pathname: teamName
            ? `/${teamName}/console/home`
            : `/${currentTeamUser.login}/console/home`,
        }}
      />
    );
  }
  return <Redirect to="/" />;
};

// redirects /console/tab --> /my-team/console/tab
const TeamlessConsoleRedirect: React.FC = () => {
  const currentTeamUser = useCurrentTeamUser();
  const { tab } = useParams<{ tab?: string }>();

  if (currentTeamUser) {
    return (
      <Redirect
        to={{
          pathname: `/${currentTeamUser.login}/console/${tab}${window.location.search}`,
        }}
      />
    );
  }

  return <Redirect to="/" />;
};

const RedirectToContactForm: React.FC = () => {
  useEffect(() => {
    window.location.href = "https://zeet.co/contact";
  }, []);
  return null;
};

const RootRedirect: React.FC = () => {
  const isLoggedIn = useIsLoggedIn();

  if (isLoggedIn) {
    return <Redirect to={`/dashboard`} />;
  }
  return <Redirect to="/login" />;
};

/*
  reserved top level names:
  - account
  - admin
  - api
  - billing
  - careers
  - close
  - cloud-onboard
  - dashboard
  - error
  - help
  - login
  - new
  - new-team
  - privacy
  - profile
  - project
  - r
  - repo
  - saas-pricing
  - signup
  - team
  - tos

  reserved team names
  - new
  - onboard
*/

export const Routes: React.FC = () => {
  useAnalyticsUser();

  return (
    <Switch>
      <Route path="/team-onboard">
        <RedirectToContactForm />
      </Route>
      <Route exact path="/team/:name/invite/:token">
        <BodyWrapper showSidebar={false}>
          <Container>
            <Pageview pageName="Team Invite" />
            <TeamInvite />
          </Container>
        </BodyWrapper>
      </Route>
      <Route exact path="/close">
        <Close />
      </Route>
      <Route exact path="/error">
        <BodyWrapper showSidebar={false}>
          <ErrorPage />
        </BodyWrapper>
      </Route>
      <Route exact path="/~/success">
        <BodyWrapper showSidebar={false}>
          <ConfirmationPage />
        </BodyWrapper>
      </Route>
      <Route exact path="/~/error">
        <BodyWrapper showSidebar={false}>
          <ErrorPage />
        </BodyWrapper>
      </Route>
      <Route exact path="/login">
        <BodyWrapper showSidebar={false}>
          <Pageview pageName="Login" />
          <LoginPage />
        </BodyWrapper>
      </Route>
      <Route exact path="/signup">
        <BodyWrapper showSidebar={false}>
          <Pageview pageName="Signup" />
          <SignupPage />
        </BodyWrapper>
      </Route>
      <Route path="/careers">
        <Pageview pageName="Careers" />
        <CareersPage />
      </Route>
      <Route exact path="/r/:referrer">
        <OldReferrerRedirect />
      </Route>
      <Route exact path="/">
        <Pageview pageName="Home" />
        <Container>
          <RootRedirect />
        </Container>
      </Route>
      <Route exact path="/profile">
        <RequireAuth>
          <Pageview pageName="Profile Page" />
          <BodyWrapper showSidebar={true}>
            <ProfilePage />
          </BodyWrapper>
        </RequireAuth>
      </Route>
      <Route path="/repo/:id/deployments/:deploymentId">
        <RequireAuth>
          <LegacyRepoDeploymentIdRedirect />
        </RequireAuth>
      </Route>
      <Route path="/repo/:id">
        <RequireAuth>
          <LegacyRepoIdRedirect />
        </RequireAuth>
      </Route>
      <Route exact path={["/job/:id", "/:teamName/job/:id"]}>
        <RequireAuth>
          <Pageview pageName="Job" />
          <BodyWrapper showSidebar={true}>
            <JobView />
          </BodyWrapper>
        </RequireAuth>
      </Route>
      <Route exact path="/billing">
        <RequireTeam>
          <Container>
            <Pageview pageName="Billing" />
            <Billing />
          </Container>
        </RequireTeam>
      </Route>
      <Route path={["/:teamName/support"]}>
        <RequireTeam>
          <BodyWrapper showSidebar={true}>
            <Support />
          </BodyWrapper>
        </RequireTeam>
      </Route>
      {getNewResourceWorkflow(location)}
      <Route
        path={[
          "/account/billing/upgrade",
          "/:teamName/account/billing/upgrade",
        ]}
      >
        <RequireTeam>
          <BodyWrapper showSidebar={true}>
            <Pageview pageName="Billing Upgrade" />
            <BillingUpgradeWrapper />
          </BodyWrapper>
        </RequireTeam>
      </Route>
      <Route
        path={["/account", "/:teamName/account", "/:teamName/account/:tab+"]}
      >
        <RequireTeam>
          <BodyWrapper showSidebar={true}>
            <Pageview pageName="Account" />
            <AccountPage />
          </BodyWrapper>
        </RequireTeam>
      </Route>
      <Route path={["/:teamName/console/:tab"]}>
        <RequireTeam>
          <BodyWrapper showSidebar={true}>
            <Console />
          </BodyWrapper>
        </RequireTeam>
      </Route>
      <Route path={["/console/:tab+"]}>
        <RequireTeam>
          <TeamlessConsoleRedirect />
        </RequireTeam>
      </Route>
      <Route path="/:teamName/:projectName">
        <RequireTeam>
          <BodyWrapper showSidebar={true}>
            <ProjectRouter />
          </BodyWrapper>
        </RequireTeam>
      </Route>
      <Route exact path={["/dashboard", "/:teamName"]}>
        <RequireTeam>
          <DashboardRedirect />
        </RequireTeam>
      </Route>
      <Redirect to="/" />
    </Switch>
  );
};
