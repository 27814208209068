import { AnalyticsEvent, Button, useTrack } from "@zeet/web-ui";
import { useHistory } from "react-router-dom";

interface JsonModalCellProps {
  onOpenFunc: () => unknown;
  modalLink: string;
  awsAccountId: string;
  awsUsername: string;
  awsPolicyName: string;
  arn: string;
}

export const JsonModalCell = ({
  onOpenFunc,
  modalLink,
  awsAccountId,
  awsUsername,
  awsPolicyName,
  arn,
}: JsonModalCellProps) => {
  const history = useHistory();
  const { track } = useTrack();
  return (
    <Button
      variant="primary"
      size="sm"
      onClick={() => {
        onOpenFunc();
        history.push(
          `${modalLink}&aws_account_id=${awsAccountId}&aws_username=${awsUsername}&aws_policy_name=${awsPolicyName}&arn=${arn}`
        );
        track(AnalyticsEvent.VIEW_PERMISSIONS_JSON);
      }}
    >
      View
    </Button>
  );
};
