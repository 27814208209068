import { Flex, Spacer, TextProps } from "@chakra-ui/react";
import { DetailItem, DetailLabel, theme } from "@zeet/web-ui";
import { StatusText } from "./StatusText";

interface StatusDetailItemProps extends React.PropsWithChildren<TextProps> {
  statusInfo: { themeColor?: theme.StatusColor; text?: string };
}

export const StatusDetailItem = ({
  statusInfo,
  children,
  ...rest
}: StatusDetailItemProps) => {
  return (
    <DetailItem>
      <DetailLabel>Status</DetailLabel>
      <Flex alignItems="center">
        <StatusText
          {...rest}
          color={statusInfo?.themeColor}
          text={statusInfo?.text}
        />
        <Spacer />
        {children}
      </Flex>
    </DetailItem>
  );
};
