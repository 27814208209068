import { Input, InputProps } from "@chakra-ui/react";
import React, { useState } from "react";
import "./Settings.scss";

export const CoolInput = React.forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    const [focus, setFocus] = useState(false);
    const [hover, setHover] = useState(false);
    return (
      <Input
        ref={ref}
        type={(focus || hover) && !props.isDisabled ? "text" : "password"}
        onFocus={() => {
          setFocus(true);
        }}
        onBlur={() => {
          setFocus(false);
        }}
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
        {...props}
      />
    );
  }
);
