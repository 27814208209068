import startCase from "lodash/startCase";

const formatAsStartCase = (str: string): string => {
  return startCase(str);
};

const formatAsSlug = (str: string) => {
  return str
    .toLowerCase()
    .replace(/[^a-z0-9 -]/g, "")
    .replace(/\s+/g, "-");
};

const formatMillisecondsAsTiming = (milliseconds: number): string => {
  const seconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  const h = hours > 0 ? `${hours}h ` : "";
  const m = minutes % 60 > 0 ? `${minutes % 60}m ` : "";
  const s = seconds % 60 > 0 ? `${seconds % 60}s` : "";

  return `${h}${m}${s}`.trim() || "0s";
};
export { formatAsStartCase, formatAsSlug, formatMillisecondsAsTiming };
