import {
  CloudProvider,
  useCloudDetailsAwsQuery,
  useCloudDetailsAzureQuery,
  useCloudDetailsCoreweaveQuery,
  useCloudDetailsDoQuery,
  useCloudDetailsGcpQuery,
  useCloudDetailsLinodeQuery,
  useCloudDetailsVultrQuery,
} from "@zeet/web-api/dist/graphql";

const pollInterval = 3000;

export type supportedCloudProviders = keyof typeof queries;

export type queryResults = NonNullable<
  ReturnType<(typeof queries)[supportedCloudProviders]>["data"]
>;

export const queries = {
  [CloudProvider.Aws]: ({ id, cloudId }) =>
    useCloudDetailsAwsQuery({
      variables: {
        id: id,
        accountId: cloudId,
      },
      pollInterval,
    }),
  [CloudProvider.Gcp]: ({ id, cloudId }) =>
    useCloudDetailsGcpQuery({
      variables: {
        id: id,
        accountId: cloudId,
      },
      pollInterval,
    }),
  [CloudProvider.Do]: ({ id, cloudId }) =>
    useCloudDetailsDoQuery({
      variables: {
        id: id,
        accountId: cloudId,
      },
      pollInterval,
    }),
  [CloudProvider.Coreweave]: ({ id, cloudId }) =>
    useCloudDetailsCoreweaveQuery({
      variables: {
        id: id,
        accountId: cloudId,
      },
      pollInterval,
    }),
  [CloudProvider.Linode]: ({ id, cloudId }) =>
    useCloudDetailsLinodeQuery({
      variables: {
        id: id,
        accountId: cloudId,
      },
      pollInterval,
    }),
  [CloudProvider.Vultr]: ({ id, cloudId }) =>
    useCloudDetailsVultrQuery({
      variables: {
        id: id,
        accountId: cloudId,
      },
      pollInterval,
    }),
  [CloudProvider.Azure]: ({ id, cloudId }) =>
    useCloudDetailsAzureQuery({
      variables: {
        id: id,
        accountId: cloudId,
      },
      pollInterval,
    }),
};
