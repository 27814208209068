import { Flex, Spacer, Tabs, useDisclosure } from "@chakra-ui/react";
import {
  Button,
  Container,
  useCurrentTeamUser,
  useQueryParams,
} from "@zeet/web-ui";
import { useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { ProjectListView } from "~/components/Dashboard/ProjectListView";
import { GroupTitle } from "~/components/GroupTitle";
import { DetailHeader } from "~/components/Layouts/DetailHeader";
import { useCurrentTabIndex } from "~/components/Layouts/useCurrentTabIndex";
import { ProjectCountBadge } from "~/components/ProjectCountBadge";
import { NavTabPanels, NavTabType } from "../../Layouts/NavTab";
import { CreateProjectEnvironmentModal } from "../ProjectEnvironment";
import { useProject } from "../Provider";
import { projectPath } from "../util";
import AccessControl from "./AccessControl";
import Revisions from "./Revisions";
import Settings from "./Settings";

const Environments = () => {
  const teamUser = useCurrentTeamUser();
  const query = useQueryParams();
  const shouldCreate = query.get("create");
  const history = useHistory();
  const project = useProject();

  const { onOpen, isOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (!isOpen && shouldCreate) onOpen();
  }, [shouldCreate, isOpen, onOpen]);

  // routeKey is the string following the _underscore in the path
  // routeKey is used to identify tabs which live at a subpath relative to projectPath
  const tabs = useMemo(
    (): NavTabType[] => [
      {
        label: (
          <Flex alignItems="center" gap={2}>
            Projects
            <ProjectCountBadge />
          </Flex>
        ),
        to: `/${projectPath(project)}`,
        component: () => (
          <>
            <Spacer mt="4" />
            <ProjectListView
              actions={
                <Button variant="primary" onClick={onOpen}>
                  New Sub-Group
                </Button>
              }
            />
          </>
        ),
      },
      {
        label: "Access Control",
        routeKey: "access",
        to: `/${projectPath(project)}/_access`,
        component: () => <AccessControl project={project} />,
      },
      {
        label: "Revisions",
        routeKey: "revisions",
        to: `/${projectPath(project)}/_revisions`,
        component: () => <Revisions project={project} />,
      },
      {
        label: "Settings",
        routeKey: "settings",
        to: `/${projectPath(project)}/_settings`,
        path: [
          `/${projectPath(project)}/_settings`,
          `/${projectPath(project)}/_settings/:tab`,
        ],
        component: () => <Settings project={project} />,
      },
    ],
    [onOpen, project]
  );

  const currentTabIndex = useCurrentTabIndex(tabs);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    params.delete("create");
    history.replace({
      search: params.toString(),
    });
  }, [history]);

  return (
    <Tabs index={currentTabIndex} colorScheme="brand" isLazy>
      <CreateProjectEnvironmentModal
        project={project}
        isOpen={isOpen}
        onClose={onClose}
        teamName={teamUser.login}
      />
      <DetailHeader
        title={<GroupTitle name={project.name} />}
        tabs={tabs}
        fullWidth
      />
      <Container fullWidth>
        <NavTabPanels tabs={tabs} />
      </Container>
    </Tabs>
  );
};

export default Environments;
