import { DeleteIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  IconButton,
  Input,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import {
  KubernetesSecretMountInput,
  RepoDetailFragment,
  useUpdateProjectSettingsMutation,
} from "@zeet/web-api/dist/graphql";
import { CopyableText } from "@zeet/web-ui";
import React, { useEffect, useMemo, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { FormCard } from "../../../FormCard";
import { shouldDisplayError } from "../../util";

export const SecretMountSettings: React.FC<{
  repo: RepoDetailFragment;
}> = ({ repo }) => {
  const toast = useToast();

  const [changed, setChanged] = useState(false);

  const { register, control, handleSubmit } = useForm<{
    mounts: KubernetesSecretMountInput[];
  }>({
    defaultValues: {
      mounts: repo.secretMounts || [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "mounts",
  });

  const defaultFieldValues = useMemo(
    () => ({
      mountPath: "",
      secretName: "",
    }),
    []
  );

  useEffect(() => {
    if (fields.length === 0) {
      append(defaultFieldValues, { shouldFocus: false });
    }
  }, [fields, append, defaultFieldValues]);

  const [updateSettings, { error, loading, data }] =
    useUpdateProjectSettingsMutation({
      onCompleted: (data) => {
        setChanged(false);
        if (data) {
          toast({
            title: "Secret Settings Saved",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      },
    });

  const onSubmit = (values: {
    mounts: Array<{ mountPath: string; secretName: string }>;
  }): void => {
    updateSettings({
      variables: {
        input: {
          id: repo.id,
          secretMounts: values.mounts
            .filter((v) => v.mountPath && v.secretName)
            .map((v) => ({
              mountPath: v.mountPath,
              secretName: v.secretName,
            })),
        },
      },
    });
  };

  if (error) {
    console.error(error);
  }

  const errorMsg = !changed && error?.message;

  return (
    <>
      <FormCard
        isLoading={loading}
        onSubmit={handleSubmit(onSubmit)}
        title="Secret mounts"
        description="Mount existing Kubernetes secrets"
      >
        <Flex flexDirection="column">
          <FormControl
            isInvalid={shouldDisplayError(error, data) && !!errorMsg}
          >
            <Stack spacing={4}>
              <Flex direction={["column", "row"]}>
                Secret Namespace
                <CopyableText
                  ml={2}
                  color="brandVar"
                  whiteSpace="nowrap"
                  toCopy={repo.namespace}
                  showIcon
                >
                  {repo.namespace}
                </CopyableText>
              </Flex>
              <Flex gap={4}>
                <Text whiteSpace={"nowrap"} flex="1">
                  Secret Mounts
                </Text>
                <Text whiteSpace={"nowrap"} flex="1" mr="12">
                  Mount Path
                </Text>
              </Flex>
              {fields.map((item, index) => (
                <Stack key={item.id} isInline spacing={4} alignItems="center">
                  <Input
                    {...register(`mounts.${index}.secretName`)}
                    defaultValue={item.secretName}
                    placeholder="(e.g. db-name)"
                  />
                  <Input
                    {...register(`mounts.${index}.mountPath`)}
                    defaultValue={item.mountPath}
                    placeholder="(e.g. /secret)"
                  />
                  <IconButton
                    aria-label="delete"
                    icon={<DeleteIcon />}
                    onClick={() => remove(index)}
                  />
                </Stack>
              ))}
              {shouldDisplayError(error, data) && (
                <FormErrorMessage>{errorMsg}</FormErrorMessage>
              )}
              <Flex width="100%" justifyContent="space-between">
                <Button
                  colorScheme="brand"
                  onClick={() =>
                    append(defaultFieldValues, { shouldFocus: false })
                  }
                  isLoading={loading}
                >
                  + Add More
                </Button>
              </Flex>
            </Stack>
          </FormControl>
        </Flex>
      </FormCard>
    </>
  );
};
