"use client";

import { Box, PropsOf, useColorModeValue } from "@chakra-ui/react";
import useComponentSize from "@rehooks/component-size";
import {
  BarChart,
  // The series types are defined with the SeriesOption suffix
  BarSeriesOption,
  LineChart,
  LineSeriesOption,
} from "echarts/charts";
import {
  DataZoomComponent,
  DataZoomComponentOption,
  GridComponent,
  GridComponentOption,
  LegendComponent,
  LegendComponentOption,
  MarkLineComponent,
  MarkPointComponent,
  TitleComponent,
  // The component types are defined with the suffix ComponentOption
  TitleComponentOption,
  ToolboxComponent,
  ToolboxComponentOption,
  TooltipComponent,
  TooltipComponentOption,
} from "echarts/components";
import type { EChartsType } from "echarts/core";
import * as echarts from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import React, { useEffect, useMemo, useRef, useState } from "react";
import zTheme from "../theme";

const colors = zTheme.colors;

// Combine an Option type with only required components and charts via ComposeOption
export type ECOption = echarts.ComposeOption<
  | BarSeriesOption
  | LineSeriesOption
  | TitleComponentOption
  | GridComponentOption
  | DataZoomComponentOption
  | LegendComponentOption
  | ToolboxComponentOption
  | TooltipComponentOption
>;

// Register the required components
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  LineChart,
  CanvasRenderer,
  DataZoomComponent,
  LegendComponent,
  ToolboxComponent,
  MarkLineComponent,
  MarkPointComponent,
]);

interface ZChartProps {
  options?: ECOption;
  boxProps?: Omit<PropsOf<typeof Box>, "ref">;
}

function getTheme(labelColor: string, axisColor: string) {
  return {
    labelColor,
    color: [colors.brand["500"], colors.cyan["500"]],

    title: {
      textStyle: {
        color: labelColor,
      },
    },

    line: {
      symbol: "none",
      smooth: true,
    },
    valueAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: axisColor,
        },
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: "#333",
        },
      },
      splitLine: {
        show: false,
      },
      splitArea: {
        show: false,
      },
    },
    timeAxis: {
      axisLine: {
        show: true,
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: "#333",
        },
      },
      axisLabel: {
        show: true,
        color: labelColor,
      },
      splitLine: {
        show: false,
      },
      splitArea: {
        show: false,
      },
    },
    toolbox: {
      iconStyle: {
        borderColor: labelColor,
      },
    },
    legend: {
      textStyle: {
        color: labelColor,
      },
    },
    emphasis: {
      iconStyle: {
        borderColor: axisColor,
      },
    },
    tooltip: {
      axisPointer: {
        lineStyle: {
          color: "#cccccc",
          width: 1,
        },
        crossStyle: {
          color: "#cccccc",
          width: 1,
        },
      },
    },
    visualMap: {
      color: ["#fc97af", colors.brand["500"]],
    },
    dataZoom: {
      // backgroundColor: "rgba(255,255,255,0)",
      // dataBackgroundColor: "rgba(114,204,255,1)",
      handleColor: colors.brand["500"],
      handleSize: "90%",
      textStyle: {
        color: labelColor,
        fontWeight: "bold",
      },
      selectedDataBackground: {
        lineStyle: {
          color: colors.brand["500"],
        },
        areaStyle: {
          color: colors.brand["500"],
          opacity: 0.2,
        },
      },
      moveHandleStyle: {
        color: colors.brand["500"],
      },
      emphasis: {
        moveHandleStyle: {
          color: colors.brand["500"],
        },
      },
      brushStyle: {
        color: colors.brand["500"],
        opacity: 0.2,
      },
    },
  };
}

export const ZChart: React.FC<ZChartProps> = ({ boxProps = {}, options }) => {
  const boxRef = useRef<HTMLDivElement>(null);
  const [echartInstance, setEchartInstance] = useState<
    EChartsType | undefined
  >();

  const { width, height } = useComponentSize(boxRef);

  const lightTheme = useMemo(() => getTheme("#000", "#000"), []);
  const darkTheme = useMemo(() => getTheme("#fff", "#fff"), []);

  const theme = useColorModeValue(lightTheme, darkTheme);

  //init the chart
  useEffect(() => {
    if (!boxRef.current) {
      return;
    }
    const instance = echarts.init(boxRef.current, theme);
    setEchartInstance(instance);
    return () => {
      if (instance) {
        instance.dispose();
        setEchartInstance(undefined);
      }
    };
  }, [theme]);

  //width & height

  useEffect(() => {
    if (echartInstance) {
      echartInstance.resize({ width, height });
    }
  }, [width, height, echartInstance]);

  useEffect(() => {
    if (echartInstance && !echartInstance.isDisposed() && options) {
      const currOption: ECOption = echartInstance.getOption() || {};
      const currSeriesType = Array.isArray(currOption.series)
        ? currOption.series[0]?.type
        : currOption.series?.type || "line";
      const series = Array.isArray(options.series)
        ? options.series.map((s) => ({ ...s, type: currSeriesType || "line" }))
        : { ...options.series, type: currSeriesType || "line" };

      echartInstance.setOption({
        ...options,
        ...currOption,
        series,
      });
    }
  }, [options, echartInstance]);

  return <Box {...boxProps} ref={boxRef} />;
};
