import {
  DatabaseProviderType,
  DeployTarget,
  DeployType,
  RepoCommonFragment,
  RepoSourceType,
} from "@zeet/web-api/dist/graphql";

export const isAutoBuild = (repo: RepoCommonFragment): boolean => {
  return (
    (isAutoServerless(repo) || isGit(repo.source.type)) &&
    !isTerraform(repo) &&
    !isManifest(repo)
  );
};

export const isKube = (repo: RepoCommonFragment): boolean => {
  return repo.deployTarget === DeployTarget.Kubernetes;
};

const isAutoServerless = (repo: RepoCommonFragment): boolean => {
  return (
    repo.deployTarget == DeployTarget.GcpCloudRun ||
    repo.deployTarget == DeployTarget.AwsSam ||
    repo.deployTarget == DeployTarget.AwsCdk
  );
};

export const isLambda = (repo: RepoCommonFragment): boolean => {
  return repo.deployTarget === DeployTarget.AwsSam;
};

export const isCloudRun = (repo: RepoCommonFragment): boolean => {
  return repo.deployTarget === DeployTarget.GcpCloudRun;
};

export const isServerless = (repo: RepoCommonFragment): boolean => {
  return repo.deployTarget == DeployTarget.Serverless || isAutoServerless(repo);
};

export const isApp = (repo: RepoCommonFragment): boolean => {
  return (
    (isDocker(repo) || isGit(repo?.source.type)) &&
    repo.deployType != DeployType.Kubectl
  );
};

export const isTerraformRDS = (repo: RepoCommonFragment): boolean => {
  return (
    isTerraform(repo) && repo.databaseProvider === DatabaseProviderType.AwsRds
  );
};

export const hasDeploy = (repo: RepoCommonFragment): boolean => {
  return repo.deployJob || repo.deployService || false;
};

export const isDocker = (repo: RepoCommonFragment): boolean => {
  return repo.source.type === RepoSourceType.Docker;
};

export const isGit = (sourceType: RepoSourceType): boolean => {
  return [
    RepoSourceType.Github,
    RepoSourceType.GithubPublic,
    RepoSourceType.Gitlab,
    RepoSourceType.Bitbucket,
  ].includes(sourceType);
};

export const isHelm = (repo: RepoCommonFragment): boolean => {
  return repo.source.type === RepoSourceType.Helm;
};

export const isTerraform = (repo: RepoCommonFragment): boolean => {
  return (
    repo.source.type === RepoSourceType.Terraform ||
    repo.deployTarget === DeployTarget.Terraform
  );
};

export const isManifest = (repo: RepoCommonFragment): boolean => {
  return repo.deployType === DeployType.Kubectl;
};

export const hasBuildStep = (repo: RepoCommonFragment): boolean => {
  return !isDocker(repo) && !isTerraform(repo);
};

export const isDatabase = (repo: RepoCommonFragment): boolean => {
  return !!repo.databaseEngine;
};
