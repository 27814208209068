import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Box, Flex } from "@chakra-ui/react";
import { Button, Link, useColors } from "@zeet/web-ui";
import { parseGitUri } from "~/utils/parsers";
import { Source, SourceKind, SourceProps } from ".";
import { SourceIcon } from "./SourceIcon";

interface SourcePreviewProps extends SourceProps {
  onSourceEditIntent: () => void;
  onSourceDelete?: () => void;
}

type SourceDisplay = {
  label: string;
  url?: string;
};

const hasHttpProtocol = (url: string): boolean => {
  return /^https?:\/\//.test(url);
};

const isTerraformRegistrySource = (url: string): boolean => {
  const pattern = /^([a-zA-Z0-9-]+)\/([a-zA-Z0-9-]+)\/([a-zA-Z0-9-]+)$/;
  return pattern.test(url);
};

const makeTerraformSourceUrl = (url: string): string | undefined => {
  if (isTerraformRegistrySource(url)) {
    return `https://registry.terraform.io/modules/${url}`;
  }
  if (hasHttpProtocol(url)) {
    return url;
  }
};

const makeTerraformSourceDisplay = (source: Source): SourceDisplay => {
  const label = source.terraformModule?.source ?? "";
  const url = makeTerraformSourceUrl(label);
  return { label, url };
};

const makeContainerRegistrySourceUrl = (source: Source): string | undefined => {
  const { repository } = source.containerRegistry ?? {};
  if (source?.kind === SourceKind.DockerHub) {
    return `https://hub.docker.com/r/${repository}`;
  }
};

const makeContainerRegistrySourceDisplay = (source: Source): SourceDisplay => {
  const { registryUrl, repository, tag } = source.containerRegistry ?? {};
  return {
    label: `${registryUrl ? registryUrl + "/" : ""}${repository}:${
      tag ?? "latest"
    }`,
    url: makeContainerRegistrySourceUrl(source),
  };
};

const makeGitSourceDisplay = (source: Source): SourceDisplay => {
  const git = parseGitUri(source.git?.repository ?? "");
  return {
    label: `${git.owner}/${git.repo}${
      source.git?.ref ? `#${source.git.ref}` : ""
    }${source.git?.path ?? ""}`,
    url: source.git?.repository,
  };
};

const makeHelmSourceDisplay = (source: Source): SourceDisplay => {
  const { repositoryUrl, chart } = source.helmRepository ?? {};
  return {
    label: `${repositoryUrl}/${chart}`,
    url: source.helmRepository?.repositoryUrl,
  };
};

const makeSourceDisplay = (source?: Source): SourceDisplay | undefined => {
  if (source?.git) {
    return makeGitSourceDisplay(source);
  }
  if (source?.containerRegistry) {
    return makeContainerRegistrySourceDisplay(source);
  }
  if (source?.terraformModule) {
    return makeTerraformSourceDisplay(source);
  }
  if (source?.helmRepository) {
    return makeHelmSourceDisplay(source);
  }
};

export const SourcePreview = ({
  onSourceChange,
  source,
  onSourceEditIntent,
  onSourceDelete,
  ...props
}: SourcePreviewProps) => {
  const isEditable = !!onSourceChange;
  const { bg } = useColors();
  const sourceDisplay = makeSourceDisplay(source);

  return (
    <Flex
      alignItems="center"
      width="100%"
      p="3"
      pl="4"
      bg={bg}
      borderRadius="md"
      border="1px solid"
      borderColor="inherit"
      justifyContent="space-between"
      minH="58px"
      {...props}
    >
      <Flex gap="2" alignItems="center">
        <SourceIcon sourceKind={source?.kind} />
        {sourceDisplay?.url ? (
          <Link to={sourceDisplay.url} isExternal>
            <Flex gap="1" alignItems="center">
              {sourceDisplay?.label}
              <ExternalLinkIcon />
            </Flex>
          </Link>
        ) : (
          <Box>{sourceDisplay?.label}</Box>
        )}
      </Flex>
      <Flex gap="2">
        {isEditable && (
          <Button
            variant="ghost"
            size="sm"
            padding="1"
            onClick={onSourceEditIntent}
          >
            Change
          </Button>
        )}
        {onSourceDelete && (
          <Button
            variant="ghost"
            size="sm"
            padding="1"
            onClick={onSourceDelete}
          >
            Delete
          </Button>
        )}
      </Flex>
    </Flex>
  );
};
