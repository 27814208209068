import { ProjectDeployRunDetailFragment } from "@zeet/web-api/dist/graphqlv1";
import { Button } from "@zeet/web-ui";
import { FC } from "react";
import { MdReplay } from "react-icons/md";

interface DeploymentActionsCellProps {
  deployRun?: ProjectDeployRunDetailFragment;
}

export const DeploymentActionsCell: FC<DeploymentActionsCellProps> = () => {
  return (
    <Button variant="secondary" size="xs" leftIcon={<MdReplay />}>
      Rollback
    </Button>
  );
};
