import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useToast,
} from "@chakra-ui/react";
import {
  TeamMemberRole,
  UserTeamMemberQuery,
  useUpdateTeamMemberRoleMutation,
} from "@zeet/web-api/dist/graphql";
import { roleNameMap } from "@zeet/web-ui";
import { FC } from "react";
import { createPortal } from "react-dom";

interface UserRoleProps {
  member: NonNullable<UserTeamMemberQuery["currentUser"]["team"]>["members"][0];
  refetchTeamMembers: () => void;
  userIsOwnerOrAdmin: boolean;
  userIsOwner: boolean;
  teamMembers: UserTeamMemberQuery;
}

export const UserRole: FC<UserRoleProps> = ({
  member,
  refetchTeamMembers,
  userIsOwnerOrAdmin,
  userIsOwner,
  teamMembers,
}) => {
  const toast = useToast();

  const [updateTeamMemberRole] = useUpdateTeamMemberRoleMutation({
    onCompleted: (data) => {
      if (data) {
        toast({
          title: "Role Updated",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        refetchTeamMembers();
      } else {
        // no data = fail
        toast({
          title: "Failed to update role",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    },
    onError: () => {
      toast({
        title: "Failed to update role",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  return (
    <Flex width="100%">
      {userIsOwnerOrAdmin && teamMembers?.currentUser.id !== member.user.id ? (
        <Menu>
          <MenuButton>
            <Flex>
              <Button size="sm" rightIcon={<ChevronDownIcon />}>
                <Text fontSize="0.875rem">{roleNameMap[member.role]}</Text>
              </Button>
            </Flex>
          </MenuButton>
          {createPortal(
            <MenuList>
              {Object.keys(roleNameMap).map((element, index) => {
                // owner can make another owner
                if (userIsOwner || member.role === TeamMemberRole.Owner) {
                  return (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        updateTeamMemberRole({
                          variables: {
                            input: {
                              id: member.id,
                              role: element as TeamMemberRole,
                            },
                          },
                        });
                      }}
                    >
                      {roleNameMap[element]}
                    </MenuItem>
                  );
                  // admins cannot make an owner
                } else {
                  if (element !== TeamMemberRole.Owner) {
                    return (
                      <MenuItem
                        key={index}
                        onClick={() => {
                          updateTeamMemberRole({
                            variables: {
                              input: {
                                id: member.id,
                                role: element as TeamMemberRole,
                              },
                            },
                          });
                        }}
                      >
                        {roleNameMap[element]}
                      </MenuItem>
                    );
                  }
                }
              })}
            </MenuList>,
            document.body
          )}
        </Menu>
      ) : (
        <Text fontSize="sm" fontWeight="bold" ml="12px">
          {roleNameMap[member.role]}
        </Text>
      )}
    </Flex>
  );
};
