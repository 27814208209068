import { Button, ChakraProvider, Flex, Stack, Text } from "@chakra-ui/react";
import * as Sentry from "@sentry/react";
import {
  AuthProvider,
  CurrentTeamProvider,
  CurrentUserProvider,
  GlobalStyles,
  RequireAuth,
  theme,
} from "@zeet/web-ui";
import "focus-visible/dist/focus-visible";
import { Provider as JotaiProvider } from "jotai";
import React, { lazy, Suspense } from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ApolloWrapper } from "~/context/Apollo/Wrapper";
import config from "~/utils/config";
import { getEnvName, isSpecialEnv } from "~/utils/environment";
import { BillingOverviewProvider } from "./Account/BillingV2/Providers/BillingOverviewProvider";
import "./App.scss";
import CommandBar from "./CommandBar";
import Content from "./Content";
import { ReferralTracker } from "./ReferralTracker";
import { ScrollToTop } from "./ScrollToTop";
import { FlexContent } from "./utils/FlexContent";

const onError = (error): void => {
  const chunkFailedMessage = /Loading (CSS )?chunk [\d]+ failed/;
  if (chunkFailedMessage.test(error.message)) {
    setTimeout(() => window.location.reload(), 2000);
  }
};

const ErrorView: React.FC = () => (
  <FlexContent justifyContent="center" alignItems="center">
    <Stack>
      <Text fontSize="2rem">You have encountered an error</Text>
      <Button
        mt="5%"
        onClick={() => {
          window.location.reload();
        }}
      >
        Reload
      </Button>
      <Button
        onClick={() => {
          window.location.href = config.CAPTAIN_URL;
        }}
        colorScheme="brand"
      >
        Return to home
      </Button>
    </Stack>
  </FlexContent>
);

const PopoutTerminal = lazy(() => import("./popout/PopoutTerminal"));
const PopoutProjectLogs = lazy(() => import("./popout/PopoutProjectLogs"));
const titleTemplate = isSpecialEnv()
  ? `Zeet [${getEnvName()?.toLowerCase()}] - %s`
  : `Zeet - %s`;

const App: React.FC = () => {
  return (
    <>
      <Helmet
        titleTemplate={titleTemplate}
        defaultTitle="Zeet - Supercharge Your Cloud"
      >
        <meta charSet="utf-8" />
      </Helmet>

      <ChakraProvider theme={theme.default}>
        <JotaiProvider>
          <Suspense fallback={null}>
            <BrowserRouter>
              <ScrollToTop />
              <QueryParamProvider ReactRouterRoute={Route}>
                <ReferralTracker />
                <GlobalStyles />
                <Sentry.ErrorBoundary
                  onError={onError}
                  fallback={<ErrorView />}
                >
                  <AuthProvider>
                    <ApolloWrapper>
                      <CurrentUserProvider>
                        <CurrentTeamProvider>
                          <BillingOverviewProvider>
                            <CommandBar>
                              <Switch>
                                <Route
                                  exact
                                  path="/popout/terminal/:deploymentID"
                                >
                                  <RequireAuth>
                                    <PopoutTerminal />
                                  </RequireAuth>
                                </Route>
                                <Route exact path="/popout/logs/:deploymentID">
                                  <RequireAuth>
                                    <PopoutProjectLogs />
                                  </RequireAuth>
                                </Route>
                                <Route path="">
                                  <Flex
                                    flexDirection="column"
                                    minHeight="100vh"
                                  >
                                    <Content />
                                  </Flex>
                                </Route>
                              </Switch>
                            </CommandBar>
                          </BillingOverviewProvider>
                        </CurrentTeamProvider>
                      </CurrentUserProvider>
                    </ApolloWrapper>
                  </AuthProvider>
                </Sentry.ErrorBoundary>
              </QueryParamProvider>
            </BrowserRouter>
          </Suspense>
        </JotaiProvider>
      </ChakraProvider>
    </>
  );
};

export default Sentry.withProfiler(App);
