import { Button, Stack, Text } from "@chakra-ui/react";
import { useQueryParams, useTrack } from "@zeet/web-ui";
import React, { useEffect } from "react";
import config from "~/utils/config";

export const ErrorPage: React.FC = () => {
  const { track } = useTrack();
  const query = useQueryParams();
  const error = query.get("error");
  const errorDescription = query.get("error_description");
  const next = query.get("next");

  useEffect(() => {
    if (error) {
      track("error_page", { error, errorDescription });
    }
  }, [track, error, errorDescription]);

  const errorDisplay = error || "Something went wrong!";
  const errorDescriptionDisplay = errorDescription || "";
  const [nextLocation, continueMessage] = next
    ? [next, "Continue to Dashboard"]
    : [config.CAPTAIN_URL, "Return to home"];

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      minHeight="inherit"
      flexDirection="column"
    >
      <Text fontWeight="bold" fontSize="1.3rem">
        Error: {errorDisplay}
      </Text>
      <Text fontWeight="bold" fontSize="1.3rem">
        {errorDescriptionDisplay}
      </Text>
      <Button
        onClick={() => {
          window.location.href = nextLocation;
        }}
        colorScheme="brand"
      >
        {continueMessage}
      </Button>
    </Stack>
  );
};
