import {
  OperationVariables,
  QueryHookOptions,
  QueryResult,
} from "@apollo/client";
import { ChevronDownIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Collapse,
  Flex,
  Heading,
  Spacer,
  useToast,
} from "@chakra-ui/react";
import {
  Logs,
  useGenerateDownloadableLogLinkForWorkflowRunStepMutation,
} from "@zeet/web-api/dist/graphqlv1";
import { Card, CenterLoading, SearchButton, ZWarn } from "@zeet/web-ui";
import { useEffect, useMemo, useState } from "react";
import { MdDownload } from "react-icons/md";
import { LogsConsole } from "./LogsConsole/LogsConsole";

interface ApolloQuery<TInput extends OperationVariables, TResult> {
  (options: QueryHookOptions<TResult, TInput>): QueryResult<TResult, TInput>;
}

interface LogViewProps<TInput extends OperationVariables, TResult> {
  /**
   * The title to display above the logs
   */
  title: string;

  /**
   * The GraphQL query to request logs with
   */
  query: ApolloQuery<TInput, TResult>;

  /**
   * The variables for the query
   */
  variables: TInput;

  /**
   * Extracts the logs from the given GraphQL query
   * @param out The result from the GraphQL query
   */
  logsResolver(out: TResult): Logs | null | undefined;

  collapsible?: boolean;

  /**
   * Whether or not the log view should be expanded
   * @default false
   */
  expanded?: boolean;
}

/**
 * Component to fetch and display logs with a title.
 *
 * @example
 *   <LogView
 *     title="Logs"
 *     query={useLogsQuery}
 *     variables={{ userId }}
 *     logsResolver={(r) =>
 *       r.user.projectAdapters?.nodes[0].build?.runs.nodes[0].jobRun.logs
 *     }
 *   />
 */
export const LogView = <TInput extends OperationVariables, TResult>({
  title,
  query: useQuery,
  variables,
  logsResolver,
  collapsible,
  expanded,
}: LogViewProps<TInput, TResult>) => {
  const [searching, setSearching] = useState(false);
  const [skip, setSkip] = useState(false);
  const [showLogs, setShowLogs] = useState(expanded || false);
  const { data, loading } = useQuery({ variables, skip, pollInterval: 3000 });
  const stepID = variables["actionStepId"];

  const logs = useMemo(() => data && logsResolver(data), [data, logsResolver]);

  // disable polling if the logs are finished
  useEffect(() => setSkip(logs?.completed || false), [logs]);

  const toast = useToast();
  const [
    generateDownloadableLogLinkForWorkflowRunStep,
    { loading: generateDownloadableLogLinkForWorkflowRunStepLoading },
  ] = useGenerateDownloadableLogLinkForWorkflowRunStepMutation({
    onError: (err) => {
      if (err) {
        toast({
          title: err.message ?? "Unknown error when downloading logs",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    },
    onCompleted: (data) => {
      if (data) {
        toast({
          title: "Downloading log file",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        const link = document.createElement("a");
        link.href = data.generateDownloadableLogLinkForWorkflowRunStep;
        document.body.appendChild(link);
        link.click();
      }
    },
  });

  return (
    <Card
      flexDir="column"
      borderRadius="0"
      _first={{ borderTopRadius: "md" }}
      _last={{ borderBottomRadius: "md" }}
    >
      <Flex
        as="button"
        alignItems="center"
        p={2}
        px={4}
        minHeight="56px"
        width="100%"
        onClick={() => setShowLogs(!showLogs)}
      >
        {collapsible &&
          (showLogs ? (
            <ChevronDownIcon fontSize="lg" />
          ) : (
            <ChevronRightIcon fontSize="lg" />
          ))}
        <Heading size="sm">{title}</Heading>
        <Spacer />
        {(showLogs || !collapsible) && stepID && (
          <Button
            size="lg"
            p={1}
            width={8}
            minWidth={8}
            height={8}
            fontSize={"1em"}
            variant={"primary"}
            isLoading={generateDownloadableLogLinkForWorkflowRunStepLoading}
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              e.stopPropagation();
              generateDownloadableLogLinkForWorkflowRunStep({
                errorPolicy: "none",
                variables: {
                  actionStepId: stepID,
                },
              });
            }}
          >
            <MdDownload />
          </Button>
        )}
        {(showLogs || !collapsible) && logs && (
          <SearchButton searching={searching} setSearching={setSearching} />
        )}
      </Flex>
      <Collapse in={showLogs || !collapsible}>
        <Box borderTop="1px solid var(--chakra-colors-chakra-border-color)">
          {logs?.expired && (
            <ZWarn
              borderRadius={0}
              data-testid="expired-notice"
              error="These logs have expired. Logs are only available until a container is destroyed."
            />
          )}
          {loading ? (
            <CenterLoading my={6} />
          ) : logs?.entries ? (
            <LogsConsole
              isLive={!logs.completed}
              searching={searching}
              setSearching={setSearching}
              lines={logs.entries}
            />
          ) : (
            <Flex
              p={4}
              py="8"
              opacity="0.5"
              justifyContent="center"
              minHeight="400px"
            >
              No logs found.
            </Flex>
          )}
        </Box>
      </Collapse>
    </Card>
  );
};
