import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Stack,
  Text,
  TextProps,
  useToast,
} from "@chakra-ui/react";
import {
  BuildType,
  RepoDetailFragment,
  useRepoBuildSuggestionQuery,
  useUpdateProjectSettingsMutation,
} from "@zeet/web-api/dist/graphql";
import { FormSelect, Loading, Option, Tooltip } from "@zeet/web-ui";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FormCard } from "../../FormCard";
import { shouldDisplayError } from "../util";

export const ZFormLabel: React.FC<TextProps> = (props) => {
  return (
    <Text
      whiteSpace="nowrap"
      flex={1}
      display="flex"
      alignItems="center"
      mr={4}
      {...props}
    />
  );
};

interface GithubProjectBuildSettingsInterface {
  repo: RepoDetailFragment;
  title?: string;
  description?: string;
}

export const GithubProjectBuildSettings: React.FC<
  GithubProjectBuildSettingsInterface
> = ({ repo, title = "Build & Run", description }) => {
  const toast = useToast();

  const [changed, setChanged] = useState(false);
  const { handleSubmit, register, watch, setValue, reset } = useForm({
    defaultValues: repo.buildMethod || {},
  });
  const selectedBuildType = watch("type");

  useEffect(() => {
    if (repo.buildMethod) {
      reset(repo.buildMethod);
    }
  }, [repo.buildMethod, reset]);

  const [updateSettings, { error, loading: updateLoading, data }] =
    useUpdateProjectSettingsMutation({
      onCompleted: (data) => {
        setChanged(false);
        if (data) {
          toast({
            title: "Build Settings Saved",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      },
    });

  const {
    data: rbData,
    error: ghError,
    loading: ghLoading,
  } = useRepoBuildSuggestionQuery({
    variables: {
      id: repo?.id,
    },
  });

  const onSubmit = (values): void => {
    updateSettings({
      variables: {
        input: {
          id: repo.id,
          buildType: values.type,
          buildCommand: values.buildCommand,
          runCommand: values.runCommand,
          workingDirectory: values.workingDirectory
            ? values.workingDirectory
            : values.workingDirectory === ""
            ? ""
            : null,
          dockerfilePath: values.dockerfilePath || null,
          staticPath: values.staticPath,
          nodejsVersion: values.nodejsVersion.trim(),
          pythonVersion: values.pythonVersion.trim(),
          golangVersion: values.golangVersion.trim(),
        },
      },
    });
  };

  const buildType = repo.buildMethod?.type;

  useEffect(() => {
    if (buildType) {
      setValue("type", buildType, {
        shouldDirty: true,
      });
    }
  }, [buildType, setValue]);

  if (error || ghError) {
    console.error(error, ghError);
  }

  const errorMsg = !changed && error?.message;

  const buildOptions = [
    repo.buildMethod,
    ...(rbData?.currentUser?.repo?.buildMethodSuggestions?.filter(
      (b) => b.type !== repo.buildMethod?.type
    ) || []),
  ];

  return (
    <FormCard
      isLoading={updateLoading}
      title={title}
      description={description}
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormControl isInvalid={shouldDisplayError(error, data)}>
        {ghLoading ? (
          <Loading />
        ) : (
          <Stack spacing={4}>
            <Flex>
              <ZFormLabel>
                Build Method
                <Tooltip text="What kind of project is this? Check out one of our existing templates, or use a Dockerfile or shell commands to get started" />
              </ZFormLabel>
              <Box flex={5.25 /* TODO: broken alignment */}>
                <FormSelect
                  {...register("type")}
                  defaultValue={repo.buildMethod?.type}
                  onChange={(e) => {
                    setValue("type", e.target.value as BuildType);
                    buildOptions
                      .filter((b) => b && b.type === e.target.value)
                      .forEach((b) => {
                        if (!b) {
                          return;
                        }
                        setValue("buildCommand", b.buildCommand, {
                          shouldDirty: true,
                        });
                        setValue("runCommand", b.runCommand, {
                          shouldDirty: true,
                        });
                        setValue("dockerfilePath", b.dockerfilePath, {
                          shouldDirty: true,
                        });
                        setValue("staticPath", b.staticPath, {
                          shouldDirty: true,
                        });
                      });
                  }}
                  options={buildOptions
                    .map((build) => {
                      if (!build) {
                        return null as unknown as Option<BuildType>;
                      }
                      return { value: build.type, label: build.name };
                    })
                    .filter((b) => !!b)}
                />
              </Box>
            </Flex>
            <Flex
              hidden={
                ![
                  BuildType.NodeStatic,
                  BuildType.NodeNextjs,
                  BuildType.Node,
                ].includes(selectedBuildType)
              }
              alignItems="center"
            >
              <ZFormLabel>
                NodeJS Version
                <Tooltip text="Custom node.js major version (e.g. 14, 15, 16)" />
              </ZFormLabel>
              <Input
                flex={5}
                fontFamily={"mono"}
                {...register("nodejsVersion")}
                placeholder="14"
              />
            </Flex>
            <Flex
              hidden={
                ![BuildType.Python, BuildType.PythonDjango].includes(
                  selectedBuildType
                )
              }
              alignItems="center"
            >
              <ZFormLabel>
                Python Version
                <Tooltip text="Custom Python3 version (e.g. 3.7, 3.8, 3.9)" />
              </ZFormLabel>
              <Input
                flex={5}
                fontFamily={"mono"}
                {...register("pythonVersion")}
                placeholder={"3.8"}
              />
            </Flex>
            <Flex
              hidden={![BuildType.GolangModules].includes(selectedBuildType)}
              alignItems="center"
            >
              <ZFormLabel>
                Go Version
                <Tooltip text="Custom GoLang module version (e.g. 1.16, 1.17, 1.18)" />
              </ZFormLabel>
              <Input
                flex={5}
                fontFamily={"mono"}
                {...register("golangVersion")}
                placeholder={"1.16"}
              />
            </Flex>
            <Flex
              hidden={
                ![
                  BuildType.Docker,
                  BuildType.NodeNextjs,
                  BuildType.NodeStatic,
                  BuildType.Node,
                  BuildType.Python,
                  BuildType.PythonDjango,
                  BuildType.GolangModules,
                ].includes(selectedBuildType)
              }
              alignItems="center"
            >
              <ZFormLabel>
                {selectedBuildType === BuildType.Docker
                  ? "Docker Context"
                  : "Root Directory"}
                <Tooltip
                  text={
                    selectedBuildType === BuildType.Docker
                      ? 'The root directory for docker build (i.e "./", backend, frontend)'
                      : 'The root directory for your app in a monorepo (i.e "./", backend, frontend)'
                  }
                />
              </ZFormLabel>
              <Input
                flex={5}
                fontFamily={"mono"}
                {...register("workingDirectory")}
                placeholder="./ (optional)"
              />
            </Flex>
            <Flex
              hidden={![BuildType.Docker].includes(selectedBuildType)}
              alignItems="center"
            >
              <ZFormLabel>
                Dockerfile Path
                <Tooltip text="The path to your Dockerfile (i.e ./src/Dockerfile, ./dockerfile)" />
              </ZFormLabel>
              <Input
                flex={5}
                fontFamily={"mono"}
                {...register("dockerfilePath")}
                placeholder={"Dockerfile"}
              />
            </Flex>
            <Flex
              hidden={
                ![
                  BuildType.Node,
                  BuildType.Python,
                  BuildType.Ubuntu,
                  BuildType.NodeStatic,
                  BuildType.ElixirPhoenix,
                  BuildType.GolangModules,
                  BuildType.PythonDjango,
                ].includes(selectedBuildType)
              }
              alignItems="center"
            >
              <ZFormLabel>
                Build Command
                <Tooltip text="The command used to prepare your project. This could be compiling your code, installing dependencies, or maybe both!" />
              </ZFormLabel>
              <Input
                flex={5}
                fontFamily={"mono"}
                {...register("buildCommand")}
              />
            </Flex>
            <Flex
              hidden={
                ![
                  BuildType.Node,
                  BuildType.Python,
                  BuildType.Ubuntu,
                  BuildType.ElixirPhoenix,
                  BuildType.GolangModules,
                  BuildType.PythonDjango,
                  BuildType.Docker,
                  BuildType.Herokuish,
                  BuildType.NodeNextjs,
                ].includes(selectedBuildType)
              }
              alignItems="center"
            >
              <ZFormLabel>
                Run Command
                <Tooltip text="The command used to run your code. This should start your service" />
              </ZFormLabel>
              <Input
                flex={5}
                fontFamily={"mono"}
                {...register("runCommand")}
                placeholder={
                  selectedBuildType === BuildType.Docker
                    ? "Docker default"
                    : "(e.g.) npm run start"
                }
              />
            </Flex>
            <Flex
              hidden={![BuildType.NodeStatic].includes(selectedBuildType)}
              alignItems="center"
            >
              <ZFormLabel>
                Static Output Path
                <Tooltip text="The directory your build command outputs your files to (i.e dist, build, public, etc.)" />
              </ZFormLabel>
              <Input flex={5} fontFamily={"mono"} {...register("staticPath")} />
            </Flex>
            {shouldDisplayError(error, data) && (
              <FormErrorMessage>{errorMsg}</FormErrorMessage>
            )}
          </Stack>
        )}
      </FormControl>
    </FormCard>
  );
};
