import { BoxProps, Flex, Heading } from "@chakra-ui/react";
import { Button, EmptyBox } from "@zeet/web-ui";
import React from "react";

export const EmptyState: React.FC<
  {
    heading: string;
    buttonText?: string;
    buttonLink?: string;
    buttonOnClick?: () => void;
    buttonIsLoading?: boolean;
    "data-testid"?: string;
  } & BoxProps
> = ({
  heading,
  buttonText,
  buttonLink,
  buttonOnClick,
  buttonIsLoading,
  ...props
}) => {
  return (
    <EmptyBox flexDirection="column" {...props}>
      <Flex justify="center" mb={6} mt="6">
        <Heading size="md">{heading}</Heading>
      </Flex>

      {buttonText && buttonLink && (
        <Flex justify="center" mb={12}>
          <Button variant="primary" asLink to={buttonLink} position="relative">
            {buttonText}
          </Button>
        </Flex>
      )}

      {buttonText && buttonOnClick && (
        <Flex justify="center" mb={12}>
          <Button
            variant="primary"
            onClick={buttonOnClick}
            isLoading={buttonIsLoading}
            position="relative"
          >
            {buttonText}
          </Button>
        </Flex>
      )}
    </EmptyBox>
  );
};
