import { useFormContext, useWatch } from "react-hook-form";

export enum SettingsNameFormFields {
  NAME = "name",
}

interface SettingsNameProps {
  fieldName?: string;
}

export const DisplayName: React.FC<SettingsNameProps> = ({
  fieldName = SettingsNameFormFields.NAME,
}) => {
  const { control } = useFormContext();
  const name = useWatch({
    control,
    name: fieldName,
  });

  return <>{name || "Untitled"}</>;
};
