import { SearchIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Flex,
  List,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  RepoBasicFragment,
  RepoListItemFragment,
  useCopyEnvVarsMutation,
  useUserBasicReposQuery,
} from "@zeet/web-api/dist/graphql";
import {
  IconInput,
  Loading,
  Tooltip,
  Track,
  useColors,
  useCurrentTeamUser,
} from "@zeet/web-ui";
import React, { useCallback, useState } from "react";
import { FileUpload } from "../../FileUpload";
import { EnvRepoItem } from "../../RepoItem";
import { EnvVarsExportModal } from "./EnvVarsExportModal";

export const EnvVarsActions: React.FC<{
  to: RepoListItemFragment;
  handleEnvFile: (event: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ to, handleEnvFile }) => {
  const {
    onOpen: onCopyOpen,
    onClose: onCopyClose,
    isOpen: isCopyOpen,
  } = useDisclosure();

  const {
    onOpen: onExportOpen,
    onClose: onExportClose,
    isOpen: isExportOpen,
  } = useDisclosure();

  const currentTeamUser = useCurrentTeamUser();
  const toast = useToast();

  const [filter, setFilter] = useState("");

  const { data, loading, error } = useUserBasicReposQuery({
    variables: {
      id: currentTeamUser.id,
    },
  });

  const [copyEnvVars, { error: copyError, loading: copyLoading }] =
    useCopyEnvVarsMutation({
      onCompleted: (data) => {
        if (data) {
          onCopyClose();
          toast({
            title: "Environment Variables Saved",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      },
    });

  if (error) {
    console.error(error);
  }

  if (copyError) {
    console.error(copyError);
  }

  const copyFrom = useCallback(
    (from: RepoBasicFragment): void => {
      copyEnvVars({
        variables: {
          from: from.id,
          to: to.id,
        },
      });
    },
    [to, copyEnvVars]
  );

  const repos = data?.user.repos?.filter(
    (r) => r.enabled && r.id !== to.id && r.name.includes(filter)
  );

  repos?.sort((a, b) => a.name.localeCompare(b.name));

  const colors = useColors();

  return (
    <>
      <Flex display="inline-flex">
        <FileUpload name="dotenv" onChange={handleEnvFile} width="auto">
          <Button size="sm" alignItems="center" justifyContent="center">
            Upload .env file
            <Tooltip text="Not seeing your .env file? If you're on Mac, try ⌘ + Shift + ." />
          </Button>
        </FileUpload>
        {repos && repos.length > 0 && (
          <Button size="sm" marginLeft={4} onClick={onCopyOpen}>
            Copy variables from another project
          </Button>
        )}
        <Button size="sm" marginLeft={4} onClick={onExportOpen}>
          Export
        </Button>
      </Flex>

      {/* Modal for copying env var from another project */}
      <Modal
        isOpen={isCopyOpen}
        onClose={onCopyClose}
        size="xl"
        isCentered
        preserveScrollBarGap={true}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          {loading ? (
            <Loading />
          ) : (
            <Box mb={4}>
              <Track type="load_copy_env_vars" />
              <ModalHeader>
                Choose Source Project{" "}
                <Tooltip text="Which project do you want to copy the environment variables from?" />
              </ModalHeader>
              <ModalBody>
                <Flex align="center" mt={1} mb={4}>
                  <IconInput
                    IconComponent={SearchIcon}
                    inputProps={{
                      placeholder: "Search...",
                      value: filter,
                      onChange: (e) => setFilter(e.target.value),
                    }}
                  />
                </Flex>
                <Alert status="warning" mt={2} borderRadius="md" mb={2}>
                  <AlertIcon />
                  <AlertDescription>
                    <Text>
                      This will overwrite <strong>all</strong> of the
                      environment variables for {to.name}!
                    </Text>
                  </AlertDescription>
                </Alert>
                <List
                  alignItems="center"
                  maxHeight={"65vh"}
                  overflowY={"auto"}
                  flex={1}
                >
                  {repos && repos.length ? (
                    repos.map((repo) => (
                      <EnvRepoItem
                        key={repo.id}
                        repo={repo}
                        backgroundColor={colors.bg}
                      >
                        <Button
                          isLoading={copyLoading}
                          size="sm"
                          colorScheme="brand"
                          onClick={() => copyFrom(repo)}
                        >
                          Copy now
                        </Button>
                      </EnvRepoItem>
                    ))
                  ) : (
                    <Flex
                      border="1px solid var(--chakra-colors-chakra-border-color)"
                      justifyContent="center"
                      alignItems="center"
                      borderRadius={10}
                      p={4}
                      py={12}
                    >
                      <Text>{"No projects found :("}</Text>
                    </Flex>
                  )}
                </List>
              </ModalBody>
            </Box>
          )}
        </ModalContent>
      </Modal>
      <EnvVarsExportModal
        onExportClose={onExportClose}
        isExportOpen={isExportOpen}
        projectName={to.name}
      />
    </>
  );
};
