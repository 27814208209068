import { Box, BoxProps } from "@chakra-ui/react";

/**
 * A reusable sidebar component that supports generic elements.
 * Its children should be of type {@link DetailItem}.
 *
 * @example
 * <DetailSidebar>
 *   <DetailItem>
 *     <DetailLabel>Status</DetailLabel>
 *     <Text>Healthy</Text>
 *   </DetailItem>
 * </DetailSidebar>
 *
 * @see {@link DetailItem}
 * @see {@link DetailLabel}
 * @see {@link DetailDataBlock}
 */
export const DetailSidebar = (props: BoxProps) => {
  return <Box fontFamily="Manrope" pl={4} {...props} />;
};
