import { Text } from "@chakra-ui/react";
import { Tooltip } from "@zeet/web-ui";

export const CloudCostEstimate = () => {
  return (
    <Text>
      Cloud Cost Expected
      <Tooltip text="Cloud providers typically charge you for the resources you use and the duration for which you use them. Check with your cloud provider on what the most accurate costs might be for this Project." />
    </Text>
  );
};
