import { Flex, Heading } from "@chakra-ui/react";
import {
  Avatar,
  BlueprintSlug,
  Button,
  Card,
  isProjectTypeBlueprint,
  useCurrentTeamUser,
} from "@zeet/web-ui";
import { FaCube } from "react-icons/fa";
import {
  SettingsSection,
  SettingsSectionDetails,
} from "~/components/Layouts/Settings";
import { ProjectSettingsProps } from "../..";

export const BlueprintSettings = ({ project }: ProjectSettingsProps) => {
  const { login } = useCurrentTeamUser();

  if (
    !project.blueprint ||
    (project?.blueprint &&
      isProjectTypeBlueprint(
        project.blueprint.configuration?.slug as BlueprintSlug
      ))
  )
    return null;

  return (
    <SettingsSection>
      <SettingsSectionDetails
        title="Blueprint settings"
        description="The blueprint your project is based on."
      />
      <Flex gap="4" flexDirection="column">
        <Card
          p="4"
          as={Flex}
          alignItems="center"
          justifyContent="space-between"
        >
          <Heading size="sm" display="flex" alignItems="center">
            <Avatar
              boxSize={8}
              src={project.blueprint?.configuration?.logoUrl ?? ""}
              icon={<FaCube size={16} />}
              borderRadius="md"
              mr={2}
            />
            {project.blueprint?.configuration?.displayName}
          </Heading>
          <Button
            variant="secondary"
            asLink
            to={`/${login}/console/blueprints/${project.blueprint?.id}`}
          >
            View
          </Button>
        </Card>
      </Flex>
    </SettingsSection>
  );
};
