import { Icon } from "@chakra-ui/react";
import { useWorkflowRunsQuery } from "@zeet/web-api/dist/graphqlv1";
import {
  Button,
  CenterLoading,
  useCurrentTeamUser,
  usePagination,
} from "@zeet/web-ui";
import { useMemo } from "react";
import { BsRocketTakeoffFill } from "react-icons/bs";
import { useLocation } from "react-router-dom";
import { SearchableListLayout } from "~/components/SearchableList";
import { workflowRunColumnIds, workflowRunColumns } from "./listview";
import { useWorkflowOperations } from "./useWorkflowOperations";
import { formatWorkflowRun } from "./utils";

interface WorkflowRunListProps {
  projectId: string;
}

export const WorkflowRunList = ({ projectId }: WorkflowRunListProps) => {
  const { id: teamId } = useCurrentTeamUser();
  const { pageInput, setPage } = usePagination();
  const { pathname } = useLocation();
  const { submitRun, submitLoading } = useWorkflowOperations();
  const { data, loading } = useWorkflowRunsQuery({
    variables: {
      projectId,
      teamId,
      page: pageInput,
    },
    pollInterval: 5000,
  });

  const runs = data?.team?.project?.workflow?.runs.nodes;

  const formattedData = useMemo(
    () => ({
      columns: workflowRunColumns,
      data: runs?.map((r) => formatWorkflowRun(pathname, r)) ?? [],
      skipReset: false,
    }),
    [runs, pathname]
  );

  if (loading) return <CenterLoading />;

  return (
    <SearchableListLayout
      id="workflow-runs"
      columns={workflowRunColumns}
      defaultColumnIds={workflowRunColumnIds}
      data={formattedData}
      pageChanged={setPage}
      hasNextPage={data?.team?.project?.workflow?.runs.pageInfo.hasNextPage}
      actionButtons={
        <Button
          variant="primary"
          onClick={() => {
            if (!data?.team?.project?.workflow?.id) return null;
            submitRun(projectId, data?.team?.project?.workflow?.id);
          }}
          isLoading={submitLoading}
          leftIcon={<Icon as={BsRocketTakeoffFill} />}
        >
          Trigger Workflow
        </Button>
      }
    />
  );
};
