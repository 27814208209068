import { Flex, useDisclosure } from "@chakra-ui/react";
import {
  TeamCloudQuery,
  UserTeamMemberQuery,
} from "@zeet/web-api/dist/graphql";
import { ICurrentConnection, Skeleton, useCurrentTeam } from "@zeet/web-ui";
import { FC, useState } from "react";
import { ConnectedUserModal } from "../../Console/ConsoleUsers/ConnectedUserModal";
import { UserCell } from "../../Console/ConsoleUsers/UserCell";

interface UserConnectProps {
  member: NonNullable<UserTeamMemberQuery["currentUser"]["team"]>["members"][0];
  teamCloudConnectionsLoading: boolean | undefined;
  userIsOwnerOrAdmin: boolean;
  teamCloudConnections: TeamCloudQuery | undefined;
}

export const UserConnect: FC<UserConnectProps> = ({
  member,
  teamCloudConnectionsLoading,
  userIsOwnerOrAdmin,
  teamCloudConnections,
}) => {
  const currentTeam = useCurrentTeam();
  const {
    onOpen: connectedUsersOnOpen,
    onClose: connectedUsersOnClose,
    isOpen: connectedUsersIsOpen,
  } = useDisclosure();

  const [currentConnection, setCurrentConnection] =
    useState<ICurrentConnection>();

  return (
    <Flex>
      {teamCloudConnectionsLoading ? (
        <Skeleton w="200px" height="24px" />
      ) : (
        <UserCell
          addLink={`/${currentTeam?.user.login}/console/people/zeet/connect?user_id=${member.user.id}`}
          permissionsLink={`/${currentTeam?.user.login}/console/people/zeet/permissions?user_id=${member.user.id}`}
          userIsOwnerOrAdmin={userIsOwnerOrAdmin}
          teamCloudConnections={teamCloudConnections}
          member={member}
          connectedUsersOnOpen={connectedUsersOnOpen}
          setCurrentConnection={setCurrentConnection}
        />
      )}
      <ConnectedUserModal
        onClose={connectedUsersOnClose}
        isOpen={connectedUsersIsOpen}
        currentConnection={currentConnection}
      />
    </Flex>
  );
};
