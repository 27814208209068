import React from "react";
import { CodeSecurity } from "../CodeSecurity";
import { Copy, CopyProps, CopyStyleProps, DEFAULT_VARIANT } from "./Copy";
import { useCopyDisplayItemStyles } from "./hooks";

interface CopyCodeSecurityProps extends CopyStyleProps {
  code: string;
  copyMessage?: string;
  variant?: CopyProps["variant"];
}

export const CopyCodeSecurity: React.FC<CopyCodeSecurityProps> = ({
  code,
  variant = DEFAULT_VARIANT,
  ...rest
}) => {
  const copyDisplayItemStyles = useCopyDisplayItemStyles(variant);

  return (
    <Copy {...rest} text={code} variant={variant}>
      <CodeSecurity {...copyDisplayItemStyles}>{code}</CodeSecurity>
    </Copy>
  );
};
