import { useCreateBuildRunMutation } from "@zeet/web-api/dist/graphqlv1";
import { useHistory } from "react-router-dom";
import { useMutationError } from "~/hooks/useMutationError";
import { useProjectPath } from "../hooks/useProjectPath";

export const useTriggerBuild = (buildId: string) => {
  const history = useHistory();

  const path = `${useProjectPath()}/builds`;
  const [createBuildRun, { loading }] = useCreateBuildRunMutation({
    variables: {
      buildId,
    },
    onCompleted(result) {
      if (!result?.createBuildRun?.id) return;

      // then redirect to the page
      history.push(`${path}?runId=${result.createBuildRun.id}`);
    },
    ...useMutationError("Failed to trigger build"),
  });

  return {
    loading,
    triggerBuild: () => {
      createBuildRun();
    },
  };
};
