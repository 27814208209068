import { Flex, Icon } from "@chakra-ui/react";
import { FC } from "react";
import { MdCheck, MdClose } from "react-icons/md";

interface YesNoCellProps {
  isYes: boolean;
}

export const YesNoCell: FC<YesNoCellProps> = ({ isYes }) => {
  return (
    <Flex alignItems="center">
      {isYes ? (
        <>
          <Icon as={MdCheck} color="success" mr={2} /> Yes
        </>
      ) : (
        <>
          <Icon as={MdClose} color="danger" mr={2} />
          No
        </>
      )}
    </Flex>
  );
};
