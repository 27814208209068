import { Text, TextProps } from "@chakra-ui/react";
import { useColors } from "../../hooks/useColors";

/**
 * A label to use to identify DetailItems
 */
export const DetailLabel = (props: TextProps) => {
  const { fg } = useColors();
  return (
    <Text fontWeight="700" fontSize="0.875em" color={fg} mb={2} {...props} />
  );
};
