import { Box, BoxProps, Flex, Spinner, Text } from "@chakra-ui/react";
import { useVirtualizer } from "@tanstack/react-virtual";
import { IDataRow, useColors } from "@zeet/web-ui";
import clsx from "clsx";
import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  PluginHook,
  Row,
  SortingRule,
  useBlockLayout,
  useResizeColumns,
  useSortBy,
  useTable,
} from "react-table";
import Cell from "../Cell";
import { EmptyState } from "../EmptyState";
import Header from "../Header";
import { ListSkeleton } from "../ListSkeleton";
import { useListViewContext } from "../Provider";

const defaultColumn = {
  minWidth: 300,
  Cell: Cell,
  Header: Header,
  sortType: "sortingStrategy",
};

interface ListViewTableProps {
  emptyStateOverride?: React.ReactElement;
  defaultSort?: Array<SortingRule<string>>;
  sortingStrategy?(
    rowA: Row<IDataRow>,
    rowB: Row<IDataRow>,
    columnId: string,
    desc: boolean | undefined
  ): number;
  onClickRow?: (MouseEvent) => void;
  hoverProps?: CSSProperties;
}

export const LIST_VIEW_TABLE_VIEW_TYPE = "LIST_VIEW_TABLE_VIEW_TYPE";

export const ListViewTable: React.FC<ListViewTableProps & BoxProps> = ({
  defaultSort = [],
  emptyStateOverride,
  children,
  sortingStrategy,
  onClickRow,
  hoverProps,
  ...props
}) => {
  const {
    state,
    dispatch: dataDispatch,
    toggleMenuItem,
    loading,
    onInfiniteScrollRequestMoreData,
    keyboardNavigable,
    itemSelected,
  } = useListViewContext();
  const { columns, data, skipReset, ready } = state;
  const { bg, bgHover } = useColors();

  const sortTypes = useMemo(
    () => ({
      sortingStrategy:
        sortingStrategy ||
        function alphanumericFalsyLast(rowA, rowB, columnId, desc) {
          if (
            !rowA.values[columnId] &&
            rowA.values[columnId] !== 0 &&
            !rowB.values[columnId] &&
            rowB.values[columnId] !== 0
          ) {
            return 0;
          }

          if (!rowA.values[columnId] && rowA.values[columnId] !== 0) {
            return desc ? -1 : 1;
          }

          if (!rowB.values[columnId] && rowB.values[columnId] !== 0) {
            return desc ? 1 : -1;
          }

          return isNaN(rowA.values[columnId])
            ? rowA.values[columnId].localeCompare(rowB.values[columnId])
            : rowA.values[columnId] - rowB.values[columnId];
        },
    }),
    [sortingStrategy]
  );

  const plugins: PluginHook<IDataRow>[] = [useBlockLayout, useResizeColumns];

  if (defaultSort) {
    plugins.push(useSortBy);
  }

  const attachAccessorsToColumns = useMemo(() => {
    return columns.map((column) => {
      if (column.accessor) {
        return column;
      }
      return {
        ...column,
        accessor: (row) => row[column.id]?.text,
      };
    });
  }, [columns]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns: attachAccessorsToColumns,
        data,
        defaultColumn,
        dataDispatch,
        toggleMenuItem,
        autoResetSortBy: !skipReset,
        autoResetFilters: !skipReset,
        autoResetRowState: !skipReset,
        initialState: {
          sortBy: defaultSort,
        },
        sortTypes,
      },
      ...plugins
    );

  function isTableResizing() {
    for (const headerGroup of headerGroups) {
      for (const column of headerGroup.headers) {
        if (column.isResizing) {
          return true;
        }
      }
    }

    return false;
  }

  const [rowOffset, setRowOffset] = useState<number>();
  const rowContainerRef = useRef<HTMLDivElement | null>(null);
  const rowContainerRefCallback = useCallback((r: HTMLDivElement | null) => {
    if (!r) return;
    rowContainerRef.current = r;
    setRowOffset(r.getBoundingClientRect().top);
  }, []);

  const shouldShowSkeleton =
    !ready ||
    (loading && (!onInfiniteScrollRequestMoreData || data.length < 1));

  const virtualizer = useVirtualizer({
    count: data.length,
    getScrollElement: () => document.body,
    estimateSize() {
      // now, this doesn't change but it does seem advantageous to find a better way of doing this
      return 50;
    },
    scrollMargin: rowOffset,
    overscan: 10,
  });

  useEffect(() => {
    if (!keyboardNavigable) return;

    const handler = (e: KeyboardEvent) => {
      if (
        e.key !== "j" &&
        e.key !== "k" &&
        e.key !== "ArrowUp" &&
        e.key !== "ArrowDown"
      )
        return;
      if ((e.target as HTMLInputElement).nodeName.toLowerCase() === "input") {
        return;
      }
      e.preventDefault();
      (rowContainerRef.current?.querySelector(".tr") as HTMLElement)?.focus();
    };

    document.body.addEventListener("keydown", handler);
    return () => document.body.removeEventListener("keydown", handler);
  }, [keyboardNavigable]);

  const rowClickHandler = (item: IDataRow) => (_: React.MouseEvent) =>
    onClickRow?.(item);

  const rowKeyHandler = (item: IDataRow) => {
    return (e: React.KeyboardEvent) => {
      if (!keyboardNavigable) return;
      const row = e.target as HTMLElement;
      const isUp = e.key === "k" || e.key === "ArrowUp";
      const isDown = e.key === "j" || e.key === "ArrowDown";
      const isEnter = e.key === "Enter";
      if (!isUp && !isDown && !isEnter) return;
      e.stopPropagation();
      e.preventDefault();

      if (isEnter) {
        if (itemSelected) {
          itemSelected(item);
        }
        return;
      }

      const nextEl = (
        isUp ? row.previousSibling : row.nextSibling
      ) as HTMLElement | null;
      if (!nextEl) return;
      nextEl.focus();
      nextEl.scrollIntoView({ block: "center" });
    };
  };

  useEffect(() => {
    if (!shouldShowSkeleton) {
      setTimeout(() => virtualizer.measure(), 1000);
    }
  }, [shouldShowSkeleton, virtualizer]);

  // if we're infinite scrolling, still render what we have, even if we're loading
  if (shouldShowSkeleton) {
    return <ListSkeleton />;
  } else if (columns.length < 1) {
    return <EmptyState heading="All columns are currently hidden." />;
  } else if (data.length < 1) {
    return emptyStateOverride ? (
      emptyStateOverride
    ) : (
      <EmptyState heading="No matches. Try modifying your filters." />
    );
  }

  return (
    <Box
      width="100%"
      maxWidth="100%"
      justifyContent="flex-start"
      borderWidth="1px"
      borderColor="var(--chakra-colors-chakra-border-color)"
      borderRadius="lg"
      display="inline-flex"
      overflowY="hidden"
      data-testid="listview-table"
      {...props}
    >
      <Box
        className={clsx("table", isTableResizing() && "noselect")}
        {...getTableProps()}
        zIndex="1"
        minWidth="100%"
        borderRadius="lg"
      >
        <Box borderTopRadius="lg">
          {headerGroups.map((headerGroup, index) => (
            <Box
              {...headerGroup.getHeaderGroupProps()}
              key={index}
              className="tr"
              minWidth="100%"
              borderBottom="1px solid"
              borderColor="var(--chakra-colors-chakra-border-color)"
            >
              {headerGroup.headers.map((column) =>
                column.render("Header", { key: column.id })
              )}
            </Box>
          ))}
        </Box>
        <Box
          {...getTableBodyProps()}
          height={`${virtualizer.getTotalSize()}px`}
          position="relative"
          ref={rowContainerRefCallback}
        >
          {virtualizer.getVirtualItems().map((item) => {
            const row = rows[item.index];
            if (!row) {
              return;
            }

            prepareRow(row);
            return (
              // eslint-disable-next-line
              <Box
                {...row.getRowProps()}
                className="tr"
                py="12px"
                fontSize="14px"
                width="100%"
                height="50px"
                top="0"
                left="0"
                position="absolute"
                transform={`translateY(${item.start - (rowOffset || 0)}px)`}
                _hover={{
                  background: bgHover,
                  textDecoration: "none",
                  ...(hoverProps || {}),
                }}
                cursor={onClickRow ? "pointer" : "auto"}
                onClick={rowClickHandler(row.original)}
                onKeyDown={rowKeyHandler(row.original)}
                _focus={{
                  background: bg,
                }}
                tabIndex={0}
                {...(row.index !== rows.length - 1
                  ? {
                      borderBottom: "1px solid",
                      borderColor: "var(--chakra-colors-chakra-border-color)",
                    }
                  : {})}
                minWidth="100%"
              >
                {row.cells.map((cell) => (
                  // eslint-disable-next-line
                  <Flex {...cell.getCellProps()} className="td" border="none">
                    {cell.render("Cell")}
                  </Flex>
                ))}
              </Box>
            );
          })}
        </Box>
        {loading && onInfiniteScrollRequestMoreData && (
          <Flex
            alignItems="center"
            justifyContent="center"
            pt="4"
            mb="4"
            gap="2"
            borderTop="1px solid var(--chakra-colors-chakra-border-color)"
          >
            <Spinner size="sm" />
            <Text>Fetching more items...</Text>
          </Flex>
        )}
        {children}
      </Box>
    </Box>
  );
};
