import {
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
} from "@chakra-ui/react";
import Color from "color";
import { forwardRef } from "react";
import { useColors } from "../hooks/useColors";
import { Link } from "./Link";

export interface ButtonProps extends ChakraButtonProps {
  asLink?: boolean;
  to?: string;
  variant?: "primary" | "secondary" | "ghost" | "error" | "tertiary";
}

const getVariantProps = (
  variant: ButtonProps["variant"] = "primary",
  colors: ReturnType<typeof useColors>
) => {
  return {
    primary: {
      colorScheme: "brand",
      color: "black",
      _hover: { background: Color(colors.brand).darken(0.25).hex() },
    },
    secondary: {
      background: colors.bg4,
      border: "1px solid var(--chakra-colors-chakra-border-color)",
      _hover: { background: colors.bg },
      _active: { background: Color(colors.bg).darken(0.015).hex() },
    },
    tertiary: {
      background: colors.bgHover,
      border: "1px solid var(--chakra-colors-chakra-border-color)",
      _hover: { background: colors.bg },
      _active: { background: Color(colors.bg).darken(0.015).hex() },
    },
    ghost: {
      background: "transparent",
      border: "none",
      boxShadow: "none",
      _hover: { background: "transparent" },
      _active: { background: "transparent" },
    },
    error: {
      colorScheme: "red",
    },
  }[variant];
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ asLink = false, to = undefined, variant, ...buttonProps }, ref) => {
    const colors = useColors();
    const variantProps = getVariantProps(variant, colors);
    const linkProps = asLink && to ? { as: Link, to, noUnderline: true } : {};

    return (
      <ChakraButton
        boxShadow="md"
        fontSize="14px"
        minW="fit-content"
        to={to}
        {...linkProps}
        {...variantProps}
        {...buttonProps}
        ref={ref}
      />
    );
  }
);
