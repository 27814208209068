import { CenterLoading } from "@zeet/web-ui";
import React, { useMemo } from "react";
import { Helmet } from "react-helmet";
import { useBillingOverviewContext } from "../Account/BillingV2/Providers/BillingOverviewProvider";
import AdvancedSupport from "./Advanced";
import BasicSupport from "./Basic";
import SupportSidebarWrapper from "./SupportSidebar";
import { determineSupportLevel, getIsAdvanced } from "./utils";

const Support: React.FC = () => {
  const [{ userBillingOverview }] = useBillingOverviewContext();
  const isAdvanced = useMemo(() => {
    return getIsAdvanced(determineSupportLevel(userBillingOverview));
  }, [userBillingOverview]);

  if (!userBillingOverview) {
    return <CenterLoading />;
  }

  return (
    <>
      <Helmet>
        <title>Support</title>
      </Helmet>
      <SupportSidebarWrapper>
        {isAdvanced ? <AdvancedSupport /> : <BasicSupport />}
      </SupportSidebarWrapper>
    </>
  );
};

export default Support;
