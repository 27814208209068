import { Box, Tooltip } from "@chakra-ui/react";
import TimeAgo from "react-timeago";

interface TimeBoxProps {
  prefix?: string;
  time?: Date | null;
}

export const TimeBox: React.FC<TimeBoxProps> = ({
  prefix,
  time,
  ...boxProps
}) => {
  return (
    <Tooltip label={time?.toLocaleString()}>
      <Box as="span" {...boxProps}>
        {prefix ? `${prefix} ` : null}
        <TimeAgo date={time || 0} />
      </Box>
    </Tooltip>
  );
};
