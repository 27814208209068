import { WorkflowRunStepDetailFragment } from "@zeet/web-api/dist/graphqlv1";
import { GenericStepRunDetail } from "./GenericRunStepDetail";

interface BuildStepRunDetailProps {
  step: WorkflowRunStepDetailFragment;
  runId: string;
  projectId: string;
}

export const BuildStepRunDetail = ({
  step,
  projectId,
  runId,
}: BuildStepRunDetailProps) => {
  return (
    <GenericStepRunDetail step={step} projectId={projectId} runId={runId} />
  );
};
