import { Flex, Stack, TabList, TabPanels, Tabs } from "@chakra-ui/react";
import { Button } from "@zeet/web-ui";
import { useState } from "react";
import { SourceProps } from ".";
import { SourceIcon } from "./SourceIcon";
import { SourceTab } from "./SourceTab";
import { SourceTabPanel } from "./SourceTabPanel";

interface SourceEditorProps extends SourceProps {
  onSourceEditClose: () => void;
}

export const SourceEditor = ({
  validSources,
  onSourceEditClose,
  onSourceChange,
  source,
  ...props
}: SourceEditorProps) => {
  const [currentSource, setCurrentSource] = useState(source);

  const getInitialIndex = () => {
    if (source?.kind) {
      return validSources?.indexOf(source.kind) ?? 0;
    }
    return 0;
  };

  const [index, setIndex] = useState(getInitialIndex());

  const maybeChangeSource = () => {
    if (currentSource) {
      onSourceChange?.(currentSource);
    }
  };

  return (
    <Flex
      alignItems="center"
      width="100%"
      borderRadius="md"
      borderColor="inherit"
      justifyContent="space-between"
      {...props}
    >
      <Stack width="100%">
        <Tabs
          variant="unstyled"
          defaultIndex={0}
          index={index}
          onChange={(index) => setIndex(index)}
          isLazy
        >
          <TabList gap="4" overflowX="auto" p="1">
            {validSources?.map((sourceKind, index) => (
              <SourceTab
                key={index}
                leftIcon={<SourceIcon sourceKind={sourceKind} />}
              >
                {sourceKind}
              </SourceTab>
            ))}
          </TabList>
          <TabPanels>
            {validSources?.map((sourceKind, index) => (
              <SourceTabPanel
                key={index}
                sourceKind={sourceKind}
                currentSource={currentSource}
                onSourceChange={setCurrentSource}
              />
            ))}
          </TabPanels>
        </Tabs>
        <Flex gap="2">
          {source ? (
            <>
              <Button onClick={maybeChangeSource}>Save</Button>
              <Button onClick={onSourceEditClose} variant="secondary">
                Cancel
              </Button>
            </>
          ) : (
            <Button onClick={maybeChangeSource}>Continue</Button>
          )}
        </Flex>
      </Stack>
    </Flex>
  );
};
