import { ColorModeScript } from "@chakra-ui/react";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { getUserId, PosthogProvider, theme } from "@zeet/web-ui";
import { createRoot } from "react-dom/client";
import App from "~/components/App";
import config from "~/utils/config";
import "./index.scss";

declare global {
  interface Window {
    $crisp: any;
    //__zeet_data__
    __zeet_data__: Record<string, string>;
    //temporary
    __zSetTheme: (theme: string) => void;
    //gtag
    gtag: (a: string, b: string, c: Record<string, unknown>) => void;
    dataLayer: any[];
    _hsq: any[];
  }
}

if (!config.isDev) {
  Sentry.init({
    dsn: "https://49504687742241c38e6364ccf95a3d38@o398488.ingest.sentry.io/5254292",
    environment: config.ENV,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 0.01,
    integrations: [
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
      new BrowserTracing(),
    ],
  });
}

const container = document.getElementById("root");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
  <>
    <ColorModeScript initialColorMode={theme.default.config.initialColorMode} />
    <PosthogProvider
      getUniqueId={getUserId}
      token={config.POSTHOG_API_KEY}
      config={{
        api_host: config.POSTHOG_API_HOST,
        debug: config.isDev,
        disable_session_recording: config.isDev,
      }}
      name="zeet"
    >
      <App />
    </PosthogProvider>
  </>
);
