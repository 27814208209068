import { Flex, Heading, Input, Text } from "@chakra-ui/react";
import { CloudProvider, RepoDetailFragment } from "@zeet/web-api/dist/graphql";
import { Card, Link, Tooltip, useCurrentTeamUser } from "@zeet/web-ui";
import React, { useState } from "react";
import { FaAws } from "react-icons/fa";
import { CloudProviderRoutes } from "../../Console/ConsoleClouds/Details";
import { ZFormLabel } from "./Build";

export const LambdaDeployInfo: React.FC<{ repo: RepoDetailFragment }> = ({
  repo,
}) => {
  const currentTeamUser = useCurrentTeamUser();
  const [region] = useState(
    repo.serverlessRegion === null ? "us-west-2" : repo.serverlessRegion
  );
  return (
    <Card pb={4}>
      <Flex flexDir="column" px={6} pt={6}>
        <Heading mb={3} size="md">
          <Flex justifyContent="space-between">
            <Text>{"Deployment Information"}</Text>
          </Flex>
        </Heading>

        <Flex display="inline-flex" alignItems="center" mb={2} mt={2}>
          <Text mr={2}>Target Account</Text>
          <Link
            rel="noopener noreferrer"
            to={`/${currentTeamUser.login}/console/clouds/${
              CloudProviderRoutes[CloudProvider.Aws]
            }/${repo.awsAccount?.accountID}`}
            isExternal
          >
            <Flex display="inline-flex" alignItems="center">
              <FaAws />
              <Text ml={2} color="brandVar">
                {`${repo.awsAccount?.accountID}`}
              </Text>
            </Flex>
          </Link>
        </Flex>
        <Flex display="inline-flex" alignItems="center">
          <ZFormLabel>
            Target Region
            <Tooltip text="Region that your Serverless resource is deployed to. When invoked, a compute instance will be spun up in this region to service the request." />
          </ZFormLabel>
          <Input
            flexGrow={1}
            className="nice-digits"
            isDisabled={true}
            name="cpu"
            value={region}
          />
        </Flex>
      </Flex>
    </Card>
  );
};
