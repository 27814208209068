import { FormControl, Switch } from "@chakra-ui/react";
import { FormLabel, Input } from "@zeet/web-ui";
import { useState } from "react";

interface GitBranchProps {
  value?: string;
  onChange?: (value: string) => void;
}

export const GitBranch = ({ value, onChange }: GitBranchProps) => {
  const [isDefaultBranch, setIsDefaultBranch] = useState(!value);

  return (
    <>
      <FormControl mt="4" display="flex" alignItems="center" gap="2">
        <Switch
          isChecked={isDefaultBranch}
          onChange={(e) => {
            setIsDefaultBranch(e.target.checked);
            onChange?.("");
          }}
        />
        <FormLabel mb="0">Use default branch (i.e., main)</FormLabel>
      </FormControl>
      <FormControl mt="4" hidden={isDefaultBranch}>
        <FormLabel>Branch (or other git ref)</FormLabel>
        <Input
          placeholder="main"
          value={value}
          onChange={(e) => onChange?.(e.target.value)}
        />
      </FormControl>
    </>
  );
};
