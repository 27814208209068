import {
  Box,
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import {
  useMoveRepoMutation,
  useUserBasicReposQuery,
} from "@zeet/web-api/dist/graphql";
import { Loading, useCurrentTeamUser, ZError } from "@zeet/web-ui";
import React from "react";
import { FaDocker } from "react-icons/fa";
import { LanguageIcon } from "../../LanguageIcon";
import { useEnv, useProject } from "../Provider";

export const MoveAppToEnv: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  const currentTeamUser = useCurrentTeamUser();
  const toast = useToast();
  const project = useProject();
  const env = useEnv();

  const { data, loading: l1 } = useUserBasicReposQuery({
    variables: {
      id: currentTeamUser.id,
    },
  });

  const [filter, setFilter] = React.useState("");

  const [moveRepo, { error }] = useMoveRepoMutation({
    onCompleted: (data) => {
      if (data) {
        toast({
          title: "Resource Moved",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        onClose();
      }
    },
    update(cache) {
      cache.modify({
        id: cache.identify(env),
        fields: {
          repos(_, { DELETE }) {
            return DELETE;
          },
        },
      });
    },
  });

  if (l1) return <Loading />;

  const onSubmit = (values) => {
    moveRepo({
      variables: {
        input: {
          id: values.id,
          projectID: project.id,
          environmentID: env.id,
        },
      },
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl" preserveScrollBarGap>
      <ModalOverlay />
      <ModalContent p={8}>
        <ModalHeader p={0}>
          Move project to <b>{`${project.name}/${env.name}`}</b>
        </ModalHeader>
        <ModalBody>
          <Input
            name="filter"
            onChange={(e) => setFilter(e.target.value)}
            placeholder="Filter Projects..."
          />

          <Stack maxHeight={"50vh"} overflowY="scroll">
            {data?.user?.repos
              ?.slice()
              ?.filter(
                (repo) =>
                  `${repo.project?.name}/${repo.projectEnvironment?.name}/${repo.name}`.includes(
                    filter.trim().toLowerCase()
                  ) && repo?.projectEnvironment?.id !== env.id
              )
              .sort((a, b) => a.name.localeCompare(b.name))
              ?.map((repo) => {
                let repoIcon: React.ReactElement;
                if (repo.image === "docker") {
                  repoIcon = <FaDocker size="1.5rem" color="#0db7ed" />;
                } else {
                  repoIcon = (
                    <LanguageIcon language={repo.image} w="1.5rem" h="1.5rem" />
                  );
                }

                return (
                  <Flex
                    minWidth={["300px", "500px"]}
                    alignItems="center"
                    key={repo.id}
                    my={2}
                  >
                    <Flex
                      padding={2}
                      borderRadius={5}
                      alignItems="center"
                      shadow="sm"
                      flex={"5 0 10rem"}
                    >
                      <Flex flex={3} minWidth={0}>
                        {repoIcon}
                        <Text ml={2} fontWeight="600">
                          {`${repo.project?.name}/${repo.projectEnvironment?.name}/${repo.name}`}
                        </Text>
                      </Flex>
                    </Flex>
                    <Button
                      ml={4}
                      flex={"1 0 3rem"}
                      colorScheme="brand"
                      size="sm"
                      shadow="md"
                      onClick={() => {
                        onSubmit({ id: repo.id });
                      }}
                    >
                      Move
                    </Button>
                  </Flex>
                );
              })}
          </Stack>
          <Box pt={8}>
            {error?.message && (
              <Box pb={8}>
                <ZError error={error.message} />
              </Box>
            )}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
