import {
  DeploymentDetailFragment,
  RepoDetailFragment,
  UserDetailFragment,
} from "@zeet/web-api/dist/graphql";

export class AbsoluteRoutes {
  static deploymentAlerts = (
    currentTeamUser: UserDetailFragment,
    repo: RepoDetailFragment,
    deployment: DeploymentDetailFragment
  ) => {
    return repo.project && repo.projectEnvironment
      ? `/${currentTeamUser.login}/${repo.project.name}/${repo.projectEnvironment.name}/${repo.name}/deployments/${deployment.id}/alerts`
      : null;
  };

  static teamNotificationSettings = (currentTeamUser: UserDetailFragment) => {
    return `/${currentTeamUser.login}/account/notifications`;
  };
}
