import { DeepPartial, Link, Tag, useDisclosure } from "@chakra-ui/react";
import { RevisableEntity } from "@zeet/web-api/dist/graphqlv1";
import { getRevisionDescription, RevisionDetailDrawer } from "@zeet/web-ui";
import { useState } from "react";

interface RevisionMetadataCellProps {
  revision: DeepPartial<RevisableEntity>;
  revisions: DeepPartial<RevisableEntity>[];
}

export const RevisionCell = ({
  revision,
  revisions,
}: RevisionMetadataCellProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentRevisionSequenceId, setCurrentRevisionSequenceId] =
    useState<number>(revision?.revisionMetadata?.sequenceId ?? 0);

  const latestRevision = revisions?.[0];

  const isLatestRevision =
    revision?.revisionMetadata?.sequenceId ===
    latestRevision?.revisionMetadata?.sequenceId;

  const onPreviousRevision = () => {
    const previousRevision = revisions?.find(
      (r) => r?.revisionMetadata?.sequenceId === currentRevisionSequenceId - 1
    );
    if (previousRevision?.revisionMetadata?.sequenceId !== undefined) {
      setCurrentRevisionSequenceId(
        previousRevision?.revisionMetadata?.sequenceId
      );
    }
  };

  const onNextRevision = () => {
    const nextRevision = revisions?.find(
      (r) => r?.revisionMetadata?.sequenceId === currentRevisionSequenceId + 1
    );
    if (nextRevision?.revisionMetadata?.sequenceId !== undefined) {
      setCurrentRevisionSequenceId(nextRevision?.revisionMetadata?.sequenceId);
    }
  };

  const currentRevision = revisions?.find(
    (r) => r?.revisionMetadata?.sequenceId === currentRevisionSequenceId
  );

  const onRevisionClose = () => {
    setCurrentRevisionSequenceId(revision?.revisionMetadata?.sequenceId ?? 0);
    onClose();
  };

  return (
    <>
      <Link
        onClick={onOpen}
        _hover={{
          textDecoration: "none",
          color: "brandVar",
        }}
        overflow="hidden"
      >
        Revision #{revision?.revisionMetadata?.sequenceId}:{" "}
        {getRevisionDescription(revision)}
        {isLatestRevision && (
          <Tag ml="2" size="sm">
            latest
          </Tag>
        )}
      </Link>

      <RevisionDetailDrawer
        isOpen={isOpen}
        onClose={onRevisionClose}
        revision={currentRevision}
        revisions={revisions}
        onPreviousRevision={onPreviousRevision}
        onNextRevision={onNextRevision}
      />
    </>
  );
};
