import { CloudAccount, CloudProvider } from "@zeet/web-api/dist/graphql";
import { UnionOfParameter, ValuesOfMap } from "~/utils/ts-utils";

export const CloudProviderNameMap: { [key in CloudProvider]: string } = {
  [CloudProvider.Aws]: "AWS",
  [CloudProvider.Gcp]: "GCP",
  [CloudProvider.Do]: "DigitalOcean",
  [CloudProvider.Coreweave]: "CoreWeave",
  [CloudProvider.Linode]: "Akamai Linode",
  [CloudProvider.Vultr]: "Vultr",
  [CloudProvider.Azure]: "Azure",
  // not officially supported
  [CloudProvider.Alibaba]: "Alibaba",
  [CloudProvider.Ibm]: "IBM",
  [CloudProvider.Oci]: "OCI",
  [CloudProvider.Ovh]: "OVH",
  [CloudProvider.Scaleway]: "Scaleway",
  [CloudProvider.Tencent]: "Tencent",
  [CloudProvider.Unknown]: "Unknown",
  [CloudProvider.Zeet]: "Zeet",
};

type CloudLabelParams = {
  provider: string;

  // the user specified name of the cloud account
  displayName: string;

  // ideally an uniquely identifying property from the external cloud provider
  // fallback to user specified name if not available
  // fallback to the zeet account uuid if not available
  identifier: string;
};

/*
This is the formatting strategy used in the cloud target selection page of the latest new project flow.
*/
export const formatCloudProviderLabel = (target: CloudLabelParams): string => {
  return `${CloudProviderNameMap[target.provider]}${
    target.provider !== target.displayName && target.displayName
      ? " - " + target.displayName
      : ""
  }${target.identifier && ` (${target.identifier})`}`;
};

/*
This is the formatting strategy used in the cloud name column of the cloud list view in the console.
*/
export const formatCloudNameLabel = (target: CloudLabelParams): string => {
  return target.displayName || target.identifier;
};

/*
This is the formatting strategy used in the cloud identifier column of the cloud list view in the console.
*/
export const formatCloudIdLabel = (target: CloudLabelParams): string => {
  return target.identifier;
};

interface NameableAWSAccount {
  id: string;
  name: string;
  accountID?: string | null;
}

export const getAwsLabelParams = (
  account: NameableAWSAccount
): CloudLabelParams => ({
  provider: CloudProvider.Aws,
  displayName: account.name,
  identifier: account?.accountID || account.name || account.id,
});

interface NameableLinodeAccount {
  id: string;
  name: string;
  accessTokenPrefix?: string | null;
}

export const getLinodeLabelParams = (
  account: NameableLinodeAccount
): CloudLabelParams => ({
  provider: CloudProvider.Linode,
  displayName: account.name,
  identifier: account?.accessTokenPrefix || account.name || account.id,
});

interface NameableDoAccount {
  id: string;
  name: string;
  accessTokenPrefix?: string | null;
  defaultProject?: string | null;
}

export const getDoLabelParams = (
  account: NameableDoAccount
): CloudLabelParams => ({
  provider: CloudProvider.Do,
  displayName: account.name,
  identifier:
    account.defaultProject ||
    account?.accessTokenPrefix ||
    account.name ||
    account.id,
});

interface NameableGcpAccount {
  id: string;
  name: string;
  projectID?: string | null;
}

export const getGcpLabelParams = (
  account: NameableGcpAccount
): CloudLabelParams => ({
  provider: CloudProvider.Gcp,
  displayName: account.name,
  identifier: account?.projectID || account.name || account.id,
});

interface NameableCoreweaveAccount {
  id: string;
  name: string;
  namespace?: string | null;
}

export const getCoreweaveLabelParams = (
  account: NameableCoreweaveAccount
): CloudLabelParams => ({
  provider: CloudProvider.Coreweave,
  displayName: account.name,
  identifier: account?.namespace || account.name || account.id,
});

interface NameableVultrAccount {
  id: string;
  name: string;
}

export const getVultrLabelParams = (
  account: NameableVultrAccount
): CloudLabelParams => ({
  provider: CloudProvider.Vultr,
  displayName: account.name,
  identifier: account.name || account.id,
});

interface NameableAzureAccount {
  id: string;
  name: string;
  subscriptionID?: string | null;
}

export const getAzureLabelParams = (
  account: NameableAzureAccount
): CloudLabelParams => ({
  provider: CloudProvider.Azure,
  displayName: account.name,
  identifier: account?.subscriptionID || account.name || account.id,
});

const cloudAccountMap = {
  [CloudProvider.Aws]: getAwsLabelParams,
  [CloudProvider.Linode]: getLinodeLabelParams,
  [CloudProvider.Do]: getDoLabelParams,
  [CloudProvider.Gcp]: getGcpLabelParams,
  [CloudProvider.Coreweave]: getCoreweaveLabelParams,
  [CloudProvider.Vultr]: getVultrLabelParams,
  [CloudProvider.Azure]: getAzureLabelParams,
};

type NameableCloudAccount = UnionOfParameter<
  ValuesOfMap<typeof cloudAccountMap>
>;

export const getCloudAccountLabelParams = (
  account: NameableCloudAccount & Pick<CloudAccount, "__typename">
): CloudLabelParams => {
  switch (account.__typename) {
    case "AWSAccount":
      return getAwsLabelParams(account);
    case "LinodeAccount":
      return getLinodeLabelParams(account);
    case "DOAccount":
      return getDoLabelParams(account);
    case "GCPAccount":
      return getGcpLabelParams(account);
    case "CoreWeaveAccount":
      return getCoreweaveLabelParams(account);
    case "VultrAccount":
      return getVultrLabelParams(account);
    case "AzureAccount":
      return getAzureLabelParams(account);
  }

  return {
    provider: CloudProvider.Unknown,
    displayName: "Unknown",
    identifier: "Unknown",
  };
};
