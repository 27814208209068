import { RepeatIcon } from "@chakra-ui/icons";
import {
  AbsoluteCenter,
  Flex,
  FormControl,
  Heading,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Switch,
  Text,
  useToast,
} from "@chakra-ui/react";
import {
  EphemeralContainer,
  useCreateEphemeralContainerMutation,
  UserEphemeralContainersDocument,
  useUserEphemeralContainersQuery,
} from "@zeet/web-api/dist/graphql";
import {
  Button,
  CenterLoading,
  FormLabel,
  Select,
  Tooltip,
} from "@zeet/web-ui";
import React, { useEffect, useMemo, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { DeploymentTerminal } from "../../Terminal";

interface DebugTerminalPopupProps {
  deploymentId: string;
  onClose: () => unknown;
  repoImage: string | null | undefined;
}

export const DebugTerminalPopup = ({
  deploymentId,
  onClose,
  repoImage,
}: DebugTerminalPopupProps) => {
  const toast = useToast();

  const { data, loading } = useUserEphemeralContainersQuery({
    variables: {
      id: deploymentId,
    },
    pollInterval: 5000,
  });

  const existingContainers = useMemo(
    () =>
      (data?.currentUser.deployment?.ephemeralContainers ?? []).filter(
        (c) => !!c
      ) as EphemeralContainer[],
    [data]
  );

  const hasDebugContainers = existingContainers.length > 0;
  const [imageInput, setImageInput] = useState("ubuntu:latest");
  const [showCreateContainer, setShowCreateContainer] = useState(false);
  const [currentTerminalId, setCurrentTerminalId] = useState<string | null>(
    hasDebugContainers ? existingContainers[0]?.name ?? null : null
  );

  useEffect(() => {
    if (!currentTerminalId && existingContainers.length > 0) {
      setCurrentTerminalId(existingContainers[0]?.name ?? null);
    }
  }, [currentTerminalId, existingContainers]);

  const [imageInputDisabled, setImageInputDisabled] = useState(!!repoImage);

  const [createEphemeralContainer, { loading: createLoading }] =
    useCreateEphemeralContainerMutation({
      refetchQueries: [
        {
          query: UserEphemeralContainersDocument,
          variables: { id: deploymentId },
        },
      ],
    });

  return (
    <Modal onClose={onClose} preserveScrollBarGap isOpen>
      <ModalOverlay />
      <ModalContent
        maxWidth="min(80vw, 1000px)"
        display="flex"
        flexDir="column"
        height="80vh"
      >
        <ModalHeader display="flex" alignItems="center" gap={4}>
          <Text>Debug Terminal</Text>
          <Spacer />
          {hasDebugContainers && (
            <>
              <Select
                tabIndex={-1}
                captureMenuScroll={false}
                chakraStyles={{
                  container: (provided) => ({
                    ...provided,
                    width: "225px",
                  }),
                }}
                isSearchable={false}
                useBasicStyles
                value={currentTerminalId}
                onChange={(v) => {
                  if (!v) return;
                  const c = existingContainers.find((c) => c.name === v.value);
                  if (!c) return;
                  setCurrentTerminalId(c.name);
                  setShowCreateContainer(false);
                }}
                isOptionDisabled={(opt) => !opt.ready}
                options={[
                  ...existingContainers.map((c) => ({
                    label: `${c.name} (${c.image})`,
                    value: c.name,
                    ready: c.ready,
                  })),
                ]}
              />
              <Button
                onClick={() => setShowCreateContainer(true)}
                variant="secondary"
              >
                Create
              </Button>
              <Button
                onClick={async () => {
                  await setCurrentTerminalId(null);
                  await setCurrentTerminalId(currentTerminalId);
                }}
                variant="secondary"
              >
                <RepeatIcon />
              </Button>
            </>
          )}
          <IconButton
            icon={<IoMdClose />}
            aria-label="close"
            onClick={onClose}
          />
        </ModalHeader>

        <ModalBody width="100%" flexGrow={1} p={0}>
          {loading ? (
            <CenterLoading />
          ) : !hasDebugContainers || showCreateContainer ? (
            <Stack alignItems="center" height="100%" pb="12">
              <Flex
                flexDirection="column"
                maxW="500px"
                margin="auto"
                gap="4"
                justifyContent="center"
                minH="50%"
                p="8"
                borderRadius="md"
              >
                <Heading size="md">Launch new debug container</Heading>
                <Text opacity="0.5">
                  A debug container can help you investigate problems with an
                  existing deployment.{" "}
                  <a href="https://kubernetes.io/docs/tasks/debug/debug-application/debug-running-pod/#ephemeral-container">
                    Learn more
                  </a>
                </Text>
                {!!repoImage && (
                  <FormControl as={Flex} alignItems="center" gap="2">
                    <Switch
                      defaultChecked={imageInputDisabled}
                      onChange={(e) => {
                        if (repoImage) setImageInput(repoImage);
                        setImageInputDisabled(e.target.checked);
                      }}
                    />

                    <FormLabel mb="0">
                      Use latest build image
                      <Tooltip text="This option is only available to deployments that contain a build" />
                    </FormLabel>
                  </FormControl>
                )}
                <FormControl isDisabled={imageInputDisabled}>
                  <FormLabel>Or, use custom image</FormLabel>
                  <Input
                    placeholder={imageInput}
                    onChange={(e) => setImageInput(e.target.value)}
                    value={imageInput}
                  />
                </FormControl>
                <Button
                  mt="6"
                  isLoading={createLoading}
                  onClick={async () => {
                    const res = await createEphemeralContainer({
                      variables: {
                        deploymentId,
                        input: {
                          image: imageInput,
                        },
                      },
                    });
                    if (res.data) {
                      setCurrentTerminalId(
                        res.data.createEphemeralContainer.name
                      );
                    }
                    if (res.errors) {
                      toast({
                        title:
                          res.errors[0]?.message ??
                          "Unknown error when creating container",
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                      });
                    }
                    setShowCreateContainer(false);
                  }}
                >
                  Launch and connect
                </Button>
              </Flex>
            </Stack>
          ) : currentTerminalId &&
            existingContainers.find((c) => c.name === currentTerminalId)
              ?.ready ? (
            <DeploymentTerminal
              deploymentID={deploymentId}
              containerName={currentTerminalId}
              fillContainer
              type="ephemeral"
            />
          ) : (
            <AbsoluteCenter>
              <Flex flexDirection={"column"}>
                <CenterLoading />
                <Text marginTop={"2"}>
                  Waiting for container to be ready. Taking too long? Your
                  container may be corrupted, or is being created.
                </Text>
              </Flex>
            </AbsoluteCenter>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
