import { Box } from "@chakra-ui/react";
import { Skeleton } from "@zeet/web-ui";
import React from "react";

export const JobsSkeleton: React.FC = () => {
  return (
    <Box>
      <Skeleton height="1.75rem" borderRadius="6px" width="6rem" />
      {Array.from(Array(8).keys()).map((element) => (
        <Skeleton
          key={element}
          height="6rem"
          width="100%"
          borderRadius="6px"
          marginTop="1rem"
        />
      ))}
    </Box>
  );
};
