import { Flex, Grid, GridItem, Heading, Text } from "@chakra-ui/react";
import { useProjectBranchesLazyQuery } from "@zeet/web-api/dist/graphql";
import { useWorkflowRunsLazyQuery } from "@zeet/web-api/dist/graphqlv1";
import {
  Card,
  CenterLoading,
  Link,
  TimeBox,
  useCurrentTeamUser,
  useEffectOnce,
} from "@zeet/web-ui";
import { useState } from "react";
import { useProjectV3Context } from "~/components/Project/ProjectV3Provider";
import { useProjectPath } from "../hooks/useProjectPath";
import { ResourceLatestDeploymentData } from "./utils";

const GrayText: React.FC<React.PropsWithChildren> = (props) => {
  return (
    <Text color="gray.500" textTransform="uppercase" fontSize={14} {...props} />
  );
};

export const ResourceDetailTopView: React.FC<{
  projectId: string;
}> = ({ projectId }) => {
  const path = useProjectPath();
  const [deploymentData, setDeploymentData] = useState<
    ResourceLatestDeploymentData | undefined
  >(undefined);
  const { id: teamId } = useCurrentTeamUser();
  const { projectData, projectLoading } = useProjectV3Context();

  const [fetchWorkflow, { loading: loadingWorkflow }] =
    useWorkflowRunsLazyQuery({
      variables: {
        projectId,
        teamId,
        page: { after: "0", first: 1 },
      },
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        const latestWorkflowRun =
          data?.team?.project?.workflow?.runs.nodes?.[0];

        if (!latestWorkflowRun) {
          return;
        }

        setDeploymentData({
          link: `${path}/workflows?workflowId=${latestWorkflowRun?.id}`,
          linkContent: `Workflow Run #${latestWorkflowRun.sequence}`,
          createdAt: latestWorkflowRun.createdAt,
        });
      },
    });

  const repoId = projectData?.user.projectV3Adapters?.nodes[0]?.repo?.id;

  const [fetchRepoBranch, { loading: loadingRepoBranch }] =
    useProjectBranchesLazyQuery({
      variables: {
        id: repoId ?? "",
        active: true,
      },
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        const latestDeployment =
          data.currentUser.repo?.productionBranchV2?.latestDeployment;

        if (!latestDeployment) {
          return;
        }

        setDeploymentData({
          link: `${path}/deployments/${latestDeployment.id}`,
          linkContent: `${latestDeployment.id.slice(0, 4)} (${
            latestDeployment.branch
          })`,
          createdAt: latestDeployment.createdAt,
        });
      },
    });

  useEffectOnce(() => {
    if (repoId) {
      fetchRepoBranch();
    } else {
      fetchWorkflow();
    }
  });

  if (projectLoading || loadingWorkflow || loadingRepoBranch) {
    return <CenterLoading />;
  }

  if (!deploymentData) {
    return null;
  }

  return (
    <>
      <Card p={4} my={4}>
        <Flex alignItems="center" mb={3}>
          <Heading
            size="md"
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
            maxWidth="30rem"
          >
            Resources Overview
          </Heading>
        </Flex>
        <Grid gap={4} alignItems="center" templateColumns="repeat(2, 1fr)">
          <GridItem>
            <GrayText>Deployment</GrayText>
            <Flex>
              <Link mr="1" to={deploymentData.link}>
                {deploymentData.linkContent}
              </Link>
            </Flex>
          </GridItem>
          <GridItem>
            <GrayText>Created</GrayText>
            <Flex align="center" display="inline-flex">
              <TimeBox time={deploymentData.createdAt} />
            </Flex>
          </GridItem>
        </Grid>
      </Card>
    </>
  );
};
