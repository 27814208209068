import { CopyIcon } from "@chakra-ui/icons";
import { useToast } from "@chakra-ui/react";
import copy from "copy-to-clipboard";
import React from "react";
import { Button, ButtonProps } from "../Button";

export interface CopyProps extends ButtonProps {
  children?: React.ReactNode;
  text: string;
}

export const CopyButton: React.FC<CopyProps> = ({
  text,
  children,
  ...rest
}) => {
  const toast = useToast();
  return (
    <Button
      leftIcon={<CopyIcon />}
      onClick={() => {
        copy(text);
        toast({
          title: "Copied to clipboard",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }}
      {...rest}
    >
      {children}
    </Button>
  );
};
