import { Stack } from "@chakra-ui/react";
import { DeleteProjectAction } from "./actions/delete";
import { DestroyAction } from "./actions/destroy";
import { DestroyResourcesAction } from "./actions/destroyResources";
import { PauseAction } from "./actions/pause";
import { ResumeAction } from "./actions/resume";
import { UnlinkAction } from "./actions/unlink";

export enum DeletionType {
  Pause,
  Resume,
  Delete,
  DeleteWithResources,
  DestroyResources,
}

interface DeleteFlowProps {
  callback(type: DeletionType, force?: boolean): Promise<void>;
  projectName: string;
  canDelete?: boolean;
  canDestroy?: boolean;
  canDestroyResources?: boolean;
  canPause?: boolean;
  canResume?: boolean;
  canUnlink?: boolean;
}

export const DeleteFlow = ({
  callback,
  projectName,
  canDelete,
  canDestroy,
  canDestroyResources,
  canPause,
  canResume,
  canUnlink,
}: DeleteFlowProps) => {
  return (
    <Stack gap={8}>
      {canResume && (
        <ResumeAction
          onAction={() => callback(DeletionType.Resume)}
          name={projectName}
        />
      )}
      {canDelete && (
        <DeleteProjectAction
          onAction={() => callback(DeletionType.Delete)}
          name={projectName}
        />
      )}
      {canPause && (
        <PauseAction
          onAction={() => callback(DeletionType.Pause)}
          name={projectName}
        />
      )}
      {canUnlink && (
        <UnlinkAction
          onAction={() => callback(DeletionType.Delete, true)}
          name={projectName}
        />
      )}

      {canDestroyResources && (
        <DestroyResourcesAction
          onAction={() => callback(DeletionType.DestroyResources)}
          name={projectName}
        />
      )}
      {canDestroy && (
        <DestroyAction
          onAction={(f) => callback(DeletionType.DeleteWithResources, f)}
          name={projectName}
        />
      )}
    </Stack>
  );
};
