import {
  useProjectEnvironmentQuery,
  useUserRepoQuery,
} from "@zeet/web-api/dist/graphql";
import { CenterLoading, ZError } from "@zeet/web-ui";
import { useEffect } from "react";
import {
  Redirect,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import Environment from "./Environment";
import { ProjectEnvProvider, useProject } from "./Provider";
import { projectPath, repoPath } from "./util";

export const ProjectEnvRedirect = () => {
  const history = useHistory();
  const project = useProject();
  const match = useRouteMatch();

  useEffect(() => {
    if (
      project?.isMultiRepo ||
      !("defaultEnvironment" in project) ||
      !project.defaultEnvironment?.defaultRepo
    ) {
      history.replace(`/${projectPath(project)}/`);
    } else {
      history.replace(
        history.location.pathname.replace(
          match.url,
          `/${projectPath(project)}/${project.defaultEnvironment.name}/${
            project.defaultEnvironment.defaultRepo.name
          }`
        )
      );
    }
  }, [history, match.url, project]);

  return null;
};

export const LegacyRedirect = () => {
  const project = useProject();
  const history = useHistory();

  const envName = history.location.pathname.substring(
    history.location.pathname.lastIndexOf("/") + 1
  );

  const { error, loading } = useProjectEnvironmentQuery({
    variables: { env: envName, path: projectPath(project) },
    errorPolicy: "none",
  });

  if (loading) {
    return <CenterLoading />;
  }

  if (error) {
    // there is no conflicting env name
    if (error.graphQLErrors?.[0]?.path?.[1] === "environment") {
      return (
        <Redirect
          to={history.location.pathname.replace(
            `/${projectPath(project)}/`,
            `/${projectPath(project)}/_default/`
          )}
        />
      );
    }
    return <ZError error={error.message} />;
  }

  // the env and project exist
  return (
    <ProjectEnvProvider envNameOverride={envName}>
      <Environment />
    </ProjectEnvProvider>
  );
};

export const LegacyRedirectWrapper: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const project = useProject();
  const history = useHistory();

  const { envName } = useParams<{
    envName: string;
  }>();

  const possibleLegacy = [
    "metrics",
    "settings",
    "logs",
    "jobs",
    "deployments",
  ].includes(envName);

  const { error, loading } = useProjectEnvironmentQuery({
    skip: !possibleLegacy,
    variables: { env: envName, path: projectPath(project) },
    errorPolicy: "none",
  });

  if (!possibleLegacy) {
    return <>{children}</>;
  }

  if (loading) {
    return <CenterLoading />;
  }

  if (error) {
    // there is no conflicting env name
    if (error.graphQLErrors?.[0]?.path?.[1] === "environment") {
      return (
        <Redirect
          to={history.location.pathname.replace(
            `/${projectPath(project)}/`,
            `/${projectPath(project)}/_default/`
          )}
        />
      );
    }
    return <ZError error={error.message} />;
  }

  // the env and project exist
  return <>{children}</>;
};

export const LegacyRepoIdRedirect = () => {
  const { id } = useParams<{ id: string }>();
  const { data, error, loading } = useUserRepoQuery({
    variables: { id },
    errorPolicy: "ignore",
  });

  if (error) return <ZError error={error.message} />;
  if (loading) return <CenterLoading />;
  if (!data?.currentUser.repo) {
    return <ZError error={"Resource not found"} />;
  }
  return <Redirect to={`/${repoPath(data.currentUser.repo)}`} />;
};

export const LegacyRepoDeploymentIdRedirect = () => {
  const { id, deploymentId } = useParams<{
    id: string;
    deploymentId: string;
  }>();
  const { data, error, loading } = useUserRepoQuery({
    variables: { id },
    errorPolicy: "ignore",
  });

  if (error) return <ZError error={error.message} />;
  if (loading) return <CenterLoading />;
  if (!data?.currentUser.repo) {
    return <ZError error={"Resource not found"} />;
  }
  return (
    <Redirect
      to={`/${repoPath(data.currentUser.repo)}/deployments/${deploymentId}`}
    />
  );
};
