import { WorkflowRunStepDetailFragment } from "@zeet/web-api/dist/graphqlv1";
import { GenericRunStepNestedDetail } from "./GenericRunStepNestedDetail";

interface DeployStepRunDetailProps {
  step: WorkflowRunStepDetailFragment;
  runId: string;
  projectId: string;
}

export const DeployRunStepDetail = ({
  step,
  runId,
  projectId,
}: DeployStepRunDetailProps) => {
  return (
    <GenericRunStepNestedDetail
      title="Deploy"
      step={step}
      runId={runId}
      projectId={projectId}
    />
  );
};
