import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Box,
  Divider,
  Flex,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Link, MenuContainer } from "@zeet/web-ui";
import React from "react";
import { useBillingOverviewContext } from "../Account/BillingV2/Providers/BillingOverviewProvider";
import { DetailHeader } from "../Layouts/DetailHeader";
import { determineSupportLevel } from "./utils";

interface IResource {
  text: string;
  link: string;
}

const SupportSidebarWrapper: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const [{ userBillingOverview }] = useBillingOverviewContext();
  const isMobileBreakpoint = useBreakpointValue({ base: true, md: false });
  const linkProps = {
    opacity: 0.7,
    transition: "none",
    _hover: { textDecoration: "none", opacity: 0.9 },
  };

  const resources: IResource[] = [
    {
      text: "View Docs",
      link: "https://docs.zeet.co/reference/introduction",
    },
    {
      text: "View Guides",
      link: "https://docs.zeet.co/guides/introduction",
    },
    {
      text: "Browse Helpdesk",
      link: "https://helpdesk.zeet.co/",
    },
    {
      text: "Zeet Status",
      link: "https://status.zeet.co/",
    },
  ];

  return (
    <>
      <DetailHeader title="Support Center" fullWidth />
      <Flex px="8" py="6" minHeight="inherit">
        <MenuContainer
          boxSizing="content-box"
          minW="250px"
          display={["none", "none", "block"]}
        >
          <Text fontWeight="bold" mt={4}>
            Your Account
          </Text>
          <Stack
            as="aside"
            position={["relative", "relative", "sticky"]}
            w="10rem"
            maxW="100%"
            display="flex"
            alignItems={["center", "center", "flex-start"]}
            width="100%"
            mt={2}
          >
            <Box>
              <Text data-testid="support-sidebar-level" opacity={0.7}>
                Support Level:{" "}
                <strong>{determineSupportLevel(userBillingOverview)}</strong>
              </Text>
              <Link
                exact
                isExternal
                to="https://zeet.co/pricing#support"
                {...linkProps}
              >
                <Flex alignItems="center">
                  <Text fontSize="small" fontStyle="italic">
                    View all support levels
                  </Text>
                  <ExternalLinkIcon ml={1} fontSize="sm" opacity=".8" />
                </Flex>
              </Link>
            </Box>
          </Stack>
          <Text fontWeight="bold" mt={10}>
            Zeet Resources
          </Text>
          <Stack
            as="aside"
            position={["relative", "relative", "sticky"]}
            w="10rem"
            maxW="100%"
            display="flex"
            alignItems={["center", "center", "flex-start"]}
            width="100%"
            mt={2}
          >
            {resources.map((r) => (
              <Link to={r.link} isExternal {...linkProps}>
                {r.text}
                <ExternalLinkIcon ml={1} fontSize="sm" opacity=".8" />
              </Link>
            ))}
          </Stack>
          {isMobileBreakpoint && <Divider my={4} />}
        </MenuContainer>
        <Box width="100%" margin="auto" mt="0">
          {children}
        </Box>
      </Flex>
    </>
  );
};

export default SupportSidebarWrapper;
