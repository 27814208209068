import {
  useDeleteProjectResourcesMutation,
  WorkflowRunsDocument,
} from "@zeet/web-api/dist/graphqlv1";
import { AnalyticsEvent, useCurrentTeamUser, useTrack } from "@zeet/web-ui";
import { useHistory } from "react-router-dom";
import { useProjectPath } from "../../../hooks/useProjectPath";

export const useDeleteResource = ({ projectId }: { projectId: string }) => {
  const currentTeamUser = useCurrentTeamUser();
  const history = useHistory();
  const projectPath = useProjectPath();
  const { track } = useTrack();

  const onError = (error?: Error) => {
    track(
      AnalyticsEvent.DELETE_PROJECT_RESOURCES_ERROR,
      error ? { error } : undefined
    );
  };

  const [deleteResources, { loading: deleteProjectLoading }] =
    useDeleteProjectResourcesMutation({
      onError,
      errorPolicy: "none",
      onCompleted: (data) => {
        if (data?.deleteProjectResources) {
          const nextPath = `${projectPath}/workflows?workflowId=${data?.deleteProjectResources}`;
          history.push(nextPath);
          track(AnalyticsEvent.DELETE_PROJECT_RESOURCES_SUCCESS);
        } else {
          onError();
        }
      },
      refetchQueries: [
        {
          query: WorkflowRunsDocument,
          variables: {
            teamId: currentTeamUser?.id,
            projectId,
            page: { after: "0", first: 20 },
          },
        },
      ],
      awaitRefetchQueries: true,
    });

  return {
    deleteResources,
    loading: deleteProjectLoading,
  };
};
