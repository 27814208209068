import { CloudProvider } from "@zeet/web-api/dist/graphql";

export const cloudGuideMap = {
  [CloudProvider.Aws]: "https://docs.zeet.co/cloud/aws/",
  [CloudProvider.Gcp]: "https://docs.zeet.co/cloud/gcp/",
  [CloudProvider.Do]: "https://docs.zeet.co/cloud/digitalocean/",
  [CloudProvider.Coreweave]: "https://docs.zeet.co/cloud/coreweave/",
  [CloudProvider.Linode]: "https://docs.zeet.co/cloud/linode/",
  [CloudProvider.Vultr]: "https://docs.zeet.co/cloud/vultr/",
  [CloudProvider.Azure]: "https://docs.zeet.co/cloud/azure/",
};
