enum AnalyticsEvent {
  DEPLOYED_REPOSITORY = "deploy_success_github",
  DEPLOYED_DOCKER = "deploy_success_docker",
  DEPLOYED_DATABASE = "deploy_success_database",
  DEPLOYED_DEMO = "team_onboard_created_zeet_demo",
  CREATE_PROJECT_SUCCESS = "create_project_success",
  CREATE_PROJECT_STEP_START = "create_project_step_start",
  CONNECTED_GITHUB = "github_connected",
  CONNECTED_CLOUD_PROVIDER = "cloud_connect_success",
  ONBOARD_TEAM_SIGNUP = "team_onboard_signup",
  ONBOARD_ROLE_SELECTED = "team_onboard_role_selected",
  ONBOARD_TEAM_INVITE = "team_onboard_invite_team_member",
  TEAM_MEMBER_INVITE = "team_member_invite",
  UPDATE_CLOUD_SUBMIT = "update_cloud_submit",
  UPDATE_CLOUD_SUCCESS = "update_cloud_success",
  UPDATE_CLOUD_ERROR = "update_cloud_error",
  UPDATE_CLUSTER_SUBMIT = "update_cluster_submit",
  UPDATE_CLUSTER_SUCCESS = "update_cluster_success",
  UPDATE_CLUSTER_ERROR = "update_cluster_error",
  SET_ALTERNATIVE_TAG_TEMPLATE = "set_alternative_tag_templates",
  CREATE_BLUEPRINT_START = "create_blueprint_start",
  CREATE_BLUEPRINT_SUCCESS = "create_blueprint_success",
  CREATE_BLUEPRINT_ERROR = "create_blueprint_error",
  UPDATE_BLUEPRINT_SUCCESS = "update_blueprint_success",
  UPDATE_BLUEPRINT_ERROR = "update_blueprint_error",
  DELETE_BLUEPRINT_SUCCESS = "delete_blueprint_success",
  DELETE_BLUEPRINT_ERROR = "delete_blueprint_error",
  SEARCH_BLUEPRINTS_MARKETPLACE = "search_blueprints_marketplace",
  SEARCH_BLUEPRINTS_APP = "search_blueprints_app",
  DELETE_PROJECT_SUCCESS = "delete_project_success",
  DELETE_PROJECT_ERROR = "delete_project_error",
  DELETE_PROJECT_RESOURCES_SUCCESS = "delete_project_resources_success",
  DELETE_PROJECT_RESOURCES_ERROR = "delete_project_resources_error",
  DESTROY_PROJECT_SUCCESS = "destroy_project_success",
  DESTROY_PROJECT_ERROR = "destroy_project_error",
  CONNECT_CLOUD_ATTEMPT = "connect_cloud_attempt",
  CREATE_CLUSTER_CLICK = "click_create_cluster",
  CREATE_CLUSTER_START = "create_cluster_start",
  CREATE_CLUSTER_SUBMIT = "create_cluster_submit",
  CREATE_CLUSTER_ERROR = "create_cluster_error",
  VIEW_PERMISSIONS_JSON = "zeet_users_view_permissions_json",
  DESTROY_CLUSTER_ERROR = "delete_cluster_error",
  DESTROY_CLUSTER_SUCCESS = "delete_cluster_success",
  UNLINK_CLUSTER_ERROR = "unlink_cluster_error",
  UNLINK_CLUSTER_SUCCESS = "unlink_cluster_success",
  DELETE_REPO_SUCCESS = "delete_repo_success",
  DELETE_REPO_ERROR = "delete_repo_error",
  DELETE_REPO_CLICK = "delete_repo_click",
  DELETE_REPO_CONFIRM = "delete_repo_confirm",
  UNLINK_REPO_SUCCESS = "unlink_repo_success",
  UNLINK_REPO_ERROR = "unlink_repo_error",
  UNLINK_REPO_CONFIRM = "unlink_repo_confirm",
  UNLINK_REPO_CLICK = "unlink_repo_click",
  BILLING_VIEW_MORE_PRODUCTS_CLICK = "view_more_products_click",
  BILLING_CONTACT_US_CLICK = "contact_us_click",
  BILLING_PAYMENT_METHOD_CLICK = "billing_payment_method_click",
  BILLING_INVOICE_HISTORY_CLICK = "billing_invoice_history_click",
  BILLING_OPENED_REVIEW = "billing_upgrade_opened_review",
  BILLING_CHANGE_PRODUCT_CLICK = "billing_upgrade_product_click",
  BILLING_REVIEW_CLICK = "billing_review_click",
  BILLING_CONFIRM_CHANGES_ATTEMPT = "billing_confirm_changes_attempt",
  BILLING_CONFIRM_CHANGES_SUCCESS = "billing_confirm_changes_success",
  BILLING_CONFIRM_CHANGES_ERROR = "billing_confirm_changes_error",
  BILLING_ADD_PAYMENT_ATTEMPT = "billing_add_payment_attempt",
  UPGRADE_MODAL_DISPLAYED = "upgrade_modal_displayed",
  USERS_NEW_SIGNUP = "new_user_signup",
  VIEW_DEPLOYED_PROJECT = "view_app_deployed",
  UPDATE_NOTIFICATIONS_EVENT_SETTINGS = "update_notifications_event_settings",
  CONFIRMATION_PAGE = "confirmation_page",
  INSTALL_BITBUCKET = "install_bitbucket_integration",
  LINK_PROJECT_SUCCESS = "linkProject_success",
  LINK_PROJECT_ERROR = "linkProject_error",
  UNLINK_PROJECT_SUCCESS = "unlinkProject_success",
  UNLINK_PROJECT_ERROR = "unlinkProject_error",
  CLICK_OPEN_TERMINAL = "click_open_terminal",
  CLICK_PROJECT_RESOURCES = "click_project_resources",
  DEPLOYMENT_ALERTS_NAVIGATION_UNAVAILABLE = "deployment_alerts_navigation_unavailable",
  GROUP_PERMISSION_UPDATE = "group_permission_update",
}

export { AnalyticsEvent };
