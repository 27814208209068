import { Box, Flex } from "@chakra-ui/react";
import { Skeleton } from "@zeet/web-ui";
import React from "react";

export const DeploymentsSkeleton: React.FC = () => {
  return (
    <Box width="100%" mt="4">
      <Flex width="100%" justifyContent="space-between">
        <Flex gap={4}>
          <Skeleton height="2.5rem" w={48} borderRadius="6px" />
          <Skeleton height="2.5rem" w={32} borderRadius="6px" />
        </Flex>
        <Skeleton height="2.5rem" width="19%" borderRadius="6px" />
      </Flex>
      <Box mt="1.5rem">
        {Array.from(Array(8).keys()).map((element) => (
          <Skeleton
            key={element}
            height="6rem"
            width="100%"
            borderRadius="6px"
            marginTop="1rem"
          />
        ))}
      </Box>
    </Box>
  );
};
