"use client";

import { Code, CodeProps } from "@chakra-ui/react";
import React, { CSSProperties, useState } from "react";

export const CodeSecurity = React.forwardRef<HTMLPreElement, CodeProps>(
  (props, ref) => {
    const [isFocused, setFocused] = useState<boolean>(false);
    const [isHovered, setHovered] = useState<boolean>(false);
    const style = {
      WebkitTextSecurity: isFocused || isHovered ? "none" : "disc",
    } as CSSProperties;

    return (
      <Code
        style={style}
        ref={ref}
        rows={1}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        {...props}
      />
    );
  }
);
