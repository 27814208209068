import {
  Box,
  Flex,
  Grid,
  GridProps,
  Heading,
  Spacer,
  useColorModeValue,
} from "@chakra-ui/react";

import {
  GenericWorkflowStepActionType,
  useWorkflowRunStepNestedDetailQuery,
  WorkflowRunDetailFragment,
  WorkflowRunStepDetailFragment,
} from "@zeet/web-api/dist/graphqlv1";
import { Button, Link, useColors, useCurrentTeamUser } from "@zeet/web-ui";
import { Fragment } from "react";
import { useProjectPath } from "../hooks/useProjectPath";
import { WorkflowStepStatusIcon } from "./Steps/WorkflowStepStatusIcon";
import {
  getNestedWorkflowRun,
  sortStepsBySequenceNumber,
  workflowActionTitleMap,
} from "./utils";

interface WorkflowCanvasProps {
  workflowRun: WorkflowRunDetailFragment;
  title?: string;
  disableStepLinks?: boolean;
  projectId: string;
}

export const WorkflowCanvas = ({
  projectId,
  workflowRun,
  title,
  disableStepLinks,
}: WorkflowCanvasProps) => {
  const { bg2 } = useColors();
  const path = useProjectPath();
  const dotColor = useColorModeValue(
    "rgba(200, 200, 200, 1)",
    "rgba(255, 255, 255, 0.25)"
  );

  return (
    <Box
      backgroundColor={bg2}
      backgroundImage={`radial-gradient(circle at 8px 8px, ${dotColor} 1px, transparent 1px)`}
      backgroundSize="16px 16px"
      borderRadius="lg"
      shadow="md"
      overflow="hidden"
      overflowX="auto"
      borderWidth={1}
    >
      <Flex p={4} alignItems="flex-start" position="sticky" top="0" left="0">
        <Heading size="sm" backgroundColor={bg2}>
          {title || "Workflow"}
        </Heading>
        <Spacer />
        <Button
          asLink
          to={`${path}/settings`}
          variant="secondary"
          height="auto"
          py={1}
        >
          Settings
        </Button>
      </Flex>
      <WorkflowStepGrid
        projectId={projectId}
        disableStepLinks={disableStepLinks}
        workflowRun={workflowRun}
      />
    </Box>
  );
};

const WorkflowStepGrid = ({
  projectId,
  workflowRun,
  disableStepLinks,
  ...props
}: WorkflowCanvasProps & GridProps) => {
  const { fg2 } = useColors();
  if (!workflowRun?.steps) return null;

  const columnsDef = workflowRun.steps
    .map(() => "minmax(100px, 500px)")
    .join(" minmax(20px, 100px) ");

  const steps = sortStepsBySequenceNumber(workflowRun?.steps);

  return (
    <Grid
      gridTemplateColumns={columnsDef}
      px={16}
      pb={32}
      pt={24}
      justifyContent="center"
      alignItems="center"
      minWidth="fit-content"
      {...props}
    >
      {steps.map((s, i) => {
        return (
          s && (
            <Fragment key={i}>
              <WorkflowStep
                projectId={projectId}
                step={s}
                stepId={s.sequenceNumber}
                workflowRun={workflowRun}
                disableStepLinks={disableStepLinks}
              />
              {workflowRun?.steps && i != workflowRun.steps.length - 1 && (
                <Box background={fg2} height="2px" />
              )}
            </Fragment>
          )
        );
      })}
    </Grid>
  );
};

interface WorkflowStepProps {
  step: WorkflowRunStepDetailFragment;
  workflowRun: WorkflowRunDetailFragment;
  stepId: number;
  projectId: string;
  disableStepLinks?: boolean;
}

const WorkflowStep = ({
  step,
  workflowRun,
  stepId,
  disableStepLinks,
  projectId,
}: WorkflowStepProps) => {
  const currentTeamUser = useCurrentTeamUser();
  const { data } = useWorkflowRunStepNestedDetailQuery({
    variables: {
      projectId,
      stepId: step.id,
      runId: workflowRun.id,
      teamId: currentTeamUser?.id,
    },
    pollInterval: 3000,
    skip:
      step.action !== GenericWorkflowStepActionType.OrchestrationDeploy ||
      !step?.executionId,
  });

  const nestedWorkflowRun = getNestedWorkflowRun(data);
  const steps = nestedWorkflowRun?.steps || [];

  if (steps.length === 0) {
    return (
      <WorkflowStepSingle
        projectId={projectId}
        step={step}
        workflowRun={workflowRun}
        stepId={stepId}
        disableStepLinks={disableStepLinks}
      />
    );
  }

  if (nestedWorkflowRun) {
    return (
      <WorkflowStepGroup
        projectId={projectId}
        step={step}
        workflowRun={workflowRun}
        nestedWorkflowRun={nestedWorkflowRun}
        stepId={stepId}
        disableStepLinks={disableStepLinks}
      />
    );
  }

  return null;
};

const WorkflowStepGroup = ({
  step,
  workflowRun,
  nestedWorkflowRun,
  stepId,
  projectId,
  disableStepLinks,
}: { nestedWorkflowRun: WorkflowRunDetailFragment } & WorkflowStepProps) => {
  const { bg2, bg } = useColors();
  const path = useProjectPath();

  if (!step?.action) {
    return null;
  }

  const linkProps = disableStepLinks
    ? {}
    : {
        as: Link,
        to: `${path}/workflows?workflowId=${workflowRun.id}&stepId=${stepId}`,
        _hover: { textDecoration: "none", bg },
      };

  return (
    <Flex
      p={4}
      align="center"
      shadow="md"
      bg={bg2}
      borderRadius="md"
      borderWidth={2}
      flexDir="column"
      gap="3"
      minWidth="fit-content"
      {...linkProps}
    >
      <Flex width="100%" alignItems="center">
        {workflowActionTitleMap[step.action]}
      </Flex>
      <Flex justifyContent="space-between" width="100%">
        <WorkflowStepGrid
          workflowRun={nestedWorkflowRun}
          projectId={projectId}
          disableStepLinks
          p="0"
        />
      </Flex>
    </Flex>
  );
};

const WorkflowStepSingle = ({
  step,
  workflowRun,
  stepId,
  disableStepLinks,
}: WorkflowStepProps) => {
  const { bg2, bg } = useColors();
  const path = useProjectPath();

  if (!step?.action) return null;

  const linkProps = disableStepLinks
    ? {}
    : {
        as: Link,
        to: `${path}/workflows?workflowId=${workflowRun.id}&stepId=${stepId}`,
        _hover: { textDecoration: "none", bg },
      };

  return (
    <Flex
      p={4}
      align="center"
      shadow="md"
      bg={bg2}
      borderRadius="md"
      borderWidth={2}
      {...linkProps}
    >
      {workflowActionTitleMap[step.action]}
      <Spacer />
      <WorkflowStepStatusIcon step={step} workflowRun={workflowRun} />
    </Flex>
  );
};
