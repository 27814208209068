// TODO use this component for all provider selection, its more generic

import { Box, Flex, IconButton, Tooltip } from "@chakra-ui/react";
import {
  CloudProvider,
  ClusterStatus,
  UserDeployTargetsQuery,
  useUserDeployTargetsQuery,
} from "@zeet/web-api/dist/graphql";
import { useColors, useCurrentTeamUser } from "@zeet/web-ui";
import { chakraComponents, Select } from "chakra-react-select";
import { useState } from "react";
import { MdRefresh } from "react-icons/md";
import {
  formatCloudProviderLabel,
  getAwsLabelParams,
  getCoreweaveLabelParams,
  getDoLabelParams,
  getGcpLabelParams,
  getLinodeLabelParams,
} from "~/components/Cloud/name";
import { ProviderLogo } from "~/components/Cloud/ProviderLogo";
import { ProviderOption } from "../../context";

export const getClusterOptions = (
  data?: UserDeployTargetsQuery
): ProviderOption[] => {
  return (
    data?.user?.clusters?.map((cluster) => ({
      provider: cluster.cloudProvider ?? CloudProvider.Unknown,
      kubernetes: true,
      label: cluster.name,
      value: cluster.id,
      connected: cluster.connected,
      clusterState: cluster.state,
      clusterStatus: cluster.status,
    })) ?? []
  );
};

const awsToProviderOption = (
  accounts: UserDeployTargetsQuery["user"]["awsAccounts"]
): ProviderOption[] => {
  return (
    accounts?.map(
      (account): ProviderOption => ({
        provider: CloudProvider.Aws,
        label: formatCloudProviderLabel(getAwsLabelParams(account)),
        value: account.id,
        connected: account.connected,
      })
    ) ?? []
  );
};

const linodeToProviderOption = (
  accounts: UserDeployTargetsQuery["user"]["linodeAccounts"]
): ProviderOption[] => {
  return (
    accounts?.map(
      (account): ProviderOption => ({
        provider: CloudProvider.Linode,
        label: formatCloudProviderLabel(getLinodeLabelParams(account)),
        value: account.id,
        connected: account.connected,
      })
    ) ?? []
  );
};

const digitalOceanToProviderOption = (
  accounts: UserDeployTargetsQuery["user"]["doAccounts"]
): ProviderOption[] => {
  return (
    accounts?.map(
      (account): ProviderOption => ({
        provider: CloudProvider.Do,
        label: formatCloudProviderLabel(getDoLabelParams(account)),
        value: account.id,
        connected: account.connected,
      })
    ) ?? []
  );
};

const gcpToProviderOption = (
  accounts: UserDeployTargetsQuery["user"]["gcpAccounts"]
): ProviderOption[] => {
  return (
    accounts?.map(
      (account): ProviderOption => ({
        provider: CloudProvider.Gcp,
        label: formatCloudProviderLabel(getGcpLabelParams(account)),
        value: account.id,
        connected: account.connected,
      })
    ) ?? []
  );
};

const coreweaveToProviderOption = (
  accounts: UserDeployTargetsQuery["user"]["coreweaveAccounts"]
): ProviderOption[] => {
  return (
    accounts?.map(
      (account): ProviderOption => ({
        provider: CloudProvider.Coreweave,
        label: formatCloudProviderLabel(getCoreweaveLabelParams(account)),
        value: account.id,
        connected: account.connected,
      })
    ) ?? []
  );
};

export const getCloudOptions = (
  cloudProviderOptions: CloudProvider[],
  data: UserDeployTargetsQuery | undefined
): ProviderOption[] => {
  const connectedOptions: ProviderOption[] = [];

  if (cloudProviderOptions.includes(CloudProvider.Aws)) {
    const options = awsToProviderOption(data?.user?.awsAccounts);
    connectedOptions.push(...options);
  }
  if (cloudProviderOptions.includes(CloudProvider.Linode)) {
    const options = linodeToProviderOption(data?.user?.linodeAccounts);
    connectedOptions.push(...options);
  }
  if (cloudProviderOptions.includes(CloudProvider.Do)) {
    const options = digitalOceanToProviderOption(data?.user?.doAccounts);
    connectedOptions.push(...options);
  }
  if (cloudProviderOptions.includes(CloudProvider.Gcp)) {
    const options = gcpToProviderOption(data?.user?.gcpAccounts);
    connectedOptions.push(...options);
  }
  if (cloudProviderOptions.includes(CloudProvider.Coreweave)) {
    const options = coreweaveToProviderOption(data?.user?.coreweaveAccounts);
    connectedOptions.push(...options);
  }
  return connectedOptions?.map((option) => ({
    ...option,
  }));
};

export const clusterStatusMap = {
  [ClusterStatus.Healthy]: {
    color: "none",
    tooltip: "We're ready to deploy to this cluster.",
    rank: 8,
  },
  [ClusterStatus.Creating]: {
    color: "yellow",
    tooltip: "Your cluster is still creating. Please wait",
    rank: 7,
  },
  [ClusterStatus.Pending]: {
    color: "yellow",
    tooltip: "You cluster is pending creation.",
    rank: 6,
  },
  [ClusterStatus.PendingSetup]: {
    color: "yellow",
    tooltip:
      "You may create your Project but you need to upload a kubeconfig to this cluster to deploy the Project.",
    rank: 5,
  },
  [ClusterStatus.Unreachable]: {
    color: "red",
    tooltip:
      "We're having trouble connecting to this provider. You can still attempt to deploy, but it may fail.",
    rank: 4,
  },
  [ClusterStatus.Error]: {
    color: "red",
    tooltip:
      "We're having trouble connecting to this provider. You can still attempt to deploy, but it may fail.",
    rank: 3,
  },
  [ClusterStatus.Deleting]: {
    color: "red",
    tooltip: "This cluster is in the process of deleting.",
    rank: 2,
  },
  [ClusterStatus.ErrorDeleting]: {
    color: "red",
    tooltip:
      "This cluster is in the process of deleting but the deletion failed.",
    rank: 1,
  },
};

export const sortByClusterStatus = (a: ProviderOption, b: ProviderOption) => {
  return (
    clusterStatusMap[b.clusterStatus || ClusterStatus.Error].rank -
    clusterStatusMap[a.clusterStatus || ClusterStatus.Error].rank
  );
};

interface ProviderSelectorProps {
  validCloudProviders?: CloudProvider[];
  showClusters?: boolean;
  value?: ProviderOption | null;
  onChange?: (option: ProviderOption | null) => unknown;
}

export const ProviderSelectorMenu: React.FC<ProviderSelectorProps> = ({
  validCloudProviders,
  showClusters,
  value,
  onChange,
}) => {
  const { id } = useCurrentTeamUser();
  const { loading, refetch, data } = useUserDeployTargetsQuery({
    variables: {
      id,
    },
  });

  const [selectedProvider, setSelectedProvider] = useState<
    ProviderOption | null | undefined
  >(value);
  const cloudOptions = getCloudOptions(validCloudProviders ?? [], data).sort(
    sortByClusterStatus
  );
  const clusterOptions = showClusters
    ? getClusterOptions(data).sort(sortByClusterStatus)
    : null;
  const { bg } = useColors();

  return (
    <Flex data-testid="target-provider-selector">
      <Select
        useBasicStyles
        chakraStyles={{
          container: (provided) => ({
            ...provided,
            backgroundColor: bg,
            flex: 1,
          }),
          menuList: (provided) => ({ ...provided, boxShadow: "2xl" }),
        }}
        openMenuOnFocus
        captureMenuScroll={false}
        hasStickyGroupHeaders
        placeholder="Select provider"
        options={[
          {
            label: "Clouds",
            options: cloudOptions ?? [],
          },
          {
            label: "Clusters",
            options: clusterOptions ?? [],
          },
        ]}
        isLoading={loading}
        components={{
          Option: ({ children, ...props }) => (
            <chakraComponents.Option {...props}>
              <Tooltip
                label={
                  clusterStatusMap[props?.data?.clusterStatus ?? ""]?.tooltip
                }
                placement="right"
              >
                <Flex alignItems="center" ml="-1">
                  <Flex alignItems="center">
                    <ProviderLogo option={props.data} />
                    <Box ml="1">{children}</Box>
                    <Box
                      borderRadius="50%"
                      backgroundColor={
                        clusterStatusMap[props?.data?.clusterStatus ?? ""]
                          ?.color
                      }
                      height="12px"
                      width="12px"
                      ml="8px"
                    />
                  </Flex>
                </Flex>
              </Tooltip>
            </chakraComponents.Option>
          ),
          SingleValue: ({ children, ...props }) => {
            if (!props.data) return null;
            return (
              <chakraComponents.SingleValue {...props}>
                <Flex alignItems="center" ml="-1">
                  <Flex alignItems="center">
                    <ProviderLogo option={props.data} />
                    <Box ml="1">{children}</Box>
                    <Box
                      borderRadius="50%"
                      backgroundColor={
                        clusterStatusMap[props?.data?.clusterStatus ?? ""]
                          ?.color
                      }
                      height="12px"
                      width="12px"
                      ml="8px"
                    />
                  </Flex>
                </Flex>
              </chakraComponents.SingleValue>
            );
          },
        }}
        value={selectedProvider}
        onChange={(option) => {
          setSelectedProvider(option);
          onChange?.(option);
        }}
      />
      <IconButton
        aria-label="refresh"
        icon={<MdRefresh size="1.5rem" />}
        bg={bg}
        borderRadius="md"
        onClick={() => refetch()}
        ml="2"
      />
    </Flex>
  );
};
