import { ChevronDownIcon, SettingsIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Switch,
  Text,
  useToast,
} from "@chakra-ui/react";
import {
  CertManagerChallengeType,
  ClusterDomainsDetailFragment,
  DomainDetailFragment,
  RepoDetailFragment,
  useUpdateCertMutation,
} from "@zeet/web-api/dist/graphql";
import {
  AWSLogo,
  GCPLogo,
  K8SLogo,
  LinodeLogo,
  Tooltip,
  ZeetLogo,
} from "@zeet/web-ui";
import React from "react";
import { NIL as NIL_UUID } from "uuid";
import cwLogo from "~/images/coreweave/logo.png";
import doLogo from "~/images/digitalocean/logo.png";
import { CustomDomain, IClustersCustomDomain } from "../../types/misc";
import { isKube } from "../../utils/project";
import { repoPath } from "../util";

export const DomainSettingsMenu: React.FC<{
  repo: RepoDetailFragment;
  domain: DomainDetailFragment;
  cluster: ClusterDomainsDetailFragment["cluster"];
  matchedCustomDomains: IClustersCustomDomain[] | undefined;
  currentCustomDomain: IClustersCustomDomain | undefined;
}> = ({ repo, domain, matchedCustomDomains, currentCustomDomain }) => {
  const toast = useToast();

  const [update, { loading: isLoading }] = useUpdateCertMutation({
    onCompleted: (data) => {
      if (data) {
        toast({
          title: "Certificate Updated",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    },
  });
  const onClickFunction = (selectedCustomDomain: CustomDomain | undefined) => {
    update({
      variables: {
        input: {
          id: domain.id,
          syncDomainID: selectedCustomDomain?.id
            ? selectedCustomDomain?.id
            : NIL_UUID,
        },
      },
    });
  };

  return (
    <Flex alignItems={"center"}>
      <Text mr={2}>Settings</Text>
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<SettingsIcon />}
        />
        <MenuList>
          <MenuItem>
            Auto Certificate
            <Flex alignItems="center" mr={2}>
              <Tooltip
                text={`You can disable auto-certificate when your CDN or L7 load balancer handles TLS termination.`}
              />
            </Flex>
            <Flex ml="auto" alignItems={"center"}>
              {domain?.disableCertManager ? " Disabled " : " Enabled "}
              <Switch
                isDisabled={isLoading}
                isChecked={!domain.disableCertManager}
                ml="16px"
                onChange={() => {
                  update({
                    variables: {
                      input: {
                        id: domain.id,
                        disableCertManager: !domain.disableCertManager,
                      },
                    },
                  });
                }}
              />
            </Flex>
          </MenuItem>
          {isKube(repo) && !domain.syncDomain && (
            <MenuItem closeOnSelect={false}>
              <Flex width="100%" justifyContent="space-between">
                <Text>DNS-01 Challenge</Text>
                <Flex alignItems="center" mr={2}>
                  <Tooltip
                    text={`Unlike the default HTTP-01 challenge, DNS-01 challenge does not require any inbound traffic to your cluster. After you enable DNS-01, you will see new DNS records which need to be added with your DNS provider.`}
                  />
                </Flex>
                <Flex ml="auto" alignItems={"center"}>
                  {domain.certManagerChallengeType ===
                  CertManagerChallengeType.Dns01
                    ? " Enabled "
                    : " Disabled "}
                  <Switch
                    isDisabled={isLoading || domain.domain?.indexOf("*") !== -1}
                    isChecked={
                      domain.certManagerChallengeType ===
                      CertManagerChallengeType.Dns01
                    }
                    ml="16px"
                    onChange={() => {
                      update({
                        variables: {
                          input: {
                            id: domain.id,
                            certManagerChallengerType:
                              domain.certManagerChallengeType ===
                              CertManagerChallengeType.Dns01
                                ? CertManagerChallengeType.Http01
                                : CertManagerChallengeType.Dns01,
                          },
                        },
                      });
                    }}
                  />
                </Flex>
              </Flex>
            </MenuItem>
          )}
          {isKube(repo) &&
            matchedCustomDomains &&
            matchedCustomDomains.length > 1 && (
              <MenuItem closeOnSelect={false}>
                <Flex
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center"
                  minWidth="400px"
                >
                  <Flex alignItems="center" mr="16px">
                    <Text>Sync from another cluster</Text>
                    <Tooltip text="Copy the SSL certificate for this domain from another cluster."></Tooltip>
                  </Flex>
                  <CustomDomainsInput
                    onClickFunction={onClickFunction}
                    matchedCustomDomains={matchedCustomDomains}
                    currentCustomDomain={currentCustomDomain}
                  />
                </Flex>
              </MenuItem>
            )}
        </MenuList>
      </Menu>
    </Flex>
  );
};

const CustomDomainsInput: React.FC<{
  matchedCustomDomains: IClustersCustomDomain[] | undefined;
  onClickFunction: (selectedCustomDomain: CustomDomain | undefined) => void;
  currentCustomDomain: IClustersCustomDomain | undefined;
}> = ({ onClickFunction, matchedCustomDomains, currentCustomDomain }) => {
  return (
    <Menu>
      <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
        <CustomDomainOption customDomainWithCluster={currentCustomDomain} />
      </MenuButton>
      <MenuList>
        {matchedCustomDomains?.map((mcd, i) => (
          <MenuItem key={i} onClick={() => onClickFunction(mcd.customDomain)}>
            <CustomDomainOption customDomainWithCluster={mcd} />
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export const CustomDomainOption: React.FC<{
  customDomainWithCluster?: IClustersCustomDomain;
}> = ({ customDomainWithCluster }) => {
  const cluster = customDomainWithCluster?.cluster;
  const customDomain = customDomainWithCluster?.customDomain;

  if (!customDomainWithCluster || !customDomainWithCluster?.cluster) {
    return (
      <Stack
        fontSize="sm"
        fontWeight="bold"
        alignItems="center"
        justifyContent="center"
        isInline
      >
        <Box>
          <ZeetLogo logoHeight="22px" logoWidth="22px" />
        </Box>
        <Text>Disabled</Text>
      </Stack>
    );
  }
  if (cluster?.awsAccount?.accountID) {
    return (
      <Stack fontSize="sm" fontWeight="bold" alignItems="center" isInline>
        <CustomDomainRepo customDomain={customDomain} />
        <Box>
          <K8SLogo height="0.9rem" />
        </Box>
        <Text>{cluster?.name}</Text>
        <Box>
          <AWSLogo height="0.9rem" />
        </Box>
        <Text>{cluster.awsAccount.accountID}</Text>
      </Stack>
    );
  }

  if (cluster?.gcpAccount?.projectID) {
    return (
      <Stack fontSize="sm" fontWeight="bold" alignItems="center" isInline>
        <CustomDomainRepo customDomain={customDomain} />
        <Box>
          <K8SLogo height="0.9rem" />
        </Box>
        <Text>{cluster?.name}</Text>
        <Box>
          <GCPLogo height="0.9rem" />
        </Box>
        <Text>{cluster.gcpAccount.projectID}</Text>
      </Stack>
    );
  }

  if (cluster?.doAccount?.defaultProject) {
    return (
      <Stack fontSize="sm" fontWeight="bold" alignItems="center" isInline>
        <CustomDomainRepo customDomain={customDomain} />

        <Box>
          <K8SLogo height="0.9rem" />
        </Box>
        <Text>{cluster?.name}</Text>
        <Box>
          <Image height="0.9rem" src={doLogo} />
        </Box>
        <Text>{cluster.doAccount.defaultProject}</Text>
      </Stack>
    );
  }

  if (cluster?.cwAccount?.namespace) {
    return (
      <Stack fontSize="sm" fontWeight="bold" alignItems="center" isInline>
        <CustomDomainRepo customDomain={customDomain} />
        <Box>
          <K8SLogo height="0.9rem" />
        </Box>
        <Text>{cluster?.name}</Text>
        <Box>
          <Image height="0.9rem" src={cwLogo} />
        </Box>
        <Text>{cluster.cwAccount.namespace}</Text>
      </Stack>
    );
  }

  if (cluster?.linodeAccount?.accessTokenPrefix) {
    return (
      <Stack fontSize="sm" fontWeight="bold" alignItems="center" isInline>
        <CustomDomainRepo customDomain={customDomain} />
        <Box>
          <K8SLogo height="0.9rem" />
        </Box>
        <Text>{cluster?.name}</Text>
        <Box>
          <LinodeLogo height="0.9rem" />
        </Box>
        <Text>{cluster?.linodeAccount?.accessTokenPrefix}</Text>
      </Stack>
    );
  }

  return null;
};

const CustomDomainRepo: React.FC<{
  customDomain?: CustomDomain;
}> = ({ customDomain }) => {
  return (
    <Flex>
      {`${(customDomain?.repo && repoPath(customDomain.repo)) || ""}`}
    </Flex>
  );
};
