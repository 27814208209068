import {
  Flex,
  FormControl,
  FormErrorMessage,
  Spinner,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import Editor from "@monaco-editor/react";
import {
  RepoDetailFragment,
  useRepoCustomizationQuery,
  useRepoRenderPodSpecPatchLazyQuery,
  useUpdateProjectSettingsMutation,
} from "@zeet/web-api/dist/graphql";
import { Loading, useDebounce } from "@zeet/web-ui";
import React, { useEffect, useState } from "react";
import { FormCard } from "../../../FormCard";
import { shouldDisplayError } from "../../util";

/* cspell:disable */
const example = `# Example for adding /dev/shm to PodSpec
# containers:
# - name: container
#   volumeMounts:
#   - mountPath: /dev/shm
#     name: dshm
# volumes:
# - name: dshm
#   emptyDir:
#     medium: Memory
`;
/* cspell:enable */

const PodSpecSettingsInner: React.FC<{
  repo: RepoDetailFragment;
  initialPatch: string;
}> = ({ repo, initialPatch }) => {
  const toast = useToast();

  const [
    render,
    {
      data: podSpecData,
      previousData: prevPodSpecData,
      loading: rL,
      error: rE,
    },
  ] = useRepoRenderPodSpecPatchLazyQuery();

  const [podSpecPatch, setPodSpecPatch] = useState(initialPatch);

  const renderedData = (podSpecData || prevPodSpecData)?.repo
    ?.productionBranchV2?.renderPodSpec;

  const [focus, setFocus] = useState(false);
  const renderInput = useDebounce(podSpecPatch, 500);

  useEffect(() => {
    if (renderInput || focus) {
      render({
        variables: {
          id: repo.id,
          patch: renderInput,
        },
      });
    }
  }, [renderInput, render, repo, focus]);

  const [updateSettings, { error, loading, data }] =
    useUpdateProjectSettingsMutation({
      onCompleted: (data) => {
        if (data) {
          toast({
            title: "PodSpecPatch Saved",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      },
    });

  const onSubmit = (values): void => {
    updateSettings({
      variables: {
        input: {
          id: repo.id,
          kubernetesCustomization: {
            podSpecPatch: values.podSpecPatch,
          },
        },
      },
    });
  };

  if (error) {
    console.error(error);
  }

  const errorMsg = error?.message || (rE?.message && "Invalid Patch Input");

  return (
    <>
      <FormCard
        isLoading={loading}
        onSubmit={() => onSubmit({ podSpecPatch })}
        title="PodSpec Customization"
        description="Provide custom YAML patch to be applied to the PodSpec"
      >
        <Flex flexDirection="column">
          <FormControl
            isInvalid={!!errorMsg && shouldDisplayError(error, data)}
          >
            <Stack spacing={4}>
              <Editor
                onChange={(code) => setPodSpecPatch(code || "")}
                onMount={(editor) => {
                  editor.onDidFocusEditorText(() => setFocus(true));
                  editor.onDidBlurEditorText(() => setFocus(false));
                }}
                theme="vs-dark"
                defaultValue={initialPatch || example}
                defaultLanguage="yaml"
                options={{
                  lineNumbers: "off",
                  glyphMargin: false,
                  lineDecorationsWidth: 0,
                  lineNumbersMinChars: 0,
                  minimap: {
                    enabled: false,
                  },
                }}
                height="20rem"
                width="100%"
              />
              {shouldDisplayError(error, data) && (
                <FormErrorMessage>{errorMsg}</FormErrorMessage>
              )}
              {renderedData && (
                <>
                  <Flex alignItems={"center"}>
                    <Text mr={4}> Rendered Pod Spec </Text> {rL && <Spinner />}
                  </Flex>
                  <Editor
                    theme="vs-dark"
                    value={renderedData}
                    defaultLanguage="yaml"
                    options={{
                      lineNumbers: "off",
                      glyphMargin: false,
                      lineDecorationsWidth: 0,
                      lineNumbersMinChars: 0,
                      minimap: {
                        enabled: false,
                      },
                      readOnly: true,
                    }}
                    height="20rem"
                    width="100%"
                  />
                </>
              )}
            </Stack>
          </FormControl>
        </Flex>
      </FormCard>
    </>
  );
};

export const PodSpecSettings: React.FC<{
  repo: RepoDetailFragment;
}> = ({ repo }) => {
  const { data, loading } = useRepoCustomizationQuery({
    variables: {
      id: repo.id,
    },
  });

  if (loading) {
    return <Loading />;
  }

  return (
    <PodSpecSettingsInner
      repo={repo}
      initialPatch={data?.repo?.kubernetesCustomization?.podSpecPatch || ""}
    />
  );
};
