import { AddIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertIcon,
  Button,
  Divider,
  Flex,
  HStack,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Switch,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  useToast,
} from "@chakra-ui/react";
import {
  AutoscalingTriggerInput,
  CloudProvider,
  RepoDetailFragment,
  UpdateProjectInput,
  useUpdateProjectSettingsMutation,
  useUserDeployTargetsQuery,
} from "@zeet/web-api/dist/graphql";
import { FormSelect, Link, Loading, Select, useColors } from "@zeet/web-ui";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { CloudCostEstimate } from "~/components/CloudCost";
import { stripTypeNames } from "~/utils/ts-utils";
import { shouldDisplayError } from "../util";
import { ClusterOption } from "./ClusterOption";
import {
  AdvancedReplicationAutoscalingConfiguration,
  DeploymentReplicationInput,
  ReplicationConfigurationMode,
} from "./ReplicationAdvancedAutoscaling";

type Size = {
  name: string;
  longName?: string;
  text: string;
  price?: number;
  custom?: boolean;
  cpu: string;
  memory: string;
  gpu?: number;
};

const sizeOptions: Record<string, Size> = {
  Tiny: {
    name: "Tiny",
    longName: "Tiny - Shared Resources",
    cpu: "Shared",
    memory: "Shared",
    text: "Shared instance: Has no resource guarantee. Best for development and testing, limited reliability",
    price: 5,
  },
  Small: {
    name: "Small",
    cpu: "1",
    memory: "1",
    text: "Small instance: Great for non-production workloads.",
    price: 20,
  },
  Medium: {
    name: "Medium",
    cpu: "2",
    memory: "4",
    text: "Medium instance: Best suited for light production workloads.",
    price: 50,
  },
  Large: {
    name: "Large",
    cpu: "4",
    memory: "8",
    text: "Large instance: This is packed with power.",
    price: 100,
  },
  GPU: {
    name: "GPU",
    longName: "GPU - 4-Core - 16G Memory - Nvidia GPU",
    cpu: "4",
    memory: "14",
    gpu: 1,
    text: "GPU instance: Powered by the latest generation Nvidia GPU!",
    price: 505,
  },
  Custom: {
    name: "Custom",
    longName: "Custom Instance",
    cpu: "2",
    memory: "2",
    text: "Custom instance: Pick your own performance!",
  },
};

type AcceleratorOption = [string, boolean, string];

const awsAccelerators: AcceleratorOption[] = [
  ["Disabled", true, "disabled"],
  ["NVIDIA Tesla A100", false, "disabled-alt"],
  ["NVIDIA Tesla A10G", true, "nvidia-tesla-a10"],
  ["NVIDIA Tesla V100", false, "disabled-alt"],
  ["NVIDIA Tesla K80", false, "disabled-alt"],
  ["NVIDIA Tesla M60", false, "disabled-alt"],
  ["NVIDIA Tesla T4", true, "nvidia-tesla-t4"],
  ["AWS Elastic Inference", false, "disabled-alt"],
  ["AWS Inferentia", false, "disabled-alt"],
  ["AMD Radeon Pro V520", false, "disabled-alt"],
  ["Xilinx VU9P FPGA", false, "disabled-alt"],
  ["Xilinx U30 Media Accelerator", false, "disabled-alt"],
];

const gcpAccelerators: AcceleratorOption[] = [
  ["Disabled", true, "disabled"],
  ["NVIDIA Tesla A100", true, "nvidia-tesla-a100"],
  ["NVIDIA Tesla V100", true, "nvidia-tesla-v100"],
  ["NVIDIA Tesla P100", false, "disabled-alt"],
  ["NVIDIA Tesla K80", false, "disabled-alt"],
  ["NVIDIA Tesla T4", true, "nvidia-tesla-t4"],
  ["Google Cloud TPU v2", true, "v2"],
  ["Google Cloud TPU v3", true, "v3"],
];

const cwAccelerators: AcceleratorOption[] = [
  ["Disabled", true, "disabled"],
  ["NVIDIA H100 HGX", true, "H100_NVLINK_80GB"],
  ["NVIDIA H100 PCIe", true, "H100_PCIE"],
  ["NVIDIA A40", true, "A40"],
  ["NVIDIA A100 PCIe 40 GB", true, "A100_PCIE_40GB"],
  ["NVIDIA A100 PCIe 80 GB", true, "A100_PCIE_80GB"],
  ["NVIDIA A100 NVLINK 40 GB", true, "A100_NVLINK"],
  ["NVIDIA A100 NVLINK 80 GB", true, "A100_NVLINK_80GB"],
  ["NVIDIA V100 NVLINK", true, "Tesla_V100_NVLINK"],
  ["NVIDIA RTX A4000", true, "RTX_A4000"],
  ["NVIDIA RTX A5000", true, "RTX_A5000"],
  ["NVIDIA RTX A6000", true, "RTX_A6000"],
  ["NVIDIA RTX 4000", true, "Quadro_RTX_4000"],
  ["NVIDIA RTX 5000", true, "Quadro_RTX_5000"],
];

type SizeType = keyof typeof sizeOptions;

const getSize = (size: SizeType): Size => {
  return sizeOptions[size]!;
};

const stripRam = (ram?: string | null): string => {
  return ram ? ram.slice(0, ram.length - 1) : "";
};

const isTpu = (type: string): boolean => {
  return type === "v2" || type === "v3";
};

export type ReplicationFormInputs = {
  cpu: string;
  memory: string;
  ephemeralStorage: number;
  spot: boolean;
  gpuType: string;
  gpuCount: number;
  tpuCores: number;
  tpuTfVersion: string;
  triggers: AutoscalingTriggerInput[];
};

const getDefaultSize = (repo: RepoDetailFragment): SizeType => {
  let defaultSize = "Custom";
  const curSize = Object.keys(sizeOptions)
    .map(getSize)
    .filter(
      (o) => o.cpu === repo.cpu && o.memory === stripRam(repo.memory)
    )?.[0]?.name;

  if (curSize) {
    defaultSize = curSize;
  }

  if (repo.cpu === "" && repo.memory === "") {
    defaultSize = "Tiny";
  }

  if (repo.gpu?.count || repo.tpu?.cores) {
    defaultSize = "Custom";
  }

  return defaultSize;
};

export const AdvancedReplicationSettings: React.FC<{
  repo: RepoDetailFragment;
}> = ({ repo }) => {
  const toast = useToast();

  const { bg } = useColors();

  const [changed, setChanged] = useState(false);

  const defaultSize = getDefaultSize(repo);
  const [size, setSize] = useState<SizeType>(defaultSize);
  const isSizeTiny: boolean = size === "Tiny";

  const cloud = repo?.cluster?.cloudProvider as CloudProvider;

  let defaultGPUType = "nvidia-tesla-t4";
  let availableSizes = ["Tiny", "Small", "Medium", "Large", "GPU", "Custom"];

  if (cloud === CloudProvider.Coreweave) {
    defaultGPUType = "A40";
    availableSizes = ["Tiny", "Small", "Medium", "Large", "GPU", "Custom"];
  }

  // only set default GPU type if there is a GPU
  const defaultGPUValue =
    repo.gpu?.type ||
    repo.tpu?.type ||
    (repo.gpu?.count ? defaultGPUType : "disabled");

  const [autoscaling, setAutoscaling] = useState(
    (repo.autoscaling?.triggers?.length || 0) > 0
  );

  const defaultReplica = repo.replication?.[0]?.replicas || 0;

  const [replicas, setReplicas] = useState(
    autoscaling
      ? repo?.autoscaling?.minReplicas || defaultReplica
      : defaultReplica
  );
  const [maxReplicas, setMaxReplicas] = useState(
    autoscaling
      ? repo?.autoscaling?.maxReplicas || defaultReplica + 2
      : defaultReplica + 2
  );
  const [kedaScaledObjectSpec, setKedaScaledObjectSpec] = useState(
    repo?.autoscaling?.kedaScaledObjectSpec || null
  );

  const [configurationMode, setConfigurationMode] = useState(
    kedaScaledObjectSpec
      ? ReplicationConfigurationMode.advanced
      : ReplicationConfigurationMode.simple
  );

  // wrapper type for the underlying state properties of replication input
  const replicationInput: DeploymentReplicationInput = {
    selectedMode: configurationMode,
    minReplicas: replicas,
    maxReplicas: maxReplicas,
    kedaScaledObjectSpec: kedaScaledObjectSpec,
  };

  // translate from ReplicationInput to underlying state values
  const updateReplicationInput = (input: DeploymentReplicationInput) => {
    setConfigurationMode(input.selectedMode);
    switch (input.selectedMode) {
      case ReplicationConfigurationMode.advanced:
        setKedaScaledObjectSpec(input.kedaScaledObjectSpec);
        return;
      case ReplicationConfigurationMode.simple:
        setReplicas(input.minReplicas);
        setMaxReplicas(input.maxReplicas);
        return;
    }
  };

  const maxReplicaLimit = repo?.owner?.isTeam ? 20000 : 100;

  const fullAutoscalingConfigurationEnabled = !isSizeTiny && autoscaling;

  const [targets, setTargets] = useState<
    { id: string; name: string; region?: string | null }[]
  >(
    repo.replication?.map((r) => ({
      id: r.cluster?.id || "",
      name: r.cluster?.name || "",
      region: r.cluster?.region || r.region || "",
    })) || []
  );

  const hasSpot = [CloudProvider.Aws, CloudProvider.Gcp].includes(
    repo?.cluster?.cloudProvider as CloudProvider
  );

  const { handleSubmit, register, watch, control, setValue } =
    useForm<ReplicationFormInputs>({
      defaultValues: {
        cpu:
          defaultSize === "Custom" && repo.cpu
            ? repo.cpu
            : sizeOptions[size]?.cpu,
        memory:
          defaultSize === "Custom" && repo.memory
            ? stripRam(repo.memory)
            : sizeOptions[size]?.memory,
        ephemeralStorage: repo?.ephemeralStorage || 10,
        gpuType: defaultGPUValue,
        gpuCount: repo?.gpu?.count,
        tpuCores: repo?.tpu?.cores,
        tpuTfVersion: repo?.tpu?.tfVersion,
        spot: hasSpot ? !repo?.dedicated : false,
        triggers: stripTypeNames(repo?.autoscaling?.triggers || []),
      },
      shouldUnregister: false,
    });

  const watchForm = watch();
  const cpu = parseFloat(watch("cpu")) || 0;
  const ram = parseFloat(watch("memory")) || 0;
  const [updateSettings, { error, loading, data }] =
    useUpdateProjectSettingsMutation({
      onCompleted: (data) => {
        setChanged(false);
        if (data) {
          toast({
            title: !(
              repo.replication?.every((element) => {
                return targets.some((element2) => {
                  return element2.id === element?.cluster?.id;
                });
              }) && repo.replication?.length === targets.length
            )
              ? "Performance Settings Saved. Please ensure the DNS is set to the correct CNAME."
              : "Performance Settings Saved.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      },
      onError: (error) => {
        setChanged(false);
        if (error)
          toast({
            title: "Failed to save",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
      },
    });

  const onSubmit = (values: ReplicationFormInputs): void => {
    let cpuInput = cpu.toString();
    let memoryInput = ram.toString() + "G";
    let replicaInput = replicas || 0;
    let dedicated = !values.spot || false;

    if (isSizeTiny) {
      cpuInput = "";
      memoryInput = "";
      replicaInput = 1;
      dedicated = false;
    }

    const input: UpdateProjectInput = {
      id: repo.id,
      cpu: cpuInput,
      memory: memoryInput,
      ephemeralStorage: values.ephemeralStorage,
      tpu: {
        type: "",
        cores: 0,
        tfVersion: "",
      },
      gpu: {
        type: "",
        count: 0,
      },
      dedicated: dedicated,
      replication: targets.map((t) => {
        return {
          replicas: replicaInput,
          region: t.region || "",
          clusterID: t.id,
        };
      }),
    };

    if (isTpu(values.gpuType)) {
      input.tpu = {
        type: values.gpuType,
        cores: values.tpuCores,
        tfVersion: values.tpuTfVersion,
      };
    } else if (
      values.gpuType &&
      !(
        watchForm.gpuType === "disabled" || watchForm.gpuType === "disabled-alt"
      )
    ) {
      input.gpu = {
        type: values.gpuType,
        count: values.gpuCount || 0,
      };
    }

    if (fullAutoscalingConfigurationEnabled) {
      // when simple configuration is selected
      // we clear the kedaScaledObjectSpec on submit
      // TODO(anchor): persist the user's configurationMode choice server-side,
      //  allowing us to retain the kedaScaledObjectSpec value in the database,
      //  instead of relying on the "nullability" to determine whether
      //  simple or advanced configuration values should be used
      let kedaSpecValue = kedaScaledObjectSpec || "";
      if (configurationMode == ReplicationConfigurationMode.simple) {
        setKedaScaledObjectSpec(null);
        kedaSpecValue = "";
      }
      input.autoscaling = {
        kedaScaledObjectSpec: kedaSpecValue,
        triggers: values.triggers,
        minReplicas: replicas,
        maxReplicas: maxReplicas,
      };
    } else {
      input.autoscaling = {
        kedaScaledObjectSpec: "",
        minReplicas: replicas,
        maxReplicas: replicas,
        triggers: [],
      };
    }

    updateSettings({
      variables: {
        input: input,
      },
    });
  };

  const onChangeSize = (size) => {
    setSize(size);
    setValue("cpu", sizeOptions[size]?.cpu ?? "");
    setValue("memory", sizeOptions[size]?.memory ?? "");

    if (size === "GPU") {
      setValue("gpuType", defaultGPUType);
      setValue("gpuCount", 1);
    }

    if (size !== "GPU" && size !== "Custom") {
      setValue("gpuType", "disabled");
      setValue("gpuCount", 0);
    }
  };

  useEffect(() => {
    let isCustom = false;
    if (!cpu || !ram) {
      return;
    }

    if (cpu.toString() !== sizeOptions[size]?.cpu) {
      isCustom = true;
    }

    if (ram.toString() !== sizeOptions[size]?.memory) {
      isCustom = true;
    }

    if (
      !(
        watchForm.gpuType === "disabled" || watchForm.gpuType === "disabled-alt"
      ) &&
      watchForm.gpuType !== defaultGPUType
    ) {
      isCustom = true;
    }

    if (isCustom) {
      setSize("Custom");
    }

    if (cpu && ram) {
      setChanged(true);
    }
  }, [watchForm, cpu, ram, repo, size, defaultGPUType]);

  const renderOpt = (opt): string => {
    if (opt.longName) {
      return opt.longName;
    }

    return `${opt.name} - ${opt.cpu}-Core - ${opt.memory}G Memory`;
  };

  return (
    <Flex
      as="form"
      onSubmit={(e) => {
        e.stopPropagation();
        e.preventDefault();
        handleSubmit(onSubmit)(e);
      }}
      flexDir="column"
    >
      <Flex flexDir="column" px={6} pb={4}>
        <Stack spacing={4}>
          <Divider />
          <Stack spacing={2}>
            <Text whiteSpace="nowrap" fontWeight="bold">
              General Resources
            </Text>
            <Flex alignItems="center" mr={2}>
              <Text whiteSpace="nowrap"> Presets </Text>
              <Select
                ml={4}
                flex={1}
                onChange={(s) => onChangeSize(s?.value)}
                value={size}
                options={availableSizes.map((size) => {
                  return { value: size, label: renderOpt(sizeOptions[size]) };
                })}
              />
            </Flex>
            <Stack isInline spacing={[2, 2, 4]}>
              <Flex flex={1} alignItems="center">
                <Text mr={4} whiteSpace="nowrap">
                  CPU Cores
                </Text>
                <Input
                  flexGrow={1}
                  isDisabled={isSizeTiny}
                  {...register("cpu")}
                  className="nice-digits"
                />
              </Flex>
              <Flex flex={1} alignItems="center">
                <Text mr={4} whiteSpace="nowrap">
                  Memory (GB)
                </Text>
                <Input
                  flexGrow={1}
                  isDisabled={isSizeTiny}
                  {...register("memory")}
                  className="nice-digits"
                />
              </Flex>
            </Stack>
            {!isSizeTiny && (
              <Stack isInline spacing={[2, 2, 4]}>
                <Flex flex={1} alignItems="center">
                  <Text mr={4} whiteSpace="nowrap">
                    {repo?.cluster?.cloudProvider === CloudProvider.Aws
                      ? "Spot Instance"
                      : "Preemptible Instance"}
                  </Text>
                  <Flex flexGrow={1}>
                    <Switch
                      {...register("spot")}
                      size="lg"
                      colorScheme="brand"
                      isDisabled={!hasSpot}
                    />
                  </Flex>
                </Flex>
                <Flex flex={1} alignItems="center">
                  <Text mr={4} whiteSpace="nowrap">
                    Temporary Storage (GB)
                  </Text>
                  <Input
                    flexGrow={1}
                    type="number"
                    isDisabled={isSizeTiny}
                    {...register("ephemeralStorage", { valueAsNumber: true })}
                    className="nice-digits"
                  />
                </Flex>
              </Stack>
            )}
          </Stack>
          {!isSizeTiny && (
            <>
              <Divider />
              <Stack spacing={2}>
                <Flex flex={1} alignItems="center">
                  <Text whiteSpace="nowrap" fontWeight="bold">
                    Hardware Accelerator (GPU/TPU/FPGA)
                  </Text>

                  <FormSelect
                    ml={4}
                    flex={5}
                    {...register("gpuType")}
                    isOptionDisabled={(o) => o.disabled}
                    options={(repo?.cluster?.cloudProvider === CloudProvider.Aws
                      ? awsAccelerators
                      : repo?.cluster?.cloudProvider === CloudProvider.Gcp
                      ? gcpAccelerators
                      : repo?.cluster?.cloudProvider === CloudProvider.Coreweave
                      ? cwAccelerators
                      : [["Disabled", true, "disabled"] as AcceleratorOption]
                    ).map(([name, enabled, value]: AcceleratorOption) => {
                      return { value: value, label: name, disabled: !enabled };
                    })}
                  />
                </Flex>
                {!(
                  watchForm.gpuType === "disabled" ||
                  watchForm.gpuType === "disabled-alt"
                ) && (
                  <Stack isInline alignItems="center">
                    {!isTpu(watchForm.gpuType) && (
                      <Flex flex={1} alignItems="center">
                        <Text whiteSpace="nowrap" mr={4}>
                          Accelerator Count
                        </Text>
                        <Input
                          flexGrow={1}
                          type="number"
                          {...register("gpuCount", { valueAsNumber: true })}
                        />
                      </Flex>
                    )}
                    {isTpu(watchForm.gpuType) && (
                      <>
                        <Stack flex={1} isInline alignItems="center">
                          <Text whiteSpace="nowrap">TPU Cores</Text>
                          <FormSelect
                            flex={2}
                            {...register("tpuCores", { valueAsNumber: true })}
                            defaultValue="8"
                            options={[
                              "8",
                              "32",
                              "128",
                              "256",
                              "512",
                              "1024",
                              "2048",
                            ].map((type) => {
                              return { value: type, label: type };
                            })}
                          />
                        </Stack>
                        <Stack isInline flex={1} alignItems="center">
                          <Text flex={1} whiteSpace="nowrap">
                            Tensorflow Version
                          </Text>
                          <FormSelect
                            flex={3}
                            {...register("tpuTfVersion")}
                            defaultValue="2.5.0"
                            options={[
                              "2.1",
                              "2.2",
                              "2.3",
                              "2.4",
                              "2.5.0",
                              "2.6.0",
                            ].map((version) => {
                              return { value: version, label: version };
                            })}
                          />
                        </Stack>
                      </>
                    )}
                  </Stack>
                )}
              </Stack>
            </>
          )}
          <Divider />
          <Stack spacing={2}>
            <Flex flex={1} alignItems="center">
              <Text whiteSpace="nowrap" fontWeight="bold">
                Replication
              </Text>

              <Text ml="auto">Autoscaling {autoscaling ? "On" : "Off"}</Text>
              <Switch
                ml={4}
                colorScheme="brand"
                isDisabled={isSizeTiny}
                onChange={(v) => {
                  setAutoscaling(v.target.checked);
                }}
                defaultChecked={autoscaling}
              />
            </Flex>
            <ClusterSelection
              repo={repo}
              targets={targets}
              setTargets={(targets) => {
                setChanged(true);
                setTargets(targets);
              }}
            />
            {fullAutoscalingConfigurationEnabled ? (
              // if non-tiny instance, full autoscaling configuration is enabled
              <AdvancedReplicationAutoscalingConfiguration
                sizeIsTiny={isSizeTiny}
                replicationInput={replicationInput}
                updateReplicationInput={updateReplicationInput}
                maxReplicaLimit={maxReplicaLimit}
                setChanged={setChanged}
                control={control}
                watch={watch}
                setValue={setValue}
              />
            ) : (
              // if tiny instance, only static replica count is supported
              <Flex flex={1} alignItems="center">
                <Text mr={4}> Replicas </Text>
                <NumberInput
                  isDisabled={isSizeTiny}
                  min={0}
                  max={repo?.owner?.isTeam ? 20000 : 100}
                  keepWithinRange={false}
                  value={
                    isSizeTiny ? 1 : Number.isNaN(replicas) ? "" : replicas
                  }
                  onChange={(_, v) => {
                    setChanged(true);
                    if (v < 0) {
                      v = 0;
                    }
                    setReplicas(v);
                  }}
                  flexGrow={1}
                >
                  <NumberInputField name="replicas" />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </Flex>
            )}
          </Stack>
          {shouldDisplayError(error, data) && (
            <>
              <Divider />
              <Alert status="error">
                <AlertIcon />
                {error?.message}
              </Alert>
            </>
          )}
        </Stack>
      </Flex>
      <Divider />
      <Flex bg={bg} as="footer" py={3} px={6} align="center">
        <CloudCostEstimate />
        <Button
          type="submit"
          isDisabled={!changed}
          isLoading={loading}
          colorScheme="brand"
          px={6}
          size="sm"
          ml="auto"
        >
          {"Save"}
        </Button>
      </Flex>
    </Flex>
  );
};

const ClusterSelection: React.FC<{
  repo: RepoDetailFragment;
  targets: { id: string; name: string; region?: string | null }[];
  setTargets: (
    targets: { id: string; name: string; region?: string | null }[]
  ) => void;
}> = ({ repo, targets, setTargets }) => {
  const { data: targetsData, loading: targetsLoading } =
    useUserDeployTargetsQuery({
      variables: {
        id: repo.owner.id,
      },
    });

  if (targetsLoading) {
    return <Loading />;
  }
  return (
    <HStack flexWrap="wrap" gridRowGap={2}>
      <Text whiteSpace="nowrap">Target Clusters</Text>
      {targets?.map((t) => {
        return (
          <Tag key={t.id} size="lg" variant="outline" colorScheme="brand">
            <Link
              to={`/${repo?.owner.login}/console/clusters/view`}
              noUnderline
              isExternal
            >
              <TagLabel>{`${t?.name || "zeet-cloud"}`}</TagLabel>
            </Link>

            {targets.length > 1 && (
              <TagCloseButton
                onClick={() => {
                  setTargets(targets.filter((tt) => t.id !== tt.id));
                }}
              />
            )}
          </Tag>
        );
      })}
      <Menu>
        <MenuButton
          as={Button}
          leftIcon={<AddIcon />}
          size="sm"
          colorScheme="brand"
        >
          Add Target
        </MenuButton>
        <MenuList maxH="250px" overflowY="auto">
          {targetsData?.user?.clusters
            ?.filter((c) => !targets?.filter((t) => t.id === c.id)?.length)
            .map((c) => {
              return (
                <MenuItem
                  key={c.id}
                  onClick={() => {
                    setTargets([...targets, c]);
                  }}
                >
                  <ClusterOption
                    clusterId={c.id}
                    clusters={targetsData.user.clusters}
                  />
                </MenuItem>
              );
            })}
          <Link to={`/${repo?.owner.login}/console/clusters/new`} noUnderline>
            <MenuItem>New Cluster</MenuItem>
          </Link>
        </MenuList>
      </Menu>
    </HStack>
  );
};
