import { Box, Flex, FlexProps, Grid, GridItem, Text } from "@chakra-ui/react";
import { Card, useColors } from "@zeet/web-ui";
import { ReactNode, useMemo } from "react";
import { EmptyState } from "../EmptyState";
import { useListViewContext } from "../Provider";

export const LIST_VIEW_CARDS_VIEW_TYPE = "LIST_VIEW_CARDS_VIEW_TYPE";

interface Field {
  id: string;
  render?: (any) => ReactNode;
}

interface ListViewCardsProps {
  fields: Array<Field>;
}

export const ListViewCards = ({
  fields,
  ...flexProps
}: ListViewCardsProps & FlexProps) => {
  const { bg } = useColors();
  const { columns, state } = useListViewContext();

  const columnLabelsByField = useMemo(() => {
    const columnLabelEntries = columns
      .map((column) => [column.id, column.label])
      .filter(([id]) => fields.map(({ id }) => id).includes(id!));

    return Object.fromEntries(columnLabelEntries);
  }, [columns, fields]);

  if (state.data.length < 1) {
    return <EmptyState heading="No matches. Try modifying your filters." />;
  }

  return (
    <Flex flexDirection="column" gap={4} {...flexProps}>
      {state.data.map((item, index) => (
        <Card
          px={6}
          py={4}
          _hover={{ background: bg, textDecoration: "none" }}
          key={index}
        >
          <Grid
            gap={4}
            gridTemplateColumns={{
              base: "repeat(1, 1fr)",
              sm: "repeat(2, 1fr)",
              md: "repeat(4, 1fr)",
            }}
          >
            {fields.map((field) => (
              <GridItem key={field.id} overflow="hidden">
                <Text fontSize="12px" fontWeight="500" color="gray.500">
                  {columnLabelsByField[field.id]}
                </Text>

                <Text mt="2px" fontSize="14px" display="flex">
                  {field.render ? (
                    field.render(item[field.id])
                  ) : (
                    <Box
                      overflow="hidden"
                      whiteSpace="nowrap"
                      textOverflow="ellipsis"
                    >
                      {item[field.id]?.text}
                    </Box>
                  )}
                </Text>
              </GridItem>
            ))}
          </Grid>
        </Card>
      ))}
    </Flex>
  );
};
