import {
  AspectRatio,
  Box,
  ButtonGroup,
  IconButton,
  Text,
} from "@chakra-ui/react";
import useComponentSize from "@rehooks/component-size"; // cspell:disable-line
import { Card, Input, SearchableProps, useColors } from "@zeet/web-ui";
import { atom, useAtom } from "jotai";
import React, {
  FormEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { IDisposable, Terminal, type ITheme } from "xterm";
import { CanvasAddon } from "xterm-addon-canvas";
import { FitAddon } from "xterm-addon-fit";
import { ISearchOptions, SearchAddon } from "xterm-addon-search";
import { WebglAddon } from "xterm-addon-webgl";
import "xterm/css/xterm.css";
import "./ZTerm.scss";
import themes from "./zTermSchemes";

interface ZTermProps extends SearchableProps {
  xterm: React.MutableRefObject<Terminal | null>;
  fillContainer?: true;
  onData?(data: string): void;
  onResize?(event: { cols: number; rows: number }): void;
}

const defaultTheme = "chalk";
const terminalThemeKey = "zeet_terminal_theme";
export const themeAtom = atom<string, string>(
  () =>
    (localStorage.getItem(terminalThemeKey) || defaultTheme).replace(/"/g, ""),
  (_, set, newTheme) => {
    localStorage.setItem(terminalThemeKey, newTheme);
    set(themeAtom, newTheme);
  }
);

function useZTermTheme(): ITheme {
  const [themeId] = useAtom(themeAtom);

  return useMemo(() => {
    const rawTheme = themes[themeId];
    if (!rawTheme) {
      return {};
    }

    const xTermTheme: ITheme = {};
    Object.keys(rawTheme.colors).forEach((key) => {
      const newKey = `${key}`
        .replace("terminal.", "")
        .replace("ansi", "")
        .toLowerCase();
      xTermTheme[newKey] = rawTheme.colors[key];
    });
    return xTermTheme;
  }, [themeId]);
}

const safeFit = (addon?: FitAddon) => {
  if (!addon) {
    return;
  }

  if (Number.isFinite(addon.proposeDimensions()?.cols)) {
    addon.fit();
  }
};

const getWebGlAddon = () => {
  try {
    return new WebglAddon();
  } catch (e) {
    console.warn("Error initializing webgl for xterm", e);
    return null;
  }
};

// i know it says deprecated but even MDN says despite deprecation, do this.
// technically should use navigator.userAgentData but its not in TS dom lib yet :/
const isMacOs = () => navigator.platform.startsWith("Mac");

export const ZTerm: React.FC<ZTermProps> = ({
  xterm,
  fillContainer,
  searching,
  setSearching,
  onData,
  onResize,
}) => {
  const theme = useZTermTheme();
  const boxRef = useRef<HTMLDivElement>(null);
  const containerRef: React.MutableRefObject<HTMLDivElement | null> =
    useRef<HTMLDivElement>(null);
  const listeners = useRef<IDisposable[]>([]);
  const [initialized, setInitialized] = useState(false);
  const fitAddonRef = useRef(new FitAddon());
  const searchAddonRef = useRef(new SearchAddon());
  const webglAddonRef = useRef(getWebGlAddon());
  const canvasAddonRef = useRef(new CanvasAddon());

  const onKeyPress = useCallback(
    (e: { domEvent: KeyboardEvent }) => {
      const isModifierPressed = isMacOs()
        ? e.domEvent.metaKey
        : e.domEvent.ctrlKey;
      if (
        isModifierPressed &&
        e.domEvent.key.toLowerCase() === "f" &&
        setSearching
      ) {
        e.domEvent.preventDefault();
        setSearching(!searching);
      }
    },
    [searching, setSearching]
  );

  const { width, height } = useComponentSize(boxRef);

  useLayoutEffect(() => {
    if (xterm.current) {
      xterm.current.resize(10, 10);
      safeFit(fitAddonRef.current);
    }
  }, [xterm, width, height]);

  const createTerminal = useCallback(
    (container: HTMLDivElement) => {
      containerRef.current = container;
      if (!container) {
        xterm.current = null;
        setInitialized(false);
        return;
      }

      xterm.current?.dispose();
      container.innerHTML = "";
      xterm.current = new Terminal({
        theme,
        scrollback: 9999999, // cspell:disable-line
        allowProposedApi: true,
      });
      xterm.current.open(container);
      xterm.current.loadAddon(fitAddonRef.current);
      if (webglAddonRef.current) {
        try {
          xterm.current.loadAddon(webglAddonRef.current);
        } catch (e) {
          console.warn("WebGL xterm unavailable, falling back to canvas");
          xterm.current.loadAddon(canvasAddonRef.current);
        }
      } else {
        xterm.current.loadAddon(canvasAddonRef.current);
      }
      xterm.current.loadAddon(searchAddonRef.current);
      xterm.current.focus();
      safeFit(fitAddonRef.current);
      setInitialized(true);
    },
    [theme, xterm]
  );

  useEffect(() => {
    listeners.current.forEach((l) => l.dispose());
    listeners.current = [];

    if (!xterm.current) return;
    listeners.current.push(xterm.current.onKey(onKeyPress));
    if (onData) listeners.current.push(xterm.current.onData(onData));
    if (onResize) listeners.current.push(xterm.current.onResize(onResize));

    // for some reason, meta key events don't pass through via xterm. so we have to use the
    // dom instead. they also don't pass through any react containers :/
    if (!isMacOs) return;

    const htmlHandler = (e: KeyboardEvent) => onKeyPress({ domEvent: e });
    containerRef.current?.addEventListener("keydown", htmlHandler);
    return () => {
      containerRef.current?.removeEventListener("keydown", htmlHandler);
    };
  }, [initialized, onData, onResize, onKeyPress, xterm]);

  useEffect(() => {
    if (!searching) {
      searchAddonRef.current.clearDecorations();

      // give the terminal back focus
      xterm.current?.focus();
    }
  }, [searching, xterm]);

  const terminal = <div ref={createTerminal} className="z-xterm" />;

  if (fillContainer) {
    return (
      <Box
        overflow="hidden"
        ref={boxRef}
        py={1}
        pl={2}
        height="100%"
        width="100%"
        bg={theme.background}
        position="relative"
      >
        {searching && (
          <ZTermSearchBar
            search={searchAddonRef.current}
            setSearching={setSearching}
          />
        )}
        {terminal}
      </Box>
    );
  }

  return (
    <Box
      overflow="hidden"
      ref={boxRef}
      pl="4"
      bg={theme.background}
      position="relative"
    >
      {searching && (
        <ZTermSearchBar
          search={searchAddonRef.current}
          setSearching={setSearching}
        />
      )}

      <AspectRatio w="100%">{terminal}</AspectRatio>
    </Box>
  );
};

interface ZTermSearchBarProps {
  search: SearchAddon;
  setSearching?(searching: boolean): unknown;
}

const ZTermSearchBar = ({ search, setSearching }: ZTermSearchBarProps) => {
  const { bg } = useColors();
  const [query, setQuery] = useState<string | null>(null);
  const [resultIndex, setResultIndex] = useState<number | null>(null);
  const [resultCount, setResultCount] = useState<number | null>(null);

  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key.toLowerCase() === "enter" || e.key.toLowerCase() === "return") {
      if (e.shiftKey) {
        previous();
      } else {
        next();
      }

      return;
    }

    if (!(isMacOs() ? e.metaKey : e.ctrlKey)) return;
    if (e.key.toLowerCase() !== "f") return;

    e.preventDefault();
    setSearching && setSearching(false);
  };

  const onInput = (e: FormEvent<HTMLInputElement>) => {
    const text = (e.target as HTMLInputElement).value;
    if (query !== text) {
      if (text === "") {
        search.clearDecorations();
        setResultIndex(null);
        setResultCount(null);
      }
      setQuery(text);
    }
  };

  const searchOptions = useMemo<ISearchOptions>(
    () => ({
      caseSensitive: false,
      incremental: true,
      decorations: {
        activeMatchBackground: "#ff6f00",
        matchBackground: "#0277bd",
        activeMatchColorOverviewRuler: "",
        matchOverviewRuler: "",
      },
    }),
    []
  );

  const next = () => {
    if (!query) return;
    search.findNext(query, searchOptions);
  };

  const previous = () => {
    if (!query) return;
    search.findPrevious(query, searchOptions);
  };

  useEffect(next, [query, search, searchOptions]);

  useEffect(() => {
    const listener = search.onDidChangeResults(
      ({ resultIndex, resultCount }) => {
        setResultIndex(resultIndex);
        setResultCount(resultCount);
      }
    );

    return () => listener.dispose();
  }, [search]);

  const onBlur = () => search.clearActiveDecoration();

  const resultNumber =
    resultIndex === null || resultIndex === -1 ? "?" : resultIndex + 1;

  return (
    <Card
      position="absolute"
      top={0}
      bg={bg}
      right={8}
      zIndex={5}
      borderTop="none"
      borderTopLeftRadius={0}
      borderTopRadius={0}
      p={2}
      display="flex"
      alignItems="center"
      onKeyDown={onKeyDown}
    >
      <Input
        placeholder="Find..."
        py={1}
        height="auto"
        border="1px solid var(--chakra-colors-chakra-border-color)"
        autoFocus
        onInput={onInput}
        onBlur={onBlur}
      />
      <Text whiteSpace="nowrap" fontSize="xs" mx={2} minWidth="55px">
        {resultCount === null || resultCount === 0
          ? "No results"
          : `${resultNumber} of ${resultCount}`}
      </Text>
      <ButtonGroup size="sm" isAttached variant="outline" ml={1}>
        <IconButton
          aria-label="Previous Result"
          icon={<BiChevronUp />}
          onClick={previous}
          onBlur={onBlur}
        />
        <IconButton
          aria-label="Next Result"
          icon={<BiChevronDown />}
          onClick={next}
          onBlur={onBlur}
        />
      </ButtonGroup>
    </Card>
  );
};
