import { AddIcon, SettingsIcon, UpDownIcon } from "@chakra-ui/icons";
import {
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { useUserTeamsQuery } from "@zeet/web-api/dist/graphql";
import {
  Avatar,
  useCurrentTeamUser,
  useCurrentUser,
  useSetTeamId,
  useTrack,
} from "@zeet/web-ui";
import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

export const TeamSwitcher: React.FC = () => {
  const { track } = useTrack();
  const currentUser = useCurrentUser();
  const currentTeamUser = useCurrentTeamUser();
  const setTeamId = useSetTeamId();
  const teamUser = useCurrentTeamUser();
  const { data, refetch } = useUserTeamsQuery();
  const teamUserId = teamUser?.id;

  const history = useHistory();

  useEffect(() => {
    refetch();
  }, [teamUserId, refetch]);

  if (!teamUser || !currentUser) {
    return null;
  }

  return (
    <Flex alignItems="center" zIndex={100}>
      <Flex
        as={Link}
        to={`/${currentTeamUser?.login}/console/home`}
        alignItems="center"
      >
        <Avatar size="xs" user={teamUser} borderRadius="md" />
        <Text
          ml={2}
          mr={1}
          fontWeight="bold"
          noOfLines={1}
          maxW="9rem"
          wordBreak="break-all"
        >
          {teamUser?.login}
        </Text>
      </Flex>
      <Menu flip placement="bottom">
        <MenuButton
          variant="ghost"
          size="xs"
          as={IconButton}
          icon={<UpDownIcon />}
        />
        <MenuList maxH="90vh" overflowY="auto">
          {currentUser?.hasZeetCloud && (
            <>
              <MenuGroup title="Personal Account">
                <MenuItem
                  flexDir="row-reverse"
                  iconSpacing={0}
                  icon={
                    <IconButton
                      as={Link}
                      ml="auto"
                      bg=""
                      size="xs"
                      aria-label="settings"
                      icon={<SettingsIcon />}
                      to={`/${currentUser?.login}/account/profile`}
                    />
                  }
                  onClick={(e) => {
                    setTeamId("");
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //@ts-ignore
                    if (e.target.nodeName === "BUTTON") {
                      history.push(`/${currentUser?.login}/console/home`);
                    }
                  }}
                >
                  <Flex>
                    <Avatar size="xs" user={currentUser} />
                    <Text fontSize="sm" ml={2} mr={1} word-break="break-all">
                      {currentUser?.login}
                    </Text>
                  </Flex>
                </MenuItem>
              </MenuGroup>
              <MenuDivider />
            </>
          )}
          {data?.currentUser.teams?.length ? (
            <>
              <MenuGroup title="Teams">
                {data.currentUser.teams.map((t) => (
                  <MenuItem
                    iconSpacing={0}
                    flexDir="row-reverse"
                    icon={
                      <IconButton
                        as={Link}
                        onClick={() => {
                          // cspell:disable-next-line
                          track("teamswitcher_click_team_settings", {
                            teamId: t.team.id,
                          });
                        }}
                        to={`/${t?.team.user.login}/account/profile`}
                        ml="auto"
                        bg=""
                        size="xs"
                        aria-label="settings"
                        icon={<SettingsIcon />}
                      />
                    }
                    key={t.id}
                    onClick={(e) => {
                      //workaround for actually redirecting to the dashboard but *only* if the click is on the button itself and not on the icon button above...
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      //@ts-ignore
                      if (e.target.nodeName === "BUTTON") {
                        history.push(`/${t.team.user.login}/console/home`);
                      }
                    }}
                  >
                    <Flex>
                      <Avatar size="xs" borderRadius="md" user={t.team.user} />
                      <Text
                        fontSize="sm"
                        ml={2}
                        mr={1}
                        textOverflow="ellipsis"
                        overflow="hidden"
                        maxWidth="150px"
                        whiteSpace="nowrap"
                      >
                        {t.team.user.name}
                      </Text>
                    </Flex>
                  </MenuItem>
                ))}
              </MenuGroup>
              <MenuDivider />
            </>
          ) : null}
          <MenuItem
            iconSpacing={0}
            as={Link}
            onClick={() => track("click_team_switcher_new_team")}
            to="/team-onboard"
            icon={
              <IconButton
                pointerEvents="none"
                aria-label=""
                ml="auto"
                bg=""
                size="xs"
                icon={<AddIcon />}
              />
            }
            flexDir="row-reverse"
          >
            <Text fontSize="sm">New Team</Text>
          </MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  );
};
