import { DeleteIcon } from "@chakra-ui/icons";
import { Flex, IconButton, useToast } from "@chakra-ui/react";
import {
  useDeleteTeamMemberInvitationMutation,
  UserBillingOverviewDocument,
  UserTeamMemberQuery,
} from "@zeet/web-api/dist/graphql";
import { useCurrentTeamUser } from "@zeet/web-ui";
import { FC } from "react";

interface InvitationDeleteProps {
  invitation: NonNullable<
    UserTeamMemberQuery["currentUser"]["team"]
  >["memberInvitations"][0];
  refetchTeamMembers: () => void;
}

export const InvitationDelete: FC<InvitationDeleteProps> = ({
  invitation,
  refetchTeamMembers,
}) => {
  const toast = useToast();
  const currentTeamUser = useCurrentTeamUser();

  const [deleteInvite, { loading: diLoading }] =
    useDeleteTeamMemberInvitationMutation({
      onCompleted: (data) => {
        if (data) {
          toast({
            title: "Team Member Invite Deleted",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          refetchTeamMembers();
        }
      },
      refetchQueries: [
        {
          query: UserBillingOverviewDocument,
          variables: { id: currentTeamUser.id },
        },
      ],
    });
  return (
    <Flex justifyContent="flex-start">
      <IconButton
        onClick={() =>
          deleteInvite({
            variables: {
              id: invitation.id,
            },
          })
        }
        isLoading={diLoading}
        size="sm"
        borderRadius="sm"
        aria-label="remove team invite"
        icon={<DeleteIcon />}
      />
    </Flex>
  );
};
