import { ChevronDownIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  Button,
  Tooltip as CTooltip,
  Divider,
  Flex,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import Editor from "@monaco-editor/react";
import {
  AutoscalingTriggerInput,
  AutoscalingType,
  FeatureKey,
  useSubscriptionFeatureQuery,
} from "@zeet/web-api/dist/graphql";
import {
  AnalyticsEvent,
  Select,
  Tooltip,
  useCurrentTeamUser,
  useEffectOnce,
  useTrack,
} from "@zeet/web-ui";
import yaml from "js-yaml";
import React, { useEffect, useMemo, useState } from "react";
import {
  Control,
  Controller,
  useFieldArray,
  UseFormSetValue,
} from "react-hook-form";
import { ImArrowUp } from "react-icons/im";
import { UpgradeProductModal } from "~/components/Account/BillingV2/Modals/UpgradeProductModal";
import { useBillingOverviewContext } from "~/components/Account/BillingV2/Providers/BillingOverviewProvider";
import {
  BillingModalSource,
  billingModalText,
  getHeaderText,
} from "~/components/Account/BillingV2/utils";
import { SIDEBAR_WIDTH } from "~/components/Sidebar";
import { ReplicationFormInputs } from "./ReplicationAdvanced";

export enum ReplicationConfigurationMode {
  simple = "simple",
  advanced = "advanced",
}

export type DeploymentReplicationInput = {
  selectedMode: ReplicationConfigurationMode;
  minReplicas: number;
  maxReplicas: number;
  kedaScaledObjectSpec: string | null;
};

interface AutoscalingProps {
  sizeIsTiny: boolean;
  replicationInput: DeploymentReplicationInput;
  updateReplicationInput: (DeploymentReplicationInput) => void;
  maxReplicaLimit: number;
  setChanged: (boolean) => void;
  control: Control<any>;
  watch: any;
  setValue: UseFormSetValue<ReplicationFormInputs>;
}

export const AdvancedReplicationAutoscalingConfiguration: React.FC<
  AutoscalingProps
> = ({
  sizeIsTiny,
  replicationInput,
  updateReplicationInput,
  maxReplicaLimit,
  setChanged,
  control,
  watch,
  setValue,
}) => {
  const {
    selectedMode: configurationMode,
    minReplicas,
    maxReplicas,
    kedaScaledObjectSpec: advancedReplicationYaml,
  } = replicationInput;

  const setConfigurationMode = (mode: ReplicationConfigurationMode) => {
    const input = {
      ...replicationInput,
      selectedMode: mode,
    };
    updateReplicationInput(input);
  };

  const setMinReplicas = (replicaCount: number) => {
    const input: DeploymentReplicationInput = {
      ...replicationInput,
      minReplicas: replicaCount,
    };
    updateReplicationInput(input);
  };

  const setMaxReplicas = (replicaCount: number) => {
    const input: DeploymentReplicationInput = {
      ...replicationInput,
      maxReplicas: replicaCount,
    };
    updateReplicationInput(input);
  };

  const setAdvancedReplicationYaml = (replicationYaml: string) => {
    const input: DeploymentReplicationInput = {
      ...replicationInput,
      kedaScaledObjectSpec: replicationYaml,
    };
    updateReplicationInput(input);
  };

  const getSimpleReplicaConfiguration = () => {
    return (
      <>
        <Text> Min Replicas </Text>
        <NumberInput
          isDisabled={sizeIsTiny}
          min={0}
          max={maxReplicas || maxReplicaLimit}
          keepWithinRange={false}
          value={sizeIsTiny ? 1 : Number.isNaN(minReplicas) ? "" : minReplicas}
          onChange={(_, v) => {
            setChanged(true);
            setMinReplicas(v);
          }}
          flexGrow={1}
        >
          <NumberInputField name="replicas" />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
        <Text> Max Replicas </Text>
        <NumberInput
          isDisabled={sizeIsTiny}
          min={minReplicas}
          max={maxReplicaLimit}
          keepWithinRange={false}
          value={sizeIsTiny ? 1 : Number.isNaN(maxReplicas) ? "" : maxReplicas}
          onChange={(_, v) => {
            setChanged(true);
            setMaxReplicas(v);
          }}
          flexGrow={1}
        >
          <NumberInputField name="maxReplicas" />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </>
    );
  };

  const getAdvancedReplicationConfigurationWidget = () => {
    return (
      <Editor
        theme="vs-dark"
        value={advancedReplicationYaml || ""}
        defaultLanguage="yaml"
        options={{
          lineNumbers: "off",
          glyphMargin: false,
          lineDecorationsWidth: 0,
          lineNumbersMinChars: 0,
          minimap: {
            enabled: false,
          },
        }}
        height="20rem"
        width="100%"
        onChange={(contents) => setAdvancedReplicationYaml(contents || "")}
      />
    );
  };

  const configurationWidget = () => {
    if (configurationMode === ReplicationConfigurationMode.simple) {
      return getSimpleReplicaConfiguration();
    } else if (configurationMode === ReplicationConfigurationMode.advanced) {
      return getAdvancedReplicationConfigurationWidget();
    }
  };

  return (
    <>
      <Divider />
      <Stack isInline spacing={4} alignItems={"center"}>
        <Select
          width={"max-content"}
          value={configurationMode.valueOf()}
          onChange={(e) =>
            setConfigurationMode(e?.value as ReplicationConfigurationMode)
          }
          options={[
            {
              value: ReplicationConfigurationMode.simple,
              label: "Simple Configuration",
            },
            {
              value: ReplicationConfigurationMode.advanced,
              label: "Advanced Configuration (YAML)",
            },
          ]}
          flex={1}
        />
        <Tooltip
          text={
            "Simple Configuration allows you to specify only min- and max-replicas. " +
            "Advanced Configuration allows to you specify a complete KEDA ScaledObjectSpec " +
            "(all keys except for scaleTargetRef and triggers)"
          }
        />
      </Stack>
      <Stack isInline spacing={4} flex={1} alignItems="center">
        {configurationWidget()}
      </Stack>
      <AutoscalingTriggers
        control={control}
        watch={watch}
        setValue={setValue}
      />
    </>
  );
};

const AutoscalingTriggers: React.FC<{
  control: any;
  watch: any;
  setValue: UseFormSetValue<ReplicationFormInputs>;
}> = ({ control, watch, setValue }) => {
  const { fields, append, remove } = useFieldArray<AutoscalingTriggerInput>({
    control,
    name: "triggers",
  });

  const watchTriggers = watch("triggers");
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchTriggers[index],
    };
  });

  useEffectOnce(() => {
    if (!fields?.length) {
      append(
        {
          type: AutoscalingType.Cpu,
          spec: `type: cpu
metadata:
  type: Utilization
  value: "60"
  containerName: "container"`,
        },
        { shouldFocus: false }
      );
    }
  });

  return (
    <>
      {controlledFields.map((item, index) => (
        <ScalingTrigger
          key={item.id}
          trigger={item}
          control={control}
          index={index}
          remove={remove}
          setValue={setValue}
          watch={watch}
        />
      ))}
      <Flex>
        <Button
          ml="auto"
          colorScheme="brand"
          size="sm"
          onClick={() =>
            append(
              { type: AutoscalingType.Cpu, spec: "" },
              { shouldFocus: false }
            )
          }
        >
          + Add Autoscaling Trigger
        </Button>
      </Flex>
    </>
  );
};
const ScalingTrigger = ({
  trigger,
  control,
  setValue,
  index,
  remove,
  watch,
}) => {
  const currentTeamUser = useCurrentTeamUser();

  const [{ dataProductCatalog }] = useBillingOverviewContext();
  const { data } = useSubscriptionFeatureQuery({
    variables: {
      id: currentTeamUser.id,
      featureKey: FeatureKey.AdvancedAutoscaling,
    },
  });
  const isPriorityUser = useMemo(() => {
    return (
      data?.user.billingOverview?.subscriptionFeatures.byKey?.enabled || false
    );
  }, [data]);

  const { onOpen, onClose, isOpen } = useDisclosure();
  const { track } = useTrack();

  const autoscaleOptions = [
    {
      key: AutoscalingType.Cpu,
      text: "CPU Utilization",
      image: "",
      isPriorityFeature: false,
    },
    {
      key: AutoscalingType.Memory,
      text: "Memory Utilization",
      image: "",
      isPriorityFeature: false,
    },
    {
      key: AutoscalingType.Prometheus,
      text: "Prometheus Metrics",
      image: <ImArrowUp fill="#00EF00" />,
      isPriorityFeature: true,
    },
    {
      key: AutoscalingType.Custom,
      text: "Custom KEDA Scaler",
      image: <ImArrowUp fill="#00EF00" />,
      isPriorityFeature: true,
    },
  ];

  const watchForm = watch();

  const initialSpec: any = useMemo(() => {
    try {
      return yaml.load(trigger?.spec);
    } catch (e) {
      console.error(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack spacing={2} alignItems="center">
      <Divider />
      <Flex flexDirection="row" alignItems="center" width="100%">
        <Text mr={6} flexShrink={1}>
          Scaling Trigger
        </Text>
        <>
          <Menu>
            <MenuButton
              as={Button}
              aria-label="Options"
              rightIcon={<ChevronDownIcon />}
              mr={4}
              width={`calc(100% - 100px - ${SIDEBAR_WIDTH})`}
            >
              {
                autoscaleOptions.find(
                  (o) => o.key === watchForm.triggers[index].type
                )?.text
              }
            </MenuButton>
            <MenuList>
              {autoscaleOptions.map((o, i) => {
                const requiresUpgrade = o.isPriorityFeature && !isPriorityUser;
                return (
                  <CTooltip
                    placement="top"
                    label="Requires subscription upgrade"
                    isDisabled={!o.isPriorityFeature || isPriorityUser}
                  >
                    <MenuItem
                      key={i}
                      onClick={() => {
                        if (requiresUpgrade) {
                          onOpen();
                          track(AnalyticsEvent.UPGRADE_MODAL_DISPLAYED, {
                            upgrade_entity: BillingModalSource.AUTOSCALING,
                          });
                        } else {
                          setValue(`triggers.${index}.type`, o.key);
                        }
                      }}
                    >
                      <Text mr={4}>{o.text}</Text>
                      {requiresUpgrade && o.image}
                    </MenuItem>
                  </CTooltip>
                );
              })}
            </MenuList>
          </Menu>
          <UpgradeProductModal
            onClose={onClose}
            isOpen={isOpen}
            headerText={getHeaderText(dataProductCatalog, "Plan")}
            titleText={billingModalText.autoscaling.title}
            bodyText={billingModalText.autoscaling.body}
            shouldGoBack={false}
            primaryButtonLink={`/${currentTeamUser?.login}/account/billing/upgrade`}
            primaryButtonText="Compare Plans"
          />
        </>
        <IconButton
          flexShrink={1}
          aria-label="delete"
          icon={<DeleteIcon />}
          borderRadius="md"
          onClick={() => remove(index)}
        />
      </Flex>

      <Controller
        control={control}
        name={`triggers.${index}.spec`}
        defaultValue={trigger.spec}
        render={({ field: { onChange, value } }) => {
          if (trigger.type == AutoscalingType.Cpu) {
            return (
              <Flex flexDirection="row" alignItems="center" width="100%">
                <Text display="inline" mr={4}>
                  Target CPU Utilization (% of Total)
                </Text>
                <Input
                  flexGrow={1}
                  type="number"
                  defaultValue={initialSpec?.["metadata"]?.["value"]}
                  onChange={(e) => {
                    if (e.target.value) {
                      onChange(`type: cpu
metadata:
  type: Utilization
  value: "${e.target.value}"
  containerName: "container"`);
                    }
                  }}
                  placeholder="(e.g.) 70"
                  className="nice-digits"
                />
              </Flex>
            );
          } else if (trigger.type === AutoscalingType.Memory) {
            return (
              <Flex flexDirection="row" alignItems="center" width="100%">
                <Text display="inline" mr={4}>
                  Target Memory Utilization (% of Total)
                </Text>
                <Input
                  flexGrow={1}
                  type="number"
                  defaultValue={initialSpec?.["metadata"]?.["value"]}
                  onChange={(e) => {
                    if (e.target.value) {
                      onChange(`type: memory
metadata:
  type: Utilization
  value: "${e.target.value}"
  containerName: "container"`);
                    }
                  }}
                  placeholder="(e.g.) 70"
                  className="nice-digits"
                />
              </Flex>
            );
          } else if (trigger.type === AutoscalingType.Prometheus) {
            return (
              <PromTrigger onChange={onChange} initialValue={initialSpec} />
            );
          }
          return (
            <Flex flexDirection="row" alignItems="flex-start" width="100%">
              <Text display="inline" mr={4}>
                Spec
              </Text>
              <Textarea
                defaultValue={value}
                minHeight="10rem"
                fontFamily={"mono"}
                placeholder={`type: cron
metadata:
  timezone: America/Los_Angeles
  start: 0 8 * * *               # 8 am every day
  end: 0 10 * * *                # 10 am every day
  desiredReplicas: "3"`}
                onChange={(v) => {
                  if (v.target.value) {
                    onChange(v.target.value);
                  }
                }}
              />
            </Flex>
          );
        }}
      />
    </Stack>
  );
};
const PromTrigger = ({ onChange, initialValue }) => {
  const [query, setQuery] = useState(initialValue?.["metadata"]?.["query"]);
  const [value, setValue] = useState(initialValue?.["metadata"]?.["threshold"]);

  useEffect(() => {
    if (query) {
      onChange(`type: prometheus
metadata:
  serverAddress: http://prometheus-server.prometheus.svc.cluster.local
  metricName: metric
  query: ${query}
  threshold: '${value}'`);
    }
  }, [onChange, query, value]);

  return (
    <Stack width="100%">
      <Flex flexDirection="row" alignItems="center" width="100%">
        <Text display="inline" mr={4} flex={1}>
          Prometheus Query
        </Text>
        <Input
          flex={3}
          value={query}
          fontFamily="monospace"
          placeholder={`(e.g.) sum(rate(http_requests_total{deployment="\${KUBE_DEPLOYMENT_NAME}"}[2m])`}
          onChange={(e) => setQuery(e.target.value)}
        />
      </Flex>
      <Flex flexDirection="row" alignItems="center" width="100%">
        <Text display="inline" mr={4} flex={1}>
          Target Value (Integer)
        </Text>
        <Input
          flex={3}
          type="number"
          value={value}
          onChange={(e) => setValue(Math.round(parseFloat(e.target.value)))}
          placeholder="(e.g.) 50"
          className="nice-digits"
        />
      </Flex>
    </Stack>
  );
};
