import {
  Button,
  Code,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  ProjectContextFragment,
  ProjectPermissionsDocument,
  ProjectPermissionsQuery,
  useConfigureUserProjectPermissionsMutation,
} from "@zeet/web-api/dist/graphql";
import { AnalyticsEvent, Select, useTrack, ZError } from "@zeet/web-ui";
import { useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";

type AvailableRolesQuery = NonNullable<
  NonNullable<ProjectPermissionsQuery["project"]>["permissions"]
>["availableRoles"];

type ProjectPermissionsUser = NonNullable<
  NonNullable<
    NonNullable<ProjectPermissionsQuery["project"]>["permissions"]
  >["users"]
>[0];

export const ProjectRoleUpdateMenu: React.FC<{
  project: ProjectContextFragment;
  user: ProjectPermissionsUser;
  availableRoles: AvailableRolesQuery;
}> = ({ project, user, availableRoles }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Flex>
      <Button
        width="100%"
        height="24px"
        onClick={onOpen}
        bgColor={"inherit"}
        _hover={{ bgColor: "inherit" }}
      >
        <Flex justifyContent="center">
          <BsThreeDotsVertical />
        </Flex>
      </Button>
      <UpdateDialog
        isOpen={isOpen}
        onClose={onClose}
        project={project}
        user={user}
        availableRoles={availableRoles}
      />
    </Flex>
  );
};

const UpdateDialog: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  project: ProjectContextFragment;
  user: ProjectPermissionsUser;
  availableRoles: AvailableRolesQuery;
}> = ({ isOpen, onClose, user, project, availableRoles }) => {
  const toast = useToast();
  const { track } = useTrack();

  const [updatePermission, { loading, error }] =
    useConfigureUserProjectPermissionsMutation({
      onCompleted: (data) => {
        if (data) {
          toast({
            title: "Group Role Updated",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          onClose();
        }
      },
      refetchQueries: [
        {
          query: ProjectPermissionsDocument,
          variables: { id: project.id },
        },
      ],
      awaitRefetchQueries: true,
    });

  const [selectedRole, setSelectedRole] = useState<string | null>(
    user.projectRole?.name || null
  );

  if (!user.user?.id) {
    return <ZError error={"Invalid user, please contact Zeet support."} />;
  }

  const onSubmit = () => {
    if (selectedRole) {
      // the "-" string is the select value used to indicate "remove project specific permission"
      const newRole = selectedRole == "-" ? null : selectedRole;
      track(AnalyticsEvent.GROUP_PERMISSION_UPDATE, {
        groupId: project.id,
        teamMemberGroupRoleName: newRole,
      });
      updatePermission({
        variables: {
          permission: {
            userIds: [user.user?.id || ""],
            teamMemberProjectRoleName: newRole,
          },
          projectID: project.id,
        },
      }).finally(() => {
        setSelectedRole(null);
      });
    }
  };

  const options = [
    {
      label: "Unassign Group Permission",
      value: "-",
      isDisabled: user.projectRole === null,
    },
    ...availableRoles.map((role) => {
      return {
        label: role.name,
        value: role.name,
      };
    }),
  ].map((opt) => ({
    isDisabled: opt.value === user.projectRole?.name,
    ...opt,
  }));

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton data-testid="confirmation-modal-close-button" />
        <ModalHeader>
          <Text>Assign Group Permission</Text>
        </ModalHeader>
        <ModalBody>
          <Stack>
            <Text fontWeight={"bold"}>{user.user?.login}</Text>
            <Text>
              Configure role for group: <Code>{project.name}</Code>
            </Text>
            <Flex>
              <Select
                width="100%"
                options={options}
                value={selectedRole as string}
                onChange={(e) => setSelectedRole(e?.value ?? null)}
              ></Select>
            </Flex>
          </Stack>
          <ZError error={error} />
          <Flex mt="24px" justifyContent="end">
            <Button
              colorScheme="brand"
              isLoading={loading}
              color="black"
              data-testid="confirmation-modal-confirm-button"
              onClick={onSubmit}
            >
              Save
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
