import { Flex, FlexProps } from "@chakra-ui/react";
import { Fragment } from "react";

interface ResourceLineProps {
  leftChildren: React.ReactNode[];
}

export const ResourceLine: React.FC<ResourceLineProps & FlexProps> = ({
  leftChildren,
  children,
  ...props
}) => {
  return (
    <Flex padding="3" direction="row" {...props}>
      <Flex>
        {leftChildren.map((leftChild, i) => (
          <Fragment key={i}>{leftChild}</Fragment>
        ))}
      </Flex>
      <Flex flex="1" justifyContent="flex-end">
        {children}
      </Flex>
    </Flex>
  );
};
