import { Button, Text, useBoolean, useColorModeValue } from "@chakra-ui/react";
import {
  config,
  Link,
  useAuthActions,
  useColors,
  useTrack,
  ZWarn,
} from "@zeet/web-ui";
import React, { createRef, useCallback, useEffect, useState } from "react";
import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from "react-google-login";
import { FcGoogle } from "react-icons/fc";

const chromeThirdPartyCookiesInstructionUrl =
  "https://blog.google/products/chrome/privacy-sandbox-tracking-protection/";

interface GoogleButtonProps extends React.ComponentPropsWithoutRef<"button"> {
  showThirdPartyCookieWarning: boolean;
  isDisabled?: boolean;
  onClick: () => void;
}

const GoogleButton: React.FC<GoogleButtonProps> = ({
  showThirdPartyCookieWarning,
  isDisabled,
  onClick,
  children,
  ...props
}) => {
  const color = useColorModeValue("blackAlpha", "whiteAlpha");
  const { bg } = useColors();
  const ref = createRef<HTMLButtonElement>();

  const [clicked, setHasClicked] = useState(false);

  useEffect(() => {
    if (isDisabled || !clicked) return;

    onClick();
  }, [clicked, isDisabled, onClick]);

  return (
    <>
      <Button
        ref={ref}
        size="lg"
        colorScheme={color}
        bg={`${color}.900`}
        color={`${bg} !important`}
        variant="solid"
        leftIcon={<FcGoogle />}
        iconSpacing="4"
        isLoading={clicked && isDisabled}
        onClick={() => setHasClicked(true)}
        {...props}
      >
        {children}
      </Button>
      {showThirdPartyCookieWarning && (
        <ZWarn>
          <Text>
            Google login may be prevented when third-party cookies are disabled.
            To learn how to enable third-party cookies on Chrome, click&nbsp;
            <Link to={chromeThirdPartyCookiesInstructionUrl} color="brand.500">
              here
            </Link>
            .
          </Text>
        </ZWarn>
      )}
    </>
  );
};

export const GoogleLoginButton: React.FC<{
  buttonText?: string;
}> = ({ buttonText: buttonTextOverride }) => {
  const buttonText = buttonTextOverride || "Continue with Google";
  const { loginGoogle } = useAuthActions();

  const { track } = useTrack();

  const [showThirdPartyCookieWarning, { on: setCookieWarningOn }] =
    useBoolean(false);

  const responseGoogle = useCallback(
    (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
      if (!("getAuthResponse" in response)) {
        track("invalid_google_login");
        throw new Error("invalid google login");
      }
      const idToken = response.getAuthResponse().id_token;
      loginGoogle(idToken);
    },
    [loginGoogle, track]
  );

  const failureGoogle = useCallback(
    (error: any) => {
      track("failed_google_login", {
        error: error,
      });
    },
    [track]
  );

  return (
    <GoogleLogin
      clientId={config.GOOGLE_OAUTH_CLIENT_ID}
      buttonText={buttonText}
      onSuccess={responseGoogle}
      onFailure={failureGoogle}
      onScriptLoadFailure={failureGoogle}
      onRequest={() => {
        // when the cookie is successfully set, the page will redirect to the dashboard
        // if the cookie cannot be set, it's usually because third party cookies are blocked, so we show a popover with instructions
        setTimeout(() => {
          setCookieWarningOn();
        }, 1500);
      }}
      render={({ disabled, onClick }) => (
        <GoogleButton
          showThirdPartyCookieWarning={showThirdPartyCookieWarning}
          isDisabled={disabled}
          onClick={() => {
            track("click_google_signup");
            onClick();
          }}
        >
          {buttonText}
        </GoogleButton>
      )}
      cookiePolicy={"single_host_origin"}
    />
  );
};
