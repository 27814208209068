import { ApolloError } from "@apollo/client";
import { DeleteIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertIcon,
  Flex,
  Heading,
  Icon,
  IconButton,
  Input,
  InputGroup,
  Stack,
  Textarea,
  TextareaProps,
  Tooltip,
} from "@chakra-ui/react";
import {
  BuildType,
  EnvVarDetailFragment,
  RepoDetailFragment,
} from "@zeet/web-api/dist/graphql";
import { Button, Link, Tip, useTrack } from "@zeet/web-ui";
import React, { Fragment, useState } from "react";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { FormCard } from "../../FormCard";
import { useEnvVarSettingsContext } from "./EnvVarSettingsProvider";
import "./Settings.scss";

const CoolInputArea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  (props, ref) => {
    const [focus, setFocus] = useState(false);
    const [hover, setHover] = useState(false);
    return (
      <Textarea
        ref={ref}
        className={
          (focus || hover) && !props.isDisabled ? "" : "textarea-password"
        }
        rows={1}
        onFocus={() => {
          setFocus(true);
        }}
        onBlur={() => {
          setFocus(false);
        }}
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
        {...props}
      />
    );
  }
);

export const EnvVarSettings: React.FC<{
  onSubmit: () => void;
  error: ApolloError | undefined;
  loading: boolean;
  actionButtons: JSX.Element;
  inherited?: {
    name: string;
    path: string;
    type: string;
    vars?: EnvVarDetailFragment[] | null;
  }[];
  repo?: RepoDetailFragment;
}> = ({ onSubmit, error, loading, actionButtons, inherited, repo }) => {
  const { track } = useTrack();
  const [{ envs, changed }, { dispatch, parseEnv }] =
    useEnvVarSettingsContext();

  const handlePaste = (
    e: React.ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const text = e.clipboardData.getData("text/plain");
    if (text.includes("=")) {
      try {
        e.preventDefault();
        parseEnv(text);
      } catch {
        // ignore
      }
    }
  };

  return (
    <FormCard
      isDisabled={!changed}
      isLoading={loading}
      title="Environment Variables"
      buttonTestId="save-env-vars"
      description={
        <>
          Environment variables are accessible at runtime <strong>and</strong>{" "}
          at build time! Use this to securely pass secrets or other runtime
          configuration.
        </>
      }
      formHint={actionButtons}
      onSubmit={(e) => {
        e.stopPropagation();
        e.preventDefault();
        track("click_project_settings_env_save");
        onSubmit();
      }}
    >
      {repo?.buildMethod?.type === BuildType.Docker && (
        <Tip id="dockerfile-envs" mt="3" dismissible={false}>
          In order to inject these envs into your project, you'll need to{" "}
          <Link
            to="https://docs.zeet.co/configuration/build-methods/#dockerfile"
            textDecoration="underline"
          >
            explicitly declare them in your Dockerfile
          </Link>
        </Tip>
      )}
      <Stack spacing={3}>
        {envs.map((env, i) => {
          const isDisabled = !env.visible;
          return (
            <Stack isInline spacing={2} key={env.id}>
              <Input
                placeholder="ENV_NAME"
                value={env.name}
                onPaste={handlePaste}
                onChange={(e) =>
                  dispatch({
                    type: "change",
                    index: i,
                    key: "name",
                    value: e.target.value,
                  })
                }
              />
              <InputGroup w="100%">
                <CoolInputArea
                  placeholder="value"
                  value={env.value}
                  isDisabled={isDisabled}
                  data-testid={`env-value-${i}`}
                  onChange={(e) =>
                    dispatch({
                      type: "change",
                      index: i,
                      key: "value",
                      value: e.target.value,
                    })
                  }
                />
              </InputGroup>
              <Tooltip
                label={
                  env.sealed
                    ? "Reveal this hidden variable to assign a new value"
                    : "Hide this variable. Hidden variables can be overridden with new values, but they will not be visible in settings"
                }
              >
                <IconButton
                  aria-label="delete"
                  icon={
                    env.sealed ? (
                      <Icon as={IoMdEyeOff} />
                    ) : (
                      <Icon as={IoMdEye} />
                    )
                  }
                  colorScheme={env.sealed ? "brand" : "gray"}
                  borderRadius="md"
                  onClick={() => dispatch({ type: "seal", index: i })}
                />
              </Tooltip>
              <IconButton
                aria-label="delete"
                icon={<DeleteIcon />}
                borderRadius="md"
                onClick={() => dispatch({ type: "delete", index: i })}
              />
            </Stack>
          );
        })}
        {error && (
          <Alert status="error">
            <AlertIcon />
            {error?.message}
          </Alert>
        )}
        <Flex justifyContent="space-between" mt={2}>
          <Button
            size="sm"
            colorScheme="brand"
            onClick={() => {
              track("click_project_settings_env_add");
              dispatch({ type: "add" });
            }}
          >
            + Add More
          </Button>
        </Flex>
      </Stack>
      {inherited && inherited.some((i) => !!i.vars?.length) && (
        <Stack mt={6}>
          <Heading size="sm" mb={0}>
            Inherited environment variables
          </Heading>
          {inherited
            .filter((e) => e.vars && e.vars.length > 0)
            .map((e) => (
              <Fragment key={e.type}>
                <Flex alignItems="center" justifyContent="space-between">
                  <Heading size="xs">
                    From {e.type} {e.name}
                  </Heading>
                  <Button variant="ghost" asLink to={e.path}>
                    Configure
                  </Button>
                </Flex>
                {e.vars?.map((env) => (
                  <Stack direction="row" spacing={2} key={env.id}>
                    <Input placeholder="ENV_NAME" value={env.name} />
                    <InputGroup w="100%">
                      <CoolInputArea
                        placeholder="value"
                        value={env.value}
                        isReadOnly={true}
                      />
                    </InputGroup>
                    <IconButton
                      aria-label="sealed"
                      disabled={true}
                      icon={
                        env.sealed ? (
                          <Icon as={IoMdEyeOff} />
                        ) : (
                          <Icon as={IoMdEye} />
                        )
                      }
                      colorScheme={env.sealed ? "brand" : "gray"}
                      borderRadius="md"
                    />
                  </Stack>
                ))}
              </Fragment>
            ))}
        </Stack>
      )}
    </FormCard>
  );
};
