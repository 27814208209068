import { DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {
  TeamMemberRole,
  UserBillingOverviewDocument,
  useRemoveTeamMemberMutation,
  UserTeamMemberQuery,
} from "@zeet/web-api/dist/graphql";
import { DeleteDialog, useCurrentTeam } from "@zeet/web-ui";
import { FC } from "react";
import ReactDOM from "react-dom";
import { BsThreeDotsVertical } from "react-icons/bs";

interface UserActionProps {
  member: NonNullable<UserTeamMemberQuery["currentUser"]["team"]>["members"][0];
  refetchTeamMembers: () => void;
  userIsOwnerOrAdmin: boolean;
}

export const UserAction: FC<UserActionProps> = ({
  member,
  refetchTeamMembers,
  userIsOwnerOrAdmin,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const team = useCurrentTeam();

  const [removeTeamMember, { loading }] = useRemoveTeamMemberMutation({
    refetchQueries: [
      {
        query: UserBillingOverviewDocument,
        variables: { id: team?.user?.id },
      },
    ],
    errorPolicy: "none",
  });

  return (
    <Flex>
      <Menu>
        <MenuButton width="100%" height="24px">
          <Flex justifyContent="center">
            <BsThreeDotsVertical />
          </Flex>
        </MenuButton>
        {ReactDOM.createPortal(
          <MenuList>
            <MenuItem
              onClick={onOpen}
              isDisabled={
                !userIsOwnerOrAdmin || member.role === TeamMemberRole.Owner
              }
            >
              <Flex>
                <DeleteIcon color="red" />
                <Text color="red" ml="6px">
                  Delete
                </Text>
              </Flex>
            </MenuItem>
          </MenuList>,
          document.body
        )}
      </Menu>
      <DeleteDialog
        confirmationString={member.user.login ?? ""}
        onClose={onClose}
        onDelete={() =>
          removeTeamMember({
            variables: {
              input: { id: team?.id, userID: member.user.id },
            },
          })
        }
        onSuccess={() => refetchTeamMembers()}
        isLoading={loading}
        isOpen={isOpen}
        type="user"
        warningMessage={
          <Box>
            This action cannot be undone. This will remove{" "}
            <Text as="b">{member.user.name}</Text> from your team.
          </Box>
        }
      />
    </Flex>
  );
};
