import { Box, Link as CLink, Flex, IconButton, Text } from "@chakra-ui/react";
import { Container, ThemeSwitcher, useTrack } from "@zeet/web-ui";
import React from "react";
import { FaLinkedin, FaTwitter } from "react-icons/fa";
import config from "~/utils/config";

const Footer: React.FC = () => {
  const { track } = useTrack();

  return (
    <Flex
      as="footer"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Container>
        <Flex
          align="center"
          justify="space-between"
          fontSize="sm"
          height="4rem"
          mt={10}
          w="100%"
        >
          <Text opacity={0.6}>Zeet © {new Date().getFullYear()}</Text>
          <Box>
            <IconButton
              isExternal
              as={CLink}
              opacity={0.6}
              filter="grayscale(1)"
              _hover={{ opacity: 1, filter: "grayscale(0)" }}
              icon={<FaLinkedin color="#7289da" size="1.5rem" />}
              aria-label="zeet linkedin"
              href={config.linkedinURL}
              onClick={() => track("click_footer_discord")}
              variant="ghost"
              size="md"
            />{" "}
            <span style={{ opacity: 0.3 }}>|</span>{" "}
            <IconButton
              isExternal
              as={CLink}
              opacity={0.6}
              filter="grayscale(1)"
              _hover={{ opacity: 1, filter: "grayscale(0)" }}
              icon={<FaTwitter color="rgb(29, 161, 242)" size="1.5rem" />}
              aria-label="zeet discord"
              href={config.twitterUrl}
              onClick={() => track("click_footer_twitter")}
              variant="ghost"
              size="md"
            />
          </Box>
          <Box opacity={0.6}>
            <ThemeSwitcher />
          </Box>
        </Flex>
      </Container>
    </Flex>
  );
};

export default Footer;
