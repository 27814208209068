import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  AspectRatio,
  Button,
  Card,
  Flex,
  useDisclosure,
} from "@chakra-ui/react";
import {
  CombinedDeploymentMetricsUpdatedSubscription,
  RepoDetailFragment,
  RepoPrometheusQuery,
  useCombinedDeploymentMetricsUpdatedSubscription,
  useRepoPrometheusQuery,
} from "@zeet/web-api/dist/graphql";
import { ECOption, useCurrentTeamUser, ZChart } from "@zeet/web-ui";
import { useEffect, useMemo, useState } from "react";
import { EmptyState } from "~/components/ListViewV2";
import { getEmptyMetricsArray } from "~/components/utils/array";
import { MetricsSkeleton } from "../MetricsSkeleton";
import { PrometheusModal } from "../PrometheusModal";
import { baseOptions } from "./index";

const KubernetesMetrics: React.FC<{
  repo: RepoDetailFragment;
  deploymentID: string;
  onOpenCPU: () => void;
  onOpenMemory: () => void;
  prometheusData: RepoPrometheusQuery | undefined;
}> = ({ repo, deploymentID, onOpenCPU, onOpenMemory, prometheusData }) => {
  const showPrometheusButton =
    ((repo?.cluster?.awsAccount ||
      repo?.cluster?.gcpAccount ||
      repo?.cluster?.doAccount ||
      repo?.cluster?.linodeAccount) &&
      prometheusData?.user.cluster?.prometheus?.user &&
      prometheusData.user.cluster?.prometheus?.password &&
      prometheusData.user.cluster.prometheus.url) ||
    (repo.cluster?.cwAccount && prometheusData?.user?.cluster?.prometheus?.url);
  const combinedDeploymentId =
    deploymentID || repo.productionDeployment?.id || "";

  const {
    data: rawData,
    error,
    loading,
  } = useCombinedDeploymentMetricsUpdatedSubscription({
    variables: { deploymentID: combinedDeploymentId },
    skip: !combinedDeploymentId,
  });
  const [data, setData] = useState<
    CombinedDeploymentMetricsUpdatedSubscription | undefined
  >();
  useEffect(() => {
    if (
      !error?.graphQLErrors.find((gqlE) =>
        gqlE.path?.some((p) =>
          [
            "networkMetrics",
            "diskMetrics",
            "cpuMetrics",
            "memoryMetrics",
          ].includes(p as string)
        )
      )
    ) {
      setData(rawData);
    }
  }, [rawData, setData, error]);

  const cpuOptions: ECOption = useMemo(() => {
    return {
      ...baseOptions,
      title: {
        text: "CPU Usage",
      },
      series: [
        {
          name: "CPU",
          smooth: false,
          symbol: "none",
          areaStyle: {},
          data:
            data?.deploymentUpdated.cpuMetrics &&
            data.deploymentUpdated.cpuMetrics.length
              ? data.deploymentUpdated.cpuMetrics.map((d) => [
                  new Date(d.timestamp),
                  ((d.value || 0) * 100).toFixed(2),
                ])
              : getEmptyMetricsArray(),
        },
      ],
      yAxis: [
        {
          type: "value",
          boundaryGap: [0, "33%"],
          axisLabel: {
            formatter: "{value}%",
          },
          name: "CPU",
        },
      ],
    };
  }, [data]);

  const memOptions: ECOption = useMemo(() => {
    return {
      ...baseOptions,
      title: {
        text: "Memory Usage",
      },
      series: [
        {
          name: "Memory",
          smooth: false,
          symbol: "none",
          areaStyle: {},
          data:
            data?.deploymentUpdated.memoryMetrics &&
            data.deploymentUpdated.memoryMetrics.length
              ? data?.deploymentUpdated.memoryMetrics?.map((d) => [
                  new Date(d.timestamp),
                  ((d.value || 0) / 1024 / 1024).toFixed(2),
                ])
              : getEmptyMetricsArray(),
        },
      ],
      yAxis: [
        {
          type: "value",
          boundaryGap: [0, "33%"],
          axisLabel: {
            formatter: "{value} MB",
          },
          name: "Memory",
        },
      ],
    };
  }, [data]);

  const networkOptions: ECOption = useMemo(() => {
    return {
      ...baseOptions,
      title: {
        text: "Network Egress",
      },
      series: [
        {
          name: "Network",
          smooth: false,
          symbol: "none",
          areaStyle: {},
          data:
            data?.deploymentUpdated.networkMetrics &&
            data.deploymentUpdated.networkMetrics.length
              ? data.deploymentUpdated.networkMetrics.map((d) => [
                  new Date(d.timestamp),
                  ((d.value || 0) / 1024 / 1024).toFixed(2),
                ])
              : getEmptyMetricsArray(),
        },
      ],
      yAxis: [
        {
          type: "value",
          boundaryGap: [0, "33%"],
          axisLabel: {
            formatter: "{value} MB/s",
          },
          name: "Network",
        },
      ],
    };
  }, [data]);

  const diskOptions: ECOption = useMemo(() => {
    return {
      ...baseOptions,
      title: {
        text: "Disk Usage",
      },
      series: [
        {
          name: "Disk",
          smooth: false,
          symbol: "none",
          areaStyle: {},
          data:
            data?.deploymentUpdated.diskMetrics &&
            data.deploymentUpdated.diskMetrics.length
              ? data.deploymentUpdated.diskMetrics.map((d) => [
                  new Date(d.timestamp),
                  ((d.value || 0) / 1024 / 1024).toFixed(2),
                ])
              : getEmptyMetricsArray(),
        },
      ],
      yAxis: [
        {
          type: "value",
          boundaryGap: [0, "33%"],
          axisLabel: {
            formatter: "{value} GB",
          },
          name: "Disk",
        },
      ],
    };
  }, [data]);

  if (loading) {
    return <MetricsSkeleton />;
  }

  if (!rawData) {
    return <EmptyState heading="No metrics available for this project" />;
  }

  return (
    <Card p={2} mt={4} color="brandVar">
      {showPrometheusButton && (
        <Flex width="100%" mb={2}>
          <Button
            size="sm"
            ml="auto"
            _hover={{ textDecoration: "none" }}
            onClick={() => onOpenCPU()}
            rightIcon={<ExternalLinkIcon />}
          >
            View in Prometheus
          </Button>
        </Flex>
      )}
      <AspectRatio minHeight="300px" width="100%" ratio={16 / 9}>
        <ZChart options={cpuOptions} />
      </AspectRatio>
      {showPrometheusButton && (
        <Flex width="100%" my={2}>
          <Button
            size="sm"
            ml="auto"
            _hover={{ textDecoration: "none" }}
            onClick={() => onOpenMemory()}
            rightIcon={<ExternalLinkIcon />}
          >
            View in Prometheus
          </Button>
        </Flex>
      )}
      <AspectRatio minHeight="300px" width="100%" ratio={16 / 9}>
        <ZChart options={memOptions} />
      </AspectRatio>
      <AspectRatio minHeight="300px" width="100%" ratio={16 / 9}>
        <ZChart options={networkOptions} />
      </AspectRatio>
      <AspectRatio minHeight="300px" width="100%" ratio={16 / 9}>
        <ZChart options={diskOptions} />
      </AspectRatio>
    </Card>
  );
};

/**
 * Remove after Flag.MetricsTabV2 is launched
 * @deprecated use KubernetesMetricsWrapper instead
 */
export const KubernetesMetricsWrapperLegacy: React.FC<{
  repo: RepoDetailFragment;
  deploymentID: string;
}> = ({ repo, deploymentID }) => {
  const {
    onOpen: onOpenCPU,
    onClose: onCloseCPU,
    isOpen: isOpenCPU,
  } = useDisclosure();
  const {
    onOpen: onOpenMemory,
    onClose: onCloseMemory,
    isOpen: isOpenMemory,
  } = useDisclosure();

  const currentTeamUser = useCurrentTeamUser();

  const { data: prometheusData } = useRepoPrometheusQuery({
    variables: {
      clusterID: repo.cluster?.id ?? "",
      userID: currentTeamUser.id,
      repoID: repo.id,
    },
    skip: !repo.cluster?.id,
  });

  return (
    <>
      <PrometheusModal
        onClose={onCloseCPU}
        isOpen={isOpenCPU}
        repo={repo}
        queryType="cpu"
        prometheusData={prometheusData}
      />
      <PrometheusModal
        onClose={onCloseMemory}
        isOpen={isOpenMemory}
        repo={repo}
        queryType="memory"
        prometheusData={prometheusData}
      />
      <KubernetesMetrics
        repo={repo}
        deploymentID={deploymentID}
        onOpenCPU={onOpenCPU}
        onOpenMemory={onOpenMemory}
        prometheusData={prometheusData}
      />
    </>
  );
};
