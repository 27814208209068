import { Text, TextProps } from "@chakra-ui/react";
import { theme, useColors } from "@zeet/web-ui";

interface StatusTextProps extends TextProps {
  color?: theme.StatusColor;
  text?: string;
}

export const StatusText = ({ color, text, ...rest }: StatusTextProps) => {
  const { status } = useColors();
  return (
    <Text
      fontWeight="600"
      color={status[color ?? "neutral"].text}
      fontSize="1.2em"
      {...rest}
    >
      {text}
    </Text>
  );
};
