import {
  DeploymentStatus,
  ProjectDeployListFragment,
} from "@zeet/web-api/dist/graphqlv1";
import { DataTypes, IDataRow, IZeetData } from "@zeet/web-ui";
import { Column } from "react-table";

type ColumnsType = Column<IDataRow>[];

enum ColumnIds {
  DEPLOY_DISPLAY_NAME = "projectDeployDisplayName",
  DEPLOYMENT_STATUS = "projectDeployStatus",
  DEPLOYMENT_COUNT = "projectDeployCount",
  LAST_DEPLOYMENT_AT = "projectLastDeploymentAt",
}

const columns: ColumnsType = [
  {
    id: ColumnIds.DEPLOY_DISPLAY_NAME,
    label: "Name",
    minWidth: 400,
    dataType: DataTypes.LINK,
    options: [],
    order: 1,
  },
  {
    id: ColumnIds.DEPLOYMENT_STATUS,
    label: "Status",
    minWidth: 150,
    dataType: DataTypes.PROJECT_DEPLOYMENT_STATUS,
    options: [],
    order: 2,
  },
  {
    id: ColumnIds.LAST_DEPLOYMENT_AT,
    label: "Last deployment",
    minWidth: 200,
    dataType: DataTypes.TIMEAGO,
    options: [],
    order: 3,
  },
  {
    id: ColumnIds.DEPLOYMENT_COUNT,
    label: "# of deploys",
    minWidth: 100,
    dataType: DataTypes.TEXT,
    options: [],
    order: 3,
  },
];

const defaultColumnIds = Object.values(ColumnIds) as ColumnIds[];

function formatProjectDeploysAsDataRow(
  path: string,
  projectDeploys?: ProjectDeployListFragment[]
): IZeetData {
  const formattedData: IDataRow[] =
    projectDeploys?.map((projectDeploys) => {
      return {
        [ColumnIds.DEPLOY_DISPLAY_NAME]: {
          text: projectDeploys.name || "default",
          link: `${path}?deployId=${projectDeploys.id}`,
        },
        [ColumnIds.DEPLOYMENT_STATUS]: {
          text: String(DeploymentStatus.Unknown),
          status: DeploymentStatus.Unknown,
        },
        [ColumnIds.LAST_DEPLOYMENT_AT]: {
          // date: projectDeploys.runs?.nodes?.[0]?.createdAt, TODO: fix this, we need a date from the BE
          date: new Date(),
        },
        [ColumnIds.DEPLOYMENT_COUNT]: {
          text: String(projectDeploys.runs?.totalCount),
        },
      };
    }) ?? [];

  return {
    columns,
    data: formattedData,
    skipReset: false,
  };
}

export { columns, defaultColumnIds, formatProjectDeploysAsDataRow };
