import { Box, BoxProps, Image, ImageProps } from "@chakra-ui/react";
import { ReactComponent as c } from "devicon/icons/c/c-plain.svg";
import { ReactComponent as cpp } from "devicon/icons/cplusplus/cplusplus-plain.svg";
import { ReactComponent as csharp } from "devicon/icons/csharp/csharp-plain.svg";
import { ReactComponent as go } from "devicon/icons/go/go-original.svg";
import { ReactComponent as java } from "devicon/icons/java/java-plain.svg";
import { ReactComponent as js } from "devicon/icons/javascript/javascript-plain.svg";
import { ReactComponent as php } from "devicon/icons/php/php-plain.svg";
import { ReactComponent as ruby } from "devicon/icons/ruby/ruby-plain.svg";
import { ReactComponent as swift } from "devicon/icons/swift/swift-original.svg";
import { ReactComponent as ts } from "devicon/icons/typescript/typescript-plain.svg";
import React from "react";
import { FaCube, FaDocker } from "react-icons/fa";
import fargateSrc from "~/images/aws/fargate.png";
import lambdaSvg from "~/images/aws/lambda.svg";
import CloudFunctionsSrc from "~/images/gcp/functions.png";
import CloudRunSrc from "~/images/gcp/run.png";
import { ReactComponent as py } from "~/images/python.svg";
import { ReactComponent as Rust } from "~/images/rust.svg";
import { ReactComponent as Serverless } from "~/images/serverless/logo.svg";

const fallback: React.FC = () => {
  return <FaCube size="100%" opacity={0.5} />;
};

const docker: React.FC<{ color?: string }> = () => {
  return <FaDocker size="100%" />;
};

const rust: React.FC = (props) => {
  return <Rust fill="#dea584" {...props} />;
};

const Lambda: React.FC = (props) => {
  return <Image src={lambdaSvg} {...props} />;
};

const CloudFunctions: React.FC<ImageProps> = (props) => {
  return <Image src={CloudFunctionsSrc} {...props} />;
};

const CloudRun: React.FC<ImageProps> = (props) => {
  return <Image src={CloudRunSrc} {...props} />;
};

const Fargate: React.FC<ImageProps> = (props) => {
  return <Image src={fargateSrc} {...props} />;
};

// top languages on github
const MAP = {
  javascript: js,
  python: py,
  java: java,
  go: go,
  "c++": cpp,
  ruby: ruby,
  typescript: ts,
  php: php,
  "c#": csharp,
  c: c,
  scala: fallback,
  shell: fallback,
  rust: rust,
  swift: swift,
  kotlin: fallback,
  dart: fallback,
  lambda: Lambda,
  cloudfunctions: CloudFunctions, // cspell:disable-line
  cloudrun: CloudRun, // cspell:disable-line
  docker: docker,
  serverless: Serverless,
  fargate: Fargate,
  gitlab: fallback,
  github: fallback,
};

export const LanguageIcon: React.FC<
  { language?: string | null } & BoxProps
> = ({ language, ...props }) => {
  const Logo = (language && MAP[language.toLowerCase()]) || fallback;

  return (
    <Box {...props}>
      <Logo height="100%" width="100%" />
    </Box>
  );
};
