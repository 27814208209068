import { GitSourceInput } from "@zeet/web-api/dist/graphqlv1";
import { useEffect } from "react";
import { GithubSelectionCustom } from "~/components/RepositorySelect/github";
import { GithubContextProvider } from "~/components/RepositorySelect/githubContext";
import { useGithubOrg } from "~/components/RepositorySelect/useGithubOrg";
import { GithubRepo } from "~/components/RepositorySelect/useGithubRepo";
import { SourceTabPanelProps } from ".";
import { GitBranch } from "../GitBranch";
import { GitWorkingDirectory } from "../GitWorkingDirectory";
import { makeGitHubSource } from "./utils";

const GitHubSourceSelector = ({
  onSourceChange,
  currentSource,
}: SourceTabPanelProps) => {
  const { activeOrg } = useGithubOrg();
  const ref = currentSource?.git?.ref ?? "";
  const path = currentSource?.git?.path ?? "";
  const installationId = parseInt(activeOrg?.id ?? "0");

  useEffect(() => {
    const gitSource: GitSourceInput = {
      ...currentSource?.git,
      repository: currentSource?.git?.repository ?? "",
      integration: {
        githubInstallationId: installationId,
      },
    };
    onSourceChange(makeGitHubSource(gitSource));
  }, [currentSource?.git, installationId, onSourceChange]);

  const onSelect = (repo: GithubRepo) => {
    const gitSource: GitSourceInput = {
      ...currentSource?.git,
      repository: `https://github.com/${repo.owner}/${repo.name}`,
      integration: {
        githubInstallationId: installationId,
      },
    };
    onSourceChange(makeGitHubSource(gitSource));
  };

  const onRefChange = (ref: string) => {
    const gitSource: GitSourceInput = {
      ...currentSource?.git,
      repository: currentSource?.git?.repository ?? "",
      ref,
    };
    onSourceChange(makeGitHubSource(gitSource));
  };

  const onPathChange = (path: string) => {
    const gitSource: GitSourceInput = {
      ...currentSource?.git,
      repository: currentSource?.git?.repository ?? "",
      path,
    };
    onSourceChange(makeGitHubSource(gitSource));
  };

  return (
    <>
      <GithubSelectionCustom
        onSelect={onSelect}
        isLoading={false}
        defaultSelectedRepoUrl={currentSource?.git?.repository}
      />
      <GitWorkingDirectory value={path} onChange={onPathChange} />
      <GitBranch value={ref} onChange={onRefChange} />
    </>
  );
};

export const GitHubSource = (props: SourceTabPanelProps) => {
  return (
    <GithubContextProvider>
      <GitHubSourceSelector {...props} />
    </GithubContextProvider>
  );
};
