import { Box, Heading, useToast } from "@chakra-ui/react";
import {
  RepoDetailFragment,
  RepoSourceType,
  useUpdateResourceAlphaMutation,
} from "@zeet/web-api/dist/graphql";
import { getSourceLink } from "~/components/Dashboard/DashboardRepoItem";
import {
  ContainerSources,
  ServerlessFunctionSources,
  Source,
  SourceKind,
  SourceManager,
} from "~/components/SourceManager";
import { isServerless } from "~/components/utils/project";
import { isSampleProject } from "~/features/Project/New/makeCreateSampleProjectInput";
import { useMutationError } from "~/hooks/useMutationError";

const makeSourceFromRepo = (repo: RepoDetailFragment): Source => {
  const gitSourceTypes = [
    RepoSourceType.Bitbucket,
    RepoSourceType.Github,
    RepoSourceType.GithubPublic,
    RepoSourceType.Gitlab,
  ];

  const containerRegistrySourceTypes = [
    RepoSourceType.Docker,
    RepoSourceType.DockerHub,
  ];

  const repoSourceMap = {
    [RepoSourceType.Bitbucket]: SourceKind.Bitbucket,
    [RepoSourceType.Github]: SourceKind.GitHub,
    [RepoSourceType.GithubPublic]: SourceKind.PublicGitRepo,
    [RepoSourceType.Gitlab]: SourceKind.GitLab,
    [RepoSourceType.Docker]: SourceKind.DockerHub,
    [RepoSourceType.DockerHub]: SourceKind.DockerHub,
  };

  const [initialImage, initialTag] = repo.source.id.split(":");

  return {
    kind: repoSourceMap[repo.source.type],
    git: gitSourceTypes.includes(repo.source.type)
      ? {
          repository: getSourceLink(repo.source),
          ref: repo.productionBranchV2?.gitRef,
          path: repo.buildMethod?.workingDirectory,
          integration: {
            gitlabIntegrationId: repo.gitlabIntegration?.id,
          },
        }
      : undefined,
    containerRegistry: containerRegistrySourceTypes.includes(repo.source.type)
      ? {
          tag: initialTag,
          repository: initialImage ?? "",
        }
      : undefined,
  };
};

export const RepoSource = ({ repo: project }: { repo: RepoDetailFragment }) => {
  const toast = useToast();

  const [updateSettings, { loading }] = useUpdateResourceAlphaMutation({
    onCompleted: (data) => {
      if (data) {
        toast({
          title: "Project source updated successfully!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    },
    ...useMutationError("Failed to update project source"),
  });

  const updateSource = (source: Source) => {
    if (!source.git?.repository && !source.containerRegistry) {
      return toast({
        title: "Please enter a valid source",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    if (source.containerRegistry) {
      updateSettings({
        variables: {
          id: project?.id as string,
          input: {
            source: {
              containerRegistry: {
                registryID: source.containerRegistry.registryId,
                registryURL: source.containerRegistry.registryUrl,
                repository: source.containerRegistry.repository,
                tag: source.containerRegistry.tag,
              },
            },
          },
        },
      });
    } else if (source?.git?.integration?.githubInstallationId) {
      updateSettings({
        variables: {
          id: project?.id as string,
          input: {
            source: {
              git: {
                repository: source.git?.repository,
                ref: source.git?.ref,
                path: source.git?.path,
                integration: {
                  githubInstallationID:
                    source.git?.integration?.githubInstallationId,
                },
              },
            },
          },
        },
      });
    } else if (source?.git?.integration?.gitlabIntegrationId) {
      updateSettings({
        variables: {
          id: project?.id as string,
          input: {
            source: {
              git: {
                repository: source.git?.repository,
                ref: source.git?.ref,
                path: source.git?.path,
                integration: {
                  gitlabIntegrationID:
                    source.git?.integration?.githubIntegrationId,
                },
              },
            },
          },
        },
      });
    } else if (source?.git?.integration?.bitbucketIntegrationId) {
      updateSettings({
        variables: {
          id: project?.id as string,
          input: {
            source: {
              git: {
                repository: source.git?.repository,
                ref: source.git?.ref,
                path: source.git?.path,
                integration: {
                  bitbucketIntegrationID:
                    source.git?.integration?.bitbucketIntegrationId,
                },
              },
            },
          },
        },
      });
    } else if (source?.git?.repository) {
      updateSettings({
        variables: {
          id: project?.id as string,
          input: {
            source: {
              git: {
                repository: source.git?.repository,
                ref: source.git?.ref,
                path: source.git?.path,
              },
            },
          },
        },
      });
    }
  };

  const validSources = isServerless(project)
    ? ServerlessFunctionSources
    : ContainerSources;

  return (
    <Box p={4} shadow="md" borderWidth="1px" borderRadius="md">
      <Heading size="md" mb="3">
        Source
      </Heading>
      <SourceManager
        validSources={validSources}
        source={makeSourceFromRepo(project)}
        onSourceChange={
          !isSampleProject(project.name) ? updateSource : undefined
        }
        isSourceLoading={loading}
      />
    </Box>
  );
};
