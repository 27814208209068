import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";
import { Flex, IconButton, Text } from "@chakra-ui/react";
import { useTrack } from "@zeet/web-ui";
import React, { useMemo } from "react";

interface PaginationControlsProps {
  totalPages: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
}

export const PaginationControls: React.FC<PaginationControlsProps> = ({
  totalPages,
  currentPage,
  setCurrentPage,
}) => {
  const { track } = useTrack();
  const pageButtons = useMemo(() => {
    const elements: number[] = [];
    const maxPagesShown = Math.min(totalPages, 10);
    let firstPageIndex = Math.max(
      Math.floor(currentPage - maxPagesShown / 2),
      0
    );
    const lastIndex = Math.min(firstPageIndex + maxPagesShown, totalPages);
    firstPageIndex -= maxPagesShown - (lastIndex - firstPageIndex);
    for (let i = firstPageIndex; i < lastIndex; i++) {
      elements.push(i);
    }
    return elements;
  }, [totalPages, currentPage]);

  return (
    <Flex w="100%" justifyContent="space-between" mt={2}>
      <IconButton
        aria-label="previous page"
        isDisabled={currentPage === 0}
        size="sm"
        icon={<ArrowLeftIcon />}
        onClick={() => {
          setCurrentPage(currentPage - 1);
          track("pagination_click_down", { totalPages, currentPage });
        }}
        mr="6px"
      />
      {pageButtons.map((index) => (
        <IconButton
          isActive={index === currentPage}
          onClick={() => {
            setCurrentPage(index);
            track("pagination_click_page", { totalPages, currentPage, index });
          }}
          size="sm"
          key={`page_${index}`}
          aria-label={`page ${index + 1}`}
          icon={<Text>{index + 1}</Text>}
          mr="6px"
        />
      ))}
      <IconButton
        aria-label="previous page"
        isDisabled={currentPage === totalPages - 1}
        size="sm"
        icon={<ArrowRightIcon />}
        onClick={() => {
          setCurrentPage(currentPage + 1);
          track("pagination_click_up", { totalPages, currentPage });
        }}
      />
    </Flex>
  );
};
