export type ArrayElement<A> = A extends readonly (infer T)[] ? T : never;

// eslint-disable-next-line
export function stripTypeNames(
  // eslint-disable-next-line
  value
) {
  if (Array.isArray(value)) {
    return value.map(stripTypeNames);
  } else if (value !== null && typeof value === "object") {
    const newObject = {};
    for (const property in value) {
      if (property !== "__typename") {
        newObject[property] = stripTypeNames(value[property]);
      }
    }
    return newObject;
  } else {
    return value;
  }
}

export type KeysOfUnion<T> = T extends T ? keyof T : never;
export type ValuesOfUnion<T> = T extends T ? T[keyof T] : never;

export type ValuesOfMap<T> = T[keyof T];

export type UnionOfParameter<T> = T extends (arg: infer U) => infer _
  ? U
  : never;
