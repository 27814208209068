import { useMemo } from "react";
import { useBillingOverviewContext } from "~/components/Account/BillingV2/Providers/BillingOverviewProvider";
import {
  determineSupportLevel,
  getIsAdvanced,
} from "~/components/Support/utils";

export const useCrispChat = () => {
  const [{ userBillingOverview }] = useBillingOverviewContext();
  const isAdvanced = useMemo(() => {
    return getIsAdvanced(determineSupportLevel(userBillingOverview));
  }, [userBillingOverview]);

  if (isAdvanced) {
    window.$crisp.push(["do", "chat:show"]);
  } else {
    window.$crisp.push(["do", "chat:hide"]);
  }
};
