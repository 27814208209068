import { Switch } from "@chakra-ui/react";
import { FieldValues } from "react-hook-form";
import { InputContainer, InputProps } from "./InputContainer";

type BooleanInputProps<TFormData> = InputProps<TFormData>;

export const BooleanInput = <TFormData extends FieldValues>({
  ...props
}: BooleanInputProps<TFormData>) => {
  return (
    <InputContainer
      {...props}
      render={(p) => (
        <Switch {...p} isChecked={p.value === "true" || p.value === true} />
      )}
    />
  );
};
