import { DeepPartial } from "@chakra-ui/react";
import { RevisableEntity } from "@zeet/web-api/dist/graphqlv1";
import { useMemo } from "react";
import { SearchableListLayout } from "../SearchableList";
import { columns, defaultColumnIds, makeRevisions } from "./utils";

interface RevisionsListProps {
  loading?: boolean;
  revisions?: RevisionListData | null;
  pageChanged?: (page: number) => unknown;
}

export interface RevisionListData {
  nodes: DeepPartial<RevisableEntity>[];
  pageInfo?: {
    hasNextPage?: boolean;
  };
}

export const RevisionsList = ({
  revisions,
  loading,
  pageChanged,
}: RevisionsListProps) => {
  const dataToRender = useMemo(() => makeRevisions(revisions), [revisions]);

  return (
    <SearchableListLayout
      defaultColumnIds={defaultColumnIds}
      data={dataToRender}
      columns={columns}
      id="revisionsList"
      hasNextPage={revisions?.pageInfo?.hasNextPage}
      pageChanged={pageChanged}
      loading={loading}
    />
  );
};
