import { Heading, Text } from "@chakra-ui/react";
import { EmptyBox, useColors } from "@zeet/web-ui";
import { IconType } from "react-icons";

interface StepUnavailableInfoProps {
  icon: IconType;
  title: string;
  subtitle: string;
}

export const StepUnavailableInfo = ({
  icon: Icon,
  title,
  subtitle,
}: StepUnavailableInfoProps) => {
  const { fg2 } = useColors();
  return (
    <EmptyBox flexDirection="column" minHeight={"400px"} mt="1" ml="4">
      <Icon color={fg2} fontSize="96px" />
      <Heading fontSize="1.8em" fontWeight={400} mt={4} mb={2}>
        {title}
      </Heading>
      <Text color={fg2}>{subtitle}</Text>
    </EmptyBox>
  );
};
