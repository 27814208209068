import { Box, BoxProps, Flex, Image, ImageProps } from "@chakra-ui/react";
import React from "react";
import { useColorModeValue } from "../hooks/useColors";
import akamai from "../images/akamai/logo.png";
import awsDark from "../images/aws/logo-dark.png";
import awsLight from "../images/aws/logo.png";
import cwLogo from "../images/coreweave/logo.png";
import datadogLight from "../images/datadog/dd_logo_v_rgb.png";
import datadogDark from "../images/datadog/dd_logo_v_white.png";
import doLogo from "../images/digitalocean/logo.png";
import gcpLogo from "../images/gcp/gcp.png";
import gitlab from "../images/gitlab/logo.png";
import grafana from "../images/grafana.png";
import helmWhite from "../images/k8s/helm-white.png";
import helm from "../images/k8s/helm.svg";
import k8s from "../images/k8s/logo.png";
import LogoWhite from "../images/logo-white.svg";
import LogoBlack from "../images/logo.svg";
import MarkWhite from "../images/mark-white.svg";
import MarkBlack from "../images/mark.svg";
import PodBlack from "../images/pod-black.svg";
import Pod from "../images/pod.svg";
import prometheus from "../images/prometheus.png";

export const getSafeImage = (logo: any) => {
  if (logo?.src) {
    return logo.src;
  }
  return logo;
};

export const HorizontalLogo: React.FC<
  BoxProps & {
    hideWordMark?: boolean;
    logoWidth?: string;
    logoHeight?: string;
    theme?: "dark" | "light";
  }
> = ({ hideWordMark, logoWidth, logoHeight, theme, ...restProps }) => {
  const logo = useColorModeValue(
    <Image
      src={getSafeImage(!theme || theme == "dark" ? LogoBlack : LogoWhite)}
      height={logoHeight ? logoHeight : "50%"}
      width={logoWidth ? logoWidth : "50%"}
    />,
    <Image
      src={getSafeImage(!theme || theme == "light" ? LogoWhite : LogoBlack)}
      height={logoHeight ? logoHeight : "50%"}
      width={logoWidth ? logoWidth : "50%"}
    />
  );

  const mark = useColorModeValue(
    <Image
      src={getSafeImage(!theme || theme == "dark" ? MarkBlack : MarkWhite)}
      height="42px"
      width="42px"
    />,
    <Image
      src={getSafeImage(!theme || theme == "light" ? MarkWhite : MarkBlack)}
      height="42px"
      width="42px"
    />
  );

  return (
    <Flex alignItems="center" {...restProps}>
      {hideWordMark ? mark : <Box width="90%">{logo}</Box>}
    </Flex>
  );
};

export const ZeetLogo: React.FC<{
  logoWidth?: string;
  logoHeight?: string;
}> = ({ logoWidth, logoHeight }) => {
  const logo = useColorModeValue(
    <Image
      src={getSafeImage(MarkBlack)}
      height={logoHeight ? logoHeight : "42px"}
      width={logoWidth ? logoWidth : "42px"}
    />,
    <Image
      src={getSafeImage(MarkWhite)}
      height={logoHeight ? logoHeight : "42px"}
      width={logoWidth ? logoWidth : "42px"}
    />
  );
  return <Box>{logo}</Box>;
};

export const AWSLogo: React.FC<ImageProps> = ({ height, ...props }) => {
  const awsLogo = useColorModeValue(
    <Image
      src={getSafeImage(awsLight)}
      height={`calc(${height} * 1.3)`}
      {...props}
    />,
    <Image src={getSafeImage(awsDark)} pt={"1px"} height={height} {...props} />
  );

  return <>{awsLogo}</>;
};

export const GCPLogo: React.FC<ImageProps> = ({ height, ...props }) => {
  return <Image src={getSafeImage(gcpLogo)} height={height} {...props} />;
};

export const LinodeLogo: React.FC<ImageProps> = ({ height, ...props }) => {
  return <Image src={getSafeImage(akamai)} height={height} {...props} />;
};

export const DigitalOceanLogo: React.FC<ImageProps> = ({
  height,
  ...props
}) => {
  return <Image src={getSafeImage(doLogo)} height={height} {...props} />;
};

export const CoreWeaveLogo: React.FC<ImageProps> = ({ height, ...props }) => {
  return <Image src={getSafeImage(cwLogo)} height={height} {...props} />;
};

export const K8SLogo: React.FC<ImageProps> = ({ ...props }) => {
  return <Image src={getSafeImage(k8s)} {...props} />;
};

export const HelmLogo: React.FC<ImageProps> = ({ ...props }) => {
  return useColorModeValue(
    <Image src={getSafeImage(helm)} {...props} />,
    <Image src={getSafeImage(helmWhite)} {...props} />
  );
};

export const PrometheusLogo: React.FC<ImageProps> = ({ ...props }) => {
  return <Image src={getSafeImage(prometheus)} {...props} />;
};

export const GrafanaLogo: React.FC<ImageProps> = ({ ...props }) => {
  return <Image src={getSafeImage(grafana)} {...props} />;
};

export const DatadogLogo: React.FC<ImageProps> = ({ ...props }) => {
  const logo = useColorModeValue(
    <Image
      src={getSafeImage(datadogLight)}
      color={"#632ac6"}
      borderRadius="2px"
      padding="3px"
      {...props}
    />,
    <Image
      src={getSafeImage(datadogDark)}
      backgroundColor={"#632ac6"}
      padding="3px"
      borderRadius="2px"
      {...props}
    />
  );

  return <>{logo}</>;
};

export const GitlabLogo: React.FC<ImageProps> = ({ ...props }) => {
  return <Image src={getSafeImage(gitlab)} {...props} />;
};

export const PodLogo: React.FC<ImageProps> = ({ ...props }) => {
  const logo = useColorModeValue(
    <Image src={getSafeImage(PodBlack)} {...props} />,
    <Image src={getSafeImage(Pod)} {...props} />
  );

  return <>{logo}</>;
};
