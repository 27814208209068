import { BridgeBlueprintInput } from "@zeet/web-ui";
import {
  ControllerFieldState,
  ControllerRenderProps,
  FieldErrors,
  RegisterOptions,
  UseFormTrigger,
} from "react-hook-form";
import { NewResourceValues } from "../../context";

const makeRegisterOptions = (
  input: BridgeBlueprintInput
): RegisterOptions<NewResourceValues> => {
  return {
    required: input.required ? "This field is required" : false,
  };
};

const makeFieldOptions = ({
  field: { value, onChange, ...restField },
  fieldState: { error },
  trigger,
}: {
  field: ControllerRenderProps<NewResourceValues>;
  fieldState: ControllerFieldState;
  trigger: UseFormTrigger<NewResourceValues>;
}) => {
  return {
    ...restField,
    error,
    value: value as string,
    onChange: (value) => {
      onChange(value);
      if (error) trigger(restField.name);
    },
    onBlur: () => {
      trigger(restField.name);
    },
  };
};

const getError = (
  errors: FieldErrors<NewResourceValues>,
  input: BridgeBlueprintInput
) => {
  if (errors.variables) {
    return errors.variables[input.id]?.message?.toString();
  }
};

export { makeRegisterOptions, makeFieldOptions, getError };
