import { Flex, Image, Text } from "@chakra-ui/react";
import { CloudProvider } from "@zeet/web-api/dist/graphql";
import { AWSLogo, LinodeLogo } from "@zeet/web-ui";
import React from "react";
import { SiMicrosoftazure, SiVultr } from "react-icons/si"; // cspell:disable-line
import cwLogo from "~/images/coreweave/logo.png";
import doLogo from "~/images/digitalocean/logo.png";
import gcpLogo from "~/images/gcp/gcp.png";

export interface CloudIconProps {
  cloudProvider?: CloudProvider | null;
  cloudAccountKey?: string | null;
}

const StyledText = ({ children }: { children?: string | undefined | null }) => (
  <Text ml="6px" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
    {children}
  </Text>
);

const CloudIcon: React.FC<CloudIconProps> = ({
  cloudProvider,
  cloudAccountKey,
}) => {
  if (cloudProvider === CloudProvider.Gcp) {
    return (
      <Flex alignItems="center">
        <Image height="0.9rem" src={gcpLogo} />
        <StyledText>{cloudAccountKey}</StyledText>
      </Flex>
    );
  }

  if (cloudProvider === CloudProvider.Do) {
    return (
      <Flex alignItems="center">
        <Image height="0.9rem" src={doLogo} />
        <StyledText>{cloudAccountKey}</StyledText>
      </Flex>
    );
  }

  if (cloudProvider === CloudProvider.Aws) {
    return (
      <Flex alignItems="center">
        <AWSLogo height="0.9rem" />
        <StyledText>{cloudAccountKey}</StyledText>
      </Flex>
    );
  }

  if (cloudProvider === CloudProvider.Coreweave) {
    return (
      <Flex alignItems="center">
        <Image height="0.9rem" src={cwLogo} />
        <StyledText>{cloudAccountKey}</StyledText>
      </Flex>
    );
  }

  if (cloudProvider === CloudProvider.Linode) {
    return (
      <Flex alignItems="center">
        <LinodeLogo height="0.9rem" />
        <StyledText>{cloudAccountKey}</StyledText>
      </Flex>
    );
  }

  if (cloudProvider === CloudProvider.Vultr) {
    return (
      <Flex alignItems="center">
        <SiVultr />
        <StyledText>{cloudAccountKey}</StyledText>
      </Flex>
    );
  }

  if (cloudProvider === CloudProvider.Azure) {
    return (
      <Flex alignItems="center">
        {/* cspell:disable-next-line */}
        <SiMicrosoftazure style={{ flexShrink: 0 }} />{" "}
        <StyledText>{cloudAccountKey}</StyledText>
      </Flex>
    );
  }

  return <Flex></Flex>;
};

export default CloudIcon;
