import { Text } from "@chakra-ui/react";
import { DetailDataBlock, DetailItem, DetailLabel, Link } from "@zeet/web-ui";
import { useProjectPath } from "../hooks/useProjectPath";

interface WorkflowTriggerDetailItemProps {
  parentRun?: { id: string; sequence: number } | null;
}

export const WorkflowTriggerDetailItem = ({
  parentRun,
}: WorkflowTriggerDetailItemProps) => {
  const path = useProjectPath();
  return (
    <DetailItem>
      <DetailLabel>Triggered by</DetailLabel>
      {parentRun ? (
        <DetailDataBlock
          as={Link}
          to={`${path}/workflows?workflow=${parentRun.id}`}
        >
          Workflow run #{parentRun.sequence}
        </DetailDataBlock>
      ) : (
        <Text>Manually</Text>
      )}
    </DetailItem>
  );
};
