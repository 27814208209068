import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Box, Flex, Text } from "@chakra-ui/react";
import { Link, Skeleton, useColors } from "@zeet/web-ui";
import { FC, ReactNode } from "react";

export interface LinkCellProps {
  as?: string;
  to?: string;
  isExternal?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  children: ReactNode | string;
  icon?: any;
  loading?: boolean;
}

export const LinkCell: FC<LinkCellProps> = ({
  as,
  to,
  isExternal = false,
  onClick = () => {},
  children,
  icon,
  loading,
}) => {
  const { bg } = useColors();
  const withExternalLinkIcon = children && isExternal;

  return loading ? (
    <Skeleton height="12px" width="70%" />
  ) : (
    <Box
      overflow="hidden"
      whiteSpace="nowrap"
      textOverflow="ellipsis"
      {...(withExternalLinkIcon ? { paddingRight: "14px" } : {})}
    >
      <Box position="relative" _hover={{ svg: { display: "block" } }}>
        <Link
          {...(as ? { as } : {})}
          isExternal={isExternal}
          onClick={onClick}
          to={to}
          _hover={{
            textDecoration: "none",
            color: "brandVar",
          }}
          overflow="hidden"
          data-testid="link-cell-button"
        >
          <Flex alignItems="center" gap={2}>
            {icon && icon}
            <Text
              paddingRight="6px"
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
            >
              {children}
            </Text>
          </Flex>
        </Link>

        {withExternalLinkIcon && (
          <ExternalLinkIcon
            fontSize="sm"
            opacity=".8"
            position="absolute"
            right="0"
            top="0"
            transform="translateX(100%) translateY(4px)"
            display="none"
            background={bg}
          />
        )}
      </Box>
    </Box>
  );
};
