import { Flex, Stack, Switch, Text, useToast } from "@chakra-ui/react";
import {
  RepoDetailFragment,
  useUpdateProjectSettingsMutation,
} from "@zeet/web-api/dist/graphql";
import { Loading, Tooltip, ZError } from "@zeet/web-ui";
import React, { useState } from "react";
import { SiDatadog } from "react-icons/si";
import { shouldDisplayError } from "../util";

export const DatadogApmSettings: React.FC<{ repo: RepoDetailFragment }> = ({
  repo,
}) => {
  const toast = useToast();

  const [datadogAPMEnabled, setDatadogAPMEnabled] = useState(
    repo.datadogApmEnabled || false
  );

  const [updateSettings, { error, loading, data }] =
    useUpdateProjectSettingsMutation({
      onCompleted: (data) => {
        if (data) {
          toast({
            title: "Datadog Integration Updated",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      },
    });

  return (
    <Stack spacing={4} mt="">
      <Flex alignItems="center">
        <SiDatadog />
        <Text fontWeight="bold" fontSize="1.2rem" mb={1} ml="6px">
          Datadog Integration
        </Text>
      </Flex>
      <Flex width="100%" justifyContent="space-between">
        <Flex alignItems="center">
          <Text>Expose Datadog Agent Host IP</Text>
          <Tooltip text="This will set DD_AGENT_HOST to the ip of the datadog agent for your Project to send APM data." />
        </Flex>
        <Flex alignItems="center">
          {loading && <Loading mr={4} />}
          <Switch
            size="lg"
            isChecked={datadogAPMEnabled}
            onChange={(e) => {
              setDatadogAPMEnabled(e.target.checked);
              updateSettings({
                variables: {
                  input: {
                    id: repo.id,
                    datadogApmEnabled: e.target.checked,
                  },
                },
              });
            }}
          />
        </Flex>
      </Flex>
      {shouldDisplayError(error, data) && <ZError error={error} />}
    </Stack>
  );
};
