import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Flex,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import {
  DatabaseProviderType,
  DeployTarget,
  DeployType,
  RepoDetailFragment,
  RepoSourceType,
  useRepoOverviewQuery,
  useUserDeploymentQuery,
} from "@zeet/web-api/dist/graphql";
import { useProjectOutputsQuery } from "@zeet/web-api/dist/graphqlv1";
import {
  Button,
  CenterLoading,
  deploymentStatusOverview,
  Flag,
  Link,
  useCurrentTeamUser,
  useFeatureFlag,
  useTrack,
  ZError,
} from "@zeet/web-ui";
import React from "react";
// cspell:disable-line
import { useParams } from "react-router-dom";
import { DatabaseConnectAction } from "~/features/Project/Detail/components/DatabaseConnectAction";
import { RepoResourceTab } from "~/features/Project/Detail/ResourcesTab/RepoResourceTab";
import { isSampleProject } from "../../features/Project/New/makeCreateSampleProjectInput";

import { NavTabType } from "../Layouts/NavTab";
import { TabbedLayout } from "../Layouts/TabbedLayout";
import { makeLink } from "../utils/link";
import { isServerless } from "../utils/project";
import { DeploymentsTab } from "./Deployments";
import { JobsTab } from "./Jobs";
import { LogsTab } from "./Logs";
import { MetricsTab } from "./Metrics";
import ProjectOnboard from "./Onboard";
import { OverviewTab } from "./OverviewTab";
import { useProjectV3Context } from "./ProjectV3Provider";
import { RevisionsTab } from "./Revisions";
import { SettingsTab } from "./Settings";
import { useRepo } from "./useRepo";
import { repoPath } from "./util";
import { SampleProjectBanner } from "./WarningBanner";

const ProjectEnabledBanner: React.FC<{ repo: RepoDetailFragment }> = ({
  repo,
}) => {
  if (!repo.enabled) {
    return (
      <Alert status="warning">
        <Flex align="center">
          <AlertIcon />
          <AlertTitle>
            This Project is currently paused.
            <Text
              as={Link}
              to={`/${repoPath(repo)}/settings/danger`}
              fontWeight={600}
              ml={2}
              textDecoration="underline"
            >
              Unpause and redeploy project
            </Text>
          </AlertTitle>
        </Flex>
      </Alert>
    );
  }
  return null;
};

const Project: React.FC = () => {
  const { tab } = useParams<{
    tab: string;
  }>();

  if (tab === "onboard") {
    return <ProjectOnboard />;
  }

  return <ProjectDash />;
};

const ProjectActions = () => {
  const { repo } = useRepo();
  const { track } = useTrack();
  const currentTeamUser = useCurrentTeamUser();
  const { projectData } = useProjectV3Context();

  const { data: projectOutputsData } = useProjectOutputsQuery({
    variables: {
      teamId: currentTeamUser?.id,
      projectId: projectData?.user.projectV3Adapters?.nodes?.[0]?.id ?? "",
    },
    skip: !projectData?.user.projectV3Adapters?.nodes?.[0]?.id,
    pollInterval: 3000,
  });

  const { data: deploymentData } = useUserDeploymentQuery({
    skip: !repo?.productionDeployment?.id,
    variables: {
      id: repo?.productionDeployment?.id || "",
    },
    errorPolicy: "all",
    pollInterval: 3000,
  });

  if (!repo) return null;

  const endpoint =
    repo?.productionDeployment?.endpoints?.[0] &&
    makeLink(repo?.productionDeployment?.endpoints?.[0]);

  const visitDisabled =
    deploymentStatusOverview(repo, deploymentData?.currentUser.deployment) !==
    "deployed";

  return (
    <Flex gap="2">
      {isServerless(repo) && repo.productionDeployment?.awsLinks?.lambda && (
        <Button
          variant="secondary"
          onClick={() => {
            window.open(repo.productionDeployment?.awsLinks?.lambda as string);
          }}
          rightIcon={<ExternalLinkIcon />}
        >
          AWS Console
        </Button>
      )}
      {isServerless(repo) &&
        repo.productionDeployment?.gcpLinks?.cloudFunctions && (
          <Button
            variant="secondary"
            onClick={() => {
              window.open(
                repo.productionDeployment?.gcpLinks?.cloudFunctions as string
              );
            }}
            rightIcon={<ExternalLinkIcon />}
          >
            GCP Console
          </Button>
        )}
      {isServerless(repo) && repo.productionDeployment?.gcpLinks?.cloudRun && (
        <Button
          variant="secondary"
          onClick={() => {
            window.open(
              repo.productionDeployment?.gcpLinks?.cloudRun as string
            );
          }}
          rightIcon={<ExternalLinkIcon />}
        >
          GCP Console
        </Button>
      )}
      {repo?.databaseProvider === DatabaseProviderType.AwsRds &&
        repo.productionDeployment?.awsLinks?.rds && (
          <Button
            variant="secondary"
            onClick={() => {
              window.open(repo.productionDeployment?.awsLinks?.rds as string);
            }}
            rightIcon={<ExternalLinkIcon />}
          >
            AWS Console
          </Button>
        )}
      <DatabaseConnectAction projectOutputs={projectOutputsData} />
      {!visitDisabled && endpoint && deploymentData?.currentUser.deployment && (
        <Tooltip label={endpoint} placement="auto-end" aria-label="endpoint">
          <Button
            onClick={() => {
              track("click_project_endpoint");
              window.open(endpoint);
            }}
            disabled={visitDisabled}
            rightIcon={<ExternalLinkIcon />}
          >
            Visit Deployment
          </Button>
        </Tooltip>
      )}
    </Flex>
  );
};

const ProjectBanner = () => {
  const { repo } = useRepo();
  const showSampleProjectBanner = isSampleProject(repo?.name);

  if (!repo) {
    return null;
  }

  return (
    <>
      {showSampleProjectBanner && <SampleProjectBanner />}
      <ProjectEnabledBanner repo={repo} />
    </>
  );
};

export const TabWrapper = ({
  component,
}: {
  component: React.FC<{ repo: RepoDetailFragment }>;
}) => {
  const { repo, loading, error } = useRepo();

  if (error && !repo) {
    return <ZError error={error} />;
  }

  if (loading) {
    return <CenterLoading />;
  }

  if (!repo) {
    return <ZError error={new Error("Resource not found")} />;
  }

  const Component = component as React.FC<{ repo: RepoDetailFragment }>;

  return <Component repo={repo} />;
};

const ProjectDash: React.FC = () => {
  const currentTeamUser = useCurrentTeamUser();
  const { repoName, envName, projectName } = useParams<{
    repoName: string;
    envName: string;
    projectName: string;
  }>();

  const { data, loading, error } = useRepoOverviewQuery({
    variables: {
      path: `${currentTeamUser.login}/${projectName}`,
      environment: envName,
      repo: repoName,
    },
  });

  const repo = data?.project?.environment.repo;

  const isContainerJob =
    repo?.deployTarget === DeployTarget.Kubernetes &&
    (!!repo?.jobRuns?.totalCount || !repo?.deployService);

  const isNotContainerApp =
    repo?.source.type === RepoSourceType.Helm ||
    repo?.source.type === RepoSourceType.Terraform ||
    repo?.deployTarget === DeployTarget.Terraform ||
    repo?.deployType === DeployType.Kubectl;

  const isResourcesTabEnabled = useFeatureFlag(
    Flag.ObservabilityResourcesContainer,
    { devEnabled: true }
  );

  if (error && !repo) {
    return <ZError error={error} />;
  }

  if (loading) {
    return <CenterLoading />;
  }

  if (!repo) {
    return <ZError error={new Error("Resource not found")} />;
  }

  const baseUrl = `/${currentTeamUser.login}/${projectName}/${envName}/${repoName}`;

  const tabs: NavTabType[] = [
    {
      label: "Overview",
      routeKey: "overview",
      to: baseUrl,
      component: OverviewTab,
    },
    {
      label: "Jobs",
      routeKey: "jobs",
      to: `${baseUrl}/jobs`,
      path: [`${baseUrl}/jobs`, `${baseUrl}/jobs/:subTab`],
      hidden: !isContainerJob || isNotContainerApp,
      component: JobsTab,
    },
    {
      label: "Deployments",
      routeKey: "deployments",
      to: `${baseUrl}/deployments`,
      path: [
        `${baseUrl}/deployments`,
        `${baseUrl}/deployments/:subTab`,
        `${baseUrl}/deployments/:subTab/:path`,
      ],
      component: DeploymentsTab,
    },
    {
      label: "Revisions",
      routeKey: "revisions",
      to: `${baseUrl}/revisions`,
      component: RevisionsTab,
    },
    {
      label: "Logs",
      routeKey: "logs",
      to: `${baseUrl}/logs`,
      path: [`${baseUrl}/logs`, `${baseUrl}/logs/:subTab`],
      hidden: isNotContainerApp,
      component: LogsTab,
    },
    {
      label: "Metrics",
      routeKey: "metrics",
      to: `${baseUrl}/metrics`,
      path: [`${baseUrl}/metrics`, `${baseUrl}/metrics/:subTab`],
      hidden: isNotContainerApp,
      component: MetricsTab,
    },
    {
      label: "Resources",
      routeKey: "resources",
      to: `${baseUrl}/resources`,
      hidden: isNotContainerApp || !isResourcesTabEnabled,
      component: RepoResourceTab,
    },
    {
      label: "Settings",
      routeKey: "settings",
      to: `${baseUrl}/settings`,
      path: [`${baseUrl}/settings`, `${baseUrl}/settings/:tab`],
      component: SettingsTab,
    },
  ];

  return (
    <TabbedLayout
      pageTitle="Project"
      title={repoName}
      banner={<ProjectBanner />}
      actions={<ProjectActions />}
      tabs={tabs}
      withContainer
      isLazy
    />
  );
};

export default Project;
