import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { NavTabType } from "./NavTab";

export const useCurrentTabIndex = (tabs: NavTabType[]) => {
  const { pathname } = useLocation();

  const currentTabIndex = useMemo(() => {
    const isMatch = (tabPath: string, pathname: string) => {
      if (tabPath.includes(":")) {
        const tabPathParts = tabPath.split("/");
        const pathnameParts = pathname.split("/");

        if (tabPathParts.length !== pathnameParts.length) {
          return false;
        }

        return tabPathParts.every((part, index) => {
          if (part.startsWith(":")) {
            return true;
          }

          return part === pathnameParts[index];
        });
      }

      return tabPath === pathname;
    };

    const visibleTabs = tabs.filter((tab) => !tab.hidden);
    return visibleTabs.findIndex((tab) => {
      const tabPath = tab.path || tab.to;

      if (tabPath instanceof Array) {
        return tabPath.some((path) => isMatch(path, pathname));
      }

      return isMatch(tabPath, pathname);
    });
  }, [pathname, tabs]);

  return currentTabIndex === -1 ? 0 : currentTabIndex;
};
