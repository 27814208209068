import { GitSourceInput } from "@zeet/web-api/dist/graphqlv1";
import { BitbucketRepositorySelect } from "~/components/RepositorySelect/bitbucket";
import { SourceTabPanelProps } from ".";
import { GitBranch } from "../GitBranch";
import { GitWorkingDirectory } from "../GitWorkingDirectory";
import { makeBitbucketSource } from "./utils";

export const BitbucketSource = ({
  onSourceChange,
  currentSource,
}: SourceTabPanelProps) => {
  const ref = currentSource?.git?.ref ?? "";
  const path = currentSource?.git?.path ?? "";

  const onSelect = (integrationId: string, repository: string) => {
    const gitSource: GitSourceInput = {
      ...currentSource?.git,
      repository,
      integration: {
        bitbucketIntegrationId: integrationId,
      },
    };
    onSourceChange(makeBitbucketSource(gitSource));
  };

  const onRefChange = (ref: string) => {
    const gitSource: GitSourceInput = {
      ...currentSource?.git,
      repository: currentSource?.git?.repository ?? "",
      ref,
    };
    onSourceChange(makeBitbucketSource(gitSource));
  };

  const onPathChange = (path: string) => {
    const gitSource: GitSourceInput = {
      ...currentSource?.git,
      repository: currentSource?.git?.repository ?? "",
      path,
    };
    onSourceChange(makeBitbucketSource(gitSource));
  };

  return (
    <>
      <BitbucketRepositorySelect onSelect={onSelect} source={currentSource} />
      <GitWorkingDirectory value={path} onChange={onPathChange} />
      <GitBranch value={ref} onChange={onRefChange} />
    </>
  );
};
