import { LogEntry } from "@zeet/web-api/dist/graphql";
import { ParsedColor } from "ansicolor";
import Color from "color";

export const formatLogLine = (log: LogEntry): string => {
  const logData = log.text;
  const warnPattern = /(^warn)|(\Wwarn\W)|(warning)/gim;
  const errorPattern = /(^err)|(\Werr\W)|(error)/gim;
  if (warnPattern.test(logData)) {
    return `\x1b[33m${logData}\x1b[0m`;
  } else if (errorPattern.test(logData)) {
    return `\x1b[1;31m${logData}\x1b[0m`;
  }
  return logData;
};

// i know it says deprecated but even MDN says despite deprecation, do this.
// technically should use navigator.userAgentData but its not in TS dom lib yet :/
export const isMacOs = () => navigator.platform.startsWith("Mac");

// splits the text up by lines, preserving escape codes as we go
// why is this necessary? because for some reason word-break: break-all
// does not properly break on certain characters, e.g. /", causing
// the line splitting to get messed up. so we just split it ourselves
// this i am guessing is why openlens does not wrap their lines.
export const splitLines = (text: string, charsPerLine: number): string[] => {
  const ansiCodes: string[] = [];
  let currentChar = 0;
  let charCounter = 0;
  const lines: string[] = [];
  let line = "";
  while (currentChar < text.length) {
    const char = text.charAt(currentChar);

    // ignore zero-width characters
    if (char === "\r") {
      currentChar++;
      continue;
    }

    if (char === "\x1b") {
      // escape code!
      const startIndex = currentChar;
      currentChar++;
      let nextChar = 0;
      while (nextChar < 0x40 || nextChar > 0x7e) {
        currentChar++;
        nextChar = text.charCodeAt(currentChar);
      }
      const code = text.substring(startIndex, currentChar + 1);
      ansiCodes.push(code);
      line += code;
    } else {
      charCounter++;
      line += char;
    }
    currentChar++;

    // if we just reached the max number of characters, start a new line
    if (charCounter === charsPerLine) {
      lines.push(line);
      line = ansiCodes.join("");
      charCounter = 0;
    }
  }

  if (charCounter > 0) {
    lines.push(line);
  }

  return lines;
};

// breaks up a range of find matches into their separate lines
export const breakUpRange = (
  match: { startIndex: number; endIndex: number; isActive: boolean },
  charsPerLine: number
) => {
  const startLineOffset = Math.floor(match.startIndex / charsPerLine);
  const endLineOffset = Math.floor(match.endIndex / charsPerLine);

  const ranges: {
    lineOffset: number;
    startIndex: number;
    endIndex: number;
    isActive: boolean;
  }[] = [];
  for (let i = startLineOffset; i <= endLineOffset; i++) {
    ranges.push({
      lineOffset: i,
      startIndex: i === startLineOffset ? match.startIndex % charsPerLine : 0,
      endIndex:
        i === endLineOffset ? match.endIndex % charsPerLine : charsPerLine,
      isActive: match.isActive,
    });
  }

  return ranges.filter((r) => r.startIndex !== r.endIndex);
};

export const extractColor = (
  color: ParsedColor | undefined,
  themeColors: Record<string, string>
) => {
  if (!color) return;

  const colorMap = {
    black: "terminal.ansiBlack",
    red: "terminal.ansiRed",
    green: "terminal.ansiGreen",
    yellow: "terminal.ansiYellow",
    blue: "terminal.ansiBlue",
    magenta: "terminal.ansiMagenta",
    cyan: "terminal.ansiCyan",
    darkGray: "terminal.ansiBrightBlack",
    lightRed: "terminal.ansiBrightRed",
    lightGreen: "terminal.ansiBrightGreen",
    lightYellow: "terminal.ansiBrightYellow",
    lightBlue: "terminal.ansiBrightBlue",
    lightMagenta: "terminal.ansiBrightMagenta",
    lightCyan: "terminal.ansiBrightCyan",
    white: "terminal.ansiBrightWhite",
  };

  const getBaseColor = () => {
    return colorMap[color.name ?? ""] ?? "terminal.foreground";
  };

  return Color(themeColors[getBaseColor()])
    .darken(color.dim ? 0.4 : 0)
    .hex();
};

export const measureCharSize = () => {
  const span = document.createElement("span");
  span.style.fontFamily = "courier-new, courier, monospace";
  span.style.fontSize = "15px";
  span.style.lineHeight = "18px";
  span.style.opacity = "0";
  span.style.position = "absolute";
  span.innerText = "a";
  document.body.appendChild(span);
  const w = span.getBoundingClientRect().width;
  document.body.removeChild(span);
  return w;
};
