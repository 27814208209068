import { Flex, Heading } from "@chakra-ui/react";
import { RxCaretRight } from "react-icons/rx";
import { Button } from "./Button";

interface ListButtonProps {
  logo: React.ReactNode;
  name: string;
  onClick: () => void;
  "data-testid": string;
}

export const ListButton: React.FC<ListButtonProps> = ({
  logo,
  name,
  onClick,
  "data-testid": dataCy,
}) => {
  return (
    <Button
      onClick={onClick}
      variant="secondary"
      width="100%"
      justifyContent="space-between"
      height="auto"
      padding={0}
      data-testid={dataCy}
    >
      <Flex ml={4} width="40px" justifyContent="center">
        {logo}
      </Flex>

      <Flex flex="1" padding={4} justifyContent="space-between">
        <Heading size="sm" display="inline-flex" alignItems="center">
          {name}
        </Heading>
        <RxCaretRight size="1.5rem" />
      </Flex>
    </Button>
  );
};
