import { useCurrentUser } from "@zeet/web-ui";
import {
  ActionId,
  ActionImpl,
  KBarAnimator,
  KBarPortal,
  KBarPositioner,
  KBarProvider,
  KBarResults,
  KBarSearch,
  useKBar,
  useMatches,
  VisualState,
} from "kbar";
import * as React from "react";
import { useHistory } from "react-router-dom";
import "./index.scss";
import usePreferencesActions from "./preferences";
import { useProjectsActions } from "./projects";

const searchStyle = {
  padding: "12px 16px",
  fontSize: "16px",
  width: "100%",
  boxSizing: "border-box" as React.CSSProperties["boxSizing"],
  outline: "none",
  border: "none",
  background: "var(--background)",
  color: "var(--foreground)",
};

const animatorStyle = {
  maxWidth: "600px",
  width: "100%",
  background: "var(--background)",
  color: "var(--foreground)",
  borderRadius: "8px",
  overflow: "hidden",
  boxShadow: "var(--shadow)",
};

const groupNameStyle = {
  padding: "8px 16px",
  fontSize: "10px",
  textTransform: "uppercase" as const,
  opacity: 0.5,
};

const CommandBar: React.FC<React.PropsWithChildren> = ({ children }) => {
  const history = useHistory();
  const initialActions = [
    {
      id: "dashboard",
      name: "Dashboard",
      keywords: "home dashboard back",
      section: "Navigation",
      perform: () => history.push("/"),
      subtitle: "Return to the dashboard",
    },
    {
      id: "projects",
      name: "Projects",
      keywords: "project",
      section: "Navigation",
    },
    {
      id: "projects-create",
      name: "Create New Project",
      keywords: "projects new create",
      perform: () => {
        history.push("/new");
      },
      parent: "projects",
    },
    {
      id: "docs",
      name: "Docs",
      keywords: "help docs documentation guide support examples",
      section: "Navigation",
      perform: () => window.open("https://docs.zeet.co", "_blank"),
    },
    {
      id: "Blog",
      name: "Blog",
      keywords: "blog examples guide",
      section: "Navigation",
      perform: () => window.open("https://blog.zeet.co", "_blank"),
    },
    {
      id: "support",
      name: "Support",
      keywords: "help support email contact feedback",
      section: "Navigation",
      perform: () => window.$crisp?.push(["do", "chat:open"]),
    },
    {
      id: "teams",
      name: "Teams",
      keywords: "teams",
      section: "Navigation",
    },
    {
      id: "teams-create",
      name: "Create New Team",
      keywords: "teams new create",
      perform: () => {
        history.push("/team-onboard");
      },
      parent: "teams",
    },
  ];

  return (
    <KBarProvider
      options={{
        enableHistory: true,
      }}
      actions={initialActions}
    >
      {children}
      <CommandBarLazyComponents />
    </KBarProvider>
  );
};

const CommandBarLazyComponents = () => {
  const { isVisible } = useKBar((state) => ({
    isVisible: state.visualState !== VisualState.hidden,
  }));

  if (!isVisible) return null;

  return (
    <>
      <CommandBarActions />
      <CommandBarPortal />
    </>
  );
};

const CommandBarActions: React.FC = () => {
  usePreferencesActions();

  const user = useCurrentUser();
  return user ? <CommandBarUserActions /> : null;
};

const CommandBarUserActions: React.FC = () => {
  useProjectsActions();

  return null;
};

const CommandBarPortal = () => {
  return (
    <KBarPortal>
      <KBarPositioner className="kbar-position">
        <KBarAnimator style={animatorStyle}>
          <KBarSearch style={searchStyle} />
          <RenderResults />
        </KBarAnimator>
      </KBarPositioner>
    </KBarPortal>
  );
};

function RenderResults() {
  const { results, rootActionId } = useMatches();

  return (
    <KBarResults
      items={results}
      onRender={({ item, active }) =>
        typeof item === "string" ? (
          <div style={groupNameStyle}>{item}</div>
        ) : (
          <ResultItem
            action={item}
            active={active}
            currentRootActionId={rootActionId || ""}
          />
        )
      }
    />
  );
}

const ResultItem = React.forwardRef(
  (
    {
      action,
      active,
      currentRootActionId,
    }: {
      action: ActionImpl;
      active: boolean;
      currentRootActionId: ActionId;
    },
    ref: React.Ref<HTMLDivElement>
  ) => {
    const ancestors = React.useMemo(() => {
      if (!currentRootActionId) return action.ancestors;
      const index = action.ancestors.findIndex(
        (ancestor) => ancestor.id === currentRootActionId
      );
      // +1 removes the currentRootAction; e.g.
      // if we are on the "Set theme" parent action,
      // the UI should not display "Set theme… > Dark"
      // but rather just "Dark"
      return action.ancestors.slice(index + 1);
    }, [action.ancestors, currentRootActionId]);

    return (
      <div
        ref={ref}
        style={{
          padding: "12px 16px",
          background: active ? "var(--background-2)" : "transparent",
          borderLeft: `3px solid ${active ? "var(--brand)" : "transparent"}`,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          cursor: "pointer",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "8px",
            alignItems: "center",
            fontSize: 14,
          }}
        >
          {action.icon && action.icon}
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div>
              {ancestors.length > 0 &&
                ancestors.map((ancestor) => (
                  <React.Fragment key={ancestor.id}>
                    <span
                      style={{
                        opacity: 0.5,
                        marginRight: 8,
                      }}
                    >
                      {ancestor.name}
                    </span>
                    <span
                      style={{
                        marginRight: 8,
                      }}
                    >
                      &rsaquo;
                    </span>
                  </React.Fragment>
                ))}
              <span>{action.name}</span>
            </div>
            {action.subtitle && (
              <span style={{ fontSize: 12 }}>{action.subtitle}</span>
            )}
          </div>
        </div>
        {action.shortcut?.length ? (
          <div
            aria-hidden
            style={{ display: "grid", gridAutoFlow: "column", gap: "4px" }}
          >
            {action.shortcut.map((sc) => (
              <div key={sc} className="kbar-shortcut">
                {sc}
              </div>
            ))}
          </div>
        ) : null}
      </div>
    );
  }
);

export default CommandBar;
