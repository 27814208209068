import { EditorInput } from "@zeet/web-ui";
import { FieldValues } from "react-hook-form";
import { InputContainer, InputProps } from "./InputContainer";

export const CodeEditorInput = <TFormData extends FieldValues>(
  props: InputProps<TFormData>
) => {
  return (
    <InputContainer
      {...props}
      render={({ ref: _ref, ...p }) => <EditorInput minHeight="300px" {...p} />}
    />
  );
};
