import {
  Alert,
  Badge,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { ProjectOutputsQuery } from "@zeet/web-api/dist/graphqlv1";
import { Button, CopyCode } from "@zeet/web-ui";
import React from "react";
import { RiZzzFill } from "react-icons/ri";
import { isDeletable } from "../utils";
import { useWorkflowOperations } from "../WorkflowsTab/useWorkflowOperations";

const makeInternalEndpoint = (
  projectOutputs?: ProjectOutputsQuery
): string | null => {
  const { fields = [] } = projectOutputs?.team?.project?.connector ?? {};
  const internalIp = fields?.find(
    (field) => field.name && ["InternalIP", "DB_HOST"].includes(field.name)
  )?.value;
  const internalPort = fields?.find(
    (field) => field.name && ["InternalPort", "DB_PORT"].includes(field.name)
  )?.value;

  if (internalIp && !internalPort) {
    return internalIp;
  }
  if (internalIp && internalPort) {
    return `${internalIp}:${internalPort}`;
  }
  return null;
};

const makeExternalEndpoint = (
  projectOutputs?: ProjectOutputsQuery
): string | null => {
  const { fields = [] } = projectOutputs?.team?.project?.connector ?? {};
  const externalIp = fields?.find(
    (field) =>
      field.name && ["ExternalIP", "DB_HOST_EXTERNAL"].includes(field.name)
  )?.value;
  const externalPort = fields?.find(
    (field) =>
      field.name && ["ExternalPort", "DB_PORT_EXTERNAL"].includes(field.name)
  )?.value;

  if (externalIp && !externalPort) {
    return externalIp;
  }
  if (externalIp && externalPort) {
    return `${externalIp}:${externalPort}`;
  }
  return null;
};

const getPassword = (projectOutputs?: ProjectOutputsQuery): string | null => {
  return (
    projectOutputs?.team?.project?.connector?.fields?.find((field) =>
      field.name?.toLowerCase()?.includes("password")
    )?.value ?? null
  );
};

const getApiKey = (projectOutputs?: ProjectOutputsQuery): string | null => {
  return (
    projectOutputs?.team?.project?.connector?.fields?.find((field) =>
      field.name?.toLowerCase()?.includes("APIKey")
    )?.value ?? null
  );
};

const getIsAuthEnabled = (projectOutputs?: ProjectOutputsQuery): boolean => {
  const authEnabled =
    projectOutputs?.team?.project?.connector?.fields?.find(
      (field) => field.name === "AuthEnabled"
    )?.value === "true";
  const hasPassword = getPassword(projectOutputs) !== null;
  const hasApiKey = getApiKey(projectOutputs) !== null;
  return authEnabled || hasPassword || hasApiKey;
};

const getAdditionalFields = (projectOutputs?: ProjectOutputsQuery) => {
  const fieldsToIgnore = [
    "AuthEnabled",
    "InternalIP",
    "InternalPort",
    "Password",
    "APIKey",
    "ExternalIP",
    "ExternalPort",
    "DB_HOST",
    "DB_PORT",
    "DB_HOST_EXTERNAL",
    "DB_PORT_EXTERNAL",
    "DB_USERNAME",
    "DB_PASSWORD",
  ];
  return projectOutputs?.team?.project?.connector?.fields?.filter(
    (field) => !fieldsToIgnore.includes(field.name ?? "")
  );
};

const DatabaseDormant = ({
  projectOutputs,
  onClose,
}: {
  projectOutputs: ProjectOutputsQuery;
  onClose: () => void;
}) => {
  const projectId = projectOutputs.team?.project?.id;
  const workflowId = projectOutputs.team?.project?.workflow?.id;

  const { submitRun, submitLoading } = useWorkflowOperations();

  return (
    <Flex flexDir="column" gap="4" mb="4">
      <Alert
        status="info"
        position="relative"
        left="-1.5rem"
        width="calc(100% + 3rem)"
        px="7"
        colorScheme="gray"
      >
        <Icon as={RiZzzFill} mr="2" />
        Database is dormant
      </Alert>
      <Text>
        Your database is not running. It must be deployed and fully provisioned
        before you can connect to it.
      </Text>
      <Button
        variant="primary"
        onClick={() => {
          if (!projectId || !workflowId) return;
          submitRun(projectId, workflowId);
          onClose();
        }}
        isLoading={submitLoading}
      >
        Deploy database
      </Button>
    </Flex>
  );
};

const DatabaseSetupInProgress: React.FC = () => {
  return (
    <Flex flexDir="column" gap="4" mb="4">
      <Alert
        status="info"
        position="relative"
        left="-1.5rem"
        width="calc(100% + 3rem)"
        px="7"
      >
        <Spinner size="sm" mr="2" />
        Database setup in progress
      </Alert>
      <Text>
        Your database is being provisioned. This may take a few minutes before
        we have connection details for you.
      </Text>
    </Flex>
  );
};

const DatabaseConnection = ({
  projectOutputs,
}: {
  projectOutputs: ProjectOutputsQuery;
}) => {
  const externalEndpoint = makeExternalEndpoint(projectOutputs);
  const internalEndpoint = makeInternalEndpoint(projectOutputs);
  const password = getPassword(projectOutputs);
  const apiKey = getApiKey(projectOutputs);
  const isAuthEnabled = getIsAuthEnabled(projectOutputs);
  const additionalFields = getAdditionalFields(projectOutputs);

  return (
    <Flex flexDir="column" gap="4" mb="4">
      {externalEndpoint && (
        <Alert
          status="error"
          position="relative"
          left="-1.5rem"
          width="calc(100% + 3rem)"
          px="7"
        >
          <Badge colorScheme="red" variant="solid" mr="2">
            Public
          </Badge>
          Database is publicly accessible
        </Alert>
      )}
      {isAuthEnabled ? (
        <Alert
          status="info"
          position="relative"
          left="-1.5rem"
          width="calc(100% + 3rem)"
          px="7"
        >
          <Badge colorScheme="blue" variant="solid" mr="2">
            Secure
          </Badge>
          Authentication is enabled
        </Alert>
      ) : (
        <Alert
          status="error"
          position="relative"
          left="-1.5rem"
          width="calc(100% + 3rem)"
          px="7"
        >
          <Badge colorScheme="red" variant="solid" mr="2">
            Not secure
          </Badge>
          Authentication is disabled
        </Alert>
      )}
      {(internalEndpoint || externalEndpoint) && (
        <Text>To connect to this database, use the following endpoints.</Text>
      )}
      {externalEndpoint && (
        <Flex flexDir="column" gap="2">
          <Text fontWeight="bold">External endpoint</Text>
          <CopyCode value={externalEndpoint} />
          <Text fontSize="sm" opacity="0.6">
            An external endpoint is accessible from anywhere on the internet.
          </Text>
        </Flex>
      )}
      {internalEndpoint && (
        <Flex flexDir="column" gap="2">
          <Text fontWeight="bold">Internal endpoint</Text>
          <CopyCode value={internalEndpoint} />
          <Text fontSize="sm" opacity="0.6">
            An internal endpoint is only accessible to other services running
            from within this cluster.
          </Text>
        </Flex>
      )}
      {apiKey && (
        <Flex flexDir="column" gap="2">
          <Text fontWeight="bold">API Key</Text>
          <CopyCode value={apiKey} sensitive />
          <Text fontSize="sm" opacity="0.6">
            Use this API key to connect to your database.
          </Text>
        </Flex>
      )}
      {password && (
        <Flex flexDir="column" gap="2">
          <Text fontWeight="bold">Password</Text>
          <CopyCode value={password} sensitive />
          <Text fontSize="sm" opacity="0.6">
            Use this password to connect to your database.
          </Text>
        </Flex>
      )}
      {additionalFields
        ?.filter((field) => !!field.value)
        .map((field) => (
          <Flex key={field.name} flexDir="column" gap="2">
            <Text fontWeight="bold">{field?.displayName}</Text>
            <CopyCode value={field.value ?? ""} />
          </Flex>
        ))}
    </Flex>
  );
};

export const DatabaseConnectModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  projectOutputs: ProjectOutputsQuery;
}> = ({ isOpen, onClose, projectOutputs }) => {
  const projectStatus = projectOutputs.team?.project?.status;

  const isSetupInProgress = (projectOutputs?: ProjectOutputsQuery) => {
    return (
      projectOutputs?.team?.project?.connector?.fields?.length === 0 ||
      projectOutputs?.team?.project?.connector?.fields === null
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      isCentered
      preserveScrollBarGap={true}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton data-testid="draft-cluster-modal-close-button" />
        <ModalHeader>Connecting to your database</ModalHeader>
        <ModalBody>
          {isDeletable(projectStatus) ? (
            <DatabaseDormant
              projectOutputs={projectOutputs}
              onClose={onClose}
            />
          ) : isSetupInProgress(projectOutputs) ? (
            <DatabaseSetupInProgress />
          ) : (
            <DatabaseConnection projectOutputs={projectOutputs} />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
