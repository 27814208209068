import { Flex, Text } from "@chakra-ui/react";
import {
  ClusterDetailV1Fragment,
  ObservabilityResourceSelector,
  ResourceType,
} from "@zeet/web-api/dist/graphqlv1";
import { Container, useColors } from "@zeet/web-ui";
import React from "react";
import { useLocation } from "react-router-dom";
import { BackNavButton } from "../components/BackNavLayout";
import { ResourceDetailContent } from "./ResourceDetailContent";
import { ResourceTypeBadge } from "./ResourceTypeBadge";

interface ResourceDetailPageProps {
  projectId: string;
  selector: ObservabilityResourceSelector;
  cluster: ClusterDetailV1Fragment;
}

export const ResourceDetailPage: React.FC<ResourceDetailPageProps> = ({
  projectId,
  selector,
  cluster,
}) => {
  const { pathname } = useLocation();
  const { bg2 } = useColors();

  return (
    <Flex direction="column">
      <Container
        fullWidth
        background={bg2}
        px={10}
        py={4}
        borderBottomWidth="1px"
        gridColumnStart={1}
        gridColumnEnd={3}
        alignItems="center"
        display="flex"
        mb="3"
      >
        <Flex direction="column" alignItems="flex-start" flex="1">
          <BackNavButton
            mt={2}
            linkText="Back to resources"
            linkTo={pathname}
          />
          <Flex alignItems="baseline">
            <Text fontSize="1.25em" fontWeight={600} mt={3}>
              {selector.resourceName} - {selector.containerName}
            </Text>
            <ResourceTypeBadge ml="3" resourceType={ResourceType.Container} />
          </Flex>
        </Flex>
      </Container>
      <ResourceDetailContent
        projectId={projectId}
        selector={selector}
        cluster={cluster}
      />
    </Flex>
  );
};
