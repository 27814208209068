import { ReactNode } from "react";
import { Link, LinkProps } from "../Link";
import { Copy, CopyProps, CopyStyleProps, DEFAULT_VARIANT } from "./Copy";
import { useCopyDisplayItemStyles } from "./hooks";

interface CopyCodeProps extends CopyStyleProps {
  children?: string | ReactNode;
  to: string;
  linkProps?: Partial<LinkProps>;
  variant?: CopyProps["variant"];
}

export const CopyLink: React.FC<CopyCodeProps> = ({
  children,
  to,
  linkProps = {},
  variant = DEFAULT_VARIANT,
  ...rest
}) => {
  const copyDisplayItemStyles = useCopyDisplayItemStyles(variant);

  return (
    <Copy {...rest} text={to} variant={variant}>
      <Link to={to} noUnderline {...copyDisplayItemStyles} {...linkProps}>
        {children || to}
      </Link>
    </Copy>
  );
};
