import { Box } from "@chakra-ui/react";
import Editor from "@monaco-editor/react";
import {
  BuildTemplateType,
  DeploymentDetailFragment,
  DeployTemplateType,
  useBuildTemplatesQuery,
} from "@zeet/web-api/dist/graphql";
import { Skeleton, ZError } from "@zeet/web-ui";
import React from "react";

export const BuildTemplates: React.FC<{
  deployment: DeploymentDetailFragment;
}> = ({ deployment }) => {
  const { data, loading, error } = useBuildTemplatesQuery({
    errorPolicy: "all",
    variables: {
      id: deployment.id,
    },
  });

  if (loading) {
    return <Skeleton mt={2} height="500px" />;
  }

  if (
    data?.currentUser.deployment?.buildStage?.buildTemplates &&
    data?.currentUser.deployment?.buildStage?.buildTemplates.length === 0
  ) {
    return null;
  }

  return (
    <Box pb="24px">
      {data?.currentUser.deployment?.buildStage?.buildTemplates?.map(
        (dt, i) => (
          <PipelineTemplate
            key={i}
            deployment={deployment}
            templateString={dt?.output}
            defaultLanguage={BuildTemplateType.Dockerfile}
          />
        )
      )}
      <ZError error={error} mt="12px" />
    </Box>
  );
};

export const PipelineTemplate: React.FC<{
  deployment: DeploymentDetailFragment;
  templateString: string | undefined | null;
  defaultLanguage: DeployTemplateType | BuildTemplateType | undefined;
}> = ({ templateString, defaultLanguage }) => {
  if (!templateString) {
    return null;
  }

  return (
    <Box>
      <Editor
        theme="vs-dark"
        value={templateString || ""}
        defaultLanguage={defaultLanguage?.toString().toLowerCase()}
        options={{
          lineNumbers: "off",
          glyphMargin: false,
          lineDecorationsWidth: 0,
          lineNumbersMinChars: 0,
          minimap: {
            enabled: false,
          },
          readOnly: true,
        }}
        height="30rem"
        width="528px"
      />
    </Box>
  );
};
