import { DataTypes } from "@zeet/web-ui";
import { jobRunStatusMap } from "../utils";

export enum BuildColumnId {
  Name = "buildName",
  Status = "buildStatus",
  CreatedAt = "buildCreated",
  TriggeredBy = "buildTriggeredBy",
}

export const buildColumnIds = Object.values(BuildColumnId);
export const buildColumns = [
  {
    id: BuildColumnId.Name,
    dataType: DataTypes.LINK,
    minWidth: 250,
    order: 1,
    label: "Name",
    options: [],
  },
  {
    id: BuildColumnId.Status,
    dataType: DataTypes.BADGE,
    minWidth: 200,
    order: 2,
    label: "Status",
    options: [
      {
        statusMap: jobRunStatusMap,
      },
    ],
  },
  {
    id: BuildColumnId.CreatedAt,
    dataType: DataTypes.TIMEAGO,
    minWidth: 180,
    order: 3,
    label: "Triggered",
    options: [],
  },
  {
    id: BuildColumnId.TriggeredBy,
    dataType: DataTypes.LINK,
    minWidth: 150,
    order: 4,
    label: "Triggered By",
    options: [],
  },
];
