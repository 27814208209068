import { CardProps, Flex, Spacer } from "@chakra-ui/react";
import { CloudProvider } from "@zeet/web-api/dist/graphql";
import { Button, Card, Skeleton, useCurrentTeamUser } from "@zeet/web-ui";
import { useCloudDetailsQuery } from "~/hooks/useCloudDetailsQuery";
import CloudIcon from "../Console/ConsoleClouds/View/CloudIcon";

interface CloudProviderViewProps extends CardProps {
  accountId: string;
  provider: CloudProvider;
}

export const CloudProviderView = ({
  accountId,
  provider,
  ...rest
}: CloudProviderViewProps) => {
  const { login } = useCurrentTeamUser();

  const { data, loading } = useCloudDetailsQuery({ id: accountId, provider });
  return (
    <Card px="4" py="2" as={Flex} flexDirection="row" fontSize="sm" {...rest}>
      {loading ? (
        <Skeleton height="24px" width="30%" />
      ) : (
        <CloudIcon cloudAccountKey={data?.name} cloudProvider={provider} />
      )}
      <Spacer />
      <Button
        asLink
        to={`/${login}/console/clouds/${provider.toLowerCase()}/${accountId}`}
        variant="secondary"
        height="auto"
        py={1}
        fontSize="0.8em"
      >
        View
      </Button>
    </Card>
  );
};
