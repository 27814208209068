import { Flex } from "@chakra-ui/react";
import { CloudProvidersEnum, DataTypes, IDataRow } from "@zeet/web-ui";
import React from "react";
import { CellProps } from "react-table";
import { CoreweaveRepoList } from "../Console/ConsoleClouds/View/CoreweaveRepoList";
import { RepoList } from "../Console/ConsoleClouds/View/RepoList";
import {
  BlueprintDriverTypeCell,
  BlueprintEnabledCell,
  BlueprintNameCell,
  BlueprintOwner,
  BooleanCell,
  CloudProviderCell,
  ClusterCloudProviderCell,
  ClustersCell,
  CodeCell,
  CopyCodeCell,
  DeploymentActionsCell,
  DeploymentStatusCell,
  InvitationDelete,
  LinkCell,
  MaxWidthTextCell,
  ProjectTypeCell,
  RevisionCell,
  StatusCell,
  SwitchCell,
  TextCell,
  TimeAgoCell,
  User,
  UserAction,
  UserConnect,
  UserRole,
  UserUsername,
  YesNoCell,
} from "./Cells";
import { BadgeCell } from "./Cells/BadgeCell";
import { CloudResourceIdCell } from "./Cells/CloudResourceIdCell";
import { ClusterStatusCell } from "./Cells/ClusterStatus";
import { JsonModalCell } from "./Cells/JsonModalCell";
import { ProjectRoleUpdateMenu } from "./Cells/ProjectRoleUpdateMenu";
import { ProjectStatusCell } from "./Cells/ProjectStatus";

const Cell: React.FC<CellProps<IDataRow>> = ({ row, column }) => {
  const originalRow = row.original[column.id];

  return (
    <Flex paddingLeft="22px" height="100%" alignItems="center">
      {column.dataType === DataTypes.TEXT && (
        <TextCell
          text={originalRow.text}
          as={originalRow.textAs}
          tooltip={originalRow.tooltip}
        />
      )}
      {column.dataType === DataTypes.BOOLEAN && (
        <BooleanCell bool={originalRow.boolean} />
      )}
      {column.dataType === DataTypes.LINK && (
        <LinkCell
          isExternal={originalRow?.isExternal ?? false}
          to={originalRow?.link}
          icon={originalRow?.icon}
          loading={originalRow?.loading}
        >
          {originalRow?.text}
        </LinkCell>
      )}
      {column.dataType === DataTypes.MAX_WIDTH_TEXT && (
        <MaxWidthTextCell text={originalRow.text} />
      )}
      {column.dataType === DataTypes.TIMEAGO && (
        <TimeAgoCell date={originalRow.date} />
      )}
      {column.dataType === DataTypes.STATE && (
        <StatusCell status={originalRow.state} />
      )}
      {column.dataType === DataTypes.CLUSTER_STATUS && (
        <ClusterStatusCell status={originalRow.state} />
      )}
      {column.dataType === DataTypes.PROJECT_STATUS && (
        <ProjectStatusCell
          status={originalRow.status}
          loading={originalRow.loading}
        />
      )}
      {column.dataType === DataTypes.CLUSTER_CLOUD_PROVIDER && (
        <ClusterCloudProviderCell clusterCloudProvider={originalRow} />
      )}
      {column.dataType === DataTypes.CLOUD_PROVIDER && (
        <CloudProviderCell
          to={originalRow?.link}
          cloudAccountKey={originalRow?.cloudAccountKey}
          cloudProvider={originalRow?.text}
        />
      )}
      {column.dataType === DataTypes.CONNECTED && (
        <YesNoCell isYes={originalRow.boolean} />
      )}
      {column.dataType === DataTypes.REPOS &&
        row.original.cloudProvider?.text !== CloudProvidersEnum.CW && (
          <RepoList projectV3Adapters={originalRow.repos} />
        )}
      {column.dataType === DataTypes.REPOS &&
        row.original.cloudProvider?.text === CloudProvidersEnum.CW && (
          <CoreweaveRepoList repos={originalRow.repos} />
        )}
      {column.dataType === DataTypes.CLUSTERS && (
        <ClustersCell
          clusters={originalRow.clusters}
          cloudId={originalRow.cloudId}
        />
      )}
      {column.dataType === DataTypes.BLUEPRINT_NAME && (
        <BlueprintNameCell blueprint={originalRow.blueprint} />
      )}
      {column.dataType === DataTypes.PROJECT_TYPE && (
        <ProjectTypeCell
          blueprint={originalRow.blueprint}
          project={originalRow.project}
        />
      )}
      {column.dataType === DataTypes.BLUEPRINT_DRIVER_TYPE && (
        <BlueprintDriverTypeCell blueprint={originalRow.blueprint} />
      )}
      {column.dataType === DataTypes.BLUEPRINT_OWNER && (
        <BlueprintOwner blueprint={originalRow.blueprint} />
      )}
      {column.dataType === DataTypes.CODE && (
        <CodeCell
          text={originalRow.text}
          as={originalRow.textAs}
          tooltip={originalRow.tooltip}
        />
      )}
      {column.dataType === DataTypes.ZEET_USERS_NAME && (
        <UserUsername member={originalRow.member} column={column} />
      )}
      {column.dataType === DataTypes.ZEET_USERS_ROLE && (
        <UserRole
          member={originalRow.member}
          refetchTeamMembers={originalRow.refetchTeamMembers}
          userIsOwner={originalRow.userIsOwner}
          userIsOwnerOrAdmin={originalRow.userIsOwnerOrAdmin}
          teamMembers={originalRow.teamMembers}
        />
      )}
      {column.dataType === DataTypes.ZEET_USERS_CONNECTED && (
        <UserConnect
          member={originalRow.member}
          teamCloudConnectionsLoading={originalRow.teamCloudConnectionsLoading}
          userIsOwnerOrAdmin={originalRow.userIsOwnerOrAdmin}
          teamCloudConnections={originalRow.teamCloudConnections}
        />
      )}
      {column.dataType === DataTypes.ZEET_USERS_ACTION && (
        <UserAction
          member={originalRow.member}
          refetchTeamMembers={originalRow.refetchTeamMembers}
          userIsOwnerOrAdmin={originalRow.userIsOwnerOrAdmin}
        />
      )}
      {column.dataType === DataTypes.ZEET_INVITATIONS_ACTION && (
        <InvitationDelete
          invitation={originalRow.invitation}
          refetchTeamMembers={originalRow.refetchTeamMembers}
        />
      )}
      {column.dataType === DataTypes.COPY_CODE && (
        <CopyCodeCell text={originalRow.text} />
      )}
      {column.dataType === DataTypes.JSON_MODAL && (
        <JsonModalCell
          arn={originalRow.arn}
          awsAccountId={originalRow.awsAccountId}
          awsPolicyName={originalRow.awsPolicyName}
          awsUsername={originalRow.awsUsername}
          modalLink={originalRow.modalLink}
          onOpenFunc={originalRow.onOpenFunc}
        />
      )}
      {column.dataType === DataTypes.CLOUD_RESOURCE_ID && (
        <CloudResourceIdCell
          fullId={originalRow.fullId}
          resourceUrl={originalRow.resourceUrl}
          simpleId={originalRow.simpleId}
        />
      )}
      {column.dataType === DataTypes.USER && (
        <User user={originalRow.user} column={column} />
      )}
      {column.dataType === DataTypes.REVISION && (
        <RevisionCell
          revision={originalRow?.revision}
          revisions={originalRow?.revisions}
        />
      )}
      {column.dataType === DataTypes.PROJECT_DEPLOYMENT_STATUS && (
        <DeploymentStatusCell status={originalRow.status} />
      )}
      {column.dataType === DataTypes.PROJECT_DEPLOYMENT_ACTIONS && (
        <DeploymentActionsCell deployRun={originalRow.deployRun} />
      )}
      {column.dataType === DataTypes.BADGE && (
        <BadgeCell
          map={column.options[0].statusMap}
          value={originalRow?.status}
        />
      )}
      {column.dataType === DataTypes.SWITCH && (
        <SwitchCell
          isChecked={originalRow?.isChecked}
          onChange={originalRow?.onChange}
        />
      )}
      {column.dataType === DataTypes.BLUEPRINT_ENABLED_ACTION && (
        <BlueprintEnabledCell blueprint={originalRow.blueprint} />
      )}
      {column.dataType === DataTypes.PROJECT_ROLE_UPDATE_ACTION && (
        <ProjectRoleUpdateMenu
          project={originalRow.project}
          user={originalRow.user}
          availableRoles={originalRow.availableRoles}
        />
      )}
    </Flex>
  );
};

export default Cell;
