import { Divider } from "@chakra-ui/react";
import {
  CombinedCloudRunMetricsUpdatedSubscription,
  MetricType,
  RepoDetailFragment,
  useCombinedCloudRunMetricsUpdatedSubscription,
} from "@zeet/web-api/dist/graphql";
import React, { useEffect, useMemo, useState } from "react";
import { adapterToMetricStream, MetricAggregationOption } from "./index";
import { MetricsChart } from "./MetricChart";
import { MetricsControlPanel } from "./MetricControlPanel";

export const GcpCloudRunMetrics: React.FC<{
  repo: RepoDetailFragment;
  deploymentID: string;
}> = ({ repo, deploymentID }) => {
  const combinedDeploymentId =
    deploymentID || repo.productionDeployment?.id || "";

  const { data, error, loading } =
    useCombinedCloudRunMetricsUpdatedSubscription({
      variables: { deploymentID: combinedDeploymentId },
      skip: !combinedDeploymentId,
    });

  const [metricsData, setMetricsData] = useState<
    CombinedCloudRunMetricsUpdatedSubscription | undefined
  >();

  useEffect(() => {
    if (!error) {
      setMetricsData(data);
    }
  }, [data, metricsData, error]);

  const metricsMap = useMemo(
    () => [
      {
        type: MetricType.RequestCount,
        metrics: adapterToMetricStream(
          MetricType.RequestCount,
          metricsData?.deploymentUpdated.request_count
        ),
      },
      {
        type: MetricType.InstanceCount,
        metrics: adapterToMetricStream(
          MetricType.InstanceCount,
          metricsData?.deploymentUpdated.instance_count
        ),
      },
      {
        type: MetricType.CpuUtilization,
        metrics: adapterToMetricStream(
          MetricType.CpuUtilization,
          metricsData?.deploymentUpdated.cpu_utilizations
        ),
      },
      {
        type: MetricType.MemoryUtilization,
        metrics: adapterToMetricStream(
          MetricType.MemoryUtilization,
          metricsData?.deploymentUpdated.memory_utilizations
        ),
      },
    ],
    [metricsData]
  );

  return (
    <MetricsControlPanel
      isLoading={loading}
      ctas={
        metricsData?.deploymentUpdated.gcpLinks?.cloudMonitoring
          ? [
              {
                link: metricsData?.deploymentUpdated.gcpLinks?.cloudMonitoring,
                label: "View in GCP Cloud Monitoring",
              },
            ]
          : []
      }
    >
      {metricsMap
        .filter((metric) => !!metric.metrics)
        .map(({ type, metrics }, mi) => (
          <>
            {!!mi && <Divider mb={2} />}
            <MetricsChart
              px={3}
              key={mi}
              metricQuery=""
              metricType={type}
              metrics={metrics ?? []}
              aggregationType={MetricAggregationOption.UNIFIED}
              showViewMetricExternalButton={false}
            />
          </>
        ))}
    </MetricsControlPanel>
  );
};
