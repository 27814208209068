import { Box } from "@chakra-ui/react";
import { PropsOf } from "@emotion/react";
import { Container, useColors } from "@zeet/web-ui";
import React from "react";

export const AccountPageHead: React.FC<PropsOf<typeof Box>> = ({
  children,
  ...restBoxProps
}) => {
  const { bg2 } = useColors();
  return (
    <Box
      w="100%"
      bg={bg2}
      py={4}
      borderBottomWidth="1px"
      mb={8}
      zIndex="10"
      {...restBoxProps}
    >
      <Container fullWidth>{children}</Container>
    </Box>
  );
};
