import { useQuery } from "@apollo/client";
import {
  CloudDetailsAwsDocument,
  CloudDetailsAwsQuery,
  CloudDetailsAzureDocument,
  CloudDetailsAzureQuery,
  CloudDetailsCoreweaveDocument,
  CloudDetailsCoreweaveQuery,
  CloudDetailsDoDocument,
  CloudDetailsDoQuery,
  CloudDetailsGcpDocument,
  CloudDetailsGcpQuery,
  CloudDetailsLinodeDocument,
  CloudDetailsLinodeQuery,
  CloudDetailsVultrDocument,
  CloudDetailsVultrQuery,
  CloudProvider,
} from "@zeet/web-api/dist/graphql";
import { useCurrentTeamUser } from "@zeet/web-ui";

const documentMap = {
  [CloudProvider.Aws]: CloudDetailsAwsDocument,
  [CloudProvider.Do]: CloudDetailsDoDocument,
  [CloudProvider.Gcp]: CloudDetailsGcpDocument,
  [CloudProvider.Azure]: CloudDetailsAzureDocument,
  [CloudProvider.Vultr]: CloudDetailsVultrDocument,
  [CloudProvider.Linode]: CloudDetailsLinodeDocument,
  [CloudProvider.Coreweave]: CloudDetailsCoreweaveDocument,
};

export const useCloudDetailsQuery = ({
  id,
  provider,
}: {
  id: string;
  provider: CloudProvider;
}) => {
  const { id: teamId } = useCurrentTeamUser();
  const queryDocument = documentMap[provider];
  const { data, loading } = useQuery(queryDocument, {
    variables: {
      id: teamId,
      accountId: id,
    },
  });

  return { data: transformData(data), loading };
};

const transformData = (
  data:
    | (CloudDetailsAwsQuery &
        CloudDetailsDoQuery &
        CloudDetailsGcpQuery &
        CloudDetailsAzureQuery &
        CloudDetailsVultrQuery &
        CloudDetailsLinodeQuery &
        CloudDetailsCoreweaveQuery)
    | null
    | undefined
):
  | { id: string; name: string; connected?: boolean | null }
  | null
  | undefined => {
  if (!data?.user) return null;
  return (
    data.user.awsAccount ||
    data.user.gcpAccount ||
    data.user.doAccount ||
    data.user.azureAccount ||
    data.user.vultrAccount ||
    data.user.linodeAccount ||
    data.user.coreweaveAccount
  );
};
