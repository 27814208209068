import { Flex } from "@chakra-ui/react";
import { DeploymentStatus } from "@zeet/web-api/dist/graphqlv1";
import { FC } from "react";
import StatusBadge from "~/features/Project/Detail/components/StatusBadge";
import { deploymentStatusMap } from "~/features/Project/Detail/utils";

interface DeploymentStatusCellProps {
  status?: DeploymentStatus;
}

export const DeploymentStatusCell: FC<DeploymentStatusCellProps> = ({
  status,
}) => {
  if (!status) {
    return null;
  }

  const statusMap = deploymentStatusMap[status];

  return (
    <Flex alignItems="center">
      <StatusBadge themeColor={statusMap.themeColor} text={statusMap.text} />
    </Flex>
  );
};
