import { HStack, Input, Stack, Text, useToast } from "@chakra-ui/react";
import {
  ContainerResourcesSpecInput,
  RepoDetailFragment,
  UpdateProjectInput,
  useUpdateProjectSettingsMutation,
} from "@zeet/web-api/dist/graphql";
import { Tooltip, ZError } from "@zeet/web-ui";
import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { FormCard } from "../../FormCard";
import { shouldDisplayError } from "../util";
import { ResourcesForm } from "./Resources";

export const ProductionBranchSettingsBox: React.FC<{
  repo: RepoDetailFragment;
}> = ({ repo }) => {
  const toast = useToast();

  const [updateSettings, { error, loading, data }] =
    useUpdateProjectSettingsMutation({
      onCompleted: (data) => {
        if (data) {
          toast({
            title: "Branch Rules Saved",
            status: "success",
            duration: 2500,
            isClosable: true,
          });
        }
      },
    });

  const { getValues, register } = useForm<{
    productionBranch: string;
  }>({
    defaultValues: {
      productionBranch:
        repo.productionBranch === null ? undefined : repo.productionBranch,
    },
  });

  const onSubmit = useCallback(() => {
    const input: UpdateProjectInput = {
      id: repo.id,
      productionBranch: getValues("productionBranch").trim(),
    };

    updateSettings({
      variables: {
        input,
      },
    });
  }, [repo.id, updateSettings, getValues]);

  return (
    <FormCard
      isLoading={loading}
      onSubmit={onSubmit}
      title="Production Branch Settings"
    >
      <Stack spacing={2}>
        <Text fontSize="sm">
          Changing the production branch creates a new deployment for the
          selected branch without affecting the existing deployment. To
          deactivate the old deployment, use the branch view on the deployments
          page.
        </Text>
        <HStack>
          <Text fontWeight="bold">
            Production Branch
            <Tooltip
              text={`All settings, including those in the Performance and Replication sections, are applied to the production branch. The preview branches, on the other hand, uses the settings from the Preview section below and has their own networking endpoints.`}
            />
          </Text>
          <Input
            {...register("productionBranch")}
            defaultValue={repo.productionBranch || undefined}
            flex={1}
          />
        </HStack>
        {shouldDisplayError(error, data) && <ZError error={error} />}
      </Stack>
    </FormCard>
  );
};

export const BranchSettingsBox: React.FC<{
  repo: RepoDetailFragment;
}> = ({ repo }) => {
  const toast = useToast();

  const [updateSettings, { error, loading, data }] =
    useUpdateProjectSettingsMutation({
      onCompleted: (data) => {
        if (data) {
          toast({
            title: "Compute Resources Saved",
            status: "success",
            duration: 2500,
            isClosable: true,
          });
        }
      },
    });

  const [input, setInput] = useState<ContainerResourcesSpecInput>();

  const onSubmit = () => {
    updateSettings({
      variables: {
        input: {
          id: repo.id,
          branchResources: input,
        },
      },
    });
  };

  return (
    <FormCard
      isLoading={loading}
      onSubmit={onSubmit}
      title="Preview Branch Resources"
    >
      <ResourcesForm
        spec={repo?.branchResources}
        onChange={setInput}
        showSpot={true}
      />
      {shouldDisplayError(error, data) && <ZError mt={2} error={error} />}
    </FormCard>
  );
};
