import { useColorMode } from "@chakra-ui/react";
import { useRegisterActions } from "kbar";
import { useHistory } from "react-router";

const usePreferencesActions: () => void = () => {
  const { setColorMode } = useColorMode();
  const history = useHistory();

  useRegisterActions([
    {
      id: "profile",
      name: "Profile",
      section: "Preferences",
      perform: () => {
        history.push("/profile");
      },
    },
    {
      id: "members",
      name: "Team Members",
      section: "Preferences",
      perform: () => {
        history.push("/account/members");
      },
    },
    {
      id: "cloud",
      name: "Manage Cloud Providers",
      section: "Preferences",
      perform: () => {
        history.push("/account/cloud");
      },
    },
    {
      id: "cluster",
      name: "Manage Clusters",
      section: "Preferences",
      perform: () => {
        history.push("/account/clusters");
      },
    },
    {
      id: "billing",
      name: "Billing",
      section: "Preferences",
      perform: () => {
        history.push("/account/billing");
      },
    },
    {
      id: "theme",
      name: "Change theme…",
      keywords: "interface color dark light",
      section: "Preferences",
    },
    {
      id: "darkTheme",
      name: "Dark",
      keywords: "dark theme",
      section: "",
      perform: () => {
        setColorMode("dark");
      },
      parent: "theme",
    },
    {
      id: "lightTheme",
      name: "Light",
      keywords: "light theme",
      section: "",
      perform: () => {
        setColorMode("light");
      },
      parent: "theme",
    },
  ]);
};

export default usePreferencesActions;
