import { Flex } from "@chakra-ui/react";
import { Badge, theme, useColors } from "@zeet/web-ui";
import { FC } from "react";

interface BadgeCellProps {
  value: string;
  map: Record<string, { themeColor: theme.StatusColor; text: string }>;
}

export const BadgeCell: FC<BadgeCellProps> = ({ value, map }) => {
  const { status } = useColors();
  const themeColor = map[value]?.themeColor ?? "";

  return (
    <Flex alignItems="center">
      <Badge colorScheme={status[themeColor].badge || "brand"}>
        {map[value]?.text || value}
      </Badge>
    </Flex>
  );
};
