import {
  ProjectInfoFragment,
  useUpdateProjectMutation,
} from "@zeet/web-api/dist/graphqlv1";
import { useCallback } from "react";
import { UseFormReturn } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { useMutationError } from "~/hooks/useMutationError";
import { ProjectSettingsValues } from "../SettingsTab";

export const useSaveProjectSettings = ({
  methods,
  project,
}: {
  methods: UseFormReturn<ProjectSettingsValues, any, undefined>;
  project: ProjectInfoFragment;
}) => {
  const { buildUrl } = useNextProjectName();
  const history = useHistory();
  const { getFieldState, getValues } = methods;
  const error = "Failed to save project settings";
  const [updateProjectSettings, { loading }] = useUpdateProjectMutation(
    useMutationError(error)
  );

  const saveSettings = useCallback(async () => {
    if (getFieldState("project").isDirty) {
      await updateProjectSettings({
        variables: {
          id: project.id,
          input: {
            name: getValues("project.name"),
          },
        },
        onCompleted: (data) => {
          if (data) {
            history.replace(buildUrl(data.updateProject.name));
          }
        },
      });
    }
  }, [
    buildUrl,
    getFieldState,
    getValues,
    history,
    project.id,
    updateProjectSettings,
  ]);

  return {
    saveSettings,
    loading,
  };
};

const useNextProjectName = () => {
  const { teamName, projectName, envName } = useParams<{
    teamName: string;
    projectName: string;
    envName: string;
  }>();

  const buildUrl = useCallback(
    (name: string) => `/${teamName}/${projectName}/${envName}/${name}/settings`,
    [envName, projectName, teamName]
  );

  return {
    buildUrl,
  };
};
