import {
  ProjectInfoDocument,
  ProjectStatusOnlyDocument,
  useAbortWorkflowRunMutation,
  useApproveWorkflowRunStepMutation,
  useSubmitWorkflowRunMutation,
  WorkflowRunDetailDocument,
  WorkflowRunsDocument,
} from "@zeet/web-api/dist/graphqlv1";
import { useCurrentTeamUser } from "@zeet/web-ui";
import { useHistory } from "react-router-dom";
import { useMutationError } from "~/hooks/useMutationError";
import { useProjectPath } from "../hooks/useProjectPath";

export const useWorkflowOperations = () => {
  const currentTeamUser = useCurrentTeamUser();
  const history = useHistory();
  const projectPath = useProjectPath();

  const [abortWorkflowRun, { loading: abortLoading }] =
    useAbortWorkflowRunMutation(
      useMutationError("Failed to abort workflow run")
    );

  const [submitWorkflowRun, { loading: submitLoading }] =
    useSubmitWorkflowRunMutation({
      onCompleted(data) {
        if (!data) return;
        const newId = data.submitWorkflow.id;
        history.replace({
          pathname: `${projectPath}/workflows`,
          search: `?workflowId=${newId}`,
        });
      },
      ...useMutationError("Failed to trigger new workflow run"),
    });

  const [approveWorkflowRunStep, { loading: approveLoading }] =
    useApproveWorkflowRunStepMutation(
      useMutationError("Failed to approve workflow step")
    );

  return {
    abort(projectId: string, runId: string) {
      return abortWorkflowRun({
        variables: {
          id: runId,
        },
        refetchQueries: [
          {
            query: WorkflowRunDetailDocument,
            variables: { projectId, runId, teamId: currentTeamUser?.id },
          },
          {
            query: ProjectStatusOnlyDocument,
            variables: { projectId, teamId: currentTeamUser?.id },
          },
        ],
        awaitRefetchQueries: true,
      });
    },
    submitRun(projectId: string, workflowId: string) {
      return submitWorkflowRun({
        variables: {
          workflowId: workflowId,
        },
        refetchQueries: [
          {
            query: WorkflowRunsDocument,
            variables: {
              teamId: currentTeamUser?.id,
              projectId,
              page: { after: "0", first: 20 },
            },
          },
          {
            query: ProjectStatusOnlyDocument,
            variables: { projectId, teamId: currentTeamUser?.id },
          },
          {
            variables: {
              teamId: currentTeamUser?.id,
              projectId,
            },
            query: ProjectInfoDocument,
          },
        ],
        awaitRefetchQueries: true,
      });
    },
    approveWorkflowRunStep(projectId: string, stepId: string, runId: string) {
      return approveWorkflowRunStep({
        variables: {
          stepId: stepId,
          projectId,
        },
        refetchQueries: [
          {
            query: WorkflowRunDetailDocument,
            variables: { projectId, runId, teamId: currentTeamUser?.id },
          },
        ],
        awaitRefetchQueries: true,
      });
    },
    abortLoading,
    submitLoading,
    approveLoading,
  };
};
