import { Flex, FormControl, FormControlProps, Spacer } from "@chakra-ui/react";
import {
  CloudDetailsAwsQuery,
  CloudDetailsDoQuery,
  CloudDetailsGcpQuery,
  CloudDetailsLinodeQuery,
  CloudProvider,
  useCloudDetailsAwsQuery,
  useCloudDetailsDoQuery,
  useCloudDetailsGcpQuery,
  useCloudDetailsLinodeQuery,
} from "@zeet/web-api/dist/graphql";
import { TerraformTargetConfigurationInput } from "@zeet/web-api/dist/graphqlv1";
import {
  Button,
  Card,
  FormLabel,
  FormSelect,
  Skeleton,
  useColors,
  useCurrentTeamUser,
} from "@zeet/web-ui";
import { useMemo, useState } from "react";
import { Path, useFormContext } from "react-hook-form";
import CloudIcon from "~/components/Console/ConsoleClouds/View/CloudIcon";
import { regions } from "~/components/utils/cloud";
import { ProviderSelectorMenu } from "~/features/Project/New/ConfigureStep/Targets/ProviderSelectorMenu";
import {
  NewResourceValues,
  ProviderOption,
} from "~/features/Project/New/context";

interface CloudProviderInfoProps {
  config: TerraformTargetConfigurationInput;
  onProviderChange: (value: ProviderOption | null) => unknown;
  regionPath?: string;
  editDisabled?: boolean;
}

const getCloudProviderType = (
  config: TerraformTargetConfigurationInput
): CloudProvider => {
  if (config.provider?.awsAccountId) return CloudProvider.Aws;
  if (config.provider?.gcpAccountId) return CloudProvider.Gcp;
  if (config.provider?.doAccountId) return CloudProvider.Do;
  if (config.provider?.linodeAccountId) return CloudProvider.Linode;
  return CloudProvider.Unknown;
};

const getCloudProviderId = (
  config: TerraformTargetConfigurationInput
): string | undefined => {
  if (config.provider?.awsAccountId) return config.provider?.awsAccountId;
  if (config.provider?.gcpAccountId) return config.provider?.gcpAccountId;
  if (config.provider?.doAccountId) return config.provider?.doAccountId;
  if (config.provider?.linodeAccountId) return config.provider?.linodeAccountId;
  return undefined;
};

const makeInitialCloudProvider = (
  data?:
    | CloudDetailsAwsQuery
    | CloudDetailsGcpQuery
    | CloudDetailsDoQuery
    | CloudDetailsLinodeQuery
): ProviderOption | null => {
  if (data && "awsAccount" in data.user) {
    return {
      provider: CloudProvider.Aws,
      label:
        data?.user.awsAccount?.name || data?.user.awsAccount?.accountID || "",
      value: data?.user.awsAccount?.id ?? "",
      connected: data?.user.awsAccount?.connected ?? false,
    };
  }
  if (data && "gcpAccount" in data.user) {
    return {
      provider: CloudProvider.Gcp,
      label:
        data?.user.gcpAccount?.name || data.user.gcpAccount?.projectID || "",
      value: data?.user.gcpAccount?.id ?? "",
      connected: data?.user.gcpAccount?.connected ?? false,
    };
  }
  if (data && "doAccount" in data.user) {
    return {
      provider: CloudProvider.Do,
      label:
        data?.user.doAccount?.name ||
        data.user.doAccount?.accessTokenPrefix ||
        "",
      value: data?.user.doAccount?.id ?? "",
      connected: data?.user.doAccount?.connected ?? false,
    };
  }
  if (data && "linodeAccount" in data.user) {
    return {
      provider: CloudProvider.Linode,
      label:
        data?.user.linodeAccount?.name ||
        data.user.linodeAccount?.accessTokenPrefix ||
        "",
      value: data?.user.linodeAccount?.id ?? "",
      connected: data?.user.linodeAccount?.connected ?? false,
    };
  }
  return null;
};

export const CloudProviderInfo = ({
  config,
  onProviderChange,
  regionPath,
  editDisabled,
}: CloudProviderInfoProps) => {
  const providerType = getCloudProviderType(config);
  const accountId = getCloudProviderId(config);
  const { loading, data } = useCloudDetailsQuery(providerType, accountId);
  const [editing, setEditing] = useState(false);
  const initialCloudProvider = makeInitialCloudProvider(data);

  if (!accountId) return null;
  if (editing) {
    return (
      <Flex gap={2} mb={2}>
        <FormControl>
          <FormLabel>Provider</FormLabel>
          <ProviderSelectorMenu
            validCloudProviders={[providerType]}
            value={initialCloudProvider}
            onChange={onProviderChange}
          />
        </FormControl>
        <RegionSelector
          provider={providerType}
          path={regionPath as Path<NewResourceValues>}
        />
      </Flex>
    );
  }

  return (
    <CloudProviderDisplay
      loading={loading}
      cloudProvider={initialCloudProvider?.provider}
      name={initialCloudProvider?.label}
      id={accountId}
      onEdit={!editDisabled ? () => setEditing(true) : undefined}
    />
  );
};

const useCloudDetailsQuery = <
  TInput extends Record<"id" | "accountId", string>
>(
  providerType: CloudProvider,
  accountId?: string
) => {
  const queries = {
    [CloudProvider.Aws]: useCloudDetailsAwsQuery,
    [CloudProvider.Gcp]: useCloudDetailsGcpQuery,
    [CloudProvider.Do]: useCloudDetailsDoQuery,
    [CloudProvider.Linode]: useCloudDetailsLinodeQuery,
  };
  const { id } = useCurrentTeamUser();
  return queries[providerType]({
    variables: {
      id,
      accountId,
    } as TInput,
    skip: !accountId,
  });
};

interface RegionSelectorProps extends FormControlProps {
  provider: CloudProvider;
  path?: Path<NewResourceValues>;
}

const RegionSelector = ({ provider, path, ...rest }: RegionSelectorProps) => {
  const { bg } = useColors();
  const { register } = useFormContext<NewResourceValues>();
  const options = useMemo(
    () => regions[provider].map((o) => ({ label: o.name, value: o.name })),
    [provider]
  );

  return (
    <FormControl {...rest}>
      <FormLabel>Region</FormLabel>
      <FormSelect
        useBasicStyles
        chakraStyles={{
          container: (provided) => ({
            ...provided,
            backgroundColor: bg,
          }),
          menuList: (provided) => ({
            ...provided,
            boxShadow: "2xl",
          }),
        }}
        openMenuOnFocus
        captureMenuScroll={false}
        placeholder="Select region"
        options={options}
        {...register(path || "target.region", {
          required: "Region is required",
        })}
      />
    </FormControl>
  );
};

interface CloudProviderDisplayProps {
  loading: boolean;
  name?: string;
  cloudProvider?: CloudProvider;
  id?: string;
  onEdit?: () => unknown;
}

const CloudProviderDisplay = ({
  loading,
  name,
  id,
  cloudProvider,
  onEdit,
}: CloudProviderDisplayProps) => {
  const { login } = useCurrentTeamUser();
  return (
    <FormControl>
      <FormLabel>Provider</FormLabel>
      <Card px="4" py="2" as={Flex} flexDirection="row">
        {loading ? (
          <Skeleton height="24px" width="30%" />
        ) : (
          <CloudIcon cloudAccountKey={name} cloudProvider={cloudProvider} />
        )}
        <Spacer />
        {onEdit && (
          <Button
            variant="secondary"
            height="auto"
            py={2}
            mr={2}
            onClick={onEdit}
            data-testid="tf-cloud-edit"
          >
            Edit
          </Button>
        )}
        <Button
          asLink
          to={`/${login}/console/clouds/${cloudProvider?.toLowerCase()}/${id}`}
          variant="secondary"
          height="auto"
          py={2}
        >
          View
        </Button>
      </Card>
    </FormControl>
  );
};
