import { Box } from "@chakra-ui/react";
import { Skeleton } from "@zeet/web-ui";
import React from "react";

export const MetricsSkeleton: React.FC = () => {
  return (
    <Box>
      {Array.from(Array(5).keys()).map((element) => (
        <Skeleton
          key={element}
          height="550px"
          width="100%"
          borderRadius="6px"
          marginTop="1rem"
        />
      ))}
    </Box>
  );
};
