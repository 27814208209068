import { Box } from "@chakra-ui/react";
import { Skeleton } from "@zeet/web-ui";
import React from "react";

export const LogsSkeleton: React.FC = () => {
  return (
    <Box width="100%" marginTop="1rem">
      <Skeleton height="2.5rem" borderRadius="6px" />
      <Skeleton height="500px" marginTop="1rem" borderRadius="6px" />
    </Box>
  );
};
