import { Flex, Heading, useToast } from "@chakra-ui/react";
import {
  RepoDetailFragment,
  useDeployRepoBranchMutation,
  useUserDeploymentQuery,
} from "@zeet/web-api/dist/graphql";
import { Button } from "@zeet/web-ui";
import React from "react";
import { useHistory } from "react-router-dom";
import { TabWrapper } from ".";
import { DeploymentDetailView } from "./Deployment";
import { repoPath } from "./util";

const OverviewTabInner: React.FC<{ repo: RepoDetailFragment }> = ({ repo }) => {
  const toast = useToast();
  const history = useHistory();

  const { data: deploymentData } = useUserDeploymentQuery({
    skip: !repo?.productionDeployment?.id,
    variables: {
      id: repo?.productionDeployment?.id || "",
    },
    errorPolicy: "all",
    pollInterval: 3000,
  });

  const [deployRepoBranch] = useDeployRepoBranchMutation({
    onCompleted: () => {
      toast({
        title: "Redeploy Without Build in Progress",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  return (
    <>
      {repo?.productionDeployment ? (
        <DeploymentDetailView
          repo={repo}
          deployment={deploymentData?.currentUser.deployment}
        />
      ) : (
        <Flex flexDirection="column" height="100%" mt="6">
          <Flex
            justifyContent="center"
            alignItems="center"
            flexDir={"column"}
            gap="6"
          >
            <Heading size="md" textAlign="center" fontWeight="700">
              No production deployment found
            </Heading>
            <Flex gap="2">
              <Button
                colorScheme="brand"
                _hover={{ textDecoration: "none" }}
                onClick={() => {
                  if (repo?.enabled) {
                    deployRepoBranch({
                      variables: {
                        input: {
                          id: repo.id,
                          branch: repo.productionBranch as string,
                        },
                      },
                    });
                  } else {
                    history.push(`/${repoPath(repo)}/settings/danger`);
                  }
                }}
              >
                Deploy Now
              </Button>
              <Button
                asLink
                to={`/${repoPath(repo)}/settings`}
                colorScheme="brand"
                _hover={{ textDecoration: "none" }}
              >
                Check Settings
              </Button>
            </Flex>
          </Flex>
        </Flex>
      )}
    </>
  );
};

export const OverviewTab = () => {
  return <TabWrapper component={OverviewTabInner} />;
};
