import { TabPanel } from "@chakra-ui/react";
import { Source, SourceKind } from "..";
import { BitbucketSource } from "./BitBucket";
import { DockerHubSource } from "./DockerHub";
import { GitHubSource } from "./GitHub";
import { GitLabSource } from "./GitLab";
import { HelmRepositorySource } from "./HelmRepository";
import { PrivateContainerRegistrySource } from "./PrivateContainerRegistry";
import { PublicGitRepoSource } from "./PublicGitRepo";
import { TerraformModuleSource } from "./TerraformModule";

export interface SourceTabPanelProps {
  sourceKind: SourceKind;
  currentSource?: Source;
  onSourceChange: (source: Source) => void;
}

const SourceComponent = (props: SourceTabPanelProps) => {
  const { sourceKind } = props;

  switch (sourceKind) {
    case SourceKind.GitHub:
      return <GitHubSource {...props} />;
    case SourceKind.GitLab:
      return <GitLabSource {...props} />;
    case SourceKind.Bitbucket:
      return <BitbucketSource {...props} />;
    case SourceKind.PublicGitRepo:
      return <PublicGitRepoSource {...props} />;
    case SourceKind.PrivateContainerRegistry:
      return <PrivateContainerRegistrySource {...props} />;
    case SourceKind.TerraformModule:
      return <TerraformModuleSource {...props} />;
    case SourceKind.HelmRepository:
      return <HelmRepositorySource {...props} />;
    case SourceKind.DockerHub:
      return <DockerHubSource {...props} />;
    default:
      return null;
  }
};

export const SourceTabPanel = (props: SourceTabPanelProps) => {
  const { sourceKind } = props;

  return (
    <TabPanel p="0" pb="4" pt="1">
      {sourceKind && <SourceComponent {...props} />}
    </TabPanel>
  );
};
