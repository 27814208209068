import { Flex, FormControl } from "@chakra-ui/react";
import {
  useRemoveVultrAccountMutation,
  VultrAccount,
} from "@zeet/web-api/dist/graphql";
import { CopyCode, FormLabel } from "@zeet/web-ui";
import { SettingsDisplayName } from "~/components/Layouts/Settings/SettingsDisplayName";
import {
  SettingsSection,
  SettingsSectionDetails,
  SettingsSections,
} from "../../../../Layouts/Settings";
import { YesNoCell } from "../../../../ListViewV2";
import { CloudDangerZone } from "./CloudDangerZone";

interface VultrDetailsProps {
  vultrAccount: VultrAccount;
  refetch: () => void;
}

export const VultrDetails: React.FC<VultrDetailsProps> = ({
  vultrAccount,
  refetch,
}) => {
  return (
    <SettingsSections>
      <SettingsSection borderTop="none">
        <SettingsSectionDetails title="General" />
        <Flex gap="6" flexDirection="column" overflow="hidden">
          <SettingsDisplayName />
          <FormControl>
            <FormLabel>Connected</FormLabel>
            <YesNoCell isYes={vultrAccount.connected ?? false} />
          </FormControl>
          <FormControl>
            <FormLabel>Zeet Cloud ID</FormLabel>
            <CopyCode value={vultrAccount?.id ?? ""} width="100%" />
          </FormControl>
        </Flex>
      </SettingsSection>
      <SettingsSection mt={12}>
        <SettingsSectionDetails title="Danger zone" />

        <CloudDangerZone
          id={vultrAccount.id}
          confirmationString={vultrAccount.id}
          useRemoveCloudAccountMutation={useRemoveVultrAccountMutation}
          refetch={refetch}
        />
      </SettingsSection>
    </SettingsSections>
  );
};
