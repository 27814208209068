import { useQueryParams } from "@zeet/web-ui";
import { BlueprintQueryParams } from "~/features/Project/New/redirect";

export const useClusterParams = () => {
  const query = useQueryParams();

  return {
    repositoryUrl: query.get(BlueprintQueryParams.HelmRepository) ?? "",
    chart: query.get(BlueprintQueryParams.HelmChart) ?? "",
    version: query.get(BlueprintQueryParams.HelmVersion) ?? "",
    releaseName: query.get(BlueprintQueryParams.HelmReleaseName) ?? "",
    namespace: query.get(BlueprintQueryParams.HelmNamespace) ?? "",
    values: decodeURIComponent(
      query.get(BlueprintQueryParams.HelmValues) ?? ""
    ),
  };
};
