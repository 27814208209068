import {
  Button,
  Divider,
  Flex,
  Heading,
  Input,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import {
  RepoDetailFragment,
  useUpdateProjectSettingsMutation,
} from "@zeet/web-api/dist/graphql";
import { Card, useColors, ZError } from "@zeet/web-ui";
import React, { useEffect, useState } from "react";
import { CloudCostEstimate } from "~/components/CloudCost";
import { shouldDisplayError } from "../util";

export const CloudRunResourceInfo: React.FC<{
  repo: RepoDetailFragment;
}> = ({ repo }) => {
  // Cloud Run memory default value
  const [containerMemory, updateMemory] = useState(
    repo.serverlessResources?.memory ? repo.serverlessResources?.memory : 0.512
  );
  // Cloud Run CPU cores
  const [containerCPU, updateCPU] = useState(
    repo.serverlessResources?.cpu ? repo.serverlessResources?.cpu : 1
  );
  const [_, updateCost] = useState(
    // for Tier 1 GCP regions
    // cost of GiB memory       + cost of CPU per second
    containerMemory * 0.0000025 + containerCPU * 0.000024
  );

  useEffect(() => {
    updateCost(containerMemory * 0.0000025 + containerCPU * 0.000024);
  }, [containerMemory, containerCPU, updateCost]);

  const [changed, setChanged] = useState(false);
  const { bg } = useColors();
  const toast = useToast();

  const [updateProjectSettingsMutation, { loading, error, data }] =
    useUpdateProjectSettingsMutation({
      onCompleted: (data) => {
        setChanged(false);
        if (data) {
          toast({
            title: "Cloud Run Settings Saved",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      },
    });

  return (
    <Card>
      <Flex flexDir="column" px={6} pt={6}>
        <Heading mb={3} size="md">
          <Flex justifyContent="space-between">
            <Text>{"Serverless Resources"}</Text>
          </Flex>
        </Heading>

        <Text fontSize="sm" mb={3}>
          {
            "You can configure how much Memory and CPU resources your GCP Cloud Run container runs with. Increasing this will allow you to run more complex\
            applications on GCP without bottlenecks. Note that Cloud Run are event-driven, and you only pay based on incoming requests."
          }
        </Text>
        <Stack spacing={2}>
          <Flex alignItems="center">
            <Text whiteSpace="nowrap" fontWeight="bold">
              General Resources
            </Text>
          </Flex>
          <Stack isInline spacing={[2, 2, 4]}>
            <Flex flex={1} alignItems="center">
              <Text mr={4} whiteSpace="nowrap">
                Memory (GB)
              </Text>
              <Input
                flexGrow={1}
                name="memory"
                className="nice-digits"
                defaultValue={containerMemory}
                onChange={(e) => {
                  setChanged(true);
                  updateMemory(Number(e.target.value));
                }}
              />
            </Flex>
            <Flex flex={1} alignItems="center">
              <Text mr={4} whiteSpace="nowrap">
                CPU Cores
              </Text>
              <Input
                flexGrow={1}
                name="cpu"
                className="nice-digits"
                defaultValue={containerCPU}
                onChange={(e) => {
                  setChanged(true);
                  updateCPU(Number(e.target.value));
                }}
              />
            </Flex>
          </Stack>
          {shouldDisplayError(error, data) && <ZError error={error} />}
        </Stack>
      </Flex>
      <br />
      <Divider />
      <Flex bg={bg} as="footer" py={3} px={6} align="center">
        <Flex mt={0} alignItems="center">
          <CloudCostEstimate />
        </Flex>
        <Button
          type="submit"
          isDisabled={!changed}
          colorScheme="brand"
          px={6}
          size="sm"
          ml="auto"
          isLoading={loading}
          onClick={() => {
            updateProjectSettingsMutation({
              variables: {
                input: {
                  id: repo.id,
                  serverlessResources: {
                    memory: containerMemory,
                    cpu: containerCPU,
                  },
                },
              },
            });
          }}
        >
          {"Save"}
        </Button>
      </Flex>
    </Card>
  );
};
