import { Text, useDisclosure } from "@chakra-ui/react";
import { ActionProps } from ".";
import { DeleteAction } from "../DeleteAction";
import { DeleteDialog } from "../DeleteDialog";

export const DestroyResourcesAction = ({ onAction, name }: ActionProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <DeleteAction
      title="Destroy cloud resources only"
      message="Destroy all cloud resources for this Project, but keep the Project and configuration in Zeet. This cannot be undone."
      action="Destroy Resources"
      onAction={onOpen}
    >
      <DeleteDialog
        onClose={onClose}
        onDelete={onAction}
        isLoading={false}
        isOpen={isOpen}
        type="cloud resources"
        title="Destroy Cloud Resources"
        action={{ pastTense: "destroyed", presentTense: "destroy" }}
        actionMessage="Destroy cloud resources for this Project"
        warningMessage={
          <Text>
            This will attempt to permanently destroy the cloud resources related
            to the{" "}
            <Text fontWeight={"bold"} as="span">
              {name}
            </Text>{" "}
            Project. This action cannot be undone.
          </Text>
        }
        confirmationString={name}
      />
    </DeleteAction>
  );
};
