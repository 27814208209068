import { Box, Tabs, Text } from "@chakra-ui/react";
import {
  AwsAccount,
  AzureAccount,
  CloudProvider,
  CoreWeaveAccount,
  DoAccount,
  GcpAccount,
  LinodeAccount,
  VultrAccount,
} from "@zeet/web-api/dist/graphql";
import {
  AWSLogo,
  Container,
  CoreWeaveLogo,
  DigitalOceanLogo,
  GCPLogo,
  Link,
  LinodeLogo,
  Loading,
  useCurrentTeamUser,
  ZError,
} from "@zeet/web-ui";
import { ReactNode } from "react";
import { SiMicrosoftazure, SiVultr } from "react-icons/si"; // cspell:disable-line
import { Redirect, useParams } from "react-router-dom";
import {
  formatCloudNameLabel,
  getAwsLabelParams,
  getAzureLabelParams,
  getCoreweaveLabelParams,
  getDoLabelParams,
  getGcpLabelParams,
  getLinodeLabelParams,
  getVultrLabelParams,
} from "~/components/Cloud/name";
import { DetailHeader } from "~/components/Layouts/DetailHeader";
import { NavTabPanels, NavTabType } from "~/components/Layouts/NavTab";
import { useCurrentTabIndex } from "~/components/Layouts/useCurrentTabIndex";
import { KeysOfUnion, ValuesOfUnion } from "~/utils/ts-utils";
import { SettingsNameFormFields } from "../../../Layouts/Settings";
import { queries, queryResults, supportedCloudProviders } from "./queries";
import RevisionsTab from "./RevisionsTab";
import { AwsDetails } from "./SettingsTab/AwsDetails";
import { AzureDetails } from "./SettingsTab/AzureDetails";
import { CloudName } from "./SettingsTab/CloudName";
import { CoreWeaveDetails } from "./SettingsTab/CoreWeaveDetails";
import { DoDetails } from "./SettingsTab/DoDetails";
import { Form } from "./SettingsTab/Form";
import { GcpDetails } from "./SettingsTab/GcpDetails";
import { LinodeDetails } from "./SettingsTab/LinodeDetails";
import { VultrDetails } from "./SettingsTab/VultrDetails";
import { cloudGuideMap } from "./utils";

export const CloudProviderRoutes: Record<supportedCloudProviders, string> = {
  [CloudProvider.Aws]: CloudProvider.Aws.toLowerCase(),
  [CloudProvider.Gcp]: CloudProvider.Gcp.toLowerCase(),
  [CloudProvider.Do]: CloudProvider.Do.toLowerCase(),
  [CloudProvider.Coreweave]: CloudProvider.Coreweave.toLowerCase(),
  [CloudProvider.Linode]: CloudProvider.Linode.toLowerCase(),
  [CloudProvider.Vultr]: CloudProvider.Vultr.toLowerCase(),
  [CloudProvider.Azure]: CloudProvider.Azure.toLowerCase(),
};

export const CloudProviderLogos: Record<supportedCloudProviders, ReactNode> = {
  [CloudProvider.Aws]: <AWSLogo height="24px" />,
  [CloudProvider.Gcp]: <GCPLogo height="24px" />,
  [CloudProvider.Do]: <DigitalOceanLogo height="24px" />,
  [CloudProvider.Coreweave]: <CoreWeaveLogo height="24px" />,
  [CloudProvider.Linode]: <LinodeLogo height="24px" />,
  [CloudProvider.Vultr]: <SiVultr size={24} />,
  [CloudProvider.Azure]: <SiMicrosoftazure size={24} />, // cspell:disable-line
};

const renderCloudComponent = ({
  cloudProvider,
  cloudAccount,
  refetch,
}: {
  cloudProvider: supportedCloudProviders;
  cloudAccount?: cloudValues;
  refetch: () => void;
}) => {
  if (!cloudAccount) {
    return null;
  }

  switch (cloudProvider) {
    case CloudProvider.Aws:
      return (
        <AwsDetails awsAccount={cloudAccount as AwsAccount} refetch={refetch} />
      );
    case CloudProvider.Gcp:
      return (
        <GcpDetails gcpAccount={cloudAccount as GcpAccount} refetch={refetch} />
      );
    case CloudProvider.Do:
      return (
        <DoDetails doAccount={cloudAccount as DoAccount} refetch={refetch} />
      );
    case CloudProvider.Coreweave:
      return (
        <CoreWeaveDetails
          coreWeaveAccount={cloudAccount as CoreWeaveAccount}
          refetch={refetch}
        />
      );
    case CloudProvider.Linode:
      return (
        <LinodeDetails
          linodeAccount={cloudAccount as LinodeAccount}
          refetch={refetch}
        />
      );
    case CloudProvider.Vultr:
      return (
        <VultrDetails
          vultrAccount={cloudAccount as VultrAccount}
          refetch={refetch}
        />
      );
    case CloudProvider.Azure:
      return (
        <AzureDetails
          azureAccount={cloudAccount as AzureAccount}
          refetch={refetch}
        />
      );
    default:
      return null;
  }
};

const getFormName = ({
  cloudProvider,
  cloudAccount,
}: {
  cloudProvider: supportedCloudProviders;
  cloudAccount: cloudValues;
}) => {
  switch (cloudProvider) {
    case CloudProvider.Aws:
      return formatCloudNameLabel(
        getAwsLabelParams(cloudAccount as AwsAccount)
      );
    case CloudProvider.Gcp:
      return formatCloudNameLabel(
        getGcpLabelParams(cloudAccount as GcpAccount)
      );
    case CloudProvider.Do:
      return formatCloudNameLabel(getDoLabelParams(cloudAccount as DoAccount));
    case CloudProvider.Coreweave:
      return formatCloudNameLabel(
        getCoreweaveLabelParams(cloudAccount as CoreWeaveAccount)
      );
    case CloudProvider.Linode:
      return formatCloudNameLabel(
        getLinodeLabelParams(cloudAccount as LinodeAccount)
      );
    case CloudProvider.Vultr:
      return formatCloudNameLabel(
        getVultrLabelParams(cloudAccount as VultrAccount)
      );
    case CloudProvider.Azure:
      return formatCloudNameLabel(
        getAzureLabelParams(cloudAccount as AzureAccount)
      );
    default:
      return null;
  }
};

type cloudKeys = Exclude<
  KeysOfUnion<queryResults["user"]>,
  "id" | "__typename"
>;
type cloudValues = Exclude<
  ValuesOfUnion<queryResults["user"]>,
  string | undefined | null
>;

const userCloudAccountKeyByProvider: Partial<{
  [key in CloudProvider]: cloudKeys;
}> = {
  [CloudProvider.Aws]: "awsAccount",
  [CloudProvider.Gcp]: "gcpAccount",
  [CloudProvider.Do]: "doAccount",
  [CloudProvider.Coreweave]: "coreweaveAccount",
  [CloudProvider.Linode]: "linodeAccount",
  [CloudProvider.Vultr]: "vultrAccount",
  [CloudProvider.Azure]: "azureAccount",
};

const queryNoop = () => ({});

export const CloudDetails: React.FC = () => {
  const currentTeamUser = useCurrentTeamUser();
  const path = `/${currentTeamUser.login}/console/clouds`;
  const { cloudId, cloudProvider: cloudProviderLowerCase } = useParams<{
    cloudId: string;
    cloudProvider: CloudProvider;
  }>();
  const cloudProvider =
    cloudProviderLowerCase.toUpperCase() as supportedCloudProviders;

  const query: (typeof queries)[supportedCloudProviders] =
    queries[cloudProvider] ?? queryNoop;

  const { data, refetch, loading } = query({
    id: currentTeamUser.id,
    cloudId,
  });

  const dataKey = userCloudAccountKeyByProvider[cloudProvider];
  const cloudAccount: cloudValues | undefined = data?.user?.[dataKey ?? ""];

  const cloudComponent = renderCloudComponent({
    cloudProvider,
    cloudAccount,
    refetch,
  });

  const tabs: NavTabType[] = [
    {
      label: "Settings",
      to: `${path}/${cloudProviderLowerCase}/${cloudId}`,
      component: () => <Box>{cloudComponent}</Box>,
      testId: "cloud-settings-tab",
    },
    {
      label: "Revisions",
      to: `${path}/${cloudProviderLowerCase}/${cloudId}/revisions`,
      component: () => <RevisionsTab cloudId={cloudId} />,
      testId: "cloud-revisions-tab",
    },
  ];

  const currentTabIndex = useCurrentTabIndex(tabs);

  if (loading) {
    return <Loading />;
  }

  const redirect = <Redirect to={`${path}/view`} />;

  if (!cloudAccount) {
    return redirect;
  }

  return cloudComponent ? (
    <Tabs
      index={currentTabIndex}
      colorScheme="success"
      isLazy
      variant="line"
      width="100%"
      height="100%"
    >
      <Form
        cloudId={cloudAccount.id}
        cloudProvider={cloudProvider as CloudProvider}
        defaultValues={{
          [SettingsNameFormFields.NAME]:
            getFormName({
              cloudAccount,
              cloudProvider,
            }) || "",
        }}
        refetch={refetch}
      >
        {!cloudAccount.connected && (
          <ZError borderRadius="none">
            <Text>
              This cloud is not connected. Please refer to this
              <Link
                display="inline-block"
                to={cloudGuideMap[cloudProvider as CloudProvider]}
                textDecoration="underline"
                mx="1"
              >
                guide
              </Link>
              to troubleshoot your connection. You may need to delete this
              connection and create a new one.
            </Text>
          </ZError>
        )}

        <DetailHeader
          title={
            <CloudName
              logo={CloudProviderLogos[cloudProvider as CloudProvider]}
            />
          }
          fullWidth
          tabs={tabs}
        />

        <Container fullWidth>
          <NavTabPanels tabs={tabs} />
        </Container>
      </Form>
    </Tabs>
  ) : (
    redirect
  );
};
