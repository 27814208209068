import {
  BridgeBlueprintInput,
  BridgeBlueprintInputId,
  buildTemplates,
} from "@zeet/web-ui";
import { useEffect } from "react";
import { Path, useFormContext } from "react-hook-form";
import { NewResourceValues } from "../../context";
import GroupInput from "./GroupInput";

interface BuildTemplateInputProps<T> {
  input: BridgeBlueprintInput;
  pathMap: (value: any) => Path<T>;
}

const BuildTemplateInput: React.FC<
  BuildTemplateInputProps<NewResourceValues>
> = ({ input, pathMap }) => {
  const { watch, resetField, getValues } = useFormContext<NewResourceValues>();

  const buildTemplate =
    (watch(`variables.${BridgeBlueprintInputId.BuildTemplate}`) as string) ??
    "";

  const getBuildTemplateVariables = (
    input: BridgeBlueprintInput
  ): BridgeBlueprintInput => {
    return {
      ...input,
      variables: [
        ...(input.variables ?? []),
        ...(buildTemplates[buildTemplate] ?? []),
      ],
    };
  };

  useEffect(() => {
    [
      BridgeBlueprintInputId.BuildCommand,
      BridgeBlueprintInputId.DockerfilePath,
      BridgeBlueprintInputId.GoVersion,
      BridgeBlueprintInputId.NodeVersion,
      BridgeBlueprintInputId.PythonVersion,
      BridgeBlueprintInputId.RunCommand,
      BridgeBlueprintInputId.BuildOutputDirectory,
      BridgeBlueprintInputId.BuildRootDirectory,
    ].forEach((id) => resetField(`variables.${id}`, { defaultValue: "" }));

    buildTemplates[buildTemplate]?.forEach((input) => {
      resetField(`variables.${input.id}`, { defaultValue: input.default });
    });
  }, [buildTemplate, resetField]);

  if (getValues("source.containerRegistry")) {
    return null;
  }

  return (
    <GroupInput pathMap={pathMap} input={getBuildTemplateVariables(input)} />
  );
};

export default BuildTemplateInput;
