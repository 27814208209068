import { UserGithubReposQuery } from "@zeet/web-api/dist/graphql";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ArrayElement } from "~/utils/ts-utils";
import { useGithubOrg } from "./useGithubOrg";

export type GithubRepo = ArrayElement<
  ArrayElement<
    UserGithubReposQuery["currentUser"]["githubInstallations"]
  >["repositories"]
>;

export function useOrgGithubRepos(filter?: string): GithubRepo[] {
  const { activeOrgWithType } = useGithubOrg();

  const repositories = useMemo(
    () => activeOrgWithType?.installation?.repositories || [],
    [activeOrgWithType?.installation?.repositories]
  );

  return useMemo(() => {
    const repos = repositories
      ?.slice()
      .sort(
        (a, b) =>
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
      );

    if (filter && Array.isArray(repos)) {
      return repos.filter(
        (repo) =>
          repo.name.indexOf(filter) > -1 || repo.owner.indexOf(filter) > -1
      );
    }

    return repos || [];
  }, [repositories, filter]);
}

export function usePaginatedOrgGithubRepos(
  filter?: string,
  pageSize = 5
): [
  GithubRepo[],
  {
    currentPage: number;
    totalPages: number;
    setCurrentPage(page: number): void;
    setPageByRepo(owner: string, name: string): void;
  }
] {
  const [currentPage, _setCurrentPage] = useState<number>(0);

  const filteredOrgRepos = useOrgGithubRepos(filter);

  const totalPages = useMemo(() => {
    return Math.ceil(filteredOrgRepos.length / pageSize);
  }, [filteredOrgRepos, pageSize]);

  //keep everything in bounds
  useEffect(() => {
    _setCurrentPage(Math.max(0, Math.min(totalPages - 1, currentPage)));
  }, [totalPages, currentPage]);

  const setCurrentPage = useCallback(
    (newPage: number) => {
      _setCurrentPage(Math.max(0, Math.min(totalPages - 1, newPage)));
    },
    [totalPages]
  );

  const setPageByRepo = useCallback(
    (owner: string, name: string) => {
      const repoIndex = filteredOrgRepos.findIndex(
        (r) => r.owner === owner && r.name === name
      );

      if (repoIndex === -1) return;

      // which page is that on?
      const targetPage = Math.floor(repoIndex / pageSize);
      setCurrentPage(targetPage);
    },
    [filteredOrgRepos, setCurrentPage, pageSize]
  );

  const pageRepos = useMemo(() => {
    const startPoint = currentPage * pageSize;
    const endPoint = startPoint + pageSize;
    return filteredOrgRepos.slice(startPoint, endPoint);
  }, [currentPage, pageSize, filteredOrgRepos]);

  return [
    pageRepos,
    { currentPage: currentPage, setCurrentPage, totalPages, setPageByRepo },
  ];
}
