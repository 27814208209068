import { Box, BoxProps } from "@chakra-ui/react";
import { ReactNode } from "react";
import { IconButtonProps } from "../IconButton";
import { Copy, CopyProps, CopyStyleProps, DEFAULT_VARIANT } from "./Copy";
import { useCopyDisplayItemStyles } from "./hooks";

interface CopyTextProps extends CopyStyleProps {
  children?: string | ReactNode;
  linkProps?: Partial<BoxProps>;
  text: string;
  variant?: CopyProps["variant"];
  boxProps?: BoxProps;
  iconButtonProps?: Partial<IconButtonProps>;
}

export const CopyText: React.FC<CopyTextProps> = ({
  children,
  text,
  boxProps = {},
  variant = DEFAULT_VARIANT,
  ...rest
}) => {
  const copyDisplayItemStyles = useCopyDisplayItemStyles(variant);

  return (
    <Copy {...rest} text={text} variant={variant}>
      <Box {...copyDisplayItemStyles} {...boxProps}>
        {children || text}
      </Box>
    </Copy>
  );
};
