import { Tooltip as CTooltip, FormControl, Icon } from "@chakra-ui/react";
import { FormLabel, Tooltip } from "@zeet/web-ui";
import React from "react";
import {
  Controller,
  ControllerRenderProps,
  FieldValues,
  Path,
  useFormContext,
} from "react-hook-form";
import { MdLock } from "react-icons/md";
import { useProjectSettings } from "../context/ProjectSettingsProvider";
import { useInputContext } from "./InputProvider";

export interface InputProps<TFormData> {
  name?: string;
  tooltip?: string;
  disabled?: boolean;
  path: Path<TFormData>;
  enabledInDraftOnly?: boolean;
  draftTooltip?: string;
}

interface InputContainerProps<TFormData extends FieldValues>
  extends InputProps<TFormData> {
  render(
    props: ControllerRenderProps<TFormData, Path<TFormData>> & {
      isReadOnly: boolean;
      "data-testid": string;
    }
  ): React.ReactElement;
}

export const InputContainer = <TFormData extends FieldValues>({
  name,
  path,
  tooltip,
  disabled,
  enabledInDraftOnly,
  draftTooltip,
  render,
}: InputContainerProps<TFormData>) => {
  const { isDraft } = useProjectSettings();
  const { resolvePath } = useInputContext();
  const { control } = useFormContext<TFormData>();
  const resolvedPath = resolvePath(path) as Path<TFormData>;

  const isReadOnly = disabled || (!!enabledInDraftOnly && !isDraft);

  const showDraftTooltip = enabledInDraftOnly && !isDraft;
  const showTooltip = tooltip && !enabledInDraftOnly && isDraft;

  const defaultDraftTooltip =
    "Can only be changed in draft mode. Destroy resources of this project to edit.";

  return (
    <CTooltip
      label={draftTooltip || defaultDraftTooltip}
      isDisabled={!showDraftTooltip}
      placement="top-start"
      openDelay={750}
    >
      <FormControl>
        {name && (
          <FormLabel alignItems="center" display="flex" gap="1">
            {showDraftTooltip && <Icon as={MdLock} />}
            {name}
            {showTooltip && <Tooltip text={tooltip} />}
          </FormLabel>
        )}
        <Controller
          control={control}
          name={resolvedPath}
          render={(p) =>
            render({ ...p.field, isReadOnly, "data-testid": resolvedPath })
          }
        />
      </FormControl>
    </CTooltip>
  );
};
