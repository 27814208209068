import { PageInput } from "@zeet/web-api/dist/graphqlv1";
import { useCallback, useMemo, useState } from "react";

const DefaultPageSize = 20;

/**
 * Hook to help with apollo pagination for queries that support PageInput.
 *
 * @example
 *   const { pageInput, nextPage } = usePagination();
 *   const { data } = useMyPaginatedQuery({
 *      variables: {
 *          ...,
 *          page: pageInput
 *      }
 *   });
 *
 *   return <>
 *      <List data={data}/>
 *      <Button onClick={nextPage}>Next</Button>
 *   </>
 * @param pageSize The size of each page, defaults to 20
 * @returns The current page, a @see PageInput to send to a GQL query, and functions to set the page, advance the page, and reset to the beginning
 */
export const usePagination = (pageSize: number = DefaultPageSize) => {
  const [after, setAfter] = useState(0);

  // 1-indexed so the first page is "Page 1"
  const setPage = useCallback(
    (page: number) => {
      setAfter((page - 1) * pageSize);
    },
    [pageSize]
  );

  const nextPage = useCallback(() => {
    setAfter(after + pageSize);
  }, [after, pageSize]);

  const reset = useCallback(() => setAfter(0), []);

  const pageInput = useMemo<PageInput>(
    () => ({
      first: pageSize,
      after: after.toString(),
    }),
    [pageSize, after]
  );

  return {
    currentPage: after / pageSize + 1,
    pageInput,
    nextPage,
    setPage,
    reset,
  };
};
