import { Text } from "@chakra-ui/react";
import {
  DatabaseProviderType,
  DeployTarget,
  RepoDetailFragment,
} from "@zeet/web-api/dist/graphql";
import { Flag, useFeatureFlag } from "@zeet/web-ui";
import React from "react";
import { useParams } from "react-router-dom";
import { TabWrapper } from ".";
import { isKube, isServerless } from "../utils/project";
import { AwsRdsMetrics } from "./Metrics/AwsRds";
import { CloudFunctionsMetrics } from "./Metrics/CloudFunctionMetrics";
import { GcpCloudRunMetrics } from "./Metrics/GcpCloudRun";
import { KubernetesMetricsWrapper } from "./Metrics/KubernetesMetrics";
import { KubernetesMetricsWrapperLegacy } from "./Metrics/KubernetesMetricsLegacy";
import { LambdaMetrics } from "./Metrics/LambdaMetrics";

export const MetricsTab = () => {
  return <TabWrapper component={Metrics} />;
};

const Metrics: React.FC<{
  repo: RepoDetailFragment;
}> = ({ repo }) => {
  const { subTab: deploymentID } = useParams<{
    subTab: string;
  }>();

  const useMetricTabV2 = useFeatureFlag(Flag.MetricsTabV2, {
    devEnabled: true,
  });

  if (repo.databaseProvider === DatabaseProviderType.AwsRds) {
    return <AwsRdsMetrics repo={repo} deploymentID={deploymentID} />;
  }

  if (isServerless(repo)) {
    if (repo.awsAccount?.id) {
      return <LambdaMetrics repo={repo} deploymentID={deploymentID} />;
    } else if (repo.deployTarget === DeployTarget.GcpCloudRun) {
      return <GcpCloudRunMetrics repo={repo} deploymentID={deploymentID} />;
    } else {
      return <CloudFunctionsMetrics repo={repo} deploymentID={deploymentID} />;
    }
  } else if (isKube(repo)) {
    if (useMetricTabV2) {
      return (
        <KubernetesMetricsWrapper repo={repo} deploymentID={deploymentID} />
      );
    }

    return (
      <KubernetesMetricsWrapperLegacy repo={repo} deploymentID={deploymentID} />
    );
  }

  return <Text>No Metrics Available</Text>;
};
