import {
  Box,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { TableButton } from "../../../ListViewV2/TableButton";
import { repoPath } from "../../../Project/util";
import { IReposList } from "../../../types/clouds-view";

export const CoreweaveRepoList: React.FC<IReposList> = ({ repos }) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  return (
    <Box>
      <Box>
        {repos && repos.length > 0 ? (
          <Box
            onClick={() => {
              onOpen();
            }}
            _hover={{ cursor: "pointer" }}
          >
            <TableButton
              text={repos.length.toString()}
              showLinkIcon={repos.length > 0}
            />
          </Box>
        ) : (
          "0"
        )}
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} size="md" isCentered>
        <ModalOverlay />
        <ModalContent mt="-10%">
          <ModalCloseButton />
          <ModalHeader>Resources</ModalHeader>
          <ModalBody>
            <Box paddingBottom="16px">
              {repos &&
                repos.map((r, index) => (
                  <Link
                    isExternal
                    key={index}
                    href={`/${repoPath(r)}`}
                    textDecoration="underline"
                  >
                    <Text>{r.name}</Text>
                  </Link>
                ))}
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
