import { Link } from "@chakra-ui/react";
import React from "react";
import { LinkProps, NavLink } from "react-router-dom";
import { useColors } from "..";

export const SideLink: React.FC<LinkProps & { to: string }> = ({
  children,
  ...restProps
}) => {
  const { activeBg: active, activeBorder } = useColors();
  return (
    <Link
      as={NavLink}
      exact
      width="100%"
      padding="0.25rem 1rem"
      rounded="md"
      fontSize="14px"
      fontWeight="500"
      whiteSpace="nowrap"
      border="1px solid transparent"
      _active={{ bg: "transparent" }}
      _hover={{
        textDecoration: "none",
        opacity: "1",
      }}
      activeStyle={{
        backgroundColor: active,
        border: `1px solid ${activeBorder}`,
        opacity: 1,
      }}
      opacity={0.4}
      {...restProps}
    >
      {children}
    </Link>
  );
};
