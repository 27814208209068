import { CheckIcon, ExternalLinkIcon, LockIcon } from "@chakra-ui/icons";
import { Box, Button, PropsOf, Text } from "@chakra-ui/react";
import { Link, useTrack } from "@zeet/web-ui";
import { formatDistance } from "date-fns";
import React from "react";
import { LanguageIcon } from "../LanguageIcon";
import { GithubRepo } from "./useGithubRepo";

interface RepoItemProps {
  repo: GithubRepo;
  now?: Date;
  onSelect?: (isActive: boolean) => void;
  isActive?: boolean;
  showOwner?: true;
  buttonText: string;
  isLoading: boolean;
  as?: PropsOf<typeof Box>["as"];
}

export const RepoItem: React.FC<RepoItemProps> = ({
  repo,
  now = new Date(),
  onSelect,
  isActive,
  as,
  showOwner,
  buttonText,
  isLoading,
}) => {
  const { track } = useTrack();

  const realRepoUrl = repo.url
    .replace("https://api.", "https://")
    .replace("/repos", "");
  return (
    <Box
      py=".5em"
      display="flex"
      flexDirection="row"
      alignItems="center"
      as={as}
      w="100%"
    >
      <LanguageIcon
        flexShrink={0}
        language={repo.primaryLanguage}
        w="1.5em"
        h="1.5em"
      />{" "}
      <Link
        isTruncated
        mr="1"
        to={realRepoUrl}
        isExternal
        display="flex"
        flexDirection="row"
        alignItems="center"
        ml={3}
        onClick={() => {
          track("click_repo_url", {
            owner: repo.owner,
            name: repo.name,
            url: realRepoUrl,
          });
        }}
      >
        <Text display="flex" mr={1}>
          {showOwner ? `${repo.owner}/` : ""}
          {repo.name}
        </Text>
        <ExternalLinkIcon flexShrink={0} />
      </Link>
      <Text
        display="flex"
        flexDirection="row"
        alignItems="center"
        flexShrink={0}
        fontSize="sm"
        color="gray.500"
        mr={2}
        whiteSpace="nowrap"
      >
        {repo.isPrivate && (
          <>
            |<LockIcon mx={1} />
          </>
        )}
        |{" "}
        {formatDistance(now, new Date(repo.updatedAt))
          .replace("almost ", "~")
          .replace("about ", "~")
          .replace("over ", ">")
          .replace("less than ", "~")}{" "}
        ago
      </Text>
      {onSelect && (
        <Button
          flexShrink={0}
          onClick={() => onSelect(!!isActive)}
          size="sm"
          ml="auto"
          colorScheme={isActive ? "brand" : "gray"}
          rightIcon={isActive ? <CheckIcon /> : undefined}
          isLoading={isLoading}
        >
          {buttonText}
        </Button>
      )}
    </Box>
  );
};
