import { FormControl, FormErrorMessage } from "@chakra-ui/react";
import { GitSourceInput } from "@zeet/web-api/dist/graphqlv1";
import { FormLabel, Input, useColors } from "@zeet/web-ui";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { parseGitUri } from "~/utils/parsers";
import { SourceTabPanelProps } from ".";
import { GitBranch } from "../GitBranch";
import { GitWorkingDirectory } from "../GitWorkingDirectory";
import { makePublicGitSource } from "./utils";

export const PublicGitRepoSource = ({
  onSourceChange,
  currentSource,
}: SourceTabPanelProps) => {
  const { bg } = useColors();
  const {
    formState: { errors },
    register,
    watch,
  } = useForm({
    defaultValues: {
      repository: currentSource?.git?.repository,
    },
  });
  const { repository } = watch();
  const ref = currentSource?.git?.ref ?? "";
  const path = currentSource?.git?.path ?? "";

  useEffect(() => {
    if (repository) {
      const gitSource: GitSourceInput = {
        ...currentSource?.git,
        repository,
      };
      onSourceChange(makePublicGitSource(gitSource));
    }
  }, [currentSource, onSourceChange, repository]);

  const onRefChange = (ref: string) => {
    const gitSource: GitSourceInput = {
      ...currentSource?.git,
      repository: currentSource?.git?.repository ?? "",
      ref,
    };
    onSourceChange(makePublicGitSource(gitSource));
  };

  const onPathChange = (path: string) => {
    const gitSource: GitSourceInput = {
      ...currentSource?.git,
      repository: currentSource?.git?.repository ?? "",
      path,
    };
    onSourceChange(makePublicGitSource(gitSource));
  };

  return (
    <>
      <FormControl mt="4" isInvalid={!!errors.repository?.message} isRequired>
        <FormLabel>URL to public git repo</FormLabel>
        <Input
          autoFocus
          bg={bg}
          _placeholder={{ opacity: 0.5 }}
          placeholder="https://github.com/zeet-demo/node-express-demo.git"
          {...register("repository", {
            required: "Git repository is required",
            validate: (value) => {
              if (!parseGitUri(value).repo) {
                return "Invalid git repository";
              }
            },
          })}
        />
        <FormErrorMessage>{errors.repository?.message}</FormErrorMessage>
      </FormControl>
      <GitWorkingDirectory value={path} onChange={onPathChange} />
      <GitBranch value={ref} onChange={onRefChange} />
    </>
  );
};
