import { usePosthog } from "@zeet/web-ui";
import queryString from "query-string";
import React, { useEffect } from "react";

export const ReferralTracker: React.FC = () => {
  const posthog = usePosthog();

  useEffect(() => {
    if (!posthog) {
      return;
    }
    try {
      const { pathname, search } = window.__zeet_data__;

      //handle `/r/` and `/a/` pathnames
      let referrer: string | null = null;
      let affiliate: string | null = null;
      if (pathname?.startsWith("/r/")) {
        referrer = pathname.replace("/", "").split("/")[1] ?? "";
      } else if (pathname?.startsWith("/a/")) {
        affiliate = pathname.replace("/", "").split("/")[1] ?? "";
      }

      const { r, a } = queryString.parse(search ?? "");
      affiliate = (Array.isArray(a) ? a[0] : a) || affiliate;
      referrer = (Array.isArray(r) ? r[0] : r) || referrer;

      if (referrer) {
        posthog.capture("zeet_referrer", {
          referrer,
        });
        posthog.people.set_once({
          referrer,
        });
      }
      if (affiliate) {
        posthog.capture("zeet_affiliate", {
          affiliate,
        });
        posthog.people.set_once({
          affiliate,
        });
      }
    } catch (err) {
      console.error("failed to record referrer / affiliate", err);
    }
  }, [posthog]);

  return null;
};
