import { Badge as CBadge } from "@chakra-ui/react";
import { PropsOf } from "@emotion/react";
import React from "react";
import { useColors } from "../hooks/useColors";

export type BadgeProps = PropsOf<typeof CBadge>;

export const Badge: React.FC<PropsOf<typeof CBadge>> = ({
  children,
  colorScheme,
  ...restProps
}) => {
  const { bg, brand } = useColors();

  const brandProps =
    colorScheme === "brand"
      ? {
          bg: brand,
          color: bg,
        }
      : {};

  return (
    <CBadge
      alignContent="center"
      colorScheme={colorScheme}
      {...brandProps}
      {...restProps}
    >
      {children}
    </CBadge>
  );
};
