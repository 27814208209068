"use client";

import {
  Alert,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Checkbox,
  FormControl,
  Text,
  useToast,
} from "@chakra-ui/react";
import capitalize from "lodash/capitalize";
import React, { FC, useRef, useState } from "react";
import { Input } from "..";

interface DeleteDialogProps extends React.PropsWithChildren {
  cantDeleteMessage?: React.ReactNode;
  confirmationString?: string;
  onClose: () => void;
  onDelete: () => Promise<unknown>;
  onFail?: (e: Error) => unknown;
  onSuccess?: () => unknown;
  isLoading: boolean;
  isOpen: boolean;
  type: string;
  warningMessage?: React.ReactNode;
  title?: string;
  action?: {
    pastTense: string;
    presentTense: string;
  };
  confirmationCheck?: string;
  actionMessage?: string;
  noToast?: boolean;
  colorScheme?: string;
}

export const DeleteDialog: FC<DeleteDialogProps> = ({
  cantDeleteMessage,
  confirmationString,
  onClose,
  onDelete,
  onSuccess,
  onFail,
  isLoading = false,
  isOpen,
  type,
  warningMessage,
  title,
  action,
  confirmationCheck,
  actionMessage,
  noToast,
  colorScheme,
  children,
}) => {
  const toast = useToast();
  const cancelRef = useRef(null);
  const [checkConfirmed, setCheckConfirmed] = useState(false);
  const [userConfirmationString, setUserConfirmationString] = useState("");

  const deleteFn = async () => {
    try {
      await onDelete();
      if (!noToast) {
        toast({
          title: `${capitalize(type)} ${
            action?.pastTense ?? "deleted"
          } successfully`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
      onSuccess && onSuccess();
    } catch (e) {
      if (!noToast) {
        toast({
          title: `Failed to ${action?.presentTense ?? "delete"} ${type}`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
      onFail && onFail(e as Error);
    }
  };

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      preserveScrollBarGap
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            <Text data-testid="delete-modal-header-text">
              {`${
                title
                  ? title
                  : `${capitalize(
                      action?.presentTense ?? "Delete"
                    )} ${capitalize(type)}`
              }`}
            </Text>
          </AlertDialogHeader>

          {cantDeleteMessage ? (
            <Alert status="error" mb="4" px="6" fontSize="sm">
              {cantDeleteMessage}
            </Alert>
          ) : (
            <>
              <Alert status="error" mb="4" px="6" fontSize="sm">
                {warningMessage ?? "This action cannot be undone."}
              </Alert>
              <AlertDialogBody>
                {confirmationString && (
                  <>
                    <Text mb="2" fontSize="sm">
                      Please type
                      <Text
                        as="b"
                        data-testid="delete-modal-confirmation-string"
                      >
                        {" "}
                        {confirmationString}{" "}
                      </Text>
                      to confirm.
                    </Text>
                    <FormControl>
                      <Input
                        data-testid="delete-confirmation-input"
                        value={userConfirmationString}
                        onChange={(e) =>
                          setUserConfirmationString(e.target.value)
                        }
                      />
                    </FormControl>
                  </>
                )}
                {confirmationCheck && (
                  <Checkbox
                    onChange={(e) => setCheckConfirmed(e.target.checked)}
                    checked={checkConfirmed}
                    mt={4}
                  >
                    {confirmationCheck}
                  </Checkbox>
                )}
                {children}
              </AlertDialogBody>
            </>
          )}

          <AlertDialogFooter>
            <Button variant="secondary" ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button
              data-testid="confirm-delete-button"
              colorScheme={colorScheme ?? "red"}
              isDisabled={
                !!cantDeleteMessage ||
                (!!confirmationString &&
                  userConfirmationString !== confirmationString) ||
                (!!confirmationCheck && !checkConfirmed)
              }
              isLoading={isLoading}
              onClick={() => {
                onClose();
                deleteFn();
              }}
              ml={3}
            >
              {actionMessage || "Delete"}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
