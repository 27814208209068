import {
  Flex,
  FlexProps,
  Grid,
  GridItem,
  Heading,
  Text,
  useColorMode,
} from "@chakra-ui/react";

interface SettingsSectionsProps {
  children: React.ReactNode;
}

export const SettingsSections: React.FC<SettingsSectionsProps & FlexProps> = ({
  children,
  ...flexRest
}) => (
  <Flex flexDirection="column" gap={6} {...flexRest}>
    {children}
  </Flex>
);

interface SettingsSectionProps {
  children: React.ReactNode;
}

export const SettingsSection: React.FC<SettingsSectionProps & FlexProps> = ({
  children,
  ...flexRest
}) => (
  <Flex
    borderTop="1px"
    borderColor="var(--chakra-colors-chakra-border-color)"
    {...flexRest}
  >
    <Grid
      width="100%"
      gap={4}
      gridTemplateColumns={["1fr", "1fr", "1fr 1.75fr"]}
      sx={{
        "> *": {
          paddingTop: "24px",
        },
      }}
    >
      {children}
    </Grid>
  </Flex>
);

interface SettingsSectionDetailsProps {
  title: string;
  description?: string;
}

export const SettingsSectionDetails: React.FC<SettingsSectionDetailsProps> = ({
  title,
  description,
}) => {
  const { colorMode } = useColorMode();

  return (
    <GridItem>
      <Heading size="md" position="sticky" top="0">
        {title}
      </Heading>

      <Text
        mt="2"
        fontSize="14px"
        lineHeight="21px"
        color={colorMode === "dark" ? "gray.400" : "gray.600"}
      >
        {description}
      </Text>
    </GridItem>
  );
};
