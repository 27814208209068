import { AddIcon, ExternalLinkIcon, SettingsIcon } from "@chakra-ui/icons";
import {
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import {
  Avatar,
  ThemeSwitcher,
  useAuthActions,
  useCurrentTeamUser,
  useCurrentUser,
  useTrack,
  Link as ZLink,
} from "@zeet/web-ui";
import React from "react";
import { BiSupport } from "react-icons/bi";
import { Link } from "react-router-dom";

export const ProfileButton: React.FC = () => {
  const { track } = useTrack();

  const currentUser = useCurrentUser();
  const teamUser = useCurrentTeamUser();
  const { logout } = useAuthActions();
  const currentTeamUser = useCurrentTeamUser();

  const { toggleColorMode } = useColorMode();
  const isMobile = useBreakpointValue({ base: true, md: false });

  if (!currentUser) {
    return null;
  }

  return (
    <>
      <Menu placement="bottom-end">
        <MenuButton
          as={Avatar}
          onClick={() => track("click_nav_menu")}
          user={currentUser}
          cursor="pointer"
          size="sm"
        />
        <MenuList>
          <MenuItem
            as={Link}
            to={`/${currentTeamUser?.login}/console/home`}
            onClick={() => track("click_nav_menu_dashboard")}
          >
            Dashboard
          </MenuItem>
          <MenuDivider />
          <MenuItem
            as={Link}
            to={`/profile`}
            onClick={() => track("click_nav_menu_new_team")}
          >
            <Text>Profile</Text>
          </MenuItem>
          <MenuItem
            as={Link}
            to="/team-onboard"
            onClick={() => track("click_nav_menu_new_team")}
          >
            <Text>New Team</Text>
            <IconButton
              pointerEvents="none"
              ml="auto"
              bg=""
              size="xs"
              aria-label=""
              icon={<AddIcon />}
            />
          </MenuItem>
          {currentUser?.hasZeetCloud && !teamUser.isTeam && (
            <MenuItem
              as={Link}
              to={`/billing?user=${currentUser.id}`}
              onClick={() => track("click_nav_menu_billing")}
              target="_blank"
              rel="noreferrer"
            >
              Billing Settings
              <IconButton
                pointerEvents="none"
                ml="auto"
                bg=""
                size="xs"
                aria-label=""
                icon={<ExternalLinkIcon />}
              />
            </MenuItem>
          )}
          {teamUser?.isTeam && (
            <MenuItem
              as={Link}
              to={`/${teamUser.login}/account/profile`}
              onClick={() => track("click_nav_menu_team")}
            >
              Team Settings
              <IconButton
                pointerEvents="none"
                ml="auto"
                bg=""
                size="xs"
                aria-label=""
                icon={<SettingsIcon />}
              />
            </MenuItem>
          )}
          {isMobile && (
            <>
              <MenuItem
                onClick={() => {
                  window.$crisp.push(["do", "chat:open"]);
                  track("click_nav_menu_support");
                }}
              >
                <Text>Support</Text>
                <IconButton
                  pointerEvents="none"
                  ml="auto"
                  bg=""
                  size="xs"
                  aria-label=""
                  icon={<BiSupport />}
                />
              </MenuItem>
              <MenuItem
                as={ZLink}
                to={`https://docs.zeet.co`}
                onClick={() => track("click_nav_menu_docs")}
                isExternal
              >
                <Text>Docs</Text>
                <IconButton
                  pointerEvents="none"
                  ml="auto"
                  bg=""
                  size="xs"
                  aria-label=""
                  icon={<ExternalLinkIcon />}
                />
              </MenuItem>
            </>
          )}
          <MenuDivider />
          <MenuItem
            as={Box}
            _active={{ pointerEvents: "none" }}
            onClick={() => toggleColorMode()}
          >
            Theme
            <ThemeSwitcher ml="auto" />
          </MenuItem>
          <MenuDivider />
          <MenuItem
            onClick={() => {
              track("click_nav_logout");
              logout();
            }}
          >
            Logout
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
};
