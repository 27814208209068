import { Flex, FormControl, Spacer, Text } from "@chakra-ui/react";
import { useClusterNameQuery } from "@zeet/web-api/dist/graphql";
import { DeploymentConfiguration } from "@zeet/web-api/dist/graphqlv1";
import {
  Button,
  Card,
  FormLabel,
  Skeleton,
  useCurrentTeamUser,
} from "@zeet/web-ui";
import { useEffect, useState } from "react";
import { FieldValues, useFormContext } from "react-hook-form";
import { CloudProviderSelector } from "~/components/Cloud/CloudProviderSelector";
import { ProviderLogo } from "~/components/Cloud/ProviderLogo";
import { useProjectV3Context } from "~/components/Project/ProjectV3Provider";
import { ProviderOption } from "~/features/Project/New/context";
import { isDeletable } from "../../utils";
import { InputContainer, InputProps } from "./InputContainer";

interface CloudProviderDisplayProps {
  loading: boolean;
  onEdit?: () => unknown;
  provider?: Partial<ProviderOption>;
}

const ClusterProviderDisplay = ({
  loading,
  provider,
  onEdit,
}: CloudProviderDisplayProps) => {
  const { login } = useCurrentTeamUser();
  return (
    <FormControl>
      <FormLabel>Cluster</FormLabel>
      <Card px="4" py="2" as={Flex} flexDirection="row">
        {loading || !provider ? (
          <Skeleton height="24px" width="30%" />
        ) : (
          <Flex gap="1" alignItems="center">
            <ProviderLogo option={provider} />
            <Text>{provider.label}</Text>
          </Flex>
        )}
        <Spacer />
        {onEdit && (
          <Button
            variant="secondary"
            height="auto"
            py={2}
            mr={2}
            onClick={onEdit}
            data-testid="tf-cloud-edit"
          >
            Edit
          </Button>
        )}
        <Button
          asLink
          to={`/${login}/console/clusters/${provider?.value}`}
          variant="secondary"
          height="auto"
          py={2}
        >
          View
        </Button>
      </Card>
    </FormControl>
  );
};

export const ClusterIdInput = <TFormData extends FieldValues>(
  props: InputProps<TFormData>
) => {
  const currentTeamUser = useCurrentTeamUser();
  const [isEditing, setIsEditing] = useState(false);
  const { projectStatus, projectInfoLoading } = useProjectV3Context();
  const {
    getValues,
    formState: { submitCount, isSubmitting },
  } = useFormContext<{
    configuration: DeploymentConfiguration;
  }>();

  useEffect(() => {
    if (submitCount > 0 && !isSubmitting) {
      setIsEditing(false);
    }
  }, [submitCount, isSubmitting]);

  const values = getValues();

  const clusterId =
    values?.configuration?.helm?.target?.clusterId ||
    values?.configuration?.kubernetes?.target?.clusterId;

  const { data } = useClusterNameQuery({
    variables: { teamId: currentTeamUser.id, clusterId: clusterId ?? "" },
    skip: !clusterId,
  });

  const isConfigurable = isDeletable(projectStatus?.team?.project?.status);

  if (!isEditing) {
    return (
      <ClusterProviderDisplay
        provider={{
          provider: data?.user?.cluster?.cloudProvider ?? undefined,
          label: data?.user?.cluster?.name,
          value: data?.user?.cluster?.id,
          kubernetes: true,
        }}
        loading={projectInfoLoading ?? false}
        onEdit={isConfigurable ? () => setIsEditing(true) : undefined}
      />
    );
  }

  return (
    <Flex gap="2" alignItems="flex-end" mb="2.5">
      <InputContainer
        {...props}
        render={({ ref: _ref, ...p }) => (
          <CloudProviderSelector showClusters={true} {...p} />
        )}
      />
      <Button
        variant="secondary"
        width="max-content"
        onClick={() => setIsEditing(false)}
      >
        Cancel
      </Button>
    </Flex>
  );
};
