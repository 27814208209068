import { Flex } from "@chakra-ui/react";
import { Link, useColors } from "@zeet/web-ui";
import { PropsWithChildren } from "react";

interface SidebarItemProps {
  isActive: boolean;
  to: string;
}

export const SidebarItem = ({
  isActive,
  children,
  to,
}: SidebarItemProps & PropsWithChildren) => {
  const { activeBg, activeBorder } = useColors();
  return (
    <Flex
      alignItems="center"
      fontSize="14px"
      px="2"
      py={2}
      ml={-2}
      _hover={{
        opacity: 1,
        textDecoration: "none",
      }}
      borderRadius="lg"
      as={Link}
      to={to}
      {...(isActive
        ? {
            opacity: 1,
            fontWeight: 700,
            background: activeBg,
            border: `1px solid ${activeBorder}`,
          }
        : {
            opacity: 0.5,
            fontWeight: 700,
            border: `1px solid transparent`,
          })}
    >
      {children}
    </Flex>
  );
};
