import { Grid } from "@chakra-ui/react";
import {
  ResourceType,
  useObservabilityConfigQuery,
  useProjectClustersQuery,
} from "@zeet/web-api/dist/graphqlv1";
import {
  CenterLoading,
  useCurrentTeamUser,
  useQueryParams,
} from "@zeet/web-ui";
import { useHistory } from "react-router-dom";
import { EmptyState } from "~/components/ListViewV2";
import { isSampleProject } from "~/features/Project/New/makeCreateSampleProjectInput";
import { ProjectDetailTabProps } from "..";
import { MetricNotEnabled } from "./MetricNotEnabled";
import { ResourceClusterList } from "./ResourceClusterList";
import { ResourceDetailPage } from "./ResourceDetailPage";
import { ResourceTypeSideBar } from "./ResourceTypeSideBar";

export const ResourcesTab = ({ project }: ProjectDetailTabProps) => {
  const projectId = project.id;
  const history = useHistory();
  const query = useQueryParams();
  const { id: teamId, login: teamLogin } = useCurrentTeamUser();
  const { loading: clustersLoading, data: clustersData } =
    useProjectClustersQuery({
      variables: {
        projectId,
        teamId,
      },
    });
  const {
    data: configData,
    loading: configLoading,
    refetch: refetchConfig,
  } = useObservabilityConfigQuery({
    variables: {
      projectId,
      teamId,
    },
    fetchPolicy: "cache-and-network",
  });
  const selector = {
    ...(configData?.team?.project?.observabilityConfig?.selector ?? {}),
    type: ResourceType.Container,
  };
  const clusters = clustersData?.team?.project?.clusters;

  if (clustersLoading || configLoading) {
    return <CenterLoading />;
  }

  if (!clusters?.length) {
    return (
      <MetricNotEnabled onButtonClick={() => handleClick(clusters?.[0]?.id)} />
    );
  }

  const resourceName = query.get("resource-name");
  const containerName = query.get("container-name");
  const clusterId = query.get("cluster-id");

  if (resourceName && containerName && clusterId) {
    const cluster = clusters.find((c) => c.id === clusterId);
    if (!cluster) {
      return (
        <MetricNotEnabled
          onButtonClick={() => handleClick(clusters?.[0]?.id)}
        />
      );
    }

    return (
      <ResourceDetailPage
        selector={{
          ...selector,
          type: ResourceType.Container,
          resourceName,
          containerName,
        }}
        projectId={projectId}
        cluster={cluster}
      />
    );
  }

  const handleClick = (clusterId?: string) => {
    history.push(`/${teamLogin}/console/clusters/${clusterId ?? ""}`);
  };

  if (isSampleProject(project.name)) {
    return <EmptyState heading="No resources available for this project" />;
  }

  return (
    <Grid
      height="100%"
      width="100%"
      templateColumns="280px 1fr"
      templateRows="auto"
    >
      <ResourceTypeSideBar />
      <ResourceClusterList
        projectId={projectId}
        clusters={clusters}
        config={configData?.team?.project?.observabilityConfig}
        refetchConfig={refetchConfig}
      />
    </Grid>
  );
};
