import { Flex, Heading, Icon } from "@chakra-ui/react";
import { useColors } from "@zeet/web-ui";
import { MdFolder } from "react-icons/md";

export const GroupTitle = ({ name }) => {
  const { fg } = useColors();
  return (
    <Flex align="center" gap="2">
      <Icon as={MdFolder} color={fg} boxSize={8} opacity={0.5} />
      <Heading size="lg" fontWeight={700}>
        {name}
      </Heading>
    </Flex>
  );
};
