import { Box, Flex, Text } from "@chakra-ui/react";
import { CloudResourceFragment } from "@zeet/web-api/dist/graphqlv1";
import { CenterLoading, DetailItem, DetailLabel } from "@zeet/web-ui";
import { ResourceTypeBadge } from "./ResourceTypeBadge";

interface ResourceControlledByInfoProps {
  cloudResource?: CloudResourceFragment;
}

export const ResourceControlledByInfo: React.FC<
  ResourceControlledByInfoProps
> = ({ cloudResource }) => {
  if (!cloudResource) {
    return <CenterLoading />;
  }

  if (!cloudResource?.owners?.length) {
    return null;
  }

  return (
    <DetailItem>
      <DetailLabel>Controlled by</DetailLabel>
      <Flex direction="column">
        {cloudResource?.owners?.map((r) => (
          <Box mb="1">
            <ResourceTypeBadge
              fontSize="14px"
              height="22px"
              resourceType={r.type}
            />
            <Text fontSize="14px">{r.name}</Text>
          </Box>
        ))}
      </Flex>
    </DetailItem>
  );
};
