import { BadgeProps, Spinner } from "@chakra-ui/react";
import {
  DeploymentCommonFragment,
  DeploymentStatus,
  RepoCommonFragment,
} from "@zeet/web-api/dist/graphql";
import React from "react";
import { Badge, repoColorMap as colorMap } from "..";

export function deploymentStatusOverview(
  repo: RepoCommonFragment,
  deployment?: DeploymentCommonFragment | null
): string {
  if (!repo.enabled) {
    return "paused";
  }

  return deploymentStatus(repo, deployment);
}

const INACTIVE = "INACTIVE";

export function deploymentStatus(
  repo: RepoCommonFragment,
  deployment?: DeploymentCommonFragment | null
): string {
  let state: string = deployment?.status || INACTIVE;
  if (
    state === DeploymentStatus.DeploySucceeded ||
    state === DeploymentStatus.DeployInProgress
  ) {
    if (deployment?.deployStatus?.state) {
      state = deployment?.deployStatus?.state;
    } else if (!deployment?.deployStatus?.active) {
      state = INACTIVE;
    }

    if (
      deployment?.status === DeploymentStatus.DeployInProgress &&
      state === INACTIVE
    ) {
      // show in progress if deployment is inactive during deployment
      state = DeploymentStatus.DeployInProgress;
    }
  }

  if (
    !repo.deployService &&
    deployment?.status === DeploymentStatus.DeployStopped
  ) {
    state = DeploymentStatus.BuildSucceeded;
  }

  return state;
}

export const statusTextMap = {
  [DeploymentStatus.BuildPending]: "Build Pending",
  [DeploymentStatus.BuildInProgress]: "Building",
  [DeploymentStatus.BuildFailed]: "Build Failed",
  [DeploymentStatus.BuildSucceeded]: "Build Succeeded",
  [DeploymentStatus.BuildAborted]: "Build Aborted",
  [DeploymentStatus.DeployPending]: "Deploy Pending",
  [DeploymentStatus.DeployInProgress]: "Deploying",
  [DeploymentStatus.ReleaseInProgress]: "Releasing",
  [DeploymentStatus.DeployFailed]: "Deploy Failed",
  [DeploymentStatus.DeploySucceeded]: "Deployed",
  [DeploymentStatus.DeployHealhty]: "Deploy Healthy", // cspell:disable-line
  [DeploymentStatus.DeployCrashing]: "Error",
  [DeploymentStatus.DeployStopped]: "Deploy Stopped",
  DEPLOYING: "Deploying",
};

const iconMap = {
  [DeploymentStatus.BuildPending]: <Spinner ml={1} size="sm" />,
  [DeploymentStatus.BuildInProgress]: <Spinner ml={1} size="sm" />,
  [DeploymentStatus.DeployPending]: <Spinner ml={1} size="sm" />,
  [DeploymentStatus.DeployInProgress]: <Spinner ml={1} size="sm" />,
  [DeploymentStatus.ReleaseInProgress]: <Spinner ml={1} size="sm" />,
  DEPLOYING: <Spinner ml={1} size="sm" />,
  STARTING: <Spinner ml={1} size="sm" />,
};

export interface StatusBadgeProps {
  status: string;
  spinner?: boolean;
  noBadge?: boolean;
}

export const StatusBadge: React.FC<StatusBadgeProps & BadgeProps> = ({
  status: inputStatus,
  spinner = true,
  noBadge,
  ...props
}) => {
  const status = inputStatus.toUpperCase();

  if (noBadge) {
    return (
      <>
        {statusTextMap[status] || status} {spinner && iconMap[status]}
      </>
    );
  }

  return (
    <Badge
      colorScheme={colorMap[status] || "brand"}
      display="inline-flex"
      alignItems="center"
      {...props}
    >
      {statusTextMap[status] || status} {spinner && iconMap[status]}
    </Badge>
  );
};

export function deploymentIsActive(
  deployment: DeploymentCommonFragment
): boolean {
  return (
    deployment.deployStatus?.active ||
    deployment.status === DeploymentStatus.BuildPending ||
    deployment.status === DeploymentStatus.BuildInProgress ||
    deployment.status === DeploymentStatus.DeployInProgress
  );
}
