import { ButtonGroup, IconButton, Text } from "@chakra-ui/react";
import { Card, Input, useColors } from "@zeet/web-ui";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { isMacOs } from "./util";

interface SearchBarProps {
  total: number;
  currentIndex: number;
  nextMatch(): void;
  prevMatch(): void;
  setQuery(q: string): void;
  setSearching?(searching: boolean): unknown;
}

export const SearchBar = ({
  currentIndex,
  nextMatch,
  prevMatch,
  setQuery,
  setSearching,
  total,
}: SearchBarProps) => {
  const { bg } = useColors();

  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key.toLowerCase() === "enter" || e.key.toLowerCase() === "return") {
      if (e.shiftKey) {
        prevMatch();
      } else {
        nextMatch();
      }

      return;
    }

    if (!(isMacOs() ? e.metaKey : e.ctrlKey)) return;
    if (e.key.toLowerCase() !== "f") return;

    e.preventDefault();
    if (setSearching) setSearching(false);
  };

  const resultNumber = currentIndex === null ? "?" : currentIndex + 1;

  return (
    <Card
      position="absolute"
      top={0}
      bg={bg}
      right={8}
      zIndex={5}
      borderTop="none"
      borderTopLeftRadius={0}
      borderTopRadius={0}
      p={2}
      display="flex"
      alignItems="center"
      onKeyDown={onKeyDown}
    >
      <Input
        placeholder="Find..."
        py={1}
        height="auto"
        border="1px solid var(--chakra-colors-chakra-border-color)"
        autoFocus
        onInput={(e) => setQuery((e.target as HTMLInputElement).value)}
      />
      <Text whiteSpace="nowrap" fontSize="xs" mx={2} minWidth="55px">
        {total === 0 ? "No results" : `${resultNumber} of ${total}`}
      </Text>
      <ButtonGroup size="sm" isAttached variant="outline" ml={1}>
        <IconButton
          aria-label="Previous Result"
          icon={<BiChevronUp />}
          onClick={prevMatch}
        />
        <IconButton
          aria-label="Next Result"
          icon={<BiChevronDown />}
          onClick={nextMatch}
        />
      </ButtonGroup>
    </Card>
  );
};
