import {
  AspectRatio,
  BoxProps,
  Flex,
  Heading,
  Img,
  SimpleGrid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link } from "@zeet/web-ui";
import React from "react";
import { Logo } from "../../AdmiralHome/index";

interface CustomerLogoProps extends BoxProps {
  logoUrl: string;
  title: string;
  href: string;
  isDark?: true;
  isLight?: true;
  filterBrightness?: string;
}

const CustomerLogo: React.FC<CustomerLogoProps> = ({
  logoUrl,
  title,
  href,
  isDark,
  isLight,
  filterBrightness,
  ...restProps
}) => {
  const invert = useColorModeValue(isLight || false, isDark || false);
  return (
    <Flex
      {...restProps}
      opacity={0.9}
      _hover={{ opacity: 1 }}
      alignItems="center"
      filterBrightness={filterBrightness}
    >
      <AspectRatio ratio={5} w="100%">
        <Link isExternal to={href}>
          <Img
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
            src={logoUrl}
            alt={title}
            filter={`grayscale(1)${invert ? " invert(1)" : ""}`}
          />
        </Link>
      </AspectRatio>
    </Flex>
  );
};

export const CustomerLogoSection: React.FC<{
  spacing?: number;
  width?: string;
}> = ({ spacing = 2, width = "180px" }) => {
  return (
    <Flex my={8} flexDirection="column" align="center" as="section">
      <Heading
        size="md"
        textTransform="uppercase"
        letterSpacing="2px"
        textAlign="center"
        fontWeight={500}
      >
        Trusted by DevOps and Developers at 3000+ teams including
      </Heading>
      <SimpleGrid
        mt={12}
        placeItems="center"
        columns={[2, 2, 3]}
        w="100%"
        spacingY="60px"
        spacingX="60px"
      >
        <Flex justifyContent="center" alignItems="center" width="100%">
          <CustomerLogo
            isLight
            w={width}
            px={spacing}
            ml="12px"
            logoUrl="/images/customer-logos/otherside.png"
            title="Otherside"
            href="https://www.hyperwriteai.com/"
          />
        </Flex>

        <CustomerLogo
          w={width}
          px={spacing}
          isLight
          logoUrl="/images/customer-logos/finc_logo.png"
          title="Founders, Inc"
          href="https://f.inc/"
        />

        <CustomerLogo
          isLight
          w={width}
          px={spacing}
          logoUrl="/images/customer-logos/livekit3.svg"
          title="LiveKit"
          href="https://livekit.io/"
          filter="brightness(0.7)"
        />

        <Flex justifyContent="center" alignItems="center" width="100%">
          <Logo
            isLight
            isGray
            w="130px"
            logoUrl="/images/customer-logos/paperxyz_logo.svg"
            aspectRatio={4}
            title="Paper"
            href="https://paper.xyz/"
            hoverColor="unset"
            filter="brightness(0.7)"
            text={
              <Text fontWeight="bold" fontFamily="mono" fontSize="1.6rem">
                Paper
              </Text>
            }
          />
        </Flex>

        <CustomerLogo
          isDark
          w={width}
          px={spacing}
          logoUrl="/images/customer-logos/tarteel_logo.svg"
          title="Tarteel AI"
          href="https://tarteel.ai/"
          filter="brightness(0.8)"
        />

        <CustomerLogo
          isDark
          w={width}
          px={spacing}
          logoUrl="/images/customer-logos/lexica_logo.svg"
          title="Lexica"
          href="https://lexica.art"
          filter="brightness(0.8)"
        />
      </SimpleGrid>
    </Flex>
  );
};
