import { Flex, FormControl } from "@chakra-ui/react";
import {
  GcpAccount,
  useRemoveGcpAccountMutation,
} from "@zeet/web-api/dist/graphql";
import { CopyCode, FormLabel } from "@zeet/web-ui";
import { SettingsDisplayName } from "~/components/Layouts/Settings/SettingsDisplayName";
import {
  SettingsSection,
  SettingsSectionDetails,
  SettingsSections,
} from "../../../../Layouts/Settings";
import { YesNoCell } from "../../../../ListViewV2";
import { CloudDangerZone } from "./CloudDangerZone";

interface GcpDetailsProps {
  gcpAccount: GcpAccount;
  refetch: () => void;
}

export const GcpDetails: React.FC<GcpDetailsProps> = ({
  gcpAccount,
  refetch,
}) => {
  return (
    <SettingsSections>
      <SettingsSection borderTop="none">
        <SettingsSectionDetails title="General" />
        <Flex gap="6" flexDirection="column" overflow="hidden">
          <SettingsDisplayName />
          <FormControl>
            <FormLabel>Connected</FormLabel>
            <YesNoCell isYes={gcpAccount.connected ?? false} />
          </FormControl>
          <FormControl>
            <FormLabel>GCP Project</FormLabel>
            <CopyCode value={gcpAccount.projectID ?? ""} width="100%" />
          </FormControl>
          <FormControl>
            <FormLabel>GCP Connection IAM Role</FormLabel>
            <CopyCode value={gcpAccount.clientEmail ?? ""} width="100%" />
          </FormControl>
          <FormControl>
            <FormLabel>Zeet Cloud ID</FormLabel>
            <CopyCode value={gcpAccount?.id ?? ""} width="100%" />
          </FormControl>
        </Flex>
      </SettingsSection>

      <SettingsSection mt={12}>
        <SettingsSectionDetails title="Danger zone" />

        <CloudDangerZone
          id={gcpAccount.id}
          confirmationString={gcpAccount.projectID ?? gcpAccount.id}
          useRemoveCloudAccountMutation={useRemoveGcpAccountMutation}
          refetch={refetch}
        />
      </SettingsSection>
    </SettingsSections>
  );
};
