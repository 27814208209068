import { Flex } from "@chakra-ui/react";
import { ClusterStatusBadge, StatusBadgeProps } from "@zeet/web-ui";
import { FC } from "react";

interface StatusCellProps {
  status: StatusBadgeProps["status"];
}

export const ClusterStatusCell: FC<StatusCellProps> = ({ status }) => (
  <Flex alignItems="center">
    <ClusterStatusBadge status={status} />
  </Flex>
);
