import { Resource } from "@zeet/web-api/dist/graphqlv1";
import { DataTypes, IDataRow, IZeetData, theme } from "@zeet/web-ui";
import { Column } from "react-table";

type ColumnsType = Column<IDataRow>[];

enum ColumnIds {
  DEPLOY_DISPLAY_NAME = "projectDeployDisplayName",
  RESOURCE_EXTERNAL_ID = "resourceExternalId",
}

const columns: ColumnsType = [
  {
    id: ColumnIds.DEPLOY_DISPLAY_NAME,
    label: "Name",
    minWidth: theme.defaultFirstColumnWidth,
    dataType: DataTypes.TEXT,
    options: [],
    order: 1,
  },
  {
    id: ColumnIds.RESOURCE_EXTERNAL_ID,
    label: "External ID",
    minWidth: 400,
    dataType: DataTypes.LINK,
    options: [],
    order: 1,
  },
];

const defaultColumnIds = Object.values(ColumnIds) as ColumnIds[];

function formatDataRow(resources?: Resource[]): IZeetData {
  const formattedData: IDataRow[] =
    resources?.map((resource) => {
      return {
        [ColumnIds.DEPLOY_DISPLAY_NAME]: {
          text: resource?.displayName,
        },
        [ColumnIds.RESOURCE_EXTERNAL_ID]: {
          text: resource.externalId,
        },
      };
    }) ?? [];

  return {
    columns,
    data: formattedData,
    skipReset: false,
  };
}

export { columns, defaultColumnIds, formatDataRow };
