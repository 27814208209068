import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  useClipboard,
} from "@chakra-ui/react";

import Editor from "@monaco-editor/react";
import { Tooltip, Track } from "@zeet/web-ui";
import React, { useEffect, useMemo } from "react";
import saveAsFile from "~/utils/save-as-file";
import { useEnvVarSettingsContext } from "./EnvVarSettingsProvider";
import "./Settings.scss";

export const EnvVarsExportModal: React.FC<{
  isExportOpen: boolean;
  onExportClose: () => void;
  projectName: string;
}> = ({ isExportOpen, onExportClose, projectName }) => {
  const [{ envs }] = useEnvVarSettingsContext();
  const envText = useMemo(() => {
    return envs
      .filter((env) => env.name && env.value)
      .map((env) => {
        return `${env.name}=${env.value}`;
      })
      .join("\n");
  }, [envs]);

  const {
    hasCopied: hasCopiedToClipboard,
    onCopy: onCopyToClipboard,
    setValue,
  } = useClipboard(envText);

  useEffect(() => {
    setValue(envText);
  }, [envText, setValue]);

  return (
    <Modal
      isOpen={isExportOpen}
      onClose={onExportClose}
      size="xl"
      isCentered
      preserveScrollBarGap={true}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        {/* TODO refactor below Track */}
        <Track type="load_copy_env_vars" />
        <ModalHeader>
          Export Environment Variables{" "}
          <Tooltip text="You can copy the env env vars directly into your clipboard or download as a file" />
        </ModalHeader>
        <ModalBody>
          <Editor
            theme="vs-dark"
            value={envText}
            defaultLanguage="yaml"
            options={{
              lineNumbers: "off",
              glyphMargin: false,
              lineDecorationsWidth: -1,
              lineNumbersMinChars: -1,
              minimap: {
                enabled: false,
              },
              readOnly: true,
              scrollBeyondLastLine: false,
            }}
            height="40vh"
            width="100%"
          />
        </ModalBody>
        <ModalFooter>
          <Button onClick={onCopyToClipboard}>
            {hasCopiedToClipboard ? "Copied" : "Copy to clipboard"}
          </Button>
          <Spacer />
          <Button
            ml="3"
            colorScheme="brand"
            onClick={() => {
              saveAsFile(envText, projectName + ".env");
            }}
          >
            Download .env file
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
