import config from "./config";

const isSpecialEnv = (): boolean => {
  return getEnvName() !== null;
};

const getEnvName = (): string | null => {
  if (config.ZEET_ENVIRONMENT === "staging") {
    return "Staging";
  }
  if (config.ZEET_ENVIRONMENT === "zorp") {
    return "Zorp";
  }
  if (config.ZEET_ENVIRONMENT === "enterprise") {
    return "Enterprise";
  }
  if (config.isDev) {
    return "Local";
  }
  return null;
};

export { isSpecialEnv, getEnvName };
