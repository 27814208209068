import { Flex, Image, Text } from "@chakra-ui/react";
import { AWSLogo, CloudProvidersEnum } from "@zeet/web-ui";
import React from "react";
import cwLogo from "~/images/coreweave/logo.png";
import doLogo from "~/images/digitalocean/logo.png";
import gcpLogo from "~/images/gcp/gcp.png";

const ConsoleCloudUserIcon: React.FC<{
  simpleCloudId: string | null | undefined;
  cloudProvider: CloudProvidersEnum | undefined;
}> = ({ simpleCloudId, cloudProvider }) => {
  if (cloudProvider === CloudProvidersEnum.GCP) {
    return (
      <Flex alignItems="center">
        <Image height="0.9rem" src={gcpLogo} />
        <Text
          maxW="250px"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
          ml="6px"
        >
          {simpleCloudId}
        </Text>
      </Flex>
    );
  }
  if (cloudProvider === CloudProvidersEnum.DO) {
    return (
      <Flex alignItems="center">
        <Image height="0.9rem" src={doLogo} />
        <Text
          maxW="250px"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
          ml="6px"
        >
          {simpleCloudId}
        </Text>{" "}
      </Flex>
    );
  }
  if (cloudProvider === CloudProvidersEnum.AWS) {
    return (
      <Flex alignItems="center">
        <AWSLogo height="0.9rem" />
        <Text
          maxW="250px"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
          ml="6px"
        >
          {simpleCloudId}
        </Text>{" "}
      </Flex>
    );
  }
  if (cloudProvider === CloudProvidersEnum.CW) {
    return (
      <Flex alignItems="center">
        <Image height="0.9rem" src={cwLogo} />
        <Text
          maxW="250px"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
          ml="6px"
        >
          {simpleCloudId}
        </Text>{" "}
      </Flex>
    );
  }
  return <Flex></Flex>;
};

export default ConsoleCloudUserIcon;
