import { Box, Flex, Heading, Stack, Tooltip } from "@chakra-ui/react";
import { BlueprintSlug, Button, Link, useTrack, ZeetLogo } from "@zeet/web-ui";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ReactComponent as GithubCircleImage } from "~/images/onboard/github-circle.svg";
import { useBillingOverviewContext } from "../../Account/BillingV2/Providers/BillingOverviewProvider";
import { useDemoProject } from "./DemoProjectProvider";
import { DeployCard } from "./DeployCard";

const TeamDeployMenu: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { track } = useTrack();
  const location = useLocation();
  const [{ userBillingOverview }] = useBillingOverviewContext();
  const maxCloudsReached =
    !userBillingOverview?.user.billingOverview?.subscriptionUsageAllowed
      .cloudAccounts;
  const history = useHistory();
  const { cloudProvider, deployDemo, isManualLoading } = useDemoProject();

  const projectSources = [
    {
      title: "Deploy my own repo",
      description:
        "I know what I'm doing. Let me unlock the power of my cloud!",
      icon: <GithubCircleImage height="40px" />,
      link: `/new?tags=Git`,
      posthogType: "personal repo",
      buttonText: "Next: Configure project",
    },
    {
      title: "Deploy a sample",
      description:
        "Deploy a simple Node.js on your cluster to experience the power of Zeet ⚡",
      icon: <ZeetLogo />,
      buttonText: "Next: Deploy sample",
      posthogType: "demo",
      link: `/new/zeet/${BlueprintSlug.DemoProject}`,
    },
  ];

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      paddingBottom="2rem"
      flexDirection="column"
      mr="3"
      minH="365px"
    >
      <Heading size="md" mt="12">
        Let's deploy your first project using Zeet
      </Heading>
      <Flex justifyContent="center" mt="36px">
        <Stack spacing="16px" alignItems="center">
          {projectSources?.map((projectSource, index) => {
            if (projectSource.posthogType === "demo") {
              // TODO: if the user has a non-aws/gcp cloud and is not gated by billing, the user must add a aws/gcp account
              return (
                <Tooltip
                  placement="bottom"
                  label="The cloud you have connected with Zeet is not compatible with the demo project. Please remove the current cloud and connect an AWS or GCP account."
                  isDisabled={
                    (maxCloudsReached && !!cloudProvider) || !maxCloudsReached
                  }
                  key={index}
                >
                  <Box
                    w={{ base: "85%", sm: "85%", md: "90%", lg: "100%" }}
                    onClick={() => {
                      if (
                        (maxCloudsReached && !!cloudProvider) ||
                        !maxCloudsReached
                      ) {
                        setActiveIndex(index);
                      }
                    }}
                    data-testid={`${projectSource.posthogType}-deploy-card-container`}
                  >
                    <DeployCard
                      projectSource={projectSource}
                      index={index}
                      activeIndex={activeIndex}
                    />
                  </Box>
                </Tooltip>
              );
            } else {
              return (
                <Box
                  w={{ base: "85%", sm: "85%", md: "90%", lg: "100%" }}
                  key={index}
                  onClick={() => setActiveIndex(index)}
                >
                  <DeployCard
                    projectSource={projectSource}
                    index={index}
                    activeIndex={activeIndex}
                  />
                </Box>
              );
            }
          })}
        </Stack>
      </Flex>
      <Flex justifyContent="center" mt={10}>
        <Button
          variant="primary"
          isLoading={isManualLoading}
          fontSize="md"
          p="4"
          px="8"
          data-testid="team-deploy-menu-button"
          onClick={() => {
            track("team_onboard_deploying", {
              type: projectSources[activeIndex]?.posthogType,
            });
            if (activeIndex === 1 && cloudProvider) {
              return deployDemo();
            }
            if (activeIndex === 0 || (activeIndex === 1 && !maxCloudsReached)) {
              return history.push(
                `${projectSources[activeIndex]?.link}${location.search}`
              );
            }
          }}
        >
          {projectSources[activeIndex]?.buttonText}
        </Button>
      </Flex>
      <Flex justifyContent="center" mt={4}>
        <Link
          to="https://docs.zeet.co/"
          isExternal
          color="gray.500"
          hoverColor="brand.100"
        >
          Browse docs and tutorials
        </Link>
      </Flex>
    </Flex>
  );
};

export default TeamDeployMenu;
