import { EditorInput } from "@zeet/web-ui";
import { FieldValues } from "react-hook-form";

import { useIsFormValueSet } from "~/hooks/useIsFormValueSet";
import { removeObjectProperties } from "~/utils/object";
import { InputContainer, InputProps } from "../../../inputs/InputContainer";
import { SettingsSection } from "../../SettingsSection";

export const useSourcefulBlueprintConfigurationPath = (): string | null => {
  const isKubernetes = useIsFormValueSet("configuration.kubernetes");
  const isHelm = useIsFormValueSet("configuration.helm");
  const isTerraform = useIsFormValueSet("configuration.terraform");

  if (isKubernetes) return "kubernetes";
  if (isHelm) return "helm";
  if (isTerraform) return "terraform";
  return null;
};

// This is a special editor that automatically value to json and back
const SourceEditorInput = <TFormData extends FieldValues>(
  props: InputProps<TFormData>
) => {
  return (
    <InputContainer
      {...props}
      render={({ ref: _ref, onChange, value, ...p }) => {
        const defaultValue = JSON.stringify(
          removeObjectProperties(value || {}, [
            (value) => value === null,
            (_, key) => key === "__typename",
          ]),
          null,
          4
        );

        return (
          <EditorInput
            minHeight="300px"
            onChange={(v) => onChange(JSON.parse(v || ""))}
            value={defaultValue}
            {...p}
            data-testid="source-override-editor"
          />
        );
      }}
    />
  );
};

export const SourceOverrideSettings = () => {
  const bpPath = useSourcefulBlueprintConfigurationPath()?.toLowerCase();
  if (!bpPath) return null;

  return (
    <SettingsSection
      name="Source Configuration"
      description="Advanced settings to override Blueprint configuration"
    >
      <SourceEditorInput
        name="Blueprint Source"
        tooltip="By providing a custom source, you are overriding the blueprint's source"
        path={`${bpPath}.blueprint.source`}
        data-testid="source-override-editor"
      />
    </SettingsSection>
  );
};
