import {
  ProjectContextFragment,
  ProjectContextWithoutEnvironmentFragment,
} from "@zeet/web-api/dist/graphql";
import React from "react";
import { APIIdentifiersSettings } from "../../APIIdentifiersSettings";
import { useEnv } from "../Provider";

interface APIProps {
  project: ProjectContextFragment | ProjectContextWithoutEnvironmentFragment;
}

export const API: React.FC<APIProps> = ({ project }) => {
  const env = useEnv();
  const identifiers = [
    { title: "User", value: project.owner.id },
    { title: "Project", value: project.id },
    { title: "Environment", value: env.id },
  ];

  return <APIIdentifiersSettings identifiers={identifiers} />;
};
