import { Text, useDisclosure } from "@chakra-ui/react";
import { ActionProps } from ".";
import { DeleteAction } from "../DeleteAction";
import { DeleteDialog } from "../DeleteDialog";

export const UnlinkAction = ({ onAction, name }: ActionProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <DeleteAction
      title="Unlink this Project"
      message="Retain all cloud resources for this project, but remove project from Zeet. This cannot be undone."
      action="Unlink Project"
      onAction={onOpen}
    >
      <DeleteDialog
        onClose={onClose}
        onDelete={onAction}
        isLoading={false}
        isOpen={isOpen}
        type="project"
        action={{ pastTense: "unlinked", presentTense: "unlink" }}
        actionMessage="Unlink this project"
        confirmationString={name}
        warningMessage={
          <Text>
            This will retain all cloud resources for the{" "}
            <Text fontWeight={"bold"} as="span">
              {name}
            </Text>{" "}
            project, but will permanently remove the project from Zeet.
          </Text>
        }
      />
    </DeleteAction>
  );
};
