import { Flex, Text } from "@chakra-ui/react";
import { useColors } from "@zeet/web-ui";
import React from "react";
import { BiLinkExternal } from "react-icons/bi";

export const TableButton: React.FC<{ text: string; showLinkIcon: boolean }> = ({
  text,
  showLinkIcon,
}) => {
  const { bg } = useColors();
  return (
    <Flex backgroundColor={bg} alignItems="center" width="100%">
      <Text mr="10px">{text}</Text>
      {showLinkIcon && <BiLinkExternal />}
    </Flex>
  );
};
