import { useToast } from "@chakra-ui/react";
import {
  Blueprint,
  BlueprintsDocument,
  useUpdateBlueprintMutation,
  useUpdateMarketplaceBlueprintMutation,
} from "@zeet/web-api/dist/graphql";
import { useCurrentTeamUser } from "@zeet/web-ui";
import { FC, useEffect, useState } from "react";
import { useMutationError } from "~/hooks/useMutationError";
import { SwitchCell } from "./SwitchCell";

interface BlueprintEnabledCellProps {
  blueprint?: Blueprint;
}

export const BlueprintEnabledCell: FC<BlueprintEnabledCellProps> = ({
  blueprint,
}) => {
  const toast = useToast();
  const isEnabled = blueprint?.isOfficial
    ? blueprint?.enabled
    : blueprint?.published;
  const [isChecked, setIsChecked] = useState(isEnabled ?? false);
  const currentTeamUser = useCurrentTeamUser();

  const sharedMutationOptions = {
    ...useMutationError("Failed to update blueprint"),
    onCompleted: (data) => {
      if (data) {
        toast({
          title: "Blueprint updated",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    },
    refetchQueries: [
      {
        query: BlueprintsDocument,
        variables: {
          userId: currentTeamUser.id,
          permissionsUserId: currentTeamUser.id,
          pageInput: { first: 100 },
        },
      },
    ],
  };

  useEffect(() => {
    setIsChecked(isEnabled ?? false);
  }, [isEnabled, blueprint]);

  const [updateCustomBlueprint] = useUpdateBlueprintMutation(
    sharedMutationOptions
  );
  const [updateOfficialBlueprint] = useUpdateMarketplaceBlueprintMutation(
    sharedMutationOptions
  );
  const updateBlueprint = blueprint?.isOfficial
    ? updateOfficialBlueprint
    : updateCustomBlueprint;

  const onChange = (checked: boolean) => {
    setIsChecked(checked);
    updateBlueprint({
      variables: blueprint?.isOfficial
        ? ({
            input: {
              blueprintID: blueprint.id,
              enabled: checked,
              userID: currentTeamUser.id,
            },
          } as any)
        : {
            id: blueprint?.id ?? "",
            input: {
              displayName: blueprint?.displayName ?? "",
              published: checked,
            },
          },
    });
  };

  return <SwitchCell isChecked={isChecked} onChange={onChange} />;
};
