import { GitSourceInput } from "@zeet/web-api/dist/graphqlv1";
import { Source, SourceKind } from "..";

const makeSourceFromGit = (kind: SourceKind, git: GitSourceInput): Source => {
  return {
    kind,
    git,
  };
};

export const makeGitHubSource = (git: GitSourceInput): Source => {
  return makeSourceFromGit(SourceKind.GitHub, git);
};

export const makeBitbucketSource = (git: GitSourceInput): Source => {
  return makeSourceFromGit(SourceKind.Bitbucket, git);
};

export const makeGitlabSource = (git: GitSourceInput): Source => {
  return makeSourceFromGit(SourceKind.GitLab, git);
};

export const makePublicGitSource = (git: GitSourceInput): Source => {
  return makeSourceFromGit(SourceKind.PublicGitRepo, {
    ...git,
    integration: null,
  });
};
