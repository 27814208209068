import { Button, Flex, Input, Stack, Text, useToast } from "@chakra-ui/react";
import {
  CloudProvider,
  RepoDetailFragment,
  useUpdateProjectSettingsMutation,
} from "@zeet/web-api/dist/graphql";
import { CopyableText, ZError } from "@zeet/web-ui";
import React from "react";
import { useForm } from "react-hook-form";
import { shouldDisplayError } from "../../util";
import { ZFormLabel } from "../Build";

export const CloudIdentitySettings: React.FC<{
  repo: RepoDetailFragment;
}> = ({ repo }) => {
  const toast = useToast();

  const cloud = repo?.cluster?.cloudProvider as CloudProvider;

  const { handleSubmit, register, watch } = useForm({
    defaultValues: {
      cloudIdentity:
        cloud === CloudProvider.Aws
          ? repo?.awsIamRole
          : repo?.gcpServiceAccount,
    },
  });

  const watchForm = watch();

  const [updateSettings, { error, loading, data }] =
    useUpdateProjectSettingsMutation({
      onCompleted: (data) => {
        if (data) {
          toast({
            title: "Release Settings Saved",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      },
    });

  const onSubmit = (values) => {
    if (cloud === CloudProvider.Aws) {
      updateSettings({
        variables: {
          input: {
            id: repo.id,
            awsIamRole: values?.cloudIdentity,
          },
        },
      });
    } else {
      updateSettings({
        variables: {
          input: {
            id: repo.id,
            gcpServiceAccount: values?.cloudIdentity,
          },
        },
      });
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.stopPropagation();
        e.preventDefault();
        handleSubmit(onSubmit)(e);
      }}
    >
      <Flex flexDirection="column">
        <Text fontWeight="bold" fontSize="1.2rem" mb={3}>
          {cloud === CloudProvider.Aws
            ? "AWS Iam Role"
            : "GCP Workload Identity"}{" "}
          Settings
        </Text>

        <Stack spacing={3}>
          <Text>
            Cloud Identity settings let you assign cloud native IAM permission
            to your container without manually creating credentials.
          </Text>

          <Stack spacing={1}>
            <Text>Service Account Connection Info</Text>
            <Flex ml={4}>
              Namespace:{" "}
              <CopyableText
                flex={3}
                color="brandVar"
                whiteSpace="nowrap"
                ml="2"
                toCopy={repo.namespace}
                showIcon
              >
                {repo.namespace}
              </CopyableText>
            </Flex>
            <Flex ml={4}>
              ServiceAccount:{" "}
              <CopyableText
                flex={3}
                color="brandVar"
                whiteSpace="nowrap"
                ml="2"
                toCopy={repo.kubernetesCustomization?.serviceAccountName}
                showIcon
              >
                {repo.kubernetesCustomization?.serviceAccountName}
              </CopyableText>
            </Flex>
          </Stack>

          <Flex alignItems="center">
            <ZFormLabel>
              {cloud === CloudProvider.Aws
                ? "AWS Iam Role ARN"
                : "GCP Service Account ID"}
            </ZFormLabel>
            <Input
              flex={5}
              fontFamily={"mono"}
              {...register("cloudIdentity")}
              placeholder={`(e.g.) ${
                cloud === CloudProvider.Aws
                  ? "arn:aws:iam::123456789012:role/my-role"
                  : "account@xyz.iam.gserviceaccount.com"
              }`}
              defaultValue={watchForm.cloudIdentity || ""}
            />
          </Flex>
          {shouldDisplayError(error, data) && <ZError error={error} />}
          <Flex>
            <Button
              ml={"auto"}
              colorScheme="brand"
              isLoading={loading}
              type="submit"
            >
              Save
            </Button>
          </Flex>
        </Stack>
      </Flex>
    </form>
  );
};
