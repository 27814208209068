import {
  Box,
  Button,
  Code,
  Flex,
  Grid,
  GridItem,
  Heading,
  LinkBox,
  LinkOverlay,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import {
  JobRunState,
  ProjectJobQuery,
  ProjectJobsQuery,
  RepoDetailFragment,
  RepoSourceType,
  useProjectJobQuery,
  useProjectJobsQuery,
  useRunJobMutation,
} from "@zeet/web-api/dist/graphql";
import { Card, Link, StatusBadge, useColors, ZWarn } from "@zeet/web-ui";
import isUndefined from "lodash/isUndefined";
import { default as React, useState } from "react";
import { useParams } from "react-router-dom";
import { default as TimeAgo } from "react-timeago";
import { TabWrapper } from ".";
import { LogsConsole } from "../LogsConsole/LogsConsole";
import { getVersionLink } from "./Deployment";
import { JobsSkeleton } from "./JobsSkeleton";
import { repoPath } from "./util";

const jobState = (state?: JobRunState): string => {
  if (state == JobRunState.JobRunStarting) {
    return "starting";
  } else if (state == JobRunState.JobRunRunning) {
    return "running";
  } else if (state == JobRunState.JobRunFailed) {
    return "failed";
  } else if (state == JobRunState.JobRunSucceeded) {
    return "succeeded";
  }
  return "inactive";
};

const jobName = (id: string): string => {
  return "Job " + id.split("-")[0];
};

const JobRunListView: React.FC<{
  repo: RepoDetailFragment;
  jobRun: NonNullable<
    NonNullable<ProjectJobsQuery["currentUser"]["repo"]>["jobRuns"]
  >["nodes"][0];
}> = ({ repo, jobRun: job }) => {
  const { bg2 } = useColors();
  const [showTime, setShowTime] = useState<boolean>(false);

  const status = job.state;
  const version = jobVersion(repo, job.build?.version || "");

  return (
    <LinkBox bg={bg2} p={4} mt={4} shadow="md" borderRadius="md">
      <Flex alignItems="center" mb={3}>
        <Heading size="md">
          <LinkOverlay
            as={Link}
            to={`/${repoPath(repo)}/jobs/${job.id}`}
            display="inline-flex"
          >
            <Text
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
              maxWidth="30rem"
            >
              {jobName(job.id)} {version ? `(${version})` : null}
            </Text>
          </LinkOverlay>
        </Heading>

        <StatusBadge ml="auto" fontSize="md" status={jobState(status)} />
      </Flex>
      <Text fontSize="sm" marginTop={4}>
        Created
        <Box
          marginLeft={1}
          as="span"
          onMouseEnter={() => setShowTime(true)}
          onMouseLeave={() => setShowTime(false)}
        >
          {showTime ? (
            new Date(job?.createdAt || 0).toLocaleString()
          ) : (
            <TimeAgo date={job?.createdAt || 0} />
          )}
        </Box>
      </Text>
    </LinkBox>
  );
};

export const JobsTab = () => {
  return <TabWrapper component={Jobs} />;
};

const Jobs: React.FC<{
  repo: RepoDetailFragment;
}> = ({ repo }) => {
  const { subTab: jobRunID } = useParams<{
    subTab: string;
  }>();

  const { data, loading, refetch } = useProjectJobsQuery({
    variables: {
      id: repo.id,
    },
    pollInterval: 3000,
  });

  const toast = useToast();

  const [runJob, { loading: rjL }] = useRunJobMutation({
    onCompleted: (data) => {
      if (data) {
        refetch();
        toast({
          title: "Job Executed Added",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    },
  });

  if (loading) {
    return <JobsSkeleton />;
  }

  if (jobRunID) {
    return <JobRunDetailView repo={repo} jobRunID={jobRunID} />;
  }

  return (
    <Stack spacing={4}>
      <Flex mb={2}>
        <Button
          verticalAlign="middle"
          onClick={() => {
            runJob({
              variables: {
                input: {
                  id: repo.id,
                  build: true,
                },
              },
            });
          }}
          isLoading={rjL}
        >
          Run Job
        </Button>
      </Flex>
      {data?.currentUser?.repo?.jobRuns?.nodes?.map((item) => (
        <JobRunListView key={item.id} repo={repo} jobRun={item} />
      ))}
    </Stack>
  );
};

const GrayText: React.FC<React.PropsWithChildren> = (props) => {
  return (
    <Text color="gray.500" textTransform="uppercase" fontSize={14} {...props} />
  );
};

const TimeBox: React.FC<{ time?: Date | null }> = ({ time, ...props }) => {
  const [showTime, setShowTime] = useState(false);
  return (
    <Box
      as="span"
      onMouseEnter={() => setShowTime(true)}
      onMouseLeave={() => setShowTime(false)}
      {...props}
    >
      {showTime ? (
        new Date(time || 0).toLocaleString()
      ) : (
        <TimeAgo date={time || 0} />
      )}
    </Box>
  );
};

const jobVersion = (repo: RepoDetailFragment, version: string): string => {
  if (
    repo.source.type === RepoSourceType.Github ||
    repo.source.type === RepoSourceType.GithubPublic
  ) {
    return version.slice(0, 7);
  }
  return version;
};

const JobRunDetailView: React.FC<{
  repo: RepoDetailFragment;
  jobRunID: string;
}> = ({ repo, jobRunID }) => {
  const { data, loading } = useProjectJobQuery({
    variables: {
      repoID: repo.id,
      jobID: jobRunID,
    },
  });

  const job = data?.currentUser?.repo?.jobRun;
  if (loading || !job) {
    return <JobsSkeleton />;
  }

  const version = jobVersion(repo, job.build?.version || "");

  return (
    <>
      <Card p={4} my={4}>
        <Flex alignItems="center" mb={3}>
          <Heading
            size="md"
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
            maxWidth="30rem"
          >
            {`ID ${job.id}`}
          </Heading>
          <Flex ml="auto">
            <StatusBadge ml={2} fontSize="md" status={jobState(job.state)} />
          </Flex>
        </Flex>
        <Grid gap={4} alignItems="center" templateColumns="repeat(2, 1fr)">
          {version ? (
            <GridItem>
              <GrayText>Version</GrayText>
              <Flex align="center" display="inline-flex">
                {version}
              </Flex>
            </GridItem>
          ) : null}
          <GridItem>
            <GrayText>Created</GrayText>
            <Flex align="center" display="inline-flex">
              <TimeBox time={job.createdAt} />
            </Flex>
          </GridItem>
          {job.deployment && (
            <GridItem>
              <GrayText>Deployment</GrayText>
              <Flex>
                <Link
                  mr="1"
                  to={`/${repoPath(repo)}/deployments/${job.deployment.id}`}
                >
                  {job.deployment.id?.slice(0, 4)}
                </Link>
                {"("}
                <Link to={getVersionLink(repo, version)}>
                  <Text
                    textOverflow="ellipsis"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    maxWidth="30rem"
                  >
                    {job.deployment.branch}
                  </Text>
                </Link>
                {")"}
              </Flex>
            </GridItem>
          )}
          {job.command && (
            <GridItem>
              <GrayText>Run Command</GrayText>
              <Flex align="center" display="inline-flex">
                <Code> {job.command} </Code>
              </Flex>
            </GridItem>
          )}
          {!isUndefined(job.exitCode) && (
            <GridItem>
              <GrayText>Exit Code</GrayText>
              <Flex align="center" display="inline-flex">
                {job.exitCode}
              </Flex>
            </GridItem>
          )}
        </Grid>
      </Card>
      <Card flexDir="column" p={4} mb={4}>
        <Flex alignItems="center">
          <Heading size="md">Job Logs</Heading>
        </Flex>
        {job.logs?.expired && (
          <ZWarn error="These logs have expired. Logs are only available until a container is destroyed." />
        )}
        <JobLogs job={job} />
      </Card>
    </>
  );
};

const JobLogs: React.FC<{
  job: NonNullable<ProjectJobQuery["currentUser"]["repo"]>["jobRun"];
}> = ({ job }) => {
  const [searching, setSearching] = useState(false);

  return (
    <LogsConsole
      isLive={!job.logs?.completed}
      lines={job.logs?.entries ?? []}
      searching={searching}
      setSearching={setSearching}
    />
  );
};
