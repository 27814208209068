import {
  Metric,
  MetricLabelType,
  MetricStream,
  MetricType,
  ObservabilityRange,
} from "@zeet/web-api/dist/graphql";
import { ECOption } from "@zeet/web-ui";
import { add, format, formatISODuration } from "date-fns";

const suffix = {
  CPU: "%",
  Memory: " MB",
  Network: " MB/s",
  Disk: " GB",
  Duration: "ms",
};

const usage = {
  CPU: " Usage",
  Memory: " Usage",
  Network: " Throughput",
  Disk: " Usage",
};

export enum MetricRangeOption {
  PAST_5_MIN = "PAST_5_MIN",
  PAST_15_MIN = "PAST_15_MIN",
  PAST_30_MIN = "PAST_30_MIN",
  PAST_1_HOUR = "PAST_1_HOUR",
  PAST_3_HOUR = "PAST_3_HOUR",
  PAST_1_DAY = "PAST_1_DAY",
  PAST_1_WEEK = "PAST_1_WEEK",
}

export const convertToMetricRange = (
  range: MetricRangeOption
): ObservabilityRange => {
  switch (range) {
    case MetricRangeOption.PAST_5_MIN:
      return {
        start: add(new Date(), { minutes: -5 }),
        end: new Date(),
        step: formatISODuration({ seconds: 30 }),
      };
    case MetricRangeOption.PAST_15_MIN:
      return {
        start: add(new Date(), { minutes: -15 }),
        end: new Date(),
        step: formatISODuration({ seconds: 30 }),
      };
    case MetricRangeOption.PAST_30_MIN:
      return {
        start: add(new Date(), { minutes: -30 }),
        end: new Date(),
        step: formatISODuration({ minutes: 1 }),
      };
    case MetricRangeOption.PAST_1_HOUR:
      return {
        start: add(new Date(), { hours: -1 }),
        end: new Date(),
        step: formatISODuration({ minutes: 1 }),
      };
    case MetricRangeOption.PAST_3_HOUR:
      return {
        start: add(new Date(), { hours: -3 }),
        end: new Date(),
        step: formatISODuration({ minutes: 1 }),
      };
    case MetricRangeOption.PAST_1_DAY:
      return {
        start: add(new Date(), { days: -1 }),
        end: new Date(),
        step: formatISODuration({ minutes: 5 }),
      };
    case MetricRangeOption.PAST_1_WEEK:
      return {
        start: add(new Date(), { weeks: -1 }),
        end: new Date(),
        step: formatISODuration({ minutes: 30 }),
      };
  }
};

export const metricRangeOptions = [
  {
    label: "Past 5 minutes",
    value: MetricRangeOption.PAST_5_MIN.toString(),
  },
  {
    label: "Past 15 minutes",
    value: MetricRangeOption.PAST_15_MIN.toString(),
  },
  {
    label: "Past 30 minutes",
    value: MetricRangeOption.PAST_30_MIN.toString(),
  },
  {
    label: "Past 1 hour",
    value: MetricRangeOption.PAST_1_HOUR.toString(),
  },
  {
    label: "Past 3 hours",
    value: MetricRangeOption.PAST_3_HOUR.toString(),
  },
  {
    label: "Past 1 day",
    value: MetricRangeOption.PAST_1_DAY.toString(),
  },
  {
    label: "Past 1 week",
    value: MetricRangeOption.PAST_1_WEEK.toString(),
  },
];

export enum MetricAggregationOption {
  UNIFIED = "UNIFIED",
  SPLIT_CHARTS = "SPLIT_CHARTS",
  SPLIT_SERIES = "SPLIT_SERIES",
}

export const metricAggregationOptions = [
  {
    label: "Unified",
    value: MetricAggregationOption.UNIFIED.toString(),
  },
  {
    label: "Split charts",
    value: MetricAggregationOption.SPLIT_CHARTS.toString(),
  },
  {
    label: "Split series",
    value: MetricAggregationOption.SPLIT_SERIES.toString(),
  },
];

export const metricGroupByOptions = [
  {
    label: "Container",
    value: [MetricLabelType.Container.toString()],
  },
  {
    label: "Pod",
    value: [MetricLabelType.Pod.toString()],
  },
];

export const adapterToMetricStream = (
  type: MetricType,
  metrics?: Metric[] | null
): MetricStream[] => {
  if (!metrics) {
    return [];
  }

  return [
    {
      type,
      query: "",
      labels: [],
      entries: metrics.map((m) => ({
        timestamp: m.timestamp,
        value: m.value,
      })),
    },
  ];
};

export const baseOptions: ECOption = {
  renderer: "svg",
  grid: { left: "6%", right: "0%" },
  title: {
    text: "Resource Usage",
  },
  legend: {},
  tooltip: {
    trigger: "axis",
    formatter: (params) => {
      return `
    <h4 style="font-weight: 700; font-size: 1rem;">${format(
      params[0].axisValue,
      "MMM dd - HH:mm"
    )}</h4>
    <hr style="margin: 0 -10px;"/>
    ${params
      .map((val, index) => {
        return `<p><strong style="color: ${params[index].color};">
      ${params[index].data[1]}${suffix[val.seriesName] || ""}
      </strong>${val.seriesName}${usage[val.seriesName] || ""}</p>
      `;
      })
      .join("")}
    `;
    },
  },
  toolbox: {
    feature: {
      restore: {},
      saveAsImage: {},
      magicType: { type: ["line", "bar"] },
    },
  },
  xAxis: {
    type: "time",
    boundaryGap: false as unknown as [string, string], // typings are incorrect despite being shipped with the library. xref: https://echarts.apache.org/en/option.html#xAxis.boundaryGap
    axisLabel: {
      formatter: {
        day: "{monthStyle|{MMM}} {daystyle|{dd}}",
        hour: "{hourStyle|{HH}}:{minuteStyle|{mm}}",
      },
      rich: {
        monthStyle: {
          fontWeight: "bold",
        },
        daystyle: {
          fontWeight: "bold",
        },
        hourStyle: {},
        minuteStyle: {},
      },
    },
  },

  dataZoom: [
    {
      type: "inside",
      start: 90,
      end: 100,
    },
    {
      start: 90,
      end: 100,
      labelFormatter: (value) => format(value || 0, "MMM dd - HH:mm"),
    },
  ],
};
