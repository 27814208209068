import {
  FormControl,
  FormErrorMessage,
  HStack,
  Select,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import {
  GitlabIntegration,
  IntegrationType,
  useUserIntegrationsQuery,
} from "@zeet/web-api/dist/graphql"; // adjust import based on actual path
import {
  Button,
  FormLabel,
  Input,
  Skeleton,
  useCurrentTeamUser,
} from "@zeet/web-ui";
import React, { useEffect, useMemo, useState } from "react";
import { HashLink } from "react-router-hash-link";
import { Source } from "~/components/SourceManager";

// Parses the GitLab URL to extract the repository path
const parseGitlabUrl = (url: string): string => {
  const regex = /gitlab.com\/(.*)/g;
  const found = url.match(regex);
  return found?.[0].split("gitlab.com/")[1] || "";
};

export const GitlabRepositorySelect: React.FC<{
  onSelect: (integrationId: string, repository: string) => void;
  source?: Source;
}> = ({ onSelect, source }) => {
  const currentTeamUser = useCurrentTeamUser();
  const [repositoryUrl, setRepositoryUrl] = useState(
    source?.git?.repository || ""
  );
  const [selectedIntegrationId, setSelectedIntegrationId] = useState(
    source?.git?.integration?.gitlabIntegrationId || ""
  );

  // Fetch integrations
  const { data, loading } = useUserIntegrationsQuery({
    variables: {
      id: currentTeamUser?.id,
    },
  });

  const gitlabIntegrations = useMemo(
    () =>
      data?.user?.integrations?.filter(
        (integration) => integration.type === IntegrationType.Gitlab
      ) as GitlabIntegration[],
    [data]
  );

  useEffect(() => {
    setSelectedIntegrationId(gitlabIntegrations?.[0]?.id || "");
  }, [gitlabIntegrations]);

  // Render integrations in a dropdown
  const renderIntegrations = () => {
    if (!data || !gitlabIntegrations) {
      return <option>Error loading integrations</option>;
    }
    return gitlabIntegrations.map((integration) => (
      <option key={integration.id} value={integration.id}>
        {integration.name}
      </option>
    ));
  };

  useEffect(() => {
    if (selectedIntegrationId && repositoryUrl) {
      onSelect(selectedIntegrationId, repositoryUrl);
    }
  }, [selectedIntegrationId, repositoryUrl, onSelect]);

  if (loading) {
    return (
      <Stack gap="2" mt="4">
        <Skeleton height="48px" borderRadius="md" />
        <Skeleton height="48px" borderRadius="md" />
      </Stack>
    );
  }

  return (
    <VStack>
      {gitlabIntegrations?.length === 0 ? (
        <>
          <Text>No GitLab integrations found</Text>
          <Button to="/account/integrations#vcs-gitlab" as={HashLink}>
            Add GitLab Integration
          </Button>
        </>
      ) : (
        <>
          <FormControl mt="4" isRequired>
            <FormLabel>GitLab Integration</FormLabel>
            <HStack>
              <Select
                value={selectedIntegrationId}
                onChange={(e) => setSelectedIntegrationId(e.target.value)}
              >
                {renderIntegrations()}
              </Select>
              <Button
                to="/account/integrations#vcs-gitlab"
                variant="secondary"
                as={HashLink}
              >
                Manage Integrations
              </Button>
            </HStack>
          </FormControl>

          <FormControl
            mt="4"
            isRequired
            isInvalid={!!repositoryUrl && !parseGitlabUrl(repositoryUrl)}
          >
            <FormLabel>URL to GitLab repository</FormLabel>
            <Input
              autoFocus
              placeholder="https://gitlab.com/zeet-demo/node-express-demo.git"
              onChange={(e) => setRepositoryUrl(e.target.value)}
              value={repositoryUrl}
            />
            <FormErrorMessage>
              {parseGitlabUrl(repositoryUrl) ? "" : "Invalid GitLab repository"}
            </FormErrorMessage>
          </FormControl>
        </>
      )}
    </VStack>
  );
};
