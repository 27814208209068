import { Box, Heading, Icon } from "@chakra-ui/react";
import { ResourceType } from "@zeet/web-api/dist/graphqlv1";
import { BsBox } from "react-icons/bs";
import { useLocation } from "react-router-dom";
import { SidebarItem } from "../WorkflowsTab/Steps/SidebarItem";

interface ResourceTypeSideBarProps {
  activeType?: ResourceType;
}

export const ResourceTypeSideBar: React.FC<ResourceTypeSideBarProps> = () => {
  const { pathname } = useLocation();

  return (
    <Box flex="1" mt="2">
      <Heading size="sm" my={2} mt={4} opacity={0.5} fontSize="14px">
        Workloads
      </Heading>
      <SidebarItem isActive={true} to={pathname}>
        <Icon as={BsBox} mr={2} boxSize={5} />
        Pods
      </SidebarItem>
    </Box>
  );
};
