import { Flex, Stack } from "@chakra-ui/react";
import {
  ProjectBasicFragment,
  useProjectEnvironmentVariablesQuery,
} from "@zeet/web-api/dist/graphql";
import { SideLink } from "@zeet/web-ui";
import React from "react";
import { Route, Switch } from "react-router-dom";
import { EnvVarSettingsProvider } from "../Settings/EnvVarSettingsProvider";
import { projectPath } from "../util";
import { API } from "./API";
import { Danger } from "./Danger";
import Envs from "./Envs";

const Settings: React.FC<{ project: ProjectBasicFragment }> = ({ project }) => {
  const { data } = useProjectEnvironmentVariablesQuery({
    variables: { path: projectPath(project) },
  });

  const basePath = `/${projectPath(project)}/_settings`;
  const apiUrl = `${basePath}/api`;
  const dangerUrl = `${basePath}/danger`;

  return (
    <Flex flexDirection="row" mt={4}>
      <Stack mr={5}>
        <SideLink to={basePath}>Environment Variables</SideLink>
        <SideLink to={apiUrl}>API</SideLink>
        <SideLink to={dangerUrl}>Danger Zone</SideLink>
      </Stack>

      <Switch>
        <Route exact path={basePath}>
          <EnvVarSettingsProvider envsData={data?.project?.envs}>
            <Envs />
          </EnvVarSettingsProvider>
        </Route>
        <Route exact path={apiUrl}>
          <API project={project} />
        </Route>
        <Route exact path={dangerUrl}>
          <Danger project={project} />
        </Route>
      </Switch>
    </Flex>
  );
};

export default Settings;
