import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import {
  Button,
  EmptyBox,
  IconButtonGroup,
  IDataRow,
  Tip,
  useCurrentUser,
  useLocalStorage,
} from "@zeet/web-ui";
import { ChangeEvent, useCallback, useEffect, useRef } from "react";
import { BsFillGridFill } from "react-icons/bs";
import { FaList } from "react-icons/fa";
import { useHistory, useParams } from "react-router-dom";
import { Row } from "react-table";
import { useQueryState } from "~/hooks/useQueryState";
import { CloudsProvider } from "../Console/ConsoleClouds/CloudsProvider";
import {
  LIST_VIEW_TABLE_VIEW_TYPE,
  ListView,
  ListViewFilter,
  ListViewTable,
  ListViewTableColumns,
  ListViewViewManager,
  ListViewViewToggle,
} from "../ListViewV2";
import { DemoProjectProviderWrapper } from "../TeamOnboard/CloudOnboard/DemoProjectProvider";
import TeamDeployMenu from "../TeamOnboard/CloudOnboard/TeamDeployMenu";
import { PROJECT_CARDS_VIEW_TYPE, ProjectCardsView } from "./ProjectCardView";
import { ProjectTreeEmptyState } from "./ProjectEmptyState";
import {
  ColumnIds,
  defaultColumnIds,
  projectV3Columns,
  useProjectListViewData,
} from "./ProjectV3Utils";

export const ProjectListView = ({ actions }: { actions?: React.ReactNode }) => {
  const currentUser = useCurrentUser();
  const [filterValue, setFilterValue] = useQueryState("query");
  const history = useHistory();
  const [cardSortingStrategy, setCardSortingStrategy] = useLocalStorage(
    "ZEET_CARD_LIST_SORTING",
    "lastUpdated"
  );

  const handleFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterValue(e.target.value);
  };

  const { projectName: groupName, envName: subgroupName } = useParams<{
    projectName: string;
    envName: string;
  }>();

  const { data, loading, projects, groups } = useProjectListViewData({
    filter: filterValue,
    groupName,
    subgroupName,
  });

  const filterRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (
        e.key !== "/" ||
        (e.target as HTMLElement).nodeName.toLowerCase() === "input"
      )
        return;
      e.preventDefault();
      filterRef.current?.focus();
    };
    document.addEventListener("keyup", handler);
    return () => {
      document.removeEventListener("keyup", handler);
    };
  }, []);

  const sortingStrategy = useCallback(
    (
      rowA: Row<IDataRow>,
      rowB: Row<IDataRow>,
      colId: string,
      desc: boolean | undefined
    ): number => {
      const [aGroup, aSubgroup, aVal] = [
        rowA.values[ColumnIds.PROJECT_V3_GROUP_NAME],
        rowA.values[ColumnIds.PROJECT_V3_SUBGROUP_NAME],
        rowA.values[colId],
      ];
      const [bGroup, bSubgroup, bVal] = [
        rowB.values[ColumnIds.PROJECT_V3_GROUP_NAME],
        rowB.values[ColumnIds.PROJECT_V3_SUBGROUP_NAME],
        rowB.values[colId],
      ];

      const compare = (a: string | number, b: string | number) => {
        // do the regular sort
        if (typeof a === "undefined" || b === null) {
          return desc ? -1 : 1;
        }

        if (typeof a === "string") {
          return a.localeCompare(b as string);
        }

        if (typeof b === "string") {
          // a is an integer but b is a string?
          return 0;
        }

        return desc ? b - a : a - b;
      };

      // actually sort normally
      if (aGroup === bGroup && aSubgroup == bSubgroup) {
        return compare(aVal, bVal);
      }

      if (aGroup === bGroup) {
        // sort by subgroup!
        return compare(getKey(aGroup, aSubgroup), getKey(bGroup, bSubgroup));
      }

      function getKey(groupName: string, subgroupName?: string): string {
        const projects = data.data
          .filter(
            (r) =>
              r[ColumnIds.PROJECT_V3_GROUP_NAME]?.text === groupName &&
              (!subgroupName ||
                r[ColumnIds.PROJECT_V3_SUBGROUP_NAME]?.text === subgroupName)
          )
          .map((r) => r[colId].text);

        // now find the (min/max depending on sort order) key
        let current = projects[0];
        for (let i = 1; i < projects.length; i++) {
          const comparison = (desc ? -1 : 1) * compare(current, projects[i]);
          if (comparison > 0) current = projects[i];
        }
        return current;
      }

      return compare(getKey(aGroup), getKey(bGroup));
    },
    [data]
  );

  const showOnboardingTip =
    (!groupName &&
      !subgroupName &&
      (projects?.length ?? 0) <= 2 &&
      !filterValue &&
      !loading) ||
    (currentUser?.createdAt &&
      new Date(currentUser?.createdAt) > new Date("2024-02-01"));

  if (!loading && !groupName && !subgroupName && projects?.length === 0) {
    return (
      <EmptyBox mt={0} border="none">
        <DemoProjectProviderWrapper>
          <CloudsProvider>
            <TeamDeployMenu />
          </CloudsProvider>
        </DemoProjectProviderWrapper>
      </EmptyBox>
    );
  }

  return (
    <>
      <ListView
        id="activeProjects"
        columns={projectV3Columns}
        defaultColumnIds={defaultColumnIds}
        formattedData={data}
        filterValue={filterValue}
        handleFilterChange={handleFilterChange}
        defaultViewMode={PROJECT_CARDS_VIEW_TYPE}
        loading={loading}
        keyboardNavigable
        itemSelected={(item) => {
          const href = item[ColumnIds.PROJECT_V3_NAME]?.link;
          if (href) history.push(href);
        }}
      >
        <Flex
          alignItems="flex-end"
          justifyContent="space-between"
          flexDirection={{ base: "column", sm: "row" }}
        >
          <Flex alignItems="center" gap={4} order={{ base: 1, sm: 2 }}>
            <ListViewViewManager visible={LIST_VIEW_TABLE_VIEW_TYPE}>
              <ListViewTableColumns />
            </ListViewViewManager>

            <ListViewViewManager visible={PROJECT_CARDS_VIEW_TYPE}>
              <Menu autoSelect={false} placement="bottom-end">
                <MenuButton
                  variant="secondary"
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                  flexShrink={0}
                  fontSize="14px"
                >
                  Sort
                </MenuButton>

                <MenuList maxHeight="70vh" zIndex="2" overflowY="auto">
                  <MenuItem
                    onClick={() => setCardSortingStrategy("name")}
                    fontWeight={
                      cardSortingStrategy === "name" ? "bold" : "normal"
                    }
                  >
                    Name
                  </MenuItem>
                  <MenuItem
                    onClick={() => setCardSortingStrategy("lastUpdated")}
                    fontWeight={
                      cardSortingStrategy === "lastUpdated" ? "bold" : "normal"
                    }
                  >
                    Last Updated
                  </MenuItem>
                </MenuList>
              </Menu>
            </ListViewViewManager>

            <IconButtonGroup>
              <ListViewViewToggle
                icon={<BsFillGridFill />}
                label="Card view"
                viewMode={PROJECT_CARDS_VIEW_TYPE}
              />

              <ListViewViewToggle
                icon={<FaList />}
                label="Table view"
                viewMode={LIST_VIEW_TABLE_VIEW_TYPE}
              />
            </IconButtonGroup>

            {actions && actions}
          </Flex>

          <Box
            marginTop={{ base: 6, sm: 0 }}
            order={{ base: 2, sm: 1 }}
            width="100%"
            mr={{ base: 0, sm: 6 }}
          >
            <ListViewFilter ref={filterRef} />
          </Box>
        </Flex>

        {showOnboardingTip && (
          <Tip id="project-onboarding">
            Tip: A Zeet Project represents one workload in Zeet - roughly
            equivalent to a GitHub repo.
          </Tip>
        )}

        <ListViewViewManager visible={PROJECT_CARDS_VIEW_TYPE}>
          <ProjectCardsView
            projects={projects}
            groups={groups}
            sortingStrategy={cardSortingStrategy as "name" | "lastUpdated"}
          />
        </ListViewViewManager>

        <ListViewViewManager visible={LIST_VIEW_TABLE_VIEW_TYPE}>
          <ListViewTable
            emptyStateOverride={
              <ProjectTreeEmptyState
                level={!subgroupName && groupName ? "group" : "global"}
              />
            }
            mt={9}
            sortingStrategy={sortingStrategy}
            defaultSort={[
              {
                id: ColumnIds.PROJECT_V3_UPDATED_AT,
                desc: true,
              },
            ]}
          />
        </ListViewViewManager>
      </ListView>
    </>
  );
};
