import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
} from "@chakra-ui/react";
import { FormLabel, Input, Link, useColors } from "@zeet/web-ui";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { SourceTabPanelProps } from ".";
import { Source, SourceKind } from "..";

const makeSourceFromTerraformModule = (
  source: string,
  version: string
): Source => {
  return {
    kind: SourceKind.TerraformModule,
    terraformModule: {
      source,
      version,
    },
  };
};

export const TerraformModuleSource = (props: SourceTabPanelProps) => {
  const { bg } = useColors();
  const {
    formState: { errors },
    register,
    watch,
  } = useForm({
    defaultValues: {
      moduleSource: "",
      moduleVersion: "",
    },
  });

  const { moduleSource, moduleVersion } = watch();

  useEffect(() => {
    if (moduleSource) {
      props.onSourceChange(
        makeSourceFromTerraformModule(moduleSource, moduleVersion)
      );
    }
  }, [moduleSource, moduleVersion, props]);

  return (
    <Flex mt="4" gap="4" flexDirection="column">
      <FormControl isRequired isInvalid={!!errors.moduleSource?.message}>
        <FormLabel>Module Source</FormLabel>
        <Input
          placeholder="terraform-aws-modules/vpc/aws"
          _placeholder={{ opacity: 0.5 }}
          autoFocus
          bg={bg}
          {...register("moduleSource", {
            validate: (value) => {
              if (value && value.trim()) return true;
              return "Module source must be present";
            },
          })}
        />
        <FormErrorMessage>{errors.moduleSource?.message}</FormErrorMessage>
        <FormHelperText>
          Need help finding the source?{" "}
          <Link
            display="inline-flex"
            alignItems="center"
            to="https://developer.hashicorp.com/terraform/language/modules/sources#terraform-registry"
          >
            Check out Terraform docs <ExternalLinkIcon ml="1" />
          </Link>
        </FormHelperText>
      </FormControl>
      <FormControl isInvalid={!!errors.moduleVersion?.message}>
        <FormLabel>Module Version</FormLabel>
        <Input
          placeholder="latest"
          _placeholder={{ opacity: 0.5 }}
          bg={bg}
          {...register("moduleVersion")}
        />
        <FormErrorMessage>{errors.moduleVersion?.message}</FormErrorMessage>
      </FormControl>
    </Flex>
  );
};
