import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Flex, Heading, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import {
  RepoDetailFragment,
  TroubleshootingIssueBlockType,
  useDeploymentAlertsQuery,
} from "@zeet/web-api/dist/graphql";
import { ProjectStatus } from "@zeet/web-api/dist/graphqlv1";
import {
  Button,
  Card,
  deploymentStatus,
  statusTextMap,
  useCurrentTeamUser,
} from "@zeet/web-ui";
import { isSampleProject } from "~/features/Project/New/makeCreateSampleProjectInput";
import { AbsoluteRoutes } from "~/utils/routes";
import { DeploymentDetail } from ".";
import { useProjectV3Context } from "../ProjectV3Provider";
import { troubleshootLinkMap } from "../util";

type ProjectIssue = {
  description: string;
  severity: "error" | "warning" | "recommendation";
  linkText?: string | React.ReactNode;
  link?: string;
};

interface ProjectIssuesProps {
  repo: RepoDetailFragment;
  deployment?: DeploymentDetail | null;
}

export const ProjectIssues = ({ repo, deployment }: ProjectIssuesProps) => {
  const currentTeamUser = useCurrentTeamUser();
  const { projectStatus } = useProjectV3Context();
  const { data: alertData } = useDeploymentAlertsQuery({
    variables: {
      deploymentID: deployment?.id ?? "",
      page: { first: 1 },
    },
    skip: !deployment,
  });

  const makeIssues = (): ProjectIssue[] => {
    const issues: ProjectIssue[] = [];
    const deployStatus = deployment?.deployStatus;

    const showPendingClusterBanner =
      projectStatus?.team?.project?.status ===
        ProjectStatus.PendingHealthyCluster &&
      repo?.cluster?.id &&
      !isSampleProject(repo.name);

    if (showPendingClusterBanner) {
      issues.push({
        description: "Healthy cluster required to deploy",
        severity: "error",
        linkText: "Fix issue",
        link: `/${currentTeamUser.login}/console/clusters/${repo?.cluster?.id}`,
      });
    }

    if ((deployStatus?.troubleshooting?.issue?.blocks?.length ?? 0) > 0) {
      const troubleshootingBlocks =
        deployStatus?.troubleshooting?.issue?.blocks ?? [];
      const troubleshootingIssues = troubleshootingBlocks
        .filter(
          (block) => block.blockType === TroubleshootingIssueBlockType.Evidence
        )
        .map(
          (block): ProjectIssue => ({
            description: block.content ?? "",
            severity: "error",
          })
        );
      issues.push(...troubleshootingIssues);
    }

    if (deployment?.deploymentError?.message) {
      issues.push({
        description: deployment?.deploymentError?.message,
        severity: "error",
      });
    }

    if (deployStatus?.errorMessage) {
      issues.push({
        description: deployStatus.errorMessage,
        severity: "error",
      });
    }

    const status = deploymentStatus(repo, deployment);
    const troubleshootingLink = troubleshootLinkMap[status];
    if (troubleshootingLink) {
      issues.push({
        description: `${statusTextMap[status]}`,
        severity: "warning",
        linkText: (
          <Flex alignItems="center" gap="2">
            Troubleshoot issue <ExternalLinkIcon />
          </Flex>
        ),
        link: troubleshootingLink,
      });
    }

    const hasAlerts =
      (alertData?.currentUser.deployment?.alerts?.nodes.length ?? 0) > 0;

    if (hasAlerts && deployment) {
      issues.push({
        description: "Alerts are firing for this deployment",
        severity: "warning",
        linkText: "View alerts",
        link:
          AbsoluteRoutes.deploymentAlerts(currentTeamUser, repo, deployment) ??
          "",
      });
    }

    return issues;
  };

  const cleanIssueDescriptions = (issues: ProjectIssue[]): ProjectIssue[] => {
    return issues.map((issue) => {
      return {
        ...issue,
        description:
          issue?.description?.[0]?.toUpperCase() + issue?.description?.slice(1),
      };
    });
  };

  const addIssueActions = (issues: ProjectIssue[]): ProjectIssue[] => {
    return issues.map((issue) => {
      if (issue.description.includes("attach a container registry")) {
        return {
          ...issue,
          linkText: (
            <Flex alignItems="center" gap="2">
              Fix issue <ExternalLinkIcon />
            </Flex>
          ),
          link: "https://docs.zeet.co/registry/",
        };
      }
      return issue;
    });
  };

  const issues = cleanIssueDescriptions(addIssueActions(makeIssues()));

  if (issues.length === 0) {
    return null;
  }

  return (
    <Card p="4" borderColor="red.400">
      <Heading size="sm">Issues detected</Heading>

      <UnorderedList
        mt="3"
        display="flex"
        flexDirection="column"
        gap="3"
        opacity={0.8}
      >
        {issues.map((issue, index) => (
          <ListItem key={index}>
            <Flex alignItems="center" gap="2">
              <Text>{issue.description}</Text>
              {issue.link && (
                <Button
                  asLink
                  to={issue.link}
                  display="flex"
                  alignItems="center"
                  gap="1"
                  size="xs"
                  variant="tertiary"
                >
                  {issue.linkText ?? "Learn more"}
                </Button>
              )}
            </Flex>
          </ListItem>
        ))}
      </UnorderedList>
    </Card>
  );
};
