import { Flex, Stack } from "@chakra-ui/react";
import { Skeleton } from "@zeet/web-ui";
import React from "react";

export const ListSkeleton: React.FC = () => {
  return (
    <Flex width="100%" mt={9} borderRadius="lg" overflow="hidden">
      <Stack minW={"100%"} gap="1px">
        <Skeleton height="42px" borderRadius="0" />
        {Array.from(Array(20).keys()).map((element) => (
          <Skeleton key={element} height="50px" width="100%" borderRadius="0" />
        ))}
      </Stack>
    </Flex>
  );
};
