import {
  ProjectV3AdapterDetailFragment,
  ProjectV3Query,
  useProjectV3Query,
} from "@zeet/web-api/dist/graphql";
import {
  ProjectInfoQuery,
  ProjectStatusOnlyQuery,
  useProjectInfoQuery,
  useProjectStatusOnlyQuery,
} from "@zeet/web-api/dist/graphqlv1";
import { useCurrentTeamUser } from "@zeet/web-ui";
import React, { createContext, useContext } from "react";
import { useParams } from "react-router-dom";

interface ProjectV3Context {
  projectData?: ProjectV3Query | undefined;
  projectStatus?: ProjectStatusOnlyQuery | undefined;
  projectLoading?: boolean;
  projectInfoData?: ProjectInfoQuery | undefined;
  projectInfoLoading?: boolean;
  refetchProjectV3: () => void;
}

const noop = () => null;

const projectV3Context = createContext<ProjectV3Context>({
  refetchProjectV3: noop,
});

export const ProjectV3Provider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const currentTeamUser = useCurrentTeamUser();

  const { projectName, envName, repoName } = useParams<{
    projectName: string;
    envName: string;
    repoName: string;
  }>();

  const {
    data: projectData,
    loading: projectLoading,
    refetch: refetchProjectV3,
  } = useProjectV3Query({
    variables: {
      userId: currentTeamUser?.id,
      name: repoName,
      environmentName: envName,
      projectName,
    },
  });

  const projectAdapter: ProjectV3AdapterDetailFragment | undefined | null =
    projectData?.user.projectV3Adapters?.nodes[0];

  const { data: projectInfoData, loading: projectInfoLoading } =
    useProjectInfoQuery({
      variables: {
        teamId: currentTeamUser?.id,
        projectId: projectAdapter?.id ?? "",
      },
      skip: !projectAdapter?.id,
    });

  const { data: projectStatusData } = useProjectStatusOnlyQuery({
    variables: {
      teamId: currentTeamUser?.id,
      projectId: projectAdapter?.id ?? "",
    },
    skip: !projectAdapter?.id,
    pollInterval: 3000,
  });

  return (
    <projectV3Context.Provider
      value={{
        projectData,
        projectLoading,
        projectStatus: projectStatusData,
        projectInfoData,
        projectInfoLoading,
        refetchProjectV3,
      }}
    >
      {children}
    </projectV3Context.Provider>
  );
};

export function useProjectV3Context(): ProjectV3Context {
  return useContext(projectV3Context);
}
