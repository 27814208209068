export enum OnboardStepsEnum {
  FINISHED = "FINISHED",
}

export const isInOnboard = (location: string): boolean => {
  if (location.includes("/team-onboard")) {
    return true;
  }

  return false;
};

export const shouldBeInOnboarding = (localStorageString: string): boolean => {
  const step = getOnboardStepFromLocalStorage(localStorageString);

  if (!step || step === OnboardStepsEnum.FINISHED) {
    return false;
  }

  return true;
};

const getOnboardStepFromLocalStorage = (localStorageString: string): string => {
  const colonIndex = localStorageString.indexOf(":::");
  return localStorageString.substring(
    colonIndex + 3,
    localStorageString.length
  );
};

export const getTeamIdFromLocalStorage = (
  localStorageString: string
): string => {
  const colonIndex = localStorageString.indexOf(":::");
  return localStorageString.substring(0, colonIndex);
};
