import { useDeleteProjectMutation } from "@zeet/web-api/dist/graphqlv1";
import { AnalyticsEvent, useCurrentTeamUser, useTrack } from "@zeet/web-ui";
import { useHistory, useParams } from "react-router-dom";

export const useDeleteProject = () => {
  const { track } = useTrack();
  const history = useHistory();
  const currentTeamUser = useCurrentTeamUser();
  const { teamName } = useParams<{ teamName?: string }>();

  const onError = (error?: Error) => {
    track(AnalyticsEvent.DELETE_PROJECT_ERROR, error ? { error } : undefined);
  };

  const [deleteProject, { loading: deleteProjectLoading }] =
    useDeleteProjectMutation({
      errorPolicy: "none",
      onError,
      onCompleted: (data) => {
        if (data && data.deleteProject) {
          history.push(
            teamName
              ? `/${teamName}/console/home`
              : `/${currentTeamUser.login}/console/home`
          );
          track(AnalyticsEvent.DELETE_PROJECT_SUCCESS);
        } else {
          onError();
        }
      },
    });

  return {
    deleteProject,
    loading: deleteProjectLoading,
  };
};
