import { Flex, Text } from "@chakra-ui/react";
import { useProjectBranchesLazyQuery } from "@zeet/web-api/dist/graphql";
import { useWorkflowRunsLazyQuery } from "@zeet/web-api/dist/graphqlv1";
import {
  CenterLoading,
  DetailItem,
  DetailLabel,
  Link,
  useCurrentTeamUser,
} from "@zeet/web-ui";
import { PropsWithChildren, useEffect, useState } from "react";
import ReactTimeago from "react-timeago";
import { useProjectV3Context } from "~/components/Project/ProjectV3Provider";
import { useProjectPath } from "../hooks/useProjectPath";
import { ResourceLatestDeploymentData } from "./utils";

interface ResourceLatestDeploymentInfoProps {
  projectId: string;
}

export const ResourceLatestDeploymentInfo: React.FC<
  ResourceLatestDeploymentInfoProps
> = ({ projectId }) => {
  const path = useProjectPath();
  const [deploymentData, setDeploymentData] = useState<
    ResourceLatestDeploymentData | undefined
  >(undefined);
  const { id: teamId } = useCurrentTeamUser();
  const { projectData, projectLoading } = useProjectV3Context();
  const [fetchWorkflow, { loading: loadingWorkflow }] =
    useWorkflowRunsLazyQuery({
      variables: {
        projectId,
        teamId,
        page: { after: "0", first: 1 },
      },
      fetchPolicy: "cache-and-network",
    });
  const [fetchRepoBranch, { loading: loadingRepoBranch }] =
    useProjectBranchesLazyQuery();

  const repoId = projectData?.user.projectV3Adapters?.nodes[0]?.repo?.id;

  useEffect(() => {
    if (repoId) {
      fetchRepoBranch({
        variables: {
          id: repoId,
          active: true,
        },
        fetchPolicy: "cache-and-network",
        onCompleted: (data) => {
          const latestDeployment =
            data.currentUser.repo?.productionBranchV2?.latestDeployment;

          if (!latestDeployment) {
            return;
          }

          setDeploymentData({
            link: `${path}/deployments/${latestDeployment.id}`,
            linkContent: `${latestDeployment.id.slice(0, 4)} (${
              latestDeployment.branch
            })`,
            createdAt: latestDeployment.createdAt,
          });
        },
      });
    } else {
      fetchWorkflow({
        variables: {
          projectId,
          teamId,
          page: { after: "0", first: 1 },
        },
        fetchPolicy: "cache-and-network",
        onCompleted: (data) => {
          const latestWorkflowRun =
            data?.team?.project?.workflow?.runs.nodes?.[0];

          if (!latestWorkflowRun) {
            return;
          }

          setDeploymentData({
            link: `${path}/workflows?workflowId=${latestWorkflowRun?.id}`,
            linkContent: `Workflow Run #${latestWorkflowRun.sequence}`,
            createdAt: latestWorkflowRun.createdAt,
          });
        },
      });
    }
  }, [repoId, projectId, teamId, fetchRepoBranch, path, fetchWorkflow]);

  if (projectLoading || loadingWorkflow || loadingRepoBranch) {
    return <CenterLoading />;
  }

  if (!deploymentData) {
    return null;
  }

  return (
    <DetailItem>
      <DetailLabel>Deployment</DetailLabel>
      <Flex alignItems="center">
        <ResourceLatestDeploymentContent
          link={deploymentData.link}
          createdAt={deploymentData.createdAt}
        >
          {deploymentData.linkContent}
        </ResourceLatestDeploymentContent>
      </Flex>
    </DetailItem>
  );
};

const ResourceLatestDeploymentContent: React.FC<
  PropsWithChildren<ResourceLatestDeploymentData>
> = ({ link, createdAt, children }) => {
  return (
    <>
      <Link to={link} hoverColor="brand.100">
        {children}
      </Link>
      <Text opacity="0.5" pl={"6px"}>
        {createdAt && <ReactTimeago date={createdAt} />}
      </Text>
    </>
  );
};
