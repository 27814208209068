import {
  ReposForProjectEnvironmentBasicQuery,
  useReposForProjectEnvironmentBasicQuery,
} from "@zeet/web-api/dist/graphql";
import update from "immutability-helper";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import { useEnv, useProject } from "../Provider";
import { projectPath } from "../util";

type RepoType = {
  repo: NonNullable<
    NonNullable<
      ReposForProjectEnvironmentBasicQuery["project"]
    >["environment"]["repos"]
  >[0];
  isSelected: boolean;
};

type ReducerAction =
  | { type: "toggle"; index: number }
  | { type: "selectAll" }
  | { type: "unselectAll" }
  | {
      type: "set";
      repos?:
        | Array<Pick<RepoType, "repo" | "isSelected"> | null | undefined>
        | null
        | void
        | undefined;
    };

interface RebuildReposContextValues {
  repos: RepoType[];
}

interface RebuildReposContextActions {
  dispatch: React.Dispatch<ReducerAction>;
}

type RebuildReposContext = [
  RebuildReposContextValues,
  RebuildReposContextActions
];

const noop = () => null;

const rebuildReposContext = createContext<RebuildReposContext>([
  // default values
  {
    repos: [],
  },
  {
    dispatch: noop,
  },
]);

export const RebuildReposProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const project = useProject();
  const env = useEnv();
  const { data } = useReposForProjectEnvironmentBasicQuery({
    variables: {
      path: projectPath(project),
      environment: env?.name,
    },
  });

  const reposSelected = useMemo(() => {
    return data?.project?.environment.repos.reduce((acc, curr) => {
      return [
        {
          repo: curr,
          isSelected: true,
        },
        ...acc,
      ];
    }, [] as RepoType[]);
  }, [data]);

  const reposUnselected = useMemo(() => {
    return data?.project?.environment.repos.reduce((acc, curr) => {
      return [
        {
          repo: curr,
          isSelected: false,
        },
        ...acc,
      ];
    }, [] as RepoType[]);
  }, [data]);

  const [repos, dispatch] = useReducer((repos: any, action: ReducerAction) => {
    switch (action.type) {
      case "toggle": {
        const isSelected = !repos[action.index].isSelected;
        return update(repos, {
          [action.index]: {
            isSelected: { $set: isSelected },
          },
        });
      }
      case "set": {
        return update(repos, {
          $set: action.repos && action.repos?.length > 0 ? action.repos : [],
        });
      }
      case "selectAll": {
        return update(repos, {
          $set: reposSelected,
        });
      }
      case "unselectAll": {
        return update(repos, {
          $set: reposUnselected,
        });
      }
    }
  }, []);

  useEffect(() => {
    dispatch({ type: "set", repos: reposSelected });
  }, [reposSelected, dispatch]);

  const actions: RebuildReposContextActions = useMemo(
    () => ({
      dispatch: (reducerAction: ReducerAction) => {
        dispatch(reducerAction);
      },
    }),
    []
  );

  return (
    <rebuildReposContext.Provider
      value={[
        {
          repos,
        },
        actions,
      ]}
    >
      {children}
    </rebuildReposContext.Provider>
  );
};

export function useRebuildReposContext(): RebuildReposContext {
  return useContext(rebuildReposContext);
}
