import {
  Box,
  FormControl,
  Heading,
  Input,
  Stack,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import Editor from "@monaco-editor/react";
import {
  RepoDetailFragment,
  useUpdateProjectSettingsMutation,
} from "@zeet/web-api/dist/graphql";
import { ZError } from "@zeet/web-ui";
import React, { useEffect, useState } from "react";
import { AiOutlineWarning } from "react-icons/ai";
import { FormCard } from "../../FormCard";
import { shouldDisplayError } from "../util";

interface TerraformProjectSettingsProps {
  repo: RepoDetailFragment;
}

export const TerraformProjectSettings: React.FC<
  TerraformProjectSettingsProps
> = ({ repo }) => {
  const toast = useToast();
  const [isDirty, setIsDirty] = useState(false);

  const [updateSettings, { error, loading, data }] =
    useUpdateProjectSettingsMutation({
      onCompleted: (data) => {
        if (data) {
          toast({
            title: "Terraform Settings Saved",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      },
    });

  const [version, setVersion] = useState(repo?.productionBranch || "");
  const [source, setSource] = useState(repo?.source.id || "");
  const [values, setValues] = useState(
    JSON.stringify(JSON.parse(repo?.terraformVariables || "{}"), null, 2)
  );

  useEffect(() => {
    setVersion(repo?.productionBranch || "latest");
  }, [repo?.productionBranch]);

  return (
    <FormCard
      isDisabled={!isDirty}
      isLoading={loading}
      title="Terraform Setup"
      onSubmit={(e) => {
        e.stopPropagation();
        e.preventDefault();
        updateSettings({
          variables: {
            input: {
              id: repo.id,
              productionBranch: version,
              terraformVariables: values,
              terraformSource: source,
            },
          },
        });
      }}
    >
      <FormControl>
        <Stack spacing={2} mt={2}>
          <Box display="flex" alignItems="center">
            <Tooltip
              placement="top"
              label="Changing this field has the potential for adverse impact on your Project. Only modify this field if you know what you are doing. Reach out to Zeet Support if you aren't sure before making any changes."
            >
              <Box display="flex" alignItems="center">
                <Heading as="h4" size="sm" mr={2}>
                  Module Source
                </Heading>
                <AiOutlineWarning size={20} />
              </Box>
            </Tooltip>
          </Box>
          <Input
            value={source}
            onChange={(e) => {
              setIsDirty(true);
              setSource(e.target.value);
            }}
          />
          <Heading as="h4" size="sm">
            Version
          </Heading>
          <Input
            value={version}
            onChange={(e) => {
              setIsDirty(true);
              setVersion(e.target.value);
            }}
            placeholder="latest"
          />
          <Heading as="h4" size="sm">
            Variables
          </Heading>
          <Editor
            theme="vs-dark"
            value={values}
            defaultLanguage="yaml"
            options={{
              lineNumbers: "off",
              glyphMargin: false,
              lineDecorationsWidth: 0,
              lineNumbersMinChars: 0,
              minimap: {
                enabled: false,
              },
            }}
            height="20rem"
            width="100%"
            onChange={(e) => {
              setIsDirty(true);
              setValues(e || "");
            }}
          />
          {shouldDisplayError(error, data) && <ZError error={error} />}
        </Stack>
      </FormControl>
    </FormCard>
  );
};
