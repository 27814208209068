import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Link, useCurrentTeamUser } from "@zeet/web-ui";
import React from "react";
import { LinkCell } from "../../../ListViewV2/Cells";
import { projectV3Path } from "../../../Project/util";
import { IProjectV3AdaptersList } from "../../../types/clouds-view";

export const RepoList: React.FC<IProjectV3AdaptersList> = ({
  projectV3Adapters,
}) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const currentTeamUser = useCurrentTeamUser();

  return (
    <Box>
      <Box data-testid="repo-list-count">
        {projectV3Adapters && projectV3Adapters.length > 0 ? (
          <LinkCell
            to="/"
            as="button"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onOpen();
            }}
          >
            {projectV3Adapters.length.toString()}
          </LinkCell>
        ) : (
          "0"
        )}
      </Box>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="md"
        isCentered
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Projects</ModalHeader>
          <ModalBody>
            <Box paddingBottom="16px">
              {projectV3Adapters &&
                projectV3Adapters.map((r, index) => {
                  if (r) {
                    return (
                      <Link
                        key={index}
                        to={`/${projectV3Path(r, currentTeamUser?.login)}`}
                        textDecoration="underline"
                      >
                        <Text data-testid={`repo-list-modal-name-${index}`}>
                          {r.name}
                        </Text>
                      </Link>
                    );
                  }
                })}
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
