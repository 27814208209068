import { SettingsIcon } from "@chakra-ui/icons";
import { Box, Button, Flex } from "@chakra-ui/react";
import {
  TeamCloudQuery,
  UserTeamMemberQuery,
} from "@zeet/web-api/dist/graphql";
import {
  CloudProvidersEnum,
  ICurrentConnection,
  Link,
  useColors,
  useTrack,
} from "@zeet/web-ui";
import * as React from "react";
import { AiOutlineEye } from "react-icons/ai";

interface UserCellProps {
  addLink: string;
  permissionsLink: string;
  userIsOwnerOrAdmin: boolean;
  teamCloudConnections: TeamCloudQuery | undefined;
  member: NonNullable<UserTeamMemberQuery["currentUser"]["team"]>["members"][0];
  connectedUsersOnOpen: () => void;
  setCurrentConnection: React.Dispatch<
    React.SetStateAction<ICurrentConnection | undefined>
  >;
}

export const UserCell: React.FC<UserCellProps> = ({
  addLink,
  permissionsLink,
  userIsOwnerOrAdmin,
  teamCloudConnections,
  member,
}) => {
  const { bg } = useColors();
  const { track } = useTrack();

  const connectCloudUsersArray: {
    connectionId: string | undefined;
    shortFormArn: string;
    member: NonNullable<
      UserTeamMemberQuery["currentUser"]["team"]
    >["members"][0];
    cloudProvider: CloudProvidersEnum;
  }[] = [];
  teamCloudConnections?.currentUser?.team?.awsAccounts?.map((awsAccount) => {
    awsAccount.userIamConnections?.map((uic) => {
      if (uic.userId === member.user.id) {
        const user = awsAccount.iamUsers?.find((iamUser) => {
          return iamUser.arn === uic.awsIamArn;
        });
        connectCloudUsersArray.push({
          shortFormArn: `${awsAccount.accountID}/${user?.username}`,
          connectionId: uic.id,
          member,
          cloudProvider: CloudProvidersEnum.AWS,
        });
      }
    });
  });

  if (connectCloudUsersArray.length) {
    return (
      <Box>
        <Flex>
          {userIsOwnerOrAdmin && (
            <Flex>
              <Button
                aria-label="settings-button"
                leftIcon={<SettingsIcon />}
                size="sm"
                as={Link}
                to={addLink}
                bg={bg}
                borderWidth="1px"
                _hover={{ textDecoration: "none", opacity: "1.0" }}
                opacity="0.6"
                onClick={() => track("zeet_user_to_cloud_user_edit")}
              >
                Connect
              </Button>
              <Button
                aria-label="permissions-button"
                leftIcon={<AiOutlineEye />}
                size="sm"
                ml="6px"
                as={Link}
                to={permissionsLink}
                bg={bg}
                borderWidth="1px"
                _hover={{ textDecoration: "none", opacity: "1.0" }}
                opacity="0.6"
                onClick={() => track("zeet_user_permissions")}
              >
                Permissions
              </Button>
            </Flex>
          )}
        </Flex>
      </Box>
    );
  }

  return (
    <Box>
      <Flex alignItems="center">
        {userIsOwnerOrAdmin && (
          <Button
            aria-label="add-button"
            to={addLink}
            as={Link}
            size="sm"
            bg={bg}
            borderWidth="1px"
            opacity="0.6"
            _hover={{ textDecoration: "none", color: "brand.500" }}
            onClick={() => track("zeet_user_to_cloud_user_connect_1")}
          >
            + Connect
          </Button>
        )}
      </Flex>
    </Box>
  );
};
