import { WarningIcon } from "@chakra-ui/icons";
import {
  Box,
  DeepPartial,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Grid,
  Tag,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { DiffEditor, useMonaco } from "@monaco-editor/react";
import { RevisableEntity } from "@zeet/web-api/dist/graphqlv1";
import { Uri } from "monaco-editor";
import { useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import Draggable from "react-draggable";
import { FaArrowLeft } from "react-icons/fa";
import {
  MdClose,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
} from "react-icons/md";
import { useColors } from "../..";
import { TimeBox } from "../../components/TimeBox";
import { useMonacoTheme } from "../../hooks/useMonacoTheme";
import { Avatar } from "../Avatar";
import { Card } from "../Card";
import { EditorInput } from "../EditorInput";
import { IconButton } from "../IconButton";
import "./RevisionDetailDrawer.scss";
import { formatRevisionData } from "./util";

export const getRevisionDescription = (
  revision: DeepPartial<RevisableEntity> | undefined
) => {
  if (revision?.revisionMetadata?.description) {
    return revision?.revisionMetadata?.description;
  }
  if (revision?.revisionMetadata?.sequenceId === 0) {
    return "Initial revision";
  }
  return "Changed configuration";
};

interface RevisionDetailDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  onNextRevision: () => void;
  onPreviousRevision: () => void;
  revision?: DeepPartial<RevisableEntity>;
  revisions: DeepPartial<RevisableEntity>[];
}

const ResizeHandle = ({ id }: { id: string }) => {
  const { brand } = useColors();
  return (
    <>
      {createPortal(
        <Draggable
          axis="x"
          onDrag={(_, data) => {
            document
              .getElementById(`chakra-modal-${id}`)
              ?.style.setProperty("max-width", `${Math.abs(data.x) + 512}px`);
          }}
          bounds={{ right: 0 }}
        >
          <Box
            minH="100vh"
            pos="absolute"
            top="0"
            right="655px"
            width="4px"
            zIndex="9999"
            backgroundColor="transparent"
            transition="background-color 0.2s ease"
            cursor="col-resize"
            _hover={{
              backgroundColor: brand,
            }}
          />
        </Draggable>,
        document.body
      )}
    </>
  );
};

export const RevisionDetailDrawer = ({
  isOpen,
  onClose,
  onNextRevision,
  onPreviousRevision,
  revision,
  revisions,
}: RevisionDetailDrawerProps) => {
  const monaco = useMonaco();
  const [editorUri, setEditorUri] = useState<Uri | null>();
  const [diffEditorUri, setDiffEditorUri] = useState<Uri | null>();
  const comparingWith = useMemo<
    DeepPartial<RevisableEntity> | null | undefined
  >(() => {
    if (!revision) return null;
    const index = revisions.indexOf(revision);
    return index + 1 >= revisions.length ? null : revisions[index + 1];
  }, [revision, revisions]);

  useMonacoTheme();

  useEffect(() => {
    if (editorUri) {
      const { json } = formatRevisionData(revision);
      monaco?.editor?.getModel(editorUri)?.setValue(json);
    }
  }, [monaco, editorUri, revision]);

  useEffect(() => {
    if (isOpen) return;
    setEditorUri(null);
    setDiffEditorUri(null);
  }, [isOpen]);

  useEffect(() => {
    if (diffEditorUri && editorUri && comparingWith && monaco && isOpen) {
      const { hashes: startHashes } = formatRevisionData(revision);
      const { json, hashes: newHashes } = formatRevisionData(comparingWith);

      const diffModel = monaco.editor?.getModel(diffEditorUri);
      const model = monaco.editor?.getModel(editorUri);
      diffModel?.setValue(json);

      // try to find changed values
      const matches = model
        ?.findMatches(
          `"Value": "********"`, // this is a very specific string, yes
          false,
          false,
          true,
          null,
          false
        )
        .filter((m) => {
          // try to find the name, should be on the line above
          const lineAbove = model.getLineContent(m.range.startLineNumber - 1);
          const match = lineAbove.match(/"Name": "(.*)",/);
          if (!match || !match[1]) return false;

          // find the matching item
          const varName = JSON.parse(`"${match[1]}"`);

          // check if the hash has changed
          const oldHash = startHashes.find((h) => h.name === varName);
          const newHash = newHashes.find((h) => h.name === varName);
          return oldHash && newHash && oldHash?.hash !== newHash?.hash;
        });

      // and add line decorations for them all
      const decorations = monaco.editor
        .getDiffEditors()[0]
        ?.createDecorationsCollection(
          matches?.map((m) => ({
            range: m.range,
            options: {
              isWholeLine: true,
              className: "variable-changed-line-decoration",
              overviewRuler: {
                color: "rgba(0, 255, 0, 0.3)",
                darkColor: "rgba(0, 255, 0, 0.1)",
                position: monaco.editor.OverviewRulerLane.Full,
              },
            },
          }))
        );

      const { dispose } = monaco.languages.registerInlayHintsProvider("json", {
        provideInlayHints() {
          return {
            hints:
              matches?.map((m) => ({
                kind: monaco.languages.InlayHintKind.Type,
                position: {
                  column: m.range.endColumn,
                  lineNumber: m.range.endLineNumber,
                },
                label: `Changed`,
                paddingLeft: true,
              })) ?? [],
            dispose() {
              // nothing to clean up!
            },
          };
        },
      });

      return () => {
        decorations?.clear();
        dispose();
      };
    }
  }, [monaco, diffEditorUri, comparingWith, revision, editorUri, isOpen]);

  const isLatestRevision =
    revision?.revisionMetadata?.sequenceId ===
    revisions[0]?.revisionMetadata?.sequenceId;
  const isFirstRevision = revision?.revisionMetadata?.sequenceId === 0;
  const id = `revision-detail-drawer-${revision?.revisionMetadata?.id}`;

  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      size="lg"
      preserveScrollBarGap
      id={id}
    >
      <ResizeHandle id={id} />
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader as={Flex} justifyContent="space-between">
          <Flex alignItems="center" gap="2">
            {comparingWith ? (
              "Comparing Revisions"
            ) : (
              <>
                Revision #{revision?.revisionMetadata?.sequenceId}{" "}
                {isLatestRevision && <Tag>latest</Tag>}
              </>
            )}
          </Flex>
          <Flex gap="2">
            <IconButton
              variant="secondary"
              icon={<MdKeyboardArrowUp />}
              aria-label="up"
              onClick={onNextRevision}
              disabled={isLatestRevision}
              size="sm"
            />
            <IconButton
              variant="secondary"
              icon={<MdKeyboardArrowDown />}
              aria-label="down"
              onClick={onPreviousRevision}
              disabled={isFirstRevision}
              size="sm"
            />
            <IconButton
              variant="secondary"
              icon={<MdClose />}
              aria-label="close"
              onClick={onClose}
              size="sm"
            />
          </Flex>
        </DrawerHeader>

        <DrawerBody mb="4">
          {comparingWith ? (
            <Grid gridTemplateColumns="1fr auto 1fr" mb={4} gap={2}>
              <RevisionCard revision={comparingWith} slim showName />
              <Box alignSelf="center">
                <FaArrowLeft />
              </Box>
              <RevisionCard revision={revision} slim showName />
            </Grid>
          ) : (
            <Box mb={4}>
              <RevisionCard revision={revision} />
            </Box>
          )}
          <Flex height="calc(100% - 125px)">
            {comparingWith ? (
              <DiffEditor
                options={{
                  minimap: {
                    enabled: false,
                  },
                  readOnly: true,
                  renderSideBySide: false,
                  inlayHints: {
                    padding: true,
                  },
                }}
                theme="zeet-dark"
                onMount={(editor) => {
                  setEditorUri(editor?.getModifiedEditor()?.getModel()?.uri);
                  setDiffEditorUri(
                    editor?.getOriginalEditor()?.getModel()?.uri
                  );
                }}
                language="json"
                wrapperProps={{
                  "data-testid": "revision-detail-diff-editor",
                }}
              />
            ) : (
              <EditorInput
                options={{
                  minimap: {
                    enabled: false,
                  },
                  readOnly: true,
                }}
                wrapperProps={{
                  "data-testid": "revision-detail-editor",
                  className: "monaco-editor-wrapper",
                }}
                defaultLanguage="json"
                onMount={(editor) => {
                  setEditorUri(editor?.getModel()?.uri);
                }}
              />
            )}
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

interface RevisionCardProps {
  revision?: DeepPartial<RevisableEntity>;
  slim?: boolean;
  title?: string;
  showName?: boolean;
}

const RevisionCard = ({ revision, slim, showName }: RevisionCardProps) => {
  const { fg2 } = useColors();

  const showAuthorInfo = !!revision?.revisionMetadata?.authorInfo?.summary;
  const isCreatorDeleted =
    !!revision?.revisionMetadata?.authorInfo?.creatorDeleted;

  return (
    <Card
      as={Flex}
      pt={showName ? "2" : "4"}
      px="4"
      pb="4"
      gap="1"
      flexDir={"column"}
      justifyContent="space-between"
      flexGrow={1}
      flexShrink={0}
    >
      {showName && (
        <Text fontWeight={600} color={fg2} fontSize="xs">
          Revision #{revision?.revisionMetadata?.sequenceId}
        </Text>
      )}
      <Flex mb={1}>{getRevisionDescription(revision)}</Flex>
      <Box opacity="0.7">
        <Flex fontSize="14px" alignItems="center" gap="2">
          {!slim && <Text>Revised by</Text>}
          <Avatar size="xs" user={revision?.revisionMetadata?.createdBy} />
          <Text textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
            {revision?.revisionMetadata?.createdBy?.name}
          </Text>
          {isCreatorDeleted && (
            <Tooltip label="This user has been deleted.">
              <WarningIcon />
            </Tooltip>
          )}
          {!slim && (
            <TimeBox
              time={new Date(String(revision?.revisionMetadata?.createdAt))}
            />
          )}
        </Flex>
        {showAuthorInfo && (
          <Flex>
            <Text fontSize="xs" paddingTop={2}>
              {revision?.revisionMetadata?.authorInfo?.summary}
            </Text>
          </Flex>
        )}
      </Box>
    </Card>
  );
};
