import { Box, Flex, Stack } from "@chakra-ui/react";
import {
  CloudProvider,
  ClusterProvider,
  DeployTarget,
  RepoDetailFragment,
  UserDetailFragment,
} from "@zeet/web-api/dist/graphql";
import { Link, Tip, useColors, useCurrentTeamUser } from "@zeet/web-ui";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { AbsoluteRoutes } from "~/utils/routes";
import { TabWrapper } from "..";
import { DatabaseEnvs } from "../../RepositorySelect/DatabaseEnvs";
import {
  hasBuildStep,
  hasDeploy,
  isApp,
  isAutoBuild,
  isCloudRun,
  isDatabase,
  isDocker,
  isHelm,
  isKube,
  isLambda,
  isManifest,
  isServerless,
  isTerraform,
  isTerraformRDS,
} from "../../utils/project";
import { CopyProjectButton } from "../CopyProjectButton";
import { repoPath } from "../util";
import { AlternativeTags } from "./AlternativeTags";
import { API } from "./API";
import { AwsRdsSettings } from "./AwsRds";
import { EnvironmentSettings } from "./Branch";
import {
  BranchSettingsBox,
  ProductionBranchSettingsBox,
} from "./BranchSettingsBox";
import { GithubProjectBuildSettings } from "./Build";
import {
  AdvancedBuildSettingsBox,
  BuildResourceSettingsBox,
} from "./BuildSettingsBox";
import { CDNSettings } from "./CDN";
import { CloudRunResourceInfo } from "./CloudRunResources";
import { Danger } from "./Danger";
import { DatadogApmSettings } from "./DatadogApm";
import { DeployStrategySettings } from "./Deploy";
import { DomainSettings } from "./Domain";
import { EnvVarSettingsProvider } from "./EnvVarSettingsProvider";
import { EnvVarsProjectSettings } from "./EnvVarsProjectSettings";
import { HealthCheckSettings } from "./HealthCheck";
import { HelmProjectSettings } from "./Helm";
import { DockerDeploySettings } from "./Image";
import {
  ReleaseSettings,
  TerminationSettings,
  TimeoutSettings,
} from "./Kubernetes/Advanced";
import {
  IngressAnnotationSettings,
  PodAnnotationSettings,
  ServiceAnnotationSettings,
} from "./Kubernetes/Annotations";
import { CloudIdentitySettings } from "./Kubernetes/Identity";
import { IngressSpecSettings } from "./Kubernetes/IngressSpec";
import { PodSpecSettings } from "./Kubernetes/PodSpec";
import { PrometheusSettings } from "./Kubernetes/Prometheus";
import { SecretMountSettings } from "./Kubernetes/SecretMounts";
import { LambdaDeployInfo } from "./LambdaDeployInfo";
import { LambdaResourceInfo } from "./LambdaResources";
import { LinkDatabase } from "./LinkDatabase";
import { LogShipperSettings } from "./LogShipper";
import { ManifestProjectSettings } from "./Manifest";
import {
  AdvancedNetworkSettings,
  ApiGatewaySettings,
  NetworkingSettings,
} from "./Networking";
import { RepoPipelineSettings } from "./Pipeline";
import { ReplicationSettings } from "./Replication";
import { RepoSource } from "./RepoSource";
import { TerraformProjectSettings } from "./Terraform";
import { VerticalLinks } from "./VerticalLinks";
import { VolumeSettings } from "./Volume";

const CLOUDS_WITH_CDN = [CloudProvider.Aws];
const CLOUDS_WITH_OIDC = [CloudProvider.Aws, CloudProvider.Gcp];
const CLOUDS_WITH_DOMAIN = [
  CloudProvider.Aws,
  CloudProvider.Gcp,
  CloudProvider.Do,
  CloudProvider.Linode,
  CloudProvider.Coreweave,
  CloudProvider.Azure,
  CloudProvider.Unknown,
];

export const SettingsTab = () => {
  return <TabWrapper component={Settings} />;
};

const Settings: React.FC<{
  repo: RepoDetailFragment;
}> = ({ repo }) => {
  const url = `/${repoPath(repo)}/settings`;
  const { bg2 } = useColors();
  const currentTeamUser = useCurrentTeamUser();

  const cloud = repo?.cluster?.cloudProvider as CloudProvider;

  const isZeetKubeApp = isKube(repo) && isApp(repo);
  const isZeetServerlessApp = isServerless(repo);

  // ports
  const enableNetworking =
    (isZeetKubeApp || isZeetServerlessApp) && hasDeploy(repo);

  // domain
  const enableDomainSettings =
    enableNetworking &&
    ((isZeetKubeApp && CLOUDS_WITH_DOMAIN.includes(cloud)) ||
      (isZeetKubeApp &&
        repo?.cluster?.clusterProvider === ClusterProvider.Generic) ||
      (isZeetServerlessApp && isLambda(repo)));

  const enableCDN = isZeetKubeApp && CLOUDS_WITH_CDN.includes(cloud);

  const enableCloudIdentity = isZeetKubeApp && CLOUDS_WITH_OIDC.includes(cloud);

  const enableAdvanced =
    hasBuildStep(repo) || isZeetKubeApp || isTerraformRDS(repo);

  const hasEnvVar = hasBuildStep(repo) || hasDeploy(repo);

  const links = [
    { text: "General", url: `${url}` },
    { text: "Networking", url: `${url}/networking`, show: enableNetworking },
    { text: "CI/CD", url: `${url}/source` },
    {
      text: "Database",
      url: `${url}/database`,
      show: !isDatabase(repo) || !isTerraform(repo),
    },
    {
      text: "Logging & Monitoring",
      url: `${url}/logging`,
      show: isZeetKubeApp,
    },
    { text: "Health Check", url: `${url}/health`, show: isZeetKubeApp },
    { text: "Kubernetes", url: `${url}/kubernetes`, show: isZeetKubeApp },
    { text: "Advanced", url: `${url}/advanced`, show: enableAdvanced },
    { text: "API", url: `${url}/api` },
    { text: "Danger Zone", url: `${url}/danger` },
  ];

  const visibleLinks = links.filter((link) => link.show !== false);

  const hasSource = isZeetKubeApp || isServerless(repo);

  return (
    <Flex flexDirection={["column", "column", "row"]}>
      <VerticalLinks links={visibleLinks} />
      <Switch>
        <Route exact path={url}>
          <Stack spacing={4} flex={1}>
            {hasSource && <RepoSource repo={repo} />}
            {isDocker(repo) && <DockerDeploySettings repo={repo} />}
            {isAutoBuild(repo) && <GithubProjectBuildSettings repo={repo} />}
            {isHelm(repo) && <HelmProjectSettings repo={repo} />}
            {isManifest(repo) && <ManifestProjectSettings repo={repo} />}
            {isTerraform(repo) &&
              (isTerraformRDS(repo) ? (
                <AwsRdsSettings repo={repo} />
              ) : (
                <TerraformProjectSettings repo={repo} />
              ))}
            {repo.databaseEnvs.length > 0 && (
              <DatabaseEnvs repo={repo} placement="settings" />
            )}
            {hasEnvVar && (
              <EnvVarSettingsProvider envsData={repo.envs}>
                <EnvVarsProjectSettings repo={repo} />
              </EnvVarSettingsProvider>
            )}
            {isAutoBuild(repo) && !hasDeploy(repo) && (
              <RepoPipelineSettings repo={repo} />
            )}
            {isLambda(repo) && <LambdaDeployInfo repo={repo} />}
            {isLambda(repo) && <LambdaResourceInfo repo={repo} />}
            {isCloudRun(repo) && <CloudRunResourceInfo repo={repo} />}
            {isZeetKubeApp && <ReplicationSettings repo={repo} />}
            {isZeetKubeApp && <VolumeSettings repo={repo} />}
            <CopyProjectButton repo={repo} />
          </Stack>
        </Route>

        <Route exact path={url + "/networking"}>
          <Stack spacing={4} flex={1}>
            {enableDomainSettings && (
              <Box
                p={4}
                shadow="md"
                borderWidth="1px"
                bg={bg2}
                borderRadius="md"
              >
                <DomainSettings repo={repo} />
              </Box>
            )}
            {enableNetworking && (
              <Box
                p={4}
                shadow="md"
                borderWidth="1px"
                bg={bg2}
                borderRadius="md"
              >
                <NetworkingSettings repo={repo} />
              </Box>
            )}
            {enableCDN && (
              <Box
                p={4}
                shadow="md"
                borderWidth="1px"
                bg={bg2}
                borderRadius="md"
              >
                <CDNSettings repo={repo} />
              </Box>
            )}
            {isServerless(repo) &&
              repo.deployTarget !== DeployTarget.GcpCloudRun && (
                <Box
                  p={4}
                  shadow="md"
                  borderWidth="1px"
                  bg={bg2}
                  borderRadius="md"
                >
                  <ApiGatewaySettings repo={repo} />
                </Box>
              )}
            {isZeetKubeApp && (
              <Box
                p={4}
                shadow="md"
                borderWidth="1px"
                bg={bg2}
                borderRadius="md"
              >
                <AdvancedNetworkSettings repo={repo} />
              </Box>
            )}
          </Stack>
        </Route>

        <Route exact path={url + "/source"}>
          <Stack spacing={4} flex={1}>
            <Box p={4} shadow="md" borderWidth="1px" bg={bg2} borderRadius="md">
              <EnvironmentSettings repo={repo} />
            </Box>
            <ProductionBranchSettingsBox repo={repo} />
            {isZeetKubeApp && <BranchSettingsBox repo={repo} />}
          </Stack>
        </Route>

        {!isDatabase(repo) && (
          <Route exact path={url + "/database"}>
            <Stack spacing={4} flex={1}>
              <LinkDatabase repo={repo} />
            </Stack>
          </Route>
        )}

        {isZeetKubeApp && (
          <Route exact path={url + "/logging"}>
            <LoggingSettings currentTeamUser={currentTeamUser} repo={repo} />
          </Route>
        )}
        {isZeetKubeApp && (
          <Route exact path={url + "/health"}>
            <Stack spacing={4} flex={1}>
              <Box
                p={4}
                shadow="md"
                borderWidth="1px"
                bg={bg2}
                borderRadius="md"
              >
                <HealthCheckSettings repo={repo} />
              </Box>
            </Stack>
          </Route>
        )}
        {isZeetKubeApp && (
          <Route exact path={url + "/kubernetes"}>
            <Stack spacing={4} flex={1}>
              <SecretMountSettings repo={repo} />
              <PodAnnotationSettings repo={repo} />
              <ServiceAnnotationSettings repo={repo} />
              <IngressAnnotationSettings repo={repo} />
              {enableCloudIdentity && (
                <Box
                  p={4}
                  shadow="md"
                  borderWidth="1px"
                  bg={bg2}
                  borderRadius="md"
                >
                  <CloudIdentitySettings repo={repo} />
                </Box>
              )}
              <PodSpecSettings repo={repo} />
              <IngressSpecSettings repo={repo} />
            </Stack>
          </Route>
        )}
        <Route exact path={url + "/advanced"}>
          <Stack spacing={4} flex={1}>
            {isTerraformRDS(repo) && <TerraformProjectSettings repo={repo} />}
            {hasBuildStep(repo) && (
              <>
                <BuildResourceSettingsBox repo={repo} />
                <AdvancedBuildSettingsBox repo={repo} />
                <Box>
                  <AlternativeTags repo={repo} />
                </Box>
              </>
            )}
            {isZeetKubeApp && (
              <>
                <Box
                  p={4}
                  shadow="md"
                  borderWidth="1px"
                  bg={bg2}
                  borderRadius="md"
                >
                  <ReleaseSettings repo={repo} />
                </Box>
                <Box
                  p={4}
                  shadow="md"
                  borderWidth="1px"
                  bg={bg2}
                  borderRadius="md"
                >
                  <DeployStrategySettings repo={repo} />
                </Box>
                <Box
                  p={4}
                  shadow="md"
                  borderWidth="1px"
                  bg={bg2}
                  borderRadius="md"
                >
                  <TerminationSettings repo={repo} />
                </Box>
              </>
            )}
            <Box p={4} shadow="md" borderWidth="1px" bg={bg2} borderRadius="md">
              <TimeoutSettings repo={repo} />
            </Box>
          </Stack>
        </Route>
        <Route exact path={url + "/danger"}>
          <Danger repo={repo} />
        </Route>
        <Route exact path={url + "/api"}>
          <API repo={repo} />
        </Route>
        <Redirect path={url} to={url} />
      </Switch>
    </Flex>
  );
};

const LoggingSettings: React.FC<{
  currentTeamUser: UserDetailFragment;
  repo: RepoDetailFragment;
}> = ({ currentTeamUser, repo }) => {
  const { bg2 } = useColors();

  return (
    <Stack spacing={4} flex={1}>
      <Tip id="pod-crash-tips">
        Did you know Zeet can send notifications when a Kubernetes pod crash is
        detected? Check out the Notification options in the{" "}
        <Link
          to={AbsoluteRoutes.teamNotificationSettings(currentTeamUser)}
          textDecoration="underline"
        >
          team settings
        </Link>
        .
      </Tip>
      <Box p={4} shadow="md" borderWidth="1px" bg={bg2} borderRadius="md">
        <LogShipperSettings repo={repo} />
      </Box>
      <Box p={4} shadow="md" borderWidth="1px" bg={bg2} borderRadius="md">
        <PrometheusSettings repo={repo} />
      </Box>
      <Box p={4} shadow="md" borderWidth="1px" bg={bg2} borderRadius="md">
        <DatadogApmSettings repo={repo} />
      </Box>
    </Stack>
  );
};
