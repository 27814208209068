import { useIsFormValueSet } from "~/hooks/useIsFormValueSet";
import { InputProvider, InputRow, StringInput } from "../../../inputs";
import { CloudProviderInput } from "../../../inputs/CloudProviderInput";
import { IntegerInput } from "../../../inputs/IntegerInput";
import { SettingsSection } from "../../SettingsSection";

export const AwsSamSettings = () => {
  if (!useIsFormValueSet("configuration.awsSam")) return null;

  return (
    <SettingsSection
      basePath="awsSam"
      name="AWS SAM"
      description="Configure additional settings for your serverless deployment"
    >
      <InputProvider basePath="target">
        <InputRow>
          <CloudProviderInput name="Cloud" />
          <StringInput name="Region" path="awsRegion" enabledInDraftOnly />
        </InputRow>
        <StringInput name="Stack Name" path="stackName" enabledInDraftOnly />
      </InputProvider>
      <InputProvider basePath="generator">
        <StringInput name="Run command" path="runCommand" />
        <StringInput name="HTTP Port" path="httpPort" placeholder="3000" />
        <InputRow>
          <IntegerInput
            name="Memory"
            path="serverlessMemory"
            placeholder="0.25"
          />
        </InputRow>
      </InputProvider>
    </SettingsSection>
  );
};
