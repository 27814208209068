import { useToast } from "@chakra-ui/react";
import { useUpdateProjectEnvironmentVariablesMutation } from "@zeet/web-api/dist/graphql";
import React, { useEffect } from "react";
import { useProject } from "../Provider";
import { MenuButtons } from "../Settings/EnvMenuButtons";
import { useEnvVarSettingsContext } from "../Settings/EnvVarSettingsProvider";
import { EnvVarSettings } from "../Settings/EnvVarsSettings";

const Envs: React.FC = () => {
  const toast = useToast();
  const [{ envs }, { parseEnv }] = useEnvVarSettingsContext();
  const project = useProject();
  const [updateEnvs, { error, loading }] =
    useUpdateProjectEnvironmentVariablesMutation({
      errorPolicy: "all",
      onCompleted: (data) => {
        if (data) {
          toast({
            title: "Environment Variables Saved",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      },
    });

  const onSubmit = () => {
    updateEnvs({
      variables: {
        input: {
          id: project.id,
          envs: envs
            .filter((e) => e.name)
            .map((e) => ({
              name: e.name.trim(),
              value: e.value.trim(),
              sealed: e.sealed,
            })),
        },
      },
    });
  };

  const menuButtons = React.useMemo(() => {
    return <MenuButtons handleEnvFile={parseEnv}></MenuButtons>;
  }, [parseEnv]);

  useEffect(() => {
    if (error) {
      toast({
        title: "Failed to save environment variables",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [error, toast]);

  if (error) {
    console.error(error);
  }

  return (
    <EnvVarSettings
      actionButtons={menuButtons}
      onSubmit={onSubmit}
      error={error}
      loading={loading}
    />
  );
};

export default Envs;
