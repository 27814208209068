import { Box, Flex } from "@chakra-ui/react";
import { Blueprint, BlueprintType } from "@zeet/web-api/dist/graphql";
import { BlueprintType as BlueprintTypeV2 } from "@zeet/web-api/dist/graphqlv1";
import { FC, ReactNode } from "react";
// cspell:disable
import {
  SiAwslambda,
  SiGooglecloud,
  SiHelm,
  SiKubernetes,
  SiTerraform,
} from "react-icons/si";
// cspell:enable
import {
  BridgeBlueprintType,
  HorizontalLogo,
  LegacyBlueprintType,
} from "@zeet/web-ui";

type BlueprintDisplayType = {
  [key in BridgeBlueprintType]: {
    icon: ReactNode;
    name: string;
  };
};

export const BlueprintDriverTypeCell: FC<{ blueprint?: Blueprint }> = ({
  blueprint,
}) => {
  if (!blueprint) {
    return null;
  }

  const typeDisplay: BlueprintDisplayType = {
    [BlueprintType.Terraform]: {
      icon: <SiTerraform size={14} />,
      name: "Terraform",
    },
    [BlueprintType.KubernetesManifest]: {
      icon: <SiKubernetes size={14} />,
      name: "Kubernetes Manifest",
    },
    [BlueprintType.ZeetKubernetes]: {
      icon: <HorizontalLogo hideWordMark boxSize="16px" />,
      name: "Zeet App",
    },
    [BlueprintType.ZeetAwsLambda]: {
      icon: <HorizontalLogo hideWordMark boxSize="16px" />,
      name: "Zeet App",
    },
    [BlueprintType.ZeetGcpCloudRun]: {
      icon: <HorizontalLogo hideWordMark boxSize="16px" />,
      name: "Zeet App",
    },
    [BlueprintType.Helm]: {
      icon: <SiHelm size={14} />,
      name: "Helm",
    },
    [LegacyBlueprintType.Terraform]: {
      icon: <SiTerraform size={14} />,
      name: "Terraform",
    },
    [BlueprintTypeV2.AwsSam]: {
      icon: <SiAwslambda size={14} />, // cspell:disable-line
      name: "AWS Lambda",
    },
    [BlueprintTypeV2.GcpCloudRun]: {
      icon: <SiGooglecloud size={14} />, // cspell:disable-line
      name: "Google Cloud Run",
    },
  };

  return (
    <Box overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
      <Flex alignItems="center">
        <Box mr="1">{typeDisplay[blueprint.type]?.icon}</Box>
        {typeDisplay[blueprint.type]?.name}
      </Flex>
    </Box>
  );
};
