import {
  TeamMemberRole,
  UserTeamMemberQuery,
} from "@zeet/web-api/dist/graphql";
import { CloudProvidersEnum } from "../types/clouds-view";

export const roleNameMap = {
  [TeamMemberRole.Owner]: "Owner",
  [TeamMemberRole.Admin]: "Admin",
  [TeamMemberRole.Member]: "Member",
  [TeamMemberRole.Viewer]: "Viewer",
};
export interface ICurrentConnection {
  connectionId: string | null | undefined;
  cloudProvider: CloudProvidersEnum | undefined;
  shortFormArn: string | null | undefined;
  member:
    | NonNullable<UserTeamMemberQuery["currentUser"]["team"]>["members"][0]
    | undefined;
}

export interface IZeetUserConnectData {
  zeetUser: {
    name: string | null | undefined;
    login: string | null | undefined;
    avatar: string | null | undefined;
  };
  cloudAccount: {
    cloudProvider: CloudProvidersEnum | undefined;
    cloudAccount: string | null | undefined;
    cloudProviderLink: string | null | undefined;
  };
  cloudUser: {
    cloudUser: string | null | undefined;
  };
  cloudId: {
    cloudId: string | null | undefined;
    shortCloudId: string | null | undefined;
  };
  permissions: {
    permissions: ({
      arn: string;
      policyName: string;
      groupName: string;
    } | null)[];
  };
  status: {
    isConnected: boolean | undefined;
    connectionId: string | null | undefined;
  };
}
