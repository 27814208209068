import { CopyIcon } from "@chakra-ui/icons";
import { Box, Flex, IconButton, Text, useToast } from "@chakra-ui/react";
import copy from "copy-to-clipboard";
import React from "react";
import { FaDocker } from "react-icons/fa";
import { BuildImage } from "../../types/deployment";

export const BuildArtifact: React.FC<{
  buildImage: BuildImage;
  showDockerImage: boolean;
}> = ({ buildImage, showDockerImage }) => {
  const toast = useToast();

  return (
    <Flex>
      {showDockerImage && (
        <Flex alignItems="center" px="24px">
          <FaDocker size={60} />
        </Flex>
      )}
      <Box>
        <Flex>
          <Text mr="4px" fontWeight="bold">
            Tags:
          </Text>
          {buildImage.tag}
        </Flex>
        <Flex display="inline-flex">
          <Text mr="4px">
            URI: {buildImage.URI}
            <IconButton
              variant="unstyled"
              icon={<CopyIcon />}
              size="sm"
              aria-label="copy"
              onClick={() => {
                copy(buildImage.URI || "");
                toast({
                  title: `Copied`,
                  status: "success",
                  duration: 5000,
                  isClosable: true,
                });
              }}
            />
          </Text>
        </Flex>
      </Box>
    </Flex>
  );
};
