import { FormControl } from "@chakra-ui/react";
import { FormLabel, Input } from "@zeet/web-ui";
import { useFormContext } from "react-hook-form";

export const SettingsDisplayName: React.FC = () => {
  const { register } = useFormContext();
  return (
    <FormControl isRequired>
      <FormLabel>Display Name</FormLabel>
      <Input {...register("name", { required: true })} />
    </FormControl>
  );
};
