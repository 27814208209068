"use client";

import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import {
  Box,
  Collapse,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  List,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { useCombobox, UseComboboxStateChange } from "downshift";
import { PropsWithChildren } from "react";
import { useColors } from "../hooks/useColors";

interface ComboBoxProps<I> {
  isPrivateRegistry: boolean;
  isRelative?: true;
  items: I[];
  onInputValueChange?: (changes: UseComboboxStateChange<I>) => void;
  onSelectedItemChange?: (changes: UseComboboxStateChange<I>) => void;
  itemToString: (item: I | null) => string;
  initialInputValue?: string;
  inputValue?: string;
  isLoading?: boolean;
  renderListItem: (
    item: I,
    spreadProps: Record<string, unknown>,
    isHighlightedItem: boolean,
    index: number
  ) => JSX.Element;
  pagination?: JSX.Element | null;
  inputProps?: InputProps;
  isDisabled?: true;
}

export const ComboBox = <T,>({
  isPrivateRegistry,
  isRelative,
  onSelectedItemChange,
  renderListItem,
  inputValue,
  items,
  isLoading,
  onInputValueChange,
  itemToString,
  initialInputValue,
  pagination,
  inputProps,
}: PropsWithChildren<ComboBoxProps<T>>): JSX.Element => {
  const { bg } = useColors();

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    toggleMenu,
  } = useCombobox<T>({
    initialInputValue,
    inputValue,
    items,
    onInputValueChange,
    itemToString,
    onSelectedItemChange,
  });

  const zIndex = isOpen ? 10 : 2;

  return (
    <Flex
      {...getComboboxProps()}
      position="relative"
      flexDirection="column"
      padding={2}
      marginX={-2}
      bg={isOpen ? bg : ""}
      borderRadius="md"
      shadow={isOpen ? "md" : ""}
      transition="all 200ms ease"
      mb={isOpen ? 3 : undefined}
    >
      <InputGroup zIndex={zIndex.toString()}>
        <Input
          {...inputProps}
          {...getInputProps()}
          bg={bg}
          onFocus={() => (isOpen ? null : toggleMenu())}
        ></Input>

        <InputRightElement>
          <IconButton
            isDisabled={inputProps?.isDisabled}
            onClick={toggleMenu}
            aria-label="toggle mennu"
            icon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
            variant="unstyled"
          />
        </InputRightElement>
      </InputGroup>
      <Box
        zIndex={(zIndex - 1).toString()}
        bg={isOpen ? bg : ""}
        position={isRelative ? "relative" : "absolute"}
        top={0}
        left={0}
        right={0}
        borderRadius="md"
        padding={isRelative ? 0 : 2}
        pt={isRelative ? "" : "3rem"}
      >
        <Collapse animateOpacity in={isOpen}>
          <List {...getMenuProps()} flex={1} mt={0} padding={2}>
            {isLoading ? (
              <Flex
                w="100%"
                justifyContent="center"
                p="1rem"
                alignItems="center"
              >
                <Spinner size="sm" speed="0.6s" />
                <Text size="sm" ml="1rem">
                  Loading...
                </Text>
              </Flex>
            ) : items.length ? (
              items.map((item, index) =>
                renderListItem(
                  item,
                  getItemProps({ item, index }),
                  index === highlightedIndex,
                  index
                )
              )
            ) : (
              <Flex
                w="100%"
                justifyContent="center"
                p="1rem"
                alignItems="center"
              >
                {isPrivateRegistry ? (
                  <Text size="sm" ml="1rem">
                    Looks like you&#39;re using a private registry!
                  </Text>
                ) : (
                  <Text size="sm" ml="1rem">
                    {`Nothing found. If you are searching a private image, your
                    image may not be listed here. Otherwise,
                    consider refining your search.`}
                  </Text>
                )}
              </Flex>
            )}
          </List>
          {pagination}
        </Collapse>
      </Box>
    </Flex>
  );
};
