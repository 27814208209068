import { IDataRow } from "../types/listview";

export const filterViewDataByText = (
  data: IDataRow[],
  filters: Array<string>
) => {
  return data.filter((d) => {
    return filters.every((filter) => {
      return Object.values(d).find((value) => {
        try {
          return value.text?.toLowerCase().includes(filter.toLowerCase());
        } catch (err) {
          return false;
        }
      });
    });
  });
};
