"use client";

import {
  Input as ChakraInput,
  InputGroup,
  InputProps,
  InputRightElement,
} from "@chakra-ui/react";
import { forwardRef, useState } from "react";
import { useColors } from "../hooks/useColors";
import { Button } from "./Button";

export const getInputStyleProps = ({ bg2 }: ReturnType<typeof useColors>) => ({
  fontSize: "14px",
  boxShadow: "md",
  background: bg2,
});

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (inputProps, ref) => {
    const colors = useColors();
    const inputStyleProps = getInputStyleProps(colors);
    const [showPassword, setShowPassword] = useState(false);

    if (inputProps.type === "password") {
      return (
        <InputGroup>
          <ChakraInput
            {...inputStyleProps}
            {...inputProps}
            ref={ref}
            type={showPassword ? "text" : "password"}
          />
          <InputRightElement width="4.5rem">
            <Button
              variant="secondary"
              h="1.75rem"
              size="sm"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? "Hide" : "Show"}
            </Button>
          </InputRightElement>
        </InputGroup>
      );
    }

    return <ChakraInput {...inputStyleProps} {...inputProps} ref={ref} />;
  }
);
