import { Box, Flex, Stack } from "@chakra-ui/react";
import { HorizontalLogo, useCurrentTeamUser } from "@zeet/web-ui";
import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import { useMenuItems } from "../utils/sidebar";
import { NavButton } from "./NavButton";

export const SIDEBAR_WIDTH = "185px";

const SidebarPosition = ({ children }) => (
  <Flex
    as="section"
    bg="bg-canvas"
    width={SIDEBAR_WIDTH}
    position="fixed"
    top="0"
    bottom={0}
    zIndex="3"
    display={["none", "none", "block"]}
  >
    {children}
  </Flex>
);

const SidebarMenu = () => {
  const currentTeamUser = useCurrentTeamUser();
  const { topMenuItems, bottomMenuItems } = useMenuItems(
    currentTeamUser?.login
  );
  const { pathname } = useLocation<{ pathname: string }>();
  const isTabActive = (tabName: string) => {
    const tabs = [
      "console/clouds",
      "console/clusters",
      "console/blueprints",
      "console/status",
      "console/people",
      "support",
      "settings",
    ];
    const isBasicPath = tabs.find((t) =>
      pathname.startsWith(`/${currentTeamUser?.login}/${t}`)
    );
    const isBillingPath = pathname.startsWith(
      `/${currentTeamUser?.login}/account/billing`
    );
    const isSettingsPath =
      pathname.startsWith(`/${currentTeamUser?.login}/account`) ||
      pathname.startsWith("/profile");
    if (isBasicPath && pathname.includes(tabName)) {
      return true;
    }
    if (isBillingPath && tabName === "usage") {
      return true;
    }

    if (isSettingsPath && tabName === "settings" && !isBillingPath) {
      return true;
    }

    if (
      !isBasicPath &&
      !isBillingPath &&
      !isSettingsPath &&
      tabName === "projects"
    ) {
      return true;
    }

    return false;
  };

  return (
    <Flex
      flex="1"
      color="on-accent"
      flexDir="column"
      height="100%"
      position="relative"
      justifyContent="space-between"
      py="4"
      px="3"
      pb="5"
    >
      <Stack>
        <Box as={Link} to={`/${currentTeamUser.login}/console/home`} mb="4">
          <HorizontalLogo logoHeight="2.5rem" logoWidth="65%" />
        </Box>
        {topMenuItems.map((menuItem, i) => (
          <NavButton
            key={i}
            label={menuItem.name}
            imageSourceWhite={menuItem.iconWhite}
            imageSourceBlack={menuItem.iconBlack}
            icon={menuItem.icon}
            link={menuItem.link}
            isActive={isTabActive(menuItem.name.toLowerCase())}
            isNew={menuItem.isNew}
          />
        ))}
      </Stack>
      <Stack>
        {bottomMenuItems.map((menuItem, index) => (
          <NavButton
            key={index}
            label={menuItem.name}
            imageSourceWhite={menuItem.iconWhite}
            imageSourceBlack={menuItem.iconBlack}
            icon={menuItem.icon}
            link={menuItem.link}
            isActive={isTabActive(menuItem.name.toLowerCase())}
            isNew={menuItem.isNew}
          />
        ))}
      </Stack>
    </Flex>
  );
};

const Sidebar: React.FC = () => (
  <SidebarPosition>
    <SidebarMenu />
  </SidebarPosition>
);

export default Sidebar;
