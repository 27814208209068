import { Button, Divider, Flex, Input, Text, useToast } from "@chakra-ui/react";
import {
  RepoDetailFragment,
  useUpdateProjectSettingsMutation,
} from "@zeet/web-api/dist/graphql";
import { ZError } from "@zeet/web-ui";
import React from "react";
import { useForm } from "react-hook-form";
import { shouldDisplayError } from "../../util";
import { ZFormLabel } from "../Build";

export const TerminationSettings: React.FC<{ repo: RepoDetailFragment }> = ({
  repo,
}) => {
  const toast = useToast();

  const { handleSubmit, register, watch } = useForm({
    defaultValues: {
      preStopSleep: repo?.preStopSleep,
      terminationGracePeriodSeconds: repo?.terminationGracePeriodSeconds || 30,
    },
  });

  const watchForm = watch();

  const [updateSettings, { error, loading, data }] =
    useUpdateProjectSettingsMutation({
      onCompleted: (data) => {
        if (data) {
          toast({
            title: "Termination Settings Saved",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      },
    });

  const onSubmit = (values) => {
    updateSettings({
      variables: {
        input: {
          id: repo.id,
          preStopSleep: values?.preStopSleep,
          terminationGracePeriodSeconds:
            values?.terminationGracePeriodSeconds || 30,
        },
      },
    });
  };

  return (
    <form
      onSubmit={(e) => {
        e.stopPropagation();
        e.preventDefault();
        handleSubmit(onSubmit)(e);
      }}
    >
      <Flex flexDirection="column">
        <Text fontWeight="bold" fontSize="1.2rem" mb={2}>
          Termination Settings
        </Text>

        <Flex flexDirection="column">
          <Text my={2}>
            When your Project is terminating it will first receive a SIGTERM
            signal. Termination grace period gives your Project extra time to
            gracefully shutdown before being forcefully terminated by SIGKILL
            signal.
          </Text>

          <Flex alignItems="center">
            <ZFormLabel fontWeight="bold">
              Termination Grace Period (seconds)
            </ZFormLabel>
            <Input
              flex={5}
              fontFamily={"mono"}
              {...register("terminationGracePeriodSeconds", {
                valueAsNumber: true,
              })}
              type="number"
              placeholder={"(e.g.) 30"}
              defaultValue={watchForm.terminationGracePeriodSeconds}
            />
          </Flex>

          <Divider my={4} />

          <Text my={2}>
            If your Project is reachable by external traffic, pre termination
            timeout will give you a grace period after the resource is removed
            from load balancer and before receiving the SIGTERM signal. This
            timeout can protect high traffic web service from dropping
            connections when receiving the SIGTERM signal.
          </Text>

          <Flex alignItems="center">
            <ZFormLabel fontWeight="bold">
              Pre Termination Timeout (seconds)
            </ZFormLabel>
            <Input
              flex={5}
              fontFamily={"mono"}
              {...register("preStopSleep", {
                valueAsNumber: true,
              })}
              type="number"
              placeholder={"(e.g.) 30"}
              defaultValue={watchForm.preStopSleep || 0}
            />
          </Flex>
          {shouldDisplayError(error, data) && <ZError error={error} />}
          <Button
            mt={2}
            ml={"auto"}
            colorScheme="brand"
            isLoading={loading}
            type="submit"
          >
            Save
          </Button>
        </Flex>
      </Flex>
    </form>
  );
};

export const ReleaseSettings: React.FC<{ repo: RepoDetailFragment }> = ({
  repo,
}) => {
  const toast = useToast();

  const { handleSubmit, register, watch } = useForm({
    defaultValues: {
      releaseCommand: repo?.buildMethod?.releaseCommand,
    },
  });

  const watchForm = watch();

  const [updateSettings, { error, loading, data }] =
    useUpdateProjectSettingsMutation({
      onCompleted: (data) => {
        if (data) {
          toast({
            title: "Release Settings Saved",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      },
    });

  const onSubmit = (values) => {
    updateSettings({
      variables: {
        input: {
          id: repo.id,
          releaseCommand: values?.releaseCommand,
        },
      },
    });
  };

  return (
    <form
      onSubmit={(e) => {
        e.stopPropagation();
        e.preventDefault();
        handleSubmit(onSubmit)(e);
      }}
    >
      <Flex flexDirection="column">
        <Text fontWeight="bold" fontSize="1.2rem" mb={3}>
          Release Settings
        </Text>

        <Flex flexDirection="column">
          <Text mb={3}>
            Release command lets you to run certain tasks like database
            migration or CDN cache invalidation before a new version of your
            resource is deployed. New version will not be deployed if release
            command returns error.
          </Text>

          <Flex alignItems="center">
            <ZFormLabel>Release Command</ZFormLabel>
            <Input
              flex={5}
              fontFamily={"mono"}
              {...register("releaseCommand")}
              placeholder={"(e.g.) rake db:migrate"}
              defaultValue={watchForm.releaseCommand || ""}
            />
          </Flex>
          {shouldDisplayError(error, data) && <ZError error={error} />}
          <Button
            mt={2}
            ml={"auto"}
            colorScheme="brand"
            isLoading={loading}
            type="submit"
          >
            Save
          </Button>
        </Flex>
      </Flex>
    </form>
  );
};

export const TimeoutSettings: React.FC<{ repo: RepoDetailFragment }> = ({
  repo,
}) => {
  const toast = useToast();

  const { handleSubmit, register, watch } = useForm({
    defaultValues: {
      deployTimeoutSeconds: repo?.deployTimeoutSeconds
        ? repo?.deployTimeoutSeconds
        : 3600,
    },
  });

  const watchForm = watch();

  const [updateSettings, { error, loading, data }] =
    useUpdateProjectSettingsMutation({
      onCompleted: (data) => {
        if (data) {
          toast({
            title: "Deploy Settings Saved",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      },
    });

  const onSubmit = (values) => {
    updateSettings({
      variables: {
        input: {
          id: repo.id,
          deployTimeoutSeconds: values?.deployTimeoutSeconds,
        },
      },
    });
  };

  return (
    <form
      onSubmit={(e) => {
        e.stopPropagation();
        e.preventDefault();
        handleSubmit(onSubmit)(e);
      }}
    >
      <Flex flexDirection="column">
        <Text fontWeight="bold" fontSize="1.2rem" mb={3}>
          Deploy Timeout
        </Text>

        <Flex flexDirection="column">
          <Text mb={3}>
            By default, your deployment will timeout after 1 hour. You may
            customize the timeout to accommodate longer deployments.
          </Text>
          <Flex alignItems="center">
            <ZFormLabel>Timeout (seconds)</ZFormLabel>
            <Input
              flex={5}
              fontFamily={"mono"}
              {...register("deployTimeoutSeconds", {
                valueAsNumber: true,
              })}
              data-testid="timeout-settings-input"
              defaultValue={watchForm.deployTimeoutSeconds || ""}
              type="number"
            />
          </Flex>
          {shouldDisplayError(error, data) && <ZError error={error} />}
          <Button
            mt={2}
            ml={"auto"}
            colorScheme="brand"
            isLoading={loading}
            type="submit"
          >
            Save
          </Button>
        </Flex>
      </Flex>
    </form>
  );
};
