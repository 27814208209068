import { Divider, Heading, PropsOf, Text } from "@chakra-ui/react";
import { Card } from "@zeet/web-ui";

interface SettingsCardProps {
  title: string;
  description?: string;
  children: React.ReactNode;
}

export const SettingsCard = ({
  children,
  title,
  description,
  ...props
}: SettingsCardProps & PropsOf<typeof Card>) => {
  return (
    <Card p="4" {...props}>
      <Heading size="sm">{title}</Heading>
      {description && (
        <Text mt="1" opacity="0.65">
          {description}
        </Text>
      )}
      <Divider my="3" />
      {children}
    </Card>
  );
};
