import { BadgeProps } from "@chakra-ui/react";
import { ResourceType } from "@zeet/web-api/dist/graphqlv1";
import { LabelBadge } from "@zeet/web-ui";

interface ResourceTypeBadgeProps {
  resourceType?: ResourceType;
}

export const ResourceTypeBadge: React.FC<
  ResourceTypeBadgeProps & BadgeProps
> = ({ resourceType, ...props }) => {
  if (!resourceType) {
    return null;
  }

  return <LabelBadge labelValue={resourceType} {...props} />;
};
