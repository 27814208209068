import { Box, Flex } from "@chakra-ui/react";
import {
  ActionTypes,
  DataTypes,
  IDataRow,
  shortId,
  useColors,
} from "@zeet/web-ui";
import React, { useEffect, useState } from "react";
import { BiHide } from "react-icons/bi";
import { FaSortDown } from "react-icons/fa";
import { usePopper } from "react-popper";
import { HeaderProps } from "react-table";
import ArrowDownIcon from "./img/ArrowDown";
import ArrowUpIcon from "./img/ArrowUp";
import HashIcon from "./img/Hash";
import MultiIcon from "./img/Multi";
import PlusIcon from "./img/Plus";
import TextIcon from "./img/Text";

const Header: React.FC<HeaderProps<IDataRow>> = ({
  column: { id, created, label, getResizerProps, getHeaderProps },
  setSortBy,
  dataDispatch,
  toggleMenuItem,
}) => {
  const { bg2, bg } = useColors();
  const [expanded, setExpanded] = useState(created || false);
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const [inputRef] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom",
    strategy: "absolute",
  });
  const [header, setHeader] = useState(label);
  const [typeReferenceElement] = useState<HTMLButtonElement | null>(null);
  const [typePopperElement, setTypePopperElement] =
    useState<HTMLDivElement | null>(null);
  const typePopper = usePopper(typeReferenceElement, typePopperElement, {
    placement: "right",
    strategy: "fixed",
  });
  const [showType, setShowType] = useState(false);

  const buttons = [
    {
      onClick: () => {
        dataDispatch({
          type: ActionTypes.UPDATE_COLUMN_HEADER,
          columnId: id,
          label: header,
        });
        setSortBy([{ id: id, desc: false }]);
        setExpanded(false);
      },
      icon: <ArrowUpIcon />,
      label: "Sort ascending",
    },
    {
      onClick: () => {
        dataDispatch({
          type: ActionTypes.UPDATE_COLUMN_HEADER,
          columnId: id,
          label: header,
        });
        setSortBy([{ id: id, desc: true }]);
        setExpanded(false);
      },
      icon: <ArrowDownIcon />,
      label: "Sort descending",
    },
    {
      onClick: () => {
        dataDispatch({
          type: ActionTypes.UPDATE_COLUMN_HEADER,
          columnId: id,
          label: header,
        });
        toggleMenuItem(id);
        setExpanded(false);
      },
      icon: <BiHide />,
      label: "Hide",
    },
  ];

  const types = [
    {
      onClick: () => {
        dataDispatch({
          type: "update_column_type",
          columnId: id,
          dataType: DataTypes.SELECT,
        });
        setShowType(false);
        setExpanded(false);
      },
      icon: <MultiIcon />,
      label: "Select",
    },
    {
      onClick: () => {
        dataDispatch({
          type: "update_column_type",
          columnId: id,
          dataType: DataTypes.TEXT,
        });
        setShowType(false);
        setExpanded(false);
      },
      icon: <TextIcon />,
      label: "Text",
    },
    {
      onClick: () => {
        dataDispatch({
          type: "update_column_type",
          columnId: id,
          dataType: DataTypes.NUMBER,
        });
        setShowType(false);
        setExpanded(false);
      },
      icon: <HashIcon />,
      label: "Number",
    },
  ];

  function getHeader() {
    if (id !== "999999") {
      return (
        <>
          <Box {...getHeaderProps()} className="th noselect d-inline-block">
            <Flex onClick={() => setExpanded(true)} ref={setReferenceElement}>
              <Box fontSize="12px" lineHeight="18px" className="th-content">
                {label}
              </Box>
              <Flex alignItems="center">
                <Box marginBottom="4px">
                  <FaSortDown />
                </Box>
              </Flex>
            </Flex>
            <Box {...getResizerProps()} className="resizer" />
          </Box>
          {expanded && (
            <Box className="overlay" onClick={() => setExpanded(false)} />
          )}
          {expanded && (
            <Box
              ref={setPopperElement}
              style={{ ...styles.popper, zIndex: 3 }}
              {...attributes.popper}
            >
              <Box
                background={bg2}
                border="1px solid var(--chakra-colors-chakra-border-color)"
                className="border-radius-md"
                shadow="md"
                style={{
                  width: 240,
                }}
              >
                <Box>
                  {showType && (
                    <Box
                      className="shadow-5 border-radius-md"
                      ref={setTypePopperElement}
                      onMouseEnter={() => setShowType(true)}
                      onMouseLeave={() => setShowType(false)}
                      {...typePopper.attributes.popper}
                      style={{
                        ...typePopper.styles.popper,
                        width: 200,
                        backgroundColor: "white",
                        zIndex: 4,
                      }}
                    >
                      {types.map((type, index) => (
                        <button
                          key={index}
                          className="sort-button"
                          onClick={type.onClick}
                        >
                          <Box
                            as="span"
                            className="svg-icon svg-text icon-margin"
                          >
                            {type.icon}
                          </Box>
                          {type.label}
                        </button>
                      ))}
                    </Box>
                  )}
                </Box>
                <Box key={shortId()} paddingTop="8px" paddingBottom="8px">
                  {buttons.map((button, index) => (
                    <Box
                      as="button"
                      type="button"
                      color="var(--chakra-colors-chakra-body-text)"
                      _hover={{ background: bg }}
                      className="sort-button"
                      onMouseDown={button.onClick}
                      key={index}
                    >
                      <Box
                        as="span"
                        className="svg-icon svg-text icon-margin"
                        color="var(--chakra-colors-chakra-body-text)"
                        sx={{
                          svg: {
                            stroke: "var(--chakra-colors-chakra-body-text)",
                            width: "18px",
                            height: "18px",
                            marginRight: "4px",
                          },
                        }}
                      >
                        {button.icon}
                      </Box>
                      {button.label}
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          )}
        </>
      );
    }
    return (
      <Box {...getHeaderProps()} className="th noselect d-inline-block">
        <Box
          className="th-content d-flex justify-content-center"
          onClick={() =>
            dataDispatch({
              type: "add_column_to_left",
              columnId: "999999",
              focus: true,
            })
          }
        >
          <Box as="span" className="svg-icon-sm svg-gray">
            <PlusIcon />
          </Box>
        </Box>
      </Box>
    );
  }

  useEffect(() => {
    if (created) {
      setExpanded(true);
    }
  }, [created]);

  useEffect(() => {
    setHeader(label);
  }, [label]);

  useEffect(() => {
    if (inputRef) {
      inputRef.focus();
    }
  }, [inputRef]);

  return getHeader();
};

export default Header;
