import { CopyIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  FormControl,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  DeployTarget,
  RepoDetailFragment,
  useDuplicateProjectMutation,
  useProjectEnvironmentsBasicQuery,
  useUserBasicProjectsQuery,
} from "@zeet/web-api/dist/graphql";
import {
  AnalyticsEvent,
  Card,
  FormSelect,
  Select,
  useCurrentTeamUser,
  useTrack,
  ZError,
} from "@zeet/web-ui";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { UpgradeProductModal } from "../Account/BillingV2/Modals/UpgradeProductModal";
import { useBillingOverviewContext } from "../Account/BillingV2/Providers/BillingOverviewProvider";
import {
  BillingModalSource,
  getButtonLink,
  maybeOpenBillingModal,
} from "../Account/BillingV2/utils";
import { useProject } from "./Provider";

interface CopyProjectButtonProps {
  repo: RepoDetailFragment;
}

export const CopyProjectButton: React.FC<CopyProjectButtonProps> = ({
  repo,
}) => {
  const project = useProject();
  const currentTeamUser = useCurrentTeamUser();
  const toast = useToast();
  const history = useHistory();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [currentProjectName, setCurrentProjectName] = useState(project.name);
  const [{ userBillingOverview, isOwnerOrAdmin }] = useBillingOverviewContext();
  const {
    onOpen: paymentOnOpen,
    onClose: paymentOnClose,
    isOpen: paymentIsOpen,
  } = useDisclosure();

  const [duplicateProject, { error, loading }] = useDuplicateProjectMutation({
    onCompleted: (data) => {
      if (data) {
        onClose();
        toast({
          title: "Project Successfully Duplicated!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        history.push(
          `/${currentTeamUser.login}/${data.duplicateProject.project?.name}/${data.duplicateProject.projectEnvironment?.name}/${data.duplicateProject.name}`
        );
      }
    },
    onError: (error) => {
      if (error) {
        toast({
          title: "Failed to duplicate Project",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    },
  });

  const { handleSubmit, control } = useForm({
    defaultValues: {
      newName: `${repo?.name}-copy`,
      enabled: true,
      group: `${project.id}`,
      subgroup:
        "defaultEnvironment" in project
          ? `${project?.defaultEnvironment?.id}`
          : "",
    },
  });

  const onSubmit = (data, enabled) => {
    duplicateProject({
      variables: {
        input: {
          id: repo?.id,
          enabled: enabled,
          name: data.newName,
          projectID: data.group,
          environmentID: data.subgroup,
        },
      },
    });
  };

  const projectsQuery = useUserBasicProjectsQuery({
    variables: { id: currentTeamUser.id },
  });
  const projects = projectsQuery?.data?.user.projects.nodes;

  const handleProjectChange = (projectID) => {
    const selectedProject = projects?.find(
      (project) => project.id === projectID
    );
    setCurrentProjectName(selectedProject?.name || project.name);
  };

  const environmentsQuery = useProjectEnvironmentsBasicQuery({
    skip: !currentProjectName,
    variables: { path: `${currentTeamUser.login}/${currentProjectName}` },
  });

  const { track } = useTrack();

  const billingOnOpenFunc = () => {
    paymentOnOpen();
    track(AnalyticsEvent.UPGRADE_MODAL_DISPLAYED, {
      upgrade_entity: BillingModalSource.PROJECTS,
    });
  };

  const environments = environmentsQuery.data?.project?.environments;

  return (
    <Card p="4">
      <Heading mb={3} size="md">
        Duplicate
      </Heading>
      <Text mb={3} fontSize="sm">
        Create a new Project with the same settings. Commonly this is used to
        create another environment for a Project.
      </Text>
      <Tooltip
        isDisabled={repo.deployTarget !== DeployTarget.Terraform}
        label="This feature is not available for terraform Projects."
      >
        <Button
          onClick={() => {
            maybeOpenBillingModal({
              onOpen: billingOnOpenFunc,
              userBillingOverview,
              onClickFunction: () => {
                onOpen();
              },
              usageUnit: "projects",
            });
          }}
          isDisabled={repo.deployTarget === DeployTarget.Terraform}
          data-testid="duplicate-project-button"
        >
          <Text mr={3}>Duplicate Project</Text>
          <CopyIcon />
        </Button>
      </Tooltip>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
        isCentered
        preserveScrollBarGap
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Duplicate this Project</ModalHeader>
          <ModalBody pb="2rem">
            <form
              onSubmit={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleSubmit(onSubmit)(e);
              }}
            >
              <Flex flexDirection="column" p={6} gap={6}>
                <FormControl my={2}>
                  <Flex>
                    <Text width="40%" mt={2} mr={2}>
                      New Project Name
                    </Text>
                    <Controller
                      name="newName"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Input
                          {...field}
                          width="60%"
                          placeholder="New Project Name"
                          required={true}
                        />
                      )}
                    />
                  </Flex>
                </FormControl>
                <FormControl my={2}>
                  <Flex>
                    <Text width="40%" mt={2} mr={2}>
                      Group
                    </Text>
                    <Controller
                      name="group"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { onChange, ...rest } }) => (
                        <Select
                          {...rest}
                          width="60%"
                          placeholder={project.name}
                          onChange={(e) => {
                            onChange(e?.value);
                            handleProjectChange(e?.value);
                          }}
                          options={projects?.map((project) => ({
                            value: project.id,
                            label: project.name,
                          }))}
                        />
                      )}
                    />
                  </Flex>
                </FormControl>
                <FormControl my={2}>
                  <Flex>
                    <Text width="40%" mt={2} mr={2}>
                      Sub-Group
                    </Text>

                    <Controller
                      name="subgroup"
                      control={control}
                      render={({ field }) => (
                        <FormSelect
                          {...field}
                          width="60%"
                          placeholder="Select Sub-Group"
                          required={true}
                          options={environments?.map((environment) => ({
                            value: environment.id,
                            label: environment.name,
                          }))}
                        />
                      )}
                    />
                  </Flex>
                </FormControl>
              </Flex>
              <ZError error={error} />
              <Flex justifyContent="center" gap={4}>
                <Button
                  onClick={() =>
                    handleSubmit((data) => onSubmit(data, false))()
                  }
                  mt="24px"
                  isLoading={loading}
                >
                  Save as Draft
                </Button>
                <Button
                  colorScheme="brand"
                  onClick={() => handleSubmit((data) => onSubmit(data, true))()}
                  mt="24px"
                  isLoading={loading}
                >
                  Deploy Now
                </Button>
              </Flex>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
      <UpgradeProductModal
        onClose={paymentOnClose}
        isOpen={paymentIsOpen}
        headerText="You’ve reached the free Projects limit"
        titleText="Add more Projects for your team"
        bodyText="Add a payment method so your team can continue building with Pay as you Go billing."
        shouldGoBack={false}
        primaryButtonLink={getButtonLink(
          isOwnerOrAdmin,
          userBillingOverview,
          currentTeamUser
        )}
        primaryButtonText={
          isOwnerOrAdmin ? "Add payment method" : "Review usage"
        }
      />
    </Card>
  );
};
