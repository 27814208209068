import { Box, Flex, PropsOf, Text } from "@chakra-ui/react";
import { Button, EmptyBox, useCurrentTeamUser } from "@zeet/web-ui";
import { useParams } from "react-router-dom";

interface ProjectTreeEmptyStateProps {
  level?: "global" | "group" | "subgroup";
}

export const ProjectTreeEmptyState = ({
  level,
  ...props
}: ProjectTreeEmptyStateProps & PropsOf<typeof Box>) => {
  const currentTeamUser = useCurrentTeamUser();
  const params = useParams<{ projectName: string }>();

  if (level === "group") {
    return (
      <EmptyBox mt={9}>
        <Flex flexDirection="column" alignItems="center">
          <Text>No Sub-Groups found. Why not create one?</Text>
          <Button
            variant="secondary"
            mt="4"
            asLink
            to={`/${currentTeamUser.login}/${params.projectName}?create=true`}
          >
            New Sub-Group
          </Button>
        </Flex>
      </EmptyBox>
    );
  }

  return (
    <EmptyBox mt={9} {...props}>
      <Flex flexDirection="column" alignItems="center">
        <Text>No Projects found. Why not create one?</Text>
        <Button variant="secondary" mt="4" asLink to={`/new`}>
          New Project
        </Button>
      </Flex>
    </EmptyBox>
  );
};
