import { ColorMode, extendTheme } from "@chakra-ui/react";
import { Theme } from "@chakra-ui/theme";
import { mode } from "@chakra-ui/theme-tools";
import Color from "color";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Dict = Record<string, any>;

const darkBgBase = Color("#101010");
const lightBgBase = Color("#F9F9F9");

const darkFgBase = Color("#fff");
const darkFgSecondary = Color("#848990");
const darkFgTertiary = Color("#3a3e43");
const lightFgBase = Color("#000");
const lightFgSecondary = Color("#848990");
const lightFgTertiary = Color("#3a3e43");

const amazonOrange = Color("#FF9900");
const facebookBlue = Color("#4267B2");
const googleRed = Color("#DB4437");

export const successColor = Color("#16A34A");

const zeetGreen = Color("#00FF00");
const brandDark = zeetGreen.hex(); // zeet green is based off dark mode
const brandLight = zeetGreen.darken(0.15).blacken(0.06).hex();

export const defaultFirstColumnWidth = 250;

export const colors = {
  brand: {
    100: zeetGreen.lighten(0.1).whiten(0.1).hex(),
    200: brandDark,
    300: brandDark,
    400: brandDark,
    500: brandLight,
    600: brandLight,
  }, // chakra colorScheme gradient
  brandDark,
  brandLight,
  brandVar: "var(--brand)", // dynamic brand works in both dark and light mode
  success: "var(--success)",
  warning: "var(--warning)",
  danger: "var(--danger)",
  accent: {
    100: "var(--accents-1)",
    200: "var(--accents-2)",
    300: "var(--accents-3)",
    400: "var(--accents-4)",
    500: "var(--accents-5)",
    600: "var(--accents-6)",
    700: "var(--accents-7)",
    800: "var(--accents-8)",
    900: "var(--accents-9)",
  },
  gray: {
    // copy paste
    50: "#F7FAFC",
    100: "#EDF2F7",
    200: "#E2E8F0",
    300: "#CBD5E0",
    400: "#A0AEC0",
    500: "#ADADB8",
    600: "#4A5568",
    700: "#1e1e21", // tw bg2
    800: "#121215", // tw bg
    900: "#171923",
  },
  black2: {
    200: "#111",
    300: "#333",
    500: "#222",
    600: "#353535",
  },
};

export const colorMap = {
  dark: {
    appBg: "#172015",
    appContentBg: "#111112",
    appContentBorder: "#ffffff1c",
    appSubNavBg: "#1e1e21d4",
    bg: darkBgBase.hex(),
    bg2: darkBgBase.lighten(0.25).hex(),
    bg3: darkBgBase.lighten(0.5).hex(),
    bg4: darkBgBase.lighten(0.75).hex(),
    fg: darkFgBase.hex(),
    fg2: darkFgSecondary.hex(),
    fg3: darkFgTertiary.hex(),
    brand: brandDark,
    activeBg: "#ffffff1c",
    activeBorder: "#ffffff0d",
    bgHover: darkFgTertiary.darken(0.4).hex(),
    tableHeadingBg: darkBgBase.lighten(0.2).hex(),
    status: {
      neutral: { text: "gray.300", badge: "gray" },
      good: { text: "green.300", badge: "brand" },
      bad: { text: "red.300", badge: "red" },
      waiting: { text: "yellow.300", badge: "yellow" },
      active: { text: "blue.300", badge: "blue" },
    },
    bgButtonHover: "var(--chakra-colors-whiteAlpha-300)",
  },
  light: {
    appBg: "#f3fdf2",
    appContentBg: "#FFFFFF",
    appContentBorder: "#0000001c",
    appSubNavBg: "#f1f1f5db",
    bg: lightBgBase.hex(),
    bg2: lightBgBase.lighten(0.025).hex(), // these are wrong should be darken
    bg3: lightBgBase.darken(0.005).hex(),
    bg4: lightBgBase.lighten(0.075).hex(),
    fg: lightFgBase.hex(),
    fg2: lightFgSecondary.hex(),
    fg3: lightFgTertiary.hex(),
    brand: brandLight,
    activeBg: "#00000012",
    activeBorder: "#0000000d",
    bgHover: lightFgTertiary.lighten(2.9).hex(),
    tableHeadingBg: lightBgBase.lighten(0.02).hex(),
    status: {
      neutral: { text: "gray.600", badge: "gray" },
      good: { text: "green.400", badge: "green" },
      bad: { text: "red.400", badge: "red" },
      waiting: { text: "orange.300", badge: "yellow" },
      active: { text: "blue.400", badge: "blue" },
    },
    bgButtonHover: "var(--chakra-colors-gray-200)",
  },
};

export type ColorMapProps = (typeof colorMap)["dark"];
export type StatusColor = keyof ColorMapProps["status"];

const defaultConfig = {
  initialColorMode: "dark" as ColorMode,
  useSystemColorMode: false,
};

const generateCssVars: (theme: Dict) => Dict = (theme) => ({
  "--chakra-colors-chakra-border-color": mode(
    "rgb(0 0 0 / 13%) !important",
    "rgb(255 255 255 / 13%) !important"
  )(theme),
  "--app-background": mode(colorMap.light.appBg, colorMap.dark.appBg)(theme),
  "--app-subnav-background": mode(
    colorMap.light.appSubNavBg,
    colorMap.dark.appSubNavBg
  )(theme),
  "--app-content-background": mode(
    colorMap.light.appContentBg,
    colorMap.dark.appContentBg
  )(theme),

  "--app-theme-background": mode(
    "linear-gradient(0deg, #0eafff0d, var(--app-background))",
    "linear-gradient(0deg, #0eafff08, var(--app-background))"
  )(theme),

  "--brand": mode(colorMap.light.brand, colorMap.dark.brand)(theme),
  "--background": mode(colorMap.light.bg, colorMap.dark.bg)(theme),
  "--background-2": mode(colorMap.light.bg2, colorMap.dark.bg2)(theme),
  "--background-3": mode(colorMap.light.bg3, colorMap.dark.bg3)(theme),
  "--background-opaque": mode(
    lightBgBase.darken(0.025).alpha(0.9).rgb().toString(),
    darkBgBase.alpha(0.8).rgb().toString()
  )(theme),
  "--shadow": mode(
    "0px 6px 30px rgb(0 0 0 / 20%)",
    "rgb(0 0 0 / 50%) 0px 16px 70px"
  )(theme),
  "--shadow-sm": mode(
    "0 1px 2px 0 rgba(0 0 0 0.05)",
    "0 4px 6px -1px rgba(0 0 0 0.1), 0 2px 4px -1px rgba(0 0 0 0.06)"
  )(theme),
  "--shadow-md": mode(
    "0px 1px 2px rgba(17, 24, 39, 0.05)",
    `0px 1px 2px 1px ${darkBgBase.darken(0.4).rgb()}`
  )(theme),
  "--accents-1": mode(lightBgBase.hex(), darkBgBase.lighten(0.25).hex())(theme),
  "--accents-2": mode("rgb(226, 232, 240)", "rgba(255, 255, 255, 0.16)")(theme),
  "--accents-1-opaque": mode(
    lightBgBase.alpha(0.9).rgb().toString(),
    darkBgBase.lighten(0.25).alpha(0.9).rgb().toString()
  )(theme),
  "--foreground": mode(lightFgBase.hex(), darkFgBase.hex())(theme),
  "--secondary": mode("#444", "#ddd")(theme),
  "--accents-alpha": mode(
    "var(--chakra-colors-blackAlpha-400)",
    "var(--chakra-colors-whiteAlpha-400)"
  )(theme),
  "--success": successColor.hex(),
  "--warning": mode(
    "var(--chakra-colors-yellow-500)",
    "var(--chakra-colors-yellow-600)"
  )(theme),
  "--danger": mode(
    "var(--chakra-colors-red-500)",
    "var(--chakra-colors-red-600)"
  )(theme),
  "--gradientColorZero": zeetGreen.hex(), // zeet green
  "--gradientColorOne": amazonOrange.hex(), // amazon orange
  "--gradientColorTwo": facebookBlue.hex(), //fb blue
  "--gradientColorThree": googleRed.hex(), // google red...
  "--gradientColorZeroTransparent": zeetGreen.alpha(0).rgb().toString(),
  "--gradientColorOneTransparent": amazonOrange.alpha(0).rgb().toString(),
  "--gradientColorTwoTransparent": facebookBlue.alpha(0).rgb().toString(),
  "--gradientColorThreeTransparent": googleRed.alpha(0).rgb().toString(),
  "--buildColor": zeetGreen.hex(),
  "--scaleColor": facebookBlue.hex(),
  "--monitorColor": amazonOrange.hex(),
});

export const getGlobalStylesWithTheme = (theme: Dict) => ({
  ":root, :host, ::before, ::after": generateCssVars(theme),
  "html, body, :host": {
    bg: "var(--app-theme-background)",
    color: "var(--foreground)",
  },
  "::selection": {
    bg: "var(--brand)",
    color: "var(--background)",
  },
  button: {
    WebkitFontSmoothing: "antialiased",
    MozOsxFontSmoothing: "grayscale",
  },
  ".chakra-ui-light button": {
    color: "black",
  },
  ".chakra-ui-light .chakra-badge": {
    color: "blackAlpha.800",
  },
});

const defaultStyles = {
  global: (theme: Dict) => getGlobalStylesWithTheme(theme),
};

const components = {
  Tooltip: {
    baseStyle: {
      bg: "var(--foreground)",
      color: "var(--background)",
    },
  },
  Button: {
    baseStyle: {
      lineHeight: "1", // why chakra?
      shadow: "xs",
    },
    defaultProps: {
      colorScheme: "gray",
    },
  },
  Switch: {
    defaultProps: {
      colorScheme: "brand",
    },
  },
  Heading: {
    baseStyle: {
      fontFamily: "Manrope, sans-serif",
    },
  },
  Menu: {
    baseStyle: {
      list: {
        "&:focus:not([data-focus-visible-added]):not([data-focus-visible-disabled])":
          { shadow: "dark-lg" },
      },
    },
  },
};

const shadows: Partial<Theme["shadows"]> = {
  sm: "var(--shadow-sm)",
  md: "var(--shadow-md)",
};

export const buildTheme = ({
  config,
  styles,
}: {
  config: Dict;
  styles: Dict;
}) => extendTheme({ colors, config, shadows, styles, components });

const theme = buildTheme({ config: defaultConfig, styles: defaultStyles });

export default theme;
