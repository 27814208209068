import { DeepPartial } from "@chakra-ui/react";
import {
  Blueprint,
  CloudProvider,
  DeploymentAlertDetailFragment,
  ProjectContextFragment,
  ProjectContextWithoutEnvironmentFragment,
  ProjectListItemFragment,
  ProjectPermissionsQuery,
  ProjectV3AdapterStatus,
  TeamCloudQuery,
  UserTeamMemberQuery,
} from "@zeet/web-api/dist/graphql";
import {
  DeploymentStatus as DeploymentStatusV1,
  JobRunState,
  ProjectDeployRunListFragment,
  RevisableEntity,
  User,
} from "@zeet/web-api/dist/graphqlv1";
import { ReactNode } from "react";
import { Column } from "react-table";
import { ClusterStatusType } from "..";
import { CloudProvidersEnum } from "../types/clouds-view";

type ProjectPermissionsQueryResult = NonNullable<
  NonNullable<ProjectPermissionsQuery["project"]>["permissions"]
>;

type ProjectPermissionsUser = ProjectPermissionsQueryResult["users"][0];
type AvailableRolesQuery = ProjectPermissionsQueryResult["availableRoles"];

export function shortId(): string {
  return "_" + Math.random().toString(36).substr(2, 9);
}

export function randomColor(): string {
  return `hsl(${Math.floor(Math.random() * 360)}, 95%, 90%)`;
}

export interface IDataDispatch {
  type: string;
  option?: string;
  backgroundColor?: string;
  columnId?: string;
  rowIndex?: number;
  value?: string;
  label?: string;
  dataType?: string;
  focus?: boolean;
}

// this is used in multi-select popup menu
export type IDataOption =
  | {
      label: string;
      backgroundColor: string;
    }
  | {
      statusMap: Record<string, { color: string; text: string }>;
    };

// Because we use a different version for types and the real entity
export interface ColumnAdapter {
  label: string;
  dataType: string;
  options: string[];
  order: number;
}

// data created
export interface IDataRow {
  projectName?: {
    text: string;
    link: string;
    icon?: ReactNode;
    isExternal?: boolean;
  };
  environmentCount?: {
    text: string;
  };
  repoName?: {
    text: string;
    link: string;
    icon?: ReactNode;
    isExternal?: boolean;
  };
  publicURL?: {
    text: string;
    link: string;
  };
  privateURL?: {
    text: string;
    link: string;
    isExternal?: boolean;
  };
  projectPrivateUrl?: {
    text: string;
  };
  projectType?: {
    text: string;
    blueprint?: Blueprint;
    project?: ProjectListItemFragment;
  };
  sourceName?: {
    text: string;
    link: string;
  };
  sourceType?: {
    text: string;
    link: string;
  };
  deployState?: {
    state: string;
  };
  repoLink?: {
    text: string;
    link: string;
  };
  updated?: {
    date: Date;
  };
  branch?: {
    text: string;
  };
  cpuCount?: {
    text: string;
  };
  gpuCount?: {
    text: string;
  };
  tpuCount?: {
    text: string;
  };
  memory?: {
    text: string;
  };
  clusterName?: {
    text: string;
    link: string;
    isExternal?: boolean;
  };
  clusterRegion?: {
    text: string | null | undefined;
  };
  clusterState?: {
    text: string | null | undefined;
    state: ClusterStatusType;
  };
  clusterConnected?: {
    text: string;
    boolean: boolean | null | undefined;
  };
  clusterCloudProvider?: {
    text: CloudProvider | null | undefined;
    clusterAWSAccount: string | null | undefined;
    clusterGCPAccount: string | null | undefined;
    clusterDOAccount: string | null | undefined;
    clusterCWAccount: string | null | undefined;
    clusterLDAccount: string | null | undefined;
    clusterAzureAccount: string | null | undefined;
    clusterVultrAccount: string | null | undefined;
    clusterGCPAccountLink: string;
    clusterDOAccountLink: string;
    clusterAWSAccountLink: string;
    clusterCWAccountLink: string;
    clusterLDAccountLink: string;
    clusterAzureAccountLink: string;
    clusterVultrAccountLink: string;
  };
  clusterCloudId?: {
    text: string | null | undefined;
  };
  clusterDistribution?: {
    text: string | null | undefined;
  };
  clusterDomain?: {
    text: string | null | undefined;
  };
  clusterConnectedRepos?: {
    text: number | undefined;
  };
  clusterUpdatedAt?: {
    text: string;
    date: Date;
  };
  clusterRegistry?: {
    text: string | undefined;
  };
  getPropertyIcon?: React.FC<string>;
  cloudProvider?: {
    text: string | null | undefined;
    cloudAccountKey: string | null | undefined;
    link: string;
  };
  cloudAccountKey?: {
    text: string | null | undefined;
  };
  userIamUsername?: {
    text: string | undefined;
  };
  userIamArn?: {
    text: string | undefined;
  };
  userConnectButton?: {
    text: string | null | undefined;
    awsAccountId: string | null | undefined;
    awsIamArn: string | null | undefined;
    connectUserFunc: (awsAccountId: string, awsIamArn: string) => void;
    connectUserLoading: boolean;
    disconnectUserFunc: (id: string) => void;
    disconnectUserLoading: boolean;
    connectionId: string | null | undefined;
  };
  permissionsAwsPolicyName?: {
    text: string | null | undefined;
  };
  permissionsAwsArn?: {
    text: string | null | undefined;
  };
  permissionsAwsGroupName?: {
    text: string | null | undefined;
  };
  permissionsAwsJSON?: {
    text: string | null | undefined;
    onOpenFunc: () => void;
    awsAccountId: string | null | undefined;
    awsUsername: string | null | undefined;
    awsPolicyName: string | null | undefined;
    arn: string | null | undefined;
    modalLink: string | null | undefined;
  };
  consoleUsersCloudAccount?: {
    cloudProvider: CloudProvidersEnum;
    cloudId: string | null | undefined;
    cloudProviderLink: string | null | undefined;
  };
  resourceId?: {
    simpleId: string;
    fullId?: string;
  };
  minReplicas?: {
    text: string;
  };
  maxReplicas?: {
    text: string;
  };
  blueprintDisplayName?: {
    text: string;
    blueprint: Blueprint;
  };
  blueprintVisibility?: {
    text: string;
    blueprint: Blueprint;
  };
  blueprintOwner?: {
    text: string;
    blueprint: Blueprint;
  };
  blueprintSlug?: {
    text: string;
  };
  blueprintPublished?: {
    text: string;
  };
  blueprintType?: {
    text: string;
    blueprint: Blueprint;
  };
  blueprintSource?: {
    text: string;
  };
  blueprintVariables?: {
    text: string;
  };
  zeet_users_name?: {
    member: NonNullable<
      UserTeamMemberQuery["currentUser"]["team"]
    >["members"][0];
  };
  zeet_users_role?: {
    member: NonNullable<
      UserTeamMemberQuery["currentUser"]["team"]
    >["members"][0];

    refetchTeamMembers: () => void;
    userIsOwnerOrAdmin: boolean;
    userIsOwner: boolean;
    teamMembers: UserTeamMemberQuery | undefined;
  };
  zeet_users_connected_cloud_users?: {
    member: NonNullable<
      UserTeamMemberQuery["currentUser"]["team"]
    >["members"][0];

    teamCloudConnectionsLoading: boolean | undefined;
    userIsOwnerOrAdmin: boolean;
    teamCloudConnections: TeamCloudQuery | undefined;
  };
  zeet_users_action?: {
    member: NonNullable<
      UserTeamMemberQuery["currentUser"]["team"]
    >["members"][0];
    refetchTeamMembers: () => void;
    userIsOwnerOrAdmin: boolean;
  };
  zeetInvitations_email?: {
    text: NonNullable<
      UserTeamMemberQuery["currentUser"]["team"]
    >["memberInvitations"][0]["email"];
  };
  zeetInvitations_role?: {
    text: NonNullable<
      UserTeamMemberQuery["currentUser"]["team"]
    >["memberInvitations"][0]["role"];
  };
  zeetInvitations_action?: {
    invitation: NonNullable<
      UserTeamMemberQuery["currentUser"]["team"]
    >["memberInvitations"][0];
  };
  blueprintInputName?: {
    text: string;
  };
  blueprintInputType?: {
    text: string;
  };
  deployableId?: {
    text: string;
  };
  blueprintInputRequired?: {
    boolean: boolean;
  };
  blueprintResourceCount?: {
    text: string;
  };
  projectNameV3?: {
    text: string;
    link: string;
    icon?: ReactNode;
    isExternal?: boolean;
  };
  projectSource?: {
    text: string;
    link: string;
    icon?: ReactNode;
    isExternal?: boolean;
  };
  projectIdV3?: {
    text: string;
  };
  projectStatusV3?: {
    text: string;
    status?: string | ProjectV3AdapterStatus | null;
    loading?: boolean;
  };
  projectCloudProviderV3?: {
    text: string;
  };
  projectClusterProviderV3?: {
    text: string;
  };
  projectRegionV3?: {
    text: string;
  };
  projectUpdatedAtV3?: {
    date: Date;
  };
  createdAt?: {
    date: Date;
    text: string;
  };
  updatedAt?: {
    date: Date;
    text: string;
  };
  groupName?: {
    text: string;
    link: string;
  };
  subgroupName?: {
    text: string;
    link: string;
  };
  revisionTitle?: {
    text: string;
  };
  revisionId?: {
    text: string;
  };
  revisionCreatedAt?: {
    date: Date;
  };
  revisionCreatedBy?: {
    user?: Partial<User>;
  };
  revision?: {
    text: string;
    revision: DeepPartial<RevisableEntity>;
    latestRevision?: DeepPartial<RevisableEntity>;
  };
  artifactImage?: {
    text: string;
  };
  artifactRepository?: {
    text: string;
  };
  artifactCreatedAt?: {
    date: Date;
  };
  artifactRun?: {
    text: string;
    link: string;
  };
  projectDeployDisplayName?: {
    text: string;
  };
  projectDeployStatus?: {
    text: string;
    status?: DeploymentStatusV1;
  };
  projectDeployCount?: {
    text: string;
  };
  projectDeploymentActions?: {
    deployRun: ProjectDeployRunListFragment;
  };
  projectLastDeploymentAt?: {
    date?: Date;
  };
  buildName?: {
    text: string;
  };
  buildStatus?: {
    status?: JobRunState;
  };
  buildCreated?: {
    date?: Date;
  };
  buildTriggeredBy?: {
    text: string;
    link: string;
  };
  workflowRunId?: {
    text: string;
    link: string;
  };
  workflowRunTrigger?: {
    text: string;
    link: string;
  };
  workflowRunStatus?: {
    status: string;
  };
  workflowRunCreated?: {
    date: Date;
  };
  resourceExternalId?: {
    text: string;
  };
  jobRunName?: {
    text: string;
  };
  jobRunStatus?: {
    status?: JobRunState;
  };
  jobRunCreated?: {
    date?: Date;
  };
  jobName?: {
    text: string;
    link: string;
  };
  jobRunCount?: {
    text: string;
  };
  alertType?: {
    text: string;
    alert: DeploymentAlertDetailFragment;
  };
  firingSince?: {
    date: Date;
  };
  resolvedAt?: {
    date?: Date;
  };
  zeet_users_login?: {
    text: string;
  };
  zeet_users_project_role_name?: {
    text: string;
  };
  zeet_users_role_name?: {
    text: string;
  };
  project_role_update_action?: {
    project?:
      | ProjectContextFragment
      | ProjectContextWithoutEnvironmentFragment
      | null;
    user?: ProjectPermissionsUser;
    availableRoles?: AvailableRolesQuery;
  };
}

export interface IZeetData {
  columns: Column<IDataRow>[];
  data: IDataRow[];
  skipReset: boolean;
}

export const DataTypes = Object.freeze({
  NUMBER: "number",
  TEXT: "text",
  SELECT: "select",
  LINK: "link",
  BOOLEAN: "boolean",
  STATE: "state",
  CODE: "code",
  COPY_CODE: "copyCode",
  TIMEAGO: "timeago",
  CLUSTER_CLOUD_PROVIDER: "clusterCloudProvider",
  CLOUD_PROVIDER: "cloudProvider",
  NAV_LINK: "nav_link",
  CONNECTED: "connected",
  REPOS: "repos",
  COREWEAVE_PROJECTS: "coreweaveProjects",
  CLUSTERS: "clusters",
  MAX_WIDTH_TEXT: "maxWidthText",
  CONNECT: "connect",
  JSON_MODAL: "jsonModal",
  PROJECT_STATUS: "projectStatus",
  CLUSTER_STATUS: "clusterStatus",
  CONSOLE_USER_CLOUD_PROVIDER: "consoleUserCloudProvider",
  CONSOLE_USER_PERMISSIONS: "consoleUserPermissions",
  CONSOLE_USER_ZEET_USERS: "consoleUserZeetUsers",
  CONSOLE_USER_CLOUD_ID: "consoleUserCloudId",
  CLOUD_RESOURCE_ID: "cloudResourceId",
  CLOUD_RESOURCE_PROJECTS: "cloudResourceProjects",
  BLUEPRINT_NAME: "blueprintDisplayName",
  PROJECT_TYPE: "blueprintType",
  BLUEPRINT_DRIVER_TYPE: "blueprintDriver",
  BLUEPRINT_OWNER: "blueprintOwner",
  ZEET_USERS_NAME: "zeet_users_name",
  ZEET_USERS_ROLE: "zeet_users_role",
  ZEET_USERS_CONNECTED: "zeet_users_connected",
  ZEET_USERS_ACTION: "zeet_users_action",
  ZEET_INVITATIONS_EMAIL: "zeet_invitations_email",
  ZEET_INVITATIONS_ROLE: "zeet_invitations_role",
  ZEET_INVITATIONS_ACTION: "zeet_invitations_action",
  USER: "user",
  REVISION: "revisionMetadata",
  PROJECT_DEPLOYMENT_STATUS: "projectDeploymentStatus",
  PROJECT_DEPLOYMENT_ACTIONS: "projectDeploymentActions",
  BADGE: "badge",
  SWITCH: "switch",
  BLUEPRINT_ENABLED_ACTION: "blueprintEnabledAction",
  PROJECT_ROLE_UPDATE_ACTION: "project_role_update_action",
});

export const ActionTypes = Object.freeze({
  ADD_OPTION_TO_COLUMN: "add_option_to_column",
  ADD_ROW: "add_row",
  UPDATE_COLUMN_TYPE: "update_column_type",
  UPDATE_COLUMN_HEADER: "update_column_header",
  UPDATE_CELL: "update_cell",
  ADD_COLUMN_TO_LEFT: "add_column_to_left",
  ADD_COLUMN_TO_RIGHT: "add_column_to_right",
  DELETE_COLUMN: "delete_column",
  ENABLE_RESET: "enable_reset",
  UPDATE_SORTED_DATA: "update_sorted_data",
  POPULATE_COLUMN: "populate_column",
  INSERT_POPULATED_COLUMN: "insert_populated_column",
  SHOW_ALL_COLUMNS: "show_all_columns",
  POPULATE_DATA: "populate_data",
});
