// All data here will be migrated to the backend in the future
import {
  BlueprintConfiguration,
  BlueprintQuery,
  BlueprintType,
  CloudProvider,
  ClusterState,
  ContainerRegistrySourceInput,
  GitSourceInput,
  HelmRepositorySourceInput,
  SourceInput,
  TerraformRegistrySourceInput,
} from "@zeet/web-api/dist/graphql";
import { BlueprintType as BlueprintTypeV2 } from "@zeet/web-api/dist/graphqlv1";
import expressLogo from "../images/express.png";

export type ProviderOption = {
  provider: CloudProvider;
  label: string;
  value: string;
  connected?: boolean | null;
  kubernetes?: boolean;
  clusterState?: ClusterState;
};

export type NewResourceValues = {
  userID: string;
  blueprintID?: string;
  blueprintSlug: BlueprintSlug;
  blueprintType: BridgeBlueprintType;
  blueprint?: Partial<BridgeBlueprint> | null;
  blueprintConfigurationString?: string;
  blueprintConfiguration?: NonNullable<
    BlueprintQuery["user"]["blueprint"]
  >["configuration"];
  source?: {
    git?: GitSourceInput;
    helmRepository?: HelmRepositorySourceInput;
    containerRegistry?: ContainerRegistrySourceInput;
    terraformRegistry?: TerraformRegistrySourceInput;
  };
  target: {
    provider?: ProviderOption;
    region?: string | null;
    terraform?: {
      stateBackend?: {
        cloudProvider?: CloudProvider;
        cloudId?: string;
        region?: string;
        bucketName?: string;
        key?: string;
      };
    };
    helm?: {
      releaseName?: string;
      namespace?: string | null;
      values?: string;
    };
    manifest?: {
      clusterID: string;
      namespace: string;
    };
  };
  organize: {
    name: string;
    environmentName?: string;
    environmentID?: string;
    projectName?: string;
    projectID?: string;
  };
  variables: {
    [key: string]: any;
  };
};

export enum BlueprintTag {
  All = "All",
  Database = "Database",
  VectorDatabase = "Vector Database",
  GitHub = "GitHub",
  Kubernetes = "Kubernetes",
  Docker = "Docker",
  Serverless = "Serverless",
  Featured = "Featured",
  Legacy = "Legacy",
  Community = "Community",
  MostPopular = "popular",
  Git = "Git",
}

export enum BridgeBlueprintInputId {
  DatabaseEngineVersion = "ZEET_DATABASE_ENGINE_VERSION",
  DatabaseName = "ZEET_DATABASE_NAME",
  DatabaseUsername = "ZEET_DATABASE_USERNAME",
  DatabasePassword = "ZEET_DATABASE_PASSWORD",
  HelmReleaseName = "ZEET_INPUT_HELM_RELEASE_NAME",
  HelmNamespace = "ZEET_INPUT_HELM_NAMESPACE",
  HelmValues = "ZEET_INPUT_HELM_VALUES",
  Build = "ZEET_INPUT_BUILD",
  BuildTemplate = "ZEET_INPUT_BUILD_TEMPLATE",
  BuildRootDirectory = "ZEET_INPUT_BUILD_DIRECTORY",
  BuildCommand = "ZEET_INPUT_BUILD_COMMAND",
  RunCommand = "ZEET_INPUT_RUN_COMMAND",
  KubernetesRunCommand = "ZEET_INPUT_KUBERNETES_RUN_COMMAND",
  NodeVersion = "ZEET_INPUT_NODE_VERSION",
  PythonVersion = "ZEET_INPUT_PYTHON_VERSION",
  GoVersion = "ZEET_INPUT_GO_VERSION",
  DockerfilePath = "ZEET_INPUT_DOCKERFILE_PATH",
  BuildOutputDirectory = "ZEET_INPUT_BUILD_OUTPUT_DIRECTORY",
  ComputeCpu = "ZEET_INPUT_COMPUTE_CPU",
  ComputeMemory = "ZEET_INPUT_COMPUTE_MEMORY",
  ComputeIsSpotInstance = "ZEET_INPUT_COMPUTE_IS_SPOT_INSTANCE",
  PersistentVolumes = "ZEET_INPUT_PERSISTENT_VOLUMES",
  PersistentVolumeSize = "ZEET_INPUT_PERSISTENT_VOLUME_SIZE",
  PersistentVolumePath = "ZEET_INPUT_PERSISTENT_VOLUME_PATH",
  Network = "ZEET_INPUT_NETWORK",
  NetworkPort = "ZEET_INPUT_NETWORK_PORT",
  NetworkIsHttpService = "ZEET_INPUT_NETWORK_IS_HTTP_SERVICE",
  EnvironmentVariables = "ZEET_INPUT_ENVIRONMENT_VARIABLES",
  EnvironmentVariableKey = "ZEET_INPUT_ENVIRONMENT_VARIABLE_KEY",
  EnvironmentVariableValue = "ZEET_INPUT_ENVIRONMENT_VARIABLE_VALUE",
  TerraformVariables = "ZEET_INPUT_TERRAFORM_VARIABLES",
  TerraformVariableKey = "ZEET_INPUT_TERRAFORM_VARIABLE_KEY",
  TerraformVariableValue = "ZEET_INPUT_TERRAFORM_VARIABLE_VALUE",
  TerraformVariableType = "ZEET_INPUT_TERRAFORM_VARIABLE_TYPE",
}

export enum BlueprintSlug {
  // project types
  ServiceContainer = "zeet-kubernetes-container-app",
  JobContainer = "zeet-kubernetes-container-job",
  ServerlessFunction = "zeet-serverless-function",
  Helm = "helm-chart",
  Manifest = "kubernetes-manifest",
  Terraform = "terraform-module",

  // blueprint databases
  MySqlKubernetesV3 = "mysql-kubernetes",
  RedisKubernetesV3 = "redis-kubernetes",
  MongoKubernetesV3 = "mongo-kubernetes",

  // legacy blueprints
  // the following blueprints are deprecated and will be removed in the future
  // the batch number indicates the priority in which they will be deprecated

  // to be deprecated #2
  // after we launch deploy v2
  LegacyDockerImage = "zeet-kubernetes-docker-image",
  LegacyContainerApp = "zeet-kubernetes-container-app",
  LegacyDockerfile = "zeet-kubernetes-dockerfile",
  LegacyWebService = "zeet-kubernetes-web-service",

  // to be deprecated #3
  // after we gain support for tf-root deployment in deployv2
  LegacyTerraform = "zeet-terraform",

  // to be deprecated #4
  // after we gain support for serverless in deployv2
  LegacyAwsLambda = "zeet-aws-lambda",
  LegacyGoogleCloudRun = "zeet-gcp-cloud-run",

  // to be deprecated #5
  // after we gain support for databases in deployv2
  LegacyMongoDb = "zeet-mongo-kubernetes",
  LegacyMySqlKubernetes = "zeet-mysql-kubernetes",
  LegacyMySqlRds = "zeet-mysql-rds",
  LegacyPostgresKubernetes = "zeet-postgres-kubernetes",
  LegacyPostgresRds = "zeet-postgres-rds",
  LegacyRedisKubernetes = "zeet-redis-kubernetes",
  PostgresKubernetesV3 = "postgres-kubernetes",

  AwsLambda = "aws-lambda",
  GcpCloudRun = "gcp-cloud-run",
  ContainerApp = "kubernetes-container-app",
  Dockerfile = "kubernetes-dockerfile",
  DockerImage = "kubernetes-docker-image",
  WebService = "kubernetes-web-service",

  AwsSam = "aws-sam",
  DemoProject = "express-js-demo",
}

const sourcelessBlueprintSlugs: BlueprintSlug[] = [
  BlueprintSlug.Terraform,
  BlueprintSlug.Helm,
  BlueprintSlug.Manifest,
];

const projectTypeBlueprintSlugs: BlueprintSlug[] = [
  BlueprintSlug.ServiceContainer,
  BlueprintSlug.JobContainer,
  BlueprintSlug.ServerlessFunction,
  BlueprintSlug.Helm,
  BlueprintSlug.Manifest,
  BlueprintSlug.Terraform,
];

const legacyBlueprintSlugs: BlueprintSlug[] = [
  BlueprintSlug.LegacyDockerImage,
  BlueprintSlug.LegacyAwsLambda,
  BlueprintSlug.LegacyContainerApp,
  BlueprintSlug.LegacyDockerfile,
  BlueprintSlug.LegacyGoogleCloudRun,
  BlueprintSlug.LegacyWebService,
  BlueprintSlug.LegacyTerraform,
  BlueprintSlug.LegacyMongoDb,
  BlueprintSlug.LegacyMySqlKubernetes,
  BlueprintSlug.LegacyMySqlRds,
  BlueprintSlug.LegacyPostgresKubernetes,
  BlueprintSlug.LegacyPostgresRds,
  BlueprintSlug.LegacyRedisKubernetes,
  BlueprintSlug.JobContainer,
  BlueprintSlug.ServiceContainer,
  BlueprintSlug.ServerlessFunction,
];

export const legacyDatabaseBlueprints: BlueprintSlug[] = [
  BlueprintSlug.LegacyPostgresKubernetes,
  BlueprintSlug.LegacyPostgresRds,
  BlueprintSlug.LegacyMongoDb,
  BlueprintSlug.LegacyRedisKubernetes,
  BlueprintSlug.LegacyMySqlKubernetes,
  BlueprintSlug.LegacyMySqlRds,
];

const databaseBlueprintsV3: BlueprintSlug[] = [
  BlueprintSlug.PostgresKubernetesV3,
  BlueprintSlug.MySqlKubernetesV3,
  BlueprintSlug.RedisKubernetesV3,
  BlueprintSlug.MongoKubernetesV3,
];

export const legacyAppBlueprints: BlueprintSlug[] = [
  BlueprintSlug.LegacyAwsLambda,
  BlueprintSlug.LegacyDockerfile,
  BlueprintSlug.LegacyGoogleCloudRun,
  BlueprintSlug.LegacyContainerApp,
  BlueprintSlug.LegacyWebService,
];

export const legacyDockerBlueprints: BlueprintSlug[] = [
  BlueprintSlug.LegacyDockerImage,
];

export const displayBlueprintSource = (blueprint: BridgeBlueprint) => {
  return (
    !blueprint.source &&
    !blueprint.configuration &&
    !isLegacyDatabaseBlueprint(blueprint.slug) &&
    !isDatabaseBlueprintV3(blueprint.slug)
  );
};

export const isSourcelessBlueprint = (slug: BlueprintSlug): boolean => {
  return sourcelessBlueprintSlugs.includes(slug);
};

export const isProjectTypeBlueprint = (slug: BlueprintSlug): boolean => {
  return projectTypeBlueprintSlugs.includes(slug);
};

export const isLegacyBlueprint = (slug: BlueprintSlug): boolean => {
  return legacyBlueprintSlugs.includes(slug);
};

export const isLegacyDatabaseBlueprint = (slug: BlueprintSlug): boolean => {
  return legacyDatabaseBlueprints.includes(slug);
};

const isDatabaseBlueprintV3 = (slug: BlueprintSlug): boolean => {
  return databaseBlueprintsV3.includes(slug);
};

export enum BridgeBlueprintInputType {
  String = "STRING",
  Integer = "INTEGER",
  Float = "FLOAT",
  Bool = "BOOLEAN",
  Bytes = "BYTES",
  List = "LIST",
  Group = "GROUP",
  Json = "JSON",
  Yaml = "YAML",
  Source = "SOURCE",
  EnvironmentVariables = "ENVIRONMENT_VARIABLES",
}

export type EnvironmentVariable = {
  key?: string;
  value?: string;
  description?: string;
  sensitive?: boolean;
};

export type ListDefaultValue = {
  id: string;
  value: string | number | boolean;
};

export type BridgeBlueprintInput = {
  id: string;
  name: string;
  type: BridgeBlueprintInputType;
  required: boolean;
  sensitive?: boolean;
  default?:
    | string
    | number
    | boolean
    | EnvironmentVariable[]
    | ListDefaultValue[][];
  options?: string[];
  variables?: BridgeBlueprintInput[];
  description?: string;
  trueValue?: string;
  falseValue?: string;
  readOnly?: boolean;
  allowCustomOption?: boolean;
  hidden?: boolean;
};

export enum LegacyBlueprintType {
  Terraform = "ZEET_TERRAFORM",
}

export type BridgeBlueprintType =
  | LegacyBlueprintType
  | BlueprintType
  | BlueprintTypeV2.AwsSam
  | BlueprintTypeV2.GcpCloudRun
  | BlueprintTypeV2.Terraform;

export type BridgeBlueprintVersion = {
  version: string;
  preRelease?: boolean;
  timestamp?: Date;
};

export type BridgeBlueprint = {
  id?: string;
  name: string;
  owner: string;
  slug: BlueprintSlug;
  type: BridgeBlueprintType;
  richInputSchema?: any;
  description: string;
  enabled?: boolean;
  projectCount: number;
  published?: boolean;
  logoUrl?: string;
  tags?: string[];
  version: string;
  latestVersion?: string;
  versions?: BridgeBlueprintVersion[];
  variables: BridgeBlueprintInput[];
  configuration?: BlueprintConfiguration | null;
  source?: SourceInput | null;
  target?: NewResourceValues["target"];
  organizationName?: string;
  applicationVersion?: string;
  isCommunity?: boolean;
  metatags?: Map<string, string>;
  allowedCloudProviders?: CloudProvider[];
};

export enum BuildTemplate {
  NextJs = "Next.js",
  NodeJs = "Node.js",
  React = "React / JAMStack",
  Python = "Python",
  Ubuntu = "Custom Build (Ubuntu 20.04)",
  Dockerfile = "Dockerfile",
  Django = "Django",
  Golang = "Golang",
  Elixir = "Elixir / Phoenix",
  Buildpacks = "Buildpacks (Heroku 18)",
}

const buildTemplateNode: BridgeBlueprintInput[] = [
  {
    id: BridgeBlueprintInputId.NodeVersion,
    name: "Node.js Version",
    type: BridgeBlueprintInputType.String,
    default: "18",
    required: true,
  },
  {
    id: BridgeBlueprintInputId.BuildCommand,
    name: "Build Command",
    description:
      "The command used to prepare your project. This could be compiling your code, installing dependencies, or maybe both!",
    type: BridgeBlueprintInputType.String,
    default: "npm --production=false install",
    required: true,
  },
  {
    id: BridgeBlueprintInputId.RunCommand,
    name: "Run Command",
    description:
      "The command used to run your code. This should start your service",
    type: BridgeBlueprintInputType.String,
    default: "npm start",
    required: true,
  },
];

const buildTemplateNextJs: BridgeBlueprintInput[] = [
  {
    id: BridgeBlueprintInputId.NodeVersion,
    name: "Node.js Version",
    type: BridgeBlueprintInputType.String,
    default: "18",
    required: true,
  },

  {
    id: BridgeBlueprintInputId.RunCommand,
    name: "Run Command",
    description:
      "The command used to run your code. This should start your service",
    type: BridgeBlueprintInputType.String,
    default: "npm run start",
    required: true,
  },
];

const buildTemplateReact: BridgeBlueprintInput[] = [
  {
    id: BridgeBlueprintInputId.NodeVersion,
    name: "Node.js Version",
    type: BridgeBlueprintInputType.String,
    default: "18",
    required: true,
  },

  {
    id: BridgeBlueprintInputId.BuildCommand,
    name: "Build Command",
    description:
      "The command used to prepare your project. This could be compiling your code, installing dependencies, or maybe both!",
    type: BridgeBlueprintInputType.String,
    default: "npm --production=false install",
    required: true,
  },
  {
    id: BridgeBlueprintInputId.BuildOutputDirectory,
    name: "Static output directory",
    description:
      "The directory your build command outputs your files to (i.e dist, build, public, etc.)",
    type: BridgeBlueprintInputType.String,
    default: "build",
    required: true,
  },
];

const buildTemplatePython: BridgeBlueprintInput[] = [
  {
    id: BridgeBlueprintInputId.PythonVersion,
    name: "Python Version",
    type: BridgeBlueprintInputType.String,
    default: "3.8",
    required: true,
  },

  {
    id: BridgeBlueprintInputId.BuildCommand,
    name: "Build Command",
    description:
      "The command used to prepare your project. This could be compiling your code, installing dependencies, or maybe both!",
    type: BridgeBlueprintInputType.String,
    default: "pip3 install -r requirements.txt",
    required: true,
  },
  {
    id: BridgeBlueprintInputId.RunCommand,
    name: "Run Command",
    description:
      "The command used to run your code. This should start your service",
    type: BridgeBlueprintInputType.String,
    default: "python3 main.py",
    required: true,
  },
];

const buildTemplateDjango: BridgeBlueprintInput[] = [
  {
    id: BridgeBlueprintInputId.PythonVersion,
    name: "Python Version",
    type: BridgeBlueprintInputType.String,
    default: "3.8",
    required: true,
  },

  {
    id: BridgeBlueprintInputId.BuildCommand,
    name: "Build Command",
    description:
      "The command used to prepare your project. This could be compiling your code, installing dependencies, or maybe both!",
    type: BridgeBlueprintInputType.String,
    default: "pip3 install -r requirements.txt",
    required: true,
  },
  {
    id: BridgeBlueprintInputId.RunCommand,
    name: "Run Command",
    description:
      "The command used to run your code. This should start your service",
    type: BridgeBlueprintInputType.String,
    default: "gunicorn --bind 0.0.0.0:8000 .wsgi", // cspell:disable-line
    required: true,
  },
];

const buildTemplateUbuntu: BridgeBlueprintInput[] = [
  {
    id: BridgeBlueprintInputId.BuildCommand,
    name: "Build Command",
    description:
      "The command used to prepare your project. This could be compiling your code, installing dependencies, or maybe both!",
    type: BridgeBlueprintInputType.String,
    default: "bash ./build.sh",
    required: true,
  },
  {
    id: BridgeBlueprintInputId.RunCommand,
    name: "Run Command",
    description:
      "The command used to run your code. This should start your service",
    type: BridgeBlueprintInputType.String,
    default: "bash ./run.sh",
    required: true,
  },
];

const buildTemplateDockerfile: BridgeBlueprintInput[] = [
  {
    id: BridgeBlueprintInputId.DockerfilePath,
    name: "Dockerfile Path",
    description:
      "The path to your Dockerfile (i.e ./src/Dockerfile, ./dockerfile)",
    type: BridgeBlueprintInputType.String,
    default: "Dockerfile",
    required: true,
  },
  {
    id: BridgeBlueprintInputId.RunCommand,
    name: "Run Command",
    description:
      "The command used to run your code. This should start your service",
    type: BridgeBlueprintInputType.String,
    required: false,
  },
];

const buildTemplateGolang: BridgeBlueprintInput[] = [
  {
    id: BridgeBlueprintInputId.GoVersion,
    name: "Go Version",
    type: BridgeBlueprintInputType.String,
    default: "1.16",
    required: true,
  },
  {
    id: BridgeBlueprintInputId.BuildCommand,
    name: "Build Command",
    description:
      "The command used to prepare your project. This could be compiling your code, installing dependencies, or maybe both!",
    type: BridgeBlueprintInputType.String,
    default: "go build -o main",
    required: true,
  },
  {
    id: BridgeBlueprintInputId.RunCommand,
    name: "Run Command",
    description:
      "The command used to run your code. This should start your service",
    type: BridgeBlueprintInputType.String,
    default: "./main",
    required: true,
  },
];

const buildTemplateElixir: BridgeBlueprintInput[] = [
  {
    id: BridgeBlueprintInputId.BuildCommand,
    name: "Build Command",
    description:
      "The command used to prepare your project. This could be compiling your code, installing dependencies, or maybe both!",
    type: BridgeBlueprintInputType.String,
    default: "mix do compile, release",
    required: true,
  },
  {
    id: BridgeBlueprintInputId.RunCommand,
    name: "Run Command",
    description:
      "The command used to run your code. This should start your service",
    type: BridgeBlueprintInputType.String,
    default: "start",
    required: true,
  },
];

const buildTemplateBuildpacks: BridgeBlueprintInput[] = [
  {
    id: BridgeBlueprintInputId.RunCommand,
    name: "Run Command",
    description:
      "The command used to run your code. This should start your service",
    type: BridgeBlueprintInputType.String,
    default: "herokuish procfile start web",
    required: true,
  },
];

export const buildTemplates = {
  [BuildTemplate.NodeJs]: buildTemplateNode,
  [BuildTemplate.NextJs]: buildTemplateNextJs,
  [BuildTemplate.React]: buildTemplateReact,
  [BuildTemplate.Python]: buildTemplatePython,
  [BuildTemplate.Dockerfile]: buildTemplateDockerfile,
  [BuildTemplate.Django]: buildTemplateDjango,
  [BuildTemplate.Golang]: buildTemplateGolang,
  [BuildTemplate.Elixir]: buildTemplateElixir,
  [BuildTemplate.Buildpacks]: buildTemplateBuildpacks,
  [BuildTemplate.Ubuntu]: buildTemplateUbuntu,
};

const getSafeImage = (logo: any) => {
  if (logo?.src) {
    return logo.src;
  }
  return logo;
};

const safeExpressLogo = getSafeImage(expressLogo);

export const demoBlueprint: BridgeBlueprint = {
  id: "demo",
  slug: BlueprintSlug.DemoProject,
  name: "Express.js Demo",
  owner: "zeet",
  logoUrl: safeExpressLogo,
  type: BlueprintType.ZeetKubernetes,
  tags: ["zeet"],
  description: "A simple Service Container demo project to get you started",
  version: "v1.0.0",
  projectCount: 0,
  published: true,
  variables: [],
  source: {
    git: {
      repository: "https://github.com/zeet-demo/node-express-demo.git",
    },
  },
  enabled: true,
};
