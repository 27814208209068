import { Text } from "@chakra-ui/react";
import { useBuildRunDetailQuery } from "@zeet/web-api/dist/graphqlv1";
import {
  CenterLoading,
  DetailItem,
  DetailLabel,
  useCurrentTeamUser,
  ZError,
} from "@zeet/web-ui";
import { BuildArtifactsDetailItem } from "../components/BuildArtifactDetailItem";
import { WorkflowTriggerDetailItem } from "../components/WorkflowTriggerDetailItem";
import { JobRunDetail } from "../Jobs/JobRunDetail";

interface BuildDetailTabProps {
  projectId: string;
  runId: string;
  withSimpleTitle?: boolean;
}

export const BuildDetail = ({
  projectId,
  runId,
  withSimpleTitle,
}: BuildDetailTabProps) => {
  const { id: teamId } = useCurrentTeamUser();
  const runVariables = {
    projectId,
    teamId,
    runId,
  };
  const { data, loading } = useBuildRunDetailQuery({
    variables: runVariables,
    pollInterval: 5000,
  });
  const run = data?.team?.project?.build?.run;

  if (loading) return <CenterLoading />;
  if (!run?.jobRun) return <ZError m={4}>Failed to fetch build run.</ZError>;

  return (
    <JobRunDetail
      jobRunId={run.jobRun.id}
      title={withSimpleTitle ? "Build" : `Build Run #${run.sequence}`}
    >
      <DetailItem>
        <DetailLabel>Config</DetailLabel>
        <Text color="blue.400" fontWeight="bold">
          {run.definition?.id}
        </Text>
      </DetailItem>
      <WorkflowTriggerDetailItem parentRun={run.triggeredBy} />
      <BuildArtifactsDetailItem artifacts={run.artifacts} />
    </JobRunDetail>
  );
};
