import { Box } from "@chakra-ui/react";
import { ProjectLinkType } from "@zeet/web-api/dist/graphqlv1";
import { Flag, useFeatureFlag } from "@zeet/web-ui";
import { ProjectLinkAction } from "~/components/Project/LinkProject/ProjectLinkAction";
import { SettingsSection } from "../../SettingsSection";

export const ProjectLinkSettings = () => {
  const isFeatureEnabled = useFeatureFlag(Flag.DeployV2LinkDatabase, {
    devEnabled: true,
  });

  if (!isFeatureEnabled) return null;

  return (
    <SettingsSection
      name="Link Database"
      description="Want to connect a database deployed on Zeet to this project? You can create a connection using injected environment variables below."
      gap={2}
    >
      <Box>
        <ProjectLinkAction linkType={ProjectLinkType.Database} />
      </Box>
    </SettingsSection>
  );
};
