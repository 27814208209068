import { InfoIcon, WarningIcon } from "@chakra-ui/icons";
import { Box, Flex, Text, Tooltip } from "@chakra-ui/react";
import { ResourceEvent, ResourceEventType } from "@zeet/web-api/dist/graphqlv1";
import { DetailItem, DetailLabel } from "@zeet/web-ui";
import ReactTimeago from "react-timeago";

interface ResourceEventsInfoProps {
  events: ResourceEvent[];
}

export const ResourceEventsInfo: React.FC<ResourceEventsInfoProps> = ({
  events,
}) => {
  events = events.sort((a, b) => {
    const d1 = new Date(a.occurredAt ?? a.lastOccurrenceAt ?? 0);
    const d2 = new Date(b.occurredAt ?? a.lastOccurrenceAt ?? 0);
    return d2.getTime() - d1.getTime();
  });

  return (
    <DetailItem>
      <DetailLabel>Events</DetailLabel>
      {events.length ? (
        <Flex maxHeight="350px" overflowY="auto" direction="column" pr="5">
          {events.map((e, i) => (
            <Flex key={i} justifyContent="space-between">
              <Box width="100px">
                <ReactTimeago date={e.occurredAt ?? e.lastOccurrenceAt ?? 0} />
              </Box>
              <Box ml="2" mr="2">
                <ResourceEventTypeIcon type={e.type} text={e.message} />
                {i != events.length - 1 && (
                  <Box ml="2" height="50px" borderLeft="1px" />
                )}
              </Box>
              <Text width="100px" noOfLines={3}>
                {e.reason}
              </Text>
            </Flex>
          ))}
        </Flex>
      ) : (
        <Flex opacity="0.5">No events found.</Flex>
      )}
    </DetailItem>
  );
};

const ResourceEventTypeIcon: React.FC<{
  type: ResourceEventType;
  text: string;
}> = ({ type, text }) => {
  switch (type) {
    case ResourceEventType.Normal:
      return (
        <Tooltip label={text} placement="left">
          <InfoIcon color="blue.500" />
        </Tooltip>
      );
    case ResourceEventType.Warning:
      return (
        <Tooltip label={text} placement="left">
          <WarningIcon color="yellow.500" />
        </Tooltip>
      );
  }
};
