import { UpdateDeployInput } from "@zeet/web-api/dist/graphqlv1";
import { useFormContext } from "react-hook-form";
import { useIsFormValueSet } from "~/hooks/useIsFormValueSet";
import {
  StringInput,
  TerraformBackendProviderInput,
  TerraformProviderInput,
} from "../../../inputs";
import { SettingsSection } from "../../SettingsSection";

export const TerraformSettings = () => {
  const { getValues } = useFormContext<UpdateDeployInput>();
  if (!useIsFormValueSet("configuration.terraform")) return null;

  let bucketType = "s3Bucket";
  if (getValues("configuration.terraform.target.stateBackend.gcsBucket")) {
    bucketType = "gcsBucket";
  } else if (
    getValues("configuration.terraform.target.stateBackend.linodeBucket")
  ) {
    bucketType = "linodeBucket";
  }

  return (
    <>
      <SettingsSection name="Terraform Module" basePath="terraform.target">
        <StringInput name="Module Name" path="moduleName" enabledInDraftOnly />
        <SettingsSection name="Cloud Provider">
          <TerraformProviderInput />
        </SettingsSection>
        <SettingsSection
          name="State Backend"
          basePath={`stateBackend.${bucketType}`}
        >
          <TerraformBackendProviderInput />
          <StringInput
            name="Bucket Name"
            path="bucketName"
            enabledInDraftOnly
          />
          {bucketType === "gcsBucket" ? (
            <StringInput name="Prefix" path="prefix" enabledInDraftOnly />
          ) : (
            <StringInput name="Key" path="key" enabledInDraftOnly />
          )}
        </SettingsSection>
      </SettingsSection>
      <SettingsSection name="Terraform Version" basePath="terraform.blueprint">
        <StringInput
          name="Version"
          path="terraformVersion"
          placeholder="1.1.7"
        />
      </SettingsSection>
    </>
  );
};
