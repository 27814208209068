import { Switch } from "@chakra-ui/react";
import { BridgeBlueprintInput } from "@zeet/web-ui";
import React, { useState } from "react";
import { Path, useFormContext } from "react-hook-form";
import { NewResourceValues } from "../../context";

interface BoolInputProps<T> {
  input: BridgeBlueprintInput;
  pathMap: (value: any) => Path<T>;
}

const BoolInput: React.FC<BoolInputProps<NewResourceValues>> = ({
  input,
  pathMap,
}) => {
  const checked = input.default === true;
  const [isChecked, setChecked] = useState(checked);
  const { setValue } = useFormContext<NewResourceValues>();

  if (isChecked) {
    setValue(pathMap(input.id), input.trueValue ?? true);
  } else {
    setValue(pathMap(input.id), input.falseValue ?? false);
  }

  return (
    <Switch
      defaultChecked={checked}
      onChange={(e) => setChecked(e.target.checked)}
    />
  );
};

export default BoolInput;
