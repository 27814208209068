import { User } from "@zeet/web-api/dist/graphqlv1";
import { DataTypes, IDataRow, IZeetData } from "@zeet/web-ui";
import { Column } from "react-table";
import { RevisionListData } from ".";

type ColumnsType = Column<IDataRow>[];

enum ColumnIds {
  REVISION = "revision",
  REVISION_CREATED_BY = "revisionCreatedBy",
  REVISION_CREATED_AT = "revisionCreatedAt",
}

const columns: ColumnsType = [
  {
    id: ColumnIds.REVISION,
    label: "Revision",
    minWidth: 700,
    dataType: DataTypes.REVISION,
    options: [],
    order: 1,
  },
  {
    id: ColumnIds.REVISION_CREATED_BY,
    label: "Revised by",
    minWidth: 200,
    dataType: DataTypes.USER,
    options: [],
    order: 3,
  },
  {
    id: ColumnIds.REVISION_CREATED_AT,
    label: "Revised at",
    minWidth: 100,
    dataType: DataTypes.TIMEAGO,
    options: [],
    order: 3,
  },
];

const defaultColumnIds = Object.values(ColumnIds) as ColumnIds[];

function makeRevisions(revisions?: RevisionListData | null): IZeetData {
  const revisionNodes = revisions?.nodes ?? [];

  const formattedData: IDataRow[] = revisionNodes.map((revision) => ({
    [ColumnIds.REVISION]: {
      text: `Revision #${revision.revisionMetadata?.sequenceId}`,
      revision: revision,
      revisions: revisionNodes,
    },
    [ColumnIds.REVISION_CREATED_BY]: {
      text: revision.revisionMetadata?.createdBy?.name,
      user: revision.revisionMetadata?.createdBy as User,
    },
    [ColumnIds.REVISION_CREATED_AT]: {
      date: revision.revisionMetadata?.createdAt as Date,
    },
  }));

  return {
    columns,
    data: formattedData,
    skipReset: false,
  };
}

export { columns, defaultColumnIds, makeRevisions };
