import {
  Container,
  Divider,
  Flex,
  Heading,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Link, useAuthActions, useIsLoggedIn, useTrack } from "@zeet/web-ui";
import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";
import { GitHubButton } from "../GitHubButton";
import { GoogleLoginButton } from "../GoogleButton";

export const LOGIN_NEXT_KEY = "zeet_login_next";

export const LoginPage: React.FC = () => {
  const [next] = useQueryParam("yeet", StringParam);
  const { login } = useAuthActions();
  const { track } = useTrack();
  const isLoggedIn = useIsLoggedIn();

  useEffect(() => {
    if (next) {
      localStorage.setItem(LOGIN_NEXT_KEY, next);
    } else {
      localStorage.removeItem(LOGIN_NEXT_KEY);
    }
  }, [next]);

  if (isLoggedIn) {
    return <Redirect to={next || "/"} />;
  }

  return (
    <Flex
      minH="100vh"
      align="center"
      justify="center"
      direction="column"
      pb="4rem"
    >
      <Container w="md">
        <Heading size="2xl">
          Log in to{" "}
          <Text as="span" color="brandVar">
            Zeet
          </Text>
        </Heading>
        <Stack mt={8} spacing={4}>
          <GitHubButton
            onClick={() => {
              track("click_github_login");
              login();
            }}
          >
            Continue with GitHub
          </GitHubButton>
          <GoogleLoginButton />
          {/* More Login methods will go here */}
        </Stack>
      </Container>

      <Container w="md">
        <Divider my="2rem" />
        <Link
          to={next ? `/signup?yeet=${encodeURIComponent(next)}` : "/signup"}
          color="brandVar"
          hoverColor="brand.100"
        >
          Don&apos;t have an account yet? Sign Up
        </Link>
      </Container>
    </Flex>
  );
};
