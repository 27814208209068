import {
  Box,
  Divider,
  FormControl,
  FormErrorMessage,
  Heading,
} from "@chakra-ui/react";
import {
  BridgeBlueprintInput,
  BridgeBlueprintInputId,
  BridgeBlueprintInputType,
  FormLabel,
  Tooltip,
} from "@zeet/web-ui";
import { Path } from "react-hook-form";
import BoolInput from "./BoolInput";
import BuildTemplateInput from "./BuildTemplateInput";
import BytesInput from "./BytesInput";
import { EnvironmentVariablesInput } from "./EnvironmentVariablesInput";
import FloatInput from "./FloatInput";
import GroupInput from "./GroupInput";
import IntegerInput from "./IntegerInput";
import ListInput from "./ListInput";
import StringInput from "./StringInput";

const defaultPathMap = (value: any) => `variables.${value}` as any;

interface InputControlProps {
  input: BridgeBlueprintInput;
  pathMap?: (value: any) => Path<any>;
  error?: string;
}

const InputControl = ({
  input,
  error,
  pathMap,
  ...props
}: InputControlProps) => {
  const effectivePathMap = pathMap ?? defaultPathMap;
  const makeInput = (input: BridgeBlueprintInput): React.ReactNode => {
    if (input.id === BridgeBlueprintInputId.Build) {
      return <BuildTemplateInput pathMap={effectivePathMap} input={input} />;
    }
    if (
      input.id === BridgeBlueprintInputId.EnvironmentVariables ||
      input.type === BridgeBlueprintInputType.EnvironmentVariables
    ) {
      return (
        <EnvironmentVariablesInput pathMap={effectivePathMap} input={input} />
      );
    }
    if (input.type === BridgeBlueprintInputType.String) {
      return <StringInput pathMap={effectivePathMap} input={input} />;
    }
    if (input.type === BridgeBlueprintInputType.Bool) {
      return <BoolInput pathMap={effectivePathMap} input={input} />;
    }
    if (input.type === BridgeBlueprintInputType.Integer) {
      return <IntegerInput pathMap={effectivePathMap} input={input} />;
    }
    if (input.type === BridgeBlueprintInputType.Float) {
      return <FloatInput pathMap={effectivePathMap} input={input} />;
    }
    if (
      input.type === BridgeBlueprintInputType.Json ||
      input.type === BridgeBlueprintInputType.Yaml ||
      input.type === BridgeBlueprintInputType.Bytes
    ) {
      return <BytesInput pathMap={effectivePathMap} input={input} />;
    }
    if (input.type === BridgeBlueprintInputType.List) {
      return <ListInput pathMap={effectivePathMap} input={input} />;
    }
    if (input.type === BridgeBlueprintInputType.Group) {
      return <GroupInput pathMap={effectivePathMap} input={input} />;
    }
  };

  const showRequiredIndicator = (): boolean => {
    return (
      input.required &&
      // prevent children inputs being marked as required
      input.type !== BridgeBlueprintInputType.Group &&
      input.type !== BridgeBlueprintInputType.List &&
      // booleans will always have a value, marking as required is confusing
      input.type !== BridgeBlueprintInputType.Bool
    );
  };

  const showDivider = (): boolean => {
    return input.type === BridgeBlueprintInputType.List && !!input.name;
  };

  if (input.hidden) {
    return null;
  }

  return (
    <FormControl
      mb={input.name ? 4 : 0}
      isInvalid={!!error}
      isRequired={showRequiredIndicator()}
      {...props}
    >
      {input.name && input.type !== BridgeBlueprintInputType.Group && (
        <FormLabel htmlFor={input.id} display="flex" alignItems="center">
          {showDivider() ? (
            <Box flex="1">
              <Divider my="3" />
              <Heading size="sm" mb="2" mt="6">
                {input.name}
                {input.description && <Tooltip text={input.description} />}
              </Heading>
            </Box>
          ) : (
            <>
              {input.name}
              {input.description && <Tooltip text={input.description} />}
            </>
          )}
        </FormLabel>
      )}
      {makeInput(input)}
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
};

export default InputControl;
