import { Divider, Flex, useDisclosure } from "@chakra-ui/react";
import {
  CombinedDeploymentMetricsUpdatedV2Subscription,
  MetricType,
  RepoDetailFragment,
  RepoPrometheusQuery,
  useCombinedDeploymentMetricsUpdatedV2Subscription,
  useRepoPrometheusQuery,
} from "@zeet/web-api/dist/graphql";
import { useCurrentTeamUser } from "@zeet/web-ui";
import { useEffect, useMemo, useState } from "react";
import { PrometheusModal } from "../PrometheusModal";
import { MetricsChart } from "./MetricChart";
import {
  MetricsControlPanel,
  useMetricsControlPanel,
} from "./MetricControlPanel";

const KubernetesMetrics: React.FC<{
  repo: RepoDetailFragment;
  deploymentID: string;
  onOpenViewInPrometheus: (query?: string) => void;
  prometheusData: RepoPrometheusQuery | undefined;
}> = ({ repo, deploymentID, onOpenViewInPrometheus, prometheusData }) => {
  const {
    groupBy,
    setGroupBy,
    metricRange,
    setMetricRange,
    aggregationOption,
    setAggregationOption,
  } = useMetricsControlPanel();
  const showPrometheusButton =
    ((repo?.cluster?.awsAccount ||
      repo?.cluster?.gcpAccount ||
      repo?.cluster?.doAccount ||
      repo?.cluster?.linodeAccount) &&
      prometheusData?.user.cluster?.prometheus?.user &&
      prometheusData.user.cluster?.prometheus?.password &&
      prometheusData.user.cluster.prometheus.url) ||
    (repo.cluster?.cwAccount && prometheusData?.user?.cluster?.prometheus?.url);
  const combinedDeploymentId =
    deploymentID || repo.productionDeployment?.id || "";

  const { data, error, loading } =
    useCombinedDeploymentMetricsUpdatedV2Subscription({
      variables: {
        deploymentID: combinedDeploymentId,
        deploymentUUID: combinedDeploymentId,
        queryOptions: {
          groupBy: groupBy,
          range: metricRange,
        },
      },
      skip: !combinedDeploymentId,
    });

  const [metricsData, setMetricsData] = useState<
    CombinedDeploymentMetricsUpdatedV2Subscription | undefined
  >();

  useEffect(() => {
    if (!error) {
      setMetricsData(data);
    }
  }, [data, metricsData, error]);

  const metricsMap = useMemo(
    () => [
      {
        type: MetricType.Cpu,
        query: metricsData?.deploymentUpdated.cpuMetrics?.[0]?.query,
        metrics: metricsData?.deploymentUpdated.cpuMetrics,
        metricDescription:
          "This metric sums the rate of container CPU usage over the past 5 minutes.",
      },
      {
        type: MetricType.Memory,
        query: metricsData?.deploymentUpdated.memoryMetrics?.[0]?.query,
        metrics: metricsData?.deploymentUpdated.memoryMetrics,
        metricDescription:
          "This metric sums the container memory working set over the past 5 minutes.",
      },
      {
        type: MetricType.Network,
        query: metricsData?.deploymentUpdated.networkMetrics?.[0]?.query,
        metrics: metricsData?.deploymentUpdated.networkMetrics,
        metricDescription:
          "This metric sums the rate of transmitted bytes across containers over the last 5 minutes.",
      },
      {
        type: MetricType.Storage,
        query: metricsData?.deploymentUpdated.diskMetrics?.[0]?.query,
        metrics: metricsData?.deploymentUpdated.diskMetrics,
        metricDescription:
          "This metric sums the total filesystem usage across containers over the last 5 minutes.",
      },
    ],
    [metricsData]
  );

  const hasData = metricsMap.some(({ metrics }) => !!metrics?.length);

  return (
    <MetricsControlPanel
      isLoading={loading}
      isDisabled={!hasData}
      onRangeChange={setMetricRange}
      onGroupByChange={setGroupBy}
      onAggregationChange={setAggregationOption}
      groupBy={groupBy}
      setGroupBy={setGroupBy}
    >
      {hasData ? (
        metricsMap
          .filter((metric) => !!metric.metrics)
          .map(({ type, query, metrics, metricDescription }, mi) => (
            <>
              {!!mi && <Divider mb={2} />}
              <MetricsChart
                px={3}
                key={mi}
                metricType={type}
                metricQuery={query || ""}
                metrics={metrics ?? []}
                metricDescription={metricDescription}
                showViewMetricExternalButton={!!showPrometheusButton}
                aggregationType={aggregationOption}
                onViewMetricExternal={onOpenViewInPrometheus}
              />
            </>
          ))
      ) : (
        <Flex p={4} py="8" opacity="0.5" justifyContent="center">
          No metrics found.
        </Flex>
      )}
    </MetricsControlPanel>
  );
};

export const KubernetesMetricsWrapper: React.FC<{
  repo: RepoDetailFragment;
  deploymentID: string;
}> = ({ repo, deploymentID }) => {
  const [query, setQuery] = useState<string | undefined>();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const currentTeamUser = useCurrentTeamUser();
  const { data: prometheusData } = useRepoPrometheusQuery({
    variables: {
      clusterID: repo.cluster?.id ?? "",
      userID: currentTeamUser.id,
      repoID: repo.id,
    },
    skip: !repo.cluster?.id,
  });

  const onOpenViewInPrometheus = (query?: string) => {
    setQuery(query);
    onOpen();
  };

  return (
    <>
      <PrometheusModal
        onClose={onClose}
        isOpen={isOpen}
        repo={repo}
        queryType="cpu"
        query={query}
        prometheusData={prometheusData}
      />
      <KubernetesMetrics
        repo={repo}
        deploymentID={deploymentID}
        onOpenViewInPrometheus={onOpenViewInPrometheus}
        prometheusData={prometheusData}
      />
    </>
  );
};
