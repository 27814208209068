import { Icon, Image, ImageProps } from "@chakra-ui/react";
import {
  FilterCriterionOperatorType,
  FilterNode,
  GroupListItemFragment,
  ProjectListItemFragment,
  ProjectV3AdapterStatus,
  ProjectWithRepoFragment,
  RepoSourceType,
  useProjectsQuery,
  useProjectsWithRepoQuery,
} from "@zeet/web-api/dist/graphql";
import {
  BlueprintType,
  ProjectFilter,
  ProjectWithBlueprintFragment,
  ProjectWithStatusFragment,
  useProjectsWithBlueprintQuery,
  useProjectsWithDeploymentTimesQuery,
  useProjectsWithStatusQuery,
} from "@zeet/web-api/dist/graphqlv1";
import { useMemo } from "react";
import { IconType } from "react-icons";
import { FaCube } from "react-icons/fa";
/* cspell:disable */
import {
  SiAwslambda,
  SiBitbucket,
  SiDocker,
  SiGit,
  SiGithub,
  SiGitlab,
  SiGooglecloud,
  SiHelm,
  SiKubernetes,
  SiTerraform,
} from "react-icons/si";
/* cspell:enable */
import {
  DataTypes,
  filterViewDataByText,
  IDataRow,
  IZeetData,
  useCurrentTeamUser,
} from "@zeet/web-ui";
import { Column } from "react-table";
import {
  isSampleProject,
  SAMPLE_PROJECT_STATUS,
} from "../../features/Project/New/makeCreateSampleProjectInput";
import { LanguageIcon } from "../LanguageIcon";
import {
  getProjectTypeForRepo,
  projectTypeDisplay,
} from "../ListViewV2/Cells/ProjectTypeCell";
import { makeLink } from "../utils/link";
import { getSourceLink, getSourceName } from "./DashboardRepoItem";

export enum ColumnIds {
  PROJECT_V3_NAME = "projectNameV3",
  PROJECT_V3_GROUP_NAME = "groupName",
  PROJECT_V3_SUBGROUP_NAME = "subgroupName",
  PROJECT_V3_STATUS = "projectStatusV3",
  PROJECT_V3_PUBLIC_URL = "publicURL",
  PROJECT_V3_PRIVATE_URL = "projectPrivateUrl",
  PROJECT_V3_ID = "projectIdV3",
  PROJECT_V3_SOURCE = "projectSource",
  PROJECT_V3_TYPE = "projectType",
  PROJECT_V3_UPDATED_AT = "updatedAt",
}

const projectV3Columns: Column<IDataRow>[] = [
  {
    id: ColumnIds.PROJECT_V3_NAME,
    dataType: DataTypes.LINK,
    minWidth: 350,
    order: 1,
    label: "Name",
    options: [],
  },
  {
    id: ColumnIds.PROJECT_V3_STATUS,
    dataType: DataTypes.PROJECT_STATUS,
    minWidth: 250,
    order: 4,
    label: "Status",
    options: [],
  },
  {
    id: ColumnIds.PROJECT_V3_GROUP_NAME,
    dataType: DataTypes.LINK,
    minWidth: 150,
    order: 2,
    label: "Group",
    options: [],
  },
  {
    id: ColumnIds.PROJECT_V3_SUBGROUP_NAME,
    dataType: DataTypes.LINK,
    minWidth: 150,
    order: 3,
    label: "Sub-Group",
    options: [],
  },
  {
    id: ColumnIds.PROJECT_V3_UPDATED_AT,
    dataType: DataTypes.TIMEAGO,
    minWidth: 150,
    order: 1,
    label: "Last updated",
    options: [],
  },
  {
    id: ColumnIds.PROJECT_V3_TYPE,
    dataType: DataTypes.PROJECT_TYPE,
    minWidth: 200,
    order: 4,
    label: "Type",
    options: [],
  },
  {
    id: ColumnIds.PROJECT_V3_SOURCE,
    dataType: DataTypes.LINK,
    minWidth: 250,
    order: 4,
    label: "Source",
    options: [],
  },
  {
    id: ColumnIds.PROJECT_V3_PUBLIC_URL,
    dataType: DataTypes.LINK,
    minWidth: 350,
    order: 5,
    label: "Public URL",
    options: [],
  },
  {
    id: ColumnIds.PROJECT_V3_PRIVATE_URL,
    dataType: DataTypes.TEXT,
    minWidth: 350,
    order: 6,
    label: "Internal Endpoint",
    options: [],
  },
  {
    id: ColumnIds.PROJECT_V3_ID,
    dataType: DataTypes.COPY_CODE,
    minWidth: 350,
    order: 1,
    label: "Project ID",
    options: [],
  },
];

const defaultColumnIds = [
  ColumnIds.PROJECT_V3_NAME,
  ColumnIds.PROJECT_V3_GROUP_NAME,
  ColumnIds.PROJECT_V3_SUBGROUP_NAME,
  ColumnIds.PROJECT_V3_STATUS,
  ColumnIds.PROJECT_V3_UPDATED_AT,
  ColumnIds.PROJECT_V3_TYPE,
  ColumnIds.PROJECT_V3_ID,
  ColumnIds.PROJECT_V3_SOURCE,
  ColumnIds.PROJECT_V3_PUBLIC_URL,
  ColumnIds.PROJECT_V3_PRIVATE_URL,
];

export const makeSourceLink = (
  teamUserLogin: string,
  project?: ProjectListItem
): string => {
  if (project?.blueprint?.id) {
    return `/${teamUserLogin}/console/blueprints/${project.blueprint?.id}`;
  }
  const source = project?.repo?.source;
  if (!source) {
    return "";
  }
  return getSourceLink(source);
};

export const makeSourceName = (project?: ProjectListItem): string => {
  if (project?.blueprint?.configuration?.slug) {
    return project.blueprint?.configuration?.slug;
  }
  const source = project?.repo?.source;
  if (!source) {
    return "";
  }
  return getSourceName(source);
};

export const ProjectSourceIcon = ({
  project,
}: {
  project: ProjectListItem;
}) => {
  const getSourceIcon = (project?: ProjectListItem): IconType | null => {
    /* cspell:disable */
    const blueprintTypeIcon: { [key in BlueprintType]: IconType } = {
      [BlueprintType.Terraform]: SiTerraform,
      [BlueprintType.KubernetesManifest]: SiKubernetes,
      [BlueprintType.ZeetKubernetes]: SiKubernetes,
      [BlueprintType.ZeetAwsLambda]: SiAwslambda,
      [BlueprintType.ZeetGcpCloudRun]: SiGooglecloud,
      [BlueprintType.Helm]: SiHelm,
      [BlueprintType.AwsSam]: SiAwslambda,
      [BlueprintType.GcpCloudRun]: SiGooglecloud,
    };
    /* cspell:enable */

    if (project?.blueprint?.type) {
      return blueprintTypeIcon[project.blueprint.type];
    }

    const repoSourceTypeIcon: { [key in RepoSourceType]: IconType } = {
      [RepoSourceType.Bitbucket]: SiBitbucket,
      [RepoSourceType.Github]: SiGithub,
      [RepoSourceType.GithubPublic]: SiGithub,
      [RepoSourceType.Gitlab]: SiGitlab,
      [RepoSourceType.Docker]: SiDocker,
      [RepoSourceType.Terraform]: SiTerraform,
      [RepoSourceType.Helm]: SiHelm,
      [RepoSourceType.DockerHub]: SiDocker,
      [RepoSourceType.Git]: SiGit,
    };

    if (project?.repo?.source.type) {
      return repoSourceTypeIcon[project.repo.source.type];
    }

    return null;
  };

  const icon = getSourceIcon(project);

  if (icon) {
    return <Icon as={icon} boxSize={4} />;
  }

  return null;
};

export const ProjectIcon = ({
  project,
  boxSize,
}: { project: ProjectListItem } & ImageProps) => {
  const boxSizeValue = boxSize ?? 6;

  if (project.repo?.source?.type) {
    return (
      <LanguageIcon
        language={project.repo?.image}
        boxSize={boxSizeValue}
        borderRadius="md"
        overflow="hidden"
      />
    );
  }

  return (
    <Image
      src={project.blueprint?.configuration?.logoUrl ?? ""}
      boxSize={boxSizeValue}
      borderRadius="md"
      fallback={
        <Icon
          as={FaCube}
          boxSize={boxSizeValue}
          borderRadius="md"
          opacity={0.5}
        />
      }
    />
  );
};

export type ProjectListItem = ProjectListItemFragment &
  ProjectWithRepoFragment & { status?: ProjectWithStatusFragment["status"] } & {
    blueprint?: ProjectWithBlueprintFragment["blueprint"];
  };

const makeProjectFilter = (
  groupName?: string,
  subgroupName?: string
): ProjectFilter => {
  let filter: ProjectFilter = {};
  if (groupName) {
    filter = {
      groupName: {
        operator: FilterCriterionOperatorType.Equals,
        value: groupName,
      },
    };
  }
  if (subgroupName) {
    filter = {
      ...filter,
      subGroupName: {
        operator: FilterCriterionOperatorType.Equals,
        value: subgroupName,
      },
    };
  }
  return filter;
};

export const makeProjectFilterCriterion = (
  groupName?: string,
  subgroupName?: string
): FilterNode => {
  let filter: FilterNode = {};

  if (groupName) {
    filter = {
      criterion: {
        resourceAdapterFilter: {
          projectName: {
            operator: FilterCriterionOperatorType.Equals,
            value: groupName,
          },
        },
      },
    };
  }

  if (subgroupName) {
    filter = {
      criterion: {
        resourceAdapterFilter: {
          ...filter.criterion?.resourceAdapterFilter,
          environmentName: {
            operator: FilterCriterionOperatorType.Equals,
            value: subgroupName,
          },
        },
      },
    };
  }

  return filter;
};

const useProjectListViewData = ({
  filter,
  groupName,
  subgroupName,
}: {
  filter?: string;
  groupName?: string;
  subgroupName?: string;
}): {
  data: IZeetData;
  loading: boolean;
  projects?: ProjectListItem[];
  groups?: GroupListItemFragment[];
} => {
  const currentTeamUser = useCurrentTeamUser();

  const { data: projectsData, loading: projectLoading } = useProjectsQuery({
    variables: {
      userId: currentTeamUser?.id,
      filterInput: {
        filter: makeProjectFilterCriterion(groupName, subgroupName),
      },
    },
    pollInterval: 5000,
  });

  const { data: projectsTimeData, loading: projectsTimeLoading } =
    useProjectsWithDeploymentTimesQuery({
      variables: {
        teamId: currentTeamUser?.id,
        input: {
          filter: makeProjectFilter(groupName, subgroupName),
        },
      },
      pollInterval: 5000,
    });

  const { data: projectsStatusData, loading: projectsStatusLoading } =
    useProjectsWithStatusQuery({
      variables: {
        teamId: currentTeamUser?.id,
        input: {
          filter: makeProjectFilter(groupName, subgroupName),
        },
      },
      pollInterval: 15000,
    });

  const { data: projectsBlueprintData } = useProjectsWithBlueprintQuery({
    variables: {
      teamId: currentTeamUser?.id,
    },
  });

  const { data: projectsRepoData } = useProjectsWithRepoQuery({
    variables: {
      userId: currentTeamUser?.id,
      filterInput: {
        filter: makeProjectFilterCriterion(groupName, subgroupName),
      },
    },
    pollInterval: 15000,
  });

  const combinedProjectsData = useMemo((): ProjectListItem[] => {
    const projects = projectsData?.user?.projectV3Adapters?.nodes ?? [];
    const projectsWithDeploymentTimes =
      projectsTimeData?.team?.projects?.nodes ?? [];
    const projectsWithStatus = projectsStatusData?.team?.projects?.nodes ?? [];
    const projectsWithBlueprint =
      projectsBlueprintData?.team?.projects?.nodes ?? [];
    const projectsWithRepo =
      projectsRepoData?.user.projectV3Adapters?.nodes ?? [];

    return projects
      .map((project) => {
        const projectWithStatus = projectsWithStatus.find(
          (p) => p?.id === project?.id
        );
        const projectWithBlueprint = projectsWithBlueprint.find(
          (p) => p?.id === project?.id
        );
        const projectWithRepo = projectsWithRepo.find(
          (p) => p?.repo?.id === project?.repo?.id
        );
        const projectWithDeploymentTime = projectsWithDeploymentTimes.find(
          (p) => p?.id === project?.id
        );
        return {
          ...project,
          status: projectWithStatus?.status,
          repo: projectWithRepo?.repo,
          blueprint: projectWithBlueprint?.blueprint,
          updatedAt:
            projectWithDeploymentTime?.deploymentTimestamps?.lastUpdatedAt ??
            project?.updatedAt,
        };
      })
      .sort(
        (projectA, projectB) =>
          new Date(projectB?.updatedAt).getTime() -
          new Date(projectA?.updatedAt).getTime()
      );
  }, [
    projectsData?.user?.projectV3Adapters?.nodes,
    projectsTimeData?.team?.projects?.nodes,
    projectsStatusData?.team?.projects?.nodes,
    projectsBlueprintData?.team?.projects?.nodes,
    projectsRepoData?.user.projectV3Adapters?.nodes,
  ]);

  const makeProjectPath = (
    groupName?: string,
    subgroupName?: string,
    projectName?: string
  ): string => {
    return `${currentTeamUser.login}/${groupName}/${subgroupName}/${projectName}`;
  };

  const rows = combinedProjectsData.map((projectData) => {
    const { name, project: group, projectEnvironment: subgroup } = projectData;
    const status = projectData.status ?? ProjectV3AdapterStatus.Unknown;

    return {
      [ColumnIds.PROJECT_V3_ID]: {
        text: projectData.id,
      },
      [ColumnIds.PROJECT_V3_NAME]: {
        text: name,
        link: `/${makeProjectPath(group?.name, subgroup?.name, name)}`,
        icon: <ProjectIcon project={projectData} boxSize={5} />,
      },
      [ColumnIds.PROJECT_V3_TYPE]: {
        text: (
          projectTypeDisplay[projectData.blueprint?.type ?? ""] ??
          getProjectTypeForRepo(projectData.repo)
        )?.name,
        blueprint: projectData.blueprint as any,
        project: projectData,
      },
      [ColumnIds.PROJECT_V3_SOURCE]: {
        text: makeSourceName(projectData),
        link: makeSourceLink(currentTeamUser.login, projectData),
      },
      [ColumnIds.PROJECT_V3_GROUP_NAME]: {
        text: group?.name || "",
        link: `/${currentTeamUser.login}/${group?.name}`,
      },
      [ColumnIds.PROJECT_V3_SUBGROUP_NAME]: {
        text: subgroup?.name || "",
        link: `/${currentTeamUser.login}/${group?.name}/${subgroup?.name}`,
      },
      [ColumnIds.PROJECT_V3_STATUS]: {
        text: status || "",
        status: isSampleProject(projectData.name)
          ? SAMPLE_PROJECT_STATUS
          : status,
        loading: projectsStatusLoading,
      },
      [ColumnIds.PROJECT_V3_PUBLIC_URL]: {
        text: projectData.repo?.productionDeployment?.endpoints?.[0] || "",
        link: makeLink(
          projectData.repo?.productionDeployment?.endpoints?.[0] || ""
        ),
        isExternal: true,
      },
      [ColumnIds.PROJECT_V3_PRIVATE_URL]: {
        text: projectData.repo?.productionDeployment?.privateEndpoint || "",
      },
      [ColumnIds.PROJECT_V3_UPDATED_AT]: {
        date: projectData.updatedAt,
        text: new Date(projectData.updatedAt).toISOString(),
      },
    };
  });

  return useMemo(() => {
    const formattedData = {
      columns: projectV3Columns,
      data: rows,
      skipReset: false,
    };
    const filteredData = filterViewDataByText(formattedData.data, [
      filter ?? "",
    ]);

    return {
      data: { ...formattedData, data: filteredData },
      loading: projectLoading || projectsTimeLoading,
      projects: combinedProjectsData,
      groups: projectsData?.user.projects.nodes,
    };
  }, [
    rows,
    filter,
    projectLoading,
    projectsTimeLoading,
    combinedProjectsData,
    projectsData?.user.projects.nodes,
  ]);
};

export { defaultColumnIds, projectV3Columns, useProjectListViewData };
