import { Flex } from "@chakra-ui/react";
import {
  LogEntry,
  RepoCommonFragment,
  RepoDetailFragment,
  useDeploymentLogsQuery,
  useUserRepoDeploymentsQuery,
} from "@zeet/web-api/dist/graphql";
import { SearchableProps, Select } from "@zeet/web-ui";
import React, { useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { TabWrapper } from ".";
import { EmptyState } from "../ListViewV2";
import { LogsConsole } from "../LogsConsole/LogsConsole";
import { DeploymentLogSection } from "./Deployment/DeploymentLogSection";
import { LogsSkeleton } from "./LogsSkeleton";
import { repoPath } from "./util";

export const DeploymentLogs: React.FC<
  {
    repo: RepoCommonFragment;
    deploymentID: string;
    fillContainer?: true;
  } & SearchableProps
> = ({ repo, deploymentID, fillContainer, searching, setSearching }) => {
  const { data, error } = useDeploymentLogsQuery({
    variables: {
      repoID: repo.id,
      deploymentID,
    },
    pollInterval: 3000,
  });

  if (error) {
    console.error(error);
  }

  const lines = useMemo(() => {
    const deployment = data?.currentUser?.repo?.deployment;
    if (!deployment) return [];
    if (!deployment.logsV2) {
      return data?.currentUser.repo?.deployment?.logs ?? [];
    }
    const lines: LogEntry[] = [];
    if (deployment.logsV2.previous) {
      lines.push(...deployment.logsV2.previous);
      lines.push({ text: "\x1b[30;101m<<< Container Crashed >>>\x1b[0m\n" });
    }

    if (deployment.logsV2?.current) {
      lines.push(...deployment.logsV2.current);
    }
    return lines;
  }, [data]);

  return (
    <LogsConsole
      lines={lines}
      searching={searching}
      setSearching={setSearching}
      height={fillContainer && "100%"}
      isLive
    />
  );
};

export const LogsTab = () => {
  return <TabWrapper component={Logs} />;
};

const Logs: React.FC<{
  repo: RepoDetailFragment;
}> = ({ repo }) => {
  const { subTab: initialDeploymentID } = useParams<{
    subTab: string;
  }>();

  const prodDeploymentID = repo.productionDeployment?.id;
  const deploymentID = initialDeploymentID || prodDeploymentID;
  const history = useHistory();

  const { data, loading, error } = useUserRepoDeploymentsQuery({
    variables: {
      id: repo.id,
    },
    pollInterval: 3000,
    errorPolicy: "all",
  });

  if (error) {
    console.error(error);
  }

  if (loading) {
    return <LogsSkeleton />;
  }

  const deployments = data?.currentUser?.repo?.deployments?.filter(
    (d) => d?.deployStatus?.active
  );

  if (deployments?.length === 0) {
    return <EmptyState heading="No logs available for this Project" />;
  }

  return (
    <Flex mt={4} flexDirection="column">
      {deployments?.length ? (
        <Select
          mb={4}
          onChange={(e) => history.push(`/${repoPath(repo)}/logs/${e?.value}`)}
          value={deploymentID}
          options={deployments.map((d) => ({
            value: d.id,
            label: `${d.version?.slice(0, 7)} (${d.branch})`,
          }))}
        />
      ) : null}
      <DeploymentLogSection
        deployment={deployments?.find((d) => d.id === deploymentID)}
        repo={repo}
        isLogsTab={true}
      />
    </Flex>
  );
};
