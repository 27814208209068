import { CheckCircleIcon, DeleteIcon, NotAllowedIcon } from "@chakra-ui/icons";
import {
  Button,
  Divider,
  Flex,
  FormControl,
  IconButton,
  Input,
  Spinner,
  Stack,
  Switch,
  Text,
  useToast,
} from "@chakra-ui/react";
import {
  CdnDetailFragment,
  CdnProvider,
  RepoDetailFragment,
  useAddCdnMutation,
  useRemoveCdnMutation,
  useRepoNetworkQuery,
  useUpdateCdnMutation,
} from "@zeet/web-api/dist/graphql";
import { CopyableText, Tooltip, useColors, ZError } from "@zeet/web-ui";
import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import { ZFormLabel } from "./Build";
import { DNSStatus } from "./Domain";

const CertificateStatus: React.FC<{
  cdn: CdnDetailFragment;
}> = ({ cdn }) => {
  return (
    <Stack isInline alignItems="center" justifyContent="space-between">
      <Flex alignItems="center">
        <Text mr={2} fontWeight="bold">
          SSL Certificate Status
        </Text>
        <Text mr={2} fontWeight="bold">
          {cdn.certificate?.ready
            ? "Ready"
            : cdn.certificate?.issuing
            ? "Issuing"
            : "Failed"}
        </Text>
        {cdn.certificate?.ready ? (
          <CheckCircleIcon color="success" />
        ) : cdn.certificate?.issuing ? (
          <Spinner size="sm" color="warning" />
        ) : (
          <NotAllowedIcon color="danger" />
        )}
      </Flex>
    </Stack>
  );
};

const CDNView: React.FC<{
  cdn: CdnDetailFragment;
  refetch: () => void;
}> = ({ cdn, refetch }) => {
  const { bg3 } = useColors();

  const [remove, { loading }] = useRemoveCdnMutation({
    onCompleted: () => {
      refetch();
    },
  });

  const [update, { loading: updateLoading }] = useUpdateCdnMutation({
    onCompleted: () => {
      refetch();
    },
  });

  type FormValues = {
    domain: string;
  };
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      domain: "",
    },
  });

  const hasDomain = !!cdn?.domains?.length;

  const onSubmit = (values: FormValues): void => {
    const newDomains = [
      ...(cdn?.domains?.map((d) => d.domain) || []),
      values.domain,
    ];

    update({
      variables: {
        input: {
          id: cdn.id,
          domains: newDomains,
        },
      },
    });

    reset();
  };

  const renderInstruction = useCallback(() => {
    return (
      <>
        <Text>
          Please add the following DNS records with your domain name provider.
        </Text>

        <Flex
          backgroundColor={bg3}
          width="100%"
          flexDirection="column"
          padding={2}
          borderRadius={4}
        >
          <Flex>
            <Text flex={1}>Type</Text>
            <Text flex={2} ml="2">
              Name
            </Text>
            <Text flex={3} ml="2">
              Value
            </Text>
            <Text flex={1}></Text>
          </Flex>

          {cdn.domains?.map((domain) => (
            <Flex mt={2} key={domain.domain}>
              <Text flex={1} color="brandVar" whiteSpace="nowrap">
                {domain.instruction?.type || "Pending"}
              </Text>
              <CopyableText
                flex={2}
                color="brandVar"
                whiteSpace="nowrap"
                ml="2"
                toCopy={domain.domain}
              >
                {domain.domain}
              </CopyableText>
              <CopyableText
                flex={3}
                color="brandVar"
                whiteSpace="nowrap"
                ml="2"
                toCopy={domain.instruction?.value}
              >
                {domain?.certReady
                  ? domain?.routeReady
                    ? domain?.instruction?.value
                    : "Waiting for CDN Deployment"
                  : "Waiting for SSL Certificate"}
              </CopyableText>
              <Flex flex={1}>
                <DNSStatus
                  domain={domain.domain}
                  ips={[domain.instruction?.value || ""]}
                />
              </Flex>
              <IconButton
                ml={"auto"}
                aria-label="delete"
                borderRadius={4}
                isLoading={updateLoading}
                icon={<DeleteIcon />}
                size="sm"
                onClick={() => {
                  update({
                    variables: {
                      input: {
                        id: cdn.id,
                        domains: cdn.domains
                          ?.map((d) => d.domain)
                          ?.filter((d) => d !== domain.domain),
                      },
                    },
                  });
                }}
              />
            </Flex>
          ))}
        </Flex>
      </>
    );
  }, [bg3, cdn.id, update, updateLoading, cdn.domains]);

  return (
    <Stack spacing={2}>
      <Flex alignItems="center" display="inline-flex">
        <Text fontWeight="bold">CloudFront CDN</Text>

        <IconButton
          ml={"auto"}
          aria-label="delete"
          borderRadius={4}
          isLoading={loading}
          icon={<DeleteIcon />}
          onClick={() => {
            remove({
              variables: {
                id: cdn.id,
              },
            });
          }}
        />
      </Flex>

      {hasDomain && (
        <>
          <Text>Step 1: Setup SSL Certificate</Text>
          <Text>
            Please add the following DNS records with your domain name provider.
          </Text>
          <Flex
            backgroundColor={bg3}
            width="100%"
            flexDirection="column"
            padding={2}
            borderRadius={4}
          >
            <Flex>
              <Text flex={1}>Type</Text>
              <Text flex={2} ml="2">
                Name
              </Text>
              <Text flex={3} ml="2">
                Value
              </Text>
              <Text flex={1}></Text>
            </Flex>
            {cdn.domains?.map((d) => {
              const instruction = cdn?.certificate?.instructions?.filter(
                (i) => i.name === d.domain
              )[0];

              return (
                <Flex mt={2} key={d?.domain}>
                  <Text flex={1} color="brandVar" whiteSpace="nowrap">
                    {instruction?.type || "Pending"}
                  </Text>

                  <CopyableText
                    flex={2}
                    color="brandVar"
                    ml="2"
                    overflowWrap="anywhere"
                    toCopy={instruction?.domain}
                  >
                    {instruction?.domain || "Pending"}
                  </CopyableText>

                  <CopyableText
                    flex={3}
                    color="brandVar"
                    ml="2"
                    overflowWrap="anywhere"
                    toCopy={instruction?.value}
                  >
                    {instruction?.value || "Pending"}
                  </CopyableText>

                  <Flex flex={1}>
                    <DNSStatus
                      domain={instruction?.domain || ""}
                      ips={[instruction?.value || ""]}
                    />
                  </Flex>
                </Flex>
              );
            })}
          </Flex>
          <CertificateStatus cdn={cdn} />

          <Divider />
          <Text>Step 2: Setup Domain Routing</Text>

          {renderInstruction()}
        </>
      )}

      <Stack isInline alignItems="center" justifyContent="space-between">
        <Flex alignItems="center" width="100%">
          <Text width="8rem">CDN Status</Text>
          <Flex>
            <Text mr={2} fontWeight="bold">
              {cdn.state}
            </Text>
            {["Ready", "READY"].includes(cdn.state) ? (
              <CheckCircleIcon color="success" />
            ) : ["Updating", "Waiting", "Deploying", "WAITING"].includes(
                cdn.state
              ) ? (
              <Spinner size="sm" color="warning" />
            ) : ["Failed", "ERROR"].includes(cdn.state) ? (
              <NotAllowedIcon color="danger" />
            ) : null}
          </Flex>
        </Flex>
      </Stack>

      <Flex width="100%">
        <Text width="8rem"> CDN Endpoint </Text>
        {cdn?.endpoint ? (
          <CopyableText
            color="brandVar"
            whiteSpace="nowrap"
            toCopy={cdn.endpoint}
          >
            {cdn.endpoint}
          </CopyableText>
        ) : (
          <Text flex={5}>
            <b>Pending</b>
          </Text>
        )}
      </Flex>

      <form
        onSubmit={(e) => {
          e.stopPropagation();
          e.preventDefault();
          handleSubmit(onSubmit)(e);
        }}
      >
        <FormControl isInvalid={errors.domain && !!errors.domain.message}>
          <Stack spacing={2} mt={2}>
            <Flex alignContent="center">
              <ZFormLabel>Add Custom Domain</ZFormLabel>
              <Input
                {...register("domain", {
                  required: "Required",
                  validate: {
                    domain: (value) => {
                      return (
                        /^(\*\.)?(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)+([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])$/.test(
                          value.trim().toLocaleLowerCase()
                        ) || "invalid domain name"
                      );
                    },
                  },
                })}
                placeholder="example.com"
              />
              <Button
                ml={4}
                colorScheme="brand"
                isLoading={updateLoading}
                type="submit"
              >
                Add
              </Button>
            </Flex>
            <ZError error={errors.domain && errors.domain.message} />
          </Stack>
        </FormControl>
      </form>
    </Stack>
  );
};

export const CDNSettings: React.FC<{ repo: RepoDetailFragment }> = ({
  repo,
}) => {
  const toast = useToast();

  const {
    data,
    loading: getLoading,
    refetch,
  } = useRepoNetworkQuery({
    skip: !repo?.cdns?.length,
    variables: {
      id: repo.id,
    },
    pollInterval: 3000,
  });

  const [addCDN, { loading: addLoading, error: addError, data: addData }] =
    useAddCdnMutation({
      onCompleted: (data) => {
        if (data) {
          toast({
            title: "CDN Saved",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          refetch();
        }
      },
    });

  const loading = getLoading || addLoading;

  const cdn = data?.repo?.cdns?.[0];

  // only show error if add failed
  const error = !addData?.addCDN?.id && addError;

  return (
    <Stack w="100%">
      <Text fontWeight="bold" fontSize="1.2rem">
        Content Delivery Network
        <Tooltip
          text={`You can add a CDN to your project to serve content from a CDN.`}
        />
      </Text>
      <Text>
        {
          "Content Delivery Network speed up your website and protect you from DDOS by serving content from global edge locations."
        }
      </Text>
      {cdn ? (
        <CDNView cdn={cdn} refetch={refetch} />
      ) : (
        <Stack>
          <Flex>
            <ZFormLabel flex={0}> CloudFront CDN Disabled </ZFormLabel>
            <Switch
              onChange={(e) => {
                e.target.checked &&
                  addCDN({
                    variables: {
                      input: {
                        id: repo.id,
                        provider: CdnProvider.AwsCloudfront,
                        domains: data?.repo?.clusterDomains?.[0]?.domains?.map(
                          (d) => d.domain
                        ),
                      },
                    },
                  });
              }}
              isDisabled={loading || !!error}
              size="lg"
            />
            {loading && <Spinner ml={4} />}
          </Flex>
          <ZError error={error} />
        </Stack>
      )}
    </Stack>
  );
};
