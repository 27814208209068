import {
  GenericWorkflowStepActionType,
  WorkflowRunDetailFragment,
  WorkflowRunStepDetailFragment,
  WorkflowRunStepStatus,
} from "@zeet/web-api/dist/graphqlv1";
import { AiOutlineClockCircle, AiOutlineStop } from "react-icons/ai";
import { BuildStepRunDetail } from "./Steps/BuildRunStepDetail";
import {
  ClusterPrecheckStepDetail,
  ClusterStep,
} from "./Steps/ClusterPrecheckStepDetail";
import { DeployRunStepDetail } from "./Steps/DeployRunStepDetail";
import { DestroyStepRunDetail } from "./Steps/DestroyRunStepDetail";
import { GenericStepRunDetail } from "./Steps/GenericRunStepDetail";
import { StepUnavailableInfo } from "./Steps/StepUnavailableInfo";
import { WorkflowRunOverview } from "./Steps/WorkflowRunOverview";
import { workflowActionTitleMap } from "./utils";

interface WorkflowRunStepDetailProps {
  activeStep?: WorkflowRunStepDetailFragment | null;
  workflowRun: WorkflowRunDetailFragment;
  workflowId: string;
  projectId: string;
}

export const WorkflowRunLayout = ({
  activeStep,
  workflowRun,
  workflowId,
  projectId,
}: WorkflowRunStepDetailProps) => {
  if (!activeStep) {
    return (
      <WorkflowRunOverview
        projectId={projectId}
        workflowRun={workflowRun}
        workflowId={workflowId}
      />
    );
  }

  if (
    activeStep.status === WorkflowRunStepStatus.Aborted &&
    !activeStep.executionId
  ) {
    return (
      <StepUnavailableInfo
        icon={AiOutlineStop}
        title={`${workflowActionTitleMap[activeStep.action]} Skipped`}
        subtitle="This step was skipped because of an abort action"
      />
    );
  }

  if (activeStep.status === WorkflowRunStepStatus.Pending) {
    return (
      <StepUnavailableInfo
        icon={AiOutlineClockCircle}
        title={`${workflowActionTitleMap[activeStep.action]} Pending`}
        subtitle="This step is waiting to be executed"
      />
    );
  }

  if (
    activeStep.action === GenericWorkflowStepActionType.OrchestrationDestroy
  ) {
    return (
      <DestroyStepRunDetail
        runId={workflowRun.id}
        step={activeStep}
        projectId={projectId}
      />
    );
  }

  if (activeStep.action === GenericWorkflowStepActionType.OrchestrationBuild) {
    return (
      <BuildStepRunDetail
        step={activeStep}
        projectId={projectId}
        runId={workflowRun.id}
      />
    );
  }

  if (activeStep.action === GenericWorkflowStepActionType.OrchestrationDeploy) {
    return (
      <DeployRunStepDetail
        step={activeStep}
        projectId={projectId}
        runId={workflowRun.id}
      />
    );
  }

  if (
    activeStep.action ===
    GenericWorkflowStepActionType.OrchestrationClusterPrecheck
  ) {
    return <ClusterPrecheckStepDetail step={activeStep as ClusterStep} />;
  }

  return (
    <GenericStepRunDetail
      projectId={projectId}
      runId={workflowRun.id}
      step={activeStep}
    />
  );
};
