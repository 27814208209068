import { Badge, BadgeProps, theme, useColors } from "@zeet/web-ui";

interface StatusBadgeProps {
  themeColor: theme.StatusColor;
  text: string;
}

const StatusBadge = ({
  themeColor,
  text,
  ...restProps
}: StatusBadgeProps & BadgeProps) => {
  const { status } = useColors();

  return (
    <Badge colorScheme={status[themeColor].badge} {...restProps}>
      {text}
    </Badge>
  );
};

export default StatusBadge;
