import { CloudProvider } from "@zeet/web-api/dist/graphql";

export enum CloudProvidersEnum {
  AWS = "AWS",
  GCP = "GCP",
  DO = "DO",
  CW = "CW",
  LINODE = "LINODE",
  OTHER = "OTHER",
}

export interface IReposList {
  // eslint-disable-next-line
  repos: any;
}

export interface IClustersList {
  // eslint-disable-next-line
  clusters: any;
  cloudId: string;
}

export interface ICloudAccount {
  cloudProvider: CloudProvider;
  zeetCloudId: string | null | undefined;
  cloudProviderId: string | null | undefined;
}

export interface IRegion {
  name: string;
  isOptIn: boolean;
}

export interface IRegions {
  [CloudProvider.Aws]: IRegion[];
  [CloudProvider.Gcp]: IRegion[];
  [CloudProvider.Do]: IRegion[];
  [CloudProvider.Coreweave]: IRegion[];
  [CloudProvider.Linode]: IRegion[];
  [CloudProvider.Vultr]: IRegion[];
  [CloudProvider.Azure]: IRegion[];
}

export interface IResourceQuerySettings {
  currentAwsId: string;
}
