import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  ProjectBasicFragment,
  useUpdateProjectV2Mutation,
} from "@zeet/web-api/dist/graphql";
import { useDeleteGroupMutation } from "@zeet/web-api/dist/graphqlv1";
import { DeleteDialog, Link, useCurrentTeamUser, useTrack } from "@zeet/web-ui";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { RiDeleteBinLine } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import { FormCard } from "../../FormCard";

interface FormData {
  name: string;
  autoCleanupEnabled: boolean;
}

const DangerSettings: React.FC<{ project: ProjectBasicFragment }> = ({
  project,
}) => {
  const [updateProject, { loading, error }] = useUpdateProjectV2Mutation();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors: formErrors },
  } = useForm<FormData>({
    defaultValues: {
      name: project.name,
      autoCleanupEnabled: project.autoCleanupEnabled,
    },
  });
  const toast = useToast();
  const history = useHistory();
  const currentTeam = useCurrentTeamUser();

  useEffect(() => {
    if (error) {
      toast({
        title: "Failed to update project",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [error, toast]);

  const onSubmit = (data: FormData) => {
    updateProject({
      variables: {
        input: {
          id: project.id,
          name: data.name,
          autoCleanupEnabled: data.autoCleanupEnabled,
        },
      },
      onCompleted(data) {
        if (data) {
          toast({
            title: "Project updated",
            status: "success",
            duration: 5000,
            isClosable: true,
          });

          history.push(
            `/${currentTeam.login}/${data.updateProjectV2.name}/_settings/danger`
          );
        }
      },
    });
  };

  return (
    <FormCard
      description={
        "Changing any of these values will invalidate all previous Zeet Dashboard URLs for this project and its environment and resource pages."
      }
      title="Project Settings"
      onSubmit={(e) => handleSubmit(onSubmit)(e)}
      isLoading={loading}
    >
      <Stack direction="row" alignItems="center" spacing={8} mb="4">
        <Text whiteSpace={"nowrap"}>Project name</Text>
        <FormControl isInvalid={!!formErrors.name}>
          <Input {...register("name", { required: "A name is required" })} />
          {formErrors.name && (
            <FormErrorMessage>{formErrors.name.message}</FormErrorMessage>
          )}
        </FormControl>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={8}>
        <Flex alignItems={"center"}>
          <Text whiteSpace={"nowrap"}>Auto-Cleanup Enabled</Text>
        </Flex>
        <FormControl isInvalid={!!formErrors.autoCleanupEnabled}>
          <Controller
            name="autoCleanupEnabled"
            control={control}
            render={({ field: { onChange, ref, value } }) => (
              <Checkbox isChecked={value} onChange={onChange} ref={ref} />
            )}
          />
          {formErrors.autoCleanupEnabled && (
            <FormErrorMessage>
              {formErrors.autoCleanupEnabled.message}
            </FormErrorMessage>
          )}
        </FormControl>
      </Stack>
      <Text fontSize={"xs"}>
        When enabled, this Group will be automatically deleted after deleting
        the last Project within the Group. See{" "}
        <Link
          to="https://docs.zeet.co/reference/organization/#auto-cleanup"
          textDecor={"underline"}
          isExternal={true}
        >
          the docs
        </Link>{" "}
        for more info.
      </Text>
    </FormCard>
  );
};

const DeleteProject: React.FC<{ project: ProjectBasicFragment }> = ({
  project,
}) => {
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const currentTeam = useCurrentTeamUser();
  const [deleteGroup, { loading }] = useDeleteGroupMutation({
    errorPolicy: "none",
  });

  const { track } = useTrack();

  return (
    <>
      <Flex justifyContent="center" p={4}>
        <Button
          alignSelf="center"
          leftIcon={<RiDeleteBinLine size="1.5rem" />}
          p={8}
          shadow="md"
          borderRadius={4}
          display="flex"
          colorScheme="red"
          isLoading={loading}
          onClick={() => {
            onOpen();
            track("click_project_settings_delete_v2");
          }}
        >
          Delete Group
        </Button>
      </Flex>
      <DeleteDialog
        confirmationString={project.name}
        onClose={onClose}
        onDelete={() => {
          track("click_project_settings_delete_confirm_v2");
          return deleteGroup({ variables: { id: project.id } });
        }}
        onSuccess={() => {
          history.push(`/${currentTeam.login}/console/home`);
        }}
        isLoading={loading}
        isOpen={isOpen}
        type="group"
        warningMessage={
          <Box>
            This action cannot be undone. We will attempt to delete all projects
            in <Text as="b">{project.name}</Text>. It may take a long time to
            fully delete the group.
          </Box>
        }
      />
    </>
  );
};

export const Danger: React.FC<{ project: ProjectBasicFragment }> = ({
  project,
}) => {
  return (
    <Stack spacing={8}>
      <DangerSettings project={project} />

      <DeleteProject project={project} />
    </Stack>
  );
};
