import { ButtonProps as ChakraButtonProps } from "@chakra-ui/react";
import {
  ClusterState,
  DeploymentStatus,
  ProjectV3AdapterStatus,
} from "@zeet/web-api/dist/graphql";
import { Badge, Skeleton, StatusBadgeProps } from ".";

const SAMPLE_PROJECT_STATUS = "SAMPLE PROJECT";
const PROJECT_CLUSTER_CREATING_STATUS = "CLUSTER CREATING";

interface ProjectStatusProps extends ChakraButtonProps {
  status?: ProjectV3AdapterStatus | StatusBadgeProps["status"] | null;
  loading?: boolean;
  clusterState?: ClusterState;
}

type ProjectDisplayStatus = {
  color: string;
  displayName: string;
};

type ProjectDisplayStatusMap = {
  [key in ProjectV3AdapterStatus]?: ProjectDisplayStatus;
};

export const repoColorMap = {
  [DeploymentStatus.BuildPending]: "yellow",
  [DeploymentStatus.BuildInProgress]: "blue",
  [DeploymentStatus.BuildFailed]: "red",
  [DeploymentStatus.BuildSucceeded]: "brand",
  [DeploymentStatus.BuildAborted]: "gray",
  [DeploymentStatus.DeployPending]: "yellow",
  [DeploymentStatus.DeployInProgress]: "purple",
  [DeploymentStatus.ReleaseInProgress]: "purple",
  [DeploymentStatus.DeployFailed]: "red",
  [DeploymentStatus.DeploySucceeded]: "brand",
  [DeploymentStatus.DeployStopped]: "gray",
  DEPLOYED: "brand",
  DEPLOYING: "purple",
  "SHUTTING DOWN": "yellow",
  ERROR: "red",
  CRASH: "red",
  CRASHING: "red",
  INACTIVE: "gray",
  PAUSED: "gray",
  FAILED: "red",
  STARGING: "purple",
  SUCCEEDED: "brand",
  STOPPED: "gray",
  DRAFT: "gray",
  [SAMPLE_PROJECT_STATUS]: "gray",
  [PROJECT_CLUSTER_CREATING_STATUS]: "yellow",
};
export const displayStatuses: ProjectDisplayStatusMap = {
  // Failure statuses
  [ProjectV3AdapterStatus.BuildAborted]: {
    color: "red",
    displayName: "Build Aborted",
  },
  [ProjectV3AdapterStatus.BuildFailed]: {
    color: "red",
    displayName: "Build Failed",
  },
  [ProjectV3AdapterStatus.DeployCrashing]: {
    color: "red",
    displayName: "Deploy Crashing",
  },
  [ProjectV3AdapterStatus.DeployFailed]: {
    color: "red",
    displayName: "Deploy Failed",
  },
  [ProjectV3AdapterStatus.DeployStopped]: {
    color: "red",
    displayName: "Deploy Stopped",
  },
  [ProjectV3AdapterStatus.JobRunFailed]: {
    color: "red",
    displayName: "Deploy Failed",
  },

  // In progress statuses
  [ProjectV3AdapterStatus.BuildInProgress]: {
    color: "yellow",
    displayName: "Building",
  },
  [ProjectV3AdapterStatus.BuildPending]: {
    color: "yellow",
    displayName: "Build Queued",
  },
  [ProjectV3AdapterStatus.ContainerPulling]: {
    color: "yellow",
    displayName: "Container Pulling",
  },
  [ProjectV3AdapterStatus.DeployInProgress]: {
    color: "yellow",
    displayName: "Deploying",
  },
  [ProjectV3AdapterStatus.DeployPending]: {
    color: "yellow",
    displayName: "Deploy Queued",
  },
  [ProjectV3AdapterStatus.HealthChecking]: {
    color: "yellow",
    displayName: "Health Checking",
  },
  [ProjectV3AdapterStatus.JobRunPendingApproval]: {
    color: "yellow",
    displayName: "Pending Approval",
  },
  [ProjectV3AdapterStatus.JobRunRunning]: {
    color: "yellow",
    displayName: "Deploying",
  },
  [ProjectV3AdapterStatus.JobRunStarting]: {
    color: "yellow",
    displayName: "Queued",
  },
  [ProjectV3AdapterStatus.NodeProvisioning]: {
    color: "yellow",
    displayName: "Node Provisioning",
  },

  // Success statuses
  [ProjectV3AdapterStatus.BuildSucceeded]: {
    color: "green",
    displayName: "Build Succeeded",
  },
  [ProjectV3AdapterStatus.DeploySucceeded]: {
    color: "brand",
    displayName: "Deployed",
  },
  [ProjectV3AdapterStatus.JobRunSucceeded]: {
    color: "brand",
    displayName: "Deployed",
  },
  [ProjectV3AdapterStatus.DeployHealthy]: {
    color: "brand",
    displayName: "Healthy",
  },

  // Other statuses
  [ProjectV3AdapterStatus.Draft]: {
    color: "gray",
    displayName: "Draft",
  },
  [ProjectV3AdapterStatus.Inactive]: {
    color: "gray",
    displayName: "Inactive",
  },
  [ProjectV3AdapterStatus.JobRunCancelled]: {
    color: "gray",
    displayName: "Deploy Cancelled",
  },
  [ProjectV3AdapterStatus.Paused]: {
    color: "gray",
    displayName: "Paused",
  },
  [ProjectV3AdapterStatus.Unknown]: {
    color: "gray",
    displayName: "Unknown",
  },
  [ProjectV3AdapterStatus.PendingHealthyCluster]: {
    color: "yellow",
    displayName: "Cluster Setup Required",
  },
};

export const ProjectStatus: React.FC<ProjectStatusProps> = ({
  status,
  loading,
  clusterState,
  ...restProps
}) => {
  // applies any override logic
  status = statusOverride(status);

  // project status -> legacy status ->  brand (brand) colored legacy status -> unknown
  const displayStatus = status
    ? displayStatuses[status] ?? {
        // fallback to legacy
        color: repoColorMap[status.toUpperCase()] || "gray",
        displayName: status,
      }
    : displayStatuses[ProjectV3AdapterStatus.Unknown];

  if (loading) {
    return <Skeleton height="18px" width="100px" />;
  }

  return (
    <Badge
      colorScheme={displayStatus?.color}
      borderRadius="6px"
      fontWeight="700"
      {...restProps}
    >
      {displayStatus?.displayName}
    </Badge>
  );
};

const statusOverride = (
  status?: ProjectV3AdapterStatus | StatusBadgeProps["status"] | null,
  clusterState?: ClusterState
) => {
  if (status !== ProjectV3AdapterStatus.PendingHealthyCluster) {
    return status;
  }

  if (
    !clusterState ||
    ![ClusterState.Creating, ClusterState.Pending].includes(clusterState)
  ) {
    return status;
  }

  return PROJECT_CLUSTER_CREATING_STATUS;
};
