import { Box, Heading } from "@chakra-ui/react";

import {
  useWorkflowRunStepNestedDetailQuery,
  WorkflowRunStepDetailFragment,
} from "@zeet/web-api/dist/graphqlv1";
import { useCurrentTeamUser } from "@zeet/web-ui";
import { PropsWithChildren } from "react";
import { MdError } from "react-icons/md";
import { getNestedWorkflowRun, sortStepsBySequenceNumber } from "../utils";
import { WorkflowCanvas } from "../WorkflowCanvas";
import { StepUnavailableInfo } from "./StepUnavailableInfo";
import { WorkflowStepSection } from "./WorkflowStepSection";

interface GenericRunStepNestedDetailProps extends PropsWithChildren {
  step: WorkflowRunStepDetailFragment;
  runId: string;
  projectId: string;
  title: string;
}

export const GenericRunStepNestedDetail = ({
  step: nestedStep,
  runId,
  projectId,
  title,
  children,
}: GenericRunStepNestedDetailProps) => {
  const currentTeamUser = useCurrentTeamUser();
  const { data } = useWorkflowRunStepNestedDetailQuery({
    variables: {
      teamId: currentTeamUser?.id,
      stepId: nestedStep.id,
      runId,
      projectId,
    },
    pollInterval: 3000,
  });

  const workflowRun = getNestedWorkflowRun(data);

  if (!workflowRun) {
    return (
      <StepUnavailableInfo
        icon={MdError}
        title={`Step is unavailable`}
        subtitle="An error occurred in this step"
      />
    );
  }

  const steps = sortStepsBySequenceNumber(workflowRun?.steps);

  return (
    <Box pl="4" mt="1">
      <Box mb={4}>
        <WorkflowCanvas
          projectId={projectId}
          title={`${title} workflow`}
          workflowRun={workflowRun}
          disableStepLinks
        />
      </Box>
      <Box data-testid="driver-workflow-steps-with-logs">
        <Heading fontSize="14px" mb={4} opacity={0.5}>
          Steps
        </Heading>
        {steps?.map((step) => (
          <WorkflowStepSection
            key={step?.id}
            workflowRunId={data?.team?.project?.workflow?.run?.id}
            step={step}
            nestedStep={nestedStep}
            runId={runId}
            projectId={projectId}
          />
        ))}
      </Box>
      {children}
    </Box>
  );
};
