import { DataTypes } from "@zeet/web-ui";
import { jobRunStatusMap } from "../../utils";

export enum JobRunColumnId {
  Name = "jobRunName",
  Status = "jobRunStatus",
  CreatedAt = "jobRunCreated",
}

export const jobRunColumnIds = Object.values(JobRunColumnId);
export const jobRunColumns = [
  {
    id: JobRunColumnId.Name,
    dataType: DataTypes.LINK,
    minWidth: 250,
    order: 1,
    label: "Name",
    options: [],
  },
  {
    id: JobRunColumnId.Status,
    dataType: DataTypes.BADGE,
    minWidth: 200,
    order: 2,
    label: "Status",
    options: [
      {
        statusMap: jobRunStatusMap,
      },
    ],
  },
  {
    id: JobRunColumnId.CreatedAt,
    dataType: DataTypes.TIMEAGO,
    minWidth: 180,
    order: 3,
    label: "Triggered",
    options: [],
  },
];
