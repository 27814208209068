import {
  UserCloudsQuery,
  useUserCloudsQuery,
} from "@zeet/web-api/dist/graphql";
import { useCurrentTeamUser } from "@zeet/web-ui";
import React, { createContext, useContext } from "react";

interface CloudsContextValues {
  cloudQueryData?: UserCloudsQuery;
  cloudQueryLoading?: boolean;
}

interface CloudsContextActions {
  refetchClouds: () => void;
}
type CloudsContext = [CloudsContextValues, CloudsContextActions];

const noop = () => null;

const cloudsContext = createContext<CloudsContext>([
  // default values
  {},
  {
    refetchClouds: noop,
  },
]);

export const CloudsProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const currentTeamUser = useCurrentTeamUser();
  const {
    data: cloudQueryDataRaw,
    loading: cloudQueryLoading,
    refetch: refetchClouds,
  } = useUserCloudsQuery({
    variables: {
      id: currentTeamUser.id,
    },
  });

  const actions: CloudsContextActions = {
    refetchClouds,
  };
  return (
    <cloudsContext.Provider
      value={[
        {
          cloudQueryData: cloudQueryDataRaw,
          cloudQueryLoading,
        },
        actions,
      ]}
    >
      {children}
    </cloudsContext.Provider>
  );
};

export function useCloudsContext(): CloudsContext {
  return useContext(cloudsContext);
}
