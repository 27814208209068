import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import {
  MetricLabelType,
  ObservabilityRange,
} from "@zeet/web-api/dist/graphql";
import {
  ButtonState,
  ButtonStateOption,
  Container,
  Link,
  Select,
  useColors,
} from "@zeet/web-ui";
import { Fragment, useState } from "react";
import { MultiValue } from "react-select";
import { MetricsSkeleton } from "../MetricsSkeleton";
import {
  convertToMetricRange,
  MetricAggregationOption,
  metricAggregationOptions,
  metricGroupByOptions,
  MetricRangeOption,
  metricRangeOptions,
} from "./index";

interface MetricControlPanelCta {
  link: string;
  label: string;
}

interface MetricsControlPanelProps {
  ctas?: MetricControlPanelCta[];
  isLoading?: boolean;
  isDisabled?: boolean;
  onRangeChange?: (range: ObservabilityRange) => void;
  onGroupByChange?: (groupBy: MetricLabelType[]) => void;
  onAggregationChange?: (aggregationOption: MetricAggregationOption) => void;
  groupBy?: MetricLabelType[];
  setGroupBy?: (groupBy: MetricLabelType[]) => void;
}

export const useMetricsControlPanel = (
  defaultRange: MetricRangeOption = MetricRangeOption.PAST_1_HOUR
) => {
  const [metricRange, setMetricRange] = useState<ObservabilityRange>(
    convertToMetricRange(defaultRange)
  );
  const [groupBy, setGroupBy] = useState<MetricLabelType[]>([]);
  const [aggregationOption, setAggregationOption] =
    useState<MetricAggregationOption>(MetricAggregationOption.UNIFIED);

  return {
    metricRange,
    setMetricRange,
    groupBy,
    setGroupBy,
    aggregationOption,
    setAggregationOption,
  };
};

export const MetricsControlPanel: React.FC<
  React.PropsWithChildren<MetricsControlPanelProps>
> = ({
  ctas,
  isLoading,
  isDisabled,
  onRangeChange,
  onGroupByChange,
  onAggregationChange,
  children,
  groupBy,
  setGroupBy,
}) => {
  const [metricSelectedRangeOption, setMetricSelectedRangeOption] = useState(
    MetricRangeOption.PAST_1_HOUR.toString()
  );
  const [showGroupBy, setShowGroupBy] = useState(false);
  const [selectedAggregation, setSelectedAggregation] = useState(
    MetricAggregationOption.UNIFIED
  );
  const { bg2 } = useColors();

  const onMetricRangeChange = (
    newValue: { label: string; value: string } | null
  ) => {
    if (!newValue) {
      return;
    }

    const metricRangeOption = newValue.value as MetricRangeOption;
    setMetricSelectedRangeOption(metricRangeOption.toString());
    onRangeChange?.(convertToMetricRange(metricRangeOption));
  };

  const onMetricGroupByChange = (
    newValues: MultiValue<{ label: string; value: string[] }>
  ) => {
    const metricGroupByTypes = newValues
      .filter((v) => !!v.value.length)
      .map((v) => v.value[0] as MetricLabelType);
    setGroupBy?.(metricGroupByTypes);
    onGroupByChange?.(metricGroupByTypes);
  };

  const onMetricAggregationChange = (newValue: ButtonStateOption) => {
    setSelectedAggregation(newValue.value as MetricAggregationOption);

    if (newValue.value === MetricAggregationOption.UNIFIED) {
      setGroupBy?.([]);
      setShowGroupBy(false);
      return;
    }

    setShowGroupBy(true);
    onAggregationChange?.(newValue.value as MetricAggregationOption);
  };

  const showControls = onGroupByChange || onRangeChange || ctas;

  return (
    <Box
      background={bg2}
      border="1px solid var(--chakra-colors-chakra-border-color)"
      borderRadius={10}
      pt={3}
    >
      {showControls && (
        <Container
          fullWidth
          px={3}
          pb={3}
          borderBottomWidth="1px"
          gridColumnStart={1}
          gridColumnEnd={3}
          alignItems="center"
          display="flex"
          mb="3"
        >
          <Flex
            flex="1"
            justifyContent={onGroupByChange ? "space-between" : "flex-end"}
          >
            {onGroupByChange && (
              <Flex alignItems="center">
                <ButtonState
                  isDisabled={isLoading || isDisabled}
                  value={selectedAggregation.toString()}
                  onSelected={onMetricAggregationChange}
                  options={metricAggregationOptions}
                />
                {showGroupBy && (
                  <Fragment>
                    <Text ml="3" mr="3">
                      group by
                    </Text>
                    <Select
                      chakraStyles={{
                        control: (provider) => ({
                          ...provider,
                          borderRadius: "6px",
                        }),
                      }}
                      isMulti
                      size="sm"
                      flex={1}
                      isSearchable={false}
                      onChange={onMetricGroupByChange}
                      value={(groupBy ?? []).map((v) => v.toString())}
                      options={metricGroupByOptions}
                      isDisabled={isLoading || isDisabled}
                    />
                  </Fragment>
                )}
              </Flex>
            )}
            {(onRangeChange || ctas) && (
              <Flex>
                {(ctas ?? []).map((cta, ci) => (
                  <Button
                    key={ci}
                    as={Link}
                    ml={ci ? 3 : 0}
                    size="sm"
                    _hover={{ textDecoration: "none" }}
                    to={cta.link}
                    rightIcon={<ExternalLinkIcon />}
                  >
                    {cta.label}
                  </Button>
                ))}
                {onRangeChange && (
                  <Flex alignItems="center" ml={3}>
                    <Text fontWeight="bold" mr="3">
                      Range
                    </Text>
                    <Select
                      chakraStyles={{
                        control: (provider) => ({
                          ...provider,
                          borderRadius: "6px",
                        }),
                      }}
                      size="sm"
                      flex={1}
                      isSearchable={false}
                      onChange={onMetricRangeChange}
                      value={metricSelectedRangeOption}
                      options={metricRangeOptions}
                      isDisabled={isLoading || isDisabled}
                    />
                  </Flex>
                )}
              </Flex>
            )}
          </Flex>
        </Container>
      )}
      {isLoading ? <MetricsSkeleton /> : children}
    </Box>
  );
};
