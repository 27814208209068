import { DeleteIcon, ExternalLinkIcon, SettingsIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertIcon,
  Badge,
  Box,
  Button,
  Code,
  Tooltip as CTooltip,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Stack,
  Switch,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  CloudProvider,
  ClusterCertIssuersQuery,
  FeatureKey,
  Port,
  RepoDetailFragment,
  useClusterCertIssuersQuery,
  useSubscriptionFeatureQuery,
  useUpdateProjectSettingsMutation,
} from "@zeet/web-api/dist/graphql";
import {
  AnalyticsEvent,
  CopyLink,
  CopyText,
  Flag,
  FormSelect,
  Link,
  Loading,
  Tooltip,
  useColors,
  useCurrentTeamUser,
  useFeatureFlag,
  useTrack,
  ZError,
} from "@zeet/web-ui";
import React, { useMemo, useState } from "react";
import {
  useFieldArray,
  useForm,
  UseFormRegister,
  UseFormStateReturn,
} from "react-hook-form";
import { FaGlobe, FaLock } from "react-icons/fa";
import { GiCheckMark } from "react-icons/gi";
import { ImArrowUp } from "react-icons/im";
import { UpgradeProductModal } from "~/components/Account/BillingV2/Modals/UpgradeProductModal";
import { useBillingOverviewContext } from "~/components/Account/BillingV2/Providers/BillingOverviewProvider";
import {
  BillingModalSource,
  billingModalText,
  getHeaderText,
} from "~/components/Account/BillingV2/utils";
import { makeLink } from "../../utils/link";
import { isKube } from "../../utils/project";
import {
  getPrivateEndpointFQDN,
  portValidationFunction,
  shouldDisplayError,
} from "../util";
import { ZFormLabel } from "./Build";

type FormType = {
  ports: Port[];
  httpsPort?: string;
  isGrpc: boolean;
};

const PortRow: React.FC<{
  repo: RepoDetailFragment;
  port: Port;
  index: number;
  register: UseFormRegister<FormType>;
  removeItem: (index: number) => void;
  errors: UseFormStateReturn<FormType>["errors"];
}> = ({ repo, port, index, register, removeItem, errors }) => {
  const isRange = port.port?.includes("-");

  const isPublic = port.public || port.loadBalancer;

  const { bg3, bgButtonHover } = useColors();

  const privateFQDN = getPrivateEndpointFQDN(repo);
  const effectiveExternalPort = port.externalPort || port.port;

  const loadBalancer = repo.productionDeployment?.loadBalancers?.[0];

  const loadBalancerAddresses = [
    ...(loadBalancer?.dns || []),
    ...(loadBalancer?.ips || []),
  ];
  const nodeIps = repo.productionDeployment?.deployStatus?.publicIPs || [];

  const errorMsgs = Object.keys(errors.ports?.[index] || {}).map(
    (key) => errors.ports?.[index]?.[key]?.message
  );

  return (
    <Box
      backgroundColor={bg3}
      borderRadius="md"
      paddingX={4}
      paddingY={2}
      boxShadow={"md"}
    >
      <FormControl isInvalid={errorMsgs.length > 0}>
        <Accordion allowToggle defaultIndex={port.port ? undefined : 0}>
          <AccordionItem border="none">
            <HStack
              mb={[5, 5, 0]}
              width={"100%"}
              spacing={4}
              alignItems="center"
              justifyContent={"space-between"}
            >
              <HStack width="20rem">
                <Text>
                  Port
                  <Tooltip
                    text={`The port that your container is listening on, your application must be configured to listen on 0.0.0.0`}
                  />
                </Text>
                <Input
                  flex={1}
                  {...register(`ports.${index}.port`, {
                    validate: portValidationFunction,
                  })}
                  placeholder="(e.g. 80)"
                  defaultValue={port?.port}
                  width="auto"
                  type="text"
                />
              </HStack>
              <Text display="inline">Protocol</Text>
              <FormSelect
                {...register(`ports.${index}.protocol`)}
                defaultValue={port.protocol}
                flex="1"
                options={[
                  { value: "tcp", label: "TCP" },
                  { value: "udp", label: "UDP" },
                ]}
              />
              <HStack width={"6rem"}>
                {isPublic ? (
                  <>
                    <FaGlobe />
                    <Text>Public</Text>
                  </>
                ) : (
                  <>
                    <FaLock />
                    <Text>Internal</Text>
                  </>
                )}
              </HStack>
              <AccordionButton
                as={Button}
                _hover={{ background: bgButtonHover }}
                width={"auto"}
                leftIcon={<SettingsIcon />}
                rightIcon={<AccordionIcon />}
              >
                Edit Details
              </AccordionButton>
            </HStack>
            <AccordionPanel paddingX={0} paddingTop={2} paddingBottom={0}>
              <Stack>
                <HStack
                  mb={[5, 5, 0]}
                  width={"100%"}
                  spacing={4}
                  alignItems="center"
                >
                  <HStack width="20rem">
                    <Text whiteSpace={"nowrap"}>
                      External Port
                      <Tooltip
                        text={`Configure an External Port different from the container port, the external port is the port that the service will be accessible on using the internal endpoint or load balancer`}
                      />
                    </Text>
                    <Input
                      flex={1}
                      {...register(`ports.${index}.externalPort`, {
                        validate: portValidationFunction,
                      })}
                      placeholder="optional (e.g. 80)"
                      defaultValue={port?.externalPort}
                      width="auto"
                      type="text"
                      disabled={isRange}
                    />
                  </HStack>
                </HStack>
                <HStack
                  mb={[5, 5, 0]}
                  width={"100%"}
                  spacing={4}
                  alignItems="center"
                >
                  <Text whiteSpace="nowrap">
                    Load Balancer
                    <Tooltip
                      text={`Configure an External Load Balancer for this port using ${
                        repo?.cluster?.cloudProvider === CloudProvider.Aws
                          ? "AWS Network Load Balancer"
                          : repo?.cluster?.cloudProvider === CloudProvider.Gcp
                          ? "GCP Cloud Load Balancing"
                          : "Cloud Load Balancer "
                      }`}
                    />
                  </Text>
                  <Switch
                    size="lg"
                    {...register(`ports.${index}.loadBalancer`)}
                    defaultChecked={port?.loadBalancer}
                    isDisabled={isRange}
                  />
                  <Text>
                    Host Port
                    <Tooltip text="Accessible directly from Node IP? Or only via Private Networking?" />
                  </Text>
                  <Switch
                    size="lg"
                    defaultChecked={port?.public}
                    {...register(`ports.${index}.public`)}
                  />
                </HStack>
                {effectiveExternalPort ? (
                  <>
                    <Text mt={4}>Available from these endpoints:</Text>
                    <Flex
                      border="1px solid var(--chakra-colors-chakra-border-color)"
                      borderRadius="md"
                      flexDir="row"
                    >
                      <Badge
                        colorScheme="gray"
                        margin={2}
                        borderRadius="md"
                        display="flex"
                        alignItems="center"
                        width="5rem"
                        justifyContent={"center"}
                      >
                        Internal
                      </Badge>
                      <CopyText
                        iconButtonProps={{
                          minW: "1rem",
                        }}
                        text={`${
                          privateFQDN || "pending-service-endpoint"
                        }:${effectiveExternalPort}`}
                      />
                    </Flex>
                    {port.loadBalancer &&
                      (loadBalancerAddresses?.length > 0
                        ? loadBalancerAddresses
                        : ["pending-load-balancer-endpoint"]
                      ).map((addr) => (
                        <Flex
                          key={addr}
                          border="1px solid var(--chakra-colors-chakra-border-color)"
                          borderRadius="md"
                          flexDir="row"
                        >
                          <Badge
                            colorScheme="orange"
                            margin={2}
                            borderRadius="md"
                            display="flex"
                            alignItems="center"
                            width="5rem"
                            justifyContent={"center"}
                          >
                            Public
                          </Badge>
                          <CopyText
                            iconButtonProps={{
                              minW: "1rem",
                            }}
                            text={`${addr}:${effectiveExternalPort}`}
                          />
                        </Flex>
                      ))}
                    {port.public &&
                      (nodeIps?.length > 0
                        ? nodeIps
                        : ["pending-node-endpoint"]
                      ).map((addr) => (
                        <Flex
                          key={addr}
                          border="1px solid var(--chakra-colors-chakra-border-color)"
                          borderRadius="md"
                          flexDir="row"
                        >
                          <Badge
                            colorScheme="orange"
                            margin={2}
                            borderRadius="md"
                            display="flex"
                            alignItems="center"
                            width="5rem"
                            justifyContent={"center"}
                          >
                            Public
                          </Badge>
                          <CopyText
                            iconButtonProps={{
                              minW: "1rem",
                            }}
                            text={`${addr}:${effectiveExternalPort}`}
                          />
                        </Flex>
                      ))}
                  </>
                ) : null}
                <Button
                  marginLeft={"auto"}
                  colorScheme="red"
                  variant="outline"
                  aria-label="delete"
                  leftIcon={<DeleteIcon />}
                  borderRadius="md"
                  onClick={() => removeItem(index)}
                >
                  Delete
                </Button>
              </Stack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <FormErrorMessage>{errorMsgs.join("\n")}</FormErrorMessage>
      </FormControl>
    </Box>
  );
};

export const NetworkingSettings: React.FC<{ repo: RepoDetailFragment }> = ({
  repo,
}) => {
  const toast = useToast();

  const [changed, setChanged] = useState(false);

  const {
    register,
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormType>({
    defaultValues: {
      ports: repo.ports?.filter((p) => !p.https) || [],
      httpsPort: repo.ports?.filter((p) => p.https)?.[0]?.port,
      isGrpc: repo.ports?.filter((p) => p.https)?.[0]?.grpc,
    },
    shouldUnregister: false,
  });

  const {
    fields: additionalPortsListFA,
    append: appendItem,
    remove: removeItem,
  } = useFieldArray({
    control,
    name: "ports",
  });

  const watchHttps = watch("httpsPort");
  const watchGrpc = watch("isGrpc");
  const watchPorts = watch("ports");

  const additionalPortsList = additionalPortsListFA.map((p, index) => ({
    ...p,
    ...watchPorts[index],
  }));

  const [updateSettings, { error, loading, data: updateProjectData }] =
    useUpdateProjectSettingsMutation({
      onCompleted: (data) => {
        setChanged(false);
        if (data) {
          toast({
            title: "Networking Settings Saved",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      },
    });

  const onSubmit = (values: FormType): void => {
    let out = values.ports || [];
    if (values.httpsPort) {
      out = out.concat({
        port: values.httpsPort,
        externalPort: "",
        protocol: "tcp",
        public: true,
        loadBalancer: false,
        https: true,
        grpc: values.isGrpc,
      });
    }
    updateSettings({
      variables: {
        input: {
          id: repo.id,
          ports: JSON.stringify(out),
        },
      },
    });
  };

  if (error) {
    console.error(error);
  }

  const errorMsg = !changed && error?.message;

  const privateFQDN = getPrivateEndpointFQDN(repo);

  return (
    <form
      onSubmit={(e) => {
        e.stopPropagation();
        e.preventDefault();
        handleSubmit(onSubmit)(e);
      }}
    >
      <Flex flexDirection="column">
        <Text fontWeight="bold" fontSize="1.2rem" mb={3}>
          Networking
          <Tooltip text="Which ports should your project be accessible on? If there are no public ports, your URL will 503. Non-public ports can be accessed by your other Zeet projects" />
        </Text>
        <FormControl
          isInvalid={
            (!!errorMsg && shouldDisplayError(error, updateProjectData)) ||
            !!errors.httpsPort?.message
          }
        >
          <Text
            fontWeight="bold"
            fontSize="1rem"
            display="flex"
            alignItems="center"
            my={2}
          >
            HTTPS Load Balancing
            <Tooltip text="Configure HTTPS load balancing for your project, your application will be automatically protected with SSL encryption and configured with a public https URL endpoint" />
            <Menu>
              <MenuButton
                size="xs"
                ml={2}
                as={IconButton}
                aria-label="Options"
                icon={<SettingsIcon />}
                variant="ghost"
              />
              <MenuList>
                <MenuItem
                  icon={
                    <GiCheckMark
                      color="var(--brand)"
                      opacity={watchGrpc ? 0 : 1}
                    />
                  }
                  onClick={() => {
                    setValue("isGrpc", false, { shouldDirty: true });
                  }}
                >
                  Use HTTP Protocol
                </MenuItem>
                <MenuItem
                  icon={
                    <GiCheckMark
                      color="var(--brand)"
                      opacity={watchGrpc ? 1 : 0}
                    />
                  }
                  onClick={() => {
                    setValue("isGrpc", true, { shouldDirty: true });
                  }}
                >
                  Use GRPC Protocol
                </MenuItem>
              </MenuList>
            </Menu>
          </Text>
          <Stack>
            <Flex>
              <ZFormLabel>
                Target {watchGrpc ? "GRPC" : "HTTP"} Port{" "}
              </ZFormLabel>
              <Input
                {...register("httpsPort", {
                  validate: portValidationFunction,
                })}
                placeholder="(e.g. 3000)"
                type="number"
              />
              <IconButton
                aria-label="delete"
                icon={<DeleteIcon />}
                borderRadius="md"
                onClick={() =>
                  setValue("httpsPort", undefined, { shouldDirty: true })
                }
                width={["100%", "100%", 1]}
                ml={3}
              />
            </Flex>
          </Stack>
          <FormErrorMessage>{errors.httpsPort?.message}</FormErrorMessage>
          {watchHttps && repo?.productionDeployment?.endpoints?.[0] && (
            <Text my={2}>
              Production Endpoint
              <CopyLink
                ml={2}
                variant="sm"
                to={makeLink(repo?.productionDeployment?.endpoints?.[0])}
                linkProps={{ isExternal: true }}
              />
            </Text>
          )}
          {isKube(repo) && (
            <>
              <Divider my={4} />
              <Text
                fontWeight="bold"
                fontSize="1rem"
                display="flex"
                alignItems="center"
                my={2}
              >
                Additional Ports
                <Tooltip text="Configure additional exposed TCP/UDP ports other than the HTTPS load balancer port" />
              </Text>
              <>
                {!!additionalPortsList.length && (
                  <Text my={2} display="flex">
                    <Box flexShrink="0">Internal Endpoint</Box>
                    {!!privateFQDN && (
                      <CopyText
                        ml={2}
                        variant="sm"
                        overflow="hidden"
                        title={privateFQDN}
                        text={privateFQDN}
                      >
                        {privateFQDN}
                      </CopyText>
                    )}
                  </Text>
                )}
              </>
              <Stack spacing={2}>
                {additionalPortsList.map((port, index) => (
                  <PortRow
                    key={port.id}
                    repo={repo}
                    port={port}
                    index={index}
                    register={register}
                    removeItem={removeItem}
                    errors={errors}
                  />
                ))}

                {repo?.ports?.filter((p) => p.loadBalancer)?.length && (
                  <Stack>
                    <Text fontWeight="bold">Cloud Load Balancer</Text>
                    <Flex display="inline-flex" alignItems="center">
                      <Text>DNS</Text>
                      <Code ml={2}>
                        {repo?.productionDeployment?.loadBalancers?.[0]
                          ?.ips?.[0]
                          ? repo?.productionDeployment?.loadBalancers?.[0]?.dns?.[0]?.replace(
                              /\.$/,
                              ""
                            )
                          : "Not Available Yet"}
                      </Code>
                    </Flex>
                    <Flex display="inline-flex" alignItems="center">
                      <Text>IP</Text>
                      <Code ml={2}>
                        {repo?.productionDeployment?.loadBalancers?.[0]
                          ?.ips?.[0]
                          ? repo?.productionDeployment?.loadBalancers?.[0]
                              ?.ips?.[0]
                          : "Not Available Yet"}
                      </Code>
                    </Flex>
                    <Flex display="inline-flex" alignItems="center">
                      <Text>Ports</Text>
                      <Code ml={2}>
                        {repo?.productionDeployment?.loadBalancers?.[0]?.ports?.join(
                          ", "
                        )}
                      </Code>
                    </Flex>
                  </Stack>
                )}
              </Stack>
            </>
          )}
          {shouldDisplayError(error, updateProjectData) && (
            <FormErrorMessage mt={4}>{errorMsg}</FormErrorMessage>
          )}
          <Flex mt={4} width="100%" justifyContent="space-between">
            {isKube(repo) && (
              <Button
                colorScheme="brand"
                onClick={() =>
                  appendItem({
                    port: "",
                    externalPort: "",
                    protocol: "tcp",
                    https: false,
                    public: false,
                    grpc: false,
                    loadBalancer: false,
                  })
                }
              >
                + Add Port
              </Button>
            )}
            <Button
              type="submit"
              colorScheme="brand"
              isLoading={loading}
              marginLeft={"auto"}
            >
              Save
            </Button>
          </Flex>
        </FormControl>
      </Flex>
    </form>
  );
};

const ClusterIssuerWrapper: React.FC<{
  repo: RepoDetailFragment;
  clusterId: string;
}> = ({ repo, clusterId }) => {
  const { data, loading } = useClusterCertIssuersQuery({
    variables: {
      userID: repo.owner.id,
      repoID: repo.id,
      clusterID: clusterId,
    },
  });

  if (loading) {
    return <Loading />;
  }

  if (data && (data.user?.cluster?.clusterIssuers?.length || 0) > 1) {
    return (
      <>
        <ClusterIssuerSettings repo={repo} data={data} />
        <Divider my={4} />
      </>
    );
  }

  return null;
};

const ClusterIssuerSettings: React.FC<{
  repo: RepoDetailFragment;
  data: ClusterCertIssuersQuery | undefined;
}> = ({ repo, data }) => {
  const toast = useToast();
  const [update, { error, loading, data: updateProjectData }] =
    useUpdateProjectSettingsMutation({
      onCompleted: (data) => {
        if (data) {
          toast({
            title: "Certificate Issuer Settings Saved",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      },
    });

  const { register, handleSubmit } = useForm({
    defaultValues: {
      clusterIssuer: data?.user?.repo?.clusterIssuerName,
    },
  });

  const onSubmit = (values) => {
    const { clusterIssuer } = values;
    update({
      variables: {
        input: {
          id: repo.id,
          clusterIssuerName: clusterIssuer,
        },
      },
    });
  };

  return (
    <form
      onSubmit={(e) => {
        e.stopPropagation();
        e.preventDefault();
        handleSubmit(onSubmit)(e);
      }}
    >
      <Stack spacing={4}>
        <Flex>
          <ZFormLabel>TLS Certificate Issuer</ZFormLabel>
          <FormSelect
            flex={5.25}
            {...register("clusterIssuer")}
            options={data?.user?.cluster?.clusterIssuers?.map((i) => {
              return { value: i, label: i };
            })}
          />

          <Button type="submit" isLoading={loading} colorScheme="brand" ml={4}>
            {"Save"}
          </Button>
        </Flex>

        <Text>Change the certificate authority used for TLS certificate.</Text>
        {shouldDisplayError(error, updateProjectData) && (
          <ZError error={error} />
        )}
      </Stack>
    </form>
  );
};

const HostNetworkSettings: React.FC<{ repo: RepoDetailFragment }> = ({
  repo,
}) => {
  const toast = useToast();

  const { handleSubmit, watch, register } = useForm({
    defaultValues: {
      hostNetwork: repo.hostNetwork,
    },
  });

  const watcher = watch();

  const [updateSettings, { error, loading, data: updateProjectData }] =
    useUpdateProjectSettingsMutation({
      onCompleted: (data) => {
        if (data) {
          toast({
            title: "Host Networking Settings Saved",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      },
    });

  const onSubmit = (values): void => {
    updateSettings({
      variables: {
        input: {
          id: repo.id,
          hostNetwork: values.hostNetwork || false,
        },
      },
    });
  };

  if (error) {
    console.error(error);
  }

  return (
    <form
      onSubmit={(e) => {
        e.stopPropagation();
        e.preventDefault();
        handleSubmit(onSubmit)(e);
      }}
    >
      <Stack spacing={4}>
        <Flex>
          <ZFormLabel flex={0}>
            Host Networking {watcher.hostNetwork ? "Enabled" : "Disabled"}
          </ZFormLabel>
          <Switch
            {...register("hostNetwork", {
              onChange: (e) => {
                handleSubmit(onSubmit)(e);
              },
            })}
            isDisabled={loading}
            size="lg"
          />
          {loading && <Spinner ml={4} />}
        </Flex>

        <Text>
          Host networking can be useful to optimize network performance, and in
          situations where an application needs to handle a large range of
          ports.
        </Text>
        {shouldDisplayError(error, updateProjectData) && (
          <ZError error={error} />
        )}
      </Stack>
    </form>
  );
};

export const AdvancedNetworkSettings: React.FC<{
  repo: RepoDetailFragment;
}> = ({ repo }) => {
  const teamUser = useCurrentTeamUser();

  const paywall = !teamUser?.isTeam && {
    pointerEvents: "none" as const,
    opacity: 0.5,
  };

  return (
    <Flex flexDirection="column">
      <Text fontWeight="bold" fontSize="1.2rem" mb={3}>
        Advanced Networking
      </Text>
      <Stack spacing={4} {...paywall}>
        {paywall && (
          <Flex alignItems="center">
            <Alert status="warning" mb={4} width="100%">
              <AlertIcon />
              <FaLock />
              <Text ml={2} display="inline-flex">
                Only available for
                <Link
                  to={`/${repo?.owner.login}/console/clusters/view`}
                  ml={1}
                  color="brandVar"
                >
                  Private Clusters
                </Link>
              </Text>
            </Alert>
          </Flex>
        )}

        {repo?.cluster?.id && (
          <>
            <ClusterIssuerWrapper repo={repo} clusterId={repo.cluster.id} />
          </>
        )}
        <StaticIpSettings repo={repo} />
        <Divider />
        <HostNetworkSettings repo={repo} />
      </Stack>
    </Flex>
  );
};

const StaticIpSettings: React.FC<{ repo: RepoDetailFragment }> = ({ repo }) => {
  const toast = useToast();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const currentTeamUser = useCurrentTeamUser();
  const { track } = useTrack();
  const { data } = useSubscriptionFeatureQuery({
    variables: {
      id: currentTeamUser.id,
      featureKey: FeatureKey.AdvancedAutoscaling,
    },
  });
  const [{ dataProductCatalog }] = useBillingOverviewContext();
  const hasStaticEgress = useFeatureFlag(Flag.StaticEgressAllowed);
  const isPriorityFeatureUser = useMemo(() => {
    return (
      data?.user.billingOverview?.subscriptionFeatures.byKey?.enabled ||
      hasStaticEgress ||
      false
    );
  }, [data, hasStaticEgress]);
  const { handleSubmit, watch, register } = useForm({
    defaultValues: {
      staticIP: repo.staticIP,
    },
  });

  const watcher = watch();

  const [updateSettings, { error, loading, data: updateProjectData }] =
    useUpdateProjectSettingsMutation({
      onCompleted: (data) => {
        if (data) {
          toast({
            title: "Static IP Settings Saved",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      },
    });

  const onSubmit = (values): void => {
    updateSettings({
      variables: {
        input: {
          id: repo.id,
          staticIP: values.staticIP || false,
        },
      },
    });
  };

  if (error) {
    console.error(error);
  }

  return (
    <form
      onSubmit={(e) => {
        e.stopPropagation();
        e.preventDefault();
        handleSubmit(onSubmit)(e);
      }}
    >
      <Stack spacing={4}>
        <Flex alignItems="center">
          <ZFormLabel flex={0}>
            Static Egress IP {watcher.staticIP ? "Enabled" : "Disabled"}
          </ZFormLabel>
          <>
            <CTooltip
              placement="top"
              label="Requires subscription upgrade"
              isDisabled={isPriorityFeatureUser}
            >
              <Flex alignItems="center">
                <Box
                  onClick={() => {
                    if (!isPriorityFeatureUser) {
                      track(AnalyticsEvent.UPGRADE_MODAL_DISPLAYED, {
                        upgrade_entity: BillingModalSource.STATIC_EGRESS,
                      });
                      onOpen();
                    }
                  }}
                >
                  <Switch
                    {...register("staticIP", {
                      onChange: (e) => {
                        handleSubmit(onSubmit)(e);
                      },
                    })}
                    isDisabled={loading}
                    size="lg"
                    mr={4}
                    isReadOnly={!isPriorityFeatureUser}
                    onClick={() => {
                      if (!isPriorityFeatureUser) {
                        track(AnalyticsEvent.UPGRADE_MODAL_DISPLAYED, {
                          upgrade_entity: BillingModalSource.STATIC_EGRESS,
                        });
                        onOpen();
                      }
                    }}
                  />
                </Box>
                {loading && <Spinner mr={4} />}
                {!isPriorityFeatureUser && <ImArrowUp fill="#00EF00" />}
              </Flex>
            </CTooltip>
            <UpgradeProductModal
              onClose={onClose}
              isOpen={isOpen}
              headerText={getHeaderText(dataProductCatalog, "Plan")}
              titleText={billingModalText.staticEgress.title}
              bodyText={billingModalText.staticEgress.body}
              shouldGoBack={false}
              primaryButtonLink={`/${currentTeamUser?.login}/account/billing/upgrade`}
              primaryButtonText="Compare Plans"
            />
          </>
        </Flex>
        {watcher.staticIP && (repo.cluster?.staticIPs?.length || 0) > 0 && (
          <Text>
            Egress IP Address:
            <Code ml={2}>{repo.cluster?.staticIPs?.[0]}</Code>
          </Text>
        )}
        <Text>
          Enabling Static Egress IP will launch the resource in private subnets
          behind a NAT gateway.{" "}
          <Link
            to={
              "https://docs.aws.amazon.com/vpc/latest/userguide/vpc-nat-gateway.html"
            }
          >
            Additional traffic charge from cloud provider applies.
          </Link>
        </Text>
        {shouldDisplayError(error, updateProjectData) && (
          <ZError error={error} />
        )}
      </Stack>
    </form>
  );
};

export const ApiGatewaySettings: React.FC<{ repo: RepoDetailFragment }> = ({
  repo,
}) => {
  return (
    <Flex flexDirection="column">
      <Text fontWeight="bold" fontSize="1.2rem" mb={3}>
        Cloud API Gateway
      </Text>

      <Flex>
        {repo?.productionDeployment?.awsLinks?.apiGateway ? (
          <Button
            size="md"
            as={Link}
            _hover={{ textDecoration: "none" }}
            to={repo?.productionDeployment?.awsLinks?.apiGateway}
            rightIcon={<ExternalLinkIcon />}
          >
            View in AWS Console
          </Button>
        ) : repo?.productionDeployment?.gcpLinks?.cloudFunctionsTrigger ? (
          <Button
            size="md"
            as={Link}
            _hover={{ textDecoration: "none" }}
            to={repo?.productionDeployment?.gcpLinks?.cloudFunctionsTrigger}
            rightIcon={<ExternalLinkIcon />}
          >
            View in GCP Console
          </Button>
        ) : (
          <Text>API Gateway Not Found</Text>
        )}
      </Flex>
    </Flex>
  );
};
