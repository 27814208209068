import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import {
  ProjectLinkType,
  ProjectWithNameFragment,
} from "@zeet/web-api/dist/graphqlv1";
import React from "react";
import { ProjectLinkContainer } from ".";

export const ProjectLinkModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  linkType: ProjectLinkType;
  availableProviders: ProjectWithNameFragment[];
}> = ({ isOpen, onClose, linkType, availableProviders }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      isCentered
      preserveScrollBarGap={true}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton data-testid="draft-cluster-modal-close-button" />
        <ModalHeader>Link Project</ModalHeader>
        <ModalBody>
          <ProjectLinkContainer
            linkType={linkType}
            availableProviders={availableProviders}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
