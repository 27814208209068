import { ProjectBasicFragment } from "@zeet/web-api/dist/graphql";
import React from "react";
import { APIIdentifiersSettings } from "../../APIIdentifiersSettings";

interface APIProps {
  project: ProjectBasicFragment;
}

export const API: React.FC<APIProps> = ({ project }) => {
  const identifiers = [
    { title: "User", value: project.owner.id },
    { title: "Project", value: project.id },
  ];

  return <APIIdentifiersSettings identifiers={identifiers} />;
};
