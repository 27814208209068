import { Box, Flex } from "@chakra-ui/react";
import { useColors } from "@zeet/web-ui";
import * as React from "react";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar, { SIDEBAR_WIDTH } from "./index";

const BodyWrapper: React.FC<
  React.PropsWithChildren & { showSidebar: boolean }
> = ({ showSidebar, children }) => {
  const { appContentBg, appContentBorder } = useColors();

  return (
    <>
      <Header showSidebar={showSidebar} />
      <Flex
        width="100%"
        minHeight="calc(100vh - 200px)"
        flexDirection="column"
        pr="0.5rem"
      >
        {showSidebar ? (
          <Flex width="100%" minHeight="inherit" pt="0">
            <Sidebar />
            <Box
              pl={["0.5rem", "0.5rem", SIDEBAR_WIDTH]}
              w="100%"
              overflow="hidden"
              minHeight="inherit"
            >
              <Box
                minHeight="calc(100vh - (58px + 1rem))"
                bg={appContentBg}
                borderRadius="0 0 0.75rem 0.75rem"
                border={`1px solid ${appContentBorder}`}
                borderTop="none"
                pb="10"
                overflow="hidden"
              >
                {children}
              </Box>
              <Footer />
            </Box>
          </Flex>
        ) : (
          <Box w="100%" overflow="hidden" minHeight="inherit" pt="0">
            {children}
            <Footer />
          </Box>
        )}
      </Flex>
    </>
  );
};

export default BodyWrapper;
