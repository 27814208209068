import { Box, ContainerProps } from "@chakra-ui/react";
import { useColors } from "@zeet/web-ui";

export const SectionContainer: React.FC<ContainerProps> = ({
  children,
  ...restProps
}) => {
  const { bg2 } = useColors();

  return (
    <Box
      border="1px solid var(--chakra-colors-chakra-border-color)"
      borderRadius="lg"
      backgroundColor={bg2}
      {...restProps}
    >
      {children}
    </Box>
  );
};
