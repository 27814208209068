import { IDataDispatch, IDataRow } from "@zeet/web-ui";
import React from "react";
import { Column } from "react-table";
import { SetViewModeType } from "./ListViewProvider";

interface ListViewContextState {
  columns: Column<IDataRow>[];
  data: IDataRow[];
  skipReset: boolean;
  ready: boolean;
}

export interface ListViewContextData {
  columns: Column<IDataRow>[];
  dispatch: React.Dispatch<IDataDispatch>;
  filterValue: string;
  handleFilterChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  propertyState: Record<string, boolean>;
  state: ListViewContextState;
  showAllColumns: () => void;
  toggleMenuItem: (id: string) => void;
  viewMode: string;
  setViewMode: SetViewModeType;
  loading?: boolean;
  onInfiniteScrollRequestMoreData?: (() => void) | null;
  keyboardNavigable?: boolean;
  itemSelected?: (item: IDataRow) => void;
}

export const ListViewContext = React.createContext<ListViewContextData | null>(
  null
);
