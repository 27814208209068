import { Avatar, Flex } from "@chakra-ui/react";
import { Blueprint } from "@zeet/web-api/dist/graphql";
import { useCurrentTeamUser } from "@zeet/web-ui";
import { FC } from "react";
import { FaCube } from "react-icons/fa";
import { LinkCell } from "./LinkCell";

export const BlueprintNameCell: FC<{ blueprint?: Blueprint }> = ({
  blueprint,
}) => {
  const currentTeamUser = useCurrentTeamUser();

  return (
    <LinkCell
      to={`/${currentTeamUser.login}/console/blueprints/${blueprint?.id}`}
    >
      <Flex alignItems="center">
        <Avatar
          boxSize={6}
          src={blueprint?.logoUrl ?? ""}
          icon={<FaCube size={14} />}
          bg={!blueprint?.logoUrl ? "gray.400" : undefined}
          borderRadius="md"
          mr={2}
        />
        {blueprint?.displayName}
      </Flex>
    </LinkCell>
  );
};
