import { useProjectCountQuery } from "@zeet/web-api/dist/graphql";
import { Badge, Skeleton, useCurrentTeamUser } from "@zeet/web-ui";
import { useParams } from "react-router-dom";
import { makeProjectFilterCriterion } from "./Dashboard/ProjectV3Utils";

export const ProjectCountBadge = () => {
  const currentTeamUser = useCurrentTeamUser();

  const { projectName: groupName, envName: subgroupName } = useParams<{
    projectName: string;
    envName: string;
  }>();

  const { data: projectCountData, loading: projectCountLoading } =
    useProjectCountQuery({
      variables: {
        userId: currentTeamUser?.id as string,
        filterInput: {
          filter: makeProjectFilterCriterion(groupName, subgroupName),
        },
      },
    });

  if (projectCountLoading) {
    return <Skeleton width="32px" height="18px" rounded="lg" />;
  }

  return (
    <Badge rounded="lg" textAlign="center" width="32px">
      {projectCountData?.user.projectV3Adapters?.totalCount ?? 0}
    </Badge>
  );
};
