import {
  DeploymentDetailFragment,
  useDeploymentDeployLogsQuery,
  useDeploymentReleaseLogsQuery,
} from "@zeet/web-api/dist/graphql";
import { SearchableProps, ZWarn } from "@zeet/web-ui";
import React from "react";
import { LogsConsole } from "~/components/LogsConsole/LogsConsole";

export const DeploymentReleaseLogs: React.FC<
  {
    deployment: DeploymentDetailFragment;
  } & SearchableProps
> = ({ deployment, searching, setSearching }) => {
  const { data, error } = useDeploymentReleaseLogsQuery({
    variables: {
      id: deployment.id,
    },
    pollInterval: 2000,
  });

  if (error) {
    console.error(error);
  }

  return (
    <>
      {data?.currentUser.deployment?.releaseStage?.logs?.expired && (
        <ZWarn
          borderRadius={0}
          error="These logs have expired. Logs are only available until a container is destroyed."
        />
      )}
      <LogsConsole
        lines={data?.currentUser.deployment?.releaseStage?.logs?.entries ?? []}
        isLive={!data?.currentUser.deployment?.releaseStage?.logs?.completed}
        searching={searching}
        setSearching={setSearching}
      />
    </>
  );
};

export const DeploymentDeployLogs: React.FC<
  {
    deployment: DeploymentDetailFragment;
  } & SearchableProps
> = ({ deployment, searching, setSearching }) => {
  const { data, error } = useDeploymentDeployLogsQuery({
    variables: {
      id: deployment.id,
    },
    pollInterval: 2000,
  });

  if (error) {
    console.error(error);
  }

  return (
    <>
      {data?.currentUser.deployment?.deployStage?.logs?.expired && (
        <ZWarn
          borderRadius={0}
          error="These logs have expired. Logs are only available until a container is destroyed."
        />
      )}
      <LogsConsole
        isLive={!data?.currentUser.deployment?.deployStage?.logs?.completed}
        lines={data?.currentUser.deployment?.deployStage?.logs?.entries ?? []}
        searching={searching}
        setSearching={setSearching}
      />
    </>
  );
};
