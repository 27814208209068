import {
  DeploymentStatus,
  ProjectDeployDetailFragment,
} from "@zeet/web-api/dist/graphqlv1";
import { DataTypes, IDataRow, IZeetData, theme } from "@zeet/web-ui";
import { Column } from "react-table";

type ColumnsType = Column<IDataRow>[];

enum ColumnIds {
  DEPLOY_DISPLAY_NAME = "projectDeployDisplayName",
  DEPLOYMENT_CONFIG = "projectDeployConfig",
  DEPLOYMENT_STATUS = "projectDeployStatus",
  DEPLOYMENT_ACTIONS = "projectDeploymentActions",
  LAST_DEPLOYMENT_AT = "projectLastDeploymentAt",
}

const columns: ColumnsType = [
  {
    id: ColumnIds.DEPLOY_DISPLAY_NAME,
    label: "Name",
    minWidth: theme.defaultFirstColumnWidth,
    dataType: DataTypes.LINK,
    options: [],
    order: 1,
  },
  {
    id: ColumnIds.DEPLOYMENT_STATUS,
    label: "Status",
    minWidth: 150,
    dataType: DataTypes.PROJECT_DEPLOYMENT_STATUS,
    options: [],
    order: 2,
  },
  {
    id: ColumnIds.DEPLOYMENT_CONFIG,
    label: "Config",
    minWidth: 150,
    dataType: DataTypes.TEXT,
    options: [],
    order: 3,
  },
  {
    id: ColumnIds.LAST_DEPLOYMENT_AT,
    label: "Created",
    minWidth: 350,
    dataType: DataTypes.TIMEAGO,
    options: [],
    order: 4,
  },
  {
    id: ColumnIds.DEPLOYMENT_ACTIONS,
    label: "Actions",
    minWidth: 150,
    dataType: DataTypes.PROJECT_DEPLOYMENT_ACTIONS,
    options: [],
    order: 5,
  },
];

const defaultColumnIds = Object.values(ColumnIds) as ColumnIds[];

export function formatDataRow(
  path: string,
  projectDeploy?: ProjectDeployDetailFragment | null
): IZeetData {
  const formattedData: IDataRow[] =
    projectDeploy?.runs?.nodes?.map((deployRun) => {
      return {
        [ColumnIds.DEPLOY_DISPLAY_NAME]: {
          text: `Deployment #${deployRun.sequence}`,
          link: `${path}?deployRunId=${deployRun?.id}`,
        },
        [ColumnIds.DEPLOYMENT_CONFIG]: {
          text: "nothing",
        },
        [ColumnIds.DEPLOYMENT_STATUS]: {
          text: String(DeploymentStatus.Unknown),
          status: DeploymentStatus.Unknown,
        },
        [ColumnIds.LAST_DEPLOYMENT_AT]: {
          // date: deployRun.result?.createdAt, TODO: fix this, we need a date from the BE
          date: new Date(),
        },
        [ColumnIds.DEPLOYMENT_ACTIONS]: {
          deployRun,
        },
      };
    }) ?? [];

  return {
    columns,
    data: formattedData,
    skipReset: false,
  };
}

export { columns, defaultColumnIds };
