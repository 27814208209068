import { BuildRunListItemFragment } from "@zeet/web-api/dist/graphqlv1";
import { BuildColumnId } from "./listview";

export const formatBuildRun = (path: string, run: BuildRunListItemFragment) => {
  return {
    [BuildColumnId.Name]: {
      text: `Run #${run.sequence}`,
      link: `${path}?runId=${run.id}`,
    },
    [BuildColumnId.Status]: {
      status: run.jobRun?.state,
    },
    [BuildColumnId.CreatedAt]: {
      date: run.jobRun?.createdAt,
    },
    [BuildColumnId.TriggeredBy]: {
      text: run.triggeredBy
        ? `Workflow Run #${run.triggeredBy?.sequence}`
        : "Manually",
      link: "#",
    },
  };
};
