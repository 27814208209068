import { FC } from "react";
import CloudIcon, {
  CloudIconProps,
} from "../../Console/ConsoleClouds/View/CloudIcon";
import { LinkCell, LinkCellProps } from "./LinkCell";

interface CloudProviderCellProps {
  cloudAccountKey: CloudIconProps["cloudAccountKey"];
  cloudProvider: CloudIconProps["cloudProvider"];
  to: LinkCellProps["to"];
}

export const CloudProviderCell: FC<CloudProviderCellProps> = ({
  cloudAccountKey,
  cloudProvider,
  to,
}) => (
  <LinkCell to={to}>
    <CloudIcon
      cloudAccountKey={cloudAccountKey}
      cloudProvider={cloudProvider}
    />
  </LinkCell>
);
