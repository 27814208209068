import {
  FormControl,
  FormErrorMessage,
  HStack,
  Select,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import {
  BitbucketIntegration,
  IntegrationType,
  useUserIntegrationsQuery,
} from "@zeet/web-api/dist/graphql"; // adjust import based on actual path
import {
  Button,
  FormLabel,
  Input,
  Skeleton,
  useCurrentTeamUser,
} from "@zeet/web-ui";
import React, { useEffect, useMemo, useState } from "react";
import { HashLink } from "react-router-hash-link";
import { getIntegrationName } from "~/components/Account/Integration/Bitbucket";
import { Source } from "~/components/SourceManager";

const parseBitbucketUrl = (url: string): string => {
  const regex = /bitbucket.org\/(.*)/g;
  const found = url.match(regex);
  return found?.[0].split("bitbucket.org/")[1] || "";
};

export const BitbucketRepositorySelect: React.FC<{
  onSelect: (integrationId: string, repository: string) => void;
  source?: Source;
}> = ({ onSelect, source }) => {
  const currentTeamUser = useCurrentTeamUser();
  const [repositoryUrl, setRepositoryUrl] = useState(
    source?.git?.repository || ""
  );
  const [selectedIntegrationId, setSelectedIntegrationId] = useState(
    source?.git?.integration?.bitbucketIntegrationId || ""
  );

  // Fetch integrations
  const { data, loading } = useUserIntegrationsQuery({
    variables: {
      id: currentTeamUser?.id,
    },
  });

  const bitbucketIntegrations = useMemo(
    () =>
      data?.user?.integrations?.filter(
        (integration) => integration.type === IntegrationType.Bitbucket
      ) as BitbucketIntegration[],
    [data]
  );

  useEffect(() => {
    setSelectedIntegrationId(bitbucketIntegrations?.[0]?.id || "");
  }, [bitbucketIntegrations]);

  const renderIntegrations = () => {
    if (!data || !bitbucketIntegrations) {
      return <option>Error loading integrations</option>;
    }
    return bitbucketIntegrations.map((integration) => (
      <option key={integration.id} value={integration.id}>
        {getIntegrationName(integration)}
      </option>
    ));
  };

  useEffect(() => {
    if (selectedIntegrationId && repositoryUrl) {
      onSelect(selectedIntegrationId, repositoryUrl);
    }
  }, [selectedIntegrationId, repositoryUrl, onSelect]);

  if (loading)
    return (
      <Stack gap="2" mt="4">
        <Skeleton height="48px" borderRadius="md" />
        <Skeleton height="48px" borderRadius="md" />
      </Stack>
    );

  return (
    <VStack>
      {bitbucketIntegrations?.length === 0 ? (
        <>
          <Text>No Bitbucket integrations found</Text>
          <Button
            to="/account/integrations#bitbucket-integration-section"
            as={HashLink}
          >
            Add Bitbucket Integration
          </Button>
        </>
      ) : (
        <>
          <FormControl mt="4" isRequired>
            <FormLabel>Bitbucket Integration</FormLabel>
            <HStack>
              <Select
                value={selectedIntegrationId}
                onChange={(e) => setSelectedIntegrationId(e.target.value)}
              >
                {renderIntegrations()}
              </Select>
              <Button
                to="/account/integrations#bitbucket-integration-section"
                as={HashLink}
                variant="secondary"
              >
                Manage Integrations
              </Button>
            </HStack>
          </FormControl>

          <FormControl
            mt="4"
            isRequired
            isInvalid={!!repositoryUrl && !parseBitbucketUrl(repositoryUrl)}
          >
            <FormLabel>URL to Bitbucket repository</FormLabel>
            <Input
              autoFocus
              placeholder="https://bitbucket.org/zeet-demo/node-express-demo.git"
              onChange={(e) => setRepositoryUrl(e.target.value)}
              value={repositoryUrl}
            />
            <FormErrorMessage>
              {parseBitbucketUrl(repositoryUrl)
                ? ""
                : "Invalid Bitbucket repository"}
            </FormErrorMessage>
          </FormControl>
        </>
      )}
    </VStack>
  );
};
