import {
  BlueprintVariableInput,
  BlueprintVariableType,
  DeployConfigurationDetailFragment,
  DeployVariableDetailFragment,
  UpdateDeployInput,
} from "@zeet/web-api/dist/graphqlv1";
import { BridgeBlueprintInput } from "@zeet/web-ui";
import isUndefined from "lodash/isUndefined";

export const typeValidations = {
  [BlueprintVariableType.String]: (value: any) => {
    if (typeof value === "string") {
      return true;
    } else {
      return "invalid string";
    }
  },
  [BlueprintVariableType.Boolean]: (value: any) => {
    const lowerCaseValue = value.toLowerCase();

    if (lowerCaseValue === "true" || lowerCaseValue === "false") {
      return true;
    } else {
      return "invalid boolean";
    }
  },
  [BlueprintVariableType.Float]: (value: any) => {
    const output = Number(value);
    if (isNaN(output)) {
      return "invalid float";
    }

    return true;
  },
  [BlueprintVariableType.Integer]: (value: any) => {
    if (Number(value) === parseInt(value)) {
      return true;
    }
    return "invalid integer";
  },
  [BlueprintVariableType.Json]: (value: any) => {
    try {
      JSON.parse(value);
    } catch (e) {
      return "invalid JSON";
    }
    return true;
  },
};

const getVariableType = (
  variable: DeployVariableDetailFragment
): BlueprintVariableType | null => {
  if (variable.valueBoolean === false || variable.valueBoolean === true) {
    return BlueprintVariableType.Boolean;
  }
  if (variable.valueFloat) return BlueprintVariableType.Float;
  if (variable.valueInt === 0 || variable.valueInt) {
    return BlueprintVariableType.Integer;
  }
  if (variable.valueJson) return BlueprintVariableType.Json;
  if (variable.valueString) return BlueprintVariableType.String;

  return null;
};

const getVariableValue = (
  variable: DeployVariableDetailFragment
): BlueprintVariableType | null => {
  return (
    variable.valueString ??
    variable.valueFloat?.toString() ??
    variable.valueBoolean?.toString() ??
    variable.valueInt?.toString() ??
    variable.valueJson
  );
};

export const convertInputStringsToActualType = (
  variables: BlueprintVariableInput[]
) => {
  variables.map((v) => {
    if (v.type === BlueprintVariableType.Float) {
      const output = parseFloat(v.value);
      if (isNaN(output)) {
        return "invalid float";
      }
      v.value = output;
    }

    if (v.type === BlueprintVariableType.Integer) {
      const output = parseInt(v.value);
      if (isNaN(output)) {
        return "invalid integer";
      }
      v.value = output;
    }
  });
};

export const convertToDeployInput = (
  deploy: DeployConfigurationDetailFragment
): UpdateDeployInput | undefined => {
  if (!deploy.configuration) return;

  const data = {
    configuration: {
      variables: deploy.configuration.variables
        .map((v) => ({
          value: getVariableValue(v),
          name: v.name,
          type: getVariableType(v),
          specId: v.specId,
        }))
        .sort((v1) => (v1.specId ? -1 : 1)),
      defaultWorkflowSteps: deploy.configuration.defaultWorkflowSteps,
      requirePlanApproval: deploy.configuration.requirePlanApproval,
      awsSam: deploy.configuration?.awsSam && {
        target: {
          awsAccountId: deploy.configuration.awsSam.target?.awsAccountId,
          awsRegion: deploy.configuration.awsSam.target?.awsRegion,
          stackName: deploy.configuration.awsSam.target?.stackName,
        },
        generator: {
          runCommand: deploy.configuration.awsSam.generator?.runCommand,
          httpPort: deploy.configuration.awsSam.generator?.httpPort,
          serverlessMemory:
            deploy.configuration.awsSam.generator?.serverlessMemory,
        },
      },
      gcpCloudRun: deploy.configuration?.gcpCloudRun && {
        target: {
          gcpAccountId: deploy.configuration.gcpCloudRun?.target?.gcpAccountId,
          gcpRegion: deploy.configuration.gcpCloudRun.target?.gcpRegion,
        },
      },
      kubernetes: deploy.configuration?.kubernetes && {
        blueprint: {
          ...deploy.configuration.kubernetes.blueprint,
        },
        target: {
          clusterId: deploy.configuration.kubernetes.target?.clusterId ?? "",
          namespace: deploy.configuration.kubernetes.target?.namespace,
        },
      },
      helm: makeHelmConfiguration(deploy.configuration),
      terraform: deploy.configuration?.terraform && {
        blueprint: {
          ...deploy.configuration?.terraform?.blueprint,
        },
        target: {
          ...deploy.configuration?.terraform?.target,
          stateBackend: {
            ...deploy.configuration?.terraform.target?.stateBackend,
          },
          provider: {
            awsAccountId:
              deploy.configuration?.terraform?.target?.provider?.awsAccount?.id,
            doAccountId:
              deploy.configuration?.terraform?.target?.provider?.doAccount?.id,
            gcpAccountId:
              deploy.configuration?.terraform?.target?.provider?.gcpAccount?.id,
            linodeAccountId:
              deploy.configuration?.terraform?.target?.provider?.linodeAccount
                ?.id,
            region: deploy.configuration?.terraform?.target?.provider?.region,
          },
        },
      },
    },
  };
  return data;
};

const makeHelmConfiguration = (
  config: DeployConfigurationDetailFragment["configuration"]
) => {
  if (!config?.helm) return null;
  return {
    blueprint: {
      ...config.helm.blueprint,
    },
    target: {
      clusterId: config?.helm.target?.clusterId ?? "",
      releaseName: config?.helm.target?.releaseName,
      namespace: config?.helm.target?.namespace,
    },
    values: config.helm.values ?? "# paste your custom values.yaml",
  };
};

export const makeProjectRichInputs = (
  richInputs: BridgeBlueprintInput[],
  variables: BlueprintVariableInput[]
) => {
  const reorderedRichInput = variables
    .map((v) => richInputs.find((r) => v.name === r.id))
    .filter((r) => !isUndefined(r)) as BridgeBlueprintInput[];
  const richInputPrefilled = reorderedRichInput.map((richInput) => ({
    ...richInput,
    default: variables.find((v) => v.name === richInput.id)?.value,
  }));

  const filteredVariables = variables.filter(
    (v) => !richInputPrefilled.some((input) => input.id === v.name)
  );

  return {
    richInputPrefilled,
    filteredVariables,
  };
};
