import { Monaco, useMonaco } from "@monaco-editor/react";
import {
  BridgeBlueprintInput,
  BridgeBlueprintInputType,
  EditorInput,
} from "@zeet/web-ui";
import yaml from "js-yaml";
import { Controller, Path, useFormContext } from "react-hook-form";
import { NewResourceValues } from "../../context";
import { makeFieldOptions, makeRegisterOptions } from "./utils";

interface BytesInputProps<T> {
  input: BridgeBlueprintInput;
  pathMap: (value: any) => Path<T>;
}

const BytesInput: React.FC<BytesInputProps<NewResourceValues>> = ({
  input,
  pathMap,
}) => {
  const monaco: Monaco | null = useMonaco();
  const { control, trigger } = useFormContext<NewResourceValues>();

  if (input.type === BridgeBlueprintInputType.Json) {
    monaco?.languages?.json?.jsonDefaults?.setDiagnosticsOptions({
      validate: true,
    });
  }

  if (input.type === BridgeBlueprintInputType.Yaml) {
    monaco?.languages.yaml?.yamlDefaults?.setDiagnosticsOptions({
      validate: true,
    });
  }

  const defaultLanguage = {
    [BridgeBlueprintInputType.Json]: "json",
    [BridgeBlueprintInputType.Yaml]: "yaml",
  };

  const parse = {
    [BridgeBlueprintInputType.Json]: JSON.parse,
    [BridgeBlueprintInputType.Yaml]: yaml.load,
  };

  const validateLanguage = (value: unknown) => {
    try {
      if (typeof value !== "string") throw Error();
      parse[input.type](value);
      return true;
    } catch (e) {
      return "Invalid values";
    }
  };

  return (
    <Controller
      name={pathMap(input.id)}
      control={control}
      defaultValue={input.default as string}
      rules={{
        ...makeRegisterOptions(input),
        validate: (value) => validateLanguage(value),
      }}
      render={(props) => (
        <EditorInput
          defaultLanguage={defaultLanguage[input.type]}
          {...makeFieldOptions({ ...props, trigger })}
          onMount={(editor) => {
            editor.onDidBlurEditorText(() => trigger(`variables.${input.id}`));
          }}
        />
      )}
    />
  );
};

export default BytesInput;
