import {
  TeamGithubReposQuery,
  UserGithubReposQuery,
  useTeamGithubReposQuery,
  useUserGithubReposQuery,
} from "@zeet/web-api/dist/graphql";
import {
  OpenPopupFn,
  useCurrentTeamUser,
  useIsLoggedIn,
  usePopup,
} from "@zeet/web-ui";
import invariant from "invariant";
import React, { createContext, useContext, useEffect } from "react";

interface GithubContext {
  data?: UserGithubReposQuery;
  loading: boolean;
  teamData?: TeamGithubReposQuery;
  teamLoading: boolean;
  openPopup: OpenPopupFn;
  _notInScope: boolean;
}

const githubContext = createContext<GithubContext>({
  data: undefined,
  loading: true,
  teamData: undefined,
  teamLoading: true,
  openPopup: () => null,
  _notInScope: true,
});

export const GithubContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const isLoggedIn = useIsLoggedIn();
  const { updated, openPopup } = usePopup();
  const currentTeamUser = useCurrentTeamUser();
  const { data, loading, refetch } = useUserGithubReposQuery({
    fetchPolicy: "cache-and-network",
    skip: !isLoggedIn,
  });

  const {
    data: teamData,
    loading: teamLoading,
    refetch: teamRefetch,
  } = useTeamGithubReposQuery({
    variables: {
      path: currentTeamUser.login,
    },
    fetchPolicy: "cache-and-network",
    skip: !isLoggedIn,
  });

  useEffect(() => {
    if (isLoggedIn) {
      refetch();
      teamRefetch();
    }
  }, [updated, refetch, teamRefetch, isLoggedIn]);

  return (
    <>
      <githubContext.Provider
        value={{
          openPopup,
          data,
          loading,
          teamData,
          teamLoading,
          _notInScope: false,
        }}
      >
        {children}
      </githubContext.Provider>
    </>
  );
};

export function useGithubContext(): Omit<GithubContext, "_notInScope"> {
  const { data, loading, openPopup, teamData, teamLoading, _notInScope } =
    useContext(githubContext);
  invariant(
    !_notInScope,
    "You can't access the GithubContext from outside the GithubContext Provider"
  );
  return { data, loading, teamData, teamLoading, openPopup };
}
