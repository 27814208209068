import { Flex, Heading, Switch, Text, useToast } from "@chakra-ui/react";
import {
  useUpdateWorkflowMutation,
  WorkflowDetailFragment,
  WorkflowStepActionType,
} from "@zeet/web-api/dist/graphqlv1";
import { Card } from "@zeet/web-ui";
import { Controller, useForm } from "react-hook-form";

interface WorkflowStepsProps {
  workflow: WorkflowDetailFragment;
}

const WorkflowSteps = ({ workflow }: WorkflowStepsProps) => {
  const toast = useToast();
  const { watch, getValues, control } = useForm({
    defaultValues: {
      steps: workflow?.steps,
    },
  });

  const [updateWorkflow] = useUpdateWorkflowMutation();

  const stepDisplayText = {
    [WorkflowStepActionType.OrchestrationBuild]: "Build step",
    [WorkflowStepActionType.OrchestrationDeploy]: "Deploy step",
    [WorkflowStepActionType.OrchestrationRelease]: "Release step",
    [WorkflowStepActionType.OrchestrationDestroy]: "Destroy step",
  };

  const update = () => {
    updateWorkflow({
      variables: {
        id: workflow.id,
        input: {
          definition: {
            steps: getValues("steps"),
          },
        },
      },
      onCompleted: (data) => {
        if (data) {
          toast({
            title: "Workflow updated",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      },
    });
  };

  return (
    <Card p="4" gap="4" display="flex" flexDir="column">
      <Heading size="sm">Workflow steps</Heading>
      {workflow?.steps?.map((step, index) => (
        <Card
          key={index}
          p="4"
          justifyContent="space-between"
          alignItems="center"
          display="flex"
        >
          <Heading size="sm">{stepDisplayText[step.action]}</Heading>
          <Flex gap="1" alignItems="center">
            <Text opacity="0.5">
              {watch(`steps.${index}.disabled`) ? "Disabled" : "Enabled"}
            </Text>
            <Controller
              name={`steps.${index}.disabled`}
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Switch
                  onBlur={onBlur}
                  onChange={(e) => {
                    onChange(!e.target.checked);
                    update();
                  }}
                  isChecked={!value}
                />
              )}
            />
          </Flex>
        </Card>
      ))}
    </Card>
  );
};

export default WorkflowSteps;
