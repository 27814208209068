import { Divider, Flex } from "@chakra-ui/react";
import {
  ClusterDetailV1Fragment,
  ObservabilityResourceSelector,
  ResourceType,
  useResourcesWithMetricsQuery,
} from "@zeet/web-api/dist/graphqlv1";
import {
  Button,
  CenterLoading,
  DetailItem,
  DetailLabel,
  DetailSidebar,
  SidebarLayout,
  useCurrentTeamUser,
} from "@zeet/web-ui";
import { useMemo, useState } from "react";
import { ResourceClusterInfo } from "./ResourceClusterInfo";
import { ResourceControlledByInfo } from "./ResourceControlledByInfo";
import { ResourceDetailLogs } from "./ResourceDetailLogs";
import { ResourceDetailMetrics } from "./ResourceDetailMetrics";
import { ResourceEventsInfo } from "./ResourceEventsInfo";
import { ResourceLatestDeploymentInfo } from "./ResourceLatestDeploymentInfo";
import { ResourceStatusInfo } from "./ResourceStatusInfo";
import { metricsTypesToFetch } from "./utils";

interface ResourceDetailContentProps {
  projectId: string;
  selector: ObservabilityResourceSelector;
  cluster: ClusterDetailV1Fragment;
  showTerminalButton?: boolean;
}

export const ResourceDetailContent: React.FC<ResourceDetailContentProps> = ({
  projectId,
  selector,
  cluster,
  showTerminalButton,
}) => {
  const [loadingState, setLoadingState] = useState(false);
  const { id: teamId } = useCurrentTeamUser();
  const {
    data: resourceData,
    loading,
    refetch: refetchResource,
  } = useResourcesWithMetricsQuery({
    variables: {
      projectId,
      teamId,
      clusterId: cluster.id,
      metricTypes: metricsTypesToFetch,
      selector: {
        ...selector,
        type: ResourceType.Container,
      },
    },
    pollInterval: 30000,
    fetchPolicy: "no-cache",
  });
  const handleRefreshClick = async () => {
    setLoadingState(true);
    await refetchResource();
    setLoadingState(false);
  };

  const resource = resourceData?.team?.project?.resources?.[0];
  const hasMetrics = useMemo(() => {
    if (!resource?.metric?.length) {
      return false;
    }

    return resource?.metric?.some((metric) => {
      return !!metric.entries?.length;
    });
  }, [resource]);

  return (
    <SidebarLayout pr="0" templateColumns="1fr 300px">
      <Flex direction="column">
        {loading ? (
          <CenterLoading />
        ) : hasMetrics ? (
          <ResourceDetailMetrics metrics={resource?.metric ?? []} />
        ) : (
          <Flex
            p={4}
            py="8"
            opacity="0.5"
            justifyContent="center"
            minHeight="100px"
          >
            No container metrics found.
          </Flex>
        )}
        <ResourceDetailLogs
          projectId={projectId}
          selector={selector}
          cluster={cluster}
        />
      </Flex>
      <DetailSidebar>
        <DetailItem>
          {showTerminalButton ? (
            <Button variant="primary" mr="3">
              Terminal
            </Button>
          ) : null}
          <Button
            isLoading={loadingState}
            variant="secondary"
            onClick={handleRefreshClick}
          >
            Refresh
          </Button>
        </DetailItem>
        <Divider />
        <ResourceStatusInfo cloudResource={resource} />
        <ResourceControlledByInfo cloudResource={resource} />
        <DetailItem>
          <DetailLabel>Cluster</DetailLabel>
          <ResourceClusterInfo cluster={cluster} showLink />
        </DetailItem>
        <ResourceLatestDeploymentInfo projectId={projectId} />
        <ResourceEventsInfo events={resource?.events ?? []} />
      </DetailSidebar>
    </SidebarLayout>
  );
};
