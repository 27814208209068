import { DeleteIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  IconButton,
  Input,
  Stack,
  useToast,
} from "@chakra-ui/react";
import {
  RepoDetailFragment,
  useUpdateProjectSettingsMutation,
} from "@zeet/web-api/dist/graphql";
import { AnalyticsEvent, useTrack } from "@zeet/web-ui";
import React, { useMemo, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { FormCard } from "../../FormCard";
import "./Settings.scss";

type Tag = {
  tag: string;
};

type FormType = {
  tags: Tag[];
};

export const AlternativeTags: React.FC<{ repo: RepoDetailFragment }> = ({
  repo,
}) => {
  const { track } = useTrack();
  const [changed, setChanged] = useState(false);
  const formattedTags = useMemo(() => {
    const out: Tag[] = [];
    repo.alternativeImageTagTemplates?.map((t) => {
      out.push({
        tag: t,
      });
    });
    return out;
  }, [repo]);

  const { register, control, setValue } = useForm<FormType>({
    defaultValues: {
      tags: formattedTags || [],
    },
    shouldUnregister: false,
  });

  const {
    fields: alternativeTagsList,
    append: appendTag,
    remove: removeTag,
  } = useFieldArray({
    control,
    name: "tags",
  });

  const toast = useToast();

  const [updateSettings, { loading }] = useUpdateProjectSettingsMutation({
    onCompleted: (data) => {
      if (data) {
        toast({
          title: "Alternative Tag Template Saved",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    },
    onError: () => {
      toast({
        title: "Alternative Tag Template Failed to Save",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  return (
    <FormCard
      isDisabled={!changed}
      title="Alternative Tag Templates"
      description={`Customizable template for tags to be applied to each build image`}
      isLoading={loading}
      onSubmit={(e) => {
        e.stopPropagation();
        e.preventDefault();
        track(AnalyticsEvent.SET_ALTERNATIVE_TAG_TEMPLATE);
        const out: string[] = [];
        alternativeTagsList.map((t) => {
          out.push(t.tag);
        });

        updateSettings({
          variables: {
            input: {
              id: repo.id,
              alternativeImageTagTemplates: out,
            },
          },
        });
      }}
    >
      <Stack spacing={3} mt="24px">
        {alternativeTagsList?.map((t, i) => {
          return (
            <Stack isInline spacing={2} key={i}>
              <Input
                placeholder="TAG NAME"
                value={t.tag}
                {...register(`tags.${i}.tag`)}
                onChange={(e) => {
                  const alternativeTag = alternativeTagsList[i];
                  if (!alternativeTag) {
                    return;
                  }

                  alternativeTag.tag = e.target.value;
                  setValue("tags", alternativeTagsList);
                  setChanged(true);
                }}
              />
              <IconButton
                aria-label="delete"
                icon={<DeleteIcon />}
                borderRadius="md"
                onClick={() => {
                  setChanged(true);
                  removeTag(i);
                }}
              />
            </Stack>
          );
        })}
        <Flex justifyContent="space-between" mt={2}>
          <Button
            size="sm"
            colorScheme="brand"
            onClick={(_) => {
              appendTag({
                tag: "",
              });
            }}
          >
            + Add More
          </Button>
        </Flex>
      </Stack>
    </FormCard>
  );
};
