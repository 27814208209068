import { JobRunListItemFragment } from "@zeet/web-api/dist/graphqlv1";
import { JobRunColumnId } from "./listview";

export const formatJobRun = (path: string, run: JobRunListItemFragment) => {
  return {
    [JobRunColumnId.Name]: {
      text: `Run #${run.sequence}`,
      link: `${path}?runId=${run.id}`,
    },
    [JobRunColumnId.Status]: {
      status: run.state,
    },
    [JobRunColumnId.CreatedAt]: {
      date: run.createdAt,
    },
  };
};
