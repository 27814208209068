import React, { ReactElement, useMemo } from "react";
import { config } from "../config";
import { Flag } from "../posthog-react";
import { usePosthog } from "./Provider";

export function useFeatureFlag(
  flag: Flag,
  { devEnabled, sendEvent }: { devEnabled?: true; sendEvent?: boolean } = {}
): boolean {
  const posthog = usePosthog();
  const isEnabled = useMemo(() => {
    if (devEnabled && config.isDev) {
      return true;
    }
    return (
      (posthog &&
        posthog.isFeatureEnabled(flag, {
          send_event: sendEvent === undefined ? true : sendEvent,
        })) ||
      false
    );
  }, [devEnabled, flag, posthog, sendEvent]);

  return isEnabled;
}

interface FeatureFlagProps {
  flag: Flag;
  sendEvent?: boolean;
  fallback?: ReactElement;
  devEnabled?: true;
  children?: React.ReactNode;
}

export const FeatureFlag: React.FC<FeatureFlagProps> = ({
  flag,
  sendEvent,
  children,
  fallback = null,
  devEnabled,
}) => {
  const isFlagEnabled = useFeatureFlag(flag, { sendEvent, devEnabled });

  return isFlagEnabled ? <>{children}</> : fallback;
};
