import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useCreateGroupMutation } from "@zeet/web-api/dist/graphqlv1";
import { Button, useColors, useCurrentTeamUser } from "@zeet/web-ui";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { generateRandomProjectName } from "~/utils/generators";

type FormValues = {
  groupName: string;
};

const CreateGroupSubgroupModal = ({ isOpen, onClose }) => {
  const { bg } = useColors();
  const currentTeamUser = useCurrentTeamUser();
  const history = useHistory();
  const {
    register,
    getValues,
    reset,
    trigger,
    formState: { errors },
  } = useForm<FormValues>({
    mode: "onChange",
    defaultValues: {
      groupName: "",
    },
  });

  useEffect(() => {
    reset({
      groupName: "",
    });
  }, [isOpen]);

  const isFormValid = () => {
    return Object.keys(errors).length === 0;
  };

  const [createGroupMutation, { loading }] = useCreateGroupMutation();

  const createGroup = async () => {
    if (await trigger()) {
      await createGroupMutation({
        variables: {
          input: {
            teamId: currentTeamUser.id,
            name: getValues("groupName"),
          },
        },
      });
      history.push(`/${currentTeamUser.login}/${getValues("groupName")}`);
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered preserveScrollBarGap>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>New Group</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text opacity="0.7" mt="-4">
            Organize projects using Groups and Sub-Groups.
          </Text>
          <FormControl
            isRequired
            mt="6"
            isInvalid={!!errors.groupName?.message}
          >
            <FormLabel>Group name</FormLabel>
            <Input
              bg={bg}
              autoFocus
              placeholder={generateRandomProjectName()}
              _placeholder={{ opacity: 0.5 }}
              {...register("groupName", {
                required: "Group name is required",
              })}
            />
            <FormErrorMessage>{errors.groupName?.message}</FormErrorMessage>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button variant="secondary" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            isDisabled={!isFormValid()}
            onClick={createGroup}
            isLoading={loading}
          >
            Create Group
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateGroupSubgroupModal;
