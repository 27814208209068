import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  RepoCommonWithDbFragment,
  RepoDetailFragment,
  useLinkDatabaseMutation,
  useUserReposWithDbQuery,
} from "@zeet/web-api/dist/graphql";
import {
  ProjectLinkType,
  useProjectAvailableProvidersQuery,
} from "@zeet/web-api/dist/graphqlv1";
import {
  Flag,
  FormSelect,
  Link,
  useColors,
  useCurrentTeamUser,
  useFeatureFlag,
  useTrack,
  ZAlert,
} from "@zeet/web-ui";
import React, { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { FormCard } from "../../FormCard";
import { isDatabase } from "../../utils/project";
import { ProjectLinkContainer } from "../LinkProject";
import { useProjectV3Context } from "../ProjectV3Provider";
import { LinkDatabaseItem } from "./LinkDatabaseItem";

const AddDatabaseButton: React.FC = () => {
  return (
    <>
      <Flex width="100%" justifyContent="space-between">
        <Button
          size="md"
          mt={"auto"}
          as={Link}
          to="/new?source=database"
          colorScheme="brand"
          variant="solid"
          noUnderline
          target="_blank"
          shadow="lg"
        >
          + Create Database
        </Button>
      </Flex>
    </>
  );
};

const LinkDatabaseButton: React.FC<{
  repo: RepoDetailFragment;
  dbs: RepoCommonWithDbFragment[] | null | undefined;
}> = ({ repo, dbs }) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const toast = useToast();
  const { track } = useTrack();

  const findDefaultEnvPrefix = useMemo(() => {
    if (!repo?.databaseLinks?.length) {
      return "DATABASE_";
    }
    const baseString = "DATABASE";
    let i = 1;
    for (; i <= repo.databaseLinks.length; i++) {
      if (repo?.databaseLinks[i]?.envPrefix !== `${baseString}${i}_`) {
        break;
      }
    }
    return `${baseString}${i}_`;
  }, [repo]);

  const {
    register: registerNewDB,
    handleSubmit: handleSubmitNewDB,
    formState: { errors: errorsNewDB },
    reset: resetNewDB,
  } = useForm({
    defaultValues: {
      databaseID: "",
      envPrefix: findDefaultEnvPrefix,
    },
  });

  const [linkDatabase, { loading: loadingDatabaseLink }] =
    useLinkDatabaseMutation({
      onCompleted: (data) => {
        if (data) {
          onClose();
          toast({
            title: "Database linked",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          track("linkDatabase_success", {
            projectName: data.linkDatabase.name,
            id: data.linkDatabase.name,
          });
        }
      },
      onError: (error) => {
        if (error) {
          track("linkDatabase_error", {
            error,
          });
        }
      },
    });

  const onSubmitNewDB = (values) => {
    linkDatabase({
      variables: {
        input: {
          id: repo.id,
          databaseID: values.databaseID,
          envPrefix: values.envPrefix,
        },
      },
    });
  };

  // when a new database is added, we need to update the default prefix
  useEffect(() => {
    resetNewDB({
      databaseID: "",
      envPrefix: findDefaultEnvPrefix,
    });
  }, [repo, resetNewDB, findDefaultEnvPrefix]);

  return (
    <>
      <Flex width="100%" justifyContent="space-between">
        <Button colorScheme="brand" onClick={onOpen}>
          + Link Database
        </Button>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Choose a Database to Link</ModalHeader>
          <ModalBody>
            <Box paddingBottom="1rem">
              <form
                onSubmit={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleSubmitNewDB(onSubmitNewDB)(e);
                }}
              >
                {/* <FormControl isInvalid={false}> */}
                <Flex alignItems="center">
                  <Flex width="25%">
                    <Text>Select Database</Text>
                  </Flex>
                  <Box width="75%">
                    <FormControl
                      isRequired
                      isInvalid={!!errorsNewDB?.databaseID?.message}
                    >
                      <FormSelect
                        {...registerNewDB("databaseID", {
                          required: true,
                        })}
                        placeholder="--Select--"
                        options={dbs
                          ?.filter((e) => {
                            // only show databases that are not already linked
                            return (
                              isDatabase(e) &&
                              !repo.databaseLinks.find((el) => {
                                return el.database.id === e.id;
                              })
                            );
                          })
                          .map((element) => ({
                            value: element.id,
                            label: element.name,
                          }))}
                      />
                    </FormControl>
                  </Box>
                </Flex>
                <Flex alignItems="center" marginTop="1rem">
                  <Flex width="25%">
                    <Text>Environment Variable Prefix</Text>
                  </Flex>
                  <Box width="75%">
                    <FormControl
                      isInvalid={
                        errorsNewDB.envPrefix && !!errorsNewDB.envPrefix.message
                      }
                      isRequired
                    >
                      <Input
                        {...registerNewDB("envPrefix", {
                          required: true,
                          validate: (envPrefix) => {
                            return (
                              !repo.databaseLinks.find(
                                (element) => element.envPrefix === envPrefix
                              ) ||
                              "Please use an env prefix not used by another linked database"
                            );
                          },
                        })}
                      />
                      <FormErrorMessage>
                        {errorsNewDB.envPrefix && errorsNewDB.envPrefix.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Box>
                </Flex>
                <Flex marginTop="1rem" justifyContent="flex-end">
                  <Button
                    isLoading={loadingDatabaseLink}
                    size="sm"
                    colorScheme="brand"
                    type="submit"
                  >
                    Link Database
                  </Button>
                </Flex>
              </form>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export const LinkDatabase: React.FC<{ repo: RepoDetailFragment }> = ({
  repo,
}) => {
  const teamUser = useCurrentTeamUser();
  const { bg2 } = useColors();
  const { data: reposData, loading: reposLoading } = useUserReposWithDbQuery({
    variables: {
      id: teamUser.id,
    },
  });

  const linkType = ProjectLinkType.Database;
  const currentTeamUser = useCurrentTeamUser();
  const { projectData } = useProjectV3Context();
  const currentProject = projectData?.user.projectV3Adapters?.nodes?.[0];
  const isLinkProjectEnabled = useFeatureFlag(Flag.LinkProject, {
    devEnabled: true,
  });
  const { data } = useProjectAvailableProvidersQuery({
    variables: {
      teamId: currentTeamUser.id,
      projectId: currentProject?.id ?? "",
      linkType: linkType,
    },
  });
  const availableProviders = data?.team?.project?.availableProviders ?? [];

  return (
    <>
      <FormCard
        isLoading={reposLoading}
        onSubmit={() => {}}
        noButton
        formHint={
          "Database linked environment variables begins with a unique prefix"
        }
        title="Link Database"
        description="Link a database to your project. A list of existing linked databases is shown below along with their linked environment variables."
      >
        {isLinkProjectEnabled ? (
          <ProjectLinkContainer
            linkType={linkType}
            availableProviders={availableProviders}
          />
        ) : (
          <Flex flexDirection="column">
            <FormControl isInvalid={false}>
              {repo.databaseLinks.length > 0 ? (
                <Stack spacing={2} mb={2}>
                  <Flex width="100%">
                    <Box width="35%">
                      <Text fontWeight={"bold"}>Database Name</Text>
                    </Box>
                    <Box width="50%">
                      <Text fontWeight={"bold"}>
                        Environment Variable Prefix
                      </Text>
                    </Box>
                    <Flex width="15%" justifyContent={"center"}>
                      <Text fontWeight={"bold"}>Unlink</Text>
                    </Flex>
                  </Flex>

                  {repo.databaseLinks.map((element, index) => (
                    <LinkDatabaseItem
                      key={index}
                      repo={repo}
                      databaseLink={element}
                    />
                  ))}
                </Stack>
              ) : (
                <Text mt={2} mb={4}>
                  No linked databases yet
                </Text>
              )}
              {reposLoading ? (
                <Flex justifyContent="flex-start">
                  <Spinner />
                </Flex>
              ) : (
                <LinkDatabaseButton repo={repo} dbs={reposData?.user?.repos} />
              )}
            </FormControl>
          </Flex>
        )}
      </FormCard>
      <Stack
        padding={4}
        shadow="md"
        borderRadius={4}
        borderWidth="1px"
        bg={bg2}
        spacing={4}
      >
        <Flex fontWeight="bold" display="inline-flex" alignItems="center">
          <Text fontSize="xl">Create Database</Text>
        </Flex>
        <Text fontSize="sm">Create a new Database for your project.</Text>
        <Flex flexDirection="column">
          <FormControl isInvalid={false}>
            {reposLoading ? (
              <Flex justifyContent="flex-start">
                <Spinner />
              </Flex>
            ) : (
              <AddDatabaseButton></AddDatabaseButton>
            )}
          </FormControl>
        </Flex>
        <ZAlert>
          {"Want to learn more about Databases on Zeet?"}
          <Link
            to="https://docs.zeet.co/guides/blueprints/databases/"
            ml={1}
            color="brandVar"
            noUnderline={false}
          >
            Read the Docs.
          </Link>
        </ZAlert>
      </Stack>
    </>
  );
};
