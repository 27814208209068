import { DeepPartial } from "@chakra-ui/react";
import { RevisableEntity } from "@zeet/web-api/dist/graphqlv1";

export const formatRevisionData = (revision?: DeepPartial<RevisableEntity>) => {
  const formatJson = (json: object) =>
    JSON.stringify(json, (_, v) => (v === "" ? undefined : v), 2);
  try {
    const parsed = JSON.parse(revision?.revisionData ?? "{}");

    const hashes = preprocessRevisionData(parsed);
    return { json: formatJson(parsed), hashes };
  } catch (e) {
    return { json: formatJson({ error: "invalid revision" }), hashes: [] };
  }
};

interface HashedValue {
  name: string;
  hash: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const preprocessRevisionData = (data: any): HashedValue[] => {
  if (!data || typeof data !== "object") return [];
  if (data instanceof Array) {
    return data.flatMap((e) => preprocessRevisionData(e));
  }

  Object.keys(data).forEach((k) => {
    // maybe not the most performant solution but it is much more concise
    const json = JSON.stringify(data[k]);
    if (data[k] === null || json === "{}" || json === "[]") {
      delete data[k];
    }
  });

  // this is *crazy* yes. But we are basically looking to find
  // all instances of "Name": "***", "ValueHashed": "..." pairs.
  // kind of a cute function
  if ("ValueHashed" in data && "Name" in data) {
    // don't show it in ui
    const hash = data.ValueHashed;
    delete data["ValueHashed"];

    return [{ name: data.Name, hash }];
  }

  // okay not in this object, maybe in one of our children?
  return Object.values(data).flatMap((d) => preprocessRevisionData(d));
};
