import { DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  RepoCommonFragment,
  RepoDetailFragment,
  useUnlinkDatabaseMutation,
  useUpdateDatabaseLinkMutation,
} from "@zeet/web-api/dist/graphql";
import React from "react";
import { useForm } from "react-hook-form";
import { MdBuild } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import { CoolInput } from "./CoolInput";

type EnvVar = {
  name: string;
  value: string;
};

interface IDatabaseLink {
  database: RepoCommonFragment;
  envPrefix?: string | null;
  envs?: EnvVar[] | null;
  id: string;
}

export const LinkDatabaseItem: React.FC<{
  databaseLink: IDatabaseLink;
  repo: RepoDetailFragment;
}> = ({ databaseLink, repo }) => {
  const {
    onOpen: onOpenEditDatabaseLink,
    onClose: onCloseEditDatabaseLink,
    isOpen: isOpenEditDatabaseLink,
  } = useDisclosure();

  const {
    onOpen: onOpenRemoveLink,
    onClose: onCloseRemoveLink,
    isOpen: isOpenRemoveLink,
  } = useDisclosure();

  const {
    register,
    handleSubmit,
    formState: { errors: errorsNewDB },
  } = useForm({
    defaultValues: {
      envPrefix: databaseLink.envPrefix,
    },
  });
  const toast = useToast();

  const [unlinkDatabase, { loading: loadingUnlinkDatabase }] =
    useUnlinkDatabaseMutation({
      onCompleted: (data) => {
        if (data) {
          onCloseRemoveLink();
          toast({
            title: "Database unlinked",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      },
    });

  const [updateDatabaseLink, { loading: loadingUpdateDatabaseLink }] =
    useUpdateDatabaseLinkMutation({
      onCompleted: (data) => {
        if (data) {
          onCloseEditDatabaseLink();
          toast({
            title: "Database link updated",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      },
    });

  const onSubmit = (values) => {
    updateDatabaseLink({
      variables: {
        input: {
          id: databaseLink.id,
          envPrefix: values.envPrefix,
        },
      },
    });
  };

  return (
    <>
      <Flex
        key={databaseLink.id}
        width="100%"
        flexDir={"column"}
        padding={2}
        borderRadius={4}
        borderColor="whiteAlpha.400"
        borderWidth={1}
        shadow="sm"
      >
        <Flex width="100%" alignItems="center" mb={2}>
          <Box width="35%">
            <Text whiteSpace="nowrap" fontWeight={"bold"}>
              {databaseLink.database.name}
            </Text>
          </Box>
          <Flex width="50%" alignItems="center">
            <Text whiteSpace="nowrap">{databaseLink.envPrefix}</Text>
            <Button
              colorScheme="brand"
              marginLeft="1rem"
              height="1.5rem"
              onClick={onOpenEditDatabaseLink}
            >
              Edit
            </Button>
            <Modal
              isOpen={isOpenEditDatabaseLink}
              onClose={onCloseEditDatabaseLink}
              size="md"
              isCentered
            >
              <ModalOverlay />
              <ModalContent>
                <ModalCloseButton />
                <ModalHeader>Edit Env Prefix</ModalHeader>
                <ModalBody>
                  <Box paddingBottom="1.5rem">
                    <form
                      onSubmit={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        handleSubmit(onSubmit)(e);
                      }}
                    >
                      <FormControl
                        isInvalid={
                          errorsNewDB.envPrefix &&
                          !!errorsNewDB.envPrefix.message
                        }
                        isRequired
                      >
                        <Input
                          {...register("envPrefix", {
                            required: true,
                            validate: (envPrefix) => {
                              return (
                                !repo.databaseLinks.find(
                                  (databaseLink) =>
                                    databaseLink.envPrefix === envPrefix
                                ) ||
                                "Please use an env prefix not used by another linked database"
                              );
                            },
                          })}
                        />
                        <FormErrorMessage>
                          {errorsNewDB.envPrefix &&
                            errorsNewDB.envPrefix.message}
                        </FormErrorMessage>
                      </FormControl>
                      <Flex justifyContent="center">
                        <Button
                          alignSelf="center"
                          leftIcon={<MdBuild />}
                          mt={10}
                          shadow="md"
                          borderRadius={4}
                          display="flex"
                          colorScheme="brand"
                          isLoading={loadingUpdateDatabaseLink}
                          type="submit"
                        >
                          Update Prefix
                        </Button>
                      </Flex>
                    </form>
                  </Box>
                </ModalBody>
              </ModalContent>
            </Modal>
          </Flex>
          <Flex width="15%" justifyContent={"center"}>
            <IconButton
              icon={<DeleteIcon />}
              onClick={onOpenRemoveLink}
              size="sm"
              aria-label="delete"
            />
            <Modal
              isOpen={isOpenRemoveLink}
              onClose={onCloseRemoveLink}
              size="md"
              isCentered
            >
              <ModalOverlay />
              <ModalContent>
                <ModalCloseButton />
                <ModalHeader>
                  Are you sure you want to remove this database link?
                </ModalHeader>
                <ModalBody>
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    paddingBottom="2rem"
                  >
                    <Button
                      alignSelf="center"
                      leftIcon={<RiDeleteBinLine size="1.5rem" />}
                      mt={10}
                      p={8}
                      shadow="md"
                      height="10px"
                      borderRadius={4}
                      display="flex"
                      colorScheme="red"
                      isLoading={loadingUnlinkDatabase}
                      onClick={() => {
                        unlinkDatabase({
                          variables: {
                            id: databaseLink.id,
                          },
                        });
                      }}
                    >
                      Remove Linked Database
                    </Button>
                  </Flex>
                </ModalBody>
              </ModalContent>
            </Modal>
          </Flex>
        </Flex>
        <Stack spacing={"4px"} opacity={0.8}>
          {databaseLink.envs?.map((e) => {
            return (
              <Flex key={e.name}>
                <Text width={"35%"}>{e.name}</Text>
                <CoolInput
                  size="sm"
                  width="65%"
                  isReadOnly={true}
                  value={e.value}
                />
              </Flex>
            );
          })}
        </Stack>
      </Flex>
    </>
  );
};
