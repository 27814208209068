import { Button, Flex } from "@chakra-ui/react";
import { Tooltip } from "@zeet/web-ui";
import React from "react";
import { FileUpload } from "../../FileUpload";

export const MenuButtons: React.FC<{
  handleEnvFile: (event: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ handleEnvFile }) => {
  return (
    <Flex display="inline-flex">
      <FileUpload name="dotenv" onChange={handleEnvFile} width="auto">
        <Button size="sm" alignItems="center" justifyContent="center">
          Upload .env file
          <Tooltip text="Not seeing your .env file? If you're on Mac, try ⌘ + Shift + ." />
        </Button>
      </FileUpload>
    </Flex>
  );
};
