import { Stack } from "@chakra-ui/react";
import { SideLink } from "@zeet/web-ui";

export const VerticalLinks = ({ links }) => {
  return (
    <Stack mr={5} ml="-4" minW="250px">
      {links.map(
        (link, index) =>
          link.show !== false && (
            <SideLink key={index} to={link.url}>
              {link.text}
            </SideLink>
          )
      )}
    </Stack>
  );
};
