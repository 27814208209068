import { useDisclosure } from "@chakra-ui/react";
import { useBlueprintQuery } from "@zeet/web-api/dist/graphql";
import {
  ProjectLinkType,
  useProjectAvailableProvidersQuery,
} from "@zeet/web-api/dist/graphqlv1";
import { Button, Flag, useCurrentTeamUser, useFeatureFlag } from "@zeet/web-ui";
import React from "react";
import { useProjectV3Context } from "~/components/Project/ProjectV3Provider";
import { ProjectLinkModal } from "./ProjectLinkModal";

const getProjectLinkTitle = (linkType: ProjectLinkType) => {
  if (linkType === ProjectLinkType.Database) {
    return "Link Database";
  }

  return "";
};

const getProjectLinkTags = (linkType: ProjectLinkType) => {
  if (linkType === ProjectLinkType.Database) {
    return "Database";
  }

  return null;
};

const isProjectLinkType = (
  linkType: ProjectLinkType,
  blueprintTags: string[]
) => {
  const projectLinkTags = getProjectLinkTags(linkType);
  if (!projectLinkTags) {
    return false;
  }

  return blueprintTags.includes(projectLinkTags);
};

export const ProjectLinkAction: React.FC<{ linkType: ProjectLinkType }> = ({
  linkType,
}) => {
  const isLinkProjectEnabled = useFeatureFlag(Flag.LinkProject, {
    devEnabled: true,
  });
  const currentTeamUser = useCurrentTeamUser();
  const { projectInfoData, projectData } = useProjectV3Context();
  const currentProject = projectData?.user.projectV3Adapters?.nodes?.[0];

  const { data: blueprintData, loading: blueprintLoading } = useBlueprintQuery({
    variables: {
      userId: currentTeamUser.id,
      permissionsUserId: currentTeamUser.id,
      blueprintId: projectInfoData?.team?.project?.blueprint?.id || "",
    },
  });
  const blueprintTags = blueprintData?.user.blueprint?.tags || [];
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data, loading } = useProjectAvailableProvidersQuery({
    variables: {
      teamId: currentTeamUser.id,
      projectId: currentProject?.id ?? "",
      linkType: linkType,
    },
  });
  const availableProviders = data?.team?.project?.availableProviders;

  if (
    !isLinkProjectEnabled ||
    blueprintLoading ||
    isProjectLinkType(linkType, blueprintTags) ||
    loading ||
    !availableProviders?.length
  ) {
    return null;
  }

  return (
    <>
      <ProjectLinkModal
        {...{ isOpen, onClose, availableProviders, linkType }}
      />
      <Button variant="secondary" onClick={onOpen}>
        {getProjectLinkTitle(linkType)}
      </Button>
    </>
  );
};
