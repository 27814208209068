import { useColorModeValue as useColorModeValueChakra } from "@chakra-ui/react";
import { colorMap, ColorMapProps } from "../theme";

export const useColors = (): ColorMapProps => {
  return useColorModeValue(colorMap.light, colorMap.dark);
};

export const useColorModeValue = <TLight = unknown, TDark = unknown>(
  light: TLight,
  dark: TDark
): TLight | TDark => {
  return useColorModeValueChakra(light, dark);
};
