import { BlueprintSlug, BlueprintTag } from "@zeet/web-ui";

// Supported query params for the blueprint creation flow prefilling
export enum BlueprintQueryParams {
  HelmChart = "helm_repository_chart",
  HelmRepository = "helm_repository_url",
  HelmVersion = "helm_chart_version",
  HelmReleaseName = "helm_release_name",
  HelmNamespace = "helm_namespace",
  HelmValues = "helm_values",
  ClusterId = "cluster_id",
}

const appendParams = (params: URLSearchParams): string => {
  return params.toString() ? `?${params.toString()}` : "";
};

const makeCreateResourceRedirect = (location: Location): string | void => {
  const params = new URLSearchParams(location.search);

  if (location.pathname?.startsWith("/new/gitlab")) {
    return `/new${appendParams(params)}`;
  }

  if (location.pathname?.startsWith("/new/build")) {
    return `/new${appendParams(params)}`;
  }

  if (
    location.pathname?.startsWith("/new/helm") ||
    location.pathname?.startsWith("/new/zeet/helm")
  ) {
    if (params.get("repo")) {
      const value = params.get("repo");
      params.delete("repo");
      params.set(BlueprintQueryParams.HelmRepository, value ?? "");
    }

    if (params.get("chart")) {
      const value = params.get("chart");
      params.delete("chart");
      params.set(BlueprintQueryParams.HelmChart, value ?? "");
    }

    return `/new/zeet/${BlueprintSlug.Helm}${appendParams(params)}`;
  }

  if (params.get("source") === "docker") {
    params.delete("source");
    return `/new/zeet/${BlueprintSlug.LegacyDockerImage}`;
  }

  if (params.get("source") === "database") {
    params.delete("source");
    params.set("tags", BlueprintTag.Database);
    return `/new${appendParams(params)}`;
  }
};

export { makeCreateResourceRedirect };
