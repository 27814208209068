import { RepoSource, RepoSourceType } from "@zeet/web-api/dist/graphql";
import { GitlabLogo, HelmLogo, ZeetLogo } from "@zeet/web-ui";
import React from "react";
import { FaDocker, FaGithub } from "react-icons/fa";
import { SiBitbucket, SiTerraform } from "react-icons/si";
import { getDockerLink } from "../utils/docker";
import { getHelmLink } from "../utils/helm";

export const getSourceLink = (source: RepoSource): string => {
  switch (source.type) {
    case RepoSourceType.Github:
    case RepoSourceType.GithubPublic: {
      return `https://github.com/${source.name}`;
    }
    case RepoSourceType.Gitlab: {
      return `https://gitlab.com${source.id}`;
    }
    case RepoSourceType.Bitbucket: {
      return `https://bitbucket.org/${source.id}`;
    }
    case RepoSourceType.Docker: {
      return getDockerLink(source.id);
    }
    case RepoSourceType.Terraform: {
      if (source.name.startsWith("https://")) {
        return source.name;
      } else {
        return `https://github.com/${source.name}`;
      }
    }
    case RepoSourceType.Helm: {
      if (source.id.startsWith("https://")) {
        return source.id;
      } else if (source.id.indexOf(":") > 0) {
        return getHelmLink(source.id);
      } else {
        return `https://github.com/${source.name}`;
      }
    }
    default:
      return "";
  }
};

export const getSourceImage = (source: RepoSource): React.ReactNode => {
  return [RepoSourceType.Github, RepoSourceType.GithubPublic].includes(
    source.type
  ) ? (
    <FaGithub />
  ) : source.type === RepoSourceType.Gitlab ? (
    <GitlabLogo w="1.2rem" h="1.2rem" />
  ) : source.type === RepoSourceType.Bitbucket ? (
    <SiBitbucket />
  ) : source.type === RepoSourceType.Docker ? (
    <FaDocker />
  ) : source.type === RepoSourceType.Terraform ? (
    <SiTerraform />
  ) : source.type === RepoSourceType.Helm ? (
    <HelmLogo w="1.2rem" h="1.2rem" />
  ) : (
    <ZeetLogo />
  );
};

export const getSourceName = (source: RepoSource): string => {
  switch (source.type) {
    case RepoSourceType.Github:
    case RepoSourceType.GithubPublic: {
      return source.name;
    }
    case RepoSourceType.Terraform: {
      if (source.name.startsWith("https://")) {
        return source.name
          .replace("https://github.com/", "")
          .replace("terraform-aws-modules/", "");
      } else {
        return source.name;
      }
    }
    case RepoSourceType.Helm: {
      if (source.id.startsWith("https://")) {
        return source.id.replace("https://github.com/", "");
      } else {
        return source.name;
      }
    }
    case RepoSourceType.Docker: {
      return source.id.split(":")[0] ?? "";
    }
    case RepoSourceType.Gitlab: {
      // gitlab id is in the form of /group/project
      return source.id.replace(/^\//, "");
    }
    default:
      return source.id;
  }
};
