import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Text,
} from "@chakra-ui/react";
import {
  BlueprintVariableType,
  ProjectDetailFragment,
  UpdateDeployInput,
} from "@zeet/web-api/dist/graphqlv1";
import {
  BridgeBlueprintInput,
  Button,
  FormSelect,
  useColors,
  useEffectOnce,
} from "@zeet/web-ui";
import { useCallback, useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { FiMinusCircle } from "react-icons/fi";
import InputControl from "~/features/Project/New/ConfigureStep/Inputs/InputControl";
import { getError } from "~/features/Project/New/ConfigureStep/Inputs/utils";
import { makeBlueprintVariablesFromRichInputSchema } from "~/features/Project/New/makeBlueprint";
import { useIsFormValueSet } from "~/hooks/useIsFormValueSet";
import { StringInput } from "../../inputs";
import { ProjectVariables } from "../../inputs/ProjectVariables";
import { StringInputWithValidation } from "../../inputs/StringInputWithValidation";
import { SettingsSection } from "../SettingsSection";
import { makeProjectRichInputs, typeValidations } from "./util";

export const TerraformVariablesInputs: React.FC<{
  richInputs: BridgeBlueprintInput[];
}> = ({ richInputs }) => {
  const { bg } = useColors();

  const {
    register,
    control,
    formState: { errors },
    watch,
    trigger,
    setValue,
  } = useFormContext<UpdateDeployInput>();

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: `configuration.variables`,
  });

  const { richInputPrefilled, filteredVariables } = makeProjectRichInputs(
    richInputs,
    fields
  );

  const watcher = watch("configuration.variables");

  const makeDefaultValue = () => {
    return {
      name: "",
      value: "",
      type: BlueprintVariableType.String,
    };
  };

  const validationCallback = useCallback(
    (index: number) => {
      const type = watcher?.[index]?.type;
      return typeValidations[type || BlueprintVariableType.String];
    },
    [watcher]
  );
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    trigger(`configuration.variables.${currentIndex}.value`);
  }, [
    trigger,
    currentIndex,
    // hack needed to validate form after setValue because setValue is an
    // async function that is triggered onChange for selecting the variable type.
    watcher?.[currentIndex]?.type, // eslint-disable-line react-hooks/exhaustive-deps
    watcher?.[currentIndex]?.value, // eslint-disable-line react-hooks/exhaustive-deps
  ]);

  useEffectOnce(() => {
    if (filteredVariables.length === 0 && richInputPrefilled.length === 0) {
      append(makeDefaultValue(), { shouldFocus: false });
    }
  });

  const richInputsIndexOffset = richInputPrefilled.length;
  const removeOrReset = (index: number) => {
    if (filteredVariables.length === 0) {
      update(richInputPrefilled.length, makeDefaultValue());
    } else {
      remove(index);
    }
  };

  const options = Object.values(BlueprintVariableType)?.map((option) => ({
    label: option,
    value: option,
  }));

  return (
    <SettingsSection
      name="Variables"
      basePath="variables"
      description="Configure the variables of this project"
      gap={2}
    >
      <Box data-testid="project-inputs-section">
        {richInputPrefilled.map((input, index) => (
          <InputControl
            key={input.id}
            input={input}
            error={getError(errors, input)}
            pathMap={() => `configuration.variables.${index}.value`}
          />
        ))}
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        pr="16"
        mb="2"
      >
        <Box display="inline-flex" alignItems="center" flex="2">
          <Text>Variable Name</Text>
        </Box>
        <Box display="inline-flex" alignItems="center" flex="2">
          <Text ml="8px">Value</Text>
        </Box>
        <Box display="inline-flex" alignItems="center" flex="1">
          <Text ml="8px">Type</Text>
        </Box>
      </Box>
      {filteredVariables.map((_, index) => (
        <Box
          key={index + richInputsIndexOffset}
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          gap="3"
        >
          <StringInput path={`${index + richInputsIndexOffset}.name`} />
          <FormControl isInvalid={!!errors.configuration}>
            <StringInputWithValidation
              path={`${index + richInputsIndexOffset}.value`}
              validationFunction={validationCallback(
                index + richInputsIndexOffset
              )}
            />
            <FormErrorMessage>
              {
                errors.configuration?.variables?.[index + richInputsIndexOffset]
                  ?.value?.message as string
              }
            </FormErrorMessage>
          </FormControl>
          <FormLabel width="50%">
            <FormSelect
              useBasicStyles
              chakraStyles={{
                container: (provided) => ({
                  ...provided,
                  backgroundColor: bg,
                }),
                menuList: (provided) => ({
                  ...provided,
                  boxShadow: "2xl",
                }),
              }}
              openMenuOnFocus
              captureMenuScroll={false}
              placeholder="Select option"
              options={options}
              {...register(
                `configuration.variables.${index + richInputsIndexOffset}.type`
              )}
              onChange={(e) => {
                setCurrentIndex(index + richInputsIndexOffset);
                setValue(
                  `configuration.variables.${
                    index + richInputsIndexOffset
                  }.type`,
                  e.target.value as BlueprintVariableType,
                  { shouldValidate: true, shouldDirty: true }
                );
              }}
              data-testid={`form-select-${index + richInputsIndexOffset}`}
            />
          </FormLabel>
          <IconButton
            aria-label="delete"
            icon={<FiMinusCircle />}
            borderRadius="md"
            variant="outline"
            onClick={() => removeOrReset(index + richInputsIndexOffset)}
            mb="2"
          />
        </Box>
      ))}
      <Flex>
        <Button
          variant="secondary"
          size="sm"
          onClick={() => append(makeDefaultValue())}
          mr={4}
        >
          Add item
        </Button>
      </Flex>
    </SettingsSection>
  );
};

export const VariableSettings: React.FC<{
  project: ProjectDetailFragment;
}> = ({ project }) => {
  const isHelm = useIsFormValueSet("configuration.helm");
  const isTerraform = useIsFormValueSet("configuration.terraform");

  const projectRichInputs = makeBlueprintVariablesFromRichInputSchema(
    project.blueprint?.configuration?.richInputSchema
  );

  if (isTerraform) {
    return <TerraformVariablesInputs richInputs={projectRichInputs} />;
  }

  if (isHelm) {
    return <ProjectVariables />;
  }
  return null;
};
