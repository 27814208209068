import { Flex, Text, useDisclosure } from "@chakra-ui/react";
import {
  RepoDetailFragment,
  useDeleteProjectMutation,
  useDisableProjectMutation,
  useEnableProjectMutation,
  UserBillingOverviewDocument,
  UserCloudsDocument,
  useUnlinkProjectMutation,
} from "@zeet/web-api/dist/graphql";
import {
  AnalyticsEvent,
  DeleteFlow,
  DeletionType,
  useCurrentTeamUser,
  useTrack,
  ZError,
} from "@zeet/web-ui";
import React from "react";
import { useHistory } from "react-router-dom";
import { UpgradeProductModal } from "../../Account/BillingV2/Modals/UpgradeProductModal";
import { useBillingOverviewContext } from "../../Account/BillingV2/Providers/BillingOverviewProvider";
import { maybeOpenBillingModal } from "../../Account/BillingV2/utils";

export const DeleteProject: React.FC<{
  repo: RepoDetailFragment;
  isDisabled: boolean;
}> = ({ repo, isDisabled }) => {
  const teamUser = useCurrentTeamUser();
  const history = useHistory();

  const { track } = useTrack();
  const currentTeamUser = useCurrentTeamUser();

  const {
    onOpen: billingOnOpen,
    onClose: billingOnClose,
    isOpen: billingIsOpen,
  } = useDisclosure();
  const [{ userBillingOverview, isOwnerOrAdmin }] = useBillingOverviewContext();

  const [unpauseProject] = useEnableProjectMutation({
    update(cache) {
      cache.modify({
        id: cache.identify(repo),
        fields(_, details) {
          return details.DELETE;
        },
      });
      cache.modify({
        id: cache.identify(teamUser),
        fields: {
          repos(_, { DELETE }) {
            return DELETE;
          },
        },
      });
    },
  });

  const [deleteProject, { error: deleteRepoError }] = useDeleteProjectMutation({
    errorPolicy: "none",
    update(cache) {
      cache.modify({
        id: cache.identify(repo),
        fields(_, details) {
          return details.DELETE;
        },
      });
      cache.modify({
        id: cache.identify(teamUser),
        fields: {
          repos(_, { DELETE }) {
            return DELETE;
          },
        },
      });
    },
  });
  const [unlinkRepo, { error: unlinkRepoError }] = useUnlinkProjectMutation({
    errorPolicy: "none",
    update(cache) {
      cache.modify({
        id: cache.identify(repo),
        fields(_, details) {
          return details.DELETE;
        },
      });
      cache.modify({
        id: cache.identify(teamUser),
        fields: {
          repos(_, { DELETE }) {
            return DELETE;
          },
        },
      });
    },
  });

  const [disableRepo] = useDisableProjectMutation({
    errorPolicy: "none",
    update(cache) {
      cache.modify({
        id: cache.identify(repo),
        fields(_, details) {
          return details.DELETE;
        },
      });
      cache.modify({
        id: cache.identify(teamUser),
        fields: {
          repos(_, { DELETE }) {
            return DELETE;
          },
        },
      });
    },
  });

  // kinda the best we can do, repo doesn't really have a "draft" state.
  const hasResources = !!repo.productionDeployment;

  return (
    <Flex flexDirection="column">
      <Text fontWeight="bold" fontSize="1.2rem" mb={2}>
        Deletion
      </Text>
      {!isDisabled && (
        <DeleteFlow
          canPause={hasResources && repo.enabled}
          canResume={!repo.enabled}
          canDestroy={hasResources}
          canDelete={!hasResources}
          canUnlink={repo.enabled && hasResources}
          callback={async (type) => {
            if (type === DeletionType.Delete) {
              track(AnalyticsEvent.UNLINK_REPO_SUCCESS);
              await unlinkRepo({
                variables: { id: repo.id },
                refetchQueries: [
                  {
                    query: UserBillingOverviewDocument,
                    variables: { id: currentTeamUser?.id },
                  },
                ],
              });
              history.push(`/${repo?.owner.login}/console/home`);
              track(AnalyticsEvent.UNLINK_REPO_CONFIRM);
            }

            if (type === DeletionType.DeleteWithResources) {
              track(AnalyticsEvent.DELETE_REPO_CONFIRM);
              await deleteProject({
                variables: { id: repo.id },
                refetchQueries: [
                  {
                    query: UserBillingOverviewDocument,
                    variables: { id: currentTeamUser?.id },
                  },
                ],
              });
              history.push(`/${repo?.owner.login}/console/home`);
              track(AnalyticsEvent.DELETE_REPO_SUCCESS);
            }

            if (type === DeletionType.Pause) {
              await disableRepo({
                variables: { id: repo.id },
                refetchQueries: [
                  {
                    query: UserBillingOverviewDocument,
                    variables: { id: teamUser?.id },
                  },
                  {
                    query: UserCloudsDocument,
                    variables: { id: teamUser?.id },
                  },
                ],
              });
            }

            if (type === DeletionType.Resume) {
              return maybeOpenBillingModal({
                onOpen: billingOnOpen,
                userBillingOverview,
                onClickFunction: async () => {
                  await unpauseProject({
                    variables: {
                      id: repo.id,
                    },
                    refetchQueries: [
                      {
                        query: UserBillingOverviewDocument,
                        variables: { id: teamUser?.id },
                      },
                      {
                        query: UserCloudsDocument,
                        variables: { id: teamUser?.id },
                      },
                    ],
                  });
                },
                usageUnit: "projects",
              });
            }
          }}
          projectName={repo.name}
        />
      )}
      <UpgradeProductModal
        onClose={billingOnClose}
        isOpen={billingIsOpen}
        headerText="You've reached the free Projects limit"
        titleText="Add more Projects for your team"
        bodyText="Add a payment method so your team can continue building with Pay as you Go billing."
        shouldGoBack={false}
        primaryButtonLink={
          isOwnerOrAdmin
            ? `${userBillingOverview?.user.billingOverview?.externalLinks?.managePaymentMethod.url}/payment-methods`
            : `/${teamUser.login}/account/billing/overview`
        }
        primaryButtonText={
          isOwnerOrAdmin ? "Add payment method" : "Review usage"
        }
      />
      <ZError error={deleteRepoError || unlinkRepoError} />
    </Flex>
  );
};
