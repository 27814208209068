import { DeleteIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  IconButton,
  Input,
  NumberInput,
  NumberInputField,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import {
  RepoDetailFragment,
  useUpdateProjectSettingsMutation,
} from "@zeet/web-api/dist/graphql";
import React, { useEffect, useMemo, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { FormCard } from "../../FormCard";
import { shouldDisplayError } from "../util";

export const VolumeSettings: React.FC<{ repo: RepoDetailFragment }> = ({
  repo,
}) => {
  const toast = useToast();

  const [changed, setChanged] = useState(false);

  const { register, control, handleSubmit } = useForm({
    defaultValues: {
      volumes: repo.volumes || [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "volumes",
  });

  const defaultFieldValues = useMemo(
    () => ({
      mountPath: "",
      size: 0,
    }),
    []
  );

  useEffect(() => {
    if (fields.length === 0) {
      append(defaultFieldValues, { shouldFocus: false });
    }
  }, [fields, append, defaultFieldValues]);

  const [updateSettings, { error, loading, data }] =
    useUpdateProjectSettingsMutation({
      onCompleted: (data) => {
        setChanged(false);
        if (data) {
          toast({
            title: "Volume Settings Saved",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      },
    });

  const onSubmit = (values): void => {
    updateSettings({
      variables: {
        input: {
          id: repo.id,
          volumes: JSON.stringify(
            values.volumes.map((v) => ({
              mountPath: v.mountPath,
              size: parseInt(v.size),
            }))
          ),
        },
      },
    });
  };

  if (error) {
    console.error(error);
  }

  const errorMsg = !changed && error?.message;

  return (
    <FormCard
      isLoading={loading}
      onSubmit={handleSubmit(onSubmit)}
      title="Persistent Volumes"
      description="Mount an external volume into your project. Data not saved into these will not persist a deploy."
    >
      <Flex flexDirection="column">
        <FormControl isInvalid={!!errorMsg && shouldDisplayError(error, data)}>
          <Stack spacing={4}>
            {fields.map((item, index) => (
              <Stack key={item.id} isInline spacing={4} alignItems="center">
                <Text whiteSpace="nowrap"> Mount Path </Text>
                <Input
                  defaultValue={item.mountPath}
                  placeholder="(e.g. /data)"
                  {...register(`volumes.${index}.mountPath`)}
                />

                <Text whiteSpace="nowrap"> Size (GB) </Text>

                <NumberInput defaultValue={item.size}>
                  <NumberInputField
                    placeholder="(e.g. 10)"
                    {...register(`volumes.${index}.size`)}
                  />
                </NumberInput>

                <IconButton
                  aria-label="delete"
                  icon={<DeleteIcon />}
                  onClick={() => remove(index)}
                />
              </Stack>
            ))}
            {shouldDisplayError(error, data) && (
              <FormErrorMessage>{errorMsg}</FormErrorMessage>
            )}
            <Flex width="100%" justifyContent="space-between">
              <Button
                colorScheme="brand"
                onClick={() =>
                  append(defaultFieldValues, { shouldFocus: false })
                }
              >
                + Add More
              </Button>
            </Flex>
          </Stack>
        </FormControl>
      </Flex>
    </FormCard>
  );
};
