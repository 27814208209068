import { ButtonProps, Flex, Grid, Text } from "@chakra-ui/react";
import { useColorModeValue } from "../..";
import { Button } from "../Button";

interface DeleteActionProps extends React.PropsWithChildren {
  title: string;
  message: string;
  action: string;
  onAction?(): unknown;
  buttonTestId?: string;
  variant?: "primary" | "secondary" | "ghost" | "error";
}
export const DeleteAction = ({
  title,
  message,
  action,
  onAction,
  children,
  buttonTestId,
  variant,
}: DeleteActionProps & ButtonProps) => {
  const textColor = useColorModeValue("gray.600", "gray.400");
  return (
    <Grid
      rowGap={1}
      columnGap={4}
      style={{ grid: `"title action" auto "message action" auto / 1fr auto` }}
      alignItems="center"
    >
      <Text fontWeight="600" fontSize="14px" gridArea={"title"}>
        {title}
      </Text>
      <Text fontSize="14px" color={textColor} gridArea={"message"}>
        {message}
      </Text>
      <Flex gridArea={"action"}>
        <Button
          borderRadius={4}
          fontSize="14px"
          variant={variant ?? "error"}
          onClick={onAction}
          data-testid={buttonTestId}
        >
          {action}
        </Button>
        {children}
      </Flex>
    </Grid>
  );
};
