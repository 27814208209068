import { Code, Flex, Heading, Stack, Text } from "@chakra-ui/react";
import {
  JobRunState,
  ProjectJobQuery,
  useUserJobQuery,
} from "@zeet/web-api/dist/graphql";
import { Card, StatusBadge, TimeBox, ZWarn } from "@zeet/web-ui";
import { default as React, useState } from "react";
import { useParams } from "react-router-dom";
import { LogsConsole } from "../LogsConsole/LogsConsole";
import { JobsSkeleton } from "../Project/JobsSkeleton";
import { FlexContent } from "../utils/FlexContent";

const jobState = (state?: JobRunState): string => {
  if (state == JobRunState.JobRunStarting) {
    return "starting";
  } else if (state == JobRunState.JobRunRunning) {
    return "running";
  } else if (state == JobRunState.JobRunFailed) {
    return "failed";
  } else if (state == JobRunState.JobRunSucceeded) {
    return "succeeded";
  }
  return "inactive";
};

const GrayText: React.FC<React.PropsWithChildren> = (props) => {
  return (
    <Text color="gray.500" textTransform="uppercase" fontSize={14} {...props} />
  );
};

export const JobView: React.FC = () => {
  const { id } = useParams<{
    id: string;
  }>();

  if (!id) {
    return null;
  }

  return (
    <FlexContent px="16px">
      <JobRunDetailView jobRunID={id} />
    </FlexContent>
  );
};

const JobRunDetailView: React.FC<{
  jobRunID: string;
}> = ({ jobRunID }) => {
  const { data, loading, error } = useUserJobQuery({
    variables: {
      jobID: jobRunID,
    },
  });

  const job = data?.currentUser?.jobRun;

  if (loading) {
    return <JobsSkeleton />;
  }

  if (error || !job) {
    return <Flex> Failed to Load Job</Flex>;
  }

  return (
    <Stack width="100%">
      <Card p={4} my={4}>
        <Flex alignItems="center" mb={3}>
          <Heading
            size="md"
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
            maxWidth="30rem"
          >
            {`ID ${job.id}`}
          </Heading>
          <Flex ml="auto">
            <StatusBadge ml={2} fontSize="md" status={jobState(job.state)} />
          </Flex>
        </Flex>
        <Flex>
          <Flex flex={1} flexDirection="column">
            <GrayText>Created</GrayText>
            <Flex align="center" display="inline-flex">
              <TimeBox time={job.createdAt} />
            </Flex>
          </Flex>
        </Flex>
        <Flex mt={1} flex={1} flexDirection="column">
          <GrayText>Run Command</GrayText>
          <Flex align="center" display="inline-flex">
            <Code> {job.command} </Code>
          </Flex>
        </Flex>
        {!!job.exitCode && (
          <Flex flex={1} flexDirection="column">
            <GrayText>Exit Code</GrayText>
            <Flex align="center" display="inline-flex">
              {job.exitCode}
            </Flex>
          </Flex>
        )}
      </Card>
      <Card flexDir="column" p={4} mb={4}>
        <Flex alignItems="center">
          <Heading size="md">Job Logs</Heading>
        </Flex>
        {job.logs?.expired && (
          <ZWarn error="These logs have expired. Logs are only available until a container is destroyed." />
        )}
        <JobLogs job={job} />
      </Card>
    </Stack>
  );
};

const JobLogs: React.FC<{
  job: NonNullable<ProjectJobQuery["currentUser"]["repo"]>["jobRun"];
}> = ({ job }) => {
  const [searching, setSearching] = useState(false);

  return (
    <LogsConsole
      searching={searching}
      setSearching={setSearching}
      lines={job.logs?.entries ?? []}
      isLive={!job.logs?.completed}
    />
  );
};
