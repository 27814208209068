"use client";

import Editor, { EditorProps } from "@monaco-editor/react";
import { FieldError } from "react-hook-form";
import { useMonacoTheme } from "../hooks/useMonacoTheme";
import "./EditorInput.scss";

interface EditorInputProps extends EditorProps {
  value?: string;
  error?: FieldError;
  minHeight?: string;
  readOnly?: boolean;
}

export const EditorInput: React.FC<EditorInputProps> = ({
  value,
  error,
  minHeight,
  readOnly,
  ...props
}) => {
  useMonacoTheme();

  return (
    <Editor
      theme="zeet-dark"
      defaultLanguage="yaml"
      options={{
        lineNumbers: "off",
        glyphMargin: false,
        lineDecorationsWidth: 0,
        lineNumbersMinChars: 0,
        minimap: {
          enabled: false,
        },
        scrollbar: {
          alwaysConsumeMouseWheel: false,
        },
        readOnly: readOnly || false,
      }}
      defaultValue={value}
      wrapperProps={{
        "data-testid": props["data-testid"] ?? "editor",
        className: `monaco-editor-wrapper ${error?.message ? "error" : ""} ${
          readOnly ? "disabled-editor" : ""
        }`,
        style: {
          minHeight: minHeight ?? "100px",
        },
      }}
      {...props}
    />
  );
};
