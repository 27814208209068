import {
  Divider,
  Flex,
  Heading,
  Image,
  ListItem,
  Text,
} from "@chakra-ui/react";
import { useUserContainerRegistriesQuery } from "@zeet/web-api/dist/graphql";
import {
  ComboBox,
  Select,
  useColors,
  useCurrentTeamUser,
  useTrack,
} from "@zeet/web-ui";
import React, { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FaDocker } from "react-icons/fa";
import { NewResourceValues } from "~/features/Project/New/context";
import { PaginationControls } from "../../RepositorySelect/PaginationControls";
import {
  useDockerSearch,
  useDockerTagSearch,
} from "../../RepositorySelect/useDockerSearch";

function useIsPrivateRegistry(dockerImageInput: string | undefined) {
  const [isPrivateRegistry, setIsPrivateRegistry] = useState(false);

  useEffect(() => {
    if (
      dockerImageInput?.includes(".") &&
      !dockerImageInput.startsWith("docker.io")
    ) {
      setIsPrivateRegistry(true);
    } else {
      setIsPrivateRegistry(false);
    }
  }, [dockerImageInput]);

  return isPrivateRegistry;
}

interface DockerImageComboboxProps {
  initialImage?: string;
  onSelect: (dockerImage?: string) => void;
}

const DockerImageCombobox: React.FC<DockerImageComboboxProps> = ({
  initialImage,
  onSelect,
  ...restProps
}) => {
  const { track } = useTrack();
  const { brand } = useColors();
  const [dockerImageInput, setDockerImageInput] = useState<string>(
    initialImage || ""
  );
  const isPrivateRegistry = useIsPrivateRegistry(dockerImageInput);

  let searchInput = dockerImageInput;
  if (searchInput?.startsWith("docker.io/")) {
    searchInput = searchInput.split("/")[1] ?? "";
  }
  const { loading, items, totalPages, currentPage, setCurrentPage } =
    useDockerSearch(searchInput || "");

  return (
    <ComboBox
      {...restProps}
      isPrivateRegistry={isPrivateRegistry}
      inputProps={{ placeholder: "Docker image repository" }}
      renderListItem={(item, spreadProps, isHighlighted) => {
        const logoUrl =
          item.logo_url.large ||
          item.logo_url["small@2x"] ||
          item.logo_url.small;
        return (
          <ListItem
            display="flex"
            justifyContent="flex-start"
            key={item.slug}
            bg={isHighlighted ? brand : ""}
            color={isHighlighted ? "white" : ""}
            {...spreadProps}
            px={4}
            mx={-2}
            my={2}
            py={2}
            cursor="pointer"
            borderRadius="md"
            transition="background-color 150ms, color 150ms"
          >
            <Flex flexShrink={0} mr={8}>
              {logoUrl ? (
                <Image src={logoUrl} w="2.5rem" h="2.5rem" />
              ) : (
                <FaDocker size="2.5rem" />
              )}
            </Flex>
            <Flex direction="column" flexShrink={1}>
              <Heading maxW="100%" noOfLines={1} size="sm" as="h4">
                {item.name}
              </Heading>
              {item.short_description && (
                <Text maxW="100%" noOfLines={2} fontSize="sm">
                  {item.short_description}
                </Text>
              )}
            </Flex>
          </ListItem>
        );
      }}
      items={items}
      isLoading={loading}
      initialInputValue={dockerImageInput}
      onInputValueChange={({ inputValue }) => {
        onSelect(inputValue || "");
        setDockerImageInput(inputValue || "");
      }}
      onSelectedItemChange={({ inputValue }) => {
        const slug = items.filter(
          (i) => i.name === inputValue || i.slug === inputValue
        )?.[0]?.slug;
        onSelect(slug);
        track("select_docker_image", { image: slug });
      }}
      itemToString={(item) => (item && item.slug) || ""}
      pagination={
        totalPages > 1 ? (
          <>
            <Divider />
            <PaginationControls
              totalPages={totalPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </>
        ) : null
      }
    />
  );
};

interface DockerTagComboboxProps {
  dockerImage?: string;
  initialTag?: string;
  onSelect: (dockerTag?: string) => void;
}

const DockerTagCombobox: React.FC<DockerTagComboboxProps> = ({
  dockerImage,
  initialTag,
  onSelect,
  ...restProps
}) => {
  const { track } = useTrack();
  const [dockerTagInput, setDockerTagInput] = useState<string>(
    initialTag || "latest"
  );
  const {
    loading: tagLoading,
    items: tagItems,
    totalPages: tagTotalPages,
    currentPage: tagCurrentPage,
    setCurrentPage: tagSetCurrentPage,
  } = useDockerTagSearch(dockerImage, dockerTagInput || "");

  const isPrivateRegistry = useIsPrivateRegistry(dockerImage);
  const { brand } = useColors();

  return (
    <ComboBox
      {...restProps}
      isPrivateRegistry={isPrivateRegistry}
      inputProps={{
        placeholder: `Select a valid tag of ${dockerImage}`,
        isDisabled: !dockerImage,
      }}
      renderListItem={(item, spreadProps, isHighlighted) => {
        return (
          <ListItem
            display="flex"
            justifyContent="flex-start"
            key={item.name}
            bg={isHighlighted ? brand : ""}
            color={isHighlighted ? "white" : ""}
            {...spreadProps}
            px={4}
            mx={-2}
            my={2}
            py={2}
            cursor="pointer"
            borderRadius="md"
            transition="background-color 150ms, color 150ms"
          >
            <Flex direction="column" flexShrink={1}>
              <Heading maxW="100%" noOfLines={1} size="sm" as="h4">
                {item.name}
              </Heading>
            </Flex>
          </ListItem>
        );
      }}
      items={tagItems}
      isLoading={tagLoading}
      initialInputValue={dockerTagInput}
      onInputValueChange={({ inputValue }) => {
        onSelect(inputValue || "");
        setDockerTagInput(inputValue || "");
      }}
      onSelectedItemChange={({ inputValue }) => {
        onSelect(inputValue);
        track("select_docker_tag", {
          image: dockerImage,
          tag: inputValue,
        });
      }}
      itemToString={(item) => (item && item.name) || ""}
      pagination={
        tagTotalPages > 1 ? (
          <>
            <Divider />
            <PaginationControls
              totalPages={tagTotalPages}
              currentPage={tagCurrentPage}
              setCurrentPage={tagSetCurrentPage}
            />
          </>
        ) : null
      }
    />
  );
};

const DockerRegistryInput = () => {
  const currentTeamUser = useCurrentTeamUser();
  const { data: crData } = useUserContainerRegistriesQuery({
    variables: {
      id: currentTeamUser.id,
    },
  });

  const { setValue } = useFormContext<NewResourceValues>();

  return (
    // cannot {...register} on custom <select /> component. causes duplicate onChange
    <Select
      onChange={(v) => {
        setValue("source.containerRegistry.registryID", v?.value);
      }}
      options={crData?.user.containerRegistries?.map((cr) => {
        return { value: cr.id, label: cr.name };
      })}
    />
  );
};

export function useDockerComboBox(
  initialImage?: string,
  initialTag = "latest"
): [
  string | undefined,
  string | undefined,
  {
    DockerImageInput: React.FC<{ isRelative?: true }>;
    DockerTagInput: React.FC<{ isRelative?: true }>;
    DockerRegistryInput: React.FC<{ isRelative?: true }>;
  }
] {
  const [selectedDockerImage, setSelectedDockerImage] = useState<
    string | undefined
  >(initialImage);
  const [selectedDockerTag, setSelectedDockerTag] = useState<
    string | undefined
  >(initialTag);

  const DockerImageInput: React.FC<{ isRelative?: true }> = useMemo(
    () => (props) => {
      return (
        <DockerImageCombobox
          {...props}
          initialImage={initialImage}
          onSelect={(value) => {
            setSelectedDockerImage(value);
            setSelectedDockerTag("latest");
          }}
        />
      );
    },
    // we need memo to prevent re-creation of component on initial image change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const DockerTagInput: React.FC<{ isRelative?: true }> = useMemo(
    () => (props) => {
      return (
        <DockerTagCombobox
          {...props}
          dockerImage={selectedDockerImage}
          initialTag={selectedDockerTag}
          onSelect={setSelectedDockerTag}
        />
      );
    },
    // we need memo to prevent re-creation of component on initial tag change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedDockerImage, setSelectedDockerTag]
  );

  return [
    selectedDockerImage,
    selectedDockerTag,
    { DockerImageInput, DockerTagInput, DockerRegistryInput },
  ];
}
