import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Flex,
  Heading,
  Icon,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {
  WorkflowRunStepDetailFragment,
  WorkflowRunStepStatus,
} from "@zeet/web-api/dist/graphqlv1";
import { Button, EmptyBox, useColors } from "@zeet/web-ui";
import { FC, useRef } from "react";
import { IconType } from "react-icons";
import { MdCheckCircle, MdWarning } from "react-icons/md";
import { useDeleteProject } from "../../SettingsTab/sections/DangerSettings/useDeleteProject";
import { GenericRunStepNestedDetail } from "./GenericRunStepNestedDetail";

interface DestroyRunStepDetailProps {
  step: WorkflowRunStepDetailFragment;
  runId: string;
  projectId: string;
}

const DeleteProjectDialog: FC<{
  onClose: () => void;
  isOpen: boolean;
  projectId: string;
}> = ({ onClose, isOpen, projectId }) => {
  const cancelRef = useRef(null);

  const { deleteProject, loading: deleteProjectLoading } = useDeleteProject();

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      preserveScrollBarGap
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogCloseButton />
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Are you sure?
          </AlertDialogHeader>

          <AlertDialogBody>
            <Text>Once you delete a project, there is no going back.</Text>

            <Flex my="4" mt="6" gap="2">
              <Button flex="1" variant="secondary" onClick={onClose}>
                Cancel
              </Button>
              <Button
                variant="error"
                flex="1"
                opacity={0.8}
                isLoading={deleteProjectLoading}
                onClick={async () => {
                  await deleteProject({
                    variables: {
                      id: projectId,
                      force: true,
                    },
                  });
                }}
              >
                Confirm Delete
              </Button>
            </Flex>
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

const DestroyHero = ({
  projectId,
  title,
  description,
  actionTitle,
  icon,
}: {
  title: string;
  actionTitle: string;
  description: string;
  icon: IconType;
  projectId: string;
}) => {
  const { fg2 } = useColors();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Heading size="sm" mb={4} opacity={0.5} mt={4}>
        Next Steps
      </Heading>
      <EmptyBox flexDirection="column" minHeight={"300px"} mt={4}>
        <Icon color={fg2} fontSize="48px" as={icon} />
        <Heading fontSize="1.8em" fontWeight={400} mt={4} mb={2}>
          {title}
        </Heading>
        <Text color={fg2} textAlign="center" maxWidth="550px">
          {description}
        </Text>

        <Button variant="error" mt={6} onClick={onOpen}>
          {actionTitle}
        </Button>

        <DeleteProjectDialog
          isOpen={isOpen}
          onClose={onClose}
          projectId={projectId}
        />
      </EmptyBox>
    </>
  );
};

const DestroyFailed = ({ projectId }: DestroyRunStepDetailProps) => {
  return (
    <DestroyHero
      projectId={projectId}
      title="Destroy Failed"
      description="You can still force delete this project, which will remove the project
    from Zeet, but retain any cloud resources that were not destroyed."
      actionTitle="Force Delete Project"
      icon={MdWarning}
    />
  );
};

const DestroyComplete = ({ projectId }: DestroyRunStepDetailProps) => {
  return (
    <DestroyHero
      projectId={projectId}
      title="Destroy Complete"
      description="Project cloud resources have been successfully destroyed. You can now safely
      delete this project from Zeet."
      actionTitle="Delete Project"
      icon={MdCheckCircle}
    />
  );
};

const DestroyStepRunAction = ({
  step,
  runId,
  projectId,
}: DestroyRunStepDetailProps) => {
  if (
    step.status === WorkflowRunStepStatus.Failed ||
    step.status === WorkflowRunStepStatus.Error ||
    step.status === WorkflowRunStepStatus.Aborted
  ) {
    return <DestroyFailed runId={runId} step={step} projectId={projectId} />;
  }

  if (step.status === WorkflowRunStepStatus.Completed) {
    return <DestroyComplete runId={runId} step={step} projectId={projectId} />;
  }

  return null;
};

export const DestroyStepRunDetail = ({
  step,
  runId,
  projectId,
}: DestroyRunStepDetailProps) => {
  return (
    <GenericRunStepNestedDetail
      title="Destroy"
      step={step}
      runId={runId}
      projectId={projectId}
    >
      <DestroyStepRunAction runId={runId} step={step} projectId={projectId} />
    </GenericRunStepNestedDetail>
  );
};
