export const getEmptyMetricsArray = (): (Date | number)[][] => {
  const emptyArray = [...Array(1440)];
  const metricsArray: (number | Date)[][] = [];
  emptyArray.map(
    (_, index) =>
      (metricsArray[index] = [
        new Date(Date.now() - 1000 * 60 * (index + 1)),
        0,
      ])
  );
  return metricsArray;
};
