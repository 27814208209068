import { useProjectDeployRevisionsQuery } from "@zeet/web-api/dist/graphqlv1";
import { Container, useCurrentTeamUser, usePagination } from "@zeet/web-ui";
import { RevisionsList } from "~/components/Revisions";
import { ProjectDetailTabProps } from "..";
import { TabWrapper } from "../components/TabWrapper";

const DeploymentRevisionsTabContent = ({ project }: ProjectDetailTabProps) => {
  const { id } = useCurrentTeamUser();
  const { pageInput, setPage } = usePagination();

  const { data, loading } = useProjectDeployRevisionsQuery({
    variables: { projectId: project.id, teamId: id, page: pageInput },
  });

  const revisions = data?.team?.project?.deploys.nodes?.length
    ? data.team.project.deploys.nodes[0]?.configurationRevisions
    : null;

  return (
    <Container fullWidth>
      <RevisionsList
        revisions={revisions}
        pageChanged={setPage}
        loading={loading}
      />
    </Container>
  );
};

export const DeploymentRevisionsTab = () => {
  return <TabWrapper component={DeploymentRevisionsTabContent} />;
};
