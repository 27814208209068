import React from "react";
import { ListViewContext } from "./ListViewContext";

export const useListViewContext = () => {
  const contextData = React.useContext(ListViewContext);

  if (!contextData) {
    throw new Error(
      "useListViewContext should not be used outside of ListViewProvider"
    );
  }

  return contextData;
};
