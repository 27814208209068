import { AspectRatio, Stack } from "@chakra-ui/react";
import {
  DeploymentDetailFragment,
  LogEntry,
  useDeploymentBuildLogsAddedSubscription,
  useDeploymentBuildLogsQuery,
} from "@zeet/web-api/dist/graphql";
import {
  Link,
  SearchableProps,
  Skeleton,
  useCurrentTeamUser,
  ZError,
  ZWarn,
} from "@zeet/web-ui";
import { default as React, useEffect, useState } from "react";
import { LogsConsole } from "~/components/LogsConsole/LogsConsole";
import { sampleLogs } from "~/data/sample-project-logs";
import { isSampleProject } from "~/features/Project/New/makeCreateSampleProjectInput";
export const DeploymentBuildLogs: React.FC<
  {
    deployment: DeploymentDetailFragment;
  } & SearchableProps
> = ({ deployment, setSearching, searching }) => {
  const [init, setInit] = useState(false);
  const [entries, setEntries] = useState<LogEntry[]>([]);
  const [needsCredentials, setNeedsCredentials] = useState(false);
  const teamUser = useCurrentTeamUser();

  const sampleLogEntries: LogEntry[] = sampleLogs
    .split("\n")
    .map((text) => ({ text: text || "\n" }));

  const checkIfCredentialsNeeded = (lines: string) => {
    if (!/error building image.*TOOMANYREQUESTS/.test(lines)) return; // cspell:disable-line
    setNeedsCredentials(true);
  };

  //initial query
  const { data, loading, error, refetch } = useDeploymentBuildLogsQuery({
    errorPolicy: "all",
    variables: {
      id: deployment.id,
    },
  });

  //subscription
  const { error: subscriptionError } = useDeploymentBuildLogsAddedSubscription({
    variables: {
      input: {
        deploymentID: deployment.id,
      },
    },

    //when we finish the subscription we just refetch the query => we should end up in a "completed" state
    onSubscriptionComplete: () => {
      refetch();
    },
    //skip this entirely while we're loading or if we know the subscription is done already
    skip:
      (!data && loading) ||
      data?.currentUser.deployment?.build?.logs?.completed,
    onSubscriptionData: ({ subscriptionData: { data, error } }) => {
      if (error) {
        console.error("sub ondata error", error);
      }
      if (!init) {
        setEntries([]);
        setInit(true);
      }
      if (data?.deploymentBuildLogsAdded.text) {
        const newText = data.deploymentBuildLogsAdded.text.trimEnd() || "";
        checkIfCredentialsNeeded(newText);
        setEntries([...entries, { text: newText }]);
      }
    },
  });

  if (error) {
    console.error("build logs query err", error);
  }
  if (subscriptionError) {
    console.error("build logs sub err", subscriptionError);
  }

  useEffect(() => {
    if (data?.currentUser.deployment?.build?.logs) {
      setEntries(data.currentUser.deployment.build.logs.entries ?? []);
      data.currentUser.deployment.build.logs.entries?.forEach((line) => {
        checkIfCredentialsNeeded(line.text);
      });
    }
  }, [data, deployment.id]);

  if (loading) {
    return (
      <AspectRatio ratio={16 / 9} w="100%">
        <Skeleton speed={3} height="100%" />
      </AspectRatio>
    );
  }

  return (
    <Stack>
      <ZError
        error={data?.currentUser?.deployment?.build?.errorMessage}
        borderRadius={0}
      />
      {data?.currentUser.deployment?.build?.logs?.expired && (
        <ZWarn
          borderRadius={0}
          error="These logs have expired. Logs are only available until a container is destroyed."
        />
      )}
      {needsCredentials && (
        <ZWarn borderRadius={0}>
          You&apos;ve reached your image pull limit. To increase it,
          <Link
            color="brandVar"
            ml={1}
            to={`/${teamUser.login}/account/container-registries`}
          >
            add Docker Hub credentials
          </Link>
          .
        </ZWarn>
      )}
      <LogsConsole
        height="600px"
        isLive={!data?.currentUser.deployment?.build?.logs?.completed}
        lines={
          isSampleProject(deployment.repo?.name) ? sampleLogEntries : entries
        }
        setSearching={setSearching}
        searching={searching}
      />
    </Stack>
  );
};
