import { Box, Flex, Grid, Spacer, Text } from "@chakra-ui/react";
import {
  useWorkflowRunDetailQuery,
  useWorkflowRunsQuery,
  WorkflowRunStatus,
  WorkflowRunStepStatus,
} from "@zeet/web-api/dist/graphqlv1";
import {
  Button,
  CenterLoading,
  Container,
  NotFound404,
  useColors,
  useCurrentTeamUser,
  useQueryParams,
  ZAlert,
  ZError,
} from "@zeet/web-ui";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { BackNavButton } from "../components/BackNavLayout";
import { StatusText } from "../components/StatusText";
import { workflowRunStatusMap } from "../utils";
import { SidebarList } from "./Steps/SidebarList";
import { WorkflowActions } from "./WorkflowActions";
import {
  isApprovalStepRunning,
  WorkflowApprovalBanner,
} from "./WorkflowApprovalBanner";
import { WorkflowRunLayout } from "./WorkflowRunLayout";

interface WorkflowRunDetailProps {
  projectId: string;
  runId: string;
  isOverview?: boolean;
}

export const WorkflowRunDetail = ({
  projectId,
  runId,
  isOverview,
}: WorkflowRunDetailProps) => {
  const { id: teamId } = useCurrentTeamUser();
  const query = useQueryParams();
  const stepId = query.get("stepId");
  const { bg2 } = useColors();
  const { pathname } = useLocation();
  const stepIndex = useMemo(() => parseInt(stepId ?? ""), [stepId]);

  const { data: workflowRunsData } = useWorkflowRunsQuery({
    variables: {
      projectId,
      teamId,
      page: { after: "0", first: 20 },
    },
  });

  const { data, loading } = useWorkflowRunDetailQuery({
    variables: {
      projectId,
      runId,
      teamId,
    },
    pollInterval: 3000,
  });

  if (loading) return <CenterLoading />;

  const workflowRun = data?.team?.project?.workflow?.run;

  if (!workflowRun) {
    return (
      <NotFound404
        heading="404, workflow not found"
        text="Oops! We couldn't find this workflow."
      />
    );
  }

  if (!workflowRun.steps || !data?.team?.project?.workflow?.id) {
    return <ZError m={4}>Failed to fetch workflow steps</ZError>;
  }

  const activeStep = workflowRun.steps.find(
    (s) => s?.sequenceNumber === stepIndex
  );
  const statusInfo = workflowRunStatusMap[workflowRun.status];

  const latestWorkflowRun =
    workflowRunsData?.team?.project?.workflow?.runs.nodes?.[0];

  const blockingWorkflowRun =
    workflowRunsData?.team?.project?.workflow?.runs.nodes.find((run) => {
      return (
        run.sequence < workflowRun.sequence &&
        run.status === WorkflowRunStatus.InProgress
      );
    });

  const isWorkflowBlocked =
    !!blockingWorkflowRun && workflowRun.status !== WorkflowRunStatus.Aborted;

  const isLatestWorkflowRun =
    (latestWorkflowRun?.sequence ?? 0) <= workflowRun.sequence ||
    workflowRun.status === WorkflowRunStatus.InProgress;

  return (
    <>
      {!isOverview && (
        <>
          <Container
            fullWidth
            background={bg2}
            px={10}
            py={4}
            borderBottomWidth="1px"
            gridColumnStart={1}
            gridColumnEnd={3}
            alignItems="center"
            display="flex"
            mt={-6}
          >
            <Flex direction="column">
              <BackNavButton
                mt={2}
                linkText="Back to workflow runs"
                linkTo={pathname}
              />
              <Text
                fontSize="1.25em"
                fontWeight={600}
                mt={3}
                data-testid="workflow-title"
              >
                Workflow Run #{workflowRun.sequence}
              </Text>
            </Flex>
            <Spacer />
            <StatusText
              color={statusInfo.themeColor}
              text={statusInfo.text}
              mr={4}
            />
            <WorkflowActions
              projectId={projectId}
              status={workflowRun.status}
              runId={workflowRun.id}
              workflowId={data?.team?.project?.workflow?.id}
            />
          </Container>
          <Container fullWidth px={6}>
            {isApprovalStepRunning(
              workflowRunsData?.team?.project?.workflow?.runs.nodes
            ) &&
              latestWorkflowRun?.id === workflowRun.id && (
                <WorkflowApprovalBanner
                  data={workflowRunsData}
                  projectID={projectId}
                  planLink={`${pathname}?workflowId=${
                    latestWorkflowRun?.id
                  }&showPlanLogs=true&stepId=${workflowRun.steps.findIndex(
                    (s) => s?.status === WorkflowRunStepStatus.Running
                  )}`}
                  borderRadius="6px"
                  mt={4}
                />
              )}
            {isWorkflowBlocked && (
              <ZAlert
                mx={0}
                mt={4}
                error="Another workflow run is blocking this one from executing"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Button
                  variant="ghost"
                  size="sm"
                  ml={1}
                  asLink
                  to={`${pathname}?workflowId=${blockingWorkflowRun?.id}`}
                >
                  View blocking workflow run
                </Button>
              </ZAlert>
            )}
            {!isLatestWorkflowRun && (
              <ZAlert
                mx={0}
                mt={4}
                error="This is not the latest workflow run for this project"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Button
                  variant="ghost"
                  size="sm"
                  ml={1}
                  asLink
                  to={`${pathname}?workflowId=${latestWorkflowRun?.id}`}
                >
                  View latest workflow run
                </Button>
              </ZAlert>
            )}
          </Container>
        </>
      )}
      <Container fullWidth>
        <Grid
          height="100%"
          width="100%"
          templateColumns="250px 1fr"
          templateRows="auto"
        >
          <SidebarList workflowRun={workflowRun} activeStep={activeStep} />
          <Box py={4}>
            <WorkflowRunLayout
              workflowRun={workflowRun}
              workflowId={data?.team?.project?.workflow?.id}
              activeStep={activeStep}
              projectId={projectId}
            />
          </Box>
        </Grid>
      </Container>
    </>
  );
};
