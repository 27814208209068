import { CopyIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import {
  RepoDetailFragment,
  RepoPrometheusQuery,
} from "@zeet/web-api/dist/graphql";
import { Link } from "@zeet/web-ui";
import copy from "copy-to-clipboard";
import React, { useMemo } from "react";
import { CoolInput } from "../Project/Settings/CoolInput";

export const PrometheusModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  repo: RepoDetailFragment;
  queryType: string;
  query?: string;
  prometheusData: RepoPrometheusQuery | undefined;
}> = ({ isOpen, onClose, repo, queryType, query, prometheusData }) => {
  const prometheusURL = useMemo(() => {
    const queryMap = {
      memory: `/graph?${new URLSearchParams({
        "g0.tab": "0",
        "g0.stacked": "0",
        "g0.show_exemplars": "0",
        "g0.range_input": "1h",
        "g0.expr": `container_memory_working_set_bytes{pod=~"${prometheusData?.user.repo?.kappID}.*", image!="", container_name!="POD"}`,
      })}`,
      cpu: `/graph?${new URLSearchParams({
        "g0.tab": "0",
        "g0.stacked": "0",
        "g0.show_exemplars": "0",
        "g0.range_input": "1h",
        "g0.expr": `rate(container_cpu_usage_seconds_total{pod=~"${prometheusData?.user.repo?.kappID}.*", image!="", container_name!="POD"}[5m])`,
      })}`,
    };
    const baseURL = prometheusData?.user.cluster?.prometheus?.url;
    let urlDeepLink = queryMap[queryType];

    if (query) {
      urlDeepLink = `/graph?${new URLSearchParams({
        "g0.tab": "0",
        "g0.stacked": "0",
        "g0.show_exemplars": "0",
        "g0.range_input": "1h",
        "g0.expr": query,
      })}`;
    }

    return baseURL + urlDeepLink;
  }, [prometheusData, queryType, query]);

  const user = prometheusData?.user.cluster?.prometheus?.user;
  const password = prometheusData?.user.cluster?.prometheus?.password;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      isCentered
      preserveScrollBarGap={true}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Prometheus Instructions</ModalHeader>
        <ModalBody>
          {(repo?.cluster?.awsAccount ||
            repo?.cluster?.gcpAccount ||
            repo?.cluster?.doAccount ||
            repo?.cluster?.linodeAccount) && (
            <Box pb={4}>
              <Text>
                Enter the following credentials to access your prometheus
                metrics
              </Text>
              <Flex mt="16px" alignItems="center">
                <Flex
                  fontWeight="semibold"
                  as="dt"
                  minWidth="180px"
                  marginRight="4rem"
                  height="2rem"
                  alignItems="center"
                >
                  User
                </Flex>
                <Input value={user || ""} isReadOnly={true} height="36px" />
                <CopyIcon
                  ml={2}
                  _hover={{ cursor: "pointer" }}
                  onClick={() => copy(user || "")}
                />
              </Flex>
              <Flex mt={3} alignItems="center">
                <Flex
                  fontWeight="semibold"
                  as="dt"
                  minWidth="180px"
                  marginRight="4rem"
                  height="2rem"
                  alignItems="center"
                >
                  Password
                </Flex>
                <CoolInput
                  isDisabled={false}
                  value={password || ""}
                  isReadOnly={true}
                  height="36px"
                />
                <CopyIcon
                  ml={2}
                  _hover={{ cursor: "pointer" }}
                  onClick={() => copy(password || "")}
                />
              </Flex>
              <Flex justifyContent="center" mt={6}>
                <Button
                  size="sm"
                  as={Link}
                  _hover={{ textDecoration: "none" }}
                  to={prometheusURL}
                  isAbsolute
                  rightIcon={<ExternalLinkIcon />}
                  bgColor="brand.500"
                  color="black"
                >
                  Go to Prometheus
                </Button>
              </Flex>
            </Box>
          )}
          {repo?.cluster?.cwAccount && (
            <Box pb={4}>
              <Text>
                Follow the link below and log into your coreweave account
              </Text>
              <Flex justifyContent="center" mt={6}>
                <Button
                  size="sm"
                  as={Link}
                  _hover={{ textDecoration: "none" }}
                  to={prometheusURL}
                  isAbsolute
                  rightIcon={<ExternalLinkIcon />}
                  bgColor="brand.500"
                  color="black"
                >
                  Go to Prometheus
                </Button>
              </Flex>
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
