import { DataTypes } from "@zeet/web-ui";
import { workflowRunStatusMap } from "../utils";

export enum WorkflowRunColumnId {
  Id = "workflowRunId",
  //TriggeredBy = "workflowRunTrigger",
  Status = "workflowRunStatus",
  CreatedAt = "workflowRunCreated",
}

export const workflowRunColumnIds = Object.values(WorkflowRunColumnId);
export const workflowRunColumns = [
  {
    id: WorkflowRunColumnId.Id,
    dataType: DataTypes.LINK,
    minWidth: 350,
    order: 1,
    label: "Workflow run",
    options: [],
  },
  {
    id: WorkflowRunColumnId.Status,
    dataType: DataTypes.BADGE,
    minWidth: 250,
    order: 3,
    label: "Status",
    options: [
      {
        statusMap: workflowRunStatusMap,
      },
    ],
  },
  {
    id: WorkflowRunColumnId.CreatedAt,
    dataType: DataTypes.TIMEAGO,
    minWidth: 180,
    order: 4,
    label: "Created",
    options: [],
  },
];
