import { Badge, BadgeProps, Text } from "@chakra-ui/react";

interface LabelBadgeProps {
  labelKey?: string | null;
  labelValue?: string;
}

export const LabelBadge: React.FC<LabelBadgeProps & BadgeProps> = ({
  labelKey,
  labelValue,
  ...props
}) => {
  if (!labelValue) {
    return null;
  }

  const type = labelKey ?? "type";

  return (
    <Badge
      borderRadius="6px"
      fontWeight="600"
      height="24px"
      fontSize="1em"
      mt="1"
      textTransform="lowercase"
      colorScheme="gray"
      {...props}
    >
      <Text as="span" fontWeight="400">
        {type}=
      </Text>
      {labelValue}
    </Badge>
  );
};
