import { ApolloError } from "@apollo/client";
import {
  CloudResourceType,
  DeploymentCommonFragment,
  DeploymentStatus,
  ProjectBasicFragment,
  ProjectV3AdapterConnectionListItemFragment,
  RepoBasicFragment,
  RepoDetailFragment,
  UpdateProjectSettingsMutation,
} from "@zeet/web-api/dist/graphql";
import { BuildImage } from "../types/deployment";

export const errorMessage =
  "Please provide a valid port number of 65535 or less";

export const portValidationFunction = (value: string | undefined) => {
  if (!value) {
    return true;
  }

  const parsedInt = parseInt(value);
  if (Number(value) === parsedInt && parsedInt < 65536 && parsedInt > -1) {
    return true;
  }

  if (value.split("-").length === 2) {
    const [start, end] = value.split("-").map((v) => parseInt(v));
    if (
      Number(value) === start &&
      start === end &&
      start < 65536 &&
      start > -1
    ) {
      return true;
    }
  }

  return errorMessage;
};

export const shouldDisplayError = (
  apolloError: ApolloError | undefined,
  data: UpdateProjectSettingsMutation | null | undefined
): boolean => {
  return !data && !!apolloError;
};

// add node provisioning:  "https://docs.zeet.co/troubleshoot/kubernetes/node-provisioning/",
export const troubleshootLinkMap = {
  [DeploymentStatus.DeployFailed]:
    "https://docs.zeet.co/troubleshoot/kubernetes/deploy/",
  [DeploymentStatus.BuildFailed]:
    "https://docs.zeet.co/troubleshoot/kubernetes/deploy/",
  [DeploymentStatus.DeployCrashing]:
    "https://docs.zeet.co/troubleshoot/kubernetes/deploy/",
};

export const makeAwsConsoleUrl = (
  resource: CloudResourceType,
  id: string,
  region: string
): string => {
  const base = `https://${region}.console.aws.amazon.com`;

  switch (resource) {
    case CloudResourceType.Disk:
      return `${base}/ec2/v2/home?VolumeDetails:volumeId=${region}&region=${region}#VolumeDetails:volumeId=${id}`;
    case CloudResourceType.Vpc:
      return `${base}/vpc/home?region=${region}#VpcDetails:VpcId=${id}`;
    case CloudResourceType.Machine:
      return `${base}/ec2/v2/home?region=${region}#InstanceDetails:instanceId=${id}`;
    case CloudResourceType.LoadBalancer:
      return `${base}/ec2/v2/home?region=${region}#LoadBalancers:sort=loadBalancerName`;
    case CloudResourceType.Cluster:
      return `${base}/eks/home?region=us-east-2#/clusters/${id}`;
  }
};

export const repoPath = (repo: RepoBasicFragment): string => {
  return `${repo.owner.login}/${repo.project?.name}/${repo.projectEnvironment?.name}/${repo.name}`;
};

export const projectV3Path = (
  projectV3Adapter: NonNullable<
    ProjectV3AdapterConnectionListItemFragment["nodes"]
  >[0],
  userLogin: string
): string => {
  return `${userLogin}/${projectV3Adapter.project?.name}/${projectV3Adapter.projectEnvironment?.name}/${projectV3Adapter.name}`;
};

export const projectPath = (project: ProjectBasicFragment): string => {
  return `${project.owner.login}/${project.name}`;
};

const getDockerTagFromURI = (dockerURL: string): string => {
  const reverseDockerURL = dockerURL.split("").reverse().join("");
  const colonIndex = dockerURL.length - reverseDockerURL.indexOf(":");

  return dockerURL.substring(colonIndex, dockerURL.length);
};

export const getBuildImagesArray = (
  image?: NonNullable<DeploymentCommonFragment["build"]>["image"],
  alternativeImages?: DeploymentCommonFragment["alternativeImages"]
): BuildImage[] => {
  const output: BuildImage[] = image
    ? [{ tag: getDockerTagFromURI(image), URI: image }]
    : [];

  alternativeImages?.map((ai) =>
    output.push({
      tag: ai.tag,
      URI: ai.reference,
    })
  );

  return output;
};

export const getPrivateEndpointFQDN = (repo?: RepoDetailFragment) => {
  const privateEndpoint = repo?.productionDeployment?.privateEndpoint;
  const namespace = repo?.namespace;

  if (!privateEndpoint || !namespace) {
    return null;
  }

  // TODO: FQDN should be provided by server
  return `${privateEndpoint}.${namespace}.svc.cluster.local`;
};
