import { Box, Flex } from "@chakra-ui/react";
import { NavHead } from "~/components/Layouts/NavTab";
import { DisplayName } from "../../../../Layouts/Settings";

interface CloudNameProps {
  logo: React.ReactNode;
}

export const CloudName: React.FC<CloudNameProps> = ({ logo }) => {
  return (
    <NavHead my={0} mb={0}>
      <Flex gap={4} alignItems="center">
        <Box mt="1">{logo}</Box>
        <DisplayName />
      </Flex>
    </NavHead>
  );
};
