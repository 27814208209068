import { ApolloError, ApolloQueryResult } from "@apollo/client";
import {
  TeamMemberRole,
  UserBillingOverviewQuery,
  UserProductCatalogQuery,
  useUserBillingOverviewQuery,
  useUserProductCatalogQuery,
  useUserTeamMemberQuery,
} from "@zeet/web-api/dist/graphql";
import { useCurrentTeam, useCurrentTeamUser } from "@zeet/web-ui";
import React, { createContext, useContext, useMemo } from "react";

interface BillingOverviewContextValues {
  userBillingOverview?: UserBillingOverviewQuery;
  loadingBillingOverview?: boolean;
  errorBillingOverview?: ApolloError | undefined;
  isOwnerOrAdmin?: boolean;
  dataProductCatalog?: UserProductCatalogQuery;
  loadingProductCatalog?: boolean;
  errorProductCatalog?: ApolloError | undefined;
}

interface BillingOverviewContextActions {
  refetchProductCatalog: () => void;
  refetchBillingOverview: () => Promise<
    ApolloQueryResult<UserBillingOverviewQuery>
  >;
}
type BillingOverviewContext = [
  BillingOverviewContextValues,
  BillingOverviewContextActions
];

const noop = () => null;

const billingOverviewContext = createContext<BillingOverviewContext>([
  // default values
  {},
  {
    refetchProductCatalog: noop,
    refetchBillingOverview: () => {
      return new Promise((_, __) => {});
    },
  },
]);

export const BillingOverviewProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const currentTeamUser = useCurrentTeamUser();

  const {
    data,
    refetch: refetchBillingOverview,
    loading: loadingBillingOverview,
    error: errorBillingOverview,
  } = useUserBillingOverviewQuery({
    variables: {
      id: currentTeamUser?.id,
    },
    skip: !currentTeamUser?.id,
  });

  const currentTeam = useCurrentTeam();

  const { data: teamData } = useUserTeamMemberQuery({
    variables: {
      id: currentTeam?.id,
    },
  });

  const isOwnerOrAdmin = useMemo(() => {
    return [TeamMemberRole.Owner, TeamMemberRole.Admin].includes(
      teamData?.currentUser.team?.members.find(
        (mem) => mem.user.id === teamData.currentUser.id
      )?.role || TeamMemberRole.Viewer
    );
  }, [teamData]);

  const {
    data: dataProductCatalog,
    refetch: refetchProductCatalog,
    loading: loadingProductCatalog,
    error: errorProductCatalog,
  } = useUserProductCatalogQuery({
    variables: {
      forUserID: currentTeam?.user?.id,
    },
  });

  const actions: BillingOverviewContextActions = useMemo(
    () => ({
      refetchProductCatalog,
      refetchBillingOverview,
    }),
    [refetchProductCatalog, refetchBillingOverview]
  );

  return (
    <billingOverviewContext.Provider
      value={[
        {
          userBillingOverview: data,
          loadingBillingOverview,
          errorBillingOverview,
          isOwnerOrAdmin,
          dataProductCatalog: dataProductCatalog,
          loadingProductCatalog,
          errorProductCatalog,
        },
        actions,
      ]}
    >
      {children}
    </billingOverviewContext.Provider>
  );
};

export function useBillingOverviewContext(): BillingOverviewContext {
  return useContext(billingOverviewContext);
}
