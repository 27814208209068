import { Flex, Spacer, Tabs, useDisclosure } from "@chakra-ui/react";
import { Button, Container } from "@zeet/web-ui";
import { useMemo } from "react";
import { MdDriveFileMove } from "react-icons/md";
import { ProjectListView } from "~/components/Dashboard/ProjectListView";
import { GroupTitle } from "~/components/GroupTitle";
import { DetailHeader } from "~/components/Layouts/DetailHeader";
import { useCurrentTabIndex } from "~/components/Layouts/useCurrentTabIndex";
import { ProjectCountBadge } from "~/components/ProjectCountBadge";
import { NavTabPanels, NavTabType } from "../../Layouts/NavTab";
import { useEnv, useProject } from "../Provider";
import { projectPath } from "../util";
import { MoveAppToEnv } from "./MoveApp";
import Revisions from "./Revisions";
import Settings from "./Settings";

const Environment = () => {
  const project = useProject();
  const env = useEnv();

  const { onOpen, isOpen, onClose } = useDisclosure();

  const baseUrl = `/${projectPath(project)}/${env.name}`;

  const tabs = useMemo(
    (): NavTabType[] => [
      {
        label: (
          <Flex alignItems="center" gap={2}>
            Projects
            <ProjectCountBadge />
          </Flex>
        ),
        to: baseUrl,
        component: () => (
          <>
            <Spacer mt="4" />
            <ProjectListView
              actions={
                <>
                  <Button
                    variant="secondary"
                    leftIcon={<MdDriveFileMove />}
                    onClick={onOpen}
                  >
                    Move Project In
                  </Button>
                  <Button variant="primary" asLink to="/new">
                    New Project
                  </Button>
                </>
              }
            />
          </>
        ),
      },
      {
        label: "Revisions",
        routeKey: "revisions",
        to: `${baseUrl}/_revisions`,
        component: () => <Revisions env={{ ...env, projectId: project.id }} />,
      },
      {
        label: "Settings",
        routeKey: "settings",
        to: `${baseUrl}/_settings`,
        path: [`${baseUrl}/_settings`, `${baseUrl}/_settings/:tab`],
        component: () => <Settings />,
      },
    ],
    [baseUrl, env, onOpen, project.id]
  );

  const currentTabIndex = useCurrentTabIndex(tabs);

  return (
    <Tabs index={currentTabIndex} colorScheme="brand" isLazy>
      <MoveAppToEnv isOpen={isOpen} onClose={onClose} />

      <DetailHeader
        title={<GroupTitle name={env.name} />}
        tabs={tabs}
        fullWidth
      />

      <Container fullWidth>
        <NavTabPanels tabs={tabs} />
      </Container>
    </Tabs>
  );
};

export default Environment;
