import { Box, Heading, Icon, Text } from "@chakra-ui/react";
import {
  WorkflowRunDetailFragment,
  WorkflowRunStepDetailFragment,
} from "@zeet/web-api/dist/graphqlv1";
import { MdHome } from "react-icons/md";
import { useProjectPath } from "../../hooks/useProjectPath";
import { sortStepsBySequenceNumber, workflowActionTitleMap } from "../utils";
import { SidebarItem } from "./SidebarItem";
import { WorkflowStepStatusIcon } from "./WorkflowStepStatusIcon";

interface SidebarListProps {
  workflowRun: WorkflowRunDetailFragment;
  activeStep?: WorkflowRunStepDetailFragment | null;
}

export const SidebarList = ({ workflowRun, activeStep }: SidebarListProps) => {
  const path = useProjectPath();

  const buildStepDetailLink = (stepId: number) => {
    return `${path}/workflows?workflowId=${workflowRun.id}&stepId=${stepId}`;
  };

  const stepOverviewLink = `${path}/workflows?workflowId=${workflowRun.id}`;

  const steps = sortStepsBySequenceNumber(workflowRun?.steps);

  return (
    <Box flex="1" mt="5">
      <SidebarItem isActive={!activeStep} to={stepOverviewLink}>
        <Icon as={MdHome} mr={2} boxSize={5} />
        Overview
      </SidebarItem>
      <Heading fontSize="14px" my={2} mt={4} opacity={0.5}>
        Steps
      </Heading>
      {steps.map(
        (s, i) =>
          s && (
            <SidebarItem
              isActive={s.sequenceNumber === activeStep?.sequenceNumber}
              to={buildStepDetailLink(s.sequenceNumber)}
              key={i}
            >
              <WorkflowStepStatusIcon step={s} workflowRun={workflowRun} />
              <Text ml={1}>{workflowActionTitleMap[s.action]}</Text>
            </SidebarItem>
          )
      )}
    </Box>
  );
};
