import { Box, Flex, Icon } from "@chakra-ui/react";
import {
  Blueprint,
  BlueprintType,
  DeployTarget,
} from "@zeet/web-api/dist/graphql";
import { BlueprintType as BlueprintTypeV2 } from "@zeet/web-api/dist/graphqlv1";
// cspell:disable
import {
  SiAwslambda,
  SiGooglecloud,
  SiHelm,
  SiKubernetes,
  SiTerraform,
} from "react-icons/si";
// cspell:enable
import {
  BlueprintSlug,
  BridgeBlueprintType,
  LegacyBlueprintType,
} from "@zeet/web-ui";
import { IconType } from "react-icons";
import { ProjectListItem } from "~/components/Dashboard/ProjectV3Utils";
import { ReactComponent as JobContainerSvg } from "~/images/job-container.svg";
import { ReactComponent as ServiceContainerSvg } from "~/images/service-container.svg";

type ProjectDisplay = {
  icon: IconType | React.FC;
  name: string;
};

type ProjectDisplayType = {
  [key in BridgeBlueprintType]: ProjectDisplay;
};

type ProjectSlugType = {
  [key in BlueprintSlug]?: ProjectDisplay;
};

export const projectTypeDisplayFromSlug: ProjectSlugType = {
  [BlueprintSlug.Terraform]: {
    icon: SiTerraform,
    name: "Terraform Stack",
  },
  [BlueprintSlug.Manifest]: {
    icon: SiKubernetes,
    name: "Kubernetes Manifest",
  },
  [BlueprintSlug.Helm]: {
    icon: SiHelm,
    name: "Helm",
  },
  [BlueprintSlug.JobContainer]: {
    icon: JobContainerSvg,
    name: "Job Container",
  },
  [BlueprintSlug.ServiceContainer]: {
    icon: ServiceContainerSvg,
    name: "Service Container",
  },
  [BlueprintSlug.ServerlessFunction]: {
    icon: SiGooglecloud, // cspell:disable-line
    name: "Serverless Function",
  },
};

export const projectTypeDisplay: ProjectDisplayType = {
  [BlueprintType.Terraform]: {
    icon: SiTerraform,
    name: "Terraform Stack",
  },
  [BlueprintType.KubernetesManifest]: {
    icon: SiKubernetes,
    name: "Kubernetes Manifest",
  },
  [BlueprintType.ZeetKubernetes]: {
    icon: ServiceContainerSvg,
    name: "Service Container",
  },
  [BlueprintType.ZeetAwsLambda]: {
    icon: SiAwslambda, // cspell:disable-line
    name: "Serverless Function",
  },
  [BlueprintType.ZeetGcpCloudRun]: {
    icon: SiGooglecloud, // cspell:disable-line
    name: "Serverless Function",
  },
  [BlueprintType.Helm]: {
    icon: SiHelm,
    name: "Helm",
  },
  [LegacyBlueprintType.Terraform]: {
    icon: SiTerraform,
    name: "Terraform Stack",
  },
  [BlueprintTypeV2.AwsSam]: {
    icon: SiAwslambda, // cspell:disable-line
    name: "Serverless Function",
  },
  [BlueprintTypeV2.GcpCloudRun]: {
    icon: SiGooglecloud, // cspell:disable-line
    name: "Serverless Function",
  },
};

export const getProjectTypeForRepo = (
  repo: ProjectListItem["repo"]
): ProjectDisplay | undefined => {
  if (repo?.deployJob) {
    return {
      icon: JobContainerSvg,
      name: "Job Container",
    };
  }
  if (repo?.deployTarget === DeployTarget.Kubernetes) {
    return {
      icon: ServiceContainerSvg,
      name: "Service Container",
    };
  }
  if (repo?.deployTarget === DeployTarget.Terraform) {
    return {
      icon: SiTerraform,
      name: "Terraform Stack",
    };
  }
  if (repo?.deployTarget === DeployTarget.AwsSam) {
    return {
      icon: SiAwslambda, // cspell:disable-line
      name: "Serverless Function",
    };
  }
  if (repo?.deployTarget === DeployTarget.GcpCloudRun) {
    return {
      icon: SiGooglecloud, // cspell:disable-line
      name: "Serverless Function",
    };
  }
};

interface ProjectTypeCellProps {
  blueprint?: Blueprint;
  project?: ProjectListItem;
}

export const ProjectTypeCell = ({
  blueprint,
  project,
}: ProjectTypeCellProps) => {
  if (!blueprint && !project) {
    return null;
  }

  const projectType =
    projectTypeDisplay[blueprint?.type ?? ""] ??
    getProjectTypeForRepo(project?.repo);

  return (
    <Box overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
      <Flex alignItems="center">
        {projectType?.icon && (
          <Box mr="1">
            <Icon as={projectType?.icon} size="14" />
          </Box>
        )}
        {projectType?.name}
      </Flex>
    </Box>
  );
};
