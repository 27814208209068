import { Box, Code, CodeProps, Icon } from "@chakra-ui/react";
import React from "react";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { Copy, CopyProps, CopyStyleProps, DEFAULT_VARIANT } from "./Copy";
import { useCopyDisplayItemStyles } from "./hooks";

interface CopyCodeProps extends CopyStyleProps {
  value: string;
  variant?: CopyProps["variant"];
  codeProps?: CodeProps;
  copyMessage?: string;
  sensitive?: boolean;
}

export const CopyCode: React.FC<CopyCodeProps> = ({
  value,
  variant = DEFAULT_VARIANT,
  codeProps,
  sensitive,
  ...rest
}) => {
  const [displaySensitiveValue, setDisplaySensitiveValue] =
    React.useState<boolean>(false);
  const copyDisplayItemStyles = useCopyDisplayItemStyles(variant);

  return (
    <Box
      border="1px solid var(--chakra-colors-chakra-border-color)"
      borderRadius="md"
    >
      <Copy
        {...rest}
        text={value}
        variant={variant}
        display="flex"
        alignItems="center"
      >
        <Code {...copyDisplayItemStyles} {...codeProps} pl="0">
          {sensitive && !displaySensitiveValue
            ? "•".repeat(value.length)
            : value}
        </Code>
        {sensitive && (
          <Icon
            mr="4"
            boxSize={4}
            color="gray.500"
            as={displaySensitiveValue ? IoMdEye : IoMdEyeOff}
            onClick={() => setDisplaySensitiveValue(!displaySensitiveValue)}
          />
        )}
      </Copy>
    </Box>
  );
};
