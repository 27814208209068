import { ReactNode } from "react";
import {
  ListViewProviderProps,
  SetViewModeType,
  useListViewContext,
} from "./Provider";

interface ChildrenArgs {
  viewMode: ListViewProviderProps["defaultViewMode"];
  setViewMode: SetViewModeType;
}

interface ListViewViewManagerProps {
  children: ReactNode | ((args: ChildrenArgs) => ReactNode);
  visible?: ListViewProviderProps["defaultViewMode"];
}

export const ListViewViewManager = ({
  children,
  visible,
}: ListViewViewManagerProps) => {
  const { viewMode } = useListViewContext();
  return viewMode === visible ? <>{children}</> : null;
};
