import { Box, Flex, Text } from "@chakra-ui/react";
import { User as UserType } from "@zeet/web-api/dist/graphqlv1";
import { Avatar } from "@zeet/web-ui";
import { FC } from "react";
import { Column } from "react-table";

interface UserProps {
  user?: Partial<UserType>;
  column: Column;
}

export const User: FC<UserProps> = ({ user, column }) => {
  const getMaxColumnWidth = (width?: string | number, minWidth?: number) => {
    const widthNumber = parseInt((width ?? "0") as string);
    const minWidthNumber = minWidth ?? 0;
    const paddingRight = 60;

    if (widthNumber < minWidthNumber) {
      return minWidthNumber - paddingRight;
    }
    return widthNumber - paddingRight;
  };

  return (
    <Flex alignItems="center">
      <Avatar size="sm" user={user} />
      <Box ml="10px">
        <Text
          fontSize="14px"
          lineHeight="14px"
          maxW={getMaxColumnWidth(column.width, column.minWidth)}
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          {user?.name}
        </Text>
      </Box>
    </Flex>
  );
};
