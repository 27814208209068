import { Box, Flex, Heading, PropsOf, Text } from "@chakra-ui/react";
import { Button, EmptyBox } from "@zeet/web-ui";
import React from "react";

export const MetricNotEnabled: React.FC<
  PropsOf<typeof Box> & {
    onButtonClick?: () => void;
  }
> = ({ onButtonClick, ...props }) => {
  return (
    <>
      <EmptyBox
        justifyContent="center"
        alignItems="center"
        paddingBottom="2rem"
        flexDirection="column"
        m="5"
        minH="400px"
        {...props}
      >
        <Heading size="lg" mt="4">
          Metric service not connected
        </Heading>
        <Text
          fontSize="lg"
          mt="4"
          opacity="0.6"
          maxW="500px"
          textAlign="center"
        >
          Zeet uses a Prometheus component to fetch metrics from the Kubernetes
          cluster. Please configure your cluster and check Prometheus
          connectivity.
        </Text>
        <Flex gap="2">
          <Button variant="primary" mt="6" onClick={onButtonClick}>
            Configure Cluster
          </Button>
        </Flex>
      </EmptyBox>
    </>
  );
};
