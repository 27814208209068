import { WorkflowRunStatus } from "@zeet/web-api/dist/graphqlv1";
import { Button, ButtonProps } from "@zeet/web-ui";
import { useWorkflowOperations } from "./useWorkflowOperations";

interface WorkflowActionsProps {
  status: WorkflowRunStatus;
  workflowId: string;
  runId: string;
  projectId: string;
}

export const WorkflowActions = ({
  status,
  projectId,
  runId,
  workflowId,
}: WorkflowActionsProps) => {
  const { abort, abortLoading, submitRun, submitLoading } =
    useWorkflowOperations();

  if (
    status === WorkflowRunStatus.Completed ||
    status == WorkflowRunStatus.Aborted ||
    status == WorkflowRunStatus.Failed
  ) {
    return (
      <WorkflowActionButton
        isLoading={submitLoading}
        onClick={() => submitRun(projectId, workflowId)}
      >
        Re-run
      </WorkflowActionButton>
    );
  }

  return (
    <WorkflowActionButton
      isLoading={abortLoading}
      onClick={() => abort(projectId, runId)}
    >
      Abort
    </WorkflowActionButton>
  );
};

const WorkflowActionButton = ({
  children,
  ...rest
}: Omit<ButtonProps, "variant">) => {
  return (
    <Button variant="secondary" height="auto" py={1} {...rest}>
      {children}
    </Button>
  );
};
