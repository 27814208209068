import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tag,
} from "@chakra-ui/react";
import {
  ProjectEnvironmentsDocument,
  RepoListItemFragment,
  useCreateProjectEnvironmentMutation,
  useReposForProjectQuery,
} from "@zeet/web-api/dist/graphql";
import { ZError } from "@zeet/web-ui";
import {
  chakraComponents,
  GroupBase,
  OptionProps,
  Select,
} from "chakra-react-select";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useProject } from "./Provider";
import { projectPath } from "./util";

interface FormData {
  name: string;
  repos?: RepoListItemFragment[];
  copyRepos: boolean;
}

export const CreateProjectEnvironmentModal = ({
  isOpen,
  onClose,
  project,
  teamName,
}: {
  project: { name: string; id: string };
  teamName: string;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const projectContext = useProject();

  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
    control,
    watch,
  } = useForm<FormData>({ reValidateMode: "onBlur" });
  const copyRepos = watch("copyRepos");
  const repoRes = useReposForProjectQuery({
    skip: !copyRepos,
    variables: { project: project.name },
  });

  const history = useHistory();

  const [create, createRes] = useCreateProjectEnvironmentMutation({
    onCompleted: (d) => {
      if (!d) return;

      history.push(`/${teamName}/${project.name}`);
      onClose();
    },
    refetchQueries: [
      {
        query: ProjectEnvironmentsDocument,
        variables: { path: projectPath(projectContext) },
      },
    ],
  });

  const onSubmit = ({ name: envName, repos }: FormData) => {
    create({
      variables: {
        input: {
          name: envName,
          projectID: project.id,
          repos: repos?.map((a) => a.id) || [],
        },
      },
    });
  };

  const onCloseAndNavigate = () => {
    onClose();
    history.push(`/${teamName}/${project.name}`);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} preserveScrollBarGap>
      <ModalOverlay />
      <ModalContent p={8}>
        <ModalHeader p={0}>Create Sub-Group</ModalHeader>

        <Box pt={8}>
          {createRes.error?.message && (
            <Box pb={8}>
              <ZError error={createRes.error.message} />
            </Box>
          )}

          <form
            onSubmit={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleSubmit(onSubmit)(e);
            }}
          >
            <FormControl
              isInvalid={!!formErrors.name}
              as={Flex}
              flexDir="column"
            >
              <FormLabel htmlFor={"name"}>Name</FormLabel>
              <Input
                placeholder={"Name"}
                {...register("name", {
                  required: "Name is required",
                })}
              />
              <FormErrorMessage>
                {formErrors.name && formErrors.name.message}
              </FormErrorMessage>
            </FormControl>

            <Box my={4}>
              <Checkbox {...register("copyRepos")}>
                Copy existing Projects
              </Checkbox>

              {copyRepos && (
                <Box mt={4} mb={8}>
                  <FormLabel htmlFor={"repos"}>Copy Projects</FormLabel>
                  <Controller
                    control={control}
                    name="repos"
                    rules={{
                      validate: {
                        hasNoDuplicates: (v) => {
                          if (!v || v.length == 0) {
                            return true;
                          }
                          if (containsDuplicateRepos(v)) {
                            return false;
                          }
                        },
                      },
                    }}
                    render={({ field }) => (
                      <FormControl isInvalid={!!formErrors.repos}>
                        <Select
                          errorBorderColor="blue"
                          isLoading={repoRes.loading}
                          options={repoRes.data?.project?.repos.map((r) => ({
                            value: r.id,
                            ...r,
                          }))}
                          getOptionLabel={(r) => r.name}
                          isMulti
                          components={{ Option: SelectOption }}
                          {...field}
                        />
                        <FormErrorMessage>
                          {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          @ts-ignore */}
                          {formErrors.repos?.type == "hasNoDuplicates" &&
                            "You cannot copy multiple repos with the same name"}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  />
                </Box>
              )}
            </Box>

            <Flex justifyContent={"right"} gap={4} w={"100%"}>
              <Button onClick={onCloseAndNavigate}>Close</Button>
              <Button
                colorScheme={"brand"}
                isLoading={createRes.loading}
                type={"submit"}
                disabled={createRes.loading}
              >
                Create
              </Button>
            </Flex>
          </form>
        </Box>
      </ModalContent>
    </Modal>
  );
};

const SelectOption = ({
  children,
  ...props
}: OptionProps<
  RepoListItemFragment,
  true,
  GroupBase<RepoListItemFragment>
>) => {
  return (
    <chakraComponents.Option {...props}>
      <Flex w="100%" justifyContent={"space-between"}>
        {children}
        <Tag ml={2}>{props.data.projectEnvironment?.name}</Tag>
      </Flex>
    </chakraComponents.Option>
  );
};

const containsDuplicateRepos = (repos: RepoListItemFragment[]): boolean => {
  const repoNames = repos.map((r) => r.name);
  return new Set(repoNames).size !== repoNames.length;
};
