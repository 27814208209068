import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import { useClusterCommonQuery } from "@zeet/web-api/dist/graphql";
import {
  WorkflowRunStepDetailFragment,
  WorkflowRunStepStatus,
} from "@zeet/web-api/dist/graphqlv1";
import {
  ClusterStatusBadge,
  EmptyBox,
  getClusterStatus,
  Link,
  useCurrentTeamUser,
} from "@zeet/web-ui";

export type ClusterStep = Extract<
  WorkflowRunStepDetailFragment,
  { __typename?: "ClusterPrecheckStep" }
>;

interface ClusterPrecheckStepDetailProps {
  step: ClusterStep;
}

export const ClusterPrecheckStepDetail = ({
  step,
}: ClusterPrecheckStepDetailProps) => {
  const currentTeamUser = useCurrentTeamUser();

  const { data } = useClusterCommonQuery({
    variables: {
      id: currentTeamUser?.id,
      clusterId: step?.cluster?.id ?? "",
    },
    skip: !step?.cluster?.id,
  });

  if (step.status === WorkflowRunStepStatus.Completed) {
    return (
      <EmptyBox flexDirection="column" minHeight={"400px"} mt="1" ml="4">
        <Heading fontSize="1.8em" fontWeight={400}>
          Cluster precheck completed!
        </Heading>
      </EmptyBox>
    );
  }

  return (
    <EmptyBox flexDirection="column" minHeight={"400px"} mt="1" ml="4">
      <Flex alignItems="center">
        <Heading fontSize="1.8em" fontWeight={400}>
          Cluster Status:
        </Heading>
        <Box ml="12px">
          <ClusterStatusBadge
            status={getClusterStatus(data?.user.cluster)}
            height="36px"
            fontSize="24px"
          />
        </Box>
      </Flex>
      <Flex>
        <Text>
          Almost ready to deploy. Your cluster needs to be in a healthy state.
          See
          <Link
            to={`/${currentTeamUser?.login}/console/clusters/${step.cluster?.id}`}
            ml={1}
            textDecor="underline"
          >
            cluster details
          </Link>
        </Text>
      </Flex>
    </EmptyBox>
  );
};
