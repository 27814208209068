import { useToast } from "@chakra-ui/react";
import {
  ProjectDocument,
  useCreateResourceAlphaMutation,
} from "@zeet/web-api/dist/graphql";
import {
  BlueprintType,
  useCreateProjectMutation,
} from "@zeet/web-api/dist/graphqlv1";
import {
  AnalyticsEvent,
  BlueprintSlug,
  BridgeBlueprintType,
  isLegacyBlueprint,
  isLegacyDatabaseBlueprint,
  legacyAppBlueprints,
  LegacyBlueprintType,
  legacyDockerBlueprints,
  useCurrentTeamUser,
  useTrack,
} from "@zeet/web-ui";
import { useFormContext } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useWorkflowOperations } from "~/features/Project/Detail/WorkflowsTab/useWorkflowOperations";
import { useBillingOverviewContext } from "../../../components/Account/BillingV2/Providers/BillingOverviewProvider";
import { NewResourceValues } from "./context";
import { makeCreateProjectInput } from "./makeCreateProjectInput";
import { makeCreateResourceAlphaMutationInput } from "./makeCreateResourceAlphaInput";

const useDeploy = () => {
  const { track } = useTrack();

  const onError = (error) => {
    toast({
      title: "Failed to create resource",
      status: "error",
      description: error.message,
      duration: 5000,
      isClosable: true,
    });
  };

  const [createResourceAlpha, createResourceAlphaResult] =
    useCreateResourceAlphaMutation({ onError, errorPolicy: "none" });
  const [createProject, createProjectResult] = useCreateProjectMutation({
    onError,
    errorPolicy: "none",
  });

  const { submitRun, submitLoading } = useWorkflowOperations();

  const [, { refetchBillingOverview }] = useBillingOverviewContext();

  const currentUser = useCurrentTeamUser();
  const toast = useToast();
  const history = useHistory();
  const { getValues } = useFormContext<NewResourceValues>();

  const isLegacyProjectWithoutOnboarding = (
    blueprintType: BridgeBlueprintType
  ) => {
    return (
      isLegacyDatabaseBlueprint(getValues("blueprintSlug")) ||
      blueprintType === LegacyBlueprintType.Terraform
    );
  };

  const getRedirectPath = (
    values: NewResourceValues,
    options?: { asDraft?: boolean }
  ) => {
    const projectName = values.organize.projectName;
    const envName = values.organize.environmentName;
    const resourceName = values.organize.name;
    const blueprintType = getValues("blueprintType");
    const path = `/${currentUser.login}/${projectName}/${envName}/${resourceName}`;

    if (options?.asDraft) {
      return `${path}/settings`;
    }

    if (
      isLegacyProjectWithoutOnboarding(blueprintType) ||
      !values.target.provider?.connected ||
      values.blueprint?.slug === BlueprintSlug.JobContainer
    ) {
      return `${path}`;
    }

    return `${path}/onboard`;
  };

  const trackDeploy = (values: NewResourceValues) => {
    track(AnalyticsEvent.CREATE_PROJECT_SUCCESS, {
      blueprintSlug: values.blueprintSlug,
      blueprintId: values.blueprintID,
      target: values.target.provider?.provider,
      source: values.source,
    });

    if (isLegacyDatabaseBlueprint(values.blueprintSlug)) {
      track(AnalyticsEvent.DEPLOYED_DATABASE, {
        target: values.target.provider?.provider,
      });
    }

    if (legacyAppBlueprints.includes(values.blueprintSlug)) {
      track(AnalyticsEvent.DEPLOYED_REPOSITORY, {
        target: values.target.provider?.provider,
      });
    }

    if (legacyDockerBlueprints.includes(values.blueprintSlug)) {
      track(AnalyticsEvent.DEPLOYED_DOCKER, {
        target: values.target.provider?.provider,
      });
    }
  };

  const deploy = async (options?: { asDraft?: boolean }) => {
    const values = getValues();

    const refetchQueries = [
      {
        query: ProjectDocument,
        variables: {
          path: `${currentUser.login}/${values.organize.projectName}`,
        },
      },
    ];
    if (
      isLegacyBlueprint(values.blueprintSlug) ||
      values.blueprintType === BlueprintType.ZeetKubernetes
    ) {
      const input = makeCreateResourceAlphaMutationInput(values, {
        enabled: !options?.asDraft,
      });
      await createResourceAlpha({
        variables: { input },
        onCompleted: (data) => {
          if (data) {
            refetchBillingOverview();
            trackDeploy(values);
            const path = getRedirectPath(values, options);
            history.push(path);
          }
        },
        onError: (error) => {
          if (
            error.message.includes(
              "please attach a container registry to the cluster"
            )
          ) {
            refetchBillingOverview();
            trackDeploy(values);
            const path = getRedirectPath(values, options);
            history.push(path);
          } else if (error) {
            onError(error);
          }
        },
        refetchQueries,
        awaitRefetchQueries: true,
      });
    } else {
      const input = makeCreateProjectInput(values, {
        enabled: !options?.asDraft,
      });
      await createProject({
        variables: { input },
        onCompleted: async (data) => {
          if (data) {
            const group = values.organize.projectName;
            const subgroup = values.organize.environmentName;
            const project = data.createProject.name;
            const path = `/${currentUser.login}/${group}/${subgroup}/${project}`;

            if (!options?.asDraft) {
              const result = await submitRun(
                data.createProject.id,
                data.createProject?.workflow?.id ?? ""
              );
              const pathWithWorkflow = `${path}/workflow?workflowId=${result?.data?.submitWorkflow.id}`;
              history?.push(pathWithWorkflow);
            } else {
              history?.push(path);
            }
          }
        },
        refetchQueries,
        awaitRefetchQueries: true,
      });
    }
  };

  const getDeployIsLoading = () => {
    if (isLegacyBlueprint(getValues("blueprintSlug"))) {
      return createResourceAlphaResult.loading;
    }
    return createProjectResult.loading || submitLoading;
  };

  return {
    deploy,
    deployIsLoading: getDeployIsLoading(),
  };
};

export { useDeploy };
