import { Flag, useFeatureFlag } from "@zeet/web-ui";
import { IconType } from "react-icons";
import {
  BsClouds,
  BsFillChatLeftTextFill,
  BsFillFileBarGraphFill,
} from "react-icons/bs";
import { FaCubes } from "react-icons/fa";
import { FiSettings } from "react-icons/fi";
import { SiKubernetes } from "react-icons/si";
import peopleImageBlack from "~/images/sidebar/people-black.svg";
import peopleImageWhite from "~/images/sidebar/people-white.svg";
import rocketLaunchImageBlack from "~/images/sidebar/rocket-launch-black.svg";
import rocketLaunchImageWhite from "~/images/sidebar/rocket-launch-white.svg";
import statusBlack from "~/images/sidebar/status-black.svg";
import statusWhite from "~/images/sidebar/status-white.svg";

type MenuItem = {
  name: string;
  link: string;
  iconWhite?: string;
  iconBlack?: string;
  icon?: IconType;
  isNew?: boolean;
};

export const useMenuItems = (
  teamName: string | null | undefined
): {
  topMenuItems: MenuItem[];
  bottomMenuItems: MenuItem[];
} => {
  const linkPrefix = `/${teamName}/console`;

  const topMenuItems: MenuItem[] = [
    {
      name: "Projects",
      link: `${linkPrefix}/home`,
      iconWhite: rocketLaunchImageWhite,
      iconBlack: rocketLaunchImageBlack,
    },
    {
      name: "Clouds",
      link: `${linkPrefix}/clouds/view`,
      icon: BsClouds,
    },
    {
      name: "Clusters",
      link: `${linkPrefix}/clusters/view`,
      icon: SiKubernetes,
    },
    {
      name: "Blueprints",
      link: `${linkPrefix}/blueprints`,
      icon: FaCubes,
    },
    {
      name: "Status",
      link: `${linkPrefix}/status`,
      iconWhite: statusWhite,
      iconBlack: statusBlack,
      isNew: true,
    },
    {
      name: "People",
      link: `${linkPrefix}/people/zeet`,
      iconWhite: peopleImageWhite,
      iconBlack: peopleImageBlack,
    },
  ];

  const hasSupportEnabled = useFeatureFlag(Flag.SupportCenter, {
    devEnabled: true,
  });

  const bottomMenuItems: MenuItem[] = [
    {
      name: "Usage",
      link: `/${teamName}/account/billing/overview`,
      icon: BsFillFileBarGraphFill,
    },
    ...(hasSupportEnabled
      ? [
          {
            name: "Support",
            link: `/${teamName}/support`,
            icon: BsFillChatLeftTextFill,
          },
        ]
      : []),
    {
      name: "Settings",
      link: `/${teamName}/account/profile`,
      icon: FiSettings,
    },
  ];

  return {
    topMenuItems,
    bottomMenuItems,
  };
};
