import { useCurrentTeamUser } from "@zeet/web-ui";
import { FC } from "react";
import { IClustersList } from "../../types/clouds-view";
import { LinkCell } from "./LinkCell";

export const ClustersCell: FC<IClustersList> = ({ clusters, cloudId }) => {
  const currentTeamUser = useCurrentTeamUser();

  return clusters.length > 0 ? (
    <LinkCell
      isExternal={false}
      to={`/${currentTeamUser.login}/console/clusters/view?clusterCloudId=${cloudId}`}
    >
      {clusters.length}
    </LinkCell>
  ) : (
    <>0</>
  );
};
