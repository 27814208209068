import {
  Box,
  Button,
  Code,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  useProjectEnvironmentsAndRepoCountQuery,
  useUpdateProjectEnvironmentMutation,
} from "@zeet/web-api/dist/graphql";
import { useDeleteSubGroupMutation } from "@zeet/web-api/dist/graphqlv1";
import { CenterLoading, DeleteDialog, useTrack, ZError } from "@zeet/web-ui";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { RiDeleteBinLine } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import { FormCard } from "../../FormCard";
import { useEnv, useProject } from "../Provider";
import { projectPath } from "../util";

interface FormData {
  name: string;
}

const DangerSettings = () => {
  const [updateEnv, { loading, error }] = useUpdateProjectEnvironmentMutation();
  const env = useEnv();
  const project = useProject();
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
  } = useForm<FormData>({ defaultValues: { name: env.name } });
  const toast = useToast();
  const history = useHistory();

  useEffect(() => {
    if (error) {
      toast({
        title: "Failed to update environment",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [error, toast]);

  const onSubmit = (data: FormData) => {
    updateEnv({
      variables: {
        input: {
          id: env.id,
          name: data.name,
        },
      },
      onCompleted(data) {
        if (data) {
          toast({
            title: "Environment updated",
            status: "success",
            duration: 5000,
            isClosable: true,
          });

          history.push(
            `/${projectPath(project)}/${
              data.updateProjectEnvironment.name
            }/_settings/danger`
          );
        }
      },
    });
  };

  return (
    <FormCard
      description={
        "Changing any of these values will invalidate all previous Zeet Dashboard URLs for this Sub-Group & its Project pages."
      }
      title="Sub-Group Settings"
      onSubmit={(e) => handleSubmit(onSubmit)(e)}
      isLoading={loading}
    >
      <Stack direction="row" alignItems="center" spacing={8}>
        <Text whiteSpace={"nowrap"}>Sub-Group name</Text>
        <FormControl isInvalid={!!formErrors.name}>
          <Input {...register("name", { required: "A name is required" })} />
          {formErrors.name && (
            <FormErrorMessage>{formErrors.name.message}</FormErrorMessage>
          )}
        </FormControl>
      </Stack>
    </FormCard>
  );
};

const DeleteEnvironment = () => {
  const history = useHistory();
  const env = useEnv();
  const project = useProject();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const envsRes = useProjectEnvironmentsAndRepoCountQuery({
    variables: { path: projectPath(project) },
  });
  const isDisabled = envsRes.data?.project?.environments.length === 1;

  const [deleteSubGroup, { loading }] = useDeleteSubGroupMutation({
    errorPolicy: "none",
    update(cache) {
      cache.modify({
        id: cache.identify(env),
        fields(_, details) {
          return details.DELETE;
        },
      });
    },
  });

  const { track } = useTrack();

  if (envsRes.loading) {
    return <CenterLoading />;
  }
  if (envsRes.error) {
    return <ZError error={envsRes.error} />;
  }

  return (
    <>
      <Flex
        justifyContent="center"
        p={4}
        flexDir={"column"}
        alignItems="center"
        gap={4}
        textAlign="center"
      >
        <Button
          alignSelf="center"
          leftIcon={<RiDeleteBinLine size="1.5rem" />}
          p={8}
          shadow="md"
          disabled={isDisabled}
          borderRadius={4}
          loadingText="Deleting..."
          display="flex"
          colorScheme="red"
          isLoading={loading}
          onClick={() => {
            onOpen();
            track("click_project_environment_delete");
          }}
        >
          Delete Sub-Group
        </Button>
      </Flex>
      <DeleteDialog
        cantDeleteMessage={
          isDisabled && (
            <Text>
              This Sub-Group can&apos;t be deleted because it&apos;s the only
              Sub-Group in Project <Code>{project.name}</Code>.
            </Text>
          )
        }
        confirmationString={env.name}
        onClose={onClose}
        onDelete={() => {
          track("click_project_environment_delete_confirm");
          return deleteSubGroup({ variables: { id: env.id } });
        }}
        onSuccess={() => {
          history.push(`/${projectPath(project)}`);
        }}
        isLoading={loading}
        isOpen={isOpen}
        type="sub-group"
        warningMessage={
          <Box>
            This action cannot be undone. We will attempt to delete all Projects
            in <Text as="b">{env.name}</Text>. It may take a long time to fully
            delete the Sub-Group.
          </Box>
        }
      />
    </>
  );
};

export const Danger = () => {
  return (
    <Stack spacing={8}>
      <DangerSettings />

      <DeleteEnvironment />
    </Stack>
  );
};
