import { Button, Divider, Flex, Heading, Text } from "@chakra-ui/react";
import { Card, useColors } from "@zeet/web-ui";
import React from "react";

interface FormCardProps {
  title: string;
  description?: string | JSX.Element;
  formHint?: string | JSX.Element;
  buttonLabel?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  noButton?: boolean;
  onSubmit: (event: React.FormEvent<HTMLDivElement>) => Promise<void> | void;
  children?: React.ReactNode;
  buttonTestId?: string;
}

export const FormCard: React.FC<FormCardProps> = ({
  title,
  description,
  onSubmit,
  formHint,
  buttonLabel = "Save",
  isDisabled,
  isLoading,
  children,
  noButton,
  buttonTestId,
}) => {
  const { bg } = useColors();
  return (
    <Card pt="0" width="100%">
      <Flex
        as="form"
        onSubmit={(e) => {
          e.stopPropagation();
          e.preventDefault();

          onSubmit(e);
        }}
        flexDir="column"
      >
        <Flex flexDir="column" padding={4}>
          <Heading mb={3} size="md">
            {title}
          </Heading>
          {description && (
            <Text mb={3} fontSize="sm">
              {description}
            </Text>
          )}
          {children}
        </Flex>
        <Divider />
        <Flex bg={bg} as="footer" py={3} px={6} align="center" overflowX="auto">
          {formHint &&
            (typeof formHint === "string" ? (
              <Text w="md" opacity={0.7} fontSize="sm" mr={4}>
                {formHint}
              </Text>
            ) : (
              formHint
            ))}
          {!noButton && (
            <Button
              type="submit"
              isDisabled={isDisabled}
              isLoading={isLoading}
              colorScheme="brand"
              px={6}
              size="sm"
              ml="auto"
              data-testid={buttonTestId}
            >
              {buttonLabel}
            </Button>
          )}
        </Flex>
      </Flex>
    </Card>
  );
};
