import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";
import {
  ProjectLinkDetailFragment,
  ProjectLinkType,
  ProjectWithNameFragment,
  useLinkProjectMutation,
  useProjectLinkedProjectsQuery,
  useUnlinkProjectMutation,
} from "@zeet/web-api/dist/graphqlv1";
import {
  AnalyticsEvent,
  CenterLoading,
  CopyCode,
  FormSelect,
  useCurrentTeamUser,
  useTrack,
} from "@zeet/web-ui";
import { Fragment, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useProjectV3Context } from "../ProjectV3Provider";

interface LinkProjectForm {
  providerId: string;
  envPrefix: string;
}

const getDefaultEnvPrefixFromType = (linkType: ProjectLinkType) => {
  return linkType.toUpperCase() + "_";
};

const LinkProjectContent = ({
  onLink,
  linkType,
  linkedProjects,
  availableProviders,
  loadingLinkProject,
  currentProjectId,
}: {
  onLink: (providerId: string, envPrefix: string) => void;
  linkType: ProjectLinkType;
  linkedProjects: ProjectLinkDetailFragment[];
  availableProviders: ProjectWithNameFragment[];
  loadingLinkProject: boolean;
  currentProjectId: string;
}) => {
  const {
    register,
    formState: { errors: formErrors },
    getValues,
    setValue,
  } = useForm<LinkProjectForm>({
    defaultValues: {
      providerId: "",
      envPrefix: getDefaultEnvPrefixFromType(linkType),
    },
  });

  const availableProvidersMemoed = useMemo(() => {
    return availableProviders
      ?.filter((e) => {
        return (
          currentProjectId !== e.id &&
          !linkedProjects.find((el) => {
            return el.provider.id === e.id;
          })
        );
      })
      .map((element) => ({
        value: element.id,
        label: element.name,
      }));
  }, [availableProviders, currentProjectId, linkedProjects]);

  const onSubmit = (values: LinkProjectForm) => {
    onLink(values.providerId, values.envPrefix);
    setValue("providerId", "");
  };

  return (
    <Flex flexDir="column" gap="4" mb="4">
      <Flex flexDir="column" gap="2">
        <Text fontWeight="bold">Select Project</Text>
        <FormControl isRequired isInvalid={!!formErrors?.providerId?.message}>
          <FormSelect
            {...register("providerId", {
              required: true,
            })}
            placeholder="--Select--"
            options={availableProvidersMemoed}
          />
        </FormControl>
      </Flex>
      <Flex flexDir="column" gap="2">
        <Text fontWeight="bold">Environment Variable Prefix</Text>
        <FormControl isInvalid={!!formErrors.envPrefix?.message} isRequired>
          <Input
            {...register("envPrefix", {
              required: true,
              validate: (envPrefix) => {
                return (
                  !linkedProjects.find(
                    (element) => element.envPrefix === envPrefix
                  ) ||
                  "Please use an env prefix not used by another linked project"
                );
              },
            })}
          />
          <FormErrorMessage>{formErrors.envPrefix?.message}</FormErrorMessage>
        </FormControl>
      </Flex>
      <Flex justifyContent="flex-end" gap="2">
        <Button
          isLoading={loadingLinkProject}
          size="sm"
          colorScheme="brand"
          onClick={() => onSubmit(getValues())}
        >
          Link Project
        </Button>
      </Flex>
    </Flex>
  );
};

const LinkedProjectContent = ({
  linkedProject,
  onUnlink,
}: {
  linkedProject: ProjectLinkDetailFragment;
  onUnlink: (projectLinkId: string) => void;
}) => {
  return (
    <Flex flexDir="column" gap="4" mb="4">
      <Flex flexDir="column" gap="2">
        <Text textAlign="right" fontWeight="bold">
          {linkedProject.provider.name}
        </Text>
        {linkedProject.envs ? (
          linkedProject.envs?.map((env, index) => (
            <Fragment key={index}>
              <Text>{env.name ?? ""}</Text>
              <CopyCode value={env.value ?? ""} />
            </Fragment>
          ))
        ) : (
          <Text>
            No environment variables shared from{" "}
            <strong>{linkedProject.provider.name}</strong>
          </Text>
        )}
        <Button
          isLoading={false}
          size="sm"
          onClick={() => onUnlink(linkedProject.id)}
        >
          Unlink Project
        </Button>
      </Flex>
    </Flex>
  );
};

const LinkedProjectsContent = ({
  linkedProjects,
  onUnlink,
  isLoading,
}: {
  linkedProjects: ProjectLinkDetailFragment[];
  onUnlink: (projectLinkId: string) => void;
  isLoading?: boolean;
}) => {
  if (isLoading) {
    return <CenterLoading />;
  }

  return (
    <Flex flexDir="column" gap="4" mb="4">
      <Flex flexDir="column" gap="2">
        <Text fontWeight="bold">Linked Projects</Text>
        <Divider />
        {linkedProjects.length ? (
          <Box>
            {linkedProjects.map((linkedProject, index) => (
              <LinkedProjectContent
                key={index}
                linkedProject={linkedProject}
                onUnlink={onUnlink}
              />
            ))}
          </Box>
        ) : (
          <Text>No linked project yet</Text>
        )}
      </Flex>
    </Flex>
  );
};

export const ProjectLinkContainer = ({
  linkType,
  availableProviders,
  onLink,
}: {
  linkType: ProjectLinkType;
  availableProviders: ProjectWithNameFragment[];
  onLink?: (linkId: string) => void;
}) => {
  const toast = useToast();
  const { track } = useTrack();

  const currentTeamUser = useCurrentTeamUser();
  const { projectData } = useProjectV3Context();
  const currentProjectId =
    projectData?.user.projectV3Adapters?.nodes?.[0]?.id ?? "";
  const {
    data,
    loading: loadingLinkedProviders,
    refetch: refetchLinkedProviders,
  } = useProjectLinkedProjectsQuery({
    variables: {
      linkType,
      teamId: currentTeamUser.id,
      projectId: currentProjectId,
    },
  });
  const linkedProjects = data?.team?.project?.linkedProjects.nodes ?? [];
  const [linkProject, { loading: loadingLinkProject }] = useLinkProjectMutation(
    {
      onCompleted: (data) => {
        if (data) {
          toast({
            title: "Project linked",
            status: "success",
            duration: 5000,
            isClosable: true,
          });

          refetchLinkedProviders({
            linkType,
            teamId: currentTeamUser.id,
            projectId: currentProjectId,
          });
        }
      },
      onError: (error) => {
        if (error) {
          track(AnalyticsEvent.LINK_PROJECT_ERROR, {
            error,
          });
        }
      },
    }
  );
  const [unlinkProject] = useUnlinkProjectMutation({
    onCompleted: (data) => {
      if (data) {
        toast({
          title: "Project unlinked",
          status: "success",
          duration: 5000,
          isClosable: true,
        });

        refetchLinkedProviders({
          linkType,
          teamId: currentTeamUser.id,
          projectId: currentProjectId,
        });
      }
    },
    onError: (error) => {
      if (error) {
        track(AnalyticsEvent.UNLINK_PROJECT_ERROR, {
          error,
        });
      }
    },
  });

  const handleLink = async (providerId: string, envPrefix: string) => {
    const linkedProject = await linkProject({
      variables: {
        input: {
          projectId: currentProjectId,
          providerId,
          linkType,
          envPrefix,
        },
      },
    });

    track(AnalyticsEvent.LINK_PROJECT_SUCCESS, {
      providerId,
      projectId: currentProjectId,
    });

    onLink?.(linkedProject.data?.linkProject.id ?? "");
  };

  const onUnlink = async (linkId: string) => {
    await unlinkProject({
      variables: {
        id: linkId,
      },
    });

    refetchLinkedProviders({
      linkType,
      teamId: currentTeamUser.id,
      projectId: currentProjectId,
    });

    track(AnalyticsEvent.UNLINK_PROJECT_SUCCESS, {
      linkId,
    });
  };

  return (
    <>
      <LinkProjectContent
        onLink={handleLink}
        availableProviders={availableProviders}
        linkedProjects={linkedProjects || []}
        linkType={linkType}
        loadingLinkProject={loadingLinkProject}
        currentProjectId={currentProjectId}
      />
      <LinkedProjectsContent
        isLoading={loadingLinkedProviders}
        linkedProjects={linkedProjects ?? []}
        onUnlink={onUnlink}
      />
    </>
  );
};
