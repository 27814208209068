import { css, Global } from "@emotion/react";
import React from "react";

export const GlobalStyles: React.FC = () => {
  const hideOutline = css`
    .js-focus-visible :focus:not([data-focus-visible-added]) {
      outline: none;
      box-shadow: none;
    }
  `;

  const scrollBar = css`
    ::-webkit-scrollbar {
      width: auto;
    }
    ::-webkit-scrollbar-track {
      background-color: var(--background-2);
    }
    ::-webkit-scrollbar-thumb {
      background-color: var(--accents-alpha);
      border: 4px solid transparent;
      border-radius: 7px;
      background-clip: content-box;
    }
    ::-webkit-scrollbar-corner {
      background-color: var(--background);
    },
  `;

  return (
    <>
      <Global styles={hideOutline} />
      <Global styles={scrollBar} />
    </>
  );
};
