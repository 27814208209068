import { Container as CContainer, ContainerProps } from "@chakra-ui/react";
import React from "react";

export const Container: React.FC<ContainerProps & { fullWidth?: boolean }> = ({
  children,
  fullWidth,
  ...restProps
}) => {
  return (
    <CContainer
      px="8"
      h="100%"
      maxW={fullWidth ? "100%" : "1300px"}
      overflowX={["auto", "hidden"]}
      {...restProps}
    >
      {children}
    </CContainer>
  );
};
