import { useProjectDeploysQuery } from "@zeet/web-api/dist/graphqlv1";
import { CenterLoading, Container, useCurrentTeamUser } from "@zeet/web-ui";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { SearchableListLayout } from "~/components/SearchableList";
import { ProjectDetailTabProps } from "../..";
import {
  columns,
  defaultColumnIds,
  formatProjectDeploysAsDataRow,
} from "./utils";

const DeployList = ({ project }: ProjectDetailTabProps) => {
  const currentUser = useCurrentTeamUser();
  const { pathname } = useLocation();
  const { data, loading } = useProjectDeploysQuery({
    variables: {
      teamId: currentUser?.id,
      projectId: project?.id,
    },
  });

  const deploys = data?.team?.project?.deploys?.nodes;

  const dataToRender = useMemo(() => {
    return formatProjectDeploysAsDataRow(pathname, deploys);
  }, [deploys, pathname]);

  if (loading) {
    return <CenterLoading />;
  }

  return (
    <Container fullWidth>
      <SearchableListLayout
        id="project-deployments"
        data={dataToRender}
        columns={columns}
        defaultColumnIds={defaultColumnIds}
      />
    </Container>
  );
};

export default DeployList;
