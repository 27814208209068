export const getDockerLink = (image: string): string => {
  if (image.includes(".")) {
    if (image.includes("elastic.co")) {
      return "https://www." + image.replace("/", "/r/");
    } else {
      return "https://" + image.split(":")[0];
    }
  } else if (image.includes("/")) {
    return `https://hub.docker.com/r/${image.split(":")[0]}`;
  } else {
    return `https://hub.docker.com/_/${image.split(":")[0]}`;
  }
};
