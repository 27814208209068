import { Box, Flex, PropsOf } from "@chakra-ui/react";

export const EmptyBox: React.FC<PropsOf<typeof Box>> = ({
  children,
  ...props
}) => {
  return (
    <Flex
      padding={4}
      marginTop={6}
      flex="1"
      minHeight="300px"
      border="1px solid var(--chakra-colors-chakra-border-color)"
      justifyContent="center"
      alignItems="center"
      borderRadius={10}
      {...props}
    >
      {children}
    </Flex>
  );
};
