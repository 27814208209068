import { useColorModeValue } from "@chakra-ui/react";
import { Link, useCurrentTeamUser, ZError, ZWarn } from "@zeet/web-ui";
import React from "react";
import { MdAutoAwesome } from "react-icons/md";

export const PendingClusterBanner: React.FC<{
  clusterId?: string;
}> = ({ clusterId }) => {
  const currentTeamUser = useCurrentTeamUser();

  if (!clusterId) return null;

  return (
    <ZError borderRadius="none">
      Your Project requires a healthy cluster to deploy. See
      <Link
        display="inline-block"
        to={`/${currentTeamUser.login}/console/clusters/${clusterId}`}
        textDecoration="underline"
        mx="1"
      >
        cluster details
      </Link>
      to learn more.
    </ZError>
  );
};

export const SampleProjectBanner = () => {
  const textColor = useColorModeValue("blue.400", "blue.200");

  return (
    <ZWarn
      borderRadius="none"
      status="info"
      icon={MdAutoAwesome}
      color={textColor}
    >
      This is a sample Project so feel free to play around with the settings.
      When you’re ready you can{" "}
      <Link
        display="inline-block"
        to={`/new`}
        textDecoration="underline"
        mx="1"
      >
        create a new Project
      </Link>
    </ZWarn>
  );
};
