import { Flex, FlexProps, Heading } from "@chakra-ui/react";
import { Tooltip } from "@zeet/web-ui";
import { createContext, useContext } from "react";
import {
  SettingsSectionDetails,
  SettingsSection as SettingsSectionUI,
} from "~/components/Layouts/Settings";
import { InputProvider } from "../inputs";
import { SettingsCard } from "./SettingsCard";

const SectionContext = createContext({ depth: 1 });

interface SettingsSectionProps extends FlexProps {
  name: string;
  description?: string;
  basePath?: string;
}

export const SettingsSection = ({
  name,
  description,
  basePath,
  children,
  ...rest
}: SettingsSectionProps) => {
  const { depth } = useContext(SectionContext);
  const content = (
    <SectionContext.Provider value={{ depth: depth + 1 }}>
      {children}
    </SectionContext.Provider>
  );

  const wrappedContent = (
    <Flex gap="4" flexDirection="column" {...rest}>
      {basePath ? (
        <InputProvider basePath={basePath}>{content}</InputProvider>
      ) : (
        content
      )}
    </Flex>
  );

  if (depth === 1) {
    return (
      <SettingsSectionUI {...rest}>
        <SettingsSectionDetails title={name} description={description} />
        {wrappedContent}
      </SettingsSectionUI>
    );
  }

  if (depth === 2) {
    return (
      <SettingsCard title={name} description={description} {...rest}>
        {wrappedContent}
      </SettingsCard>
    );
  }

  // 3+
  return (
    <Flex direction="column" {...rest}>
      <Heading size="sm" mb={3}>
        {name} {description && <Tooltip text={description} />}
      </Heading>
      {wrappedContent}
    </Flex>
  );
};
