import { Box, Heading, Icon } from "@chakra-ui/react";
import {
  useCreateDeployRunMutation,
  useProjectDeployDetailQuery,
} from "@zeet/web-api/dist/graphqlv1";
import { Button, CenterLoading, useCurrentTeamUser } from "@zeet/web-ui";
import { useMemo } from "react";
import { BsRocketTakeoffFill } from "react-icons/bs";
import { useLocation } from "react-router-dom";
import { SearchableListLayout } from "~/components/SearchableList";
import { ProjectDetailTabProps } from "../..";
import { BackNavLayout } from "../../components/BackNavLayout";
import { columns, defaultColumnIds, formatDataRow } from "./utils";

const DeploymentList = ({
  deployId,
}: ProjectDetailTabProps & { deployId: string }) => {
  const { pathname } = useLocation();
  const currentUser = useCurrentTeamUser();
  const { data, loading } = useProjectDeployDetailQuery({
    variables: {
      teamId: currentUser?.id,
      deployId,
    },
  });

  const [triggerDeploy, { loading: triggerDeployLoading }] =
    useCreateDeployRunMutation({
      variables: {
        input: {
          deployId,
        },
      },
    });

  const deploy = data?.team?.deploy;

  const dataToRender = useMemo(() => {
    return formatDataRow(pathname, deploy);
  }, [deploy, pathname]);

  if (loading) {
    return <CenterLoading />;
  }

  return (
    <BackNavLayout linkTo={pathname} linkText="Back to deploys">
      <Box mx={4}>
        <Heading size="md">{deploy?.name} history</Heading>
        <SearchableListLayout
          id="project-deploy-runs"
          data={dataToRender}
          columns={columns}
          defaultColumnIds={defaultColumnIds}
          actionButtons={
            <Button
              variant="primary"
              onClick={() => triggerDeploy()}
              isLoading={triggerDeployLoading}
            >
              <Icon as={BsRocketTakeoffFill} mr={1} /> Trigger Deploy
            </Button>
          }
        />
      </Box>
    </BackNavLayout>
  );
};

export default DeploymentList;
