import { Flex } from "@chakra-ui/react";
import { StatusBadge, StatusBadgeProps } from "@zeet/web-ui";
import { FC } from "react";

interface StatusCellProps {
  status: StatusBadgeProps["status"];
}

export const StatusCell: FC<StatusCellProps> = ({ status }) => (
  <Flex alignItems="center">
    <StatusBadge status={status} />
  </Flex>
);
