import {
  Box,
  Checkbox,
  Flex,
  FormControl,
  Input,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import {
  RepoDetailFragment,
  useUpdateProjectSettingsMutation,
} from "@zeet/web-api/dist/graphql";
import { Tooltip } from "@zeet/web-ui";
import React, { useState } from "react";
import { FormCard } from "../../FormCard";
import { ZFormLabel } from "./Build";

interface ManifestProjectSettingsProp {
  repo: RepoDetailFragment;
}

export const ManifestProjectSettings: React.FC<ManifestProjectSettingsProp> = ({
  repo,
}) => {
  const toast = useToast();
  const [changed, setChanged] = useState(false);
  const [kustomize, setKustomize] = useState(
    !!repo.manifest?.kustomizeDirs?.length
  );

  const [manifestDirectory, updateManifestDirectory] = useState(
    repo.manifest?.manifests ? repo.manifest?.manifests : "./"
  );

  const [kustomizeDirectory, updateKustomizeDirectory] = useState(
    repo.manifest?.kustomizeDirs ? repo.manifest?.kustomizeDirs : "./"
  );

  const [updateSettings, { loading: updateLoading }] =
    useUpdateProjectSettingsMutation({
      onCompleted: (data) => {
        if (data) {
          toast({
            title: "Manifest Settings Saved",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      },
    });

  return (
    <FormCard
      isLoading={updateLoading}
      title={"Manifest Settings"}
      isDisabled={!changed}
      onSubmit={() => {
        updateSettings({
          variables: {
            input: {
              id: repo.id,
              manifest: {
                [kustomize ? "kustomizeDirs" : "manifests"]: [
                  kustomize
                    ? (kustomizeDirectory as string)
                    : (manifestDirectory as string),
                ],
              },
            },
          },
        });
      }}
    >
      <FormControl>
        <Stack spacing={4}>
          {kustomize && (
            <Flex>
              <ZFormLabel>
                <Text as="h4" size="sm">
                  Kustomize Directory
                </Text>
                <Tooltip text="The directory (path/to/file) where your kustomization.yaml file resides" />
              </ZFormLabel>
              <Box flex={5.25}>
                <Input
                  value={`${kustomizeDirectory}`}
                  onChange={(e) => {
                    setChanged(true);
                    updateKustomizeDirectory(e.target.value);
                  }}
                />
              </Box>
            </Flex>
          )}
          {!kustomize && (
            <Flex>
              <ZFormLabel>
                <Text as="h4" size="sm">
                  File Path
                </Text>
                <Tooltip text="The directory (path/to/file) within your GitHub repository where your manifest(s) reside." />
              </ZFormLabel>
              <Box flex={5.25}>
                <Input
                  value={`${manifestDirectory}`}
                  onChange={(e) => {
                    setChanged(true);
                    updateManifestDirectory(e.target.value);
                  }}
                />
              </Box>
            </Flex>
          )}
          <Flex justifyContent="flex-end">
            <Checkbox
              onChange={() => {
                setKustomize(!kustomize);
                setChanged(true);
              }}
              defaultChecked={!!repo.manifest?.kustomizeDirs?.length}
            >
              Use Kustomize
            </Checkbox>
          </Flex>
        </Stack>
      </FormControl>
    </FormCard>
  );
};
