import {
  Button,
  Divider,
  Flex,
  Heading,
  Input,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import {
  RepoDetailFragment,
  useUpdateProjectSettingsMutation,
} from "@zeet/web-api/dist/graphql";
import { Card, Tooltip, useColors } from "@zeet/web-ui";
import React, { useEffect, useState } from "react";
import { CloudCostEstimate } from "~/components/CloudCost";

export const LambdaResourceInfo: React.FC<{ repo: RepoDetailFragment }> = ({
  repo,
}) => {
  // Lambda memory default value
  const [lambdaMemory, updateMemory] = useState(
    repo.serverlessResources?.memory ? repo.serverlessResources?.memory : 0.128
  );
  // Lambda CPU cores calculated from memory
  const [lambdaCPU, updateCPU] = useState(lambdaMemory * 0.5);
  // Estimated Lambda cost calculated from memory
  const [_, updateCost] = useState(lambdaMemory * 1000 * 0.0000000000163);

  useEffect(() => {
    if (lambdaMemory) {
      updateCost(lambdaMemory * 1000 * 0.0000000000163);
    }
  }, [lambdaMemory, updateCost]);

  const [changed, setChanged] = useState(false);
  const { bg } = useColors();
  const toast = useToast();

  const [updateProjectSettingsMutation] = useUpdateProjectSettingsMutation({
    onCompleted: (data) => {
      setChanged(false);
      if (data) {
        toast({
          title: "Lambda Settings Saved",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    },
  });

  return (
    <Card>
      <Flex flexDir="column" px={6} pt={6}>
        <Heading mb={3} size="md">
          <Flex justifyContent="space-between">
            <Text>{"Serverless Resources"}</Text>
          </Flex>
        </Heading>

        <Text fontSize="sm" mb={3}>
          {
            "You can configure how much Memory and CPU resources your Lambda functions run with. Increasing this will allow you to run more complex\
            applications on Lambda without bottlenecks. Note that Lambda is event-driven, and you only pay based on incoming requests."
          }
        </Text>
        <Stack spacing={2}>
          <Flex alignItems="center">
            <Text whiteSpace="nowrap" fontWeight="bold">
              General Resources
            </Text>
          </Flex>
          <Stack isInline spacing={[2, 2, 4]}>
            <Flex flex={1} alignItems="center">
              <Text mr={4} whiteSpace="nowrap">
                Memory (GB)
              </Text>
              <Input
                flexGrow={1}
                name="memory"
                className="nice-digits"
                defaultValue={lambdaMemory}
                onChange={(e) => {
                  setChanged(true);
                  updateMemory(Number(e.target.value));
                  updateCPU(Number(e.target.value) * 0.5);
                }}
              />
            </Flex>
            <Flex flex={1} alignItems="center">
              <Text mr={4} whiteSpace="nowrap">
                CPU Cores
              </Text>
              <Input
                flexGrow={1}
                name="cpu"
                className="nice-digits"
                isDisabled={true}
                value={lambdaCPU}
              />
              <Tooltip text="This is just an estimated value of number of CPU performance for your Lambda function based on your memory configuration." />
            </Flex>
          </Stack>
        </Stack>
      </Flex>
      <br />
      <Divider />
      <Flex bg={bg} as="footer" py={3} px={6} align="center">
        <Flex mt={0} alignItems="center">
          <CloudCostEstimate />
        </Flex>
        <Button
          type="submit"
          isDisabled={!changed}
          colorScheme="brand"
          px={6}
          size="sm"
          ml="auto"
          onClick={() => {
            updateProjectSettingsMutation({
              variables: {
                input: {
                  id: repo.id,
                  serverlessResources: {
                    memory: lambdaMemory,
                    cpu: lambdaCPU,
                  },
                },
              },
            });
          }}
        >
          {"Save"}
        </Button>
      </Flex>
    </Card>
  );
};
