import { useToast } from "@chakra-ui/react";
import { CloudProvider } from "@zeet/web-api/dist/graphql";
import { FC, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  SettingsNameFormFields,
  SettingsUnsavedChanges,
} from "../../../../Layouts/Settings";
import { useFormSubmission } from "../useFormSubmission";

export type FormValues = {
  [SettingsNameFormFields.NAME]: string;
};

interface FormProps {
  children: React.ReactNode;
  cloudId: string;
  cloudProvider: CloudProvider;
  defaultValues: FormValues;
  refetch: () => void;
}

export const Form: FC<FormProps> = ({
  children,
  cloudId,
  cloudProvider,
  defaultValues,
  refetch,
}) => {
  const formProviderProps = useForm<FormValues>({
    mode: "onBlur",
    defaultValues,
  });
  const {
    getValues,
    handleSubmit,
    formState: { isDirty, errors },
    reset,
  } = formProviderProps;
  const toast = useToast();

  const { onSubmit, isSubmitting } = useFormSubmission({
    cloudId,
    cloudProvider,
    refetch,
    reset: () => reset(getValues()),
  });

  useEffect(() => {
    const toastId = "unsaved-cloud-changes";

    if (isDirty || isSubmitting) {
      toast({
        id: toastId,
        title: "You have unsaved changes",
        duration: null,
        render: () => (
          <SettingsUnsavedChanges
            isSubmitting={isSubmitting}
            isDisabled={Object.keys(errors).length > 0}
            onSave={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleSubmit(onSubmit)(e);
            }}
          />
        ),
      });
    } else {
      toast.close(toastId);
    }

    return () => toast.close(toastId);
  }, [isDirty, isSubmitting, errors, toast, handleSubmit, onSubmit]);

  return (
    <FormProvider {...formProviderProps}>
      <form
        onSubmit={(e) => {
          e.stopPropagation();
          e.preventDefault();
          handleSubmit(onSubmit)(e);
        }}
      >
        {children}
      </form>
    </FormProvider>
  );
};
