import { ClusterCommonQuery, ClusterStatus } from "@zeet/web-api/dist/graphql";
import { Badge, BadgeProps } from ".";

export type ClusterStatusType = ClusterCommonQuery["user"]["cluster"] | string;

export const getClusterStatus = (
  cluster: ClusterCommonQuery["user"]["cluster"] | undefined
): ClusterStatus | string => {
  if (cluster?.isExternal && cluster.status === ClusterStatus.Pending) {
    return ClusterStatus.PendingSetup;
  }

  return cluster?.status || "";
};

const colorMap = {
  [ClusterStatus.Creating]: "yellow",
  [ClusterStatus.Deleting]: "gray",
  [ClusterStatus.Error]: "red",
  [ClusterStatus.ErrorDeleting]: "red",
  [ClusterStatus.Healthy]: "brand",
  [ClusterStatus.Pending]: "yellow",
  [ClusterStatus.PendingSetup]: "yellow",
  [ClusterStatus.Unreachable]: "red",
  pendingSetup: "yellow",
};

const textMap = {
  [ClusterStatus.Creating]: "Creating",
  [ClusterStatus.Deleting]: "Deleting",
  [ClusterStatus.Error]: "Error",
  [ClusterStatus.ErrorDeleting]: "Error Deleting",
  [ClusterStatus.Healthy]: "Healthy",
  [ClusterStatus.Pending]: "Pending",
  [ClusterStatus.PendingSetup]: "Setup Required",
  [ClusterStatus.Unreachable]: "Unreachable",
};

export const ClusterStatusBadge: React.FC<
  { status: ClusterStatus | string } & BadgeProps
> = ({ status: inputStatus, ...props }) => {
  const status = textMap[inputStatus];

  return (
    <Badge
      colorScheme={colorMap[inputStatus]}
      display="inline-flex"
      alignItems="center"
      {...props}
    >
      {status}
    </Badge>
  );
};
