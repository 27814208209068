import { Divider } from "@chakra-ui/react";
import {
  CombinedLambdaDeploymentMetricsUpdatedSubscription,
  RepoDetailFragment,
  useCombinedLambdaDeploymentMetricsUpdatedSubscription,
} from "@zeet/web-api/dist/graphql";
import { MetricType } from "@zeet/web-api/dist/graphqlv1";
import { useEffect, useMemo, useState } from "react";
import { adapterToMetricStream, MetricAggregationOption } from "./index";
import { MetricsChart } from "./MetricChart";
import { MetricsControlPanel } from "./MetricControlPanel";

export const LambdaMetrics: React.FC<{
  repo: RepoDetailFragment;
  deploymentID: string;
}> = ({ repo, deploymentID }) => {
  const combinedDeploymentId =
    deploymentID || repo.productionDeployment?.id || "";

  const { data, error, loading } =
    useCombinedLambdaDeploymentMetricsUpdatedSubscription({
      variables: { deploymentID: combinedDeploymentId },
      skip: !combinedDeploymentId,
    });

  const [metricsData, setMetricsData] = useState<
    CombinedLambdaDeploymentMetricsUpdatedSubscription | undefined
  >();

  useEffect(() => {
    if (!error) {
      setMetricsData(data);
    }
  }, [data, metricsData, error]);

  const metricsMap = useMemo(
    () => [
      {
        type: MetricType.FunctionInvocation,
        metrics: adapterToMetricStream(
          MetricType.FunctionInvocation,
          metricsData?.deploymentUpdated.invocations
        ),
      },
      {
        type: MetricType.FunctionError,
        metrics: adapterToMetricStream(
          MetricType.FunctionError,
          metricsData?.deploymentUpdated.errors
        ),
      },
      {
        type: MetricType.FunctionDuration,
        metrics: adapterToMetricStream(
          MetricType.FunctionDuration,
          metricsData?.deploymentUpdated.duration
        ),
      },
      {
        type: MetricType.FunctionConcurrent,
        metrics: adapterToMetricStream(
          MetricType.FunctionConcurrent,
          metricsData?.deploymentUpdated.concurrentExecutions
        ),
      },
    ],
    [metricsData]
  );

  return (
    <MetricsControlPanel
      isLoading={loading}
      ctas={
        metricsData?.deploymentUpdated.awsLinks?.cloudwatchMetrics
          ? [
              {
                link: metricsData?.deploymentUpdated.awsLinks
                  ?.cloudwatchMetrics,
                label: "View in AWS Cloudwatch",
              },
            ]
          : []
      }
    >
      {metricsMap
        .filter((metric) => !!metric.metrics)
        .map(({ type, metrics }, mi) => (
          <>
            {!!mi && <Divider mb={2} />}
            <MetricsChart
              px={3}
              key={mi}
              metricQuery=""
              metricType={type}
              metrics={metrics ?? []}
              aggregationType={MetricAggregationOption.UNIFIED}
              showViewMetricExternalButton={false}
            />
          </>
        ))}
    </MetricsControlPanel>
  );
};
