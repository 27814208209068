import { ApolloError } from "@apollo/client";
import { DeleteIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  IconButton,
  Input,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import {
  RepoDetailFragment,
  UpdateProjectSettingsMutation,
  useUpdateProjectSettingsMutation,
} from "@zeet/web-api/dist/graphql";
import React, { useEffect, useMemo } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { FormCard } from "../../../FormCard";
import { shouldDisplayError } from "../../util";

type Annotation = Record<string, string>;

const BaseAnnotationSettings: React.FC<{
  data: Annotation | null;
  onSubmit: (data: Annotation | null) => void;
  title: string;
  description: string;
  loading: boolean;
  error?: ApolloError | undefined;
  mutationResponse?: UpdateProjectSettingsMutation | null | undefined;
}> = ({
  data,
  title,
  description,
  onSubmit: _onSubmit,
  loading,
  error,
  mutationResponse,
}) => {
  const annotationsArray = useMemo(() => {
    if (!data) return [];
    const formatted: Array<{ key: string; value: string }> = [];

    Object.keys(data).forEach((k) => {
      const value = data[k];
      if (!value) {
        return;
      }

      formatted.push({
        key: k,
        value,
      });
    });
    return formatted;
  }, [data]);

  const { register, control, handleSubmit } = useForm({
    defaultValues: {
      annotations: annotationsArray,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "annotations",
  });

  const defaultFieldValues = useMemo(
    () => ({
      key: "",
      value: "",
    }),
    []
  );

  useEffect(() => {
    if (fields.length === 0) {
      append(defaultFieldValues, { shouldFocus: false });
    }
  }, [fields, append, defaultFieldValues]);

  const onSubmit = ({
    annotations: data,
  }: {
    annotations: Array<{ key: string; value: string }>;
  }): void => {
    // An empty object and null are serialized differently, we want null if data is empty
    let formatted: Annotation | null = null;
    if (data.filter((d) => d.key).length > 0) {
      formatted = {};
      data.forEach((v) => {
        if (v.key) {
          (formatted as Annotation)[v.key] = v.value;
        }
      });
    }

    _onSubmit(formatted);
  };

  if (error) {
    console.error(error);
  }

  return (
    <>
      <FormCard
        isLoading={loading}
        onSubmit={handleSubmit(onSubmit)}
        title={title}
        description={description}
      >
        <Flex flexDirection="column">
          <FormControl isInvalid={!!error}>
            <Stack spacing={4}>
              {fields.map((item, index) => (
                <Stack key={item.id} isInline spacing={4} alignItems="center">
                  <Text whiteSpace="nowrap"> Key </Text>
                  <Input
                    {...register(`annotations.${index}.key`)}
                    defaultValue={item.key}
                    placeholder="(e.g. serviceName)"
                  />

                  <Text whiteSpace="nowrap"> Value </Text>
                  <Input
                    {...register(`annotations.${index}.value`)}
                    defaultValue={item.value}
                    placeholder="(e.g. frontend)"
                  />

                  <IconButton
                    aria-label="delete"
                    icon={<DeleteIcon />}
                    onClick={() => remove(index)}
                  />
                </Stack>
              ))}
              {shouldDisplayError(error, mutationResponse) && (
                <FormErrorMessage>{error?.message}</FormErrorMessage>
              )}
              <Flex width="100%" justifyContent="space-between">
                <Button
                  colorScheme="brand"
                  onClick={() =>
                    append(defaultFieldValues, { shouldFocus: false })
                  }
                >
                  + Add More
                </Button>
              </Flex>
            </Stack>
          </FormControl>
        </Flex>
      </FormCard>
    </>
  );
};

export const PodAnnotationSettings: React.FC<{
  repo: RepoDetailFragment;
}> = ({ repo }) => {
  const toast = useToast();

  const parsed = useMemo(
    () => JSON.parse(repo.kubernetesCustomization?.podAnnotations ?? "{}"),
    [repo.kubernetesCustomization?.podAnnotations]
  );

  const [updateSettings, { error, loading, data }] =
    useUpdateProjectSettingsMutation({
      onCompleted: (data) => {
        if (data) {
          toast({
            title: "Pod Annotations Saved",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      },
    });

  const onSubmit = (data: Annotation | null) => {
    updateSettings({
      variables: {
        input: {
          id: repo.id,
          kubernetesCustomization: {
            podAnnotations: JSON.stringify(data),
          },
        },
      },
    });
  };

  return (
    <BaseAnnotationSettings
      data={parsed}
      onSubmit={onSubmit}
      title={"Pod annotations"}
      description={"Annotate all Zeet-controlled pods with a custom key/value"}
      loading={loading}
      error={error}
      mutationResponse={data}
    />
  );
};

export const ServiceAnnotationSettings: React.FC<{
  repo: RepoDetailFragment;
}> = ({ repo }) => {
  const toast = useToast();

  const parsed = useMemo(
    () => JSON.parse(repo.kubernetesCustomization?.serviceAnnotations ?? "{}"),
    [repo.kubernetesCustomization?.serviceAnnotations]
  );

  const [updateSettings, { error, loading, data }] =
    useUpdateProjectSettingsMutation({
      onCompleted: (data) => {
        if (data) {
          toast({
            title: "Service Annotations Saved",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      },
    });

  const onSubmit = (data: Annotation | null) => {
    updateSettings({
      variables: {
        input: {
          id: repo.id,
          kubernetesCustomization: {
            serviceAnnotations: JSON.stringify(data),
          },
        },
      },
    });
  };

  return (
    <BaseAnnotationSettings
      data={parsed}
      onSubmit={onSubmit}
      title={"Service annotations"}
      description={
        "Annotate all Zeet-controlled services with a custom key/value"
      }
      loading={loading}
      error={error}
      mutationResponse={data}
    />
  );
};

export const IngressAnnotationSettings: React.FC<{
  repo: RepoDetailFragment;
}> = ({ repo }) => {
  const toast = useToast();

  const parsed = useMemo(
    () => JSON.parse(repo.kubernetesCustomization?.ingressAnnotations ?? "{}"),
    [repo.kubernetesCustomization?.ingressAnnotations]
  );

  const [updateSettings, { error, loading, data }] =
    useUpdateProjectSettingsMutation({
      onCompleted: (data) => {
        if (data) {
          toast({
            title: "Ingress Annotations Saved",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      },
    });

  const onSubmit = (data: Annotation | null) => {
    updateSettings({
      variables: {
        input: {
          id: repo.id,
          kubernetesCustomization: {
            ingressAnnotations: JSON.stringify(data),
          },
        },
      },
    });
  };

  return (
    <BaseAnnotationSettings
      data={parsed}
      onSubmit={onSubmit}
      title={"Ingress annotations"}
      description={
        "Annotate all Zeet-controlled ingresses with a custom key/value"
      }
      loading={loading}
      error={error}
      mutationResponse={data}
    />
  );
};
