import { Flex, Text } from "@chakra-ui/react";
import {
  AnalyticsEvent,
  useAccessToken,
  useIsLoggedIn,
  usePosthog,
  useQueryParams,
  useTrack,
} from "@zeet/web-ui";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { LOGIN_NEXT_KEY } from "./Login";

// This component cannot be used for popup
export const Close: React.FC = () => {
  const postHog = usePosthog();
  const { track } = useTrack();

  const query = useQueryParams();
  const token = query.get("token") || "";
  const notrack = query.get("notrack");
  const isSignup = query.get("signup");
  const nextPath = localStorage.getItem(LOGIN_NEXT_KEY) || `/dashboard`;

  const [trackedLogin, setTrackedLogin] = useState(isSignup !== "true");

  if (notrack) {
    window.localStorage.setItem("notrack", "true");
  }
  const [, setAccessToken] = useAccessToken();
  const isLoggedIn = useIsLoggedIn();

  useEffect(() => {
    if (token) {
      setAccessToken(token);
    }
  }, [token, setAccessToken]);
  useEffect(() => {
    if (isSignup === "true") {
      try {
        window.gtag("event", "conversion", {
          send_to: "AW-536730116/eU29COrLxv4BEIS09_8B", // cspell:disable-line
        });
      } catch (e) {
        console.error("failed to track conversion event", e);
      }

      setTrackedLogin(true);
    }
  }, [isSignup]);

  useEffect(() => {
    if (isLoggedIn && postHog) {
      track("login", { method: "github" });
      setTrackedLogin(true);
      if (isSignup === "true") {
        track(AnalyticsEvent.USERS_NEW_SIGNUP, { method: "github" });
      }
    }
  }, [track, isLoggedIn, postHog, isSignup]);

  if (isLoggedIn && trackedLogin) {
    localStorage.removeItem(LOGIN_NEXT_KEY);
    return <Redirect to={nextPath} />;
  }

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      height={400}
      data-testid="close-parent"
    >
      <Text fontWeight="bold" fontSize="1.5rem">
        Success! You will be redirected shortly.
      </Text>
    </Flex>
  );
};
