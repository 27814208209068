import { ProjectSettingsProps } from "../..";
import { SettingsSection } from "../SettingsSection";
import { SingleDeploySettings } from "./SingleDeploySettings";

export const DeploySettings = ({ project }: ProjectSettingsProps) => {
  const deploy = project.deploys.nodes[0];
  if (!deploy) return null;

  return (
    <SettingsSection
      name="Deploy Settings"
      description="Configure how your project is deployed"
    >
      <SingleDeploySettings project={project} deploy={deploy} />
    </SettingsSection>
  );
};
