import { BranchCommonFragment } from "@zeet/web-api/dist/graphql";

interface updatedObj {
  updatedAt?: Date | undefined | null;
}

export const sortByUpdatedAt = (a: updatedObj, b: updatedObj): number => {
  const d1 = new Date(a.updatedAt || 0);
  const d2 = new Date(b.updatedAt || 0);
  return d2.getTime() - d1.getTime();
};

export const branchUpdatedAt = (branch: BranchCommonFragment): Date => {
  return new Date(
    Math.max(
      new Date(branch.updatedAt).getTime(),
      new Date(branch.latestDeployment?.updatedAt || 0).getTime()
    )
  );
};
