import { FormControl, FormErrorMessage, Stack } from "@chakra-ui/react";
import { FormLabel } from "@zeet/web-ui";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDockerComboBox } from "~/components/Docker/hooks/useDockerComboBox";
import { SourceTabPanelProps } from ".";
import { Source, SourceKind } from "..";

const makeSourceFromDockerImage = (repository: string, tag: string): Source => {
  return {
    kind: SourceKind.DockerHub,
    containerRegistry: {
      repository: repository,
      tag,
    },
  };
};

export const DockerHubSource = (props: SourceTabPanelProps) => {
  const [dockerImage, dockerTag, { DockerTagInput, DockerImageInput }] =
    useDockerComboBox(
      props.currentSource?.containerRegistry?.repository,
      props.currentSource?.containerRegistry?.tag ?? "latest"
    );

  const {
    register,
    formState: { errors },
  } = useForm({
    defaultValues: { image: "", tag: "" },
  });

  useEffect(() => {
    if (dockerImage && dockerTag) {
      props.onSourceChange(makeSourceFromDockerImage(dockerImage, dockerTag));
    }
  }, [dockerImage, dockerTag, props]);

  return (
    <Stack mt="4" gap="2" justifyContent="space-between">
      <FormControl isInvalid={!!errors.image?.message} isRequired>
        <FormLabel mb="0">Docker Image</FormLabel>
        <DockerImageInput
          isRelative
          {...register("image", {
            required: "Container image is required",
          })}
        />
        <FormErrorMessage mt="0">{errors.image?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.tag?.message} isRequired>
        <FormLabel mb="0">Docker Image Tag</FormLabel>
        <DockerTagInput
          isRelative
          {...register("tag", {
            required: "Container tag is required",
          })}
        />
        <FormErrorMessage mt="0">{errors.tag?.message}</FormErrorMessage>
      </FormControl>
    </Stack>
  );
};
