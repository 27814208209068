import { RepoDetailFragment } from "@zeet/web-api/dist/graphql";
import React from "react";
import { APIIdentifiersSettings } from "../../APIIdentifiersSettings";
import { repoPath } from "../util";

interface APIProps {
  repo: RepoDetailFragment;
}

export const API: React.FC<APIProps> = ({ repo }) => {
  const identifiers = [
    { title: "Path", value: repoPath(repo) },
    { title: "User", value: repo.owner.id },
    { title: "Project", value: repo.project?.id },
    { title: "Environment", value: repo.projectEnvironment?.id },
    { title: "Repo", value: repo.id },
  ];

  return <APIIdentifiersSettings identifiers={identifiers} />;
};
