import { useToast } from "@chakra-ui/react";
import {
  DeployConfigurationDetailFragment,
  ProjectDetailFragment,
  UpdateDeployInput,
  useUpdateDeployMutation,
} from "@zeet/web-api/dist/graphqlv1";
import { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutationError } from "~/hooks/useMutationError";
import { useProjectSettings } from "../../context/ProjectSettingsProvider";
import { InputProvider } from "../../inputs";
import { DeployProtection } from "./DeployProtection";
import { AwsSamSettings } from "./providers/AwsSamSettings";
import { CloudRunSettings } from "./providers/CloudRunSettings";
import { HelmSettings } from "./providers/HelmSettings";
import { KubernetesSettings } from "./providers/KubernetesSettings";
import { ProjectLinkSettings } from "./providers/ProjectLinkSettings";
import { SourceOverrideSettings } from "./providers/SourceOverrideSettings";
import { TerraformOutputSettings } from "./providers/TerraformOutputSettings";
import { TerraformSettings } from "./providers/TerraformSettings";
import { convertInputStringsToActualType, convertToDeployInput } from "./util";
import { VariableSettings } from "./VariableSettings";

interface SingleDeploySettingsProps {
  deploy: DeployConfigurationDetailFragment;
  project: ProjectDetailFragment;
}

export const SingleDeploySettings = ({
  deploy,
  project,
}: SingleDeploySettingsProps) => {
  const methods = useForm<UpdateDeployInput>({
    defaultValues: convertToDeployInput(deploy),
  });
  const toast = useToast();
  const { addSaveHandler, removeSaveHandler } = useProjectSettings();
  const [updateDeployMutation] = useUpdateDeployMutation({
    onCompleted: () => {
      toast({
        title: "Project settings saved",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    },
    ...useMutationError("Failed to update deploy settings"),
  });

  const {
    handleSubmit,
    getValues,
    reset,
    formState: { isDirty },
  } = methods;

  const save = useMemo(
    () =>
      handleSubmit(async (data) => {
        if (data.configuration?.variables) {
          data.configuration.variables = data.configuration.variables.filter(
            (variable) => variable.name !== "" && variable.value !== ""
          );
          convertInputStringsToActualType(data.configuration.variables);
        }
        await updateDeployMutation({
          variables: {
            id: deploy.id,
            input: data,
          },
        });
        reset(getValues());
      }),
    [handleSubmit, updateDeployMutation, deploy.id, reset, getValues]
  );

  useEffect(() => {
    if (isDirty) {
      addSaveHandler(save);
    } else {
      removeSaveHandler(save);
    }
  }, [isDirty, save, addSaveHandler, removeSaveHandler]);

  return (
    <FormProvider {...methods}>
      <InputProvider basePath="configuration">
        <DeployProtection project={project} />
        <ProjectLinkSettings />
        <VariableSettings project={project} />
        <AwsSamSettings />
        <CloudRunSettings />
        <HelmSettings />
        <KubernetesSettings />
        <TerraformOutputSettings />
        <TerraformSettings />
        <SourceOverrideSettings />
      </InputProvider>
    </FormProvider>
  );
};
