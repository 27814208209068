import {
  Box,
  Circle,
  Divider,
  Flex,
  IconButton,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useTrack } from "@zeet/web-ui";
import React from "react";
import { FaBell } from "react-icons/fa";
import { useNotificationData } from "./useNotificationData";

export interface Notification {
  id: string;
  title: string;
  text: string;
  link: string;
  seen: boolean;
  activatesAt: Date;
}

const NotificationBell = React.forwardRef<
  HTMLButtonElement,
  { showCircle: boolean }
>(({ showCircle }, ref) => (
  <IconButton
    variant="ghost"
    aria-label={"Notifications"}
    size={"md"}
    ref={ref}
    borderRadius="full"
    opacity="0.5"
    _hover={{ opacity: 1 }}
    icon={
      <>
        <FaBell color={"gray.750"} />
        {showCircle && (
          <Circle
            color={"white"}
            position={"absolute"}
            top={"10px"}
            right={"10px"}
            fontSize={"0.8rem"}
            bgColor={"brand.200"}
            p={"3px"}
            style={{ width: "12px", height: "12px" }}
          />
        )}
      </>
    }
  />
));

const Notifications = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [notifications, markAsSeen] = useNotificationData();
  const { track } = useTrack();

  const handleOpen = () => {
    track("open_notifications", {
      unseen: notifications.filter((n) => !n.seen).slice(0, 10),
    });
    onOpen();
  };

  const hasUnseenNotifications = notifications.some((n) => !n.seen);

  return (
    <Popover
      placement="bottom"
      isOpen={isOpen}
      onOpen={handleOpen}
      onClose={onClose}
    >
      <PopoverTrigger>
        <Box>
          <NotificationBell showCircle={hasUnseenNotifications} />
        </Box>
      </PopoverTrigger>
      <PopoverContent mt={2}>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Notifications</PopoverHeader>
        <PopoverBody
          display="flex"
          flexDirection="column"
          gridGap={4}
          py={3}
          maxHeight={80}
          overflowY="auto"
        >
          {notifications.map((notification, i) => (
            <Link
              key={notification.id}
              href={notification.link}
              isExternal={true}
              _hover={{ cursor: "pointer" }}
              onClick={() => markAsSeen(notification.id)}
            >
              <Box pb={2} opacity={notification.seen ? 0.6 : undefined}>
                <Flex>
                  {!notification.seen && (
                    <Box
                      height={2}
                      width={2}
                      mt={2}
                      mr={2}
                      minW={2}
                      bg={"brandVar"}
                      borderRadius="999px"
                    />
                  )}
                  <Stack>
                    <Flex>
                      <Text fontSize="sm" fontWeight="bold" pb={1}>
                        {notification.title}
                      </Text>
                      <Text fontSize="sm" ml={4} opacity={0.6}>
                        {notification.activatesAt.toLocaleDateString("en-US", {
                          month: "2-digit",
                          day: "2-digit",
                          year: "2-digit",
                        })}
                      </Text>
                    </Flex>
                    <Box
                      fontSize="sm"
                      opacity={notification.seen ? 0.8 : undefined}
                      mb={2}
                      noOfLines={2}
                    >
                      {notification.text}
                    </Box>
                  </Stack>
                </Flex>
              </Box>
              {i !== notifications.length - 1 && <Divider />}
            </Link>
          ))}
          {notifications.length === 0 && (
            <Box textAlign="center" py={4}>
              <Text fontSize="sm" fontWeight="medium">
                You're all caught up!
              </Text>
            </Box>
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default Notifications;
