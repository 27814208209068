import { WarningIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Icon,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import {
  BlueprintType,
  ClusterDetailV1Fragment,
  Maybe,
  ObservabilityConfig,
  ResourceType,
  useResourcesWithMetricsQuery,
} from "@zeet/web-api/dist/graphqlv1";
import {
  CenterLoading,
  Flag,
  IconButton,
  Link,
  useColors,
  useCurrentTeamUser,
  useFeatureFlag,
} from "@zeet/web-ui";
import { useState } from "react";
import { BsArrowRepeat, BsGear } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import ReactTimeago from "react-timeago";
import { useProjectV3Context } from "~/components/Project/ProjectV3Provider";
import { useProjectPath } from "../hooks/useProjectPath";
import { ResourceClusterInfo } from "./ResourceClusterInfo";
import { ResourceClusterMiniChart } from "./ResourceClusterMiniChart";
import { ResourceLine } from "./ResourceLine";
import { ResourceSettingsModal } from "./ResourceSettingsModal";
import { ResourceStatusBadge } from "./ResourceStatusBadge";
import { ResourceTypeBadge } from "./ResourceTypeBadge";
import { metricsTypesToFetch } from "./utils";

interface ResourceClusterProps {
  projectId: string;
  cluster: ClusterDetailV1Fragment;
  showSettingButton?: boolean;
  config?: Maybe<ObservabilityConfig>;
  refetchConfig: () => void;
}

export const ResourceCluster: React.FC<ResourceClusterProps> = ({
  projectId,
  cluster,
  showSettingButton,
  config,
  refetchConfig,
}) => {
  const isResourceSettingsButtonEnabled = useFeatureFlag(
    Flag.ResourceSettings,
    {
      devEnabled: true,
    }
  );
  const { projectInfoData: data, projectInfoLoading: loading } =
    useProjectV3Context();
  const { id: teamId, login: teamLogin } = useCurrentTeamUser();
  const { bg3 } = useColors();
  const history = useHistory();
  const projectPath = useProjectPath();
  const [loadingMetrics, setLoadingMetrics] = useState(true);
  const [refreshingState, setRefreshingState] = useState(false);
  const { data: resourcesData, refetch: refetchResources } =
    useResourcesWithMetricsQuery({
      variables: {
        projectId,
        teamId,
        clusterId: cluster.id,
        metricTypes: metricsTypesToFetch,
        selector: {
          type: ResourceType.Pod, // TODO: select from the side menu
        },
      },
      errorPolicy: "all",
      onCompleted: () => {
        setLoadingMetrics(false);
      },
      onError: () => {
        setLoadingMetrics(false);
      },
      fetchPolicy: "no-cache",
    });

  const handleRefreshClick = async () => {
    setRefreshingState(true);
    await refetchResources();
    setRefreshingState(false);
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleResourceLineClick = (
    resourceName: string,
    containerName: string
  ) => {
    history.push(
      `${projectPath}/resources?cluster-id=${cluster.id}&resource-name=${resourceName}&container-name=${containerName}`
    );
  };

  const handleConfigSaved = async (refetch?: boolean) => {
    onClose();

    if (refetch) {
      refetchConfig();
      handleRefreshClick();
    }
  };

  const resources = resourcesData?.team?.project?.resources;

  const isHelmProject =
    data?.team?.project?.blueprint?.type === BlueprintType.Helm;
  const isSettingButtonVisible =
    isHelmProject && isResourceSettingsButtonEnabled && showSettingButton;

  if (loadingMetrics || loading) {
    return <CenterLoading />;
  }

  return (
    <Flex mt="5" direction="column">
      <Flex justifyContent="end">
        {isSettingButtonVisible && (
          <>
            <Tooltip placement="top" label="Resource settings">
              <IconButton
                isLoading={refreshingState}
                aria-label="Resource settings"
                variant="secondary"
                icon={<Icon as={BsGear} />}
                ml={3}
                onClick={onOpen}
              />
            </Tooltip>
            <ResourceSettingsModal
              projectId={projectId}
              isOpen={isOpen}
              onClose={handleConfigSaved}
              config={config}
            />
          </>
        )}
      </Flex>
      <Flex>
        <ResourceClusterInfo cluster={cluster} showLogo showLink />
        <IconButton
          isLoading={refreshingState}
          onClick={handleRefreshClick}
          variant="secondary"
          title="Refresh cluster"
          aria-label="Refresh cluster"
          bg="none"
          border="none"
          boxShadow="none"
          _hover={{ opacity: 0.6, filter: "grayscale(0)" }}
          icon={<Icon as={BsArrowRepeat} boxSize={6} />}
        />
      </Flex>
      <Flex mt="3" direction="column" borderRadius="md">
        {resources?.length ? (
          resources?.map((r, ri) => (
            <Box
              key={ri}
              border="1px solid var(--chakra-colors-chakra-border-color)"
              borderRadius="md"
              mb="5"
            >
              <ResourceLine
                leftChildren={[
                  <Text>{r.name}</Text>,
                  <ResourceTypeBadge
                    ml="3"
                    fontWeight="700"
                    fontSize="12px"
                    height="18px"
                    resourceType={r.type}
                  />,
                ]}
              >
                <>
                  {r.metric ? (
                    r.metric
                      ?.filter((metric) => metric.entries?.length)
                      .slice(0, 2)
                      .map((metric) => (
                        <ResourceClusterMiniChart metric={metric} />
                      ))
                  ) : (
                    <Tooltip
                      label="Zeet uses Prometheus to fetch metrics from your Cluster. We're unable to fetch metrics, click here to check your cluster configuration."
                      placement="right"
                    >
                      <Link
                        to={`/${teamLogin}/console/clusters/${
                          cluster.id ?? ""
                        }`}
                        target="_blank"
                      >
                        <WarningIcon color="red.500" />
                      </Link>
                    </Tooltip>
                  )}
                  <ResourceStatusBadge size="18px" ml="5" cloudResource={r} />
                </>
              </ResourceLine>
              <Flex
                border="1px solid var(--chakra-colors-chakra-border-color)"
                borderBottom="none"
                borderTopRightRadius="md"
                borderTopLeftRadius="md"
                m={4}
                direction="column"
              >
                {r.resources?.map((sr, ci) => (
                  <ResourceLine
                    key={ci}
                    leftChildren={[
                      <ResourceStatusBadge size="18px" cloudResource={sr} />,
                      <Text ml="3">{sr.name}</Text>,
                      <ResourceTypeBadge
                        ml="3"
                        fontWeight="700"
                        fontSize="12px"
                        height="18px"
                        resourceType={sr.type}
                      />,
                    ]}
                    onClick={() => handleResourceLineClick(r.name, sr.name)}
                    borderBottom="1px solid var(--chakra-colors-chakra-border-color)"
                    _hover={{ cursor: "pointer", backgroundColor: bg3 }}
                  >
                    <ReactTimeago date={sr?.createdAt || 0} />
                  </ResourceLine>
                ))}
              </Flex>
            </Box>
          ))
        ) : (
          <Flex p={4} py="8" opacity="0.5" justifyContent="center">
            No resources found.
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
