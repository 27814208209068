import {
  Blueprint,
  BlueprintMetadata,
  BlueprintMetadataMetaTag,
  BlueprintVariableSpec,
  BlueprintVariableType,
  UserDetailFragment,
} from "@zeet/web-api/dist/graphql";
import {
  BlueprintSlug,
  BridgeBlueprint,
  BridgeBlueprintInput,
  BridgeBlueprintInputType,
} from "./blueprints";

const getInputTypeFromBlueprintVariableType = (
  type: BlueprintVariableType
): BridgeBlueprintInputType => {
  switch (type) {
    case BlueprintVariableType.String:
      return BridgeBlueprintInputType.String;
    case BlueprintVariableType.Integer:
      return BridgeBlueprintInputType.Integer;
    case BlueprintVariableType.Boolean:
      return BridgeBlueprintInputType.Bool;
    case BlueprintVariableType.Float:
      return BridgeBlueprintInputType.Float;
    case BlueprintVariableType.Json:
      return BridgeBlueprintInputType.Json;
  }
};

const makeBlueprintVariablesFromVariables = (
  variables?: BlueprintVariableSpec[]
): BridgeBlueprintInput[] => {
  return (
    variables?.map((variable: BlueprintVariableSpec) => ({
      id: variable.id,
      name: variable.name,
      type: getInputTypeFromBlueprintVariableType(
        variable.type.toUpperCase() as BlueprintVariableType
      ),
      required: variable.required,
    })) ?? []
  );
};

export const makeBlueprintVariablesFromRichInputSchema = (
  blueprint: Blueprint
): BridgeBlueprintInput[] => {
  try {
    const richInputSchema = JSON.parse(blueprint.richInputSchema ?? "");
    return richInputSchema.map((variable): BridgeBlueprintInput => {
      return {
        id: variable?.id,
        name: variable?.name,
        type: variable?.type as BridgeBlueprintInputType,
        default: variable?.default,
        options: variable?.options,
        description: variable?.description,
        sensitive: variable?.sensitive ?? false,
        required: variable?.required ?? false,
        variables: variable?.variables,
        trueValue: variable?.trueValue,
        falseValue: variable?.falseValue,
        readOnly: variable?.readOnly,
        hidden: variable?.hidden,
      };
    });
  } catch (e) {
    return [];
  }
};

export const makeBlueprintVariables = (
  blueprint: Blueprint
): BridgeBlueprintInput[] => {
  if (blueprint.richInputSchema) {
    return makeBlueprintVariablesFromRichInputSchema(blueprint);
  }
  return makeBlueprintVariablesFromVariables(blueprint.variables);
};

const makeBlueprint = (
  blueprint: Blueprint,
  currentUser?: UserDetailFragment
): BridgeBlueprint => {
  const sameTeam = currentUser?.login == blueprint.owner?.login;
  const teamName = sameTeam || !currentUser ? "your team" : currentUser.name;

  return {
    id: blueprint.id,
    name: blueprint.displayName,
    owner: blueprint.owner?.login ?? "",
    logoUrl: blueprint.logoUrl ?? "",
    slug: blueprint.slug as BlueprintSlug,
    type: blueprint.type,
    tags: [...blueprint.tags, blueprint.owner?.name ?? ""],
    description:
      blueprint.description ||
      `Deploy from ${blueprint.displayName}, made by ${teamName}.`,
    version: "v1.0.0",
    latestVersion: "v1.0.0",
    richInputSchema: blueprint.richInputSchema,
    variables: makeBlueprintVariables(blueprint),
    configuration: blueprint.configuration,
    projectCount: blueprint.projectCount,
    published: blueprint.published,
    enabled: blueprint.enabled || false,
    versions: [
      {
        version: "v1.0.0",
      },
    ],
  };
};

const makeBlueprints = (
  blueprints: Blueprint[],
  currentUser?: UserDetailFragment
): BridgeBlueprint[] => {
  return (
    blueprints.map((blueprint) => makeBlueprint(blueprint, currentUser)) ?? []
  );
};

const makeBlueprintMetadata = (
  blueprint: BlueprintMetadata
): BridgeBlueprint => {
  const metatags = (blueprint.metatags || []).filter(
    (metatag) => !!metatag
  ) as BlueprintMetadataMetaTag[];
  return {
    id: blueprint.id,
    name: blueprint.displayName || blueprint.name || blueprint.slug,
    owner: blueprint.organizationDisplayName ?? "",
    logoUrl: blueprint.logoUrl ?? "",
    slug: blueprint.slug as BlueprintSlug,
    type: blueprint.type,
    tags: [...blueprint.tags, blueprint.organizationName ?? ""],
    description: blueprint.description ?? blueprint.displayName,
    version: blueprint.version ?? "v1.0.0",
    versions: blueprint.availableVersions
      .map((version) => blueprint.versions.find((v) => v.version == version))
      .filter((version) => !!version)
      .map((version) => ({
        version: version!.version,
        preRelease: version!.prerelease ?? false,
        timestamp: version!.timestamp ?? undefined,
      })),
    applicationVersion: blueprint.applicationVersion ?? undefined,
    latestVersion: blueprint.latestVersion ?? "1.0.0",
    organizationName:
      blueprint.organizationDisplayName ??
      blueprint.organizationName ??
      undefined,
    variables: [],
    projectCount: 0, // post MVP
    published: true, // maybe change to something else?
    enabled: true,
    isCommunity: true,
    metatags: metatags.reduce(
      (previousValue, currentValue) =>
        previousValue.set(currentValue.name, currentValue.value),
      new Map()
    ),
  };
};

const makeBlueprintMetadatas = (
  blueprints: BlueprintMetadata[]
): BridgeBlueprint[] => {
  return blueprints.map((blueprint) => makeBlueprintMetadata(blueprint)) ?? [];
};

export {
  makeBlueprints,
  makeBlueprintMetadatas,
  makeBlueprintMetadata,
  makeBlueprint,
};
