import { JobListItemFragment } from "@zeet/web-api/dist/graphqlv1";
import { JobColumnId } from "./listview";

export const formatJob = (path: string, job: JobListItemFragment) => {
  return {
    [JobColumnId.Name]: {
      text: job.definition.jobName || "Untitled Job",
      link: `${path}?jobId=${job.id}`,
    },
    [JobColumnId.Count]: {
      text: job.runs.totalCount.toString(),
    },
  };
};
