import {
  GenericWorkflowStepActionType,
  WorkflowRunDetailFragment,
  WorkflowRunListItemFragment,
  WorkflowRunStepDetailFragment,
  WorkflowRunStepNestedDetailQuery,
} from "@zeet/web-api/dist/graphqlv1";
import { WorkflowRunColumnId } from "./listview";

export const formatWorkflowRun = (
  path: string,
  run: WorkflowRunListItemFragment
) => {
  return {
    [WorkflowRunColumnId.Id]: {
      text: `Workflow Run #${run.sequence}`,
      link: `${path}?workflowId=${run.id}`,
    },
    [WorkflowRunColumnId.Status]: {
      status: run.status,
    },
    [WorkflowRunColumnId.CreatedAt]: {
      date: run.createdAt,
    },
  };
};

const sortBySequenceNumber = (
  a: WorkflowRunStepDetailFragment,
  b: WorkflowRunStepDetailFragment
) => {
  return a?.sequenceNumber - b?.sequenceNumber;
};

export const sortStepsBySequenceNumber = (
  steps?: (WorkflowRunStepDetailFragment | null)[] | null
) => {
  if (!steps) {
    return [];
  }
  return [...steps]
    .filter((step): step is WorkflowRunStepDetailFragment => step !== null)
    .sort(sortBySequenceNumber);
};

export const workflowActionTitleMap = {
  [GenericWorkflowStepActionType.DriverApply]: "Apply",
  [GenericWorkflowStepActionType.DriverApprove]: "Approve",
  [GenericWorkflowStepActionType.DriverPlan]: "Plan",
  [GenericWorkflowStepActionType.DriverPlanDestroy]: "Plan destroy",
  [GenericWorkflowStepActionType.OrchestrationBuild]: "Build",
  [GenericWorkflowStepActionType.OrchestrationDeploy]: "Deploy",
  [GenericWorkflowStepActionType.OrchestrationRelease]: "Release",
  [GenericWorkflowStepActionType.OrchestrationClusterPrecheck]:
    "Cluster precheck",
  [GenericWorkflowStepActionType.OrchestrationDestroy]: "Destroy",
  [GenericWorkflowStepActionType.ProjectDelete]: "Delete",
};

export const getNestedWorkflowRun = (
  data?: WorkflowRunStepNestedDetailQuery
): WorkflowRunDetailFragment | void => {
  if (
    data?.team?.project?.workflow?.run.step.__typename === "DeployRunStep" ||
    data?.team?.project?.workflow?.run.step.__typename === "DestroyRunStep"
  ) {
    return data?.team?.project?.workflow?.run.step
      .workflowRun as WorkflowRunDetailFragment;
  }
};
