import { Input, useColors } from "@zeet/web-ui";
import { FieldValues } from "react-hook-form";
import { InputContainer, InputProps } from "./InputContainer";

interface StringInputProps<TFormData> extends InputProps<TFormData> {
  placeholder?: string;
}

export const StringInput = <TFormData extends FieldValues>({
  placeholder,
  ...props
}: StringInputProps<TFormData>) => {
  const { bg } = useColors();
  return (
    <InputContainer
      {...props}
      render={(p) => <Input {...p} bg={bg} placeholder={placeholder} />}
    />
  );
};
