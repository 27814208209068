import { Box, Flex, IconButton } from "@chakra-ui/react";
import {
  BridgeBlueprintInput,
  Button,
  FormLabel,
  ListDefaultValue,
  Tooltip,
  useEffectOnce,
} from "@zeet/web-ui";
import { Path, useFieldArray, useFormContext } from "react-hook-form";
import { FiMinusCircle } from "react-icons/fi";
import { NewResourceValues } from "../../context";
import InputControl from "./InputControl";

interface ListInputProps<T> {
  input: BridgeBlueprintInput;
  pathMap: (value: any) => Path<T>;
}

const ListInput: React.FC<ListInputProps<NewResourceValues>> = ({
  input,
  pathMap,
}) => {
  const {
    formState: { errors },
  } = useFormContext<NewResourceValues>();
  const { fields, append, remove, update } = useFieldArray({
    name: pathMap(input.id),
  });

  const makeInitialValues = (input: BridgeBlueprintInput) => {
    if (input.default instanceof Array) {
      return (input.default as ListDefaultValue[][]).map(
        (dvs: ListDefaultValue[]) =>
          dvs.reduce((acc, dv) => ({ ...acc, [dv.id]: dv.value }), {})
      );
    }
  };

  const makeDefaultValues = (input: BridgeBlueprintInput) => {
    const inputVariableIds = input.variables?.map((i) => i.id);
    return inputVariableIds?.reduce((acc, id) => ({ ...acc, [id]: "" }), {});
  };

  useEffectOnce(() => {
    if (fields.length === 0) {
      const defaultValues = makeInitialValues(input) ?? [
        makeDefaultValues(input),
      ];
      defaultValues.forEach((defaultValue) => append(defaultValue));
    }
  });

  const removeOrReset = (index: number) => {
    if (fields.length === 1) {
      update(0, makeDefaultValues(input));
    } else {
      remove(index);
    }
  };

  const getError = (index: number, id: string) => {
    if (errors.variables) {
      const inputErrors = errors.variables[input.id] ?? {};
      if (inputErrors[index]) {
        return inputErrors[index][id];
      }
    }
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        pr="9"
      >
        {input.variables?.map((i) => (
          <Box key={i.id} display="inline-flex" alignItems="center" flex="1">
            <FormLabel width="100%">
              {i.name}
              {i.description && <Tooltip text={i?.description} />}
            </FormLabel>
          </Box>
        ))}
      </Box>
      {fields.map((field, index) => (
        <Box
          key={field.id}
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          gap="3"
        >
          {input.variables?.map((i) => {
            const nextId = `${input.id}.${index}.${i.id}`;
            const nextInput: BridgeBlueprintInput = {
              ...i,
              id: nextId,
              name: "",
            };
            return (
              <InputControl
                key={nextInput.id}
                input={nextInput}
                error={getError(index, i.id)?.message}
              />
            );
          })}
          <IconButton
            aria-label="delete"
            icon={<FiMinusCircle />}
            borderRadius="md"
            variant="outline"
            onClick={() => removeOrReset(index)}
            mb="2"
          />
        </Box>
      ))}
      <Flex>
        <Button
          variant="secondary"
          size="sm"
          mb={input.name ? 0 : 4}
          onClick={() => append(makeDefaultValues(input))}
          mr={4}
        >
          Add item
        </Button>
      </Flex>
    </>
  );
};

export default ListInput;
