import {
  CheckCircleIcon,
  DeleteIcon,
  NotAllowedIcon,
  RepeatIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  IconButton,
  Input,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  OrderedList,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  TextProps,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  CertManagerChallengeType,
  ClusterDomainsDetailFragment,
  DeployTarget,
  DomainDetailFragment,
  RepoDetailFragment,
  useAddRepoCustomDomainMutation,
  useClustersCustomDomainsQuery,
  useReissueCertMutation,
  useRemoveRepoCustomDomainMutation,
  useRepoNetworkQuery,
} from "@zeet/web-api/dist/graphql";
import {
  CenterLoading,
  Container,
  CopyableText,
  Tooltip,
  useTrack,
  ZAlert,
  ZError,
} from "@zeet/web-ui";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { NIL as NIL_UUID } from "uuid";
import { IClustersCustomDomain } from "../../types/misc";
import { ZFormLabel } from "./Build";
import { CustomDomainOption, DomainSettingsMenu } from "./DomainSettingsMenu";

const BT: React.FC<TextProps> = (props) => {
  return <Text as="span" color="brandVar" {...props} />;
};

const zeetIps = ["75.2.70.125", "99.83.191.219"];

interface GoogleDNSResponse {
  Status: number;
  TC: boolean;
  RD: boolean;
  RA: boolean;
  AD: boolean;
  CD: boolean;
  Question: Array<{ name: string; type: number }>;
  Answer: Array<{ name: string; type: number; TTL: number; data: string }>;
  Comment: string;
}

interface DeleteModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  deleteMessage: string;
  setDeleteMessage: React.Dispatch<React.SetStateAction<string>>;
}

export const DNSStatus: React.FC<{ domain: string; ips: string[] }> = ({
  domain,
  ips,
}) => {
  const [status, setStatus] = useState("loading");

  useEffect(() => {
    (async () => {
      const response = await fetch(
        `https://dns.google/resolve?name=${domain.replace(
          "*.",
          "_acme-challenge."
        )}&type=ALL`
      );
      const json: GoogleDNSResponse = await response.json();
      const ans = json?.["Answer"];

      // we can't actually detect this super well cuz cloudflare hides shit
      if (!ans) {
        setStatus("bad");
      } else {
        ans.forEach((d) => {
          if (ips.includes(d?.data)) {
            setStatus("good");
          }
        });
      }
    })();
  }, [domain, ips]);

  if (status === "bad") {
    return (
      <NotAllowedIcon color={"danger"} width="1.3rem" height="1.3rem" ml={4} />
    );
  } else if (status === "good") {
    return (
      <CheckCircleIcon
        color={"success"}
        width="1.3rem"
        height="1.3rem"
        ml={4}
      />
    );
  }

  return null;
};

const CertificateStatus: React.FC<{
  repo: RepoDetailFragment;
  domain: DomainDetailFragment;
  cluster: ClusterDomainsDetailFragment["cluster"];
}> = ({ repo, domain, cluster }) => {
  const toast = useToast();

  const [reissueCert, { error, loading }] = useReissueCertMutation({
    onCompleted: (data) => {
      if (data) {
        toast({
          title: "Certificate Re-Issued",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    },
  });

  const { data: customDomainsQuery, loading: customDomainsLoading } =
    useClustersCustomDomainsQuery({
      variables: {
        id: repo.owner.id,
      },
    });

  const [matchedCustomDomains, setMatchedCustomDomains] =
    useState<IClustersCustomDomain[]>();

  useEffect(() => {
    if (customDomainsQuery) {
      const tempMatched: IClustersCustomDomain[] = [
        {
          cluster: undefined,
          customDomain: undefined,
        },
      ];
      customDomainsQuery?.user?.clusters?.map((c) => {
        // only show clusters that contain the same domain and is not the attached cluster
        // prevent user from selecting a custom domain that is already synced to another domain to prevent circular copying
        if (c.id !== cluster.id) {
          c.customDomains?.map((cd) => {
            if (
              cd.customDomain?.domain === domain.domain &&
              !cd.customDomain.syncDomain?.id
            ) {
              tempMatched.push({
                cluster: c,
                customDomain: cd,
              });
            }
          });
        }
      });
      setMatchedCustomDomains(tempMatched);
    }
  }, [cluster.id, customDomainsQuery, domain.domain, setMatchedCustomDomains]);

  const [currentCustomDomain, setCurrentCustomDomain] = useState<
    IClustersCustomDomain | undefined
  >();

  useEffect(() => {
    if (domain.syncDomain?.id && domain.syncDomain?.id !== NIL_UUID) {
      setCurrentCustomDomain(
        matchedCustomDomains?.find(
          (mcd) => mcd.customDomain?.id === domain.syncDomain?.id
        )
      );
    } else {
      setCurrentCustomDomain(matchedCustomDomains?.[0]);
    }
  }, [domain, matchedCustomDomains, setCurrentCustomDomain]);
  if (customDomainsLoading) {
    return <CenterLoading />;
  }

  return (
    <Stack>
      <Stack isInline alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          <Text mr={2}> Certificate Status</Text>

          <Text mr={2} fontWeight="bold">
            {domain?.disableCertManager
              ? "Disabled"
              : domain.certificate?.ready
              ? "Ready"
              : domain.certificate?.issuing
              ? "Issuing"
              : "Failed"}
          </Text>
          {domain.certificate?.ready ? (
            <CheckCircleIcon color="success" />
          ) : domain.certificate?.issuing ? (
            <Spinner size="sm" color="warning" />
          ) : !domain?.disableCertManager ? (
            <NotAllowedIcon color="danger" />
          ) : null}
        </Flex>

        <Flex ml="auto" alignItems="center">
          {!domain.disableCertManager && (
            <Flex alignItems="center" mr={2}>
              <Text mr={2}>Retry</Text>
              <IconButton
                aria-label="retry"
                icon={<RepeatIcon />}
                borderRadius="md"
                isLoading={loading}
                onClick={() => {
                  reissueCert({
                    variables: {
                      input: {
                        id: domain.id,
                      },
                    },
                  });
                }}
              />
            </Flex>
          )}
          <DomainSettingsMenu
            repo={repo}
            domain={domain}
            cluster={cluster}
            matchedCustomDomains={matchedCustomDomains}
            currentCustomDomain={currentCustomDomain}
          />
        </Flex>
      </Stack>

      {domain.syncDomain && (
        <Text>
          Certificate Synced with{" "}
          <CustomDomainOption customDomainWithCluster={currentCustomDomain} />
        </Text>
      )}

      {!domain.syncDomain &&
        domain?.certificate?.challenges?.map((c) => {
          return (
            <Stack key={c.dnsName}>
              <Stack isInline>
                <Text> Certificate Challenge Status </Text>
                <Text fontWeight="bold">{c.statusState}</Text>
              </Stack>
              {c.statusReason && (
                <ZAlert status="warning" maxW={"60vw"}>
                  <Text maxH={"20vh"} overflowY="auto">
                    {c.statusReason}
                  </Text>
                </ZAlert>
              )}
            </Stack>
          );
        })}
      <ZError error={error} />
    </Stack>
  );
};

const DomainInstruction: React.FC<{
  repo: RepoDetailFragment;
  domain: DomainDetailFragment;
  cluster: ClusterDomainsDetailFragment["cluster"];
  isMultiCluster?: boolean;
}> = ({ repo, domain, cluster, isMultiCluster }) => {
  return (
    <Stack spacing={2}>
      {isMultiCluster && (
        <Text fontWeight="bold">
          Cluster <BT>{cluster.name}</BT>
        </Text>
      )}
      <Text>
        Please add the following DNS records with your domain name provider.
      </Text>
      <Flex maxW="60vw" overflow="auto">
        <Table size="sm">
          <Thead>
            <Tr>
              <Th width={"6rem"}>Type</Th>
              <Th>Name</Th>
              <Th>Value</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {domain.instructions &&
              domain.instructions.length > 0 &&
              domain.instructions.map((instruction, i) => (
                <Tr key={i}>
                  <Td>{instruction.type}</Td>
                  <Td>
                    <CopyableText
                      color="brandVar"
                      whiteSpace="nowrap"
                      toCopy={instruction.domain}
                    >
                      {domain.isApex ? "@" : instruction.domain}
                    </CopyableText>
                  </Td>
                  <Td>
                    <CopyableText
                      color="brandVar"
                      whiteSpace="nowrap"
                      toCopy={instruction.value}
                    >
                      {instruction.value}
                    </CopyableText>
                  </Td>
                  <Td>
                    <DNSStatus
                      domain={instruction.domain}
                      ips={[instruction.value || ""]}
                    />
                  </Td>
                </Tr>
              ))}
            {!domain.syncDomain &&
              domain.certificate?.instructions &&
              domain.certificate.instructions.length > 0 &&
              domain.certificate?.instructions.map((instruction, i) => (
                <Tr key={i}>
                  <Td>{instruction.type}</Td>
                  <Td>
                    <CopyableText
                      color="brandVar"
                      whiteSpace="nowrap"
                      toCopy={instruction.domain}
                    >
                      {instruction.domain}
                    </CopyableText>
                  </Td>
                  <Td>
                    <CopyableText
                      color="brandVar"
                      whiteSpace="nowrap"
                      toCopy={instruction.value}
                    >
                      {instruction.value}
                    </CopyableText>
                  </Td>
                  <Td>
                    <DNSStatus
                      domain={instruction.domain}
                      ips={[instruction.value || ""]}
                    />
                  </Td>
                </Tr>
              ))}
            {repo.deployTarget === DeployTarget.AwsSam &&
              domain.instructions?.length === 0 && (
                <Flex mt={2}>
                  <Text flex={1} color="brandVar" whiteSpace="nowrap">
                    Pending
                  </Text>
                  <CopyableText
                    flex={2}
                    color="brandVar"
                    whiteSpace="nowrap"
                    ml="2"
                    toCopy={domain.domain}
                  >
                    {domain.domain}
                  </CopyableText>
                  <Text flex={3} color="brandVar" whiteSpace="nowrap" ml="2">
                    Pending
                  </Text>
                </Flex>
              )}
          </Tbody>
        </Table>
      </Flex>
      {repo.deployTarget !== DeployTarget.AwsSam && domain.isApex && (
        <>
          <Text>
            If your DNS provider does not support
            <BT ml="1">CNAME</BT>, <BT mr="1">ANAME</BT>
            or <BT mx="1">ALIAS</BT> type for the <BT>@</BT> record, the you may
            use the following A record as backup.
          </Text>
          <Flex maxW="60vw" overflow="auto">
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th width={"6rem"}>Type</Th>
                  <Th>Name</Th>
                  <Th>Value</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>A</Td>
                  <Td>
                    <CopyableText
                      color="brandVar"
                      whiteSpace="nowrap"
                      toCopy={"@"}
                    >
                      {"@"}
                    </CopyableText>
                  </Td>
                  <Td>
                    <CopyableText
                      color="brandVar"
                      whiteSpace="nowrap"
                      toCopy={domain.ipTargets?.[0]}
                    >
                      {domain.ipTargets?.[0]}
                    </CopyableText>
                  </Td>
                  <Td>
                    <DNSStatus
                      domain={domain.domain}
                      ips={domain.ipTargets || zeetIps}
                    />
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Flex>
        </>
      )}
      <CertificateStatus repo={repo} domain={domain} cluster={cluster} />
    </Stack>
  );
};

const DeleteModal: React.FC<DeleteModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  deleteMessage,
  setDeleteMessage,
}) => {
  return (
    <Modal
      blockScrollOnMount={false}
      isOpen={isOpen}
      onClose={onClose}
      size="3xl"
    >
      <ModalOverlay />
      <ModalContent>
        <Container>
          <ModalHeader>Watch your step!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontWeight="bold" mb="1rem">
              You are about to delete the Custom Domain Name associated with
              your Project. If you proceed with removing the domain, please be
              aware of the following:
            </Text>
            <OrderedList ml={8}>
              <ListItem>
                Any requests to this domain will no longer work.
              </ListItem>
              <ListItem>
                This action cannot be undone, and restoring the domain name may
                take some time.
              </ListItem>
              <ListItem>
                {
                  "The removal of your Domain Name may also have unintended side effects, including a potential impact on your Project's SEO and search engine rankings, as well as any external links pointing to your Project using the custom domain name."
                }
              </ListItem>
            </OrderedList>
            <Text mt="1rem" mb="1rem">
              To remove the Domain Name associated with your Project, type{" "}
              <Text as="i">delete me</Text> to confirm.
            </Text>
            <Input
              placeholder={"delete me"}
              onChange={(e) => {
                setDeleteMessage(e.target.value);
              }}
            ></Input>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="red"
              mr={3}
              onClick={onSubmit}
              disabled={deleteMessage != "delete me"}
            >
              Remove Domain Name
            </Button>
          </ModalFooter>
        </Container>
      </ModalContent>
    </Modal>
  );
};

export const DomainSettings: React.FC<{ repo: RepoDetailFragment }> = ({
  repo,
}) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { track } = useTrack();

  const [deleteMessage, setDeleteMessage] = useState<string>("");
  const [host, setHost] = useState("");

  // auto refresh
  useRepoNetworkQuery({
    skip: !repo?.clusterDomains?.[0]?.domains?.length,
    variables: {
      id: repo.id,
    },
    pollInterval: 3000,
  });

  type FormValues = {
    domain: string;
    challengeType: CertManagerChallengeType;
  };
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      domain: "",
      challengeType: CertManagerChallengeType.Http01,
    },
  });

  const [addRepoDomain, { error: addError, loading: addLoading }] =
    useAddRepoCustomDomainMutation({
      onCompleted: (data) => {
        if (data) {
          toast({
            title: "Domain Saved",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          reset();
        }
      },
    });

  const onSubmit = (values: FormValues): void => {
    addRepoDomain({
      variables: {
        input: {
          id: repo.id,
          domain: values.domain.trim().toLocaleLowerCase(),
          certManagerChallengerType: CertManagerChallengeType.Http01,
        },
      },
    });
  };

  const [removeRepoDomain, { error: removeError, loading: removeLoading }] =
    useRemoveRepoCustomDomainMutation({
      onCompleted: (data) => {
        if (data) {
          toast({
            title: "Domain Removed",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      },
    });

  const loading = addLoading || removeLoading;
  const error = addError || removeError;

  const isMultiCluster = (repo.clusterDomains?.length || 0) > 1;

  const domainClusters =
    repo.clusterDomains
      ?.flatMap((cd) => {
        return cd.domains?.map((d) => ({
          cluster: cd.cluster,
          domain: d,
        }));
      })
      .reduce((acc, cur) => {
        if (!cur?.domain.domain) {
          return acc;
        }

        if (acc[cur.domain.domain]) {
          acc[cur.domain.domain].push(cur);
        } else {
          acc[cur.domain.domain] = [cur];
        }
        return acc;
      }, {}) || {};

  const handleRemoveDomain = () => {
    removeRepoDomain({
      variables: {
        input: {
          id: repo.id,
          domainID: domainClusters[host][0].domain.id,
        },
      },
    });
    onClose();
  };

  return (
    <>
      <DeleteModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={handleRemoveDomain}
        deleteMessage={deleteMessage}
        setDeleteMessage={setDeleteMessage}
      />
      <form
        onSubmit={(e) => {
          e.stopPropagation();
          e.preventDefault();
          handleSubmit(onSubmit)(e);
        }}
      >
        <Stack w="100%">
          <Flex>
            <Text fontWeight="bold" fontSize="1.2rem">
              Custom Domain Name
              <Tooltip
                text={`By default we'll provide *.zeet.app, if you want SSL for your own domain put it in here, and update your DNS`}
              />
            </Text>
          </Flex>
          <Text>
            {
              "Custom domain name will direct traffic to your production deployment's HTTPS load balancer endpoint."
            }
          </Text>
          {!!repo.clusterDomains?.length && (
            <>
              {Object.keys(domainClusters)
                .slice()
                .sort()
                .map((host, i) => {
                  return (
                    <>
                      {!!i && <Divider key={host + "_d"} />}
                      <Flex alignItems={"center"}>
                        <Text fontSize="xl" fontWeight="bold">
                          Domain <BT>{host}</BT>
                        </Text>
                        <IconButton
                          ml="auto"
                          aria-label="delete"
                          icon={<DeleteIcon />}
                          borderRadius="md"
                          isLoading={removeLoading}
                          onClick={() => {
                            onOpen();
                            setHost(host);
                          }}
                        />
                      </Flex>
                      {domainClusters[host]
                        .slice()
                        .sort((a, b) => {
                          return a.name > b.name;
                        })
                        .map((c, i) => {
                          return (
                            <>
                              <Stack
                                key={c.cluster.id + "_" + c.domain + "_d"}
                                pl={isMultiCluster ? "2%" : undefined}
                              >
                                {!!i && <Divider />}
                                <DomainInstruction
                                  repo={repo}
                                  domain={c.domain}
                                  cluster={c.cluster}
                                  isMultiCluster={isMultiCluster}
                                />
                              </Stack>
                            </>
                          );
                        })}
                    </>
                  );
                })}
              <Divider />
            </>
          )}
          <FormControl isInvalid={errors.domain && !!errors.domain.message}>
            <Stack spacing={2} mt={2}>
              <Box>
                <Flex>
                  <ZFormLabel>Add Custom Domain</ZFormLabel>
                  <Input
                    {...register("domain", {
                      required: "Required",
                      validate: {
                        domain: (value) => {
                          return (
                            /^(\*\.)?(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)+([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])$/.test(
                              value.trim().toLocaleLowerCase()
                            ) || "invalid domain name"
                          );
                        },
                      },
                    })}
                    placeholder="example.com"
                    onClick={() => track("click_project_settings_domain_input")}
                  />
                  <Button
                    ml={4}
                    colorScheme="brand"
                    isLoading={loading}
                    type="submit"
                    onClick={() => track("click_project_settings_domain_save")}
                  >
                    Add
                  </Button>
                </Flex>
              </Box>
              <FormErrorMessage>
                {errors.domain && errors.domain.message}
              </FormErrorMessage>
              <ZError error={error} />
            </Stack>
          </FormControl>
        </Stack>
      </form>
    </>
  );
};
