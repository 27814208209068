import { parse } from "envfile";
import { useCallback } from "react";

export const useParseEnv = (
  onEnvs: (envs: { key: string; value: string }[]) => unknown
): {
  parseEnv(e: React.ChangeEvent<HTMLInputElement> | string): void;
} => {
  const parseEnv = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement> | string) => {
      let text = "";
      if (typeof event === "string") {
        text = event;
      } else {
        const file = event.target.files?.[0];
        if (!file) return;
        text = await file.text();
      }
      const vars = parse(text);

      onEnvs(
        Object.entries(vars).map(([key, value]) => ({
          key,
          value,
        }))
      );
    },
    [onEnvs]
  );

  return { parseEnv };
};
