import { Box } from "@chakra-ui/react";
import {
  DeploymentDetailFragment,
  useDeployTemplatesQuery,
} from "@zeet/web-api/dist/graphql";
import { Skeleton, ZError } from "@zeet/web-ui";
import React from "react";
import { PipelineTemplate } from "./BuildTemplates";

export const DeployTemplates: React.FC<{
  deployment: DeploymentDetailFragment;
}> = ({ deployment }) => {
  const { data, loading, error } = useDeployTemplatesQuery({
    errorPolicy: "all",
    variables: {
      id: deployment.id,
    },
  });

  if (loading) {
    return <Skeleton mt={2} height="500px" />;
  }

  if (
    data?.currentUser.deployment?.deployTemplates &&
    data?.currentUser.deployment?.deployTemplates.length === 0
  ) {
    return null;
  }

  return (
    <Box pb="24px">
      {data?.currentUser.deployment?.deployTemplates?.map((dt, i) => (
        <PipelineTemplate
          key={i}
          deployment={deployment}
          templateString={dt?.output}
          defaultLanguage={dt?.type}
        />
      ))}
      <ZError error={error} mt="12px" />
    </Box>
  );
};
