import { useEffectOnce, useLocalStorage } from "@zeet/web-ui";
import { useState } from "react";
import { Notification } from "./Notifications";

interface Article {
  id: string;
  url: string;
  title: string;
  content_text: string;
  date_published: string;
}

export const useNotificationData = (): [
  Array<Notification>,
  (id: string) => void
] => {
  const [notifications, setNotifications] = useState<Array<Notification>>([]);
  const [seen, setSeen] = useLocalStorage("notifications_seen", "[]");
  const seenObj = JSON.parse(seen);

  const markAsSeen = (id: string) => {
    if (!seenObj.includes(id)) {
      setSeen(JSON.stringify([...seenObj, id]));
    }
  };

  useEffectOnce(() => {
    (async () => {
      const feedUrl = "https://rss.app/feeds/v1.1/XbrQn9j4EOdDsJ9S.json";
      const result = await (await fetch(feedUrl)).json();

      const changelogs = result.items.filter((item: Article) =>
        item.title.includes("Changelog")
      );

      setNotifications(
        changelogs.map(
          (changelog: Article): Notification => ({
            id: changelog.id,
            title: changelog.title,
            text: changelog.content_text,
            activatesAt: new Date(changelog.date_published),
            link: `https://${
              changelog.url.split("blog.")[1]
            }?utm_campaign=rss-notification-panel&utm_source=in-product`,
            seen: seenObj.includes(changelog.id),
          })
        )
      );
    })();
  });

  return [
    notifications
      .map((n) => ({ ...n, seen: seenObj.includes(n.id) }))
      .filter(
        (n) =>
          n.activatesAt < new Date() &&
          n.activatesAt > new Date(new Date().setDate(new Date().getDate() - 7))
      )
      .sort((a, b) => b.activatesAt.getTime() - a.activatesAt.getTime()),
    markAsSeen,
  ];
};
