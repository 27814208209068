import {
  Blueprint,
  CloudProvider,
  LambdaCloudRunBlueprintsQuery,
  UserDetailFragment,
} from "@zeet/web-api/dist/graphql";
import {
  BridgeBlueprintInputId,
  buildTemplates,
  makeBlueprint,
  makeBlueprintVariablesFromRichInputSchema,
  NewResourceValues,
  ProviderOption,
} from "@zeet/web-ui";
import { UseFormSetValue } from "react-hook-form";
import { getDescriptiveProjectName } from "~/utils/generators";
import { regions } from "./cloud";

const makeResourceValues = (
  blueprint: Blueprint,
  currentUser: UserDetailFragment
): NewResourceValues => {
  const variables = {};
  const bridgeBlueprint = makeBlueprint(blueprint, currentUser);
  // make build variables
  const initialVars = makeBlueprintVariablesFromRichInputSchema(blueprint);
  const build = initialVars
    .find((iv) => iv.id === BridgeBlueprintInputId.Build)
    ?.variables?.find((e) => e.id === BridgeBlueprintInputId.BuildTemplate);

  if (build) {
    buildTemplates[build.default as string]?.forEach(
      (input) => (variables[input.id] = input.default)
    );
    variables[BridgeBlueprintInputId.BuildTemplate] = build.default;
  }
  // make network variables
  const network = initialVars.find(
    (iv) => iv.id === BridgeBlueprintInputId.Network
  );
  network?.variables?.forEach((v) => (variables[v.id] = v.default));

  return {
    userID: currentUser.id,
    blueprintID: blueprint?.id,
    blueprintSlug: bridgeBlueprint.slug,
    blueprintType: blueprint.type,
    blueprint: bridgeBlueprint,
    source: {
      git: {
        repository: "https://github.com/zeet-demo/node-express-demo",
      },
    },
    target: {},
    variables,
    organize: {
      name: getDescriptiveProjectName(bridgeBlueprint.name),
      environmentName: "Demo Subgroup",
      projectName: "Demo Group",
    },
  };
};

const setNewResourceValues = (
  cloudType: CloudProvider,
  values: NewResourceValues,
  cloudOptions: ProviderOption[]
) => {
  const cloud = cloudOptions.find((co) => co.provider === cloudType);
  const region = regions[cloudType][0].name;
  values.target.provider = cloud;
  values.target.region = region;
};

export const setDemoFormValues = (
  cloudProvider: CloudProvider | undefined,
  blueprintsData: LambdaCloudRunBlueprintsQuery | undefined,
  cloudOptions: ProviderOption[],
  setValue: UseFormSetValue<NewResourceValues>,
  teamUser: UserDetailFragment
) => {
  if (cloudProvider === CloudProvider.Aws) {
    const blueprint = blueprintsData?.blueprintsMarketplace?.lambdaBlueprint;
    if (blueprint) {
      const values = makeResourceValues(blueprint, teamUser);
      setNewResourceValues(CloudProvider.Aws, values, cloudOptions);
      Object.entries(values).forEach(([key, value]) => {
        setValue(key as keyof NewResourceValues, value);
      });
    }
  }

  if (cloudProvider === CloudProvider.Gcp) {
    const blueprint = blueprintsData?.blueprintsMarketplace?.cloudRunBlueprint;
    if (blueprint) {
      const values = makeResourceValues(blueprint, teamUser);
      setNewResourceValues(CloudProvider.Gcp, values, cloudOptions);

      Object.entries(values).forEach(([key, value]) => {
        setValue(key as keyof NewResourceValues, value);
      });
    }
  }
};
