import { useCurrentUser } from "@zeet/web-ui";
import React from "react";
import Countdown from "react-countdown";

export const TrialCountdown: React.FC = () => {
  const currentUser = useCurrentUser();
  if (currentUser && currentUser?.freeTrialEndsAt) {
    return (
      <Countdown
        date={currentUser.freeTrialEndsAt}
        renderer={({ days, hours, minutes, seconds }) =>
          `${days ? `${days} days ` : ""}${
            days || hours ? `${hours} hours ` : ""
          }${days || hours || minutes ? `${minutes} minutes ` : ""}${
            days || hours || minutes || seconds ? `${seconds} seconds` : ""
          }`
        }
      />
    );
  }
  return null;
};
