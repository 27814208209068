import { Text } from "@chakra-ui/react";
import { CopyCode } from "@zeet/web-ui";
import { FC } from "react";

interface TextCellProps {
  text: string;
}

export const CopyCodeCell: FC<TextCellProps> = ({ text }) => (
  <Text overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
    <CopyCode variant="sm" codeProps={{ fontSize: "xs" }} value={text} />
  </Text>
);
