export enum Flag {
  AzureManagedCluster = "azure-managed-cluster",
  Billingv2 = "billingv2",
  BitbucketIntegration = "bitbucket-integration",
  CloudResourcesView = "cloud-resources-view",
  ClusterCreationLogs = "cluster-creation-logs",
  ClusterValidation = "cluster-validation",
  CreateDemoProject = "create-demo-project",
  DashboardLargeStatus = "dashboard-large-status",
  DeployablesManagement = "deployables-management",
  DeployProtection = "deploy-protection",
  Deployv2 = "deployv2",
  DeployV2LinkDatabase = "deploy-v2-link-database",
  DeployV2ProjectMove = "deploy-v2-project-move",
  DeployWorkflowSettings = "deploy-workflow-settings",
  EnableTfEjection = "enable-tf-ejection",
  Explore = "explore",
  LinkProject = "link-project",
  LinodeManagedCluster = "linode-managed-cluster",
  ManifestCreation = "manifest-creation",
  MetricsTabV2 = "metrics-tab-v2",
  NewV2 = "new-v2",
  NewV2DeployableDetailPage = "new-v2-deployable-detail-page",
  ObservabilityResources = "observability-resources",
  ObservabilityResourcesContainer = "observability-resources-container",
  ProjectDetailApiV1 = "project-detail-api-v1",
  ProjectTypes = "project-types",
  ResourceFilter = "resource-filter",
  ResourceSettings = "resource-settings",
  Revisions = "revisions",
  StaticEgressAllowed = "static-egress-allowed",
  SupportCenter = "support-center",
  ThemeSelection = "theme-selection",
  Vultr = "vultr",
  ZeetStaff = "zeet-staff",
}
