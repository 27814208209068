import { Flex, FormControl, FormErrorMessage, Stack } from "@chakra-ui/react";
import { useUserContainerRegistriesQuery } from "@zeet/web-api/dist/graphql";
import {
  FormLabel,
  Input,
  Link,
  Select,
  useCurrentTeamUser,
} from "@zeet/web-ui";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { MdLaunch } from "react-icons/md";
import { SourceTabPanelProps } from ".";
import { Source, SourceKind } from "..";

const makeSourceFromContainerRegistry = (
  registryId: string,
  repository: string,
  tag: string
): Source => {
  return {
    kind: SourceKind.PrivateContainerRegistry,
    containerRegistry: {
      registryId,
      repository,
      tag,
    },
  };
};

export const PrivateContainerRegistrySource = ({
  onSourceChange,
  currentSource,
}: SourceTabPanelProps) => {
  const currentTeamUser = useCurrentTeamUser();
  const { data } = useUserContainerRegistriesQuery({
    variables: {
      id: currentTeamUser.id,
    },
  });
  const containerRegistries = data?.user.containerRegistries || [];

  const {
    register,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      image: currentSource?.containerRegistry?.repository,
      tag: currentSource?.containerRegistry?.tag,
      registryId: currentSource?.containerRegistry?.registryId,
    },
  });

  const { registryId, image, tag } = watch();

  useEffect(() => {
    if (registryId && image && tag) {
      onSourceChange(makeSourceFromContainerRegistry(registryId, image, tag));
    }
  }, [registryId, image, tag, onSourceChange]);

  return (
    <Stack mt="4" gap="4" justifyContent="space-between">
      <FormControl isRequired>
        <Flex justifyContent="space-between">
          <FormLabel>Container Registry Credentials</FormLabel>
          <Link
            to={`/${currentTeamUser.login}/account/container-registries`}
            isExternal
            gap="1"
            mb="1"
            alignItems="center"
            fontSize="sm"
            display="flex"
            opacity="0.5"
          >
            Connect Registry
            <MdLaunch />
          </Link>
        </Flex>
        <Select
          onChange={(v) => setValue("registryId", v?.value || "")}
          options={containerRegistries?.map((registry) => ({
            value: registry.id,
            label: registry.name,
          }))}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.image?.message} isRequired>
        <FormLabel>Docker Image</FormLabel>
        <Input {...register("image")} />
        <FormErrorMessage mt="0">{errors.image?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.tag?.message} isRequired>
        <FormLabel>Docker Image Tag</FormLabel>
        <Input {...register("tag")} placeholder="latest" />
        <FormErrorMessage mt="0">{errors.tag?.message}</FormErrorMessage>
      </FormControl>
    </Stack>
  );
};
