import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Box,
  BoxProps,
  Button,
  Tooltip as CTooltip,
  Flex,
  Heading,
  Link,
  Text,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  DeploymentCommonFragment,
  DeploymentDetailFragment,
  DeploymentStatus,
  RepoCommonFragment,
  RepoDetailFragment,
  RepoSourceType,
  useDeploymentUpdatedSubscription,
  UserDeploymentQuery,
} from "@zeet/web-api/dist/graphql";
import {
  AnalyticsEvent,
  Card,
  CenterLoading,
  CopyLink,
  CopyText,
  deploymentIsActive,
  deploymentStatus,
  ProjectStatus,
  useQueryParams,
  useTrack,
} from "@zeet/web-ui";
import React, { useEffect, useState } from "react";
import { isSafari } from "react-device-detect";
import { FaGithub, FaLongArrowAltRight } from "react-icons/fa";
import { GoTerminal } from "react-icons/go";
import { MdBugReport } from "react-icons/md";
import TimeAgo from "react-timeago";
import { getSourceLink } from "~/components/Dashboard/DashboardRepoItem";
import {
  isSampleProject,
  SAMPLE_PROJECT_STATUS,
} from "~/features/Project/New/makeCreateSampleProjectInput";
import { DatabaseEnvs } from "../../RepositorySelect/DatabaseEnvs";
import { makeLink } from "../../utils/link";
import { isGit, isKube } from "../../utils/project";
import { useTerminalPopup } from "../Terminal";
import { getPrivateEndpointFQDN } from "../util";
import { DebugTerminalPopup } from "./DebugTerminal/DebugTerminalPopup";
import { DeploymentAlertModalById } from "./DeploymentAlert";
import { DeploymentBanners } from "./DeploymentBanners";
import { DeploymentLogSection } from "./DeploymentLogSection";
import { ProjectIssues } from "./ProjectIssues";
import "./styles.scss";

export const getVersionLink = (
  repo: RepoCommonFragment,
  version: string
): string => {
  const sourceLink = getSourceLink(repo?.source);

  if (
    repo?.source?.type === RepoSourceType.Github ||
    repo?.source?.type === RepoSourceType.GithubPublic
  ) {
    return `${sourceLink}/commit/${version}`;
  }
  if (repo?.source?.type === RepoSourceType.Gitlab) {
    return `${sourceLink}/-/commit/${version}`;
  }
  if (repo?.source?.type === RepoSourceType.Bitbucket) {
    return `${sourceLink}/commits/${version}`;
  }
  return sourceLink;
};

export const SmallDeploymentItem: React.FC<{
  repo: RepoCommonFragment;
  deployment: DeploymentCommonFragment;
}> = ({ repo, deployment }) => {
  useDeploymentUpdatedSubscription({
    variables: {
      id: deployment.id,
    },
  });
  const renderInner = (): JSX.Element => {
    if (deploymentIsActive(deployment)) {
      return (
        <>
          {deployment.status === DeploymentStatus.DeploySucceeded
            ? "is"
            : "will be"}{" "}
          available at:
          <Flex flexDirection="column">
            {deployment.endpoints?.map((endpoint, i) => (
              <Flex key={`${i}-${endpoint}`} align="center" display="flex">
                <Link
                  isExternal
                  href={makeLink(endpoint)}
                  onClick={(e) => {
                    e.stopPropagation(); // important to make link nesting work
                  }}
                >
                  {makeLink(endpoint)}
                  <ExternalLinkIcon mx="2px" />
                </Link>
              </Flex>
            ))}
          </Flex>
        </>
      );
    }
    return <>is inactive</>;
  };

  const { track } = useTrack();
  return (
    <Box>
      Version
      <Link
        href={getVersionLink(repo, deployment.version)}
        isExternal
        color="brandVar"
        mx={1}
        onClick={() => track("click_project_github")}
      >
        <Flex align="center" display="inline-flex">
          {deployment.version.slice(0, 7)}
          <ExternalLinkIcon mx="2px" />
        </Flex>
      </Link>
      {deployment.endpoints && renderInner()}
    </Box>
  );
};

const StageBox = ({ status, ...props }): JSX.Element => {
  const statusColorMap = {
    pending: "gray",
    loading: "success",
    done: "success",
    failed: "danger",
  };

  const staticProps = {
    borderColor: statusColorMap[status],
    borderWidth: 2,
  };

  const dynamicProps = {
    className: "deployment-loading",
  };

  const useDynamic = status === "loading" && !isSafari;

  return (
    <Flex
      borderRadius={10}
      {...(useDynamic ? dynamicProps : staticProps)}
      width="6rem"
      height="3rem"
      justifyContent="center"
      alignItems="center"
      {...props}
    />
  );
};

const StageArrow: React.FC = () => {
  const size = useBreakpointValue([16, 28, 40]);
  return <FaLongArrowAltRight fontSize={size} />;
};

const GrayText: React.FC<React.PropsWithChildren> = (props) => {
  return (
    <Text
      mb={1}
      color="gray.500"
      fontWeight="semibold"
      textTransform="uppercase"
      fontSize={14}
      {...props}
    />
  );
};

export const DeploymentItem: React.FC<
  {
    repo: RepoDetailFragment;
    deployment: DeploymentDetailFragment;
  } & BoxProps
> = ({ repo, deployment, ...boxProps }) => {
  const [showTime, setShowTime] = useState<boolean>(false);
  const { track } = useTrack();
  useDeploymentUpdatedSubscription({
    variables: {
      id: deployment.id,
    },
  });

  let buildStatus = "pending";
  let deployStatus = "pending";
  switch (deployment.status) {
    case DeploymentStatus.BuildPending:
      break;
    case DeploymentStatus.BuildInProgress:
      buildStatus = "loading";
      break;
    case DeploymentStatus.BuildFailed:
      buildStatus = "failed";
      break;
    case DeploymentStatus.DeployStopped:
      buildStatus = "done";
      break;
    case DeploymentStatus.DeployPending:
      buildStatus = "done";
      break;
    case DeploymentStatus.DeployInProgress:
      buildStatus = "done";
      deployStatus = "loading";
      break;
    case DeploymentStatus.ReleaseInProgress:
      buildStatus = "done";
      deployStatus = "loading";
      break;
    case DeploymentStatus.DeployFailed:
      buildStatus = "done";
      deployStatus = "failed";
      break;
    case DeploymentStatus.DeploySucceeded:
      buildStatus = "done";
      deployStatus = "done";
      break;
  }

  const hasEndpoints = repo?.deployService;

  const publicEndpoint = deployment.endpoints?.[0];
  const showPublicEndpoint = !!publicEndpoint && hasEndpoints;
  // Show private endpoint when _any_ ports are exposed
  const showPrivateEndpoint =
    isKube(repo) && !!deployment?.repo?.ports?.length && hasEndpoints;

  const privateFQDN = getPrivateEndpointFQDN(repo);

  // Show LoadBalancer IP when any load balancer port is exposed
  const loadBalancerEndpoint =
    repo?.productionDeployment?.loadBalancers?.[0]?.dns?.[0];
  const showLoadBalancer =
    !!repo?.productionDeployment?.loadBalancers?.[0] && hasEndpoints;

  const status = deploymentStatus(repo, deployment);
  useEffect(() => {
    if (status === "deployed") {
      track(AnalyticsEvent.VIEW_DEPLOYED_PROJECT, { repo_id: repo?.id });
    }
  }, [status, track, repo]);

  const endpointStatus = {
    [DeploymentStatus.BuildPending]: "Pending",
    [DeploymentStatus.BuildInProgress]: "Pending",
    [DeploymentStatus.DeployInProgress]: "Pending",
  };

  const getEndpointStatus = () => {
    return endpointStatus[status] ? endpointStatus[status] : "Inactive";
  };

  return (
    <Box {...boxProps}>
      <Flex
        gap={{ base: 4, md: 6 }}
        flexDirection={{ base: "column", md: "row" }}
      >
        {deployment?.repo?.source?.type &&
          isGit(deployment.repo.source.type) && (
            <Flex flex={1} flexDirection="column" overflow="hidden">
              <GrayText>Commit</GrayText>
              <Link
                href={getVersionLink(repo, deployment.version)}
                isExternal
                color="brandVar"
                mr={1}
                onClick={() => track("click_project_overview_github")}
              >
                <Flex
                  alignItems="center"
                  display="inline-flex"
                  fontFamily="monospace"
                  fontSize="lg"
                >
                  <Box mr={2}>
                    <FaGithub fontSize="1rem" />
                  </Box>
                  <Text
                    textOverflow="ellipsis"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    maxWidth="12rem"
                    mr={1}
                  >
                    {deployment.branch}
                  </Text>
                  <Text>#{deployment.version.slice(0, 7)}</Text>
                </Flex>
              </Link>
            </Flex>
          )}
        {showPublicEndpoint && (
          <Flex flex={1} flexDirection="column" overflow="hidden">
            <GrayText>Endpoint</GrayText>

            <Box>
              {deployment?.deployStatus?.active ||
              isSampleProject(repo.name) ? (
                <CopyLink
                  to={makeLink(publicEndpoint)}
                  variant="sm"
                  linkProps={{
                    isExternal: true,
                    title: publicEndpoint,
                    onClick: () => track("click_project_overview_endpoint"),
                  }}
                  data-testid="deployment-item-deployed-public-endpoint-status"
                >
                  {publicEndpoint}
                </CopyLink>
              ) : (
                <Flex
                  align="center"
                  display="inline-flex"
                  data-testid="deployment-item-not-deployed-public-endpoint-status"
                >
                  {getEndpointStatus()}
                </Flex>
              )}
            </Box>
          </Flex>
        )}
        {showPrivateEndpoint && (
          <Flex flex={1} flexDirection="column" overflow="hidden">
            <GrayText>Private Endpoint</GrayText>

            <Box>
              {deployment?.deployStatus?.active ||
              isSampleProject(repo.name) ? (
                <CopyText
                  variant="sm"
                  text={privateFQDN || ""}
                  boxProps={{
                    title: privateFQDN || "",
                    onClick: () =>
                      track("click_project_overview_private_endpoint"),
                  }}
                >
                  {privateFQDN}
                </CopyText>
              ) : (
                <Flex
                  data-testid="deployment-item-not-deployed-private-endpoint-status"
                  align="center"
                  display="inline-flex"
                >
                  {getEndpointStatus()}
                </Flex>
              )}
            </Box>
          </Flex>
        )}

        {showLoadBalancer && (
          <Flex flex={1} flexDirection="column">
            <GrayText>Load Balancer Endpoint</GrayText>
            {deploymentIsActive(deployment) ? (
              <Flex align="center" display="inline-flex">
                {loadBalancerEndpoint || "Pending"}
              </Flex>
            ) : (
              <Flex align="center" display="inline-flex">
                Inactive
              </Flex>
            )}
          </Flex>
        )}
      </Flex>
      <Flex justifyContent="center" mt={6}>
        {repo?.source.type === RepoSourceType.Docker ||
        repo?.source.type === RepoSourceType.Helm ||
        repo?.source.type == RepoSourceType.Terraform ? (
          <Flex
            justifyContent="space-around"
            alignItems="center"
            px={20}
            width="100%"
            maxW={"600px"}
          >
            <StageBox status={"done"}>Push</StageBox>
            <StageArrow></StageArrow>
            <StageBox status={deployStatus}>Deploy</StageBox>
          </Flex>
        ) : (
          <Flex
            justifyContent="space-around"
            alignItems="center"
            px={20}
            width="100%"
            maxW={"900px"}
          >
            <StageBox status={"done"}>Commit</StageBox>
            <StageArrow></StageArrow>
            <StageBox status={buildStatus}>Build</StageBox>

            {repo?.deployService && (
              <>
                <StageArrow></StageArrow>
                <StageBox status={deployStatus}>Deploy</StageBox>
              </>
            )}
          </Flex>
        )}
      </Flex>

      {deployment.description && (
        <Flex flex={1} mt={6} flexDirection="column">
          <GrayText>Description</GrayText>
          <Flex align="center" display="inline-flex">
            {deployment.description}
          </Flex>
        </Flex>
      )}

      <Flex justifyContent="space-between" marginTop={6}>
        <Text
          fontSize="sm"
          onMouseEnter={() => setShowTime(true)}
          onMouseLeave={() => setShowTime(false)}
        >
          {!showTime && "Created"}
          <Box marginLeft={1} as="span">
            {showTime ? (
              new Date(deployment?.createdAt || 0).toLocaleString()
            ) : (
              <TimeAgo date={deployment?.createdAt || 0} />
            )}
          </Box>
        </Text>
        <Text
          fontSize="sm"
          onMouseEnter={() => setShowTime(true)}
          onMouseLeave={() => setShowTime(false)}
        >
          {!showTime && "Updated"}
          <Box marginLeft={1} as="span">
            {showTime ? (
              new Date(deployment?.updatedAt || 0).toLocaleString()
            ) : (
              <TimeAgo date={deployment?.updatedAt || 0} />
            )}
          </Box>
        </Text>
      </Flex>
    </Box>
  );
};

export type DeploymentDetail = NonNullable<
  UserDeploymentQuery["currentUser"]["deployment"]
>;

export const deploymentTitle = (deployment: DeploymentDetail) => {
  return (
    deployment.id?.slice(0, 7) +
    (deployment && deployment.branch ? ` (${deployment.branch})` : "")
  );
};

export const DeploymentDetailView: React.FC<{
  repo: RepoDetailFragment;
  deployment?: DeploymentDetail | null;
}> = ({ repo, deployment }) => {
  const { track } = useTrack();
  const queryParams = useQueryParams();

  const { onOpen, Terminal } = useTerminalPopup({
    deploymentID: deployment?.id,
  });

  const {
    isOpen: isDebugTerminalOpen,
    onOpen: openDebugTerminal,
    onClose: closeDebugTerminal,
  } = useDisclosure();

  const deploymentAlertId = queryParams.get("alertId");

  if (!deployment) {
    return <CenterLoading />;
  }

  return (
    <>
      <ProjectIssues repo={repo} deployment={deployment} />
      <DeploymentBanners repo={repo} deployment={deployment} />
      {deployment?.id && deploymentAlertId && (
        <DeploymentAlertModalById
          deploymentId={deployment?.id}
          alertId={deploymentAlertId}
        />
      )}
      <Card p={4} my={4}>
        <Flex alignItems="center" justifyContent="space-between" mb={3}>
          <Heading
            size="md"
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
            maxWidth="30rem"
          >
            {deployment ? deploymentTitle(deployment) : ""}
          </Heading>
          <Flex gap={4} ml={4}>
            {(deployment.deployStatus?.active ||
              ["deployed", "crashing", "health checking", "error"].includes(
                deploymentStatus(repo, deployment)
              )) &&
              isKube(repo) &&
              repo.source.type !== RepoSourceType.Helm && (
                <>
                  <CTooltip
                    label="Attach a debug terminal"
                    aria-label=""
                    placement="auto-start"
                    hasArrow
                  >
                    <Button
                      _hover={{ color: "brand.300", cursor: "pointer" }}
                      onClick={openDebugTerminal}
                    >
                      <MdBugReport size={"20px"} color="inherit" />
                      <Text ml={1} fontWeight="600">
                        Debug
                      </Text>
                    </Button>
                  </CTooltip>
                  <CTooltip
                    label="Open terminal connected directly to the running process. May not work if crashing"
                    aria-label=""
                    placement="auto-start"
                    hasArrow
                  >
                    <Button
                      _hover={{ color: "brand.300", cursor: "pointer" }}
                      onClick={() => {
                        track(AnalyticsEvent.CLICK_OPEN_TERMINAL);
                        onOpen();
                      }}
                    >
                      <GoTerminal size={"20px"} color="inherit" />
                      <Text ml={2} fontWeight="600">
                        Terminal
                      </Text>
                    </Button>
                  </CTooltip>
                </>
              )}
            <Terminal />
            <ProjectStatus
              clusterState={repo.cluster?.state}
              status={
                isSampleProject(repo.name)
                  ? SAMPLE_PROJECT_STATUS
                  : deployment.deployStatus?.state
              }
              fontSize="16px"
              display="inline-flex"
              alignItems="center"
              px="16px"
            />
          </Flex>
        </Flex>
        {deployment && (
          <DeploymentItem repo={repo} deployment={deployment} mt={6} />
        )}
      </Card>
      {repo.databaseEnvs && repo.databaseEnvs.length > 0 && (
        <DatabaseEnvs repo={repo} placement="overview" />
      )}

      <DeploymentLogSection deployment={deployment} />

      {isDebugTerminalOpen && deployment && (
        <DebugTerminalPopup
          deploymentId={deployment.id}
          onClose={closeDebugTerminal}
          repoImage={deployment.build?.image}
        />
      )}
    </>
  );
};
