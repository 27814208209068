import {
  Blueprint,
  BlueprintVariableSpec,
  BlueprintVariableType,
  UserDetailFragment,
} from "@zeet/web-api/dist/graphql";
import {
  ContainerRegistrySourceInput,
  GitSourceInput,
} from "@zeet/web-api/dist/graphqlv1";
import {
  BlueprintSlug,
  BridgeBlueprint,
  BridgeBlueprintInput,
  BridgeBlueprintInputId,
  BridgeBlueprintInputType,
} from "@zeet/web-ui";

const getInputTypeFromBlueprintVariableType = (
  type: BlueprintVariableType
): BridgeBlueprintInputType => {
  switch (type) {
    case BlueprintVariableType.String:
      return BridgeBlueprintInputType.String;
    case BlueprintVariableType.Integer:
      return BridgeBlueprintInputType.Integer;
    case BlueprintVariableType.Boolean:
      return BridgeBlueprintInputType.Bool;
    case BlueprintVariableType.Float:
      return BridgeBlueprintInputType.Float;
    case BlueprintVariableType.Json:
      return BridgeBlueprintInputType.Json;
  }
};

const makeBlueprintVariablesFromVariables = (
  variables?: BlueprintVariableSpec[]
): BridgeBlueprintInput[] => {
  return (
    variables?.map((variable: BlueprintVariableSpec) => ({
      id: variable.id,
      name: variable.name,
      type: getInputTypeFromBlueprintVariableType(
        variable.type.toUpperCase() as BlueprintVariableType
      ),
      required: variable.required,
    })) ?? []
  );
};

const getVariablesToRender = (
  variables: BridgeBlueprintInput[]
): BridgeBlueprintInput[] => {
  return variables.filter(
    (variable) =>
      variable.type !== BridgeBlueprintInputType.Source &&
      variable.id !== BridgeBlueprintInputId.HelmValues
  );
};

const parseRichInputSchema = (blueprintRichInputSchema: any): any => {
  try {
    return JSON.parse(blueprintRichInputSchema);
  } catch (e) {
    return [];
  }
};

export const makeBlueprintVariablesFromRichInputSchema = (
  blueprintRichInputSchema: any
): BridgeBlueprintInput[] => {
  try {
    const richInputSchema = parseRichInputSchema(blueprintRichInputSchema);
    const variablesToRender = getVariablesToRender(richInputSchema);
    return variablesToRender.map((variable): BridgeBlueprintInput => {
      return {
        id: variable?.id,
        name: variable?.name,
        type: variable?.type as BridgeBlueprintInputType,
        default: variable?.default,
        options: variable?.options,
        description: variable?.description,
        sensitive: variable?.sensitive ?? false,
        required: variable?.required ?? false,
        variables: variable?.variables,
        trueValue: variable?.trueValue,
        falseValue: variable?.falseValue,
        readOnly: variable?.readOnly,
        allowCustomOption: variable.allowCustomOption,
        hidden: variable.hidden,
      };
    });
  } catch (e) {
    return [];
  }
};

const makeBlueprintSource = (
  blueprint: Blueprint
): BridgeBlueprint["source"] => {
  const richInputSchema = parseRichInputSchema(blueprint.richInputSchema);
  const customSource: {
    default?: {
      containerRegistry?: ContainerRegistrySourceInput;
      git?: GitSourceInput;
    };
  } = richInputSchema?.find(
    (variable) => variable?.type === BridgeBlueprintInputType.Source
  );

  if (customSource) {
    const { default: { git, containerRegistry } = {} } = customSource;
    return {
      containerRegistry: containerRegistry
        ? {
            registryURL: containerRegistry?.registryUrl,
            repository: containerRegistry?.repository ?? "",
            tag: containerRegistry?.tag,
          }
        : undefined,
      git: git
        ? {
            repository: git?.repository,
            ref: git?.ref,
            path: git?.path,
          }
        : undefined,
    };
  }
};

const makeBlueprintTarget = (
  blueprint: Blueprint
): BridgeBlueprint["target"] => {
  const richInputSchema = parseRichInputSchema(blueprint.richInputSchema);
  const customHelmValues = richInputSchema?.find(
    (variable) => variable?.id === BridgeBlueprintInputId.HelmValues
  );

  if (customHelmValues) {
    return {
      helm: {
        values: customHelmValues?.default,
      },
    };
  }
};

const makeBlueprintVariables = (
  blueprint: Blueprint
): BridgeBlueprintInput[] => {
  if (blueprint.richInputSchema) {
    return makeBlueprintVariablesFromRichInputSchema(blueprint.richInputSchema);
  }
  return makeBlueprintVariablesFromVariables(blueprint.variables);
};

const makeBlueprint = (
  blueprint: Blueprint,
  currentUser?: UserDetailFragment
): BridgeBlueprint => {
  const sameTeam = currentUser?.login == blueprint.owner?.login;
  const teamName = sameTeam || !currentUser ? "your team" : currentUser.name;

  return {
    id: blueprint.id,
    name: blueprint.displayName,
    owner: blueprint.owner?.login ?? "",
    logoUrl: blueprint.logoUrl ?? "",
    slug: blueprint.slug as BlueprintSlug,
    type: blueprint.type,
    tags: [...blueprint.tags, blueprint.owner?.name ?? ""],
    description:
      blueprint.description ||
      `Deploy from ${blueprint.displayName}, made by ${teamName}.`,
    version: "v1.0.0",
    richInputSchema: blueprint.richInputSchema,
    variables: makeBlueprintVariables(blueprint),
    configuration: blueprint.configuration,
    source: makeBlueprintSource(blueprint),
    target: makeBlueprintTarget(blueprint),
    projectCount: blueprint.projectCount,
    published: blueprint.published,
    enabled: blueprint.enabled || false,
    allowedCloudProviders: blueprint.allowedCloudProviders,
  };
};

const makeBlueprints = (
  blueprints: Blueprint[],
  currentUser?: UserDetailFragment
): BridgeBlueprint[] => {
  return (
    blueprints.map((blueprint) => makeBlueprint(blueprint, currentUser)) ?? []
  );
};

export { makeBlueprints, makeBlueprint };
