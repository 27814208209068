import { ApolloProvider } from "@apollo/client";
import React from "react";
import { useApolloClient } from "./apollo";

export const ApolloWrapper: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const apolloClient = useApolloClient();
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};
