import { Flex, FlexProps, Spinner } from "@chakra-ui/react";
import React from "react";

export const Loading: React.FC<{ color?: string } & FlexProps> = ({
  color,
  ...props
}) => {
  return (
    <Flex width="100%" justifyContent="center" {...props}>
      <Spinner color={color} speed="0.6s" />
    </Flex>
  );
};

export const CenterLoading: React.FC<{ color?: string } & FlexProps> = ({
  color,
  ...props
}) => {
  return (
    <Flex
      flex={1}
      height="100%"
      width="100%"
      justifyContent="center"
      alignItems="center"
      {...props}
    >
      <Spinner color={color} speed="0.6s" />
    </Flex>
  );
};
