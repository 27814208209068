import { Box, ContainerProps } from "@chakra-ui/react";

export const MenuContainer: React.FC<ContainerProps> = ({
  children,
  ...restProps
}) => {
  return (
    <Box
      w={["100%", "100%", "20%"]}
      maxWidth={[null, null, "200px"]}
      {...restProps}
    >
      {children}
    </Box>
  );
};
