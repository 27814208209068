import { Input, useColors } from "@zeet/web-ui";
import { FieldValues } from "react-hook-form";
import { InputProps } from "./InputContainer";
import { InputContainerWithValidation } from "./InputContainerWithValidation";
interface StringInputWithValidationProps<TFormData>
  extends InputProps<TFormData> {
  placeholder?: string;
  validationFunction: (value: any) => any;
}

export const StringInputWithValidation = <TFormData extends FieldValues>({
  placeholder,
  validationFunction,
  ...props
}: StringInputWithValidationProps<TFormData>) => {
  const { bg } = useColors();

  return (
    <InputContainerWithValidation
      {...props}
      validationFunction={validationFunction}
      render={(p) => <Input {...p} bg={bg} placeholder={placeholder} />}
    />
  );
};
