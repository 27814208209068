import {
  BlueprintVariableType,
  UpdateDeployInput,
} from "@zeet/web-api/dist/graphqlv1";
import { useFormContext } from "react-hook-form";
import { SettingsSection } from "../sections/SettingsSection";
import { BooleanInput } from "./BooleanInput";
import { CodeEditorInput } from "./CodeEditorInput";
import { IntegerInput } from "./IntegerInput";
import { StringInput } from "./StringInput";

export const ProjectVariables = () => {
  const { getValues } = useFormContext<UpdateDeployInput>();
  const variables = getValues("configuration.variables");

  if (variables?.length === 0) return null;

  return (
    <SettingsSection
      name="Variables"
      basePath="variables"
      description="Configure the variables of this project"
      gap={2}
    >
      {variables?.map((variable, index) => {
        if (!variable) return null;
        if (variable.type === BlueprintVariableType.String) {
          return (
            <StringInput name={variable.name || ""} path={`${index}.value`} />
          );
        }
        if (variable.type === BlueprintVariableType.Boolean) {
          return (
            <BooleanInput name={variable.name || ""} path={`${index}.value`} />
          );
        }
        if (variable.type === BlueprintVariableType.Float) {
          return (
            <IntegerInput name={variable.name || ""} path={`${index}.value`} />
          );
        }
        if (variable.type === BlueprintVariableType.Integer) {
          return (
            <IntegerInput name={variable.name || ""} path={`${index}.value`} />
          );
        }
        if (variable.type === BlueprintVariableType.Json) {
          return (
            <CodeEditorInput
              name={variable.name || ""}
              path={`${index}.value`}
            />
          );
        }
        return null;
      })}
    </SettingsSection>
  );
};
