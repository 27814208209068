import { Container, useQueryParams } from "@zeet/web-ui";
import { ProjectDetailTabProps } from "..";
import { WorkflowRunDetail } from "./WorkflowRunDetail";
import { WorkflowRunList } from "./WorkflowRunList";

export const WorkflowRunTab = ({ project }: ProjectDetailTabProps) => {
  const query = useQueryParams();
  const workflowId = query.get("workflowId");
  const projectId = project.id;

  if (workflowId) {
    return <WorkflowRunDetail projectId={projectId} runId={workflowId} />;
  }

  return (
    <Container fullWidth>
      <WorkflowRunList projectId={projectId} />
    </Container>
  );
};
