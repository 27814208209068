import { Flex } from "@chakra-ui/react";
import { useWorkflowDetailQuery } from "@zeet/web-api/dist/graphqlv1";
import { Flag, useCurrentTeamUser, useFeatureFlag } from "@zeet/web-ui";
import {
  SettingsSection,
  SettingsSectionDetails,
} from "~/components/Layouts/Settings";
import { ProjectSettingsProps } from "../..";
import WorkflowSteps from "./WorkflowSteps";

export const WorkflowSettingsSection = ({ project }: ProjectSettingsProps) => {
  const isFeatureEnabled = useFeatureFlag(Flag.DeployWorkflowSettings);

  const currentTeamUser = useCurrentTeamUser();
  const { data, loading } = useWorkflowDetailQuery({
    variables: {
      projectId: project.id,
      teamId: currentTeamUser.id,
    },
    skip: !isFeatureEnabled,
  });

  if (!isFeatureEnabled) return null;

  const workflow = data?.team?.project?.workflow;

  if (loading || !workflow) {
    return null;
  }

  return (
    <SettingsSection>
      <SettingsSectionDetails title="Workflow settings" />
      <Flex gap="4" flexDirection="column">
        <WorkflowSteps workflow={workflow} />
      </Flex>
    </SettingsSection>
  );
};
