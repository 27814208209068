import {
  Heading,
  PropsOf,
  Spacer,
  Tab,
  TabList,
  TabListProps,
  TabPanel,
  TabPanelProps,
  TabPanels,
} from "@chakra-ui/react";
import { theme, useColors } from "@zeet/web-ui";
import { Link } from "react-router-dom";

export type NavTabType<TabProps = any> = {
  label: string | React.ReactNode;
  to: string;
  path?: string | string[];
  component: React.FC<TabProps>;
  hidden?: boolean;
  routeKey?: string;
  testId?: string;
};

const NavTab = ({ to, hidden, children, ...props }) => {
  const { fg, fg2 } = useColors();

  if (hidden) return null;

  return (
    <Tab
      as={Link}
      _hover={{ textDecoration: "none" }}
      to={to}
      fontSize="14px"
      fontWeight={700}
      px={3}
      mb={0}
      color={fg2}
      _selected={{ borderBottomColor: theme.successColor.hex(), color: fg }}
      minWidth="fit-content"
      {...props}
    >
      {children}
    </Tab>
  );
};

export const NavTabList = ({
  tabs,
  ...props
}: { tabs: NavTabType[] } & TabListProps) => {
  return (
    <TabList border="none" userSelect="none" overflowX="auto" {...props}>
      {tabs.map((tab) => (
        <NavTab
          key={tab.to}
          hidden={tab.hidden}
          to={tab.to}
          data-testid={tab.testId}
        >
          {tab.label}
        </NavTab>
      ))}
    </TabList>
  );
};

export const NavTabPanels = ({
  tabs,
  ...props
}: { tabs: NavTabType[] } & TabPanelProps) => {
  return (
    <TabPanels>
      {tabs.map((tab) => {
        if (tab.hidden) return null;
        return (
          <TabPanel key={tab.to} p={0} {...props}>
            <Spacer mt="6" />
            <tab.component />
          </TabPanel>
        );
      })}
    </TabPanels>
  );
};

export const NavHead: React.FC<PropsOf<typeof Heading>> = ({
  children,
  ...props
}) => {
  return (
    <Heading
      w="100%"
      zIndex="10"
      my={6}
      mb={7}
      size="lg"
      minH="40px"
      fontFamily="Manrope, sans-serif"
      {...props}
    >
      {children}
    </Heading>
  );
};
