import { Button, ButtonGroup, ButtonGroupProps } from "@chakra-ui/react";
import { useState } from "react";
import { useColors } from "..";

export interface ButtonStateOption {
  label: string;
  value: string;
}

interface ButtonStateProps {
  value: string;
  options: ButtonStateOption[];
  onSelected: (options: ButtonStateOption) => void;
}

export const ButtonState: React.FC<ButtonStateProps & ButtonGroupProps> = ({
  value,
  options,
  onSelected,
  ...props
}) => {
  const { brand } = useColors();
  const [currentSelected, setCurrentSelected] = useState<
    ButtonStateOption | undefined
  >(options.find((o) => o.value === value));

  const handleOnOptionClick = (option: ButtonStateOption) => {
    setCurrentSelected(option);
    onSelected(option);
  };

  return (
    <ButtonGroup size="sm" isAttached variant="outline" {...props}>
      {options.map((option, i) => (
        <Button
          isDisabled={props.isDisabled}
          key={i}
          _selected={
            props.isDisabled
              ? {}
              : {
                  background: brand,
                  color: "black",
                }
          }
          onClick={() => handleOnOptionClick(option)}
          {...(currentSelected?.value === option.value
            ? { "data-selected": "true" }
            : {})}
        >
          {option.label}
        </Button>
      ))}
    </ButtonGroup>
  );
};
