import { useIsFormValueSet } from "~/hooks/useIsFormValueSet";
import { ClusterIdInput, InputProvider, StringInput } from "../../../inputs";
import { CodeEditorInput } from "../../../inputs/CodeEditorInput";
import { SettingsSection } from "../../SettingsSection";

export const HelmSettings = () => {
  if (!useIsFormValueSet("configuration.helm")) return null;

  return (
    <SettingsSection
      basePath="helm"
      name="Helm Chart"
      description="Configure additional settings for your Helm Chart"
    >
      <InputProvider basePath="target">
        <ClusterIdInput name="Cluster" path="clusterId" />
        <StringInput
          enabledInDraftOnly
          name="Namespace"
          path="namespace"
          tooltip="The Kubernetes namespace to deploy the chart to. Can only be modified when the project is archived or being drafted."
        />
        <StringInput
          enabledInDraftOnly
          name="Release Name"
          path="releaseName"
          placeholder="(inferred from manifest)"
        />
      </InputProvider>
      <CodeEditorInput
        name="Custom Values"
        tooltip="By providing custom values, you are potentially overriding configuration settings"
        path="values"
      />
    </SettingsSection>
  );
};
