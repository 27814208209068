import { Link as CLink, PropsOf } from "@chakra-ui/react";
import React from "react";
import { Link as RLink } from "react-router-dom";

export type LinkProps = PropsOf<typeof CLink> &
  PropsOf<typeof RLink> & {
    isEmphasized?: true;
    to: string;
    isAbsolute?: boolean;
    hoverColor?: string;
    noUnderline?: boolean;
  };

export const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
  (
    {
      children,
      isEmphasized,
      to,
      isExternal,
      isAbsolute,
      hoverColor,
      noUnderline,
      ...restProps
    },
    ref
  ) => {
    // react router only works with internal routes
    let useChakra = isAbsolute;
    if (typeof to === "string") {
      useChakra = useChakra || to.startsWith("http");

      if (isAbsolute && !to.startsWith("http")) {
        to = "https://" + to;
      }
    }

    const sharedProps = {
      ref,
      fontWeight: isEmphasized ? "bold" : undefined,
      ...(noUnderline ? { _hover: { textDecoration: "none" } } : {}),
      ...restProps,
    };

    return useChakra ? (
      <CLink
        href={to}
        isExternal={true}
        _hover={{ color: hoverColor || "brandVar" }}
        {...sharedProps}
      >
        {children}
      </CLink>
    ) : (
      <CLink as={RLink} to={to} isExternal={isExternal} {...sharedProps}>
        {children}
      </CLink>
    );
  }
);
