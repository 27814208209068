import { CenterLoading, ZError } from "@zeet/web-ui";
import { useProjectV3Context } from "~/components/Project/ProjectV3Provider";
import { ProjectDetailTabProps } from "..";

export const TabWrapper = ({
  component,
}: {
  component: React.FC<ProjectDetailTabProps>;
}) => {
  const { projectInfoLoading, projectInfoData } = useProjectV3Context();

  const project = projectInfoData?.team?.project;

  if (projectInfoLoading) {
    return <CenterLoading />;
  }

  if (!project) {
    return <ZError error={new Error("Resource not found")} />;
  }

  const Component = component as React.FC<ProjectDetailTabProps>;
  return <Component project={project} />;
};
