import React, { createContext, useCallback, useContext, useMemo } from "react";

interface ProjectSettingsContextValue {
  isDraft: boolean;
  addSaveHandler(handler: () => unknown): void;
  removeSaveHandler(handler: () => unknown): void;
}

const ProjectSettingsContext = createContext<ProjectSettingsContextValue>({
  isDraft: true,
  addSaveHandler() {
    // ignored
  },
  removeSaveHandler() {
    // ignored
  },
});

export const useProjectSettings = (): ProjectSettingsContextValue =>
  useContext(ProjectSettingsContext);

interface ProjectSettingsProviderProps extends React.PropsWithChildren {
  isDraft: boolean;
  setSaveHandlers: React.Dispatch<React.SetStateAction<(() => unknown)[]>>;
}

export const ProjectSettingsProvider = ({
  isDraft,
  setSaveHandlers,
  children,
}: ProjectSettingsProviderProps) => {
  const addSaveHandler = useCallback(
    (handler: () => unknown) => {
      setSaveHandlers((a) => [...a, handler]);
    },
    [setSaveHandlers]
  );

  const removeSaveHandler = useCallback(
    (handler: () => unknown) => {
      setSaveHandlers((a) => {
        const index = a.indexOf(handler);
        if (index === -1) return a;
        return [...a.slice(0, index), ...a.slice(index + 1)];
      });
    },
    [setSaveHandlers]
  );

  const contextValue = useMemo(
    () => ({
      isDraft,
      addSaveHandler,
      removeSaveHandler,
    }),
    [isDraft, addSaveHandler, removeSaveHandler]
  );

  return (
    <ProjectSettingsContext.Provider value={contextValue}>
      {children}
    </ProjectSettingsContext.Provider>
  );
};
