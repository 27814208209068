import { useIsFormValueSet } from "~/hooks/useIsFormValueSet";
import { InputRow, StringInput } from "../../../inputs";
import { CloudProviderInput } from "../../../inputs/CloudProviderInput";
import { SettingsSection } from "../../SettingsSection";

export const CloudRunSettings = () => {
  if (!useIsFormValueSet("configuration.gcpCloudRun")) return null;

  return (
    <SettingsSection
      basePath="gcpCloudRun.target"
      name="Cloud Run"
      description="Configure additional settings for your serverless deployment"
    >
      <InputRow>
        <CloudProviderInput name="Cloud" />
        <StringInput name="Region" path="gcpRegion" enabledInDraftOnly />
      </InputRow>
    </SettingsSection>
  );
};
