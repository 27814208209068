import { Box } from "@chakra-ui/react";
import { CenterLoading, NotFound404 } from "@zeet/web-ui";
import NextGenProjectDetail from "~/features/Project/Detail";
import Project from "../Project";
import {
  ProjectV3Provider,
  useProjectV3Context,
} from "../Project/ProjectV3Provider";

export const ProjectBridge = () => {
  return (
    <ProjectV3Provider>
      <WrappedProjectBridge />
    </ProjectV3Provider>
  );
};

const WrappedProjectBridge = () => {
  const { projectData: data, projectLoading } = useProjectV3Context();
  const projectAdapter = data?.user.projectV3Adapters?.nodes[0];
  const legacyProject = projectAdapter?.repo;

  if (projectLoading) {
    return <CenterLoading />;
  }

  if (projectAdapter?.isDeployV2) {
    return (
      <Box data-testid="project-v4-detail">
        <NextGenProjectDetail projectId={projectAdapter.id} />
      </Box>
    );
  }

  if (legacyProject) {
    return (
      <Box data-testid="project-v1-detail">
        <Project />
      </Box>
    );
  }

  return (
    <NotFound404
      heading="404, project not found"
      text="This project doesn't exist... yet. Why not make what you're looking for?"
      primaryButtonLink="/new?tags=All"
      primaryButtonText="New Project"
      secondaryButtonLink="/"
      secondaryButtonText="View my Projects"
    />
  );
};
