import { FormControl } from "@chakra-ui/react";
import { FormLabel, Tooltip } from "@zeet/web-ui";
import React from "react";
import {
  Controller,
  ControllerRenderProps,
  FieldValues,
  Path,
  useFormContext,
} from "react-hook-form";
import { useProjectSettings } from "../context/ProjectSettingsProvider";
import { useInputContext } from "./InputProvider";

interface InputProps<TFormData> {
  name?: string;
  tooltip?: string;
  disabled?: boolean;
  path: Path<TFormData>;
  enabledInDraftOnly?: boolean;
  validationFunction: (value: any) => any;
}

interface InputContainerProps<TFormData extends FieldValues>
  extends InputProps<TFormData> {
  render(
    props: ControllerRenderProps<TFormData, Path<TFormData>> & {
      isReadOnly: boolean;
      "data-testid": string;
    }
  ): React.ReactElement;
}

export const InputContainerWithValidation = <TFormData extends FieldValues>({
  name,
  path,
  tooltip,
  disabled,
  enabledInDraftOnly,
  render,
  validationFunction,
}: InputContainerProps<TFormData>) => {
  const { isDraft } = useProjectSettings();
  const { resolvePath } = useInputContext();
  const { control } = useFormContext<TFormData>();
  const resolvedPath = resolvePath(path) as Path<TFormData>;

  const isReadOnly = disabled || (!!enabledInDraftOnly && !isDraft);

  return (
    <FormControl>
      {name && (
        <FormLabel>
          {name}
          {tooltip && <Tooltip text={tooltip} />}
        </FormLabel>
      )}
      <Controller
        control={control}
        name={resolvedPath}
        rules={{
          validate: validationFunction,
        }}
        render={(p) =>
          render({ ...p.field, isReadOnly, "data-testid": resolvedPath })
        }
      />
    </FormControl>
  );
};
