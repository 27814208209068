import {
  Box,
  Flex,
  FormControl,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  LinodeAccount,
  useRemoveLinodeAccountMutation,
  useUpdateLinodeAccountMutation,
} from "@zeet/web-api/dist/graphql";
import { Button, CopyCode, FormLabel } from "@zeet/web-ui";
import { SettingsDisplayName } from "~/components/Layouts/Settings/SettingsDisplayName";
import {
  SettingsSection,
  SettingsSectionDetails,
  SettingsSections,
} from "../../../../Layouts/Settings";
import { YesNoCell } from "../../../../ListViewV2";
import { CloudDangerZone } from "./CloudDangerZone";
import { TokenModal } from "./TokenModal";

interface LinodeDetailsProps {
  linodeAccount: LinodeAccount;
  refetch: () => void;
}

export const LinodeDetails: React.FC<LinodeDetailsProps> = ({
  linodeAccount,
  refetch,
}) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const toast = useToast();
  const [updateLinode, { loading, error }] = useUpdateLinodeAccountMutation({
    onCompleted: (data) => {
      if (data) {
        onClose();
        refetch();
        toast({
          title: "Token Updated",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    },
  });
  return (
    <SettingsSections>
      <SettingsSection borderTop="none">
        <SettingsSectionDetails title="General" />
        <Flex gap="4" flexDirection="column">
          <FormControl>
            <SettingsDisplayName />
            <FormLabel>Connected</FormLabel>
            <YesNoCell isYes={linodeAccount.connected ?? false} />
            <Box>
              <Button mt={2} variant="secondary" size="sm" onClick={onOpen}>
                Replace Token
              </Button>
              <TokenModal
                link={`https://www.linode.com/docs/products/tools/api/guides/manage-api-tokens/`}
                handleSubmit={(e: string) => {
                  updateLinode({
                    variables: {
                      input: {
                        id: linodeAccount.id,
                        accessToken: e,
                      },
                    },
                  });
                }}
                isOpen={isOpen}
                onClose={onClose}
                loading={loading}
                error={error}
              />
            </Box>
          </FormControl>
          <FormControl>
            <FormLabel>Akamai Linode API Key</FormLabel>
            <CopyCode
              value={`${linodeAccount.accessTokenPrefix}************`}
              width="100%"
            />
          </FormControl>
          <FormControl>
            <FormLabel>Zeet Cloud ID</FormLabel>
            <CopyCode value={linodeAccount?.id ?? ""} width="100%" />
          </FormControl>
        </Flex>
      </SettingsSection>

      <SettingsSection mt={12}>
        <SettingsSectionDetails title="Danger zone" />

        <CloudDangerZone
          id={linodeAccount.id}
          confirmationString={linodeAccount.id}
          useRemoveCloudAccountMutation={useRemoveLinodeAccountMutation}
          refetch={refetch}
        />
      </SettingsSection>
    </SettingsSections>
  );
};
