import {
  Select as ChakraSelect,
  GroupBase,
  MultiValue,
  OptionsOrGroups,
  Props,
  SelectInstance,
  SingleValue,
} from "chakra-react-select";
import { useColors } from "../hooks/useColors";

import {
  CSSWithMultiValues,
  propNames as stylePropNames,
} from "@chakra-ui/react";
import React, {
  ForwardedRef,
  forwardRef,
  ReactElement,
  RefAttributes,
  useEffect,
} from "react";

export const Select: ZeeUISelect = forwardRef(
  <
    T,
    OptionType extends Option<T> = Option<T>,
    IsMulti extends boolean = false,
    Group extends GroupBase<OptionType> = GroupBase<OptionType>
  >(
    {
      chakraStyles,
      styles,
      value,
      options,
      defaultValue,
      onChange,
      ...props
    }: SelectProps<T, OptionType, IsMulti, Group>,
    ref: ForwardedRef<SelectInstance<OptionType, IsMulti, Group>>
  ) => {
    const colors = useColors();
    const [internalValue, setInternalValue] = React.useState<
      OptionType | MultiValue<OptionType> | null
    >(null);
    const valueOption = useOptionsMatcher(value, options);
    const defaultValueOption = useOptionsMatcher(defaultValue, options);
    const { styleProps, selectProps } = extractLegacyStyles(props);
    const menuPortal =
      typeof window !== "undefined" ? document.body : undefined;

    return (
      <ChakraSelect
        useBasicStyles
        chakraStyles={{
          ...chakraStyles,
          container: (baseStyles, state) => ({
            ...baseStyles,
            boxShadow: "md",
            borderRadius: "md",
            fontSize: "14px",
            background: colors.bg2,
            ...styleProps,
            ...(chakraStyles?.container
              ? chakraStyles.container(baseStyles, state)
              : {}),
          }),
          input: (baseStyles, state) => ({
            ...baseStyles,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            ...(chakraStyles?.input
              ? chakraStyles.input(baseStyles, state)
              : {}),
          }),
        }}
        styles={{
          ...styles,
          // not available in chakraStyles
          menuPortal: (baseStyles, state) => ({
            ...baseStyles,
            zIndex: 1500,
            ...(styles?.menuPortal ? styles.menuPortal(baseStyles, state) : {}),
          }),
        }}
        options={options}
        value={
          typeof valueOption === "undefined"
            ? internalValue ?? defaultValueOption
            : valueOption
        }
        menuPlacement="auto"
        onChange={(e, ...rest) => {
          setInternalValue(e);
          onChange?.call(this, e, ...rest);
        }}
        {...selectProps}
        ref={ref}
        menuPortalTarget={menuPortal}
        menuPosition="fixed"
      />
    );
  }
) as ZeeUISelect;

// Select component best suited for forms, overrides on change and provides some helpful defaults
export const FormSelect: ZeeUIFormSelect = forwardRef<
  { value: unknown },
  FormSelectProps<unknown>
>(({ onChange, name, value, ...selectProps }, ref) => {
  const notifyChange = (v: SingleValue<Option<unknown>>) => {
    setLocalValue(v?.value);
    onChange && onChange({ target: { value: v?.value, name } });
  };

  const [localValue, setLocalValue] = React.useState<unknown>(null);
  const trueValue = typeof value !== "undefined" ? value : localValue;

  // fake ref to allow react-hook-form to set a value
  const fakeRef = React.useMemo(
    () => ({
      set value(newVal) {
        setLocalValue(newVal);
      },
    }),
    [setLocalValue]
  );

  useEffect(() => {
    // set the provided ref to our fake one, if any
    if (ref) {
      if (ref instanceof Function) {
        ref(fakeRef);
      } else {
        ref.current = fakeRef;
      }
    }
  }, [ref, fakeRef]);

  return (
    <Select
      {...selectProps}
      isSearchable={false}
      value={trueValue}
      name={name}
      onChange={notifyChange}
    />
  );
}) as ZeeUIFormSelect;

// extracts chakra styles applied to the root props of the select and separates them from the react-select props
function extractLegacyStyles<
  T,
  OptionType extends Option<T> = Option<T>,
  IsMulti extends boolean = false,
  Group extends GroupBase<OptionType> = GroupBase<OptionType>
>(props: SelectProps<T, OptionType, IsMulti, Group>) {
  const styleProps = {};
  const selectProps = {};
  Object.entries(props).forEach(([key, val]) => {
    if (stylePropNames.includes(key)) styleProps[key] = val;
    else selectProps[key] = val;
  });

  return { styleProps, selectProps };
}

// simple hook that takes a value "x" and returns its matching {value: "x", label: ... } from an array
function useOptionsMatcher<T, OptionType extends Option<T>>(
  value: T,
  options?: OptionsOrGroups<OptionType, GroupBase<OptionType>>
) {
  const [match, setMatch] = React.useState<OptionType | null | undefined>(null);

  React.useEffect(() => {
    if (!value || !options) {
      setMatch(value as undefined | null); // this is important! react-select will only automatically handle value if its undefined, but will set it to empty if null
      return;
    }

    setMatch(
      options
        .flatMap((o) => ("options" in o ? o.options : o))
        .find((o) => o.value === (value as any)?.value || o.value === value)
    );
  }, [value, options]);

  return match;
}

export type Option<T> = {
  label?: string | null;
  value: T;
  isDisabled?: boolean;
};

type ZeeUISelect = <
  T = unknown,
  OptionType extends Option<T> = Option<T>,
  IsMulti extends boolean = false,
  Group extends GroupBase<OptionType> = GroupBase<OptionType>
>(
  props: SelectProps<T, OptionType, IsMulti, Group> &
    RefAttributes<SelectInstance<OptionType, IsMulti, Group>>
) => ReactElement;

type ZeeUIFormSelect = <
  T = unknown,
  OptionType extends Option<T> = Option<T>,
  IsMulti extends boolean = false,
  Group extends GroupBase<OptionType> = GroupBase<OptionType>
>(
  props: FormSelectProps<T, OptionType, IsMulti, Group> &
    RefAttributes<SelectInstance<OptionType, IsMulti, Group>>
) => ReactElement;

interface FormSelectProps<
  T,
  OptionType extends Option<T> = Option<T>,
  IsMulti extends boolean = false,
  Group extends GroupBase<OptionType> = GroupBase<OptionType>
> extends Omit<SelectProps<T, OptionType, IsMulti, Group>, "onChange"> {
  onChange?(e: { target: { value: T; name?: string } }): unknown;
}

type SelectProps<
  T,
  OptionType extends Option<T> = Option<T>,
  IsMulti extends boolean = false,
  Group extends GroupBase<OptionType> = GroupBase<OptionType>
> = Omit<Omit<Props<OptionType, IsMulti, Group>, "defaultValue">, "value"> &
  CSSWithMultiValues & {
    value?: T;
    defaultValue?: T;
  };
