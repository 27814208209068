import { Box, Text, useColorMode, useDisclosure } from "@chakra-ui/react";
import {
  UserBillingOverviewDocument,
  UserCloudsDocument,
} from "@zeet/web-api/dist/graphql";
import {
  Button,
  DeleteDialog,
  useCurrentTeamUser,
  useTrack,
  ZError,
} from "@zeet/web-ui";

interface CloudDangerZoneProps {
  id: string;
  confirmationString: string;
  refetch: () => void;
  useRemoveCloudAccountMutation: any;
}

export const CloudDangerZone: React.FC<CloudDangerZoneProps> = ({
  id,
  confirmationString,
  refetch,
  useRemoveCloudAccountMutation,
}) => {
  const { colorMode } = useColorMode();
  const { track } = useTrack();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const user = useCurrentTeamUser();

  const [removeAccount, { error, loading: deleteCloudLoading }] =
    useRemoveCloudAccountMutation({
      awaitRefetchQueries: true,
      errorPolicy: "none",
    });

  return (
    <Box>
      <Text fontWeight="600" fontSize="14px">
        Unlink this Cloud
      </Text>

      <Text
        fontSize="14px"
        mt={1}
        color={colorMode === "dark" ? "gray.400" : "gray.600"}
      >
        Please ensure there are no Projects using this account.
      </Text>

      <ZError error={error} />

      <Button
        variant="primary"
        mt={6}
        alignSelf="center"
        borderRadius={4}
        fontSize="14px"
        colorScheme="red"
        isLoading={deleteCloudLoading}
        isDisabled={deleteCloudLoading}
        onClick={onOpen}
        data-testid="delete-cloud-button"
      >
        Unlink Cloud
      </Button>

      <DeleteDialog
        confirmationString={confirmationString}
        onClose={onClose}
        onFail={() => track("delete_cloud_error")}
        onSuccess={() => {
          track("delete_cloud_success");
          refetch();
        }}
        onDelete={() =>
          removeAccount({
            variables: {
              id,
            },
            refetchQueries: [
              {
                query: UserCloudsDocument,
                variables: {
                  id: user.id,
                },
              },
              {
                query: UserBillingOverviewDocument,
                variables: { id: user?.id },
              },
            ],
          })
        }
        isLoading={deleteCloudLoading}
        isOpen={isOpen}
        title="Unlink Cloud"
        action={{
          pastTense: "unlinked",
          presentTense: "unlink",
        }}
        type="cloud"
        warningMessage={
          <Box>
            This action cannot be undone. This will permanently unlink Cloud{" "}
            <Text as="b">{confirmationString}</Text>.
          </Box>
        }
      />
    </Box>
  );
};
