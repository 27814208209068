import { Flex } from "@chakra-ui/react";
import {
  BlueprintType,
  ClusterPrecheckStep,
  GenericWorkflowStepActionType,
  ProjectInfoFragment,
  ProjectStatus,
  useProjectOutputsQuery,
  WorkflowRunStepStatus,
} from "@zeet/web-api/dist/graphqlv1";
import {
  AnalyticsEvent,
  CenterLoading,
  Container,
  Flag,
  NotFound404,
  StatusBadge,
  useCurrentTeamUser,
  useFeatureFlag,
  useTrack,
} from "@zeet/web-ui";
import { useEffect, useMemo } from "react";
import { NavTabType } from "~/components/Layouts/NavTab";
import { TabbedLayout } from "~/components/Layouts/TabbedLayout";
import { useProjectV3Context } from "~/components/Project/ProjectV3Provider";
import { PendingClusterBanner } from "~/components/Project/WarningBanner";
import ArtifactsTab from "./ArtifactsTab";
import BuildTab from "./BuildTab";
import { DatabaseConnectAction } from "./components/DatabaseConnectAction";
import { DeploymentRevisionsTab } from "./DeploymentRevisionsTab";
import DeploymentsTab from "./DeploymentsTab";
import { useProjectPath } from "./hooks/useProjectPath";
import JobsTab from "./JobsTab";
import OverviewTab from "./OverviewTab";
import { ResourcesTab } from "./ResourcesTab";
import SettingsTab from "./SettingsTab";
import { WorkflowRunTab } from "./WorkflowsTab";

export interface ProjectDetailTabProps {
  project: ProjectInfoFragment;
}

const ProjectDetailActions: React.FC<{ actions: React.ReactElement[] }> = ({
  actions,
}) => {
  return (
    <Flex flexDir="row" gap="4" mb="4">
      {actions
        .filter((action) => !!action)
        .map((action, index) => (
          <Flex key={index} flexDir="column" gap="2">
            {action}
          </Flex>
        ))}
    </Flex>
  );
};

const ProjectDetail = ({ projectId }: { projectId?: string }) => {
  const { track } = useTrack();
  const currentTeamUser = useCurrentTeamUser();
  const {
    projectStatus,
    projectInfoData: data,
    projectInfoLoading: loading,
  } = useProjectV3Context();

  const { data: projectOutputsData } = useProjectOutputsQuery({
    variables: {
      teamId: currentTeamUser?.id,
      projectId: projectId ?? "",
    },
    skip: !projectId,
    pollInterval: 3000,
  });

  const project = data?.team?.project;
  const path = useProjectPath();

  const isObservabilityResourcesEnabled = useFeatureFlag(
    Flag.ObservabilityResources,
    {
      devEnabled: true,
    }
  );

  const clusterID = useMemo(() => {
    const workflowRun = data?.team?.project?.workflow?.runs.nodes.find((n) =>
      n.steps?.find(
        (s) =>
          s?.action ===
            GenericWorkflowStepActionType.OrchestrationClusterPrecheck &&
          s?.status === WorkflowRunStepStatus.Running
      )
    );

    const step = workflowRun?.steps?.find(
      (s) =>
        s?.action ===
          GenericWorkflowStepActionType.OrchestrationClusterPrecheck &&
        s?.status === WorkflowRunStepStatus.Running
    ) as ClusterPrecheckStep;

    return step?.cluster?.id;
  }, [data]);

  useEffect(() => {
    const status = projectStatus?.team?.project?.status;
    if (status === ProjectStatus.DeploySucceeded) {
      track(AnalyticsEvent.VIEW_DEPLOYED_PROJECT, {
        project_id: projectStatus?.team?.project?.id,
      });
    }
  }, [projectStatus, track]);

  if (loading) {
    return <CenterLoading />;
  }

  if (!project) {
    return (
      <NotFound404
        heading="404, project not found"
        text="This project doesn't exist... yet. Why not make what you're looking for?"
      />
    );
  }

  const tabs: NavTabType<ProjectDetailTabProps>[] = [
    {
      label: "Overview",
      to: `${path}`,
      testId: "project-overview-tab",
      component: () => <OverviewTab project={project} />,
    },
    {
      label: "Workflows",
      to: `${path}/workflows`,
      testId: "project-workflows-tab",
      component: () => <WorkflowRunTab project={project} />,
      hidden: project.workflow === null,
    },
    {
      label: "Resources",
      to: `${path}/resources`,
      testId: "project-resources-tab",
      component: () => (
        <Container fullWidth>
          <ResourcesTab project={project} />
        </Container>
      ),
      hidden:
        !isObservabilityResourcesEnabled ||
        project.blueprint?.type === BlueprintType.Terraform ||
        project.blueprint?.type === BlueprintType.KubernetesManifest ||
        project.blueprint?.type === BlueprintType.AwsSam ||
        project.blueprint?.type === BlueprintType.GcpCloudRun,
    },
    {
      label: "Deployments",
      testId: "project-deployments-tab",
      to: `${path}/deployments`,
      component: () => <DeploymentsTab project={project} />,
      hidden:
        project.deploys.totalCount === 0 ||
        project.blueprint?.type === BlueprintType.Terraform ||
        project.blueprint?.type === BlueprintType.Helm ||
        project.blueprint?.type === BlueprintType.KubernetesManifest,
    },
    {
      label: "Revisions",
      testId: "project-revisions-tab",
      to: `${path}/revisions`,
      component: DeploymentRevisionsTab,
      hidden: project.deploys.totalCount === 0,
    },
    {
      label: "Builds",
      to: `${path}/builds`,
      testId: "project-builds-tab",
      component: () => <BuildTab project={project} />,
      hidden: !project.build,
    },
    {
      label: "Jobs",
      to: `${path}/jobs`,
      testId: "project-jobs-tab",
      component: () => <JobsTab project={project} />,
      hidden: project.jobs?.length === 0,
    },
    {
      label: "Artifacts",
      testId: "project-artifacts-tab",
      to: `${path}/artifacts`,
      component: () => <ArtifactsTab project={project} />,
      hidden: !project.build,
    },
    {
      label: "Settings",
      testId: "project-settings-tab",
      to: `${path}/settings`,
      component: () => <SettingsTab project={project} />,
    },
  ];

  return (
    <TabbedLayout
      pageTitle="Project"
      title={project.name}
      titleDecorator={
        <StatusBadge
          status={projectStatus?.team?.project?.status ?? project.status}
          fontSize="0.9rem"
          ml={4}
          borderRadius="md"
        />
      }
      banner={<PendingClusterBanner clusterId={clusterID} />}
      actions={
        <ProjectDetailActions
          actions={[
            <DatabaseConnectAction projectOutputs={projectOutputsData} />,
          ]}
        />
      }
      tabs={tabs}
      isLazy
    />
  );
};

export default ProjectDetail;
