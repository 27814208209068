import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Divider,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  Text,
} from "@chakra-ui/react";
import {
  TeamMemberRole,
  useUserTeamMemberQuery,
} from "@zeet/web-api/dist/graphql";
import {
  Button,
  useCurrentTeam,
  useCurrentTeamUser,
  useTrack,
} from "@zeet/web-ui";
import React, { useMemo } from "react";
import { AiOutlinePlusSquare } from "react-icons/ai";
import { BiPlus } from "react-icons/bi";
import {
  MdOutlineFilterDrama,
  MdOutlinePersonAdd,
  MdOutlineSchema,
} from "react-icons/md";
import { TbBinaryTree2 } from "react-icons/tb";
import { CreateMenuItem } from "./CreateMenuItem";

interface CreateMenuCategoryProps {
  label: string;
  withDivider?: boolean;
}

const CreateMenuCategory = ({
  label,
  withDivider,
}: CreateMenuCategoryProps) => {
  return (
    <>
      {withDivider && <Divider m="8px" w="auto" />}
      <Box>
        <Text fontWeight="600" fontSize="14px" opacity="0.4" pl="8px" py="6px">
          {label.toUpperCase()}
        </Text>
      </Box>
    </>
  );
};

export const CreateMenu: React.FC = () => {
  const { track } = useTrack();
  const currentTeam = useCurrentTeam();
  const currentTeamUser = useCurrentTeamUser();
  const { data: teamData } = useUserTeamMemberQuery({
    variables: {
      id: currentTeam?.id,
    },
  });

  const userIsOwnerOrAdmin = useMemo(() => {
    return [TeamMemberRole.Owner, TeamMemberRole.Admin].includes(
      teamData?.currentUser.team?.members.find(
        (mem) => mem.user.id === teamData.currentUser.id
      )?.role || TeamMemberRole.Viewer
    );
  }, [teamData]);

  return (
    <>
      <Menu placement="bottom-end">
        <MenuButton
          aria-label="create"
          variant="secondary"
          as={Button}
          size="sm"
          ml={2}
          mr={4}
          style={{ textDecoration: "none" }}
          onClick={() => track("click_nav_new")}
          rightIcon={<ChevronDownIcon />}
          py="4"
        >
          <Flex align="center" gap="1">
            <BiPlus />
            <Text display={["none", "block"]}>New</Text>
          </Flex>
        </MenuButton>
        <MenuList px="8px">
          <CreateMenuCategory label="Deploy" />
          <CreateMenuItem
            title="Project"
            description={
              "Deploy a Container, Database, Terraform, Helm, Serverless, or other Project"
            }
            link={`/new`}
            icon={MdOutlineSchema}
            trackEvent={"new_project"}
            currentProductTypeName="ProjectPack"
            productUnitName="project"
            productSectionName="Projects"
          />
          {userIsOwnerOrAdmin && (
            <CreateMenuItem
              title="Blueprint"
              description={
                "Create a reusable Blueprint and make it available for your team to deploy"
              }
              link={`/${currentTeamUser.login}/console/blueprints/new`}
              icon={AiOutlinePlusSquare}
              trackEvent={"new_blueprint"}
              currentProductTypeName="PlanTierV2"
              productUnitName="custom blueprint"
              productSectionName="Plan"
            />
          )}
          <CreateMenuCategory label="Connect" withDivider />
          <CreateMenuItem
            title="Cluster"
            description={"Add a Cluster to scale to more clouds or regions"}
            link={`/${currentTeamUser.login}/console/clusters/new/`}
            icon={TbBinaryTree2}
            trackEvent={"new_cluster"}
            currentProductTypeName="PlanTierV2"
            productUnitName="cluster"
            productSectionName="Plan"
          />
          <CreateMenuItem
            title="Cloud"
            description={"Link a new Cloud Provider or Cloud Account"}
            link={`/${currentTeamUser.login}/console/clouds/new`}
            icon={MdOutlineFilterDrama}
            trackEvent={"new_cloud"}
            currentProductTypeName="PlanTierV2"
            productUnitName="cloud"
            productSectionName="Plan"
          />
          <CreateMenuCategory label="Team" withDivider />
          <CreateMenuItem
            title="Team Member"
            description={"Invite a team member to your Zeet account"}
            link={`/${currentTeamUser.login}/console/people/zeet/new`}
            icon={MdOutlinePersonAdd}
            trackEvent={"new_member"}
            currentProductTypeName="PlanTierV2"
            productUnitName="seat"
            productSectionName="Plan"
          />
        </MenuList>
      </Menu>
    </>
  );
};
