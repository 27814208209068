import {
  BoxProps,
  Flex,
  Heading,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { Card, Skeleton, useColors } from "@zeet/web-ui";
import Color from "color";

export type ListHeaderItem = {
  label: string;
};

interface ListHeaderProps {
  columns: ListHeaderItem[];
}

export const ListItemActionPill = ({
  children,
  onClick,
}: {
  children: React.ReactNode;
  onClick?: (e: React.MouseEvent) => void;
}) => {
  const { fg, fg3 } = useColors();

  const bgHover = useColorModeValue(Color(fg3).lighten(2.5).hex(), fg3);

  return (
    <Flex
      border="1px solid var(--chakra-colors-chakra-border-color)"
      color={fg}
      borderRadius="full"
      px="1"
      py="0.5"
      alignItems="center"
      justifyContent="center"
      opacity={0.7}
      _hover={{ opacity: 1, bg: bgHover }}
      transition="all 0.2s ease"
      cursor="pointer"
      onClick={(e) => {
        e.stopPropagation();
        onClick?.(e);
      }}
    >
      {children}
    </Flex>
  );
};

interface ListItemProps {
  onClick: () => void;
  children: React.ReactNode;
}

export const ListItem = ({ onClick, children }: ListItemProps) => {
  const { bgHover } = useColors();

  return (
    <Flex
      _notLast={{ borderBottomWidth: "1px" }}
      py="3"
      px="4"
      _hover={{
        bg: bgHover,
        cursor: "pointer",
      }}
      alignItems="center"
      onClick={onClick}
      transition="all 0.2s ease"
    >
      {children}
    </Flex>
  );
};

const ListHeader = ({ columns }: ListHeaderProps) => {
  return (
    <Flex
      px="4"
      py="2"
      borderBottom="1px solid var(--chakra-colors-chakra-border-color)"
      fontSize="sm"
      color="gray.500"
    >
      {columns.map((column, i) => (
        <Text
          key={i}
          fontWeight="bold"
          flex="1"
          textAlign={i === 0 ? "left" : "right"}
        >
          {column.label}
        </Text>
      ))}
    </Flex>
  );
};

interface ListItemsLoadingProps {
  count?: number;
}

export const ListItemsLoading = ({ count }: ListItemsLoadingProps) => {
  return (
    <Stack gap="1px">
      {[...Array(count ?? 12)].map((_, i) => (
        <Skeleton key={i} height="48px" borderRadius="0" />
      ))}
    </Stack>
  );
};

interface ListProps {
  columns: ListHeaderItem[];
  title?: string;
  children?: React.ReactNode;
}

export const List = ({
  columns,
  title,
  children,
  ...props
}: ListProps & BoxProps) => {
  return (
    <Card minH="600px" pt="0" {...props}>
      {title && (
        <Heading
          size="sm"
          px="4"
          py="4"
          borderBottom="1px solid var(--chakra-colors-chakra-border-color)"
        >
          {title}
        </Heading>
      )}
      <Stack gap="0">
        <ListHeader columns={columns} />
        {children}
      </Stack>
    </Card>
  );
};
