import { Button, ButtonProps, Container, useColors } from "@zeet/web-ui";
import React from "react";
import { MdArrowBack } from "react-icons/md";
import { useHoverStates } from "~/hooks/useHoverStates";

interface BackNavButtonProps extends Omit<ButtonProps, "variant"> {
  linkText: string;
  linkTo: string;
}

export const BackNavLayout = ({
  linkText,
  linkTo,
  children,
  ...rest
}: React.PropsWithChildren<BackNavButtonProps>) => {
  return (
    <Container fullWidth py={4}>
      <BackNavButton
        linkText={linkText}
        linkTo={linkTo}
        my={2}
        mx={4}
        {...rest}
      />
      {children}
    </Container>
  );
};

export const BackNavButton = ({
  linkText,
  linkTo,
  ...rest
}: BackNavButtonProps) => {
  const { fg2 } = useColors();
  const hoverStates = useHoverStates(fg2, "color");
  return (
    <Button
      asLink
      to={linkTo}
      variant="ghost"
      color={fg2}
      leftIcon={<MdArrowBack />}
      p="0"
      fontWeight={500}
      height="auto"
      {...hoverStates}
      {...rest}
    >
      {linkText}
    </Button>
  );
};
