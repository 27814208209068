import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";
import {
  DefaultOptions,
  NormalizedCacheObject,
  ServerError,
} from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { WebSocketLink } from "@apollo/client/link/ws";
import { MultiAPILink } from "@habx/apollo-multi-endpoint-link"; // cspell:disable-line
import introspection, { ErrorCode } from "@zeet/web-api/dist/graphql";
import introspectionV1 from "@zeet/web-api/dist/graphqlv1";
import { useAccessToken } from "@zeet/web-ui";
import { createUploadLink } from "apollo-upload-client";
import { useMemo } from "react";
import config from "~/utils/config";

const defaultOptions: DefaultOptions = {
  watchQuery: {
    errorPolicy: "all",
  },
  query: {
    errorPolicy: "all",
  },
  mutate: {
    errorPolicy: "all", // TODO: we need to migrate this to "none", because right now this won't trigger the onError callback ZEET-1879
  },
};

const apolloClient = (at?: string): ApolloClient<NormalizedCacheObject> => {
  const authHeader = at
    ? {
        authorization: `Bearer ${at}`,
      }
    : {};

  //define everything
  const httpLink = createUploadLink({
    uri: config.ANCHOR_URL + "/graphql",
    headers: {
      ...authHeader,
    },
  });

  const wsLink = (url) =>
    new WebSocketLink({
      uri: url,
      options: {
        reconnect: true,
        lazy: true,
        connectionParams: {
          ...authHeader,
        },
      },
    });

  const teamHeaderLink = setContext((_, prevContext) => {
    const { awsAccountId } = prevContext;

    if (awsAccountId) {
      return {
        ...prevContext,
        headers: {
          "X-Zeet-Aws-Account-Id": awsAccountId,
        },
      };
    } else {
      return prevContext;
    }
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(
        ({ message, locations, path, extensions }): void => {
          console.error(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          );

          if (extensions?.code === ErrorCode.NeedAuth) {
            // logout();
          }
        }
      );
    }
    if (networkError) {
      console.error(`[Network error]: ${networkError}`);
      if ((networkError as ServerError).statusCode === 401) {
        // logout();
      } else {
        if (networkError.message === "Failed to fetch") {
          // do something rip
        }
      }
    }
  });

  const retryLink = new RetryLink();

  const client = new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        BlueprintsMarketplace: {
          keyFields: [], // Temp fix to cache marketplace since there is no id
        },
        CheckPriceOutput: {
          merge: true,
        },
        Repo: { fields: { envs: { merge: false } } },
        LogEntry: {
          merge: false,
        },
        Deployment: {
          fields: {
            logs: {
              merge: false,
            },
          },
        },
        CustomDomain: {
          keyFields: false,
        },
        DeploymentConfiguration: {
          keyFields: (obj) => {
            if (obj.revisionMetadata) {
              return ["id", "revisionMetadata"];
            }

            return ["id"];
          },
        },
        User: {
          fields: {
            blueprints: {
              keyArgs: false,
              merge(existing, incoming) {
                if (!existing) return incoming;
                const newNodes = [
                  ...(existing.nodes || []),
                  ...(incoming.nodes || []),
                ];
                const uniqueNodes = newNodes.filter((n, i, a) => {
                  return a.findIndex((x) => x.__ref === n.__ref) === i;
                });
                return {
                  ...incoming,
                  nodes: uniqueNodes,
                };
              },
            },
            billingOverview: {
              merge(existing, incoming, { mergeObjects }) {
                return mergeObjects(existing, incoming);
              },
            },
          },
        },
        EnvVar: {
          keyFields: ["id", "name"],
        },
      },
      possibleTypes: [
        introspection.possibleTypes,
        introspectionV1.possibleTypes,
      ].reduce((acc, map) => {
        Object.keys(map).forEach((key) => {
          acc[key] = [...new Set([...(acc[key] || []), ...(map[key] ?? [])])];
        });
        return acc;
      }, {}),
    }),
    link: ApolloLink.from([
      teamHeaderLink,
      new MultiAPILink({
        endpoints: {
          anchor: config.ANCHOR_URL + "/graphql",
          cloudquery: config.ANCHOR_URL + "/cloudquery",
          v1: config.ANCHOR_URL + "/v1/graphql",
        },
        httpSuffix: "",
        wsSuffix: "",
        defaultEndpoint: "anchor",
        createWsLink: wsLink,
        createHttpLink: () => httpLink,
      }),
      retryLink,
      errorLink,
    ]),
    defaultOptions: defaultOptions,
  });
  return client;
};

export function useApolloClient(): ApolloClient<NormalizedCacheObject> {
  //grab the access token
  const [at] = useAccessToken();

  const client = useMemo(() => apolloClient(at), [at]);

  return client;
}
