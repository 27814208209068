import { Box, Image, Stack, Text } from "@chakra-ui/react";
import { UserDeployTargetsQuery } from "@zeet/web-api/dist/graphql";
import { AWSLogo, GCPLogo, K8SLogo, LinodeLogo, ZeetLogo } from "@zeet/web-ui";
import React from "react";
import { NIL as NIL_UUID } from "uuid";
import cwLogo from "~/images/coreweave/logo.png";
import doLogo from "~/images/digitalocean/logo.png";

export const ClusterOption: React.FC<{
  clusterId?: string;
  clusters?: UserDeployTargetsQuery["user"]["clusters"];
}> = ({ clusterId, clusters }) => {
  const cluster = clusters?.filter((c) => c.id === clusterId)?.[0];

  if (clusterId === NIL_UUID) {
    return (
      <Stack fontSize="sm" fontWeight="bold" alignItems="center" isInline>
        {cluster?.name !== "disabled" && (
          <Box>
            <ZeetLogo />
          </Box>
        )}
        <Text>{cluster?.name}</Text>
      </Stack>
    );
  }

  if (cluster?.awsAccount?.accountID) {
    return (
      <Stack fontSize="sm" fontWeight="bold" alignItems="center" isInline>
        <Box>
          <K8SLogo height="0.9rem" />
        </Box>
        <Text>{cluster?.name}</Text>
        <Box>
          <AWSLogo height="0.9rem" />
        </Box>
        <Text>{cluster.awsAccount.name || cluster.awsAccount.accountID}</Text>
      </Stack>
    );
  }

  if (cluster?.gcpAccount?.projectID) {
    return (
      <Stack fontSize="sm" fontWeight="bold" alignItems="center" isInline>
        <Box>
          <K8SLogo height="0.9rem" />
        </Box>
        <Text>{cluster?.name}</Text>
        <Box>
          <GCPLogo height="0.9rem" />
        </Box>
        <Text>{cluster.gcpAccount.name || cluster.gcpAccount.projectID}</Text>
      </Stack>
    );
  }

  if (cluster?.doAccount?.defaultProject) {
    return (
      <Stack fontSize="sm" fontWeight="bold" alignItems="center" isInline>
        <Box>
          <K8SLogo height="0.9rem" />
        </Box>
        <Text>{cluster?.name}</Text>
        <Box>
          <Image height="0.9rem" src={doLogo} />
        </Box>
        <Text>
          {cluster.doAccount.name || cluster.doAccount.defaultProject}
        </Text>
      </Stack>
    );
  }

  if (cluster?.cwAccount?.namespace) {
    return (
      <Stack fontSize="sm" fontWeight="bold" alignItems="center" isInline>
        <Box>
          <K8SLogo height="0.9rem" />
        </Box>
        <Text>{cluster?.name}</Text>
        <Box>
          <Image height="0.9rem" src={cwLogo} />
        </Box>
        <Text>{cluster.cwAccount.name || cluster.cwAccount.namespace}</Text>
      </Stack>
    );
  }

  if (cluster?.linodeAccount?.accessTokenPrefix) {
    return (
      <Stack fontSize="sm" fontWeight="bold" alignItems="center" isInline>
        <Box>
          <K8SLogo height="0.9rem" />
        </Box>
        <Text>{cluster?.name}</Text>
        <Box>
          <LinodeLogo height="0.9rem" />
        </Box>
        <Text>
          {cluster?.linodeAccount.name ||
            cluster?.linodeAccount.accessTokenPrefix}
        </Text>
      </Stack>
    );
  }

  return (
    <Stack fontSize="sm" fontWeight="bold" alignItems="center" isInline>
      <Box>
        <K8SLogo height="0.9rem" />
      </Box>
      <Text>{cluster?.name}</Text>
    </Stack>
  );
};
