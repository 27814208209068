import { DeleteFlow, DeletionType } from "@zeet/web-ui";
import {
  SettingsSection,
  SettingsSectionDetails,
} from "~/components/Layouts/Settings";
import { ProjectSettingsProps } from "../..";
import { isDeletable } from "../../../utils";
import { useDeleteProject } from "./useDeleteProject";
import { useDeleteResource } from "./useDeleteResources";
import { useDeleteProjectAndResources } from "./useDeleteResourcesAndProject";

export const DangerZone = ({ project }: ProjectSettingsProps) => {
  const { deleteResources } = useDeleteResource({
    projectId: project?.id,
  });

  const { deleteProject } = useDeleteProject();
  const { deleteProjectAndResources } = useDeleteProjectAndResources({
    projectId: project?.id,
  });

  const canDelete = isDeletable(project.status);
  return (
    <SettingsSection>
      <SettingsSectionDetails title="Danger zone" />

      <DeleteFlow
        callback={async (type, force) => {
          if (type === DeletionType.Delete) {
            await deleteProject({
              variables: {
                id: project.id,
                force: force ?? false,
              },
            });
          }
          if (type === DeletionType.DeleteWithResources) {
            await deleteProjectAndResources({
              variables: {
                id: project.id,
                deleteOnFailure: force,
              },
            });
          }
          if (type === DeletionType.DestroyResources) {
            await deleteResources({
              variables: {
                id: project.id,
              },
            });
          }
        }}
        projectName={project.name}
        canDelete={canDelete}
        canDestroy={!canDelete}
        canDestroyResources={!canDelete}
        canPause={false}
        canResume={false}
        canUnlink={!canDelete}
      />
    </SettingsSection>
  );
};
