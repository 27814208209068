import { CopyIcon } from "@chakra-ui/icons";
import {
  Alert,
  Box,
  Flex,
  FormControl,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import {
  BlueprintType,
  ProjectDetailFragment,
  useDuplicateProjectMutation,
} from "@zeet/web-api/dist/graphqlv1";
import {
  Button,
  FormLabel,
  Option,
  useCurrentTeamUser,
  ZError,
} from "@zeet/web-ui";
import { CreatableSelect } from "chakra-react-select";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useProject } from "~/components/Project/Provider";
import { useProjectEnvironmentList } from "~/components/utils/useProjectEnvironmentList";
import { SettingsSection } from "../SettingsSection";

interface IdOrName {
  id?: string | null;
  name?: string | null;
}

interface DuplicateValues {
  name: string;
  group: IdOrName;
  subGroup: IdOrName;
}

interface DuplicateProjectButtonProps {
  project: ProjectDetailFragment;
}

export const DuplicateProjectSection = ({
  project,
}: DuplicateProjectButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const toast = useToast();
  const currentTeamUser = useCurrentTeamUser();
  const currentProject = useProject();

  const projectId = project?.id;
  const projectName = project?.name;

  const { control, register, handleSubmit, setValue } =
    useForm<DuplicateValues>({
      defaultValues: {
        name: projectName + "-clone",
        group: { id: currentProject.id, name: null },
        subGroup: {
          id:
            "defaultEnvironment" in currentProject
              ? currentProject?.defaultEnvironment?.id
              : "",
          name: null,
        },
      },
    });

  const {
    availableEnvironments: availableSubGroups,
    availableProjects: availableGroups,
    selectProject,
  } = useProjectEnvironmentList();

  const [duplicateProjectMutation, { loading, error }] =
    useDuplicateProjectMutation({
      onCompleted: (data) => {
        if (!data) return;
        setIsOpen(false);
        toast({
          title: "Project Successfully Duplicated!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        history.push(
          `/${currentTeamUser.login}/${data.duplicateProject.group?.name}/${data.duplicateProject.subGroup?.name}/${data.duplicateProject.name}`
        );
      },
    });

  const submit = ({
    name,
    group: project,
    subGroup: environment,
  }: DuplicateValues) => {
    if (
      !projectId ||
      (!environment.id && !environment.name) ||
      (!project.id && !project.name)
    ) {
      return;
    }

    duplicateProjectMutation({
      variables: {
        id: projectId,
        input: {
          name,
          subGroupId: environment.id,
          subGroupName: environment.name,
          groupId: project.id,
          groupName: project.name,
        },
      },
    });
  };

  const projOptions: Option<IdOrName>[] | undefined =
    useMapToOptions(availableGroups);

  const envOptions: Option<IdOrName>[] | undefined =
    useMapToOptions(availableSubGroups);

  // update our selection whenever the available environments change
  useEffect(() => {
    setValue("subGroup", {
      id: availableSubGroups?.length ? availableSubGroups[0]?.id : null,
      name: null,
    });
  }, [availableSubGroups, setValue]);

  const findOption = (value: IdOrName, options?: Option<IdOrName>[]) =>
    options?.find(
      (o) =>
        o.value.id === value.id || (o.value.name && o.value.name === value.name)
    ) || null;

  const blueprintType = project?.blueprint?.type;
  const warningMessage = getDuplicateProjectWarning(blueprintType);

  return (
    <SettingsSection
      name="Duplicate"
      description="Create a new project with the same settings. Commonly this is used to create another environment for a project."
    >
      <Box>
        {warningMessage && (
          <Alert status="warning" mb="4" px="6" fontSize="sm">
            <Text>{warningMessage}</Text>
          </Alert>
        )}
        <Button variant="secondary" onClick={() => setIsOpen(true)}>
          <Text mr={3}>Duplicate Project</Text>
          <CopyIcon />
        </Button>
        <Modal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          size="xl"
          isCentered
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalHeader>Duplicate {projectName}</ModalHeader>
            <ModalBody pb="2rem">
              <FormControl mb={2}>
                <FormLabel>New Project Name</FormLabel>
                <Input {...register("name", { required: true })} />
              </FormControl>
              <FormControl mb={2}>
                <FormLabel>Group</FormLabel>
                <Controller
                  control={control}
                  name="group"
                  rules={{ required: true }}
                  render={({ field: { value, onChange, ...rest } }) => (
                    <CreatableSelect
                      {...rest}
                      placeholder="Select Group"
                      isSearchable={true}
                      required={true}
                      useBasicStyles
                      value={findOption(value, projOptions)}
                      onChange={(e) => {
                        onChange(e?.value);
                        selectProject(e?.value.id);
                      }}
                      options={projOptions}
                      onCreateOption={(val) => {
                        const newVal = { name: val, id: null };
                        projOptions?.push({
                          value: newVal,
                          label: val,
                        });
                        onChange(newVal);
                        selectProject(null);
                      }}
                    />
                  )}
                />
              </FormControl>
              <FormControl mb={2}>
                <FormLabel>Subgroup</FormLabel>
                <Controller
                  control={control}
                  name="subGroup"
                  rules={{ required: true }}
                  render={({ field: { value, onChange, ...rest } }) => (
                    <CreatableSelect
                      {...rest}
                      placeholder="Select Sub-Group"
                      isSearchable={true}
                      required={true}
                      useBasicStyles
                      value={findOption(value, envOptions)}
                      onChange={(e) => onChange(e?.value)}
                      options={envOptions}
                      onCreateOption={(val) => {
                        const newVal = { name: val, id: null };
                        envOptions?.push({
                          value: newVal,
                          label: val,
                        });
                        onChange(newVal);
                      }}
                    />
                  )}
                />
              </FormControl>
              <ZError error={error} />
              <Flex justifyContent="center" gap={4} mt={4}>
                <Button
                  variant="primary"
                  onClick={() => handleSubmit(submit)()}
                  isLoading={loading}
                >
                  Create
                </Button>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
    </SettingsSection>
  );
};

const getDuplicateProjectWarning = (blueprintType?: BlueprintType): string => {
  if (blueprintType === BlueprintType.Terraform) {
    return "Duplicating this project will create a new project with no Terraform state. You will need to run plan / apply after duplication.";
  }

  return "";
};

const useMapToOptions = (
  available?: { id: string; name: string }[]
): Option<IdOrName>[] | undefined =>
  useMemo(
    () =>
      available?.map((x) => ({
        value: { id: x.id },
        label: x.name,
      })),
    [available]
  );
