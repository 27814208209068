import {
  Box,
  Flex,
  Heading,
  HStack,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Button, CopyCode, useCurrentTeamUser } from "@zeet/web-ui";
import React from "react";
import { GiCheckMark } from "react-icons/gi";
import { HashLink } from "react-router-hash-link";
import { MainContainer } from "~/components/Account/MainContainer";
import { SectionContainer } from "~/components/Account/SectionContainer";
import createTicketImage from "~/images/support/create-ticket.png";
import dashboardImage from "~/images/support/dashboard.png";
import slackImage from "~/images/support/slack.png";

const BasicSupport: React.FC = () => {
  const benefits = [
    "Slack Connect channel",
    "Create tickets in the Ticket Center",
    "Answers by next business day",
    "Escalate high priority issues (P0/P1)",
    "Contextual guidance for your account",
  ];
  const teamUser = useCurrentTeamUser();

  return (
    <MainContainer>
      <SectionContainer padding={8}>
        <Text
          fontWeight="bold"
          fontSize="24px"
          textAlign={["center", "center", "left"]}
          data-testid="support-basic-title"
        >
          Our Support team is ready to help
        </Text>
        <Flex
          mt={4}
          flexDirection={["column", "column", "row"]}
          alignItems="center"
        >
          <Text textAlign={["center", "center", "left"]}>
            Reach out for help using Zeet, to submit feedback, or to report any
            issues.
          </Text>
        </Flex>
        <Stack mt="4">
          <Heading size="sm">Email us</Heading>
          <HStack>
            <Box flex="1">
              <CopyCode value="help@zeet.co" />
            </Box>
            <Button
              variant="secondary"
              onClick={(e) => {
                window.location.href = `mailto:help@zeet.co?subject=Support Request: ${teamUser.login}`;
                e.preventDefault();
              }}
            >
              Open email client
            </Button>
          </HStack>
        </Stack>
      </SectionContainer>
      <SectionContainer mt="10px" p={8}>
        <Text fontSize="24px" fontWeight="bold" textAlign="center">
          Do more with Level 1 Support
        </Text>
        <Flex
          mt={[4, 4, 12]}
          flexDirection={["column", "column", "row"]}
          justifyContent={[null, null, "space-between"]}
        >
          <Box width={["100%", "100%", "48%"]}>
            <Flex justifyContent="center">
              <Box>
                <Image src={dashboardImage} />
                <Text
                  fontSize="12px"
                  fontStyle="italic"
                  textAlign="center"
                  mt={2}
                >
                  Get in dashboard ticket center
                </Text>
              </Box>
            </Flex>
            <Flex justifyContent="space-between" mt={6}>
              <Box width="48%">
                <Image src={createTicketImage} width="100%" />
                <Text
                  fontSize="12px"
                  fontStyle="italic"
                  textAlign="center"
                  mt={2}
                >
                  P1 Ticket creation
                </Text>
              </Box>
              <Box width="48%">
                <Image src={slackImage} width="100%" />
                <Text
                  fontSize="12px"
                  fontStyle="italic"
                  textAlign="center"
                  mt={2}
                >
                  Private Slack connect
                </Text>
              </Box>
            </Flex>
          </Box>
          <Box width={["100%", "100%", "48%"]} mt={[12, 12, 0]}>
            <Stack spacing={6}>
              {benefits.map((b, i) => (
                <Flex width="100%" key={i}>
                  <Flex alignItems="center">
                    <Flex
                      justifyContent="center"
                      alignItems="center"
                      width={6}
                      height={6}
                      backgroundColor="brand.500"
                      borderRadius="md"
                    >
                      <GiCheckMark color="black" />
                    </Flex>
                  </Flex>
                  <Text ml={4}>{b}</Text>
                </Flex>
              ))}
            </Stack>
            <Flex alignItems="center" mt="60px" justifyContent="center">
              <Button
                colorScheme="brand"
                mr={4}
                px={6}
                as={HashLink}
                to={`/${teamUser?.login}/account/billing/upgrade#SUPPORT_TIER-anchor`}
                _hover={{ textDecoration: "none" }}
              >
                Upgrade
              </Button>
              <Text>or</Text>
              <Button
                variant="secondary"
                ml={4}
                px={6}
                asLink
                to="https://zeet.co/pricing#support"
                _hover={{ textDecoration: "none" }}
              >
                View all features
              </Button>{" "}
            </Flex>
          </Box>
        </Flex>
      </SectionContainer>
    </MainContainer>
  );
};

export default BasicSupport;
