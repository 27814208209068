import { Tab } from "@chakra-ui/react";
import { Button, ButtonProps, useColors } from "@zeet/web-ui";
import { PropsWithChildren } from "react";

export const SourceTab = ({
  children,
  ...props
}: Partial<ButtonProps> & PropsWithChildren) => {
  const { brand } = useColors();
  const activeStyle = {
    outline: `2px solid ${brand} !important`,
  };

  return (
    <Tab as={Button} {...props} variant="secondary" _selected={activeStyle}>
      {children}
    </Tab>
  );
};
