import { ChevronDownIcon, ChevronRightIcon, CopyIcon } from "@chakra-ui/icons";
import {
  Box,
  Collapse,
  Flex,
  Stack,
  Text,
  useBoolean,
  useClipboard,
  useToast,
} from "@chakra-ui/react";
import {
  OutputEntryDetailFragment,
  useProjectOutputQuery,
  WorkflowRunDetailFragment,
} from "@zeet/web-api/dist/graphqlv1";
import {
  DetailItem,
  DetailLabel,
  DetailSidebar,
  Loading,
  SidebarLayout,
  useColors,
  useCurrentTeamUser,
  ZError,
} from "@zeet/web-ui";
import { BuildArtifactsDetailItem } from "../../components/BuildArtifactDetailItem";
import { DurationDetailItem } from "../../components/DurationDetailItem";
import { StatusDetailItem } from "../../components/StatusDetailItem";
import { workflowRunStatusMap } from "../../utils";
import { WorkflowActions } from "../WorkflowActions";
import { WorkflowCanvas } from "../WorkflowCanvas";
import { WorkflowProperties } from "../WorkflowProperties";

interface WorkflowRunOverviewProps {
  workflowRun: WorkflowRunDetailFragment;
  workflowId: string;
  projectId: string;
}

export const WorkflowRunOverview = ({
  workflowRun,
  projectId,
  workflowId,
}: WorkflowRunOverviewProps) => {
  const { id: teamId } = useCurrentTeamUser();

  const runCompleted = !!workflowRun.finishedAt;

  const statusInfo = workflowRunStatusMap[workflowRun.status];
  const artifacts =
    workflowRun.steps?.flatMap((s) => {
      if (s?.__typename === "BuildRunStep") {
        return s?.outputs || [];
      }
      return [];
    }) || [];

  return (
    <SidebarLayout pr="0">
      <Flex direction="column">
        <WorkflowProperties />
        <WorkflowCanvas projectId={projectId} workflowRun={workflowRun} />
      </Flex>
      <DetailSidebar>
        <StatusDetailItem statusInfo={statusInfo}>
          <WorkflowActions
            projectId={projectId}
            status={workflowRun.status}
            runId={workflowRun.id}
            workflowId={workflowId}
          />
        </StatusDetailItem>

        <DurationDetailItem
          startDate={workflowRun.createdAt}
          endDate={workflowRun.finishedAt}
          hasEnded={runCompleted}
        />

        <BuildArtifactsDetailItem
          artifacts={artifacts}
          runCompleted={runCompleted}
        />

        <WorkflowOutputsDetailItem teamId={teamId} projectId={projectId} />
      </DetailSidebar>
    </SidebarLayout>
  );
};

const WorkflowOutputsDetailItem: React.FC<{
  teamId: string;
  projectId: string;
}> = (props) => {
  const [show, { toggle }] = useBoolean(false);

  return (
    <DetailItem>
      <Flex onClick={toggle} alignItems="center" as="button">
        <DetailLabel alignSelf={"center"}>Outputs</DetailLabel>
        {show ? (
          <ChevronDownIcon fontSize="1em" p={0} mb={2} />
        ) : (
          <ChevronRightIcon fontSize="1em" p={0} mb={2} />
        )}
      </Flex>
      <Collapse in={show}>
        {/* {defer query until the user actually requests outputs by expanding the collapse} */}
        {show && <ProjectOutputEntries {...props} />}
      </Collapse>
    </DetailItem>
  );
};

const ProjectOutputEntries: React.FC<{
  teamId: string;
  projectId: string;
}> = ({ teamId, projectId }) => {
  const { data, loading, error } = useProjectOutputQuery({
    variables: {
      teamId: teamId,
      projectId: projectId,
    },
  });

  const outputEntries: Array<OutputEntryDetailFragment> | null | undefined =
    data?.team?.project?.output?.entries;

  return (
    <>
      {loading && <Loading />}
      {error && <ZError error={error} />}
      {(outputEntries?.length ?? 0) == 0
        ? "This project has no outputs"
        : outputEntries // there are outputs to show
            ?.sort((a, b) =>
              (a.displayName ?? "").localeCompare(b.displayName ?? "")
            )
            .map((output, i) => (
              <ProjectOutputDataBlock
                key={`${i}-${output.name}`}
                output={output}
              />
            ))}
    </>
  );
};

interface ProjectOutputDataBlockProps {
  output?: OutputEntryDetailFragment;
}

const ProjectOutputDataBlock: React.FC<ProjectOutputDataBlockProps> = ({
  output,
}: ProjectOutputDataBlockProps) => {
  const { bg3 } = useColors();
  const toast = useToast();

  const { onCopy } = useClipboard(output?.value ?? "");

  const copyToClipboard = () => {
    onCopy();
    toast({
      title: `Copied ${output?.displayName ?? "output value"}`,
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  return (
    <Stack>
      <Text
        fontSize="sm"
        textOverflow="ellipsis"
        overflow="hidden"
        whiteSpace="nowrap"
      >
        {output?.displayName}:
      </Text>
      <Flex
        _hover={{ textDecoration: "underline" }}
        onClick={copyToClipboard}
        alignItems="center"
        border="1px"
        borderColor="var(--chakra-colors-chakra-border-color)"
        borderRadius="md"
        bg={bg3}
        fontWeight={600}
        fontSize="1em"
        px={4}
        py={2}
        mb={2}
        cursor="pointer"
      >
        <Box
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
          flexGrow={1}
        >
          <Text maxWidth={"100%"} textOverflow="ellipsis" overflow={"hidden"}>
            {output?.value}
          </Text>
        </Box>
        {output?.value && <CopyIcon />}
      </Flex>
    </Stack>
  );
};
