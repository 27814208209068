import { BoxProps } from "@chakra-ui/react";
import { CopyVariant } from "./Copy";

export const useCopyDisplayItemStyles = (variant: CopyVariant) => {
  return {
    flex: "1",
    background: "transparent",
    padding: variant === "md" ? "0 14px" : "0 8px",
    borderRightRadius: "md",
    borderLeftRadius: "0",
    fontWeight: "normal",
    fontSize: "sm",
    whiteSpace: "nowrap" as BoxProps["whiteSpace"],
    overflow: "hidden",
    textOverflow: "ellipsis",
  };
};
