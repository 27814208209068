import { BridgeBlueprintInput, Input, Select, useColors } from "@zeet/web-ui";
import { CreatableSelect } from "chakra-react-select";
import { Controller, Path, useFormContext } from "react-hook-form";
import { generateRandomPassword } from "~/utils/generators";
import { NewResourceValues } from "../../context";
import { makeRegisterOptions } from "./utils";

interface StringInputProps<T> {
  input: BridgeBlueprintInput;
  pathMap: (value: any) => Path<T>;
}

const StringInput: React.FC<StringInputProps<NewResourceValues>> = ({
  input,
  pathMap,
}) => {
  const { bg } = useColors();
  const { register, control } = useFormContext<NewResourceValues>();

  if (input.options) {
    const options = input.options.map((option) => ({
      label: option,
      value: option,
    }));

    const getValue = (value) => {
      if (value) {
        return {
          label: value,
          value: input.options?.find((o) => o === value) ?? value,
        };
      }
    };

    const chakraStyles = {
      container: (provided) => ({ ...provided, backgroundColor: bg }),
      menuList: (provided) => ({ ...provided, boxShadow: "2xl" }),
    };

    return (
      <Controller
        name={pathMap(input.id)}
        control={control}
        rules={makeRegisterOptions(input)}
        defaultValue={input.default as string}
        render={({ field: { onChange, value } }) =>
          input.allowCustomOption ? (
            <CreatableSelect
              isReadOnly={input.readOnly}
              captureMenuScroll={false}
              chakraStyles={chakraStyles}
              useBasicStyles
              openMenuOnFocus
              placeholder="Select option"
              options={options}
              onChange={(option) => onChange(option?.value)}
              value={getValue(value)}
              styles={{
                menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
              }}
              noOptionsMessage={() => "Type to create a new option"}
              menuPosition="fixed"
              menuPlacement="auto"
              menuPortalTarget={document.body}
            />
          ) : (
            <Select
              isDisabled={input.readOnly}
              captureMenuScroll={false}
              chakraStyles={chakraStyles}
              useBasicStyles
              openMenuOnFocus
              placeholder="Select option"
              options={options}
              onChange={(option) => onChange(option?.value)}
              value={value}
            />
          )
        }
      />
    );
  }

  const getDefaultValue = () => {
    if (input.default) {
      return input.default as string;
    }
    if (input.sensitive) {
      return generateRandomPassword();
    }
  };

  return (
    <Input
      backgroundColor={bg}
      defaultValue={getDefaultValue()}
      type={input.sensitive ? "password" : "text"}
      isReadOnly={input.readOnly ?? false}
      {...register(pathMap(input.id), makeRegisterOptions(input))}
    />
  );
};

export default StringInput;
