import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputRightAddon,
  Stack,
  Switch,
  Text,
  Tooltip as ToolTipChakra,
  useToast,
} from "@chakra-ui/react";
import {
  RepoDetailFragment,
  TeamMemberRole,
  UpdateProjectDangerInput,
  useMoveRepoMutation,
  useMoveRepoTargetsQuery,
  useUpdateProjectDangerSettingsMutation,
  useUserTeamsQuery,
} from "@zeet/web-api/dist/graphql";
import {
  FormSelect,
  Loading,
  Tooltip,
  useColors,
  useCurrentTeam,
  ZError,
} from "@zeet/web-ui";
import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { isApp, isKube } from "../../utils/project";
import { repoPath } from "../util";
import { ZFormLabel } from "./Build";
import { DeleteProject } from "./Disable";

const CautionSettings: React.FC<{ repo: RepoDetailFragment }> = ({ repo }) => {
  const toast = useToast();
  const history = useHistory();

  const { handleSubmit, register } = useForm({
    defaultValues: {
      name: repo?.name,
      projectID: repo.project?.id,
      environmentName: repo.projectEnvironment?.name || "development",
    },
  });

  const { data, loading: targetsLoading } = useMoveRepoTargetsQuery({
    variables: {
      id: repo.owner.id,
    },
  });

  const [moveRepo, { error, loading }] = useMoveRepoMutation({
    onCompleted: (data) => {
      if (data) {
        toast({
          title: "Project Settings Saved",
          status: "success",
          duration: 5000,
          isClosable: true,
        });

        history.replace(
          `/${repoPath(data.moveRepoToProjectEnvironment)}/settings/danger`
        );
      }
    },
    update(cache) {
      cache.modify({
        id: cache.identify(repo.owner),
        fields: {
          repos(_, { DELETE }) {
            return DELETE;
          },
        },
      });
    },
  });

  const onSubmit = (values) => {
    moveRepo({
      variables: {
        input: {
          id: repo.id,
          projectID: values.projectID,
          environmentName: values.environmentName,
          name: values.name,
        },
      },
    });
  };

  if (targetsLoading) {
    return <Loading />;
  }

  return (
    <Flex flexDirection="column">
      <Text fontWeight="bold" fontSize="1.2rem" mb={2}>
        Project Organization
      </Text>

      <Stack>
        <Text>
          Changing the project Group, Sub-Group or name of the Project will
          change its URL on Zeet dashboard, existing links referencing the old
          dashboard URL will stop working
        </Text>

        <form
          onSubmit={(e) => {
            e.stopPropagation();
            e.preventDefault();
            handleSubmit(onSubmit)(e);
          }}
        >
          <Stack>
            <Flex alignItems="center">
              <ZFormLabel>Group</ZFormLabel>
              <FormSelect
                flex={5.25}
                {...register("projectID")}
                defaultValue={repo.project?.id}
                options={data?.user.projects?.nodes?.map((p) => {
                  return { value: p.id, label: `${p.name}` };
                })}
              />
            </Flex>

            <Flex alignItems="center">
              <ZFormLabel>Sub-Group</ZFormLabel>
              <FormSelect
                flex={5.25}
                {...register("environmentName")}
                defaultValue={repo.projectEnvironment?.name}
                options={[
                  ...new Set(
                    data?.user.projects?.nodes?.flatMap((p) => {
                      return p.environments?.map((e) => {
                        return e.name || "development";
                      });
                    })
                  ),
                ].map((e) => {
                  return { value: e, label: e };
                })}
              />
            </Flex>

            <Flex alignItems="center">
              <ZFormLabel>Project Name </ZFormLabel>
              <Input flex={5} fontFamily={"mono"} {...register("name")} />
            </Flex>

            <ZError error={error} />

            <Flex>
              <Button
                ml={"auto"}
                colorScheme="brand"
                isLoading={loading}
                type="submit"
              >
                Move Project
              </Button>
            </Flex>
          </Stack>
        </form>
      </Stack>
    </Flex>
  );
};

const DangerSettings: React.FC<{ repo: RepoDetailFragment }> = ({ repo }) => {
  const toast = useToast();

  const { handleSubmit, register, watch } = useForm({
    defaultValues: {
      namespace: repo?.namespace,
      appID: repo?.appID,
      defaultIngressDomain: repo?.defaultIngressDomain,
      kappID: repo?.kappID,
      useHumanReadableKubernetesName: repo?.useHumanReadableKubernetesName,
    },
  });

  const watchForm = watch();

  const [updateSettings, { error, loading }] =
    useUpdateProjectDangerSettingsMutation({
      onCompleted: (data) => {
        if (data) {
          toast({
            title: "Project Settings Saved",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      },
    });

  const onSubmit = (values) => {
    const input: UpdateProjectDangerInput = {
      id: repo.id,
      appID: values?.appID,
    };

    if (isApp(repo) && isKube(repo)) {
      input.namespace = values?.namespace;
      input.defaultIngressDomain = values?.defaultIngressDomain;
      input.useHumanReadableKubernetesName =
        values?.useHumanReadableKubernetesName;
    }

    updateSettings({
      variables: {
        input,
      },
    });
  };

  return (
    <form
      onSubmit={(e) => {
        e.stopPropagation();
        e.preventDefault();
        handleSubmit(onSubmit)(e);
      }}
    >
      <Flex flexDirection="column">
        <Text fontWeight="bold" fontSize="1.2rem" mb={2}>
          Danger Settings
        </Text>

        <Stack>
          <Text>
            These settings will delete and recreate projects, they can only be
            change when the project is paused.
          </Text>

          {isKube(repo) && (
            <Flex alignItems="center">
              <ZFormLabel>Namespace</ZFormLabel>
              {repo.enabled ? (
                <ToolTipChakra label={"pause project to edit settings"}>
                  <Input
                    flex={5}
                    fontFamily={"mono"}
                    {...register("namespace")}
                    placeholder={"(e.g.) staging production"}
                    defaultValue={watchForm.namespace || ""}
                    isDisabled={true}
                  />
                </ToolTipChakra>
              ) : (
                <Input
                  flex={5}
                  fontFamily={"mono"}
                  {...register("namespace")}
                  placeholder={"(e.g.) staging production"}
                  defaultValue={watchForm.namespace || ""}
                />
              )}
            </Flex>
          )}

          <Flex alignItems="center">
            <ZFormLabel>
              Resource Identifier
              <Tooltip text="Zeet uses resource identifier to find and manage Cloud Resources, you can modify this value when the resource is paused" />
            </ZFormLabel>
            {repo.enabled ? (
              <ToolTipChakra label={"pause project to edit settings"}>
                <Input
                  flex={5}
                  fontFamily={"mono"}
                  {...register("appID")}
                  isDisabled={true}
                />
              </ToolTipChakra>
            ) : (
              <Input flex={5} fontFamily={"mono"} {...register("appID")} />
            )}
          </Flex>
          {isKube(repo) && (
            <Flex alignItems="center">
              <ZFormLabel>
                Default Ingress Domain
                <Tooltip text="default domain name of the https endpoint for the production branch of this Project" />
              </ZFormLabel>
              <InputGroup>
                {repo.enabled ? (
                  <ToolTipChakra label={"pause project to edit settings"}>
                    <Input
                      flex={5}
                      fontFamily={"mono"}
                      {...register("defaultIngressDomain")}
                      isDisabled={true}
                    />
                  </ToolTipChakra>
                ) : (
                  <Input
                    flex={5}
                    fontFamily={"mono"}
                    {...register("defaultIngressDomain")}
                    borderRightRadius="0"
                    width="50%"
                  />
                )}
                <InputRightAddon width="50%">
                  <Text
                    textOverflow="ellipsis"
                    overflow="hidden"
                    whiteSpace="nowrap"
                  >
                    .{repo.cluster?.domain}
                  </Text>
                </InputRightAddon>
              </InputGroup>
            </Flex>
          )}
          {isKube(repo) && (
            <Flex alignItems="center">
              <ZFormLabel>Kubernetes App ID</ZFormLabel>
              <InputGroup>
                <ToolTipChakra label={"Read only"}>
                  <Input
                    flex={5}
                    fontFamily={"mono"}
                    {...register("kappID")}
                    isReadOnly={true}
                  />
                </ToolTipChakra>
              </InputGroup>
            </Flex>
          )}
          {isKube(repo) && (
            <Flex alignItems="center">
              <ZFormLabel>Use human readable Kubernetes name</ZFormLabel>
              <InputGroup>
                {repo.enabled ? (
                  <ToolTipChakra label={"pause project to edit settings"}>
                    <Box>
                      <Switch
                        isDisabled={true}
                        size="lg"
                        {...register("useHumanReadableKubernetesName")}
                      />
                    </Box>
                  </ToolTipChakra>
                ) : (
                  <Box>
                    <Switch
                      ml={2}
                      size="lg"
                      {...register("useHumanReadableKubernetesName")}
                    />
                  </Box>
                )}
              </InputGroup>
            </Flex>
          )}
          <ZError error={error} />
          <Flex>
            <Button
              ml={"auto"}
              colorScheme="brand"
              isLoading={loading}
              isDisabled={repo.enabled}
              type="submit"
            >
              Save
            </Button>
          </Flex>
        </Stack>
      </Flex>
    </form>
  );
};

export const Danger: React.FC<{ repo: RepoDetailFragment }> = ({ repo }) => {
  const currentTeam = useCurrentTeam();
  const { data: currentUserTeams, loading } = useUserTeamsQuery();

  const isCurrentUserOwnerOrAdmin = useMemo(() => {
    if (!repo.owner.isTeam) {
      return true;
    }

    const teamEdge = currentUserTeams?.currentUser?.teams?.find((t) => {
      return t.team.id === currentTeam?.id;
    });

    return (
      teamEdge?.role === TeamMemberRole.Owner ||
      teamEdge?.role === TeamMemberRole.Admin
    );
  }, [repo, currentTeam, currentUserTeams]);

  const { bg2 } = useColors();

  if (loading) {
    return <Loading />;
  }

  return (
    <Stack spacing={8}>
      <Flex>
        <Stack spacing={4} flex={1}>
          <Box p={4} shadow="md" borderWidth="1px" bg={bg2} borderRadius="md">
            <CautionSettings repo={repo} />
          </Box>
        </Stack>
      </Flex>

      <Flex>
        <Stack spacing={4} flex={1}>
          <Box p={4} shadow="md" borderWidth="1px" bg={bg2} borderRadius="md">
            <DangerSettings repo={repo} />
          </Box>
        </Stack>
      </Flex>

      <Flex>
        <Stack spacing={4} flex={1}>
          <Box p={4} shadow="md" borderWidth="1px" bg={bg2} borderRadius="md">
            <Stack spacing={4}>
              {!isCurrentUserOwnerOrAdmin && (
                <ZError
                  error={
                    "You do not have permission to delete this project. Please contact an Admin on your team to delete this project"
                  }
                />
              )}

              <DeleteProject
                repo={repo}
                isDisabled={!isCurrentUserOwnerOrAdmin}
              />
            </Stack>
          </Box>
        </Stack>
      </Flex>
    </Stack>
  );
};
