import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from "@chakra-ui/react";
import { BridgeBlueprintInput, useColors } from "@zeet/web-ui";
import { Controller, Path, useFormContext } from "react-hook-form";
import { NewResourceValues } from "../../context";
import { makeFieldOptions, makeRegisterOptions } from "./utils";

interface IntegerInputProps<T> {
  input: BridgeBlueprintInput;
  pathMap: (value: any) => Path<T>;
}

const IntegerInput: React.FC<IntegerInputProps<NewResourceValues>> = ({
  input,
  pathMap,
}) => {
  const { bg } = useColors();
  const { control, trigger } = useFormContext<NewResourceValues>();

  return (
    <Controller
      name={pathMap(input.id)}
      control={control}
      defaultValue={input.default as string}
      rules={makeRegisterOptions(input)}
      render={(props) => (
        <NumberInput {...makeFieldOptions({ ...props, trigger })} min={0}>
          <NumberInputField bg={bg} />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      )}
    />
  );
};

export default IntegerInput;
