import { Flex, FormControl, Icon } from "@chakra-ui/react";
import {
  AwsAccount,
  useRemoveAwsAccountMutation,
} from "@zeet/web-api/dist/graphql";
import { Button, CopyCode, FormLabel } from "@zeet/web-ui";
import { MdLaunch } from "react-icons/md";
import { SettingsDisplayName } from "~/components/Layouts/Settings/SettingsDisplayName";
import {
  SettingsSection,
  SettingsSectionDetails,
  SettingsSections,
} from "../../../../Layouts/Settings";
import { YesNoCell } from "../../../../ListViewV2";
import { CloudDangerZone } from "./CloudDangerZone";

interface AwsDetailsProps {
  awsAccount: AwsAccount;
  refetch: () => void;
}

export const AwsDetails: React.FC<AwsDetailsProps> = ({
  awsAccount,
  refetch,
}) => {
  return (
    <SettingsSections>
      <SettingsSection borderTop="none">
        <SettingsSectionDetails title="General" />
        <Flex gap="4" flexDirection="column" overflow="hidden">
          <SettingsDisplayName />
          <FormControl>
            <FormLabel>Connected</FormLabel>
            <YesNoCell isYes={awsAccount.connected ?? false} />
            <Button
              asLink
              variant="secondary"
              size="sm"
              my="2"
              to={awsAccount.cloudFormationURL}
              rightIcon={<Icon as={MdLaunch} />}
            >
              {awsAccount.connected ? "Reconnect" : "Fix connection"}
            </Button>
          </FormControl>
          <FormControl>
            <FormLabel>AWS Account ID</FormLabel>
            <CopyCode value={awsAccount?.accountID ?? ""} width="100%" />
          </FormControl>
          <FormControl>
            <FormLabel>AWS Connection Role ARN</FormLabel>
            <CopyCode value={awsAccount?.roleARN ?? ""} width="100%" />
          </FormControl>
          <FormControl>
            <FormLabel>Zeet Cloud ID</FormLabel>
            <CopyCode value={awsAccount?.id ?? ""} width="100%" />
          </FormControl>
        </Flex>
      </SettingsSection>

      <SettingsSection mt={12}>
        <SettingsSectionDetails title="Danger zone" />

        <CloudDangerZone
          id={awsAccount.id}
          confirmationString={awsAccount.accountID ?? awsAccount.id}
          useRemoveCloudAccountMutation={useRemoveAwsAccountMutation}
          refetch={refetch}
        />
      </SettingsSection>
    </SettingsSections>
  );
};
