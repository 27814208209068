import {
  Flex,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link, useCurrentTeamUser } from "@zeet/web-ui";
import React from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { useMenuItems } from "../utils/sidebar";

export const MobileMenu: React.FC = () => {
  const currentTeamUser = useCurrentTeamUser();
  const color = useColorModeValue("blackAlpha", "whiteAlpha");
  const { topMenuItems, bottomMenuItems } = useMenuItems(
    currentTeamUser?.login
  );

  return (
    <Menu>
      <MenuButton w="100%" height="24px">
        <GiHamburgerMenu />
      </MenuButton>
      <MenuList>
        {[...topMenuItems, ...bottomMenuItems].map((mi, i) => (
          <MenuItem key={i}>
            <Link to={mi.link} w="100%">
              <Flex alignItems="center">
                {mi.icon ? (
                  <Icon as={mi.icon} h="24px" w="24px" />
                ) : color === "whiteAlpha" ? (
                  <Image
                    height="24px"
                    width="24px"
                    src={mi.iconWhite as string}
                  />
                ) : (
                  <Image
                    height="24px"
                    width="24px"
                    src={mi.iconBlack as string}
                  />
                )}

                <Text ml="6px">{mi.name}</Text>
              </Flex>
            </Link>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
