import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Input,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  BranchCommonFragment,
  RepoDetailFragment,
  useCreateBranchMutation,
  useDeleteProjectBranchMutation,
  useProjectBranchesQuery,
} from "@zeet/web-api/dist/graphql";
import {
  deploymentStatusOverview,
  Loading,
  StatusBadge,
  useColors,
  ZError,
  Link as ZLink,
  ZModal,
  Tooltip as ZTooltip,
} from "@zeet/web-ui";
import React, { useEffect, useRef, useState } from "react";
import { FiPower } from "react-icons/fi";
import { GoGitBranch, GoGitCommit } from "react-icons/go";
import { Link } from "react-router-dom";
import TimeAgo from "react-timeago";
import { branchUpdatedAt, sortByUpdatedAt } from "../utils/sort";
import { repoPath } from "./util";

export const BranchButton: React.FC<{
  repo: RepoDetailFragment;
}> = ({ repo }) => {
  return (
    <Button as={Link} to={`/${repoPath(repo)}/deployments/branches`}>
      <Box mr={1}>
        <GoGitBranch />
      </Box>
      View All Branches
    </Button>
  );
};

const BranchHeader: React.FC<{ text: string; tooltip?: string }> = ({
  text,
  tooltip,
}) => {
  return (
    <Text fontWeight="bold" mb={3}>
      {text}
      {tooltip && <ZTooltip text={tooltip} />}
    </Text>
  );
};

const BranchItem: React.FC<{
  repo: RepoDetailFragment;
  branch: BranchCommonFragment;
}> = ({ repo, branch }) => {
  const colors = useColors();
  const toast = useToast();
  const deployment = branch.latestDeployment;
  const canKill =
    branch.name !== repo.productionBranch &&
    (branch.state !== "STOPPED" ||
      branch.latestDeployment?.deployStatus?.active);

  const [kill, { loading, error }] = useDeleteProjectBranchMutation({
    errorPolicy: "all",
    onCompleted: (data) => {
      if (data) {
        toast({
          title: "Branch Stopped",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    },
  });

  useEffect(() => {
    if (error) {
      toast({
        title: "Failed to stop branch",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [error, toast]);

  return (
    <Flex
      my={3}
      padding={3}
      borderRadius={4}
      backgroundColor={colors.bg2}
      shadow="md"
      alignItems="center"
    >
      <Flex width="100%">
        <Flex flex={2}>
          <ZLink to={`/${repoPath(repo)}/deployments?branch=${branch.name}`}>
            <HStack>
              <Text fontWeight="600" whiteSpace="nowrap" noOfLines={1}>
                {branch.name}
              </Text>
              <GoGitBranch />
              <Text noOfLines={1}>{branch.gitRef}</Text>
              <GoGitCommit />
              <Text>{"#" + deployment?.version?.slice(0, 7)}</Text>
            </HStack>
          </ZLink>
        </Flex>
        <Flex flex={1}>
          <StatusBadge
            fontSize="sm"
            status={deploymentStatusOverview(repo, deployment)}
          />
          {canKill && (
            <Tooltip aria-label="stop" label="Shut down this branch">
              <Button
                variant="outline"
                mx={4}
                maxHeight="1.7rem"
                maxWidth="1.2rem"
                p={0}
                isLoading={loading}
                onClick={() => {
                  kill({
                    variables: {
                      id: repo.id,
                      branch: branch.name,
                    },
                  });
                }}
              >
                <FiPower color="red" />
              </Button>
            </Tooltip>
          )}
        </Flex>
        <Flex flex={1} justifyContent="flex-end">
          <Popover trigger="hover" placement="top-start" isLazy>
            <PopoverTrigger>
              <Box>
                <TimeAgo date={branchUpdatedAt(branch)} />
              </Box>
            </PopoverTrigger>
            <PopoverContent width="auto">
              <PopoverBody>
                <Stack>
                  <Flex>
                    <Text mr={1}>Created:</Text>
                    <TimeAgo date={branch.createdAt || 0} />
                  </Flex>
                  <Flex>
                    <Text mr={1}>Updated: </Text>
                    <TimeAgo date={branchUpdatedAt(branch)} />
                  </Flex>
                </Stack>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Flex>
      </Flex>
    </Flex>
  );
};

const BranchModal: React.FC<{
  refetch: () => void;
  onClose: () => void;
  isOpen: boolean;
  repo: RepoDetailFragment;
}> = ({ refetch, onClose, isOpen, repo }) => {
  const toast = useToast();

  const [createBranch, { loading, error }] = useCreateBranchMutation({
    onCompleted: (data) => {
      if (data && data.updateBranch) {
        refetch();
        onClose();
        toast({
          title: "Branch Created",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    },
  });

  const [name, setName] = useState("");
  const [gitRef, setGitRef] = useState("");

  return (
    <ZModal isOpen={isOpen} onClose={onClose} size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Create a New Branch</ModalHeader>

        <ModalBody>
          <Stack>
            <Text>This will deploy a new branch on Zeet</Text>
            <Input
              placeholder="branch-name"
              onChange={(e) => {
                setName(e.target.value);
              }}
            ></Input>
            <Text>
              Override Git Branch
              <ZTooltip text="You can specify a different branch or git ref. for example you can deploy the `feature-3` git branch to Zeet `staging` branch" />
            </Text>
            <Input
              placeholder="optional e.g. staging"
              onChange={(e) => {
                setGitRef(e.target.value);
              }}
            ></Input>
            <ZError error={error} />
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button
            alignSelf="center"
            shadow="md"
            borderRadius={4}
            display="flex"
            isLoading={loading}
            colorScheme="brand"
            onClick={() => {
              createBranch({
                variables: {
                  input: {
                    repoID: repo.id,
                    name,
                    deploy: true,
                    gitRef: gitRef || undefined,
                  },
                },
              });
            }}
          >
            Deploy Branch
          </Button>
        </ModalFooter>
      </ModalContent>
    </ZModal>
  );
};

function usePreviousNonNullish<T>(value: T): T {
  const ref = useRef<T>(value);
  useEffect(() => {
    if (value !== null && value !== undefined) {
      ref.current = value;
    }
  });
  return ref.current;
}

export const BranchView: React.FC<{
  repo: RepoDetailFragment;
}> = ({ repo }) => {
  const [showAllBranches, setShowAllBranches] = useState(false);
  const {
    data: newData,
    loading,
    refetch,
  } = useProjectBranchesQuery({
    pollInterval: 3000,
    variables: {
      id: repo.id,
      active: showAllBranches ? null : true,
    },
  });

  const prevData = usePreviousNonNullish(newData);
  const data = newData ?? prevData;

  const branches = data?.currentUser.repo?.branchesV2?.nodes;

  const prodBranch = data?.currentUser?.repo?.productionBranchV2;

  const runningBranches = branches?.filter(
    (b) => b.state !== "STOPPED" && b.id !== prodBranch?.id
  );
  const stoppedBranch = branches?.filter(
    (b) =>
      b.state === "STOPPED" &&
      b.id !== prodBranch?.id &&
      !b.latestDeployment?.deployStatus?.active
  );

  const ghostBranch = branches?.filter(
    (b) =>
      b.state === "STOPPED" &&
      b.id !== prodBranch?.id &&
      b.latestDeployment?.deployStatus?.active
  );

  const { onOpen, onClose, isOpen } = useDisclosure();

  if (loading && !data) {
    return <Loading />;
  }

  return (
    <Stack spacing={4}>
      <Flex alignItems={"center"}>
        <Heading size="md">Branches</Heading>

        <Button ml="auto" size="sm" onClick={onOpen}>
          New Branch
        </Button>
      </Flex>
      <BranchModal
        isOpen={isOpen}
        onClose={onClose}
        refetch={refetch}
        repo={repo}
      />
      <Box p={4} shadow="sm" borderWidth="1px" borderRadius="md">
        <BranchHeader text="Production Branch" />
        {prodBranch ? (
          <BranchItem repo={repo} branch={prodBranch} />
        ) : (
          <Text> No Production Branch Deployed</Text>
        )}
      </Box>
      {!!runningBranches?.length && (
        <Box p={4} shadow="sm" borderWidth="1px" borderRadius="md">
          <BranchHeader text="Active Branches" />
          {runningBranches
            ?.slice()
            .sort(sortByUpdatedAt)
            .map((branch) => {
              return <BranchItem key={branch.id} repo={repo} branch={branch} />;
            })}
        </Box>
      )}
      <Flex width={"100%"} justifyContent={"center"}>
        <Button
          variant="outline"
          onClick={() => setShowAllBranches(!showAllBranches)}
          rightIcon={showAllBranches ? <ChevronUpIcon /> : <ChevronDownIcon />}
        >
          {showAllBranches ? "Hide" : "Show"} Stopped Branches
        </Button>
      </Flex>

      {loading && <Loading />}

      {!!ghostBranch?.length && (
        <Box p={4} shadow="sm" borderWidth="1px" borderRadius="md">
          <BranchHeader
            text="Ghost Branches"
            tooltip={
              "Ghost branches are branches that were failed to clean up automatically. You can manually stop them or keep them running"
            }
          />
          {ghostBranch
            ?.slice()
            .sort(sortByUpdatedAt)
            .map((branch) => {
              return <BranchItem key={branch.id} repo={repo} branch={branch} />;
            })}
        </Box>
      )}

      {showAllBranches && (
        <Box p={4} shadow="sm" borderWidth="1px" borderRadius="md">
          <BranchHeader text="Stopped Branches" />
          {stoppedBranch?.length
            ? stoppedBranch
                ?.slice()
                .sort(sortByUpdatedAt)
                .map((branch) => {
                  return (
                    <BranchItem key={branch.id} repo={repo} branch={branch} />
                  );
                })
            : !loading && <Text> No Stopped Branches</Text>}
        </Box>
      )}
    </Stack>
  );
};
