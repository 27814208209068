import { Box } from "@chakra-ui/react";
import React from "react";

export const PageContainer: React.FC<React.PropsWithChildren> = ({
  children,
  ...restProps
}) => {
  return (
    <Box {...restProps} w="100%">
      {children}
    </Box>
  );
};
