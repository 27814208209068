import {
  ProjectBasicFragment,
  useProjectEnvironmentsBasicQuery,
  useUserBasicProjectsQuery,
} from "@zeet/web-api/dist/graphql";
import { useCurrentTeamUser } from "@zeet/web-ui";
import { useCallback, useEffect, useState } from "react";
import { useProject } from "../Project/Provider";

export const useProjectEnvironmentList = () => {
  const currentProject = useProject();
  const [selectedProject, setSelectedProject] = useState<
    ProjectBasicFragment | null | undefined
  >(undefined);
  const currentTeamUser = useCurrentTeamUser();

  // get all projects
  const projectsQuery = useUserBasicProjectsQuery({
    variables: { id: currentTeamUser.id },
  });
  const availableProjects = projectsQuery?.data?.user.projects.nodes;

  // and environments for that project
  const environmentsQuery = useProjectEnvironmentsBasicQuery({
    skip: !selectedProject?.name,
    variables: { path: `${currentTeamUser.login}/${selectedProject?.name}` },
  });

  const onProjectChange = useCallback(
    (projectId) => {
      if (projectId === null) setSelectedProject(null);
      else {
        const newProject =
          availableProjects?.find((project) => project.id === projectId) ||
          currentProject;
        setSelectedProject(newProject);
        environmentsQuery.refetch({
          path: `${currentTeamUser.login}/${newProject?.name}`,
        });
      }
    },
    [
      setSelectedProject,
      availableProjects,
      currentProject,
      currentTeamUser.login,
      environmentsQuery,
    ]
  );

  useEffect(() => {
    if (typeof selectedProject !== "undefined") return;
    onProjectChange(currentProject);
  }, [currentProject, onProjectChange, selectedProject]);

  const availableEnvironments =
    selectedProject === null
      ? []
      : environmentsQuery.data?.project?.environments;

  return {
    availableProjects,
    availableEnvironments,
    selectProject: onProjectChange,
    selectedProject,
  };
};
