import { useQueryParams } from "@zeet/web-ui";
import { useLocation } from "react-router-dom";
import { ProjectDetailTabProps } from "..";
import { BackNavLayout } from "../components/BackNavLayout";
import DeployList from "./DeployList";
import DeploymentDetail from "./DeploymentDetail";
import DeploymentList from "./DeploymentList";

const DeploymentsTab = ({ project }: ProjectDetailTabProps) => {
  const { pathname } = useLocation();
  const query = useQueryParams();
  const deployId = query.get("deployId");
  const deployRunId = query.get("deployRunId");

  if (deployId) {
    return <DeploymentList project={project} deployId={deployId} />;
  }
  if (deployRunId) {
    return (
      <BackNavLayout linkTo={pathname} linkText="Back to deploys">
        <DeploymentDetail deployRunId={deployRunId} />
      </BackNavLayout>
    );
  }
  return <DeployList project={project} />;
};

export default DeploymentsTab;
