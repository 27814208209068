import { CopyIcon, ExternalLinkIcon, QuestionIcon } from "@chakra-ui/icons";
import {
  Box,
  BoxProps,
  Button,
  Card,
  Flex,
  Grid,
  Stack,
  Tooltip,
  useClipboard,
  useToast,
} from "@chakra-ui/react";
import {
  MetricLabelType,
  MetricStream,
  MetricType,
} from "@zeet/web-api/dist/graphql";
import { ECOption, LabelBadge } from "@zeet/web-ui";
import { ResourceDetailMetricChart } from "~/features/Project/Detail/ResourcesTab/ResourceDetailMetricChart";
import { MetricAggregationOption } from "./index";

export const MetricsChart: React.FC<
  {
    metricType: MetricType;
    metrics: MetricStream[];
    metricDescription?: string;
    showViewMetricExternalButton: boolean;
    aggregationType: MetricAggregationOption;
    metricQuery?: string;
    onViewMetricExternal?: (query: string) => void;
  } & BoxProps
> = ({
  metricType,
  metrics,
  metricDescription,
  metricQuery,
  showViewMetricExternalButton,
  aggregationType,
  onViewMetricExternal,
  ...props
}) => {
  const { onCopy } = useClipboard(metricQuery || "");
  const toast = useToast();
  const getMetricLabels = (
    streams?: MetricStream[] | null
  ): MetricLabelType[] => {
    if (!streams || streams.length == 1) {
      return [];
    }

    return streams[0]?.labels?.map((l) => l.label) ?? [];
  };

  const handleCopyQueryClick = () => {
    onCopy();
    toast({
      title: "Query Copied",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  const chartOverride: ECOption = {
    grid: {
      left: 60,
      bottom: 20,
      right: 55,
    },
    xAxis: {
      axisLabel: {
        formatter: {
          day: "{monthStyle|{MMM}} {daystyle|{dd}}",
          hour: "{hourStyle|{HH}}:{minuteStyle|{mm}}",
        },
        rich: {
          monthStyle: {
            fontWeight: "bold",
          },
          daystyle: {
            fontWeight: "bold",
          },
          hourStyle: {},
          minuteStyle: {},
        },
      },
    },
  };

  return (
    <Box {...props}>
      <Flex alignItems="baseline" justifyContent="space-between">
        <Flex alignItems="center">
          <LabelBadge labelKey="metric" labelValue={metricType} mr="3" />
          {!!getMetricLabels(metrics).length && (
            <LabelBadge
              labelKey="group-by"
              labelValue={getMetricLabels(metrics).join(",")}
            />
          )}
        </Flex>
        <Flex my={2} alignItems="center">
          {metricDescription && (
            <Tooltip label={metricDescription} placement="left">
              <QuestionIcon mr={3} color="gray" />
            </Tooltip>
          )}
          {metricQuery && (
            <Button
              size="sm"
              _hover={{ textDecoration: "none" }}
              onClick={handleCopyQueryClick}
              rightIcon={<CopyIcon />}
            >
              Copy Query
            </Button>
          )}
          {showViewMetricExternalButton &&
            onViewMetricExternal &&
            metricQuery && (
              <Button
                size="sm"
                ml="2"
                _hover={{ textDecoration: "none" }}
                onClick={() => onViewMetricExternal(metricQuery)}
                rightIcon={<ExternalLinkIcon />}
              >
                View in Prometheus
              </Button>
            )}
        </Flex>
      </Flex>
      <Card mb={4} mt={4} bg="inherit">
        <Stack gap="4">
          <Grid
            gap={4}
            alignItems="center"
            templateColumns={`repeat(${
              (metrics?.length ?? 0) === 1 ||
              aggregationType === MetricAggregationOption.SPLIT_SERIES
                ? "1"
                : "2"
            }, 1fr)`}
          >
            {aggregationType === MetricAggregationOption.SPLIT_SERIES ? (
              <ResourceDetailMetricChart
                chartOverride={chartOverride}
                metricType={metricType}
                metrics={metrics.map((metric) => ({
                  name: metric.labels?.map((l) => l.value).join(","),
                  entries: metric.entries,
                  annotations: metric.annotations,
                }))}
              />
            ) : (
              metrics.map((metric, i) => (
                <ResourceDetailMetricChart
                  key={i}
                  chartOverride={chartOverride}
                  name={metric.labels?.map((l) => l.value).join(",") || "all"}
                  metricType={metric.type}
                  metrics={[metric]}
                />
              ))
            )}
          </Grid>
        </Stack>
      </Card>
    </Box>
  );
};
