import { UserBillingOverviewQuery } from "@zeet/web-api/dist/graphql";

export const determineSupportLevel = (
  overviewQuery: UserBillingOverviewQuery | undefined
): string => {
  return (
    overviewQuery?.user.billingOverview?.currentProducts?.find(
      (cp) => cp.__typename === "SupportTier"
    )?.name || ""
  );
};

enum SupportTierEnum {
  BASIC = "Basic",
  LEVEL1 = "Level 1",
  LEVEL1_30 = "Level 1 with 30 Seats",
  LEVEL2 = "Level 2",
}

export const getIsAdvanced = (supportTier: string): boolean => {
  if (supportTier === SupportTierEnum.BASIC) {
    return false;
  }
  if (
    supportTier === SupportTierEnum.LEVEL1 ||
    supportTier === SupportTierEnum.LEVEL1_30 ||
    supportTier === SupportTierEnum.LEVEL2
  ) {
    return true;
  }
  return false;
};
