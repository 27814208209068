import { Maybe } from "@zeet/web-api/dist/graphql";
import { useDebounce } from "@zeet/web-ui";
import { useEffect, useMemo, useRef, useState } from "react";
import useSwr from "swr";
import config from "~/utils/config";

interface DockerSearchImageArchitecture {
  name: string;
  label: string;
}

interface DockerSearchImageCategory {
  name: string;
  label: string;
}

interface DockerSearchImageLogo {
  small?: string;
  "small@2x"?: string;
  large?: string;
}

interface DockerSearchImageOperatingSystem {
  name: string;
  label: string;
}

interface DockerSearchImagePublisher {
  id: string;
  name: string;
}

interface DockerSearchImage {
  architectures: DockerSearchImageArchitecture[];
  categories: DockerSearchImageCategory[];
  certification_status: string;
  created_at: string;
  filter_type: string;
  id: "";
  logo_url: DockerSearchImageLogo;
  name: string;
  operating_systems: DockerSearchImageOperatingSystem[];
  popularity: number;
  publisher: DockerSearchImagePublisher;
  short_description: string;
  slug: string;
  source: string;
  star_count: number;
  pull_count: string;
  type: "image";
  updated_at: string;
}

interface DockerSearchResponse {
  count: number;
  next?: string;
  previous?: string;
  page: number;
  page_size: number;
  summaries?: DockerSearchImage[];
}

const fetcher = (url) =>
  fetch(url, { headers: new Headers({ "search-version": "v3" }) }).then((r) =>
    r.json()
  );

function getProxyUrl(url?: string | null): string | null {
  return url ? `${config.KRAKEN_URL}/${url}` : null;
}

export function useDockerSearch(
  searchStr: string,
  pageSize = 10
): {
  loading: boolean;
  items: DockerSearchImage[];
  totalPages: number;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
} {
  const debouncedSearchString = useDebounce(searchStr, 1000);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const searchHistory = useRef<Set<string>>(new Set());

  useEffect(() => {
    searchHistory.current.add(`${debouncedSearchString}_${currentPage}`);
  }, [debouncedSearchString, currentPage]);

  const dockerHubUrl = useMemo(() => {
    let baseURL = `https://hub.docker.com/api/content/v1/products/search?page_size=${pageSize}&type=image`;
    if (!searchStr) {
      baseURL += "&category=application_services&operating_system=linux";
    }
    if (searchHistory.current.has(`${searchStr}_${currentPage}`)) {
      return `${baseURL}&q=${searchStr}&page=${currentPage + 1}`;
    }
    return `${baseURL}&q=${debouncedSearchString}&page=${currentPage + 1}`;
  }, [currentPage, searchStr, debouncedSearchString, pageSize]);

  const { data } = useSwr<DockerSearchResponse>(getProxyUrl(dockerHubUrl), {
    fetcher,
  });

  const items =
    data?.summaries?.filter((i) => {
      // filter out marketplace images
      return !i.name.match(/[A-Z ]/g);
    }) || [];

  const totalPages = useMemo(() => {
    return Math.ceil((data?.count || 0) / pageSize);
  }, [pageSize, data]);

  const loading =
    !data ||
    (searchStr !== debouncedSearchString &&
      !searchHistory.current.has(`${searchStr}_${currentPage}`));

  useEffect(() => {
    setCurrentPage(0);
  }, [debouncedSearchString]);

  //keep everything in bounds
  useEffect(() => {
    if (!loading) {
      setCurrentPage(
        currentPage > totalPages - 1
          ? totalPages
          : currentPage < 0
          ? 0
          : currentPage
      );
    }
  }, [totalPages, currentPage, loading]);

  return {
    loading,
    items,
    totalPages,
    currentPage,
    setCurrentPage,
  };
}

interface DockerImageTagImage {
  architecture: Maybe<string>;
  digest: Maybe<string>;
  features: Maybe<string>;
  last_pulled: Maybe<string>;
  last_pushed: Maybe<string>;
  os: Maybe<string>;
  os_features: Maybe<string>;
  os_version: Maybe<string>;
  size: Maybe<number>;
  status: Maybe<string>;
  variant: Maybe<string>;
}

interface DockerImageTag {
  creator: number;
  full_size: number;
  id: number;
  image_id: null;
  images: DockerImageTagImage[];
  last_updated: string;
  last_updater: number;
  last_updater_username: string;
  name: string;
  slug: string;
  repository: number;
  tag_last_pulled: string;
  tag_last_pushed: string;
  tag_status: string;
  v2: boolean;
}

interface DockerImageTagResponse {
  count: number;
  page?: number;
  page_size?: number;
  next?: Maybe<string>;
  previous?: Maybe<string>;
  results: DockerImageTag[];
}

export function useDockerTagSearch(
  image: string | null | undefined,
  searchStr: string,
  pageSize = 10
): {
  loading: boolean;
  items: DockerImageTag[];
  totalPages: number;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
} {
  const debouncedSearchString = useDebounce(searchStr, 1000);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const searchHistory = useRef<Set<string>>(new Set());

  const safeImage = image || "";

  const realLibName =
    safeImage.indexOf("/") > -1 ? safeImage : `library/${safeImage}`;

  const proxyUrl = getProxyUrl(
    image
      ? `https://hub.docker.com/v2/repositories/${realLibName}/tags/?page_size=${pageSize}&page=${
          currentPage + 1
        }&name=${debouncedSearchString}&ordering=last_updated`
      : null
  );

  const { data, error } = useSwr<DockerImageTagResponse>(proxyUrl, (url) =>
    fetch(url).then((r) => r.json())
  );

  if (error) {
    console.error("failed to get tags", error);
  }

  const totalPages = useMemo(() => {
    return Math.ceil((data?.count || 0) / pageSize);
  }, [pageSize, data]);

  const loading =
    !!image &&
    (!data ||
      (searchStr !== debouncedSearchString &&
        !searchHistory.current.has(`${searchStr}_${currentPage}`)));

  useEffect(() => {
    setCurrentPage(0);
  }, [image, debouncedSearchString]);

  //keep everything in bounds
  useEffect(() => {
    if (!image) {
      return;
    }
    if (!loading) {
      setCurrentPage(
        currentPage > totalPages - 1
          ? totalPages
          : currentPage < 0
          ? 0
          : currentPage
      );
    }
  }, [totalPages, currentPage, loading, image]);

  return {
    loading,
    items: data?.results || [],
    totalPages,
    currentPage,
    setCurrentPage,
  };
}
