import { Box, ComponentWithAs, Flex, FlexProps } from "@chakra-ui/react";
import { useColors } from "../../hooks/useColors";

interface DetailDataBlockProps extends FlexProps {
  icon?: React.ReactElement;
}

/**
 * A block in a DetailSidebar that represents a link to an outside resource
 * @example
 * <DetailDataBlock icon={<FaDocker />}>latest</DetailDataBlock>
 * @example
 * <DetailDataBlock as={ZLink} to={"/deployments"}>deployment</DetailDataBlock>
 */
export const DetailDataBlock: ComponentWithAs<
  typeof Flex,
  DetailDataBlockProps
> = ({ children, icon, ...props }: DetailDataBlockProps) => {
  const { bg3 } = useColors();
  return (
    <Flex
      _hover={{ textDecoration: "underline" }}
      alignItems="center"
      border="1px"
      borderColor="var(--chakra-colors-chakra-border-color)"
      borderRadius="md"
      bg={bg3}
      fontWeight={600}
      fontSize="1em"
      px={4}
      py={2}
      mb={2}
      cursor="pointer"
      {...props}
    >
      {icon && <Box mr={2}>{icon}</Box>}
      <Box textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
        {children}
      </Box>
    </Flex>
  );
};
