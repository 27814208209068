import { useIsFormValueSet } from "~/hooks/useIsFormValueSet";

import { Flex, Switch } from "@chakra-ui/react";
import { UpdateDeployInput } from "@zeet/web-api/dist/graphqlv1";
import { FormLabel, Tooltip } from "@zeet/web-ui";
import { useFormContext } from "react-hook-form";
import { TerraformOutputs } from "../../../inputs/TerraformExcludedOutputs";
import { SettingsSection } from "../../SettingsSection";

export const TerraformOutputSettings = () => {
  const { setValue, watch } = useFormContext<UpdateDeployInput>();
  if (!useIsFormValueSet("configuration.terraform")) return null;

  const outputsEnabled = !watch(
    "configuration.terraform.blueprint.outputConfiguration.automatic.disabled"
  );
  const outputsSensitive =
    watch(
      "configuration.terraform.blueprint.outputConfiguration.automatic.sensitive"
    ) || false;
  const outputsExcluded = watch(
    "configuration.terraform.blueprint.outputConfiguration.automatic.excluded"
  );

  return (
    <SettingsSection
      name="Outputs"
      description="Configure the outputs of this Terraform project"
    >
      <Flex gap="2">
        <Switch
          isChecked={outputsEnabled}
          onChange={(e) => {
            setValue(
              "configuration.terraform.blueprint.outputConfiguration.automatic.disabled",
              !e.target.checked,
              { shouldDirty: true }
            );
          }}
        />
        <FormLabel>
          Enable automatic Terraform outputs
          <Tooltip text="Produces a single map named 'outputs' containing all module outputs" />
        </FormLabel>
      </Flex>
      <FormLabel display="flex" gap="2" hidden={!outputsEnabled}>
        <Switch
          isChecked={outputsSensitive}
          onChange={(e) => {
            setValue(
              "configuration.terraform.blueprint.outputConfiguration.automatic.sensitive",
              e.target.checked,
              { shouldDirty: true }
            );
            if (e.target.checked) {
              setValue(
                "configuration.terraform.blueprint.outputConfiguration.automatic.excluded",
                [],
                { shouldDirty: true }
              );
            }
          }}
        />
        Mark all Terraform outputs as sensitive
      </FormLabel>
      {!outputsSensitive && (
        <TerraformOutputs
          value={outputsExcluded}
          onChange={(value) =>
            setValue(
              "configuration.terraform.blueprint.outputConfiguration.automatic.excluded",
              value.filter((v) => v !== ""),
              { shouldDirty: true }
            )
          }
        />
      )}
    </SettingsSection>
  );
};
