import {
  Box,
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useDisconnectUserFromIamMutation } from "@zeet/web-api/dist/graphql";
import { ICurrentConnection, ZError } from "@zeet/web-ui";
import React from "react";
import { useConsoleUsersContext } from "../ConsoleUsersProvider";
import ConsoleCloudUserIcon from "./ConsoleCloudUserIcon";

interface ConnectedUserModalProps {
  onClose: () => void;
  isOpen: boolean;
  currentConnection: ICurrentConnection | undefined;
}

export const ConnectedUserModal: React.FC<ConnectedUserModalProps> = ({
  onClose,
  isOpen,
  currentConnection,
}) => {
  const toast = useToast();
  const [, { refetchTeamCloud }] = useConsoleUsersContext();

  const [disconnectUser, { error, loading }] = useDisconnectUserFromIamMutation(
    {
      onCompleted: (data) => {
        if (data) {
          refetchTeamCloud();
          toast({
            title: "Connection Removed!",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      },
    }
  );

  return (
    <Flex>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="lg"
        blockScrollOnMount={false}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody p="0">
            <Flex h="48px" p="16px" borderBottomWidth="1px">
              <Text
                fontSize="14px"
                fontWeight="600"
                justifyContent="space-between"
              >
                Connected user
              </Text>
              <ModalCloseButton />
            </Flex>
            <Box px="16px">
              <Flex
                justifyContent="space-between"
                py="16px"
                borderBottomWidth="1px"
              >
                <Text opacity="0.5" fontSize="14px">
                  Connected cloud user
                </Text>
                <Flex>
                  <ConsoleCloudUserIcon
                    simpleCloudId={currentConnection?.shortFormArn}
                    cloudProvider={currentConnection?.cloudProvider}
                  />
                </Flex>
              </Flex>
              <Flex
                justifyContent="space-between"
                py="16px"
                borderBottomWidth="1px"
              >
                <Text opacity="0.5" fontSize="14px">
                  Connected zeet user
                </Text>
                <Flex>
                  <Flex alignItems="center">
                    <Image
                      height="24px"
                      width="24px"
                      borderRadius="50%"
                      src={currentConnection?.member?.user.avatar || ""}
                    />
                  </Flex>
                  <Box ml="10px">
                    <Text fontSize="14px" lineHeight="14px">
                      {currentConnection?.member?.user.name}
                    </Text>
                    <Text fontSize="12px" lineHeight="12px" opacity="0.5">
                      {currentConnection?.member?.user.login}
                    </Text>
                  </Box>
                </Flex>
              </Flex>
              <Flex
                justifyContent="space-between"
                py="16px"
                borderBottomWidth="1px"
              >
                <Text opacity="0.5" fontSize="14px">
                  Assigned role
                </Text>
                <Box bg="rgba(75, 147, 255, 0.1)" p="4px" borderRadius="6px">
                  <Text fontSize="14px">{currentConnection?.member?.role}</Text>
                </Box>
              </Flex>
              <Flex justifyContent="flex-end" py="16px">
                <Button
                  colorScheme="brand"
                  onClick={() => {
                    if (!currentConnection?.connectionId) return;
                    disconnectUser({
                      variables: {
                        id: currentConnection?.connectionId,
                      },
                    });
                  }}
                  isLoading={loading}
                >
                  <Text fontSize="14px">Unlink connection</Text>
                </Button>
                <ZError error={error} />
              </Flex>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};
