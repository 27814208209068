import { InfoIcon } from "@chakra-ui/icons";
import { BoxProps, Tooltip as CTooltip } from "@chakra-ui/react";

export const Tooltip = ({
  text,
  ...props
}: {
  text: string | JSX.Element;
  props?: BoxProps;
}): JSX.Element => {
  return (
    <CTooltip
      hasArrow
      label={text}
      placement="top"
      aria-label=""
      p={3}
      borderRadius={8}
    >
      <InfoIcon
        boxSize="3.5"
        ml={2}
        mr={2}
        {...props}
        display="inline"
        opacity={0.5}
      />
    </CTooltip>
  );
};
