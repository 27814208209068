import { Box, ContainerProps } from "@chakra-ui/react";

export const MainContainer: React.FC<ContainerProps> = ({
  children,
  ...restProps
}) => {
  return (
    <Box width="100%" {...restProps}>
      {children}
    </Box>
  );
};
