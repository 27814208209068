import {
  ProjectContextFragment,
  ProjectContextWithoutEnvironmentFragment,
  useProjectRevisionsQuery,
} from "@zeet/web-api/dist/graphql";
import { usePagination } from "@zeet/web-ui";
import { RevisionsList } from "~/components/Revisions";

const Revisions: React.FC<{
  project: ProjectContextFragment | ProjectContextWithoutEnvironmentFragment;
}> = ({ project }) => {
  const { pageInput, setPage } = usePagination();

  const { data, loading } = useProjectRevisionsQuery({
    variables: {
      id: project.id,
      page: pageInput,
    },
    fetchPolicy: "cache-and-network",
  });

  return (
    <RevisionsList
      revisions={{
        nodes: data?.project?.revisions?.nodes ?? [],
        pageInfo: data?.project?.revisions?.pageInfo,
      }}
      loading={loading}
      pageChanged={setPage}
    />
  );
};

export default Revisions;
