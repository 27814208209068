import { Flex } from "@chakra-ui/react";
import { ReactNode } from "react";

interface IconButtonGroupProps {
  children: ReactNode;
}

export const IconButtonGroup = ({ children }: IconButtonGroupProps) => {
  return (
    <Flex
      display="inline-flex"
      borderRadius="md"
      sx={{
        boxShadow: "md",
        button: { borderRadius: 0, boxShadow: "none" },
        "button:nth-child(n + 2)": { borderLeft: "0" },
        "button:first-child, button:last-child": { borderRadius: "md" },
        "button:first-child:not(:last-child)": { borderRightRadius: "0" },
        "button:last-child:not(:first-child)": { borderLeftRadius: "0" },
      }}
    >
      {children}
    </Flex>
  );
};
