import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { Link } from "@zeet/web-ui";
import { ImArrowUp } from "react-icons/im";
import { useHistory } from "react-router-dom";
import { useBillingOverviewContext } from "../Providers/BillingOverviewProvider";

export const UpgradeProductModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  headerText: string;
  titleText: string;
  bodyText: string;
  shouldGoBack: boolean;
  primaryButtonText: string;
  primaryButtonLink: string;
}> = ({
  isOpen,
  onClose,
  headerText,
  titleText,
  bodyText,
  shouldGoBack,
  primaryButtonLink,
  primaryButtonText,
}) => {
  const [{ userBillingOverview }] = useBillingOverviewContext();
  const history = useHistory();

  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      onClose={() => {
        if (shouldGoBack) {
          history.goBack();
        }
        onClose();
      }}
      preserveScrollBarGap={true}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton data-testid="upgrade-modal-close-button" />
        <ModalHeader>
          <Flex alignItems="center">
            <ImArrowUp fill="#00EF00" />
            <Text ml={4}>{headerText}</Text>
          </Flex>
        </ModalHeader>
        <ModalBody>
          <Box pb={6}>
            <Text fontWeight="bold" fontSize="18px">
              {titleText}
            </Text>
            <Text mt="24px">{bodyText}</Text>
            <Flex mt="24px" justifyContent="space-between">
              <Button
                _hover={{ textDecor: "none", color: "black" }}
                colorScheme="brand"
                width="48%"
                as={Link}
                to={primaryButtonLink}
              >
                {primaryButtonText}
              </Button>
              <Button
                _hover={{ textDecor: "none" }}
                as={Link}
                isExternal
                to={
                  userBillingOverview?.user.billingOverview?.externalLinks?.help
                    .url || ""
                }
                width="48%"
              >
                Talk to Sales
              </Button>
            </Flex>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
