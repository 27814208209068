import {
  BlueprintType as BlueprintTypeV1,
  BlueprintVariableInput as BlueprintVariableInputV1,
  BlueprintVariableType,
  CloudProvider,
  CreateProjectV3Input,
  GitSourceIntegrationInput,
  TerraformStateBackendInput,
} from "@zeet/web-api/dist/graphql";
import {
  BlueprintDriverWorkflowStepAction,
  BlueprintType,
  BlueprintVariableInput,
  BuildDefinitionInput,
  BuildTargetInput,
  BuildTargetType,
  BuildType,
  CreateProjectInput,
  DeploymentConfigurationAwsSamInput,
  DeploymentConfigurationGcpCloudRunInput,
  DeploymentConfigurationInput,
  DeploymentConfigurationKubernetesHelmInput,
  DeploymentConfigurationKubernetesInput,
  DeploymentConfigurationTerraformInput,
  GitSourceInput,
  WorkflowStepActionType,
} from "@zeet/web-api/dist/graphqlv1";
import {
  BridgeBlueprint,
  BridgeBlueprintInput,
  BridgeBlueprintInputId,
  BridgeBlueprintInputType,
  BuildTemplate,
  isSourcelessBlueprint,
} from "@zeet/web-ui";
import { v4 as uuid } from "uuid";
import { regions } from "~/components/utils/cloud";
import { NewResourceValues } from "./context";

const makeVariablesFromArray = (array) => {
  if (Array.isArray(array)) {
    return array.map((v) => {
      return {
        value:
          v.value ||
          v[BridgeBlueprintInputId.TerraformVariableValue]?.toString(),
        variableName:
          v.variableName || v[BridgeBlueprintInputId.TerraformVariableKey],
        variableType:
          v.variableType || v[BridgeBlueprintInputId.TerraformVariableType],
      };
    });
  }

  return [];
};

const flattenVariables = (variables: BridgeBlueprintInput[]) => {
  const flatVariables: BridgeBlueprintInput[] = [];
  for (const variable of variables) {
    if (variable.type === BridgeBlueprintInputType.Group) {
      flatVariables.push(...flattenVariables(variable.variables ?? []));
    } else {
      flatVariables.push(variable);
    }
  }

  return flatVariables;
};

const inferVariableType = (
  name: string,
  value: string,
  variables: BridgeBlueprintInput[]
) => {
  let variableType = (variables.find((v) => {
    return v.id === name;
  })?.type ?? BlueprintVariableType.String) as BlueprintVariableType;

  // when we use falseValue and trueValue the BOOLEAN type is not the one to chosen
  if (
    variableType === BlueprintVariableType.Boolean &&
    typeof value !== "boolean"
  ) {
    if (typeof value === "number" || typeof value === "bigint") {
      variableType = Number.isSafeInteger(value)
        ? BlueprintVariableType.Integer
        : BlueprintVariableType.Float;
    } else {
      variableType = BlueprintVariableType.String;
    }
  }

  return variableType;
};

const makeVariablesFromObject = (
  variables: CreateProjectV3Input["variables"] | any,
  blueprint?: Partial<BridgeBlueprint> | null,
  parentKey = ""
): BlueprintVariableInputV1[] => {
  const flatVariables = flattenVariables(blueprint?.variables ?? []);

  return Object.keys(variables).reduce(
    (acc: BlueprintVariableInputV1[], key: string) => {
      const value = variables[key];
      const variableName = parentKey ? `${parentKey}.${key}` : key;

      if (typeof value === "object" && value !== null) {
        acc.push(...makeVariablesFromObject(value, blueprint, variableName));
      } else {
        const valueStr = String(value);
        const variableType = inferVariableType(
          variableName,
          value,
          flatVariables
        );

        acc.push({
          variableName,
          value: valueStr,
          variableType: variableType,
        });
      }

      return acc;
    },
    []
  );
};

const makeBlueprintVariables = (
  data: NewResourceValues
): CreateProjectV3Input["variables"] => {
  if (
    data.blueprintType === BlueprintTypeV1.Helm &&
    Object.keys(data.blueprint?.richInputSchema ?? {})?.length
  ) {
    return makeVariablesFromObject(data.variables, data.blueprint);
  }

  if (isSourcelessBlueprint(data.blueprintSlug)) {
    const variables =
      data.variables?.[BridgeBlueprintInputId.TerraformVariables] ??
      data.variables;
    return makeVariablesFromArray(variables).filter((v) => v.value);
  }

  if (data.blueprint?.richInputSchema) {
    return Object.entries(data.variables).map(
      ([key, value]): BlueprintVariableInputV1 => ({
        variableName: key,
        value,
        variableType: (flattenVariables(data.blueprint?.variables ?? []).find(
          (v) => v.id === key
        )?.type ?? BlueprintVariableType.String) as BlueprintVariableType,
      })
    );
  }

  return Object.entries(data.variables).map(
    ([key, value]): BlueprintVariableInputV1 => ({
      variableSpecID: key,
      value: typeof value === "boolean" ? value : String(value),
    })
  );
};

const makeStateBackendCloudProvider = (
  data: NewResourceValues
): TerraformStateBackendInput | undefined => {
  const stateBackend = data.target.terraform?.stateBackend;
  const cloudId = stateBackend?.cloudId ?? "";
  const cloudProvider = stateBackend?.cloudProvider;
  const key = stateBackend?.key ?? "";
  const bucketName = stateBackend?.bucketName ?? "";
  const region = stateBackend?.region ?? "";

  if (cloudProvider === CloudProvider.Aws) {
    return {
      s3Bucket: {
        awsAccountID: cloudId,
        bucketName: bucketName,
        region: region,
        key: key,
      },
    };
  } else if (cloudProvider === CloudProvider.Gcp) {
    return {
      gcsBucket: {
        gcpAccountID: cloudId,
        bucketName: bucketName,
        location: region,
        prefix: key,
      },
    };
  }
};

const makeBlueprintTerraformConfiguration = (
  data: NewResourceValues
): CreateProjectV3Input["terraformConfiguration"] => {
  if (data.blueprintType === BlueprintTypeV1.Terraform) {
    if (data.target.provider?.provider === CloudProvider.Aws) {
      return {
        moduleName: data.organize.name,
        stateBackend: makeStateBackendCloudProvider(data) ?? {},
        provider: {
          awsAccountID: data.target.provider?.value,
          region: data.target.region ?? regions[CloudProvider.Aws][0]?.name,
        },
      };
    } else if (data.target.provider?.provider === CloudProvider.Gcp) {
      return {
        moduleName: data.organize.name,
        stateBackend: makeStateBackendCloudProvider(data) ?? {},
        provider: {
          gcpAccountID: data.target.provider?.value,
          region: data.target.region ?? regions[CloudProvider.Gcp][0]?.name,
        },
      };
    } else if (data.target.provider?.provider === CloudProvider.Linode) {
      return {
        moduleName: data.organize.name,
        stateBackend: makeStateBackendCloudProvider(data) ?? {},
        provider: {
          linodeAccountID: data.target.provider?.value,
          region: data.target.region ?? regions[CloudProvider.Linode][0]?.name,
        },
      };
    }
  }
};

const makeGitIntegrationInput = (values?: GitSourceIntegrationInput | null) => {
  return values?.githubInstallationID
    ? {
        githubInstallationId: values?.githubInstallationID,
      }
    : undefined;
};

const makeGitSourceInput = (
  values: NewResourceValues
): GitSourceInput | undefined => {
  const { source } = values;
  if (source?.git && source.git.repository) {
    return {
      repository: source.git.repository,
      ref: source.git.ref,
      path: source.git.path,
      integration: makeGitIntegrationInput(source.git.integration),
    };
  }
};

const buildTypeMap = {
  [BuildTemplate.NodeJs]: BuildType.Node,
};

const makeBuildTargetInput = (
  values: NewResourceValues
): BuildTargetInput | undefined => {
  const { target } = values;
  if (values.blueprintType === BlueprintType.AwsSam) {
    return {
      type: BuildTargetType.AwsSamContainerRegistry,
      integration: {
        awsAccountId: target.provider?.value,
        awsRegion: target.region,
      },
    };
  }
  if (values.blueprintType === BlueprintType.GcpCloudRun) {
    return {
      type: BuildTargetType.GcpContainerRegistry,
      integration: {
        gcpAccountId: target.provider?.value,
        gcpRegion: target.region,
      },
    };
  }
};

const makeBuildDefinitionInput = (
  values: NewResourceValues
): BuildDefinitionInput | undefined => {
  const { variables, source } = values;

  const makeRunCommand = () => {
    if (values.blueprintType === BlueprintType.GcpCloudRun) {
      return variables?.[BridgeBlueprintInputId.RunCommand];
    }
  };

  if (hasBuildStep(values)) {
    return {
      target: makeBuildTargetInput(values),
      gitSource: makeGitSourceInput(values),
      buildCommand: variables?.[BridgeBlueprintInputId.BuildCommand],
      type: buildTypeMap[variables?.[BridgeBlueprintInputId.BuildTemplate]],
      nodeJsVersion: variables?.[BridgeBlueprintInputId.NodeVersion],
      workingDirectory: source?.git?.path,
      runCommand: makeRunCommand(),
    };
  }
};

const makeAwsSamInput = (
  values: NewResourceValues
): DeploymentConfigurationAwsSamInput | undefined => {
  if (values.blueprintType === BlueprintType.AwsSam) {
    const { variables, target, organize } = values;
    return {
      target: {
        awsAccountId: target.provider?.value,
        awsRegion: target.region,
        stackName: `${organize.name}-${uuid()}`,
      },
      generator: {
        runCommand: variables?.[BridgeBlueprintInputId.RunCommand],
        httpPort: variables?.[BridgeBlueprintInputId.NetworkPort],
        serverlessMemory: 128,
      },
    };
  }
};

const makeGcpCloudRunInput = (
  values: NewResourceValues
): DeploymentConfigurationGcpCloudRunInput | undefined => {
  if (values.blueprintType === BlueprintType.GcpCloudRun) {
    const { variables, target, organize } = values;
    return {
      target: {
        gcpAccountId: target.provider?.value,
        gcpRegion: target.region,
      },
      generator: {
        name: `${organize.name}-${uuid()}`,
        httpPort: variables?.[BridgeBlueprintInputId.NetworkPort],
        containerMemory: 1,
        containerCpu: 1,
      },
    };
  }
};

const makeHelmInput = (
  values: NewResourceValues
): DeploymentConfigurationKubernetesHelmInput | undefined => {
  if (values.blueprintType === BlueprintType.Helm) {
    return {
      blueprint: values.source
        ? {
            source: {
              git: makeGitSourceInput(values),
              helmRepository: values.source.helmRepository
                ? {
                    repositoryUrl: values.source.helmRepository.repositoryURL,
                    chart: values.source.helmRepository.chart,
                    version: values.source.helmRepository.version,
                  }
                : undefined,
            },
          }
        : undefined,
      target: {
        clusterId: values.target.provider?.value ?? "",
        namespace: values.target.helm?.namespace,
        releaseName: values.target.helm?.releaseName,
      },
      values: values.target.helm?.values,
    };
  }
};

const makeKubernetesInput = (
  values: NewResourceValues
): DeploymentConfigurationKubernetesInput | undefined => {
  if (values.blueprintType === BlueprintType.KubernetesManifest) {
    return {
      blueprint: values.blueprint
        ? {
            source: {
              git: makeGitSourceInput(values),
            },
          }
        : undefined,
      target: {
        clusterId: values.target.provider?.value ?? "",
        namespace: values.target.manifest?.namespace,
      },
    };
  }
};

const makeTerraformInput = (
  values: NewResourceValues
): DeploymentConfigurationTerraformInput | undefined => {
  if (values.blueprintType === BlueprintType.Terraform) {
    const v3Config = makeBlueprintTerraformConfiguration(values);
    if (!v3Config) return undefined;
    const hasSource = values.source?.git || values.source?.terraformRegistry;
    const gitSource = values.source?.git?.repository
      ? `git::${values.source?.git?.repository}`
      : "";

    return {
      blueprint: hasSource
        ? {
            source: {
              terraformModule: {
                source: values?.source?.terraformRegistry?.source ?? gitSource,
                version: values?.source?.terraformRegistry?.version,
                integration: {
                  git: makeGitIntegrationInput(
                    values?.source?.git?.integration
                  ),
                },
              },
            },
          }
        : undefined,
      target: {
        moduleName: v3Config.moduleName,
        provider: {
          awsAccountId: v3Config.provider?.awsAccountID,
          gcpAccountId: v3Config.provider?.gcpAccountID,
          linodeAccountId: v3Config.provider?.linodeAccountID,
          region: v3Config.provider?.region,
        },
        stateBackend: {
          s3Bucket: v3Config.stateBackend?.s3Bucket
            ? {
                awsAccountId: v3Config.stateBackend.s3Bucket.awsAccountID,
                bucketName: v3Config.stateBackend.s3Bucket.bucketName,
                region: v3Config.stateBackend.s3Bucket.region,
                key: v3Config.stateBackend.s3Bucket.key,
              }
            : undefined,
          gcsBucket: v3Config.stateBackend?.gcsBucket
            ? {
                gcpAccountId: v3Config.stateBackend.gcsBucket.gcpAccountID,
                bucketName: v3Config.stateBackend.gcsBucket.bucketName,
                location: v3Config.stateBackend.gcsBucket.location,
                prefix: v3Config.stateBackend.gcsBucket.prefix,
              }
            : undefined,
          linodeBucket: v3Config.stateBackend?.linodeBucket
            ? {
                linodeAccountId:
                  v3Config.stateBackend.linodeBucket.linodeAccountID,
                bucketName: v3Config.stateBackend.linodeBucket.bucketName,
                region: v3Config.stateBackend.linodeBucket.region,
                key: v3Config.stateBackend.linodeBucket.key,
                accessKey: v3Config.stateBackend.linodeBucket.accessKey,
                secretAccessKey:
                  v3Config.stateBackend.linodeBucket.secretAccessKey,
              }
            : undefined,
        },
      },
    };
  }
};

const makeVariablesInput = (
  values: NewResourceValues
): BlueprintVariableInput[] | undefined => {
  if (hasVariables(values)) {
    const v3Variables = makeBlueprintVariables(values);
    return v3Variables.map((v) => ({
      name: v.variableName,
      specId: v.variableSpecID,
      value: v.value,
      type: v.variableType,
    }));
  }
};

const makeDefaultWorkflowSteps = (
  values: NewResourceValues
): BlueprintDriverWorkflowStepAction[] | undefined => {
  return (
    values.target.configuration?.defaultWorkflowSteps || [
      BlueprintDriverWorkflowStepAction.DriverPlan,
      BlueprintDriverWorkflowStepAction.DriverApprove,
      BlueprintDriverWorkflowStepAction.DriverApply,
    ]
  );
};

const makeDeployInput = (
  values: NewResourceValues
): DeploymentConfigurationInput[] => {
  return [
    {
      defaultWorkflowSteps: makeDefaultWorkflowSteps(values),
      awsSam: makeAwsSamInput(values),
      gcpCloudRun: makeGcpCloudRunInput(values),
      helm: makeHelmInput(values),
      kubernetes: makeKubernetesInput(values),
      terraform: makeTerraformInput(values),
      variables: makeVariablesInput(values),
    },
  ];
};

const makeCreateProjectInput = (
  values: NewResourceValues,
  options?: { enabled?: boolean }
): CreateProjectInput => {
  const { organize, blueprintID, userID } = values;

  const steps = hasBuildStep(values)
    ? [
        { action: WorkflowStepActionType.OrchestrationBuild },
        { action: WorkflowStepActionType.OrchestrationDeploy },
      ]
    : [{ action: WorkflowStepActionType.OrchestrationDeploy }];

  return {
    name: organize.name,
    groupName: organize.projectName,
    subGroupName: organize.environmentName,
    blueprintId: blueprintID,
    enabled: options?.enabled ?? false,
    teamId: userID,
    build: makeBuildDefinitionInput(values),
    deploys: makeDeployInput(values),
    workflow: {
      steps,
    },
  };
};

const hasBuildStep = (values: NewResourceValues) => {
  return (
    values.blueprintType === BlueprintType.AwsSam ||
    values.blueprintType === BlueprintType.GcpCloudRun
  );
};

const hasVariables = (values: NewResourceValues) => {
  return (
    values.blueprintType === BlueprintType.Helm ||
    values.blueprintType === BlueprintType.Terraform ||
    values.blueprintType === BlueprintType.KubernetesManifest
  );
};

export { makeCreateProjectInput };
