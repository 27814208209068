import {
  AddIcon,
  CheckIcon,
  ChevronDownIcon,
  SearchIcon,
} from "@chakra-ui/icons";
import {
  Avatar,
  Button,
  Divider,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  List,
  ListItem,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import {
  UserCommonFragment,
  UserGithubReposQuery,
} from "@zeet/web-api/dist/graphql";
import {
  Skeleton,
  useAccessToken,
  useColors,
  useCurrentTeamUser,
  useCurrentUser,
  useTrack,
  ZeetLogo,
} from "@zeet/web-ui";
import Emoji from "a11y-react-emoji";
import React, { useEffect, useMemo, useState } from "react";
import { FaGithub, FaUsers } from "react-icons/fa";
import { HashLink } from "react-router-hash-link";
import config from "~/utils/config";
import { parseGitUri } from "~/utils/parsers";
import { ArrayElement } from "~/utils/ts-utils";
import { useGithubContext } from "./githubContext";
import { PaginationControls } from "./PaginationControls";
import { RepoItem } from "./RepoItem";
import {
  InstallationType,
  InstallationWithType,
  useGithubOrg,
} from "./useGithubOrg";
import { useOrgGithubRepos, usePaginatedOrgGithubRepos } from "./useGithubRepo";

interface OrgSelectProps {
  user: UserCommonFragment;
}

enum TargetType {
  ORGANIZATION = "Organization",
  USER = "User",
}

const OrgMenuItem: React.FC<{
  install: InstallationWithType;
  user: UserCommonFragment;
}> = ({ install, user }) => {
  const { track } = useTrack();
  const { setActiveOrg, activeOrgWithType } = useGithubOrg();

  return (
    <MenuItem
      onClick={() => {
        setActiveOrg(install.installation.account.login);
        track("select_gh_org", { login: install.installation.account.login });
      }}
    >
      <Avatar
        bg="inherit"
        icon={<ZeetLogo />}
        src={install.installation.account.avatar || user.avatar || ""}
        size="xs"
      />

      <Text ml={4} mr={2}>
        {install.installation.account.login}
      </Text>
      {install.type === InstallationType.TEAM && (
        <Tooltip
          position="relative"
          label="A github account linked to Zeet through a Zeet team integration."
        >
          <span>
            <FaUsers />
          </span>
        </Tooltip>
      )}
      {install.installation.account.login ===
        activeOrgWithType?.installation.account.login && (
        <CheckIcon marginLeft="auto" />
      )}
    </MenuItem>
  );
};

const OrgSelect: React.FC<OrgSelectProps> = ({ user }) => {
  const { track } = useTrack();
  const { orgsWithType, manageOrgs, activeOrgWithType } = useGithubOrg();
  const [token] = useAccessToken();
  const currentTeamUser = useCurrentTeamUser();
  const NoDuplicatesUserAccounts = useMemo(() => {
    const raw = orgsWithType?.filter(
      (o) => o.installation.targetType === TargetType.USER
    );
    return [
      ...new Map(raw.map((m) => [m.installation.account.login, m])).values(),
    ];
  }, [orgsWithType]);

  return (
    <Menu placement="bottom-start">
      <MenuButton
        as={Button}
        {...{
          flexShrink: 0,
          rightIcon: <ChevronDownIcon />,
          leftIcon: (
            <Avatar
              bg="inherit"
              icon={<ZeetLogo />}
              src={
                activeOrgWithType?.installation.account.avatar ||
                user.avatar ||
                ""
              }
              size="xs"
            />
          ),
        }}
        maxWidth="70%"
      >
        <Text ml={2} noOfLines={1} maxW="19rem">
          {activeOrgWithType?.installation.account.login || "Unknown User"}
        </Text>
      </MenuButton>
      <MenuList zIndex={100}>
        <MenuItem>Organizations</MenuItem>
        {orgsWithType
          ?.filter((o) => o.installation.targetType === TargetType.ORGANIZATION)
          .map((install, i) => {
            return <OrgMenuItem key={i} install={install} user={user} />;
          })}
        <MenuItem>Users</MenuItem>
        {NoDuplicatesUserAccounts.map((install, i) => {
          return <OrgMenuItem key={i} install={install} user={user} />;
        })}
        <MenuDivider />
        <MenuItem>
          <HashLink
            smooth
            to={`/${currentTeamUser?.login}/account/integrations#github-integration-section`}
          >
            <Flex alignItems="center">
              <FaUsers />
              <Text ml={4}>Add/Manage Team Github Integrations</Text>
            </Flex>
          </HashLink>
        </MenuItem>
        <MenuItem
          onClick={() => {
            track("click_add_github_org");
            manageOrgs(
              `${
                config.ANCHOR_URL
              }/login/github?install=${true}&integration=${false}&popup=${true}&teamuser_id=${
                currentTeamUser.id
              }&token=${token}`
            );
          }}
        >
          <AddIcon />
          <Text ml={4}> Add New Organization</Text>
        </MenuItem>
        <MenuItem
          onClick={() => {
            track("click_manage_github");
            manageOrgs(
              `${
                config.ANCHOR_URL
              }/login/github?install=${true}&integration=${false}&popup=${true}&teamuser_id=${
                currentTeamUser.id
              }&token=${token}`
            );
          }}
        >
          <FaGithub />
          <Text ml={4}> Manage Repositories</Text>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export type GithubOrg = ArrayElement<
  UserGithubReposQuery["currentUser"]["githubInstallations"]
>;

type GithubRepo = ArrayElement<
  ArrayElement<
    UserGithubReposQuery["currentUser"]["githubInstallations"]
  >["repositories"]
>;

interface RepoInfo {
  owner: string;
  name: string;
}

export const GithubSelectionCustom: React.FC<{
  onSelect: any;
  isLoading: boolean;
  defaultSelectedRepoUrl?: string;
}> = ({ onSelect, isLoading, defaultSelectedRepoUrl }) => {
  const [selectedRepoIndex, setSelectedRepoIndex] = useState(-1);
  const [selectedRepo, setSelectedRepo] = useState<RepoInfo>();
  const [parsedDefault, setParsedDefault] = useState<RepoInfo>();

  useEffect(() => {
    if (!defaultSelectedRepoUrl) return;

    const parse = parseGitUri(defaultSelectedRepoUrl);
    if (!parse.owner) return;
    const info = { owner: parse.owner, name: parse.repo };
    setParsedDefault(info);
    setSelectedRepo(info);
  }, [defaultSelectedRepoUrl]);

  const matchSelected = (other: GithubRepo) =>
    other.owner === selectedRepo?.owner && other.name === selectedRepo?.name;

  return (
    <GithubSelection defaultRepo={parsedDefault}>
      {(paginatedOrgRepos) =>
        paginatedOrgRepos.map((repo: GithubRepo, index, list) => (
          <React.Fragment key={repo.id}>
            <RepoItem
              now={new Date()}
              repo={repo}
              as={ListItem}
              isActive={matchSelected(repo)}
              buttonText={matchSelected(repo) ? "Selected" : "Select"}
              onSelect={() => {
                onSelect(repo);
                setSelectedRepoIndex(index);
                setSelectedRepo(repo);
              }}
              isLoading={selectedRepoIndex === index && isLoading}
            />
            {index < list.length - 1 && <Divider />}
          </React.Fragment>
        ))
      }
    </GithubSelection>
  );
};

const GithubSelection: React.FC<{
  children: (paginatedOrgRepos) => React.ReactNode;
  defaultRepo?: RepoInfo;
}> = ({ children, defaultRepo }) => {
  const currentUser = useCurrentUser();
  const { track } = useTrack();
  const [token] = useAccessToken();
  const currentTeamUser = useCurrentTeamUser();

  const { loading, teamLoading, openPopup } = useGithubContext();

  const [filter, setFilter] = useState<string>("");

  const [
    paginatedOrgRepos,
    { currentPage, totalPages, setCurrentPage, setPageByRepo },
  ] = usePaginatedOrgGithubRepos(filter);

  const githubRepos = useOrgGithubRepos();

  const { bg2 } = useColors();

  useEffect(() => {
    if (!defaultRepo) return;
    setPageByRepo(defaultRepo.owner, defaultRepo.name);
  }, [defaultRepo, setPageByRepo]);

  if (!currentUser) {
    return null;
  }

  if (loading || teamLoading) {
    return (
      <VStack mt="4" gap="1">
        {[...Array(6).keys()].map((item) => (
          <Skeleton key={item} width="100%" height="48px" borderRadius="md" />
        ))}
      </VStack>
    );
  }

  return (
    <Flex
      direction="column"
      mt="4"
      bg={bg2}
      borderRadius="md"
      justifyContent="space-between"
    >
      {githubRepos.length > 0 ? (
        <>
          <Flex mb={2}>
            <OrgSelect user={currentUser} />
            <InputGroup ml={2} w="auto" flexGrow={1}>
              <InputLeftElement>
                <SearchIcon color="gray.300" />
              </InputLeftElement>
              <Input
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                placeholder="Search Repos"
                onClick={() => track("click_search_repos")}
              />
            </InputGroup>
          </Flex>
          <Divider />
        </>
      ) : null}
      {paginatedOrgRepos.length ? (
        <>
          <List h="15rem">{children(paginatedOrgRepos)}</List>
          <Divider />
          <PaginationControls
            totalPages={totalPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </>
      ) : (
        <>
          {filter ? (
            <Flex
              direction="column"
              textAlign="center"
              mb={2}
              h="15rem"
              alignContent="center"
              justifyContent="center"
            >
              <Text size="sm" ml="1rem">
                <Emoji symbol="🧐" style={{ marginRight: ".5rem" }} />
                Nothing here, matey... maybe refine your search?
                <Emoji label="point up" symbol="👆" />
              </Text>
            </Flex>
          ) : null}
          {!filter ? (
            <Flex width="100%" height="100%" padding="2rem">
              <Flex width="100%" flexDirection="column">
                <Text fontWeight="bold" fontSize="1.75rem">
                  Connect GitHub Account
                </Text>
                <Text mt="0.5rem">Select a repository to deploy</Text>
                <Button
                  leftIcon={<FaGithub />}
                  onClick={() => {
                    track("click_add_github");
                    openPopup(
                      `${
                        config.ANCHOR_URL
                      }/login/github?install=${true}&integration=${false}&popup=${true}&teamuser_id=${
                        currentTeamUser.id
                      }&token=${token}`
                    );
                  }}
                  fontWeight="bold"
                  colorScheme="brand"
                  width="100%"
                  mt="5rem"
                  height="3rem"
                >
                  Connect GitHub
                </Button>
              </Flex>
            </Flex>
          ) : null}
        </>
      )}
    </Flex>
  );
};
