import {
  Button,
  Divider,
  Flex,
  Input,
  Stack,
  Switch,
  Text,
  useToast,
} from "@chakra-ui/react";
import {
  RepoDetailFragment,
  UpdateProjectInput,
  useUpdateProjectSettingsMutation,
} from "@zeet/web-api/dist/graphql";
import { Tooltip, useTrack, ZError } from "@zeet/web-ui";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { shouldDisplayError } from "../util";

type GitIntegrationInput = {
  autoDeployBranch: boolean | undefined;
  autoStopBranch: boolean | undefined;
  branchIgnore: string | undefined;
  branchStopIgnore: string | undefined;
};

const getRepoGitIntegrationValues = (
  repo: RepoDetailFragment
): GitIntegrationInput => {
  if (repo.githubIntegration?.id) {
    return {
      autoDeployBranch:
        repo.githubIntegration?.autoDeployBranch === null
          ? undefined
          : repo.githubIntegration?.autoDeployBranch,
      autoStopBranch:
        repo.githubIntegration?.autoStopBranch === null
          ? undefined
          : repo.githubIntegration?.autoStopBranch,
      branchIgnore: repo.githubIntegration?.branchIgnore || "",
      branchStopIgnore: repo.githubIntegration?.branchStopIgnore || "",
    };
  }

  if (repo.gitlabIntegration?.id) {
    return {
      autoDeployBranch:
        repo.gitlabIntegration?.autoDeployBranch === null
          ? undefined
          : repo.gitlabIntegration?.autoDeployBranch,
      autoStopBranch:
        repo.gitlabIntegration?.autoStopBranch === null
          ? undefined
          : repo.gitlabIntegration?.autoStopBranch,
      branchIgnore: repo.gitlabIntegration?.branchIgnore || "",
      branchStopIgnore: repo.gitlabIntegration?.branchStopIgnore || "",
    };
  }

  return {
    autoDeployBranch: undefined,
    autoStopBranch: undefined,
    branchIgnore: undefined,
    branchStopIgnore: undefined,
  };
};

export const EnvironmentSettings: React.FC<{ repo: RepoDetailFragment }> = ({
  repo,
}) => {
  const toast = useToast();
  const { track } = useTrack();

  const gitIntegrationValues = getRepoGitIntegrationValues(repo);

  const { getValues, register } = useForm<
    {
      manualDeploy: boolean;
      autoRetry: boolean;
      autoRollback: boolean;
    } & GitIntegrationInput
  >({
    defaultValues: {
      manualDeploy: repo.manualDeploy === null ? false : repo.manualDeploy,
      autoRetry: repo.autoRetry === null ? false : repo.autoRetry,
      autoRollback: repo.autoRollback === null ? false : repo.autoRollback,
      ...gitIntegrationValues,
    },
  });

  const [changed, setChanged] = useState(false);

  const [updateSettings, { error, loading, data }] =
    useUpdateProjectSettingsMutation({
      onCompleted: (data) => {
        if (data) {
          toast({
            title: "Branch Rules Saved",
            status: "success",
            duration: 2500,
            isClosable: true,
          });
        }
      },
    });

  const doSave = useCallback(() => {
    const input: UpdateProjectInput = {
      id: repo.id,
      manualDeploy: getValues("manualDeploy"),
      autoRetry: getValues("autoRetry"),
      autoRollback: getValues("autoRollback"),
    };

    if (repo.githubIntegration?.id) {
      input.githubIntegration = {
        autoDeployBranch: getValues("autoDeployBranch"),
        autoStopBranch: getValues("autoStopBranch"),
        branchIgnore: getValues("branchIgnore"),
        branchStopIgnore: getValues("branchStopIgnore"),
      };
    }

    if (repo.gitlabIntegration?.id) {
      input.gitlabIntegration = {
        autoDeployBranch: getValues("autoDeployBranch"),
        autoStopBranch: getValues("autoStopBranch"),
        branchIgnore: getValues("branchIgnore"),
        branchStopIgnore: getValues("branchStopIgnore"),
      };
    }

    updateSettings({
      variables: {
        input,
      },
    });
  }, [
    repo.id,
    repo.gitlabIntegration?.id,
    repo.githubIntegration?.id,
    updateSettings,
    getValues,
  ]);

  useEffect(() => {
    if (changed) {
      setChanged(false);
      doSave();
    }
  }, [doSave, changed]);

  return (
    <form
      onSubmit={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      style={{ width: "100%" }}
    >
      <Stack spacing={2}>
        <Stack spacing={2}>
          <Text fontWeight="bold">
            CI / CD Settings
            <Tooltip text="Continuous Integration / Deployment settings" />
          </Text>

          <Flex width="100%" justifyContent="space-between">
            <Text>
              Manual Deploy
              <Tooltip text="When enabled, your commits will not be build or deployed automatically." />
            </Text>
            <Switch
              size="lg"
              {...register("manualDeploy", {
                onChange: () => setChanged(true),
              })}
            />
          </Flex>
          <Flex width="100%" justifyContent="space-between">
            <Text>
              Auto-Retry on Failure
              <Tooltip text="When enabled, your failed deployment will be automatically retried" />
            </Text>
            <Switch
              size="lg"
              {...register("autoRetry", {
                onChange: () => setChanged(true),
              })}
            />
          </Flex>
          <Flex width="100%" justifyContent="space-between">
            <Text>
              Auto-Rollback on Failure
              <Tooltip text="When enabled, your failed deployment will be automatically rolled back" />
            </Text>
            <Switch
              size="lg"
              {...register("autoRollback", {
                onChange: () => setChanged(true),
              })}
            />
          </Flex>
        </Stack>

        {repo.githubIntegration?.githubRepository?.id && (
          <>
            <Divider />
            <Stack spacing={2}>
              <Text fontWeight="bold">
                Branch Rules
                <Tooltip text="Do you want branches to auto-build? Which branch should this project track from?" />
              </Text>
              <Flex width="100%" justifyContent="space-between">
                <Text>
                  Auto Deploy Branches?
                  <Tooltip text="When enabled, all branches will be built and each given their own URL. You can customizable resources used by branches under Branch Resources settings" />
                </Text>
                <Switch
                  size="lg"
                  {...register("autoDeployBranch", {
                    onChange: () => setChanged(true),
                  })}
                />
              </Flex>
              <Flex width="100%" justifyContent="space-between">
                <Text>
                  Auto Stop Branches?
                  <Tooltip text="When enabled, deployed branch will be automatically stopped on github pull request closing or branch deletion." />
                </Text>
                <Switch
                  size="lg"
                  {...register("autoStopBranch", {
                    onChange: () => setChanged(true),
                  })}
                />
              </Flex>
              <Flex
                width="100%"
                justifyContent="space-between"
                alignItems="center"
              >
                <Text whiteSpace="nowrap">
                  Ignore Branch
                  <Tooltip text="Certain branches like `main`, `production`, `staging` can be excluded to prevent conflict when using multiple projects per repository" />
                </Text>
                <Input
                  ml={4}
                  {...register("branchIgnore")}
                  defaultValue={repo.githubIntegration?.branchIgnore || ""}
                  placeholder={"(e.g.) production"}
                />
                <Button
                  ml={4}
                  colorScheme="brand"
                  size="sm"
                  isLoading={loading}
                  type="submit"
                  onClick={() => {
                    track("click_project_settings_branch_save");
                    doSave();
                  }}
                >
                  Save
                </Button>
              </Flex>
              <Flex
                width="100%"
                justifyContent="space-between"
                alignItems="center"
              >
                <Text whiteSpace="nowrap">
                  Protected Branch
                  <Tooltip text="Protected branches won't be automatically stopped on merge event" />
                </Text>
                <Input
                  ml={4}
                  {...register("branchStopIgnore")}
                  defaultValue={repo.githubIntegration?.branchStopIgnore || ""}
                  placeholder={"(e.g.) development"}
                />
                <Button
                  ml={4}
                  colorScheme="brand"
                  size="sm"
                  isLoading={loading}
                  type="submit"
                  onClick={() => {
                    track("click_project_settings_branch_save");
                    doSave();
                  }}
                >
                  Save
                </Button>
              </Flex>
            </Stack>
          </>
        )}
        {shouldDisplayError(error, data) && <ZError error={error} />}
      </Stack>
    </form>
  );
};
