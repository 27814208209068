import { As, Code, Text, Tooltip } from "@chakra-ui/react";
import { FC } from "react";

interface CodeCellProps {
  text: string;
  as?: As;
  tooltip?: string;
}

export const CodeCell: FC<CodeCellProps> = ({ text, as, tooltip }) => {
  const content = (
    <Text overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
      <Code fontSize="xs" as={as}>
        {text}
      </Code>
    </Text>
  );

  return tooltip ? (
    <Tooltip label={tooltip} placement={"top"}>
      {content}
    </Tooltip>
  ) : (
    content
  );
};
