import { CopyIcon } from "@chakra-ui/icons";
import { Flex, FlexProps, Tooltip, useToast } from "@chakra-ui/react";
import copy from "copy-to-clipboard";
import React from "react";
import { IconButton, IconButtonProps } from "../IconButton";

export type CopyStyleProps = FlexProps;

export type CopyVariant = "md" | "sm";
export const DEFAULT_VARIANT = "md";

export interface CopyProps extends CopyStyleProps {
  children?: React.ReactNode;
  copyMessage?: string;
  text: string;
  variant?: CopyVariant;
  iconButtonProps?: Partial<IconButtonProps>;
}

const getVariantWrapperProps = (variant: CopyVariant) =>
  ({
    sm: {
      lineHeight: "24px",
    },
    md: {
      lineHeight: "40px",
    },
  }[variant]);

const getVariantIconButtonProps = (variant: CopyVariant) =>
  ({
    sm: {
      size: "xs",
    },
  }[variant]);

export const Copy: React.FC<CopyProps> = ({
  children,
  copyMessage,
  text,
  variant = "md",
  iconButtonProps,
  ...rest
}) => {
  const toast = useToast();
  const resolvedIconButtonProps = {
    ...iconButtonProps,
    ...getVariantIconButtonProps(variant),
  };
  const wrapperProps = getVariantWrapperProps(variant);

  return (
    <Flex {...wrapperProps} display="inline-flex" maxWidth="100%" {...rest}>
      <Tooltip label="Copy to clipboard">
        <IconButton
          variant="ghost"
          icon={<CopyIcon />}
          aria-label="copy"
          borderRightRadius={children ? "0" : undefined}
          onClick={() => {
            copy(text);
            toast({
              title: copyMessage || "Copied to clipboard",
              status: "success",
              duration: 5000,
              isClosable: true,
            });
          }}
          {...resolvedIconButtonProps}
        />
      </Tooltip>

      {children}
    </Flex>
  );
};
