import { useJobListQuery } from "@zeet/web-api/dist/graphqlv1";
import { CenterLoading, Container, useCurrentTeamUser } from "@zeet/web-ui";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { SearchableListLayout } from "~/components/SearchableList";
import { jobColumnIds, jobColumns } from "./listview";
import { formatJob } from "./utils";

interface JobListProps {
  projectId: string;
}

export const JobList = ({ projectId }: JobListProps) => {
  const { pathname } = useLocation();
  const { id: teamId } = useCurrentTeamUser();
  const { data: jobQuery, loading } = useJobListQuery({
    variables: {
      projectId: projectId,
      teamId,
    },
    pollInterval: 3000,
  });

  const jobs = jobQuery?.team?.project?.jobs;

  const allData = useMemo(
    () => ({
      columns: jobColumns,
      data: jobs?.map((r) => formatJob(pathname, r)) || [],
      skipReset: false,
    }),
    [jobs, pathname]
  );

  if (loading) return <CenterLoading />;

  return (
    <Container fullWidth data-testid="job-list">
      <SearchableListLayout
        id="jobs"
        data={allData}
        columns={jobColumns}
        defaultColumnIds={jobColumnIds}
      />
    </Container>
  );
};
