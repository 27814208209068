import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import {
  useBlueprintNameQuery,
  useCloudNamesQuery,
  useClusterNameQuery,
} from "@zeet/web-api/dist/graphql";
import { useCurrentTeamUser, ZeetLogo } from "@zeet/web-ui";
import React from "react";
import withBreadcrumbs, {
  BreadcrumbsRoute,
} from "react-router-breadcrumbs-hoc";
import { Link, useParams } from "react-router-dom";
import { MobileMenu } from "../MobileMenu";
import { TeamSwitcher } from "../TeamSwitcher";
import { EnvBreadcrumb, ProjectBreadcrumb, RepoBreadcrumb } from "./Items";
import "./LoggedInBreadcrumbNav.scss";

const BlueprintDetailsBreadcrumb = ({ match }) => {
  const currentTeamUser = useCurrentTeamUser();
  const { data, loading } = useBlueprintNameQuery({
    variables: {
      userId: currentTeamUser.id,
      blueprintId: match.params.blueprintId,
    },
  });

  if (loading) return null;
  if (data) return data.user.blueprint?.displayName;
  return "Blueprint Details";
};

const ClusterDetailsBreadcrumb = ({ match }) => {
  const currentTeamUser = useCurrentTeamUser();
  const { data, loading } = useClusterNameQuery({
    variables: {
      teamId: currentTeamUser.id,
      clusterId: match.params.clusterId,
    },
  });

  if (loading) return null;
  if (data) return data.user.cluster?.name;
  return "Cluster Details";
};

const CloudDetailsBreadcrumb = ({ match }) => {
  const currentTeamUser = useCurrentTeamUser();
  const { data, loading } = useCloudNamesQuery({
    variables: {
      teamId: currentTeamUser.id,
    },
  });

  if (loading) return null;

  if (data) {
    const cloudAccount: any = Object.values(data.user)
      .filter((cloud) => cloud instanceof Array)
      .flat()
      .find((cloud: any) => cloud?.id === match.params.cloudId);
    return cloudAccount?.name || "Cloud Details";
  }

  return "Cloud Details";
};

const BreadcrumbLink = ({ to, children }) => {
  const currentTeamUser = useCurrentTeamUser();

  return (
    <Text
      as={Link}
      to={`/${currentTeamUser.login}${to}`}
      noOfLines={1}
      wordBreak="break-all"
    >
      {children}
    </Text>
  );
};

const breadcrumbRoutes = [
  {
    path: "/:teamName/console/home",
    breadcrumb: () => (
      <BreadcrumbLink to="/console/home">Projects</BreadcrumbLink>
    ),
  },
  {
    path: "/:teamName/console/clouds/:cloudId",
    breadcrumb: () => (
      <BreadcrumbLink to="/console/clouds/view">Clouds</BreadcrumbLink>
    ),
  },
  {
    path: "/:teamName/console/clouds/:cloud/:cloudId",
    breadcrumb: CloudDetailsBreadcrumb,
  },
  {
    path: "/:teamName/console/clusters",
    breadcrumb: () => (
      <BreadcrumbLink to="/console/clusters/view">Clusters</BreadcrumbLink>
    ),
  },
  {
    path: "/:teamName/console/clusters/view",
    breadcrumb: null,
  },
  {
    path: "/:teamName/console/clusters/new",
    breadcrumb: null,
  },
  {
    path: "/:teamName/console/clusters/:clusterId",
    breadcrumb: ClusterDetailsBreadcrumb,
  },
  {
    path: "/:teamName/console/blueprints",
    breadcrumb: () => (
      <BreadcrumbLink to="/console/blueprints">Blueprints</BreadcrumbLink>
    ),
  },
  {
    path: "/:teamName/console/blueprints/new",
    breadcrumb: null,
  },
  {
    path: "/:teamName/console/blueprints/:blueprintId",
    breadcrumb: BlueprintDetailsBreadcrumb,
  },
  {
    path: "/:teamName/console/status",
    breadcrumb: () => (
      <BreadcrumbLink to="/console/status">Status</BreadcrumbLink>
    ),
  },
  {
    path: "/:teamName/console/people",
    breadcrumb: () => (
      <BreadcrumbLink to="/console/people/zeet">People</BreadcrumbLink>
    ),
  },
  {
    path: "/:teamName/console/people/zeet/:tab",
    breadcrumb: () => (
      <BreadcrumbLink to="/console/people/zeet">Cloud Link</BreadcrumbLink>
    ),
  },
  {
    path: "/:teamName/account",
    breadcrumb: () => <BreadcrumbLink to="/account">Settings</BreadcrumbLink>,
  },
  {
    path: "/:teamName/console",
    breadcrumb: null,
  },
  {
    path: "/:teamName/console/:tab+",
    breadcrumb: null,
  },
  {
    path: "/:teamName/account",
    breadcrumb: null,
  },
  {
    path: "/:teamName/account/:tab+",
    breadcrumb: null,
  },
  {
    path: "/:teamName/support",
    breadcrumb: "Support",
  },
  {
    path: "/:teamName/support/:tab+",
    breadcrumb: null,
  },
  {
    path: "/:teamName/:projectName/:envName/_:tab?",
    breadcrumb: null,
  },
  {
    path: "/:teamName/:projectName/_:tab?",
    breadcrumb: null,
  },
  {
    path: "/:teamName/:projectName/_:tab/:subTab?",
    breadcrumb: null,
  },
  {
    path: "/new/:route",
    breadcrumb: null,
  },
  {
    path: "/new/:route/:subroute",
    breadcrumb: null,
  },
  {
    path: "/:teamName/:projectName",
    breadcrumb: ProjectBreadcrumb,
  },
  {
    path: "/:teamName/:projectName/:envName",
    breadcrumb: EnvBreadcrumb,
  },
  {
    path: "/:teamName/:projectName/:envName/:repoName",
    breadcrumb: RepoBreadcrumb,
  },
];

export const BreadcrumbSeparator: React.FC = () => {
  return (
    <Box mx="-1.5" opacity={0.7}>
      <svg
        viewBox="0 0 24 24"
        width="32"
        height="32"
        stroke="var(--chakra-colors-chakra-border-color)"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        shapeRendering="geometricPrecision"
        style={{ color: "var(--chakra-colors-chakra-border-color)" }}
      >
        <path d="M16.88 3.549L7.12 20.451"></path>
      </svg>
    </Box>
  );
};

const LoggedInBreadCrumbNav: React.FC<{
  breadcrumbs: Array<BreadcrumbsRoute>;
  showSidebar: boolean;
}> = ({ breadcrumbs, showSidebar }) => {
  const { projectName } = useParams<{ projectName: string }>();
  const isDesktop = useBreakpointValue({ base: false, md: true });

  const getOpacityForBreadcrumb = (index: number) => {
    return index === breadcrumbs.length - 1 ? 1 : 0.5;
  };

  return (
    <Breadcrumb
      display="flex"
      separator={<BreadcrumbSeparator />}
      maxW="65%"
      flexShrink={0}
      flexFlow={"column wrap"}
    >
      {!showSidebar && (
        <BreadcrumbItem flexShrink={0}>
          <Link to="/console/home">
            <ZeetLogo />
          </Link>
        </BreadcrumbItem>
      )}
      {!isDesktop && (
        <BreadcrumbItem flexShrink={0}>
          <MobileMenu />
        </BreadcrumbItem>
      )}
      <BreadcrumbItem flexShrink={0} fontSize="15px">
        <TeamSwitcher />
      </BreadcrumbItem>

      {isDesktop && projectName && (
        <BreadcrumbItem flexShrink={0} fontSize="15px">
          <Box
            userSelect="none"
            opacity={0.5}
            transition={"opacity 0.2s"}
            _hover={{ opacity: 1 }}
          >
            <BreadcrumbLink to="/console/home">Projects</BreadcrumbLink>
          </Box>
        </BreadcrumbItem>
      )}

      {isDesktop &&
        breadcrumbs.map(({ breadcrumb }, i) => (
          <BreadcrumbItem key={i} fontSize="15px">
            <Box
              userSelect="none"
              opacity={getOpacityForBreadcrumb(i)}
              transition={"opacity 0.2s"}
              _hover={{ opacity: 1 }}
            >
              {breadcrumb as React.ReactNode}
            </Box>
          </BreadcrumbItem>
        ))}
    </Breadcrumb>
  );
};

// bad type definitions
// eslint-disable-next-line prettier/prettier, @typescript-eslint/ban-ts-comment
// @ts-ignore
export default withBreadcrumbs(breadcrumbRoutes, { disableDefaults: true })(
  // eslint-disable-next-line prettier/prettier, @typescript-eslint/ban-ts-comment
  // @ts-ignore
  LoggedInBreadCrumbNav
);
