import { Button, Flex, Text, useColorMode } from "@chakra-ui/react";

interface SettingsUnsavedChangesProps {
  isSubmitting: boolean;
  isDisabled?: boolean;
  onSave: (e: any) => void;
}

export const SettingsUnsavedChanges: React.FC<SettingsUnsavedChangesProps> = ({
  isSubmitting,
  isDisabled,
  onSave,
}) => {
  const { colorMode } = useColorMode();

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      color="gray.200"
      background={colorMode === "dark" ? "gray.600" : "gray.700"}
      borderRadius="xl"
      boxShadow="md"
      padding="12px 24px"
    >
      <Text fontSize="16px" fontWeight="bold">
        You have unsaved changes
      </Text>
      <Button
        fontSize="16px"
        colorScheme="brand"
        aria-label="Save"
        size="sm"
        ml={4}
        isLoading={isSubmitting}
        isDisabled={isDisabled}
        onClick={onSave}
        data-testid="floating-save"
      >
        Save
      </Button>
    </Flex>
  );
};
