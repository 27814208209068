import {
  Box,
  Container,
  Divider,
  Flex,
  Heading,
  List,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import {
  Button,
  HorizontalLogo,
  useColors,
  useIsLoggedIn,
  Link as ZLink,
} from "@zeet/web-ui";
import React, { useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";
import { CustomerLogoSection } from "../AdmiralHome/components/CustomerLogo";
import { LineItem } from "../LineItem";
import { LOGIN_NEXT_KEY } from "../Login";

export const SignupPage: React.FC = () => {
  const [next] = useQueryParam("yeet", StringParam);
  const isLoggedIn = useIsLoggedIn();
  const isVertical = useBreakpointValue({ base: true, lg: false });

  const { bg, bg2 } = useColors();

  useEffect(() => {
    if (next) {
      localStorage.setItem(LOGIN_NEXT_KEY, next);
    }
  }, [next]);

  if (isLoggedIn) {
    return <Redirect to={"/?first_login=true"} />;
  }

  return (
    <Flex
      mt={-65}
      minH="100vh"
      flexDirection={isVertical ? "column-reverse" : "row"}
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        flex={isVertical ? "1" : "auto"}
        width={isVertical ? "100%" : "50%"}
        p={{ base: "24px", md: "32px" }}
        bg={bg2}
      >
        <Container mr={["auto", "auto", "calc(500px - 448px + 16px)"]}>
          <Box mx={8} ml={-2} mb={12}>
            <Link to="/">
              <Flex pt={12}>{!isVertical && <HorizontalLogo />}</Flex>
            </Link>
          </Box>
          <Text fontSize="30px" mb={10}>
            Seamless Cloud Deployments for Devs, DevOps, & SRE
          </Text>
          <List
            spacing={10}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            flexGrow={1}
          >
            <LineItem
              title="Free yourself from deploy tasks"
              desc="Use Zeet Blueprints to create applications & infrastructure easily - deployed to your cloud."
            />
            <LineItem
              title="Self-service dashboard"
              desc="Zeet Dashboard gives the whole team ability to view logs, manage resources, rollback deploys, update environment vars, and more."
            />
            <LineItem
              title="Easily go multi-cloud or multi-cluster"
              desc="From RDS on AWS to Google Cloud Serverless to CoreWeave GPUs with Kubernetes, Zeet has your infra needs covered."
            />
          </List>
          <Divider mt={8} />
          <CustomerLogoSection spacing={1} width="140px" />
        </Container>
      </Flex>
      <Flex
        justifyContent="center"
        flex={isVertical ? "1" : "auto"}
        width={isVertical ? "100%" : "50%"}
        p={{ base: "24px", md: "32px" }}
        mt={{ base: 0, lg: -12 }}
        bg={bg}
      >
        <Container ml={["auto", "auto", "calc(500px - 448px + 16px)"]} py={4}>
          <Flex height="100vh" flexDirection="column" justifyContent="center">
            <Flex justifyContent="center" my={8}>
              {isVertical && <HorizontalLogo mx="auto" logoWidth="140px" />}
            </Flex>
            <Heading as={"h1"} size="2xl" textAlign="center">
              <Text as="span" color="brandVar">
                Sign up
              </Text>{" "}
              & deploy 3 services for free.
            </Heading>
            <Stack my={8} spacing={4}>
              <Button asLink to="https://zeet.co/contact" variant="secondary">
                Contact Us
              </Button>
            </Stack>
            <Divider my={4} />
            <Flex justifyContent="center" mt={8}>
              <ZLink
                to={next ? `/login?yeet=${encodeURIComponent(next)}` : "/login"}
                color="brandVar"
                hoverColor="brand.100"
              >
                Already have an account? Log in
              </ZLink>
            </Flex>
          </Flex>
        </Container>
      </Flex>
    </Flex>
  );
};
