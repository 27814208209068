import {
  Skeleton as CSkeleton,
  SkeletonProps,
  useColorModeValue,
} from "@chakra-ui/react";

export const Skeleton = (props: SkeletonProps) => {
  const startColor = useColorModeValue(
    "rgb(0 0 0 / 12%)",
    "rgb(255 255 255 / 20%)"
  );
  const endColor = useColorModeValue(
    "rgb(0 0 0 / 2%)",
    "rgb(255 255 255 / 5%)"
  );

  return (
    <CSkeleton
      startColor={startColor}
      endColor={endColor}
      speed={0.7}
      {...props}
    />
  );
};
