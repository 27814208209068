import { Flex, Input, Stack, Switch, Text } from "@chakra-ui/react";
import {
  ContainerResourcesSpecInput,
  ResourcesFragment,
} from "@zeet/web-api/dist/graphql";
import { Select } from "@zeet/web-ui";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ResourceSizeEnum } from "../../../types/resources";
import { ZFormLabel } from "../Build";

const Sizes = [
  {
    name: ResourceSizeEnum.TINY,
    cpu: null,
    memory: null,
    ephemeralStorage: 0,
  },
  {
    name: ResourceSizeEnum.SMALL,
    cpu: 0.5,
    memory: 1,
    ephemeralStorage: 0,
  },
  {
    name: ResourceSizeEnum.MEDIUM,
    cpu: 1,
    memory: 2,
    ephemeralStorage: 0,
  },
  {
    name: ResourceSizeEnum.LARGE,
    cpu: 2,
    memory: 4,
    ephemeralStorage: 0,
  },
  {
    name: ResourceSizeEnum.CUSTOM,
  },
];

const SizeMap = Sizes.reduce((acc, curr) => ((acc[curr.name] = curr), acc), {});

export const ResourcesForm: React.FC<{
  spec?: ResourcesFragment | null;
  onChange: (out: ContainerResourcesSpecInput) => void;
  showSpot: boolean;
}> = ({ spec, onChange, showSpot }) => {
  const defaultSize = spec?.cpu
    ? Sizes.find(
        (b) =>
          b.cpu === spec?.cpu &&
          b.memory === spec?.memory &&
          b.ephemeralStorage === spec?.ephemeralStorage
      )?.name || ResourceSizeEnum.CUSTOM
    : ResourceSizeEnum.TINY;

  const [size, setSize] = useState<string>(defaultSize);

  const { register, watch, setValue } = useForm<{
    cpu: number;
    memory: number;
    spot: boolean;
    ephemeralStorage: number;
  }>({
    defaultValues: {
      cpu:
        defaultSize === ResourceSizeEnum.CUSTOM
          ? spec?.cpu
          : SizeMap[defaultSize].cpu,
      memory:
        defaultSize === ResourceSizeEnum.CUSTOM
          ? spec?.memory
          : SizeMap[defaultSize].memory,
      spot: defaultSize == ResourceSizeEnum.TINY ? true : spec?.spot || false,
      ephemeralStorage:
        defaultSize === ResourceSizeEnum.CUSTOM
          ? spec?.ephemeralStorage
          : SizeMap[defaultSize].ephemeralStorage,
    },
    shouldUnregister: false,
  });

  const cpu = watch("cpu");
  const memory = watch("memory");
  const spot = watch("spot");
  const ephemeralStorage = watch("ephemeralStorage");

  useEffect(() => {
    let isCustom = false;
    if (
      (cpu && cpu !== SizeMap[size].cpu) ||
      (memory && memory !== SizeMap[size].memory) ||
      (ephemeralStorage && ephemeralStorage !== SizeMap[size].ephemeralStorage)
    ) {
      isCustom = true;
    }

    if (isCustom && size !== ResourceSizeEnum.CUSTOM) {
      setSize(ResourceSizeEnum.CUSTOM);
    }
  }, [setSize, size, cpu, memory, ephemeralStorage]);

  const onChangeSize = (size) => {
    setSize(size);

    // this cannot be useEffect because race condition with size
    if (size !== ResourceSizeEnum.CUSTOM) {
      setValue("cpu", SizeMap[size].cpu);
      setValue("memory", SizeMap[size].memory);
      setValue("ephemeralStorage", SizeMap[size].ephemeralStorage);
    }
    if (size === ResourceSizeEnum.TINY) {
      setValue("spot", true, { shouldDirty: true });
    }
  };

  useEffect(() => {
    if (size == ResourceSizeEnum.TINY) {
      onChange({
        cpu: 0,
        memory: 0,
        ephemeralStorage: 0,
        spot: true,
      });
    } else {
      onChange({
        cpu,
        memory,
        spot,
        ephemeralStorage,
      });
    }
  }, [onChange, size, cpu, memory, spot, ephemeralStorage]);

  return (
    <Stack>
      <Flex alignItems="center">
        <ZFormLabel>Size Preset</ZFormLabel>
        <Select
          ml={"5px"}
          value={size}
          flexGrow={1}
          onChange={(e) => onChangeSize(e?.value)}
          options={Sizes.map(({ name, cpu, memory }) => {
            return {
              value: name,
              label:
                name === ResourceSizeEnum.CUSTOM
                  ? "Custom - Define your own size"
                  : name === ResourceSizeEnum.TINY
                  ? "Tiny - Shared resources"
                  : `${name} - ${cpu} CPU, ${memory}G memory`,
            };
          })}
        />
      </Flex>

      <Stack isInline spacing={[2, 2, 4]} mt={4} mb={2}>
        <Flex flex={1} alignItems="center">
          <Text mr={4} whiteSpace="nowrap">
            CPU Cores
          </Text>
          <Input
            flexGrow={1}
            {...register("cpu", {
              valueAsNumber: true,
            })}
            type="number"
            step="any"
            placeholder={size === ResourceSizeEnum.TINY ? "Shared" : "0"}
            className="nice-digits"
            isDisabled={size === ResourceSizeEnum.TINY}
          />
        </Flex>
        <Flex flex={1} alignItems="center">
          <Text mr={4} whiteSpace="nowrap">
            Memory (GB)
          </Text>
          <Input
            flexGrow={1}
            {...register("memory", {
              valueAsNumber: true,
            })}
            type="number"
            step="any"
            placeholder={size === ResourceSizeEnum.TINY ? "Shared" : "0"}
            className="nice-digits"
            isDisabled={size === ResourceSizeEnum.TINY}
          />
        </Flex>
      </Stack>
      <Stack isInline spacing={[2, 2, 4]} mt={4} mb={2}>
        <Flex flex={1} alignItems="center">
          <Text mr={4} whiteSpace="nowrap">
            Ephemeral Storage (GB)
          </Text>
          <Input
            flexGrow={1}
            {...register("ephemeralStorage", {
              valueAsNumber: true,
            })}
            type="number"
            step="any"
            className="nice-digits"
            isDisabled={size === ResourceSizeEnum.TINY}
          />
        </Flex>
        {showSpot && (
          <Flex flex={1} alignItems="center">
            <Text mr={4} whiteSpace="nowrap">
              Spot Instance
            </Text>
            <Flex flexGrow={1} justifyContent="flex-end">
              <Switch
                name="spot"
                size="lg"
                onChange={(e) => setValue("spot", e.target.checked)}
                isChecked={spot}
                colorScheme="brand"
                isDisabled={size === ResourceSizeEnum.TINY}
              />
            </Flex>
          </Flex>
        )}
      </Stack>
    </Stack>
  );
};
