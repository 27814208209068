import { useIsFormValueSet } from "~/hooks/useIsFormValueSet";
import { ClusterIdInput, StringInput } from "../../../inputs";
import { SettingsSection } from "../../SettingsSection";

export const KubernetesSettings = () => {
  if (!useIsFormValueSet("configuration.kubernetes")) return null;

  return (
    <SettingsSection name="Kubernetes Manifest" basePath="kubernetes.target">
      <ClusterIdInput name="Cluster" path="clusterId" />
      <StringInput
        name="Namespace"
        path="namespace"
        tooltip="The namespace to deploy the manifest to. Can only be modified when the project is archived or being drafted."
        placeholder="(inferred from manifest)"
      />
    </SettingsSection>
  );
};
