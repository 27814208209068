import { faker } from "@faker-js/faker";
import { formatAsSlug } from "@zeet/web-ui";
import genpw from "generate-password-browser";

const randomProjectNouns = [
  "apple",
  "banana",
  "cherry",
  "durian",
  "elderberry",
  "fig",
  "grape",
  "honeydew",
  "iceberg",
  "jackfruit",
  "kiwi",
  "lemon",
  "mango",
  "nectarine",
  "orange",
  "papaya",
  "quince",
  "raspberry",
  "strawberry",
  "tangerine",
  "vanilla",
  "watermelon",
];

export const getDescriptiveProjectName = (prefix: string): string => {
  return formatAsSlug(`${prefix.toLowerCase()}-${generateRandomProjectName()}`);
};

const generateRandomProjectName = () => {
  const noun = randomProjectNouns.sort(() => 0.5 - Math.random()).at(0);
  const adjective = faker.word.adjective(5);

  return `${adjective}-${noun}`;
};

const generateRandomPassword = () => {
  return genpw.generate({
    length: 16,
    numbers: true,
    excludeSimilarCharacters: true,
    strict: true,
  });
};

const generateRandomString = (length) => {
  let result = "";
  const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

export {
  generateRandomProjectName,
  generateRandomPassword,
  generateRandomString,
  randomProjectNouns,
};
