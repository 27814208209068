import { useRepoForProjectEnvironmentQuery } from "@zeet/web-api/dist/graphql";
import { useParams } from "react-router-dom";
import { useEnv, useProject } from "./Provider";
import { projectPath } from "./util";

export const useRepo = () => {
  const { repoName } = useParams<{
    repoName: string;
  }>();

  const project = useProject();
  const env = useEnv();

  const { data, loading, error } = useRepoForProjectEnvironmentQuery({
    variables: {
      path: projectPath(project),
      environment: env.name,
      repo: repoName,
    },
    fetchPolicy: "cache-first",
  });

  return { repo: data?.project?.environment.repo, loading, error };
};
