import {
  Alert,
  AlertIcon,
  Button,
  Flex,
  FormControl,
  Input,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import {
  DatadogIntegration,
  IntegrationType,
  LogShipperType,
  RepoDetailFragment,
  useRemoveProjectLogShipperMutation,
  useUpdateProjectSettingsMutation,
  useUserIntegrationsQuery,
} from "@zeet/web-api/dist/graphql";
import { FormSelect, Tooltip, useTrack } from "@zeet/web-ui";
import React from "react";
import { useForm } from "react-hook-form";
import { shouldDisplayError } from "../util";
import { ZFormLabel } from "./Build";
import { CoolInput } from "./CoolInput";

export const LogShipperSettings: React.FC<{ repo: RepoDetailFragment }> = ({
  repo,
}) => {
  const toast = useToast();
  const { track } = useTrack();

  const { data: integrations, loading: integrationsLoading } =
    useUserIntegrationsQuery({
      variables: {
        id: repo.owner.id,
      },
    });

  const { watch, register } = useForm<{
    logType: string;
    logzToken: string;
    logzUrl: string;
    syslogHost: string;
    syslogPort: string;
    syslogMode: string;
    logDNAKey: string;
    datadogKey: string;
    datadogHost: string;
    betterstackToken: string;
  }>({
    defaultValues: {
      logType: repo.logShipper?.type,
      logzToken: repo.logShipper?.logz?.token,
      logzUrl: repo.logShipper?.logz?.url || "https://listener.logz.io:8071",
      syslogHost: repo.logShipper?.syslog?.host,
      syslogPort: repo.logShipper?.syslog?.port,
      syslogMode: repo.logShipper?.syslog?.mode,
      logDNAKey: repo.logShipper?.logDNA?.key,
      datadogKey: repo.logShipper?.datadog?.key,
      betterstackToken: repo.logShipper?.betterstack?.token,
      datadogHost:
        repo.logShipper?.datadog?.host || "http-intake.logs.datadoghq.com",
    },
  });

  const watcher = watch();

  const [updateSettings, { error, loading, data }] =
    useUpdateProjectSettingsMutation({
      onCompleted: (data) => {
        if (data) {
          toast({
            title: "Log Forwarding Settings Saved",
            status: "success",
            duration: 2500,
            isClosable: true,
          });
        }
      },
      onError: (e) => {
        toast({
          title: e.message,
          status: "error",
          duration: 2500,
          isClosable: true,
        });
      },
    });

  const [removeLogShipper, { error: re, loading: rl }] =
    useRemoveProjectLogShipperMutation({
      onCompleted: (data) => {
        if (data) {
          toast({
            title: "Log Forwarding Disabled",
            status: "success",
            duration: 2500,
            isClosable: true,
          });
        }
      },
    });

  const doSave = () => {
    if (watcher.logType === LogShipperType.Logzio) {
      updateSettings({
        variables: {
          input: {
            id: repo.id,
            logShipper: {
              type: LogShipperType.Logzio,
              logz: {
                token: watcher.logzToken,
                url: watcher.logzUrl,
              },
            },
          },
        },
      });
    } else if (watcher.logType === LogShipperType.Syslog) {
      updateSettings({
        variables: {
          input: {
            id: repo.id,
            logShipper: {
              type: LogShipperType.Syslog,
              syslog: {
                host: watcher.syslogHost,
                port: watcher.syslogPort,
                mode: watcher.syslogMode,
              },
            },
          },
        },
      });
    } else if (watcher.logType === LogShipperType.Logdna) {
      updateSettings({
        variables: {
          input: {
            id: repo.id,
            logShipper: {
              type: LogShipperType.Logdna,
              logDNA: {
                key: watcher.logDNAKey,
              },
            },
          },
        },
      });
    } else if (watcher.logType === LogShipperType.Datadog) {
      updateSettings({
        variables: {
          input: {
            id: repo.id,
            logShipper: {
              type: LogShipperType.Datadog,
              datadog: {
                key: watcher.datadogKey,
                host: watcher.datadogHost,
              },
            },
          },
        },
      });
    } else if (watcher.logType === LogShipperType.Betterstack) {
      updateSettings({
        variables: {
          input: {
            id: repo.id,
            logShipper: {
              type: LogShipperType.Betterstack,
              betterstack: {
                token: watcher.betterstackToken,
              },
            },
          },
        },
      });
    } else {
      removeLogShipper({
        variables: {
          id: repo.id,
        },
      });
    }
  };

  const errorMsg = [
    LogShipperType.Syslog,
    LogShipperType.Logzio,
    LogShipperType.Logdna,
    LogShipperType.Datadog,
    LogShipperType.Betterstack,
  ].includes(watcher.logType as LogShipperType)
    ? error?.message
    : re?.message;

  return (
    <form
      onSubmit={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      style={{ width: "100%" }}
    >
      <Flex flexDirection="column">
        <Text fontWeight="bold" mb={3}>
          Log Forwarding
          <Tooltip text="Send your logs to third-party log management services in real-time!" />
        </Text>
        <FormControl isInvalid={!!errorMsg && shouldDisplayError(error, data)}>
          <Stack spacing={4}>
            <Flex flex={1} alignItems="center">
              <ZFormLabel>Logging Provider</ZFormLabel>
              <FormSelect
                {...register("logType")}
                defaultValue={watcher.logType}
                ml={"5px"}
                flex={5}
                options={[
                  ["Disabled (Logs tab only)", "disabled"],
                  ["Logz.io", LogShipperType.Logzio],
                  ["Syslog", LogShipperType.Syslog],
                  ["LogDNA", LogShipperType.Logdna],
                  ["Datadog", LogShipperType.Datadog],
                  ["Betterstack (LogTail)", LogShipperType.Betterstack],
                ].map(([name, value]) => {
                  return { value: value!, label: name };
                })}
              />
            </Flex>
            {watcher.logType === LogShipperType.Logzio && (
              <Stack spacing={4}>
                <Flex alignItems="center" width="100%">
                  <ZFormLabel>
                    Logz Token
                    <Tooltip text="Your Logz.io token (i.e Dwe94hest39fsa)" />
                  </ZFormLabel>
                  <CoolInput
                    maxWidth="780px"
                    flex={5}
                    fontFamily={"mono"}
                    {...register("logzToken")}
                    defaultValue={watcher.logzToken}
                  />
                </Flex>
                <Flex alignItems="center" width="100%">
                  <ZFormLabel>Logz Url</ZFormLabel>
                  <Input
                    maxWidth="780px"
                    flex={5}
                    fontFamily={"mono"}
                    {...register("logzUrl")}
                    defaultValue={
                      watcher.logzUrl || "https://listener.logz.io:8071"
                    }
                    isDisabled={true}
                  />
                </Flex>
              </Stack>
            )}
            {watcher.logType === LogShipperType.Syslog && (
              <Stack spacing={4}>
                <Flex alignItems="center" width="100%">
                  <ZFormLabel>Syslog Host</ZFormLabel>
                  <Input
                    maxWidth="780px"
                    flex={5}
                    fontFamily={"mono"}
                    {...register("syslogHost")}
                    defaultValue={watcher.syslogHost}
                    placeholder={"syslog.com"}
                  />
                </Flex>
                <Flex alignItems="center" width="100%">
                  <ZFormLabel>Syslog Port</ZFormLabel>
                  <Input
                    maxWidth="780px"
                    flex={5}
                    fontFamily={"mono"}
                    {...register("syslogPort")}
                    type="number"
                    defaultValue={watcher.syslogPort || "5114"}
                  />
                </Flex>
                <Flex alignItems="center" width="100%">
                  <ZFormLabel>Syslog Protocol</ZFormLabel>
                  <FormSelect
                    maxWidth="780px"
                    flex={5}
                    fontFamily={"mono"}
                    {...register("syslogMode")}
                    defaultValue={watcher.syslogMode || "udp"}
                    options={[
                      { value: "tcp", label: "TCP" },
                      { value: "udp", label: "UDP" },
                    ]}
                  />
                </Flex>
              </Stack>
            )}
            {watcher.logType === LogShipperType.Logdna && (
              <Stack spacing={4}>
                <Flex alignItems="center" width="100%">
                  <ZFormLabel>
                    LogDNA Ingestion Key
                    <Tooltip text="Your LogDNA ingestion key (i.e pr343r4n94918op1op660272q3r60620)" />
                  </ZFormLabel>
                  <CoolInput
                    maxWidth="780px"
                    flex={5}
                    fontFamily={"mono"}
                    {...register("logDNAKey")}
                    defaultValue={watcher.logDNAKey}
                  />
                </Flex>
              </Stack>
            )}
            {watcher.logType === LogShipperType.Datadog && (
              <Stack spacing={4}>
                <Flex alignItems="center" width="100%">
                  <ZFormLabel>
                    Datadog API Key
                    <Tooltip text="Your Datadog API key (i.e d2c1fd566a66faaeaafb452fdfa7a0a0)" />
                  </ZFormLabel>
                  {!integrationsLoading && (
                    <CoolInput
                      maxWidth="780px"
                      flex={5}
                      fontFamily={"mono"}
                      {...register("datadogKey")}
                      defaultValue={
                        watcher.datadogKey ||
                        (
                          integrations?.user?.integrations?.filter(
                            (i) => i.type == IntegrationType.Datadog
                          )?.[0] as DatadogIntegration
                        )?.apiKey ||
                        ""
                      }
                    />
                  )}
                </Flex>
              </Stack>
            )}
            {watcher.logType === LogShipperType.Datadog && (
              <Stack spacing={4}>
                <Flex alignItems="center" width="100%">
                  <ZFormLabel>
                    Ingest Region
                    <Tooltip text="Specify the site you want to store your data. Each site is completely independent, and you cannot share data across sites." />
                  </ZFormLabel>
                  <FormSelect
                    {...register("datadogHost")}
                    defaultValue={watcher.datadogHost}
                    ml={"5px"}
                    flex={5}
                    options={[
                      [
                        "US: http-intake.logs.datadoghq.com",
                        "http-intake.logs.datadoghq.com",
                      ],
                      [
                        "US3: http-intake.logs.us3.datadoghq.com",
                        "http-intake.logs.us3.datadoghq.com",
                      ],
                      [
                        "US5: http-intake.logs.us5.datadoghq.com",
                        "http-intake.logs.us5.datadoghq.com",
                      ],
                      [
                        "EU: http-intake.logs.datadoghq.eu",
                        "http-intake.logs.datadoghq.eu",
                      ],
                      [
                        "US1-FED: http-intake.logs.ddog-gov.com",
                        "http-intake.logs.ddog-gov.com",
                      ],
                    ].map(([name, value]) => {
                      return { value: value!, label: name };
                    })}
                  />
                </Flex>
              </Stack>
            )}
            {watcher.logType === LogShipperType.Betterstack && (
              <Stack spacing={4}>
                <Flex alignItems="center" width="100%">
                  <ZFormLabel>
                    Betterstack Token
                    <Tooltip text="Your Better Stack (LogTail) Source Token" />
                  </ZFormLabel>
                  <CoolInput
                    maxWidth="780px"
                    flex={5}
                    fontFamily={"mono"}
                    {...register("betterstackToken")}
                    defaultValue={watcher.betterstackToken}
                  />
                </Flex>
              </Stack>
            )}
            {shouldDisplayError(error, data) && (
              <Alert status="error">
                <AlertIcon />
                {errorMsg}
              </Alert>
            )}
            <Button
              ml={"auto"}
              colorScheme="brand"
              isLoading={loading || rl}
              type="submit"
              onClick={() => {
                track("click_project_settings_logging_save");
                doSave();
              }}
            >
              Save
            </Button>
          </Stack>
        </FormControl>
      </Flex>
    </form>
  );
};
