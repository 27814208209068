import {
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import {
  ProjectDetailFragment,
  useGroupsWithSubgroupsQuery,
  useUpdateProjectMutation,
} from "@zeet/web-api/dist/graphqlv1";
import { Button, Select, useColors, useCurrentTeamUser } from "@zeet/web-ui";
import { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

interface UpdateGroupSubgroupModalProps {
  isOpen: boolean;
  onClose: () => void;
  project: ProjectDetailFragment;
}

type GroupOption = {
  label: string;
  value: string;
};

export const UpdateGroupSubgroupModal = ({
  isOpen,
  onClose,
  project,
}: UpdateGroupSubgroupModalProps) => {
  const currentTeam = useCurrentTeamUser();
  const { bg } = useColors();
  const history = useHistory();
  const { data, loading } = useGroupsWithSubgroupsQuery({
    variables: { teamId: currentTeam.id },
  });
  const [selectedGroup, setSelectedGroup] = useState<GroupOption | null>({
    label: project.group?.name ?? "",
    value: project.group?.id ?? "",
  });
  const [selectedSubgroup, setSelectedSubgroup] = useState<GroupOption | null>({
    label: project.subGroup?.name ?? "",
    value: project.subGroup?.id ?? "",
  });
  const [updateProject, { loading: updateLoading }] = useUpdateProjectMutation({
    variables: {
      id: project.id,
      input: {
        groupId: selectedGroup?.value,
        subGroupId: selectedSubgroup?.value,
      },
    },
    onCompleted: (data) => {
      onClose();
      if (data) {
        history.replace(
          `/${currentTeam.login}/${selectedGroup?.label}/${selectedSubgroup?.label}/${project.name}/settings`
        );
      }
    },
  });

  const groups = useMemo(() => {
    return data?.team?.groups.nodes.map((group) => ({
      label: group.name,
      value: group.id,
    }));
  }, [data]);

  const subgroups = useMemo(() => {
    return data?.team?.groups.nodes
      .find((group) => group.id === selectedGroup?.value)
      ?.subGroups.map((subgroup) => ({
        label: subgroup.name,
        value: subgroup.id,
      }));
  }, [data, selectedGroup]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered preserveScrollBarGap>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Move Project</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text opacity="0.7">
            Move this Project to a different Group or Sub-Group.
          </Text>
          <FormControl isRequired mt="6">
            <FormLabel>Group</FormLabel>
            <Select
              bg={bg}
              placeholder="example"
              options={groups}
              value={selectedGroup?.value}
              onChange={(e) => {
                setSelectedGroup(e);
                setSelectedSubgroup(null);
              }}
              isLoading={loading}
            />
          </FormControl>
          <FormControl isRequired mt="4">
            <FormLabel>Sub-Group</FormLabel>
            <Select
              bg={bg}
              placeholder="sandbox"
              options={subgroups}
              value={selectedSubgroup?.value}
              onChange={(e) => setSelectedSubgroup(e)}
              isLoading={loading}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button variant="secondary" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            isDisabled={!selectedGroup || !selectedSubgroup}
            onClick={() => updateProject()}
            isLoading={updateLoading}
          >
            Move Project
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
