import { Flex } from "@chakra-ui/react";
import { FC } from "react";

interface BooleanCellProps {
  bool: boolean;
}

export const BooleanCell: FC<BooleanCellProps> = ({ bool }) => {
  return <Flex alignItems="center">{bool ? "True" : "False"}</Flex>;
};
