import { useQueryParams } from "@zeet/web-ui";
import { useState } from "react";
import { useHistory } from "react-router-dom";

export const useQueryState = (
  parameterName: string
): [string, (val: string) => void] => {
  const params = useQueryParams();
  const history = useHistory();
  const queryValue = params.get(parameterName);
  const [value, setValue] = useState(queryValue ?? "");

  const set = (newVal: string) => {
    setValue(newVal);
    if (!newVal) {
      params.delete(parameterName);
    } else {
      params.set(parameterName, newVal);
    }

    history.replace({ search: "?" + params.toString() });
  };

  return [value, set];
};
