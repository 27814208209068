import { CopyIcon } from "@chakra-ui/icons";
import {
  Tooltip as CTooltip,
  Flex,
  Text,
  TextProps,
  useToast,
} from "@chakra-ui/react";
import copy from "copy-to-clipboard";
import React from "react";

export const CopyableText: React.FC<
  TextProps & {
    toCopy: string | null | undefined;
    showIcon?: boolean;
    showToast?: string;
  }
> = ({ toCopy, showIcon, showToast, children, flex, ...props }) => {
  const toast = useToast();
  showToast = showToast || "Copied to clipboard";

  const inner = () => {
    return (
      <Flex
        alignItems="center"
        cursor={toCopy ? "pointer" : undefined}
        onClick={() => {
          if (toCopy) {
            copy(toCopy);
            showToast &&
              toast({
                title: showToast,
                status: "success",
                duration: 3000,
                isClosable: true,
              });
          }
        }}
        flex={flex}
      >
        <Text {...props}>{children}</Text>
        {showIcon && <CopyIcon ml={1} />}
      </Flex>
    );
  };

  return toCopy ? (
    <CTooltip
      label={`Click to Copy: ${toCopy}`}
      aria-label={toCopy || ""}
      placement="top-start"
    >
      {inner()}
    </CTooltip>
  ) : (
    inner()
  );
};
