import { Text } from "@chakra-ui/react";
import { FC } from "react";
import ReactTimeago, { ReactTimeagoProps } from "react-timeago";

interface TimeAgoCellProps {
  date: ReactTimeagoProps["date"];
}

export const TimeAgoCell: FC<TimeAgoCellProps> = ({ date }) => (
  <Text pl={"6px"}>
    <ReactTimeago date={date || 0} />
  </Text>
);
