import { DataTypes } from "@zeet/web-ui";

export enum JobColumnId {
  Name = "jobName",
  Count = "jobRunCount",
}

export const jobColumnIds = Object.values(JobColumnId);
export const jobColumns = [
  {
    id: JobColumnId.Name,
    dataType: DataTypes.LINK,
    minWidth: 250,
    order: 1,
    label: "Name",
    options: [],
  },
  {
    id: JobColumnId.Count,
    dataType: DataTypes.TEXT,
    minWidth: 200,
    order: 2,
    label: "Runs",
    options: [],
  },
];
