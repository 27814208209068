import { Heading, Icon } from "@chakra-ui/react";
import {
  useWorkflowRunsQuery,
  WorkflowRunStepStatus,
} from "@zeet/web-api/dist/graphqlv1";
import {
  Button,
  CenterLoading,
  Container,
  EmptyBox,
  useCurrentTeamUser,
} from "@zeet/web-ui";
import { BsRocketTakeoffFill } from "react-icons/bs";
import { useLocation } from "react-router-dom";
import { ProjectDetailTabProps } from "..";
import { useWorkflowOperations } from "../WorkflowsTab/useWorkflowOperations";
import {
  isApprovalStepRunning,
  WorkflowApprovalBanner,
} from "../WorkflowsTab/WorkflowApprovalBanner";
import { WorkflowRunDetail } from "../WorkflowsTab/WorkflowRunDetail";

const OverviewTab = ({ project }: ProjectDetailTabProps) => {
  const { submitRun } = useWorkflowOperations();
  const currentTeamUser = useCurrentTeamUser();
  const { data, loading } = useWorkflowRunsQuery({
    variables: {
      projectId: project.id,
      teamId: currentTeamUser.id,
      page: { after: "0", first: 20 },
    },
    pollInterval: 5000,
  });

  const { pathname } = useLocation();
  const workflowRuns = data?.team?.project?.workflow?.runs?.nodes;
  const latestWorkflowRun = workflowRuns?.[0];

  if (loading) {
    return (
      <Container fullWidth>
        <CenterLoading />
      </Container>
    );
  }

  if (!workflowRuns?.length) {
    return (
      <Container fullWidth>
        <Heading size="md" mt="8">
          Latest workflow run
        </Heading>
        <EmptyBox flexDir="column" gap="4" mt="4">
          <Heading size="sm">No workflow runs for this project yet.</Heading>
          <Button
            variant="primary"
            size="sm"
            onClick={() => {
              if (!project.workflow?.id) return;
              submitRun(project.id, project.workflow?.id);
            }}
            isLoading={loading}
            leftIcon={<Icon as={BsRocketTakeoffFill} />}
          >
            Trigger workflow
          </Button>
        </EmptyBox>
      </Container>
    );
  }

  return (
    <>
      {isApprovalStepRunning(workflowRuns) && (
        <WorkflowApprovalBanner
          data={data}
          projectID={project.id}
          borderRadius="none"
          planLink={`${pathname}?workflowId=${
            latestWorkflowRun?.id
          }&showPlanLogs=true&stepId=${latestWorkflowRun?.steps?.findIndex(
            (s) => s?.status === WorkflowRunStepStatus.Running
          )}`}
        />
      )}
      <Container fullWidth>
        <Heading size="md" mt="8">
          Latest workflow run
        </Heading>
      </Container>
      <WorkflowRunDetail
        isOverview
        runId={workflowRuns?.[0]?.id ?? ""}
        projectId={project.id}
      />
    </>
  );
};

export default OverviewTab;
