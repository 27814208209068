import { BlueprintQuery } from "@zeet/web-api/dist/graphql";
import { Column } from "react-table";
import { BridgeBlueprint } from "../types/blueprints";
import {
  ColumnAdapter,
  DataTypes,
  IDataRow,
  IZeetData,
} from "../types/listview";

type ColumnsType = (Column<IDataRow> & ColumnAdapter)[];

enum ColumnIds {
  BLUEPRINT_INPUT_NAME = "blueprintInputName",
  BLUEPRINT_INPUT_TYPE = "blueprintInputType",
  BLUEPRINT_INPUT_REQUIRED = "blueprintInputRequired",
  BLUEPRINT_INPUT_ID = "blueprintInputId",
}

const columns: ColumnsType = [
  {
    id: ColumnIds.BLUEPRINT_INPUT_NAME,
    label: "Name",
    minWidth: 300,
    dataType: DataTypes.TEXT,
    options: [],
    order: 1,
  },
  {
    id: ColumnIds.BLUEPRINT_INPUT_TYPE,
    label: "Type",
    minWidth: 100,
    dataType: DataTypes.CODE,
    options: [],
    order: 2,
  },
  {
    id: ColumnIds.BLUEPRINT_INPUT_REQUIRED,
    label: "Required",
    minWidth: 100,
    dataType: DataTypes.BOOLEAN,
    options: [],
    order: 3,
  },
  {
    id: ColumnIds.BLUEPRINT_INPUT_ID,
    label: "ID",
    minWidth: 350,
    dataType: DataTypes.COPY_CODE,
    options: [],
    order: 2,
  },
];

const defaultColumnIds = Object.values(ColumnIds) as ColumnIds[];

function makeBlueprintDetailInputData(
  data: BlueprintQuery | undefined
): IZeetData {
  const inputs = data?.user?.blueprint?.variables ?? [];

  const formattedData: IDataRow[] = inputs.map((input) => ({
    [ColumnIds.BLUEPRINT_INPUT_NAME]: {
      text: input.name,
    },
    [ColumnIds.BLUEPRINT_INPUT_TYPE]: {
      text: input.type,
    },
    [ColumnIds.BLUEPRINT_INPUT_REQUIRED]: {
      boolean: input.required,
    },
    [ColumnIds.BLUEPRINT_INPUT_ID]: {
      text: input.id,
    },
  }));

  return {
    columns,
    data: formattedData,
    skipReset: false,
  };
}

enum MarketplaceColumnIds {
  BLUEPRINT_INPUT_NAME = "blueprintInputName",
  BLUEPRINT_INPUT_TYPE = "blueprintInputType",
  BLUEPRINT_INPUT_REQUIRED = "blueprintInputRequired",
}

const marketplaceColumnIds = Object.values(
  MarketplaceColumnIds
) as MarketplaceColumnIds[];

const marketplaceColumns: ColumnsType = [
  {
    id: ColumnIds.BLUEPRINT_INPUT_NAME,
    label: "Name",
    minWidth: 300,
    dataType: DataTypes.TEXT,
    options: [],
    order: 1,
  },
  {
    id: ColumnIds.BLUEPRINT_INPUT_TYPE,
    label: "Type",
    minWidth: 200,
    dataType: DataTypes.CODE,
    options: [],
    order: 2,
  },
  {
    id: ColumnIds.BLUEPRINT_INPUT_REQUIRED,
    label: "Required",
    minWidth: 100,
    dataType: DataTypes.BOOLEAN,
    options: [],
    order: 3,
  },
];

function makeBlueprintMarketplaceInputData(
  blueprint: BridgeBlueprint
): IZeetData {
  const inputs = blueprint.variables ?? [];

  const formattedData: IDataRow[] = inputs.map((input) => ({
    [MarketplaceColumnIds.BLUEPRINT_INPUT_NAME]: {
      text: input.name,
    },
    [MarketplaceColumnIds.BLUEPRINT_INPUT_TYPE]: {
      text: input.type,
    },
    [MarketplaceColumnIds.BLUEPRINT_INPUT_REQUIRED]: {
      boolean: input.required,
    },
  }));

  return {
    columns: marketplaceColumns,
    data: formattedData,
    skipReset: false,
  };
}

export {
  columns,
  marketplaceColumns,
  defaultColumnIds,
  marketplaceColumnIds,
  makeBlueprintDetailInputData,
  makeBlueprintMarketplaceInputData,
};
