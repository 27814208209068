"use client";

import {
  TeamDetailFragment,
  UserDetailFragment,
  useTeamByNameQuery,
  useUserTeamQuery,
} from "@zeet/web-api/dist/graphql";
import { isAfter, subDays } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { config } from "../../config";
import { useLocalStorageOnce } from "../../hooks/useLocalStorage";
import { useCurrentUser, useIsLoggedIn } from "../Auth";
import { CenterLoading } from "../Loading";

const teamIdKey = "zeet_team";

export interface TeamContextType {
  currentTeam: TeamDetailFragment;
  teamId?: string;
  setTeamId: (teamId: string) => void;
}

export const teamContext = React.createContext<TeamContextType>({
  setTeamId: () => {},
  currentTeam: {} as TeamDetailFragment,
});

//currentTeam
export const CurrentTeamProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const loggedIn = useIsLoggedIn();
  const curUser = useCurrentUser();
  const [teamId, setTeamId] = useLocalStorageOnce(teamIdKey);
  const [currentTeam, setCurrentTeam] = useState<
    TeamDetailFragment | undefined
  >();

  const location = useLocation();
  const teamName = location.pathname.split("/")?.[1] || "";
  const isReserved = [
    "",
    "account",
    "admin",
    "api",
    "billing",
    "careers",
    "close",
    "cloud-onboard",
    "dashboard",
    "error",
    "help",
    "login",
    "new",
    "new-team",
    "privacy",
    "profile",
    "project",
    "r",
    "repo",
    "saas-pricing",
    "signup",
    "team",
    "tos",
    "popout",
    "serverless",
    curUser?.login,
  ].includes(teamName);

  const { data: teamData, loading: nameLoading } = useTeamByNameQuery({
    skip: !loggedIn || isReserved,
    variables: { name: teamName },
  });

  useEffect(() => {
    if (isReserved) {
      return;
    }

    const routeTeamId = teamData?.team?.id;
    if (routeTeamId) {
      setTeamId(routeTeamId);
    }
  }, [isReserved, teamData, teamName, curUser, setTeamId]);

  const { data, loading, error } = useUserTeamQuery({
    skip: !loggedIn || !teamId,
    variables: {
      id: teamId,
    },
  });

  const isLoading =
    loading ||
    nameLoading ||
    (teamData?.team?.id && currentTeam?.id != teamData?.team?.id);

  useEffect(() => {
    if (!curUser) {
      setCurrentTeam(undefined);
      return;
    }

    if (!teamId) {
      setCurrentTeam(undefined);
    } else if (data && data.currentUser.team) {
      setCurrentTeam(data.currentUser.team);
    } else if (!loading) {
      // error state
      setTeamId("");
      setCurrentTeam(undefined);
    }
  }, [isReserved, teamId, data, curUser, loading, setTeamId, setCurrentTeam]);

  if (error) {
    console.error(`failed to get team for id: ${teamId}`, error);
  }

  if (isLoading || (curUser && !currentTeam && teamId && !isReserved)) {
    return <CenterLoading data-testid="team-loading-spinner" />;
  }

  return (
    <teamContext.Provider
      value={{
        currentTeam: currentTeam || ({} as TeamDetailFragment),
        teamId,
        setTeamId,
      }}
    >
      {children}
    </teamContext.Provider>
  );
};

export function useSetTeamId(): (teamId: string) => void {
  const { setTeamId } = useContext(teamContext);
  return setTeamId;
}

export function useCurrentTeam(): TeamDetailFragment {
  const { currentTeam } = useContext(teamContext);
  return currentTeam;
}

export const isRecentlyCreatedUser = (user?: UserDetailFragment): boolean => {
  return (
    (user?.createdAt &&
      isAfter(new Date(user?.createdAt), subDays(new Date(), 7))) ??
    false
  );
};

// this should only be called inside authenticated components
export function useCurrentTeamUser(): UserDetailFragment {
  const curUser = useCurrentUser();
  const curTeam = useCurrentTeam();
  const curTeamUser = curTeam?.user || curUser;

  if (!curTeamUser && config.isDev) {
    console.error("invalid state");
  }
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return curTeamUser!;
}

export function useMaybeTeamUser(): UserDetailFragment | undefined {
  const curUser = useCurrentUser();
  const curTeam = useCurrentTeam();
  const curTeamUser = curTeam?.user || curUser;

  return curTeamUser;
}
