import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  Flex,
  Stack,
  Text,
} from "@chakra-ui/react";
import {
  CenterLoading,
  Container,
  Link,
  PageContainer,
  useCurrentTeamUser,
  useCurrentUser,
  useIsLoggedIn,
} from "@zeet/web-ui";
import { isFuture } from "date-fns";
import React, { Suspense, useMemo } from "react";
import { useCrispChat } from "~/hooks/useCrispChat";
import { NAVBAR_HEIGHT } from "./AdmiralHome";
import { Routes } from "./Routes";
import { TrialCountdown } from "./TrialCountdown";

const AppBanner: React.FC = () => {
  const currentTeamUser = useCurrentTeamUser();
  const currentUser = useCurrentUser();

  const paymentError = currentTeamUser?.paymentError;
  const authError = currentTeamUser?.paymentAuthorizationError;

  const isInTrialPeriod = useMemo(() => {
    if (
      !currentUser ||
      !currentUser.freeTrialEndsAt ||
      !currentUser.hasZeetCloud
    ) {
      return false;
    }
    return isFuture(new Date(currentUser.freeTrialEndsAt));
  }, [currentUser]);

  if (currentTeamUser && paymentError) {
    const billingSearch = `?user=${currentTeamUser.id}`;
    return (
      <Alert status="error">
        <Container>
          <Flex align="center">
            <AlertIcon />
            <Flex flexDirection="column">
              <AlertTitle>
                Your account is suspended - {paymentError}
              </AlertTitle>
              <Stack ml={0.5} spacing={0}>
                <Text fontSize="sm">
                  Please{" "}
                  <Text
                    as={Link}
                    to={{
                      pathname: "/billing",
                      search: billingSearch + "&payment-methods=true",
                    }}
                    fontWeight={600}
                  >
                    update your payment details
                  </Text>{" "}
                  and{" "}
                  <Text
                    as={Link}
                    to={{
                      pathname: "/billing",
                      search: billingSearch,
                    }}
                    fontWeight={600}
                  >
                    pay any unpaid invoices
                  </Text>{" "}
                  to resolve the issue.
                </Text>
                <Text fontSize="xs">
                  If you have questions, you can{" "}
                  <Text
                    as={Link}
                    fontWeight={600}
                    onClick={() => {
                      window.$crisp.push(["do", "chat:open"]);
                    }}
                    to="#"
                  >
                    reach out to support
                  </Text>
                  .
                </Text>
              </Stack>
            </Flex>
          </Flex>
        </Container>
      </Alert>
    );
  }

  if (currentTeamUser && authError) {
    return (
      <Alert status="warning">
        <Container>
          <Flex align="center">
            <AlertIcon />
            <Flex flexDirection="column">
              <AlertTitle>The payment method you entered is invalid</AlertTitle>
              <Stack ml={0.5} spacing={0}>
                <Text fontSize="sm">
                  Please{" "}
                  <Text
                    as={Link}
                    to={{
                      pathname: "/billing",
                      search: "?payment-methods=true",
                    }}
                    fontWeight={600}
                  >
                    update your payment details
                  </Text>{" "}
                  to resolve the issue.
                </Text>
                <Text fontSize="xs">
                  If you have questions, you can{" "}
                  <Text
                    as={Link}
                    fontWeight={600}
                    onClick={() => {
                      window.$crisp.push(["do", "chat:open"]);
                    }}
                    to="#"
                  >
                    reach out to support
                  </Text>
                  .
                </Text>
              </Stack>
            </Flex>
          </Flex>
        </Container>
      </Alert>
    );
  }

  if (
    isInTrialPeriod &&
    !currentUser?.hasPaymentMethod &&
    currentUser?.id === currentTeamUser?.id &&
    currentUser?.freeTrialEndsAt
  ) {
    return (
      <Alert status="warning">
        <Container>
          <Flex align="center">
            <AlertIcon />
            <Flex
              justify="space-between"
              align="center"
              w="100%"
              flexDir={["column", "column", "row"]}
            >
              <Stack spacing={1}>
                <AlertTitle>
                  Your trial period ends in <TrialCountdown />
                </AlertTitle>

                <Text fontSize="sm">
                  Your Projects will be disabled at the end of the trial period
                  unless you add a payment method.
                </Text>
              </Stack>
              <Button
                as={Link}
                style={{ textDecoration: "none" }}
                to={{ pathname: "/billing", search: "?payment-methods=true" }}
                my={2}
                flexShrink={0}
                colorScheme="orange"
                size="sm"
              >
                Add payment method
              </Button>
            </Flex>
          </Flex>
        </Container>
      </Alert>
    );
  }

  return null;
};

const Content: React.FC = () => {
  const isLoggedIn = useIsLoggedIn();
  useCrispChat();

  return (
    <Suspense fallback={<CenterLoading />}>
      <PageContainer>
        <Flex
          flexGrow={1}
          height="full"
          width="100%"
          flexDirection="column"
          as="main"
          minH={`calc(100vh - ${NAVBAR_HEIGHT})`}
          position="relative"
        >
          {isLoggedIn && <AppBanner />}
          <Routes />
        </Flex>
      </PageContainer>
    </Suspense>
  );
};

export default Content;
