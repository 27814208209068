import { Box, Heading } from "@chakra-ui/react";
import { useJobRunListQuery } from "@zeet/web-api/dist/graphqlv1";
import { CenterLoading, useCurrentTeamUser, usePagination } from "@zeet/web-ui";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { SearchableListLayout } from "~/components/SearchableList";
import { BackNavLayout } from "../../components/BackNavLayout";
import { jobRunColumnIds, jobRunColumns } from "./listview";
import { formatJobRun } from "./utils";

interface JobRunListProps {
  jobId: string;
}

export const JobRunList = ({ jobId }: JobRunListProps) => {
  const { pathname } = useLocation();
  const { id: teamId } = useCurrentTeamUser();
  const { setPage, pageInput } = usePagination();
  const { data: jobQuery, loading } = useJobRunListQuery({
    variables: {
      id: jobId,
      teamId,
      page: pageInput,
    },
    pollInterval: 3000,
  });

  const job = jobQuery?.team?.job;

  const allData = useMemo(
    () => ({
      columns: jobRunColumns,
      data: job?.runs.nodes.map((r) => formatJobRun(pathname, r)) || [],
      skipReset: false,
    }),
    [job, pathname]
  );

  if (loading) return <CenterLoading />;

  return (
    <BackNavLayout linkText="Back to Jobs" linkTo={pathname}>
      <Box mx={4}>
        <Heading fontSize="lg" mt={1} data-testid="job-run-list-title">
          {job?.definition.jobName} Runs
        </Heading>
        <SearchableListLayout
          id="job-runs"
          data={allData}
          columns={jobRunColumns}
          defaultColumnIds={jobRunColumnIds}
          pageChanged={setPage}
          hasNextPage={!loading && job?.runs.pageInfo.hasNextPage}
        />
      </Box>
    </BackNavLayout>
  );
};
