import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Flex, PropsOf } from "@chakra-ui/react";
import React from "react";
import { Link } from "./Link";

export const Help: React.FC<
  PropsOf<typeof Flex> & {
    text: string;
    link: string;
    isExternal: boolean;
  }
> = ({ text, link, isExternal, ...restProps }) => {
  return (
    <Flex {...restProps}>
      <Link to={link} isExternal={isExternal}>
        {text}
        <ExternalLinkIcon ml={1} fontSize="sm" opacity=".8" />
      </Link>
    </Flex>
  );
};
