import {
  CloudProvider,
  useUpdateAwsAccountMutation,
  useUpdateAzureAccountMutation,
  useUpdateCoreWeaveAccountMutation,
  useUpdateDoAccountMutation,
  useUpdateGcpAccountMutation,
  useUpdateLinodeAccountMutation,
  useUpdateVultrAccountMutation,
} from "@zeet/web-api/dist/graphql";

export const mutations = {
  [CloudProvider.Aws]: ({ onError, onCompleted }) =>
    useUpdateAwsAccountMutation({
      onError,
      onCompleted,
    }),
  [CloudProvider.Gcp]: ({ onError, onCompleted }) =>
    useUpdateGcpAccountMutation({
      onError,
      onCompleted,
    }),
  [CloudProvider.Do]: ({ onError, onCompleted }) =>
    useUpdateDoAccountMutation({
      onError,
      onCompleted,
    }),
  [CloudProvider.Coreweave]: ({ onError, onCompleted }) =>
    useUpdateCoreWeaveAccountMutation({
      onError,
      onCompleted,
    }),
  [CloudProvider.Linode]: ({ onError, onCompleted }) =>
    useUpdateLinodeAccountMutation({
      onError,
      onCompleted,
    }),
  [CloudProvider.Vultr]: ({ onError, onCompleted }) =>
    useUpdateVultrAccountMutation({
      onError,
      onCompleted,
    }),
  [CloudProvider.Azure]: ({ onError, onCompleted }) =>
    useUpdateAzureAccountMutation({
      onError,
      onCompleted,
    }),
};
