import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  Stack,
  Text,
  useBoolean,
} from "@chakra-ui/react";
import {
  DeploymentAlertDetailFragment,
  useDeploymentAlertQuery,
} from "@zeet/web-api/dist/graphql";
import {
  Card,
  CopyCode,
  FormLabel,
  Tooltip,
  useColors,
  ZError,
  ZWarn,
} from "@zeet/web-ui";
import React from "react";
import { LogsConsole } from "~/components/LogsConsole/LogsConsole";

const podLogsTooltipText =
  "These logs represent the final output of the process before it crashed, and may be useful for identifying the root cause of the crash.";

const PodCrashAlert: React.FC<{
  alert: DeploymentAlertDetailFragment;
}> = ({ alert }) => {
  return (
    <Stack gap="6">
      <HStack gap="6">
        <Card p="3" flex="1">
          <FormLabel>Alert firing since</FormLabel>
          <Text mt="-1">{new Date(alert.createdAt).toLocaleString()}</Text>
        </Card>
        <Card p="3" flex="1">
          <FormLabel>Alert resolved at</FormLabel>
          <Text mt="-1">
            {alert.resolvedAt
              ? new Date(alert.resolvedAt).toLocaleString()
              : "Ongoing"}
          </Text>
        </Card>
      </HStack>

      <Stack gap="0">
        <FormLabel>Pod Namespace</FormLabel>
        <CopyCode value={alert.namespace ?? ""} />
      </Stack>

      <Stack gap="0">
        <FormLabel>Pod Name</FormLabel>
        <CopyCode value={alert.podName ?? ""} />
      </Stack>

      <Stack>
        <Flex direction={"row"} alignItems={"center"}>
          <Text fontWeight={"bold"}>Pod Logs</Text>
          <Tooltip text={podLogsTooltipText} />
        </Flex>
        {alert.previousPodLogs?.entries ? (
          <LogsConsole
            withBorder
            height="50vh"
            lines={alert.previousPodLogs?.entries ?? []}
          />
        ) : (
          <ZWarn
            error="Pod logs not available for this alert."
            borderRadius={0}
          />
        )}
      </Stack>
    </Stack>
  );
};

export const DeploymentAlertModal: React.FC<{
  alert: DeploymentAlertDetailFragment;
  onClose?: () => void;
}> = ({ alert, onClose }) => {
  // instantiating the modal defaults to open state
  const [isOpen, { off }] = useBoolean(true);
  const { bg2 } = useColors();

  const [subtitle, content] = (() => {
    if (alert.__typename === "DeploymentPodCrashAlert") {
      return ["Pod Crashed", <PodCrashAlert alert={alert} />];
    }
    return [
      "Unknown Alert",
      <ZError>
        Oops, looks like we're not able to view this alert detail.
      </ZError>,
    ];
  })();

  const closeHandler = () => {
    if (onClose) {
      onClose();
    }
    off();
  };

  return (
    <Drawer isOpen={isOpen} onClose={closeHandler} size="lg">
      <DrawerOverlay bg="blackAlpha.800" />
      <DrawerContent bg={bg2}>
        <DrawerCloseButton />
        <DrawerHeader>
          Deployment Alert{subtitle ? `: ${subtitle}` : ""}
        </DrawerHeader>
        <DrawerBody>{content}</DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export const DeploymentAlertModalById: React.FC<{
  deploymentId: string;
  alertId: string;
}> = ({ deploymentId, alertId }) => {
  const { data: alertData, error } = useDeploymentAlertQuery({
    variables: {
      deploymentId: deploymentId,
      alertId: alertId,
    },
  });

  return (
    <>
      {error && <ZError error={`Unable to retrieve alert: ${error.message}`} />}
      {alertData?.currentUser.deployment?.alert ? (
        <DeploymentAlertModal
          alert={alertData?.currentUser.deployment?.alert}
        />
      ) : (
        <ZError error={"No alert detail available"} />
      )}
    </>
  );
};
