// cspell:disable

import { CloudProvider, UserCloudsQuery } from "@zeet/web-api/dist/graphql";
import { ICloudAccount, IRegions } from "../types/clouds-view";

// serverless regions: https://docs.aws.amazon.com/general/latest/gr/serverlessrepo.html

export const regions: IRegions = {
  [CloudProvider.Aws]: [
    { name: "us-west-2", isOptIn: false },
    { name: "us-east-1", isOptIn: false },
    { name: "us-east-2", isOptIn: false },
    { name: "us-west-1", isOptIn: false },
    { name: "ca-central-1", isOptIn: false },
    { name: "ca-west-1", isOptIn: false },
    { name: "eu-north-1", isOptIn: false },
    { name: "eu-west-1", isOptIn: false },
    { name: "eu-west-2", isOptIn: false },
    { name: "eu-west-3", isOptIn: false },
    { name: "eu-central-1", isOptIn: false },
    { name: "eu-central-2", isOptIn: true },
    { name: "eu-south-1", isOptIn: false },
    { name: "eu-south-2", isOptIn: true },
    { name: "af-south-1", isOptIn: false },
    { name: "ap-northeast-1", isOptIn: false },
    { name: "ap-northeast-2", isOptIn: false },
    { name: "ap-northeast-3", isOptIn: false },
    { name: "ap-southeast-1", isOptIn: false },
    { name: "ap-southeast-2", isOptIn: false },
    { name: "ap-southeast-3", isOptIn: true },
    { name: "ap-southeast-4", isOptIn: true },
    { name: "ap-east-1", isOptIn: false },
    { name: "ap-south-1", isOptIn: false },
    { name: "ap-south-2", isOptIn: true },
    { name: "sa-east-1", isOptIn: false },
    { name: "me-south-1", isOptIn: false },
    { name: "me-central-1", isOptIn: true },
    { name: "il-central-1", isOptIn: false },
    { name: "cn-north-1", isOptIn: true },
    { name: "cn-northwest-1", isOptIn: true },
    { name: "us-gov-west-1", isOptIn: true },
    { name: "us-gov-east-1", isOptIn: true },
  ],
  [CloudProvider.Gcp]: [
    { name: "us-west3", isOptIn: false },
    { name: "us-west2", isOptIn: false },
    { name: "us-west1", isOptIn: false },
    { name: "us-east4", isOptIn: false },
    { name: "us-east1", isOptIn: false },
    { name: "us-central1", isOptIn: false },
    { name: "asia-east1", isOptIn: false },
    { name: "asia-east2", isOptIn: false },
    { name: "asia-northeast1", isOptIn: false },
    { name: "asia-northeast2", isOptIn: false },
    { name: "asia-northeast3", isOptIn: false },
    { name: "asia-south1", isOptIn: false },
    { name: "asia-southeast1", isOptIn: false },
    { name: "australia-southeast1", isOptIn: false },
    { name: "europe-north1", isOptIn: false },
    { name: "europe-west1", isOptIn: false },
    { name: "europe-west2", isOptIn: false },
    { name: "europe-west3", isOptIn: false },
    { name: "europe-west4", isOptIn: false },
    { name: "europe-west6", isOptIn: false },
    { name: "northamerica-northeast1", isOptIn: false },
    { name: "southamerica-east1", isOptIn: false },
  ],
  [CloudProvider.Do]: [
    { name: "NYC1", isOptIn: false },
    { name: "NYC3", isOptIn: false },
    { name: "AMS3", isOptIn: false },
    { name: "SFO2", isOptIn: false },
    { name: "SFO3", isOptIn: false },
    { name: "SGP1", isOptIn: false },
    { name: "LON1", isOptIn: false },
    { name: "FRA1", isOptIn: false },
    { name: "TOR1", isOptIn: false },
    { name: "BLR1", isOptIn: false },
    { name: "SYD1", isOptIn: false },
  ],
  [CloudProvider.Coreweave]: [
    { name: "LGA1", isOptIn: false },
    { name: "ORD1", isOptIn: false },
    { name: "LAS1", isOptIn: false },
  ],
  [CloudProvider.Linode]: [
    { name: "ap-west", isOptIn: false },
    { name: "ca-central", isOptIn: false },
    { name: "ap-southeast", isOptIn: false },
    { name: "us-iad", isOptIn: false },
    { name: "us-ord", isOptIn: false },
    { name: "fr-par", isOptIn: false },
    { name: "us-sea", isOptIn: false },
    { name: "br-gru", isOptIn: false },
    { name: "nl-ams", isOptIn: false },
    { name: "se-sto", isOptIn: false },
    { name: "in-maa", isOptIn: false },
    { name: "jp-osa", isOptIn: false },
    { name: "it-mil", isOptIn: false },
    { name: "us-mia", isOptIn: false },
    { name: "id-cgk", isOptIn: false },
    { name: "us-lax", isOptIn: false },
    { name: "us-central", isOptIn: false },
    { name: "us-west", isOptIn: false },
    { name: "us-southeast", isOptIn: false },
    { name: "us-east", isOptIn: false },
    { name: "eu-west", isOptIn: false },
    { name: "ap-south", isOptIn: false },
    { name: "eu-central", isOptIn: false },
  ],
  [CloudProvider.Vultr]: [
    { name: "ams", isOptIn: false },
    { name: "atl", isOptIn: false },
    { name: "blr", isOptIn: false },
    { name: "bom", isOptIn: false },
    { name: "cdg", isOptIn: false },
    { name: "del", isOptIn: false },
    { name: "dfw", isOptIn: false },
    { name: "ewr", isOptIn: false },
    { name: "fra", isOptIn: false },
    { name: "hnl", isOptIn: false },
    { name: "icn", isOptIn: false },
    { name: "itm", isOptIn: false },
    { name: "jnb", isOptIn: false },
    { name: "lax", isOptIn: false },
    { name: "lhr", isOptIn: false },
    { name: "mad", isOptIn: false },
    { name: "man", isOptIn: false },
    { name: "mel", isOptIn: false },
    { name: "mex", isOptIn: false },
    { name: "mia", isOptIn: false },
    { name: "nrt", isOptIn: false },
    { name: "ord", isOptIn: false },
    { name: "sao", isOptIn: false },
    { name: "scl", isOptIn: false },
    { name: "sea", isOptIn: false },
    { name: "sgp", isOptIn: false },
    { name: "sjc", isOptIn: false },
    { name: "sto", isOptIn: false },
    { name: "syd", isOptIn: false },
    { name: "tlv", isOptIn: false },
    { name: "waw", isOptIn: false },
    { name: "yto", isOptIn: false },
  ],
  [CloudProvider.Azure]: [
    { name: "eastus", isOptIn: false },
    { name: "eastus2", isOptIn: false },
    { name: "southcentralus", isOptIn: false },
    { name: "westus2", isOptIn: false },
    { name: "westus3", isOptIn: false },
    { name: "australiaeast", isOptIn: false },
    { name: "southeastasia", isOptIn: false },
    { name: "northeurope", isOptIn: false },
    { name: "swedencentral", isOptIn: false },
    { name: "uksouth", isOptIn: false },
    { name: "westeurope", isOptIn: false },
    { name: "centralus", isOptIn: false },
    { name: "southafricanorth", isOptIn: false },
    { name: "centralindia", isOptIn: false },
    { name: "eastasia", isOptIn: false },
    { name: "japaneast", isOptIn: false },
    { name: "koreacentral", isOptIn: false },
    { name: "canadacentral", isOptIn: false },
    { name: "francecentral", isOptIn: false },
    { name: "germanywestcentral", isOptIn: false },
    { name: "norwayeast", isOptIn: false },
    { name: "polandcentral", isOptIn: false },
    { name: "switzerlandnorth", isOptIn: false },
    { name: "uaenorth", isOptIn: false },
    { name: "brazilsouth", isOptIn: false },
    { name: "centraluseuap", isOptIn: false },
    { name: "eastus2euap", isOptIn: false },
    { name: "qatarcentral", isOptIn: false },
    { name: "centralusstage", isOptIn: false },
    { name: "eastusstage", isOptIn: false },
    { name: "eastus2stage", isOptIn: false },
    { name: "northcentralusstage", isOptIn: false },
    { name: "southcentralusstage", isOptIn: false },
    { name: "westusstage", isOptIn: false },
    { name: "westus2stage", isOptIn: false },
    { name: "asia", isOptIn: false },
    { name: "asiapacific", isOptIn: false },
    { name: "australia", isOptIn: false },
    { name: "brazil", isOptIn: false },
    { name: "canada", isOptIn: false },
    { name: "europe", isOptIn: false },
    { name: "france", isOptIn: false },
    { name: "germany", isOptIn: false },
    { name: "global", isOptIn: false },
    { name: "india", isOptIn: false },
    { name: "japan", isOptIn: false },
    { name: "korea", isOptIn: false },
    { name: "norway", isOptIn: false },
    { name: "singapore", isOptIn: false },
    { name: "southafrica", isOptIn: false },
    { name: "switzerland", isOptIn: false },
    { name: "uae", isOptIn: false },
    { name: "uk", isOptIn: false },
    { name: "unitedstates", isOptIn: false },
    { name: "unitedstateseuap", isOptIn: false },
    { name: "eastasiastage", isOptIn: false },
    { name: "southeastasiastage", isOptIn: false },
    { name: "brazilus", isOptIn: false },
    { name: "eastusstg", isOptIn: false },
    { name: "northcentralus", isOptIn: false },
    { name: "westus", isOptIn: false },
    { name: "jioindiawest", isOptIn: false },
    { name: "southcentralusstg", isOptIn: false },
    { name: "westcentralus", isOptIn: false },
    { name: "southafricawest", isOptIn: false },
    { name: "australiacentral", isOptIn: false },
    { name: "australiacentral2", isOptIn: false },
    { name: "australiasoutheast", isOptIn: false },
    { name: "japanwest", isOptIn: false },
    { name: "jioindiacentral", isOptIn: false },
    { name: "koreasouth", isOptIn: false },
    { name: "southindia", isOptIn: false },
    { name: "westindia", isOptIn: false },
    { name: "canadaeast", isOptIn: false },
    { name: "francesouth", isOptIn: false },
    { name: "germanynorth", isOptIn: false },
    { name: "norwaywest", isOptIn: false },
    { name: "switzerlandwest", isOptIn: false },
    { name: "ukwest", isOptIn: false },
    { name: "uaecentral", isOptIn: false },
    { name: "brazilsoutheast", isOptIn: false },
    { name: "italynorth", isOptIn: false },
  ],
};

export const makeCloudsAccounts = (
  userCloudQuery: UserCloudsQuery | undefined
): ICloudAccount[] => {
  const output: ICloudAccount[] = [];

  userCloudQuery?.user.awsAccounts?.map((awsAccount) => {
    output.push({
      zeetCloudId: awsAccount.id,
      cloudProvider: CloudProvider.Aws,
      cloudProviderId: awsAccount.accountID,
    });
  });
  userCloudQuery?.user.gcpAccounts?.map((gcpAccount) => {
    output.push({
      zeetCloudId: gcpAccount.id,
      cloudProvider: CloudProvider.Gcp,
      cloudProviderId: gcpAccount.projectID,
    });
  });
  userCloudQuery?.user.doAccounts?.map((doAccount) => {
    output.push({
      zeetCloudId: doAccount.id,
      cloudProvider: CloudProvider.Do,
      cloudProviderId: doAccount.accessTokenPrefix,
    });
  });
  userCloudQuery?.user.linodeAccounts?.map((linodeAccount) => {
    output.push({
      zeetCloudId: linodeAccount.id,
      cloudProvider: CloudProvider.Linode,
      cloudProviderId: linodeAccount.accessTokenPrefix,
    });
  });
  userCloudQuery?.user.coreweaveAccounts?.map((coreweaveAccount) => {
    output.push({
      zeetCloudId: coreweaveAccount.id,
      cloudProvider: CloudProvider.Coreweave,
      cloudProviderId: coreweaveAccount.namespace,
    });
  });
  userCloudQuery?.user.vultrAccounts?.map((vultrAccount) => {
    output.push({
      zeetCloudId: vultrAccount.id,
      cloudProvider: CloudProvider.Vultr,
      cloudProviderId: vultrAccount.name,
    });
  });
  userCloudQuery?.user.azureAccounts?.map((coreweaveAccount) => {
    output.push({
      zeetCloudId: coreweaveAccount.id,
      cloudProvider: CloudProvider.Azure,
      cloudProviderId: coreweaveAccount.name,
    });
  });
  return output;
};

export const getConnectedCloudsCount = (
  cloudsQuery: UserCloudsQuery | undefined
): number => {
  let count = 0;
  const incrementFunc = (c) => {
    if (c.connected) {
      count++;
    }
  };
  cloudsQuery?.user.awsAccounts?.map(incrementFunc);
  cloudsQuery?.user.gcpAccounts?.map(incrementFunc);
  cloudsQuery?.user.coreweaveAccounts?.map(incrementFunc);
  cloudsQuery?.user.doAccounts?.map(incrementFunc);
  cloudsQuery?.user.linodeAccounts?.map(incrementFunc);
  cloudsQuery?.user.vultrAccounts?.map(incrementFunc);
  cloudsQuery?.user.azureAccounts?.map(incrementFunc);
  return count;
};
