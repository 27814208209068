import { Avatar as CAvatar, PropsOf } from "@chakra-ui/react";
import { User } from "@zeet/web-api/dist/graphqlv1";
import React from "react";

type AvatarProps = {
  user?: User;
} & PropsOf<typeof CAvatar>;

export const Avatar = React.forwardRef<HTMLElement, AvatarProps>(
  ({ user, backgroundColor, ...restProps }, ref) => {
    delete restProps["children"];
    if (user) {
      return (
        <CAvatar
          ref={ref}
          color="#fff"
          src={user.avatar || ""}
          name={user.login}
          userSelect="none"
          backgroundColor={(user?.avatar || "") && "#EEE"}
          {...restProps}
        />
      );
    }
    return (
      <CAvatar
        ref={ref}
        color="#fff"
        userSelect="none"
        backgroundColor={backgroundColor}
        {...restProps}
      />
    );
  }
);
