import {
  Maybe,
  MetricAnnotation,
  MetricAnnotationDirection,
  MetricAnnotationType,
  MetricEntry,
  MetricStreamV1Fragment,
  MetricType,
} from "@zeet/web-api/dist/graphqlv1";
import { getEmptyMetricsArray } from "~/components/utils/array";

const byte = 1;
const kilobyte = byte * 1024;
const megabyte = kilobyte * 1024;
const gigabyte = megabyte * 1024;
const terabyte = gigabyte * 1024;

enum ByteSize {
  BYTE = "B",
  KILOBYTE = "KB",
  MEGABYTE = "MB",
  GIGABYTE = "GB",
  TERABYTE = "TB",
}

const allByteSizes = [
  ByteSize.TERABYTE,
  ByteSize.GIGABYTE,
  ByteSize.MEGABYTE,
  ByteSize.KILOBYTE,
];

const byteSizeMap = {
  [ByteSize.BYTE]: byte,
  [ByteSize.KILOBYTE]: kilobyte,
  [ByteSize.MEGABYTE]: megabyte,
  [ByteSize.GIGABYTE]: gigabyte,
  [ByteSize.TERABYTE]: terabyte,
};

export const metricsTypesToFetch = [
  MetricType.Cpu,
  MetricType.Memory,
  MetricType.Storage,
  MetricType.Network,
];

export const metricToChartData = (
  metricType?: Maybe<MetricType>,
  metricEntries?: Maybe<MetricEntry[]>
) => {
  const maxEntryValue = getMaxEntryValue(metricEntries);

  return metricEntries && metricEntries.length
    ? metricEntries.map((metricEntry) => [
        new Date(metricEntry.timestamp),
        formatMetricValue(metricType, metricEntry, maxEntryValue),
      ])
    : getEmptyMetricsArray();
};

const getByteSize = (
  value?: number,
  defaultReturn: ByteSize = ByteSize.KILOBYTE
): ByteSize => {
  if (!value) {
    return defaultReturn;
  }

  for (const byteSize of allByteSizes) {
    if (value >= byteSizeMap[byteSize] / 2) {
      return byteSize;
    }
  }

  return defaultReturn;
};

export const getMetricSuffix = (
  metricType?: Maybe<MetricType>,
  metricEntries?: Maybe<MetricEntry[]>
) => {
  if (!metricType) {
    return "";
  }

  const maxEntryValue = getMaxEntryValue(metricEntries);

  switch (metricType) {
    case MetricType.Cpu:
      return "%";
    case MetricType.Memory:
      return getByteSize(maxEntryValue);
    case MetricType.Network:
      return getByteSize(maxEntryValue) + "/s";
    case MetricType.Storage:
      return getByteSize(maxEntryValue);
    case MetricType.FunctionDuration: {
      const value = maxEntryValue || 0;
      if (value > 1000) {
        return "s";
      }

      return "ms";
    }
    case MetricType.CpuUtilization:
    case MetricType.MemoryUtilization:
      return "%";
  }

  return "";
};

const getMaxEntryValue = (metricEntries?: Maybe<MetricEntry[]>) => {
  const values = metricEntries
    ?.map((m) => m.value?.valueOf())
    .filter((v) => !!v)
    .map((v) => v!);
  return values ? Math.max(...values) : undefined;
};

const formatMetricValue = (
  metricType?: Maybe<MetricType>,
  metricEntry?: MetricEntry,
  maxEntryValue?: number
) => {
  if (!metricType) {
    return "";
  }

  const byteSize = getByteSize(maxEntryValue);

  switch (metricType) {
    case MetricType.Cpu:
      return ((metricEntry?.value || 0) * 100).toFixed(2);
    case MetricType.Memory:
      return ((metricEntry?.value || 0) / byteSizeMap[byteSize]).toFixed(2);
    case MetricType.Network:
      return ((metricEntry?.value || 0) / byteSizeMap[byteSize]).toFixed(2);
    case MetricType.Storage:
      return ((metricEntry?.value || 0) / byteSizeMap[byteSize]).toFixed(2);
    case MetricType.FunctionDuration: {
      const value = metricEntry?.value || 0;
      if ((maxEntryValue || 0) > 1000) {
        return (value / 1000).toFixed(2);
      }

      return value.toFixed(2);
    }
  }

  return metricEntry?.value?.toFixed(2) ?? "";
};

export const formatLatestMetricData = (metric?: MetricStreamV1Fragment) => {
  const metricEntriesLength = metric?.entries?.length;
  if (!metricEntriesLength) {
    return "";
  }

  return (
    formatMetricValue(
      metric.type,
      metric?.entries?.[metricEntriesLength - 1],
      getMaxEntryValue(metric?.entries)
    ) + getMetricSuffix(metric.type, metric?.entries)
  );
};

type ECMetricAnnotationType = "average" | "min" | "max" | "median";
const allowedTypes = ["average", "min", "max", "median"];

interface ECMetricAnnotationData {
  name: string;
  value?: string | number;
  xAxis?: string | number;
  yAxis?: string | number;
  type?: ECMetricAnnotationType;
}

interface ECMetricAnnotation {
  markPoint?: {
    data?: ECMetricAnnotationData[];
  };
  markLine?: {
    data?: ECMetricAnnotationData[];
  };
}

export const processAnnotations = (
  annotations?: Maybe<MetricAnnotation[]>
): ECMetricAnnotation => {
  if (!annotations) {
    return {};
  }

  return annotations.reduce(
    (prevOptions, annotation) =>
      processAnnotationOptions(annotation, prevOptions),
    {}
  );
};

const processAnnotationOptions = (
  annotation: MetricAnnotation,
  options: ECMetricAnnotation
): ECMetricAnnotation => {
  switch (annotation.type) {
    case MetricAnnotationType.Point: {
      const currentPointOptions = options.markPoint?.data ?? [];
      currentPointOptions.push(getMetricAnnotationData(annotation));
      return { ...options, markPoint: { data: currentPointOptions } };
    }
    case MetricAnnotationType.Line: {
      const currentPointOptions = options.markLine?.data ?? [];
      currentPointOptions.push(getMetricAnnotationData(annotation));
      return { ...options, markLine: { data: currentPointOptions } };
    }
  }
};

const getMetricAnnotationData = (
  annotation: MetricAnnotation
): ECMetricAnnotationData => {
  const currentPointOption: ECMetricAnnotationData = { name: "" };

  if (annotation.label) {
    currentPointOption.name = annotation.label.slice(0, 6);
  }

  if (annotation.direction && annotation.value !== null) {
    switch (annotation.direction) {
      case MetricAnnotationDirection.Horizontal:
        currentPointOption.yAxis = annotation.value;
        break;
      case MetricAnnotationDirection.Vertical:
        currentPointOption.xAxis = annotation.value;
        break;
    }

    return currentPointOption;
  }

  if (
    annotation.function &&
    allowedTypes.includes(annotation.function.toLowerCase())
  ) {
    currentPointOption.type =
      annotation.function.toLowerCase() as ECMetricAnnotationType;
    return currentPointOption;
  }

  currentPointOption.value = annotation.value ?? undefined;

  return currentPointOption;
};

export interface ResourceLatestDeploymentData {
  link: string;
  linkContent?: string;
  createdAt?: Date | null;
}
