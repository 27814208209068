import { chain, isEmpty, isObject, omitBy } from "lodash";

export type RemoveObjectPropertyRule = <R>(value: R, key: string) => boolean;

export const removeObjectProperties = <T extends object>(
  object: T,
  rules: RemoveObjectPropertyRule[]
): Partial<T> => {
  if (!rules.length) {
    return object;
  }

  const applyRules = <R extends object>(partialObject: R) => {
    let newObject: Partial<R> = partialObject;

    for (const rule of rules) {
      newObject = omitBy(newObject, rule) as Partial<R>;
    }

    return newObject;
  };

  return chain(object)
    .pickBy(isObject)
    .mapValues((v) => removeObjectProperties(v as object, rules))
    .omitBy(isEmpty)
    .assign(applyRules(omitBy(object, isObject)))
    .value() as Partial<T>;
};
