import { Box, Flex, Text } from "@chakra-ui/react";
import { useColors } from "@zeet/web-ui";
import React from "react";
import { IDeployCard } from "../../types/team-onboard";

interface IDeployCardProps {
  projectSource: IDeployCard;
  index: number;
  activeIndex: number;
}

export const DeployCard: React.FC<IDeployCardProps> = ({
  projectSource,
  activeIndex,
  index,
}) => {
  const { brand } = useColors();

  return (
    <Flex
      width="415px"
      maxWidth="100%"
      padding="16px"
      borderRadius="8px"
      borderWidth="1px"
      cursor="pointer"
      borderColor={activeIndex === index ? brand : ""}
      outline={activeIndex === index ? `1px solid ${brand}` : ""}
    >
      <Flex justifyContent={"center"} alignItems="center" paddingRight="16px">
        {projectSource.icon}
      </Flex>
      <Box>
        <Text fontWeight="500">{projectSource.title}</Text>
        <Text
          maxWidth="325px"
          fontSize="14px"
          opacity="0.6"
          mt="4px"
          lineHeight="1.2"
        >
          {projectSource.description}
        </Text>
      </Box>
    </Flex>
  );
};
