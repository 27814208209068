import {
  Box,
  Button,
  Flex,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import {
  DeploymentStatus,
  DeployTarget,
  RepoSourceType,
  useRepoForProjectQuery,
} from "@zeet/web-api/dist/graphql";
import {
  Container,
  Loading,
  ProjectStatus,
  Tooltip,
  useDebounce,
  ZError,
  Link as ZLink,
} from "@zeet/web-ui";
import React, { useMemo, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { isGit } from "~/components/utils/project";
import { AccountPageHead } from "../../Account/PageHead";
import {
  getSourceImage,
  getSourceLink,
  getSourceName,
} from "../../Dashboard/DashboardRepoItem";
import { DeploymentLogSection } from "../Deployment/DeploymentLogSection";
import { useProjectV3Context } from "../ProjectV3Provider";
import { useProject } from "../Provider";
import { GithubProjectBuildSettings } from "../Settings/Build";
import { EnvVarSettingsProvider } from "../Settings/EnvVarSettingsProvider";
import { EnvVarsProjectSettings } from "../Settings/EnvVarsProjectSettings";
import { DockerDeploySettings } from "../Settings/Image";
import { projectPath, repoPath } from "../util";
import { OnboardSuccessModal } from "./OnboardSuccessModal";

const Onboard: React.FC = () => {
  const { repoName } = useParams<{
    repoName: string;
    teamName: string;
  }>();
  const project = useProject();
  const history = useHistory();
  const [stay, setStay] = useState(false);
  const isInlineStack = useBreakpointValue({ base: false, lg: true });

  const { data, loading, error } = useRepoForProjectQuery({
    variables: {
      path: projectPath(project),
      repo: repoName,
    },
    pollInterval: 3000,
  });

  const status = data?.project?.repo?.productionDeployment?.status;
  const active =
    data?.project?.repo?.productionDeployment?.deployStatus?.state ===
    "deployed";

  const _deploySuccessFull = useMemo(() => {
    return status === DeploymentStatus.DeploySucceeded && active;
  }, [status, active]);

  const deploySuccessFull = useDebounce(_deploySuccessFull, 3000, true);
  const { projectStatus } = useProjectV3Context();

  const repo = data?.project?.repo;

  if (loading) {
    return <Loading />;
  }

  if (error && !repo) {
    return <ZError error={error.message} />;
  }

  if (!repo) {
    return null;
  }

  return (
    <>
      <OnboardSuccessModal
        isOpen={!!deploySuccessFull && !stay}
        onClose={(stay) => {
          if (stay) {
            setStay(true);
          } else {
            history.push(`/${repoPath(repo)}`);
          }
        }}
      />
      <AccountPageHead>
        <Flex flexDir={["column", "column", "column", "row"]}>
          <Text fontWeight="bold" fontSize={28} mb={2}>
            New Project Onboarding
          </Text>
          <Button
            as={Link}
            to={`/${repoPath(repo)}`}
            marginLeft="auto"
            colorScheme={stay ? "brand" : undefined}
          >
            {stay ? "Continue to project" : "Skip Onboarding"}
            <Tooltip text="This will direct you to project dashboard, you can continue setup in the settings tab." />
          </Button>
        </Flex>
        <Text fontWeight="bold" fontSize={24} mb={2}>
          {repo.name}
        </Text>
        <Flex mb={2} justifyContent="space-between">
          <ZLink
            rel="noopener noreferrer"
            to={getSourceLink(repo.source)}
            isExternal
            noUnderline
          >
            <Flex display="inline-flex" alignItems="center">
              {getSourceImage(repo.source)}
              <Text ml={2} color="brandVar">
                {getSourceName(repo.source)}
              </Text>
            </Flex>
          </ZLink>
          <ProjectStatus
            clusterState={repo.cluster?.state}
            status={projectStatus?.team?.project?.status}
            fontSize="16px"
          />
        </Flex>
      </AccountPageHead>
      <Container>
        <Stack isInline={isInlineStack} spacing={8}>
          <Stack w={["100%", "100%", "100%", "50%"]} spacing={4}>
            {repo.deployTarget !== DeployTarget.Serverless && (
              <Box>
                {isGit(repo.source.type) ? (
                  <GithubProjectBuildSettings
                    title={"What kind of project do you have?"}
                    repo={repo}
                  />
                ) : (
                  <DockerDeploySettings repo={repo} />
                )}
              </Box>
            )}
            <Box>
              <EnvVarSettingsProvider envsData={repo.envs}>
                <EnvVarsProjectSettings repo={repo} />
              </EnvVarSettingsProvider>
            </Box>
          </Stack>
          <Flex
            w={["100%", "100%", "100%", "50%"]}
            flexShrink={1}
            flexDirection="column"
          >
            <Flex flexDirection="column">
              <DeploymentLogSection
                isGithubProjectOnboarding={[
                  RepoSourceType.Github,
                  RepoSourceType.GithubPublic,
                ].includes(repo.source.type)}
                deployment={repo.productionDeployment}
                repo={repo}
              />
            </Flex>
          </Flex>
        </Stack>
      </Container>
    </>
  );
};

export default Onboard;
