import { Alert, AlertProps, Flex, Icon, Text } from "@chakra-ui/react";
import { MdAutoAwesome } from "react-icons/md";
import { Button, useColorModeValue, useLocalStorage } from "..";

interface TipProps {
  id: string;
  children: React.ReactNode;
  dismissible?: boolean;
}

export const Tip = ({
  children,
  id,
  dismissible = true,
  ...props
}: TipProps & AlertProps) => {
  const [dismissed, setDismissed] = useLocalStorage(`tip-${id}`);
  const dismissOnboarding = () => setDismissed("true");
  const textColor = useColorModeValue("blue.400", "blue.200");

  if (dismissed) return null;

  return (
    <Alert
      status="info"
      color={textColor}
      alignItems="center"
      justifyContent="space-between"
      my="6"
      p="2"
      px="4"
      borderRadius="md"
      {...props}
    >
      <Flex alignItems="center" gap="2">
        <Icon as={MdAutoAwesome} />
        {children && <Text>{children}</Text>}
      </Flex>
      {dismissible && (
        <Button
          variant="ghost"
          size="sm"
          m="0"
          color={`${textColor} !important`}
          onClick={dismissOnboarding}
        >
          Dismiss
        </Button>
      )}
    </Alert>
  );
};
