import { Flex, Stack } from "@chakra-ui/react";
import { useEnvironmentEnvironmentVariablesQuery } from "@zeet/web-api/dist/graphql";
import { SideLink } from "@zeet/web-ui";
import { Route, Switch } from "react-router-dom";
import { useEnv, useProject } from "../Provider";
import { EnvVarSettingsProvider } from "../Settings/EnvVarSettingsProvider";
import { projectPath } from "../util";
import { API } from "./API";
import { Danger } from "./Danger";
import { Envs } from "./Envs";
import { RebuildReposProvider } from "./RebuildReposProvider";

const Settings = () => {
  const env = useEnv();
  const project = useProject();

  const { data } = useEnvironmentEnvironmentVariablesQuery({
    variables: { path: projectPath(project), env: env.name },
  });

  const basePath = `/${projectPath(project)}/${env.name}/_settings`;
  const apiPath = `${basePath}/api`;
  const dangerPath = `${basePath}/danger`;

  return (
    <Flex flexDirection="row" mt={4}>
      <Stack mr={5} minWidth="250px">
        <SideLink to={basePath}>Environment variables</SideLink>
        <SideLink to={apiPath}>API</SideLink>
        <SideLink to={dangerPath}>Danger Zone</SideLink>
      </Stack>
      <Switch>
        <Route exact path={basePath}>
          <EnvVarSettingsProvider envsData={data?.project?.environment.envs}>
            <RebuildReposProvider>
              <Envs projectEnvs={data?.project?.envs} />
            </RebuildReposProvider>
          </EnvVarSettingsProvider>
        </Route>
        <Route exact path={apiPath}>
          <API project={project} />
        </Route>
        <Route exact path={dangerPath}>
          <Danger />
        </Route>
      </Switch>
    </Flex>
  );
};

export default Settings;
