import {
  AspectRatio,
  Flex,
  FlexProps,
  Image,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link } from "@zeet/web-ui";
import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export const NAVBAR_HEIGHT = "3.5rem";

interface LogoProps extends FlexProps {
  logoUrl: string;
  title: string;
  href: string;
  isDark?: true;
  isLight?: true;
  isGray?: boolean;
  aspectRatio?: number;
  text?: React.ReactElement;
  hoverColor?: string;
}

export const Logo: React.FC<LogoProps> = ({
  logoUrl,
  title,
  href,
  isDark,
  isLight,
  isGray,
  aspectRatio,
  text,
  hoverColor,
  ...restProps
}) => {
  const invert = useColorModeValue(isLight || false, isDark || false);
  return (
    <Flex {...restProps} opacity={0.9} _hover={{ opacity: 1 }}>
      <AspectRatio ratio={aspectRatio || 1} w="100%">
        <Link isExternal to={href} _hover={{ color: hoverColor || "brandVar" }}>
          <Image
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
            src={logoUrl}
            alt={title}
            filter={isGray ? `grayscale(1)${invert ? " invert(1)" : ""}` : ""}
          />
          {text}
        </Link>
      </AspectRatio>
    </Flex>
  );
};
