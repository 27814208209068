import { Flex } from "@chakra-ui/react";
import { ProjectV3AdapterStatus } from "@zeet/web-api/dist/graphql";
import { ProjectStatus, StatusBadgeProps } from "@zeet/web-ui";
import { FC } from "react";

interface ProjectStatusCellProps {
  status?: ProjectV3AdapterStatus | StatusBadgeProps["status"] | null;
  loading?: boolean;
}

export const ProjectStatusCell: FC<ProjectStatusCellProps> = ({
  status,
  loading,
}) => {
  return (
    <Flex alignItems="center">
      <ProjectStatus status={status} loading={loading} />
    </Flex>
  );
};
