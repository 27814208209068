import { useColorModeValue } from "@chakra-ui/react";
import { useMonaco } from "@monaco-editor/react";
import { useEffect } from "react";
import { useColors } from "./useColors";

export const useMonacoTheme = () => {
  const monaco = useMonaco();
  const baseTheme = useColorModeValue("vs", "vs-dark");
  const { bg2 } = useColors();

  useEffect(() => {
    monaco?.editor.defineTheme("zeet-dark", {
      base: baseTheme,
      inherit: true,
      rules: [],
      colors: {
        "editor.background": bg2,
        "editorInlayHint.background": "#7fba004d",
      },
    });
    monaco?.editor.setTheme("zeet-dark");
  }, [monaco, baseTheme, bg2]);
};
