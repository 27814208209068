import { useDisclosure } from "@chakra-ui/react";
import {
  ProjectConnectorType,
  ProjectOutputsQuery,
} from "@zeet/web-api/dist/graphqlv1";
import { Button } from "@zeet/web-ui";
import React from "react";
import { DatabaseConnectModal } from "./DatabaseConnectModal";

export const DatabaseConnectAction: React.FC<{
  projectOutputs?: ProjectOutputsQuery;
}> = ({ projectOutputs }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  if (
    projectOutputs?.team?.project?.connector?.type !==
    ProjectConnectorType.DatabaseConnection
  ) {
    return null;
  }

  return (
    <>
      <DatabaseConnectModal {...{ isOpen, onClose, projectOutputs }} />
      <Button variant="secondary" onClick={onOpen}>
        Connection Info
      </Button>
    </>
  );
};
