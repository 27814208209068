import { useToast } from "@chakra-ui/react";
import {
  RepoDetailFragment,
  useSetRepoEnvsMutation,
} from "@zeet/web-api/dist/graphql";
import { useCurrentTeamUser } from "@zeet/web-ui";
import React, { useEffect } from "react";
import { EnvVarsActions } from "./EnvVarsActions";
import { useEnvVarSettingsContext } from "./EnvVarSettingsProvider";
import { EnvVarSettings } from "./EnvVarsSettings";
import "./Settings.scss";

export const EnvVarsProjectSettings: React.FC<{ repo: RepoDetailFragment }> = ({
  repo,
}) => {
  const [{ envs }, { parseEnv }] = useEnvVarSettingsContext();
  const team = useCurrentTeamUser();
  const actionButtons = React.useMemo(() => {
    return <EnvVarsActions handleEnvFile={parseEnv} to={repo} />;
  }, [repo, parseEnv]);
  const toast = useToast();
  const [setRepoEnvs, { error, loading }] = useSetRepoEnvsMutation({
    errorPolicy: "all",
    onCompleted: (data) => {
      if (data) {
        toast({
          title: "Environment Variables Saved",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    },
  });

  const onSubmit = (): void => {
    setRepoEnvs({
      variables: {
        input: {
          id: repo.id,
          envs: envs
            .filter((e) => e.name)
            .map((e) => ({
              name: e.name.trim(),
              value: e.value.trim(),
              sealed: e.sealed,
            })),
        },
      },
    });
  };

  useEffect(() => {
    if (error) {
      toast({
        title: "Failed to save environment variables",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [error, toast]);

  if (error) {
    console.error(error);
  }
  return (
    <EnvVarSettings
      actionButtons={actionButtons}
      onSubmit={onSubmit}
      error={error}
      loading={loading}
      repo={repo}
      inherited={[
        {
          name: repo.projectEnvironment?.name || "",
          path: `/${team.login}/${repo.project?.name}/${repo.projectEnvironment?.name}/_settings`,
          type: "subgroup",
          vars: repo.projectEnvironment?.envs,
        },

        {
          name: repo.project?.name || "",
          path: `/${team.login}/${repo.project?.name}/_settings`,
          type: "group",
          vars: repo.project?.envs,
        },
      ]}
    />
  );
};
