import { useUserTeamsQuery } from "@zeet/web-api/dist/graphql";
import {
  CenterLoading,
  RequireAuth,
  useCurrentTeamUser,
  useCurrentUser,
  useNextUrl,
  useQueryParams,
  useSetTeamId,
} from "@zeet/web-ui";
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

const useTeamRedirect = (): { loading: boolean } => {
  const user = useCurrentUser();
  const teamUser = useCurrentTeamUser();
  const history = useHistory();
  const query = useQueryParams();
  const yeetQuery = query.get("yeet");
  const nextUrl = useNextUrl("team-onboard");
  const onboardUrl = yeetQuery ? `/team-onboard?yeet=${yeetQuery}` : nextUrl;
  const location = useLocation();
  const setTeamId = useSetTeamId();
  const { data: teams, loading } = useUserTeamsQuery();

  useEffect(() => {
    if (!user || !teamUser) {
      return;
    }

    if (teamUser?.hasZeetCloud) {
      return;
    }

    if (loading) {
      return;
    }

    if (
      !teamUser?.isTeam &&
      teams?.currentUser.teams?.length &&
      teams?.currentUser.teams[0]?.team.user
    ) {
      setTeamId(teams?.currentUser.teams[0].team.id);
    } else if (!teamUser?.isTeam) {
      // make user create or join team
      history.push(onboardUrl); // create or join a team
    }
  }, [
    teamUser,
    teams,
    setTeamId,
    history,
    onboardUrl,
    location,
    loading,
    user,
  ]);

  // legacy path
  if (teamUser?.hasZeetCloud) {
    return { loading: false };
  }

  if (teamUser?.isTeam) {
    return { loading: false };
  }

  return { loading: true };
};

const RequiredTeamInner: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { loading } = useTeamRedirect();
  if (loading) {
    return <CenterLoading />;
  }

  return <>{children}</>;
};

export const RequireTeam: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return (
    <RequireAuth>
      <RequiredTeamInner>{children}</RequiredTeamInner>
    </RequireAuth>
  );
};
