import {
  SiBitbucket,
  SiDocker,
  SiGit,
  SiGithub,
  SiGitlab,
  SiHelm,
  SiTerraform,
} from "react-icons/si";
import { SourceKind } from ".";

interface SourceIconProps {
  sourceKind?: SourceKind;
}

export const SourceIcon = ({ sourceKind }: SourceIconProps) => {
  switch (sourceKind) {
    case SourceKind.GitHub:
      return <SiGithub />;
    case SourceKind.GitLab:
      return <SiGitlab />;
    case SourceKind.Bitbucket:
      return <SiBitbucket />;
    case SourceKind.PublicGitRepo:
      return <SiGit />;
    case SourceKind.TerraformModule:
      return <SiTerraform />;
    case SourceKind.HelmRepository:
      return <SiHelm />;
    case SourceKind.DockerHub:
      return <SiDocker />;
    default:
      return null;
  }
};
