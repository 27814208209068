import { FormLabel as ChakraFormLabel, FormLabelProps } from "@chakra-ui/react";

export const FormLabel: React.FC<FormLabelProps> = (formLabelProps) => {
  return (
    <ChakraFormLabel
      fontSize="12px"
      lineHeight="18px"
      fontWeight="600"
      opacity="0.8"
      {...formLabelProps}
    />
  );
};
