import * as posthog from "posthog-js";
import { useCallback, useEffect, useRef } from "react";
import { config } from "../config";
import { usePosthog } from "./Provider";

type BufferedEvent = [eventName: string, eventProperties?: posthog.Properties];

export function useCapture(): (
  eventName: string,
  eventProperties?: posthog.Properties
) => void {
  const postHog = usePosthog();

  //to hold any events we may not be able to send because posthog is not intialized yet
  const eventsRef = useRef<BufferedEvent[]>([]);

  const capture = useCallback(
    (eventName: string, eventProperties?: posthog.Properties) => {
      if (!postHog) {
        eventsRef.current.push([eventName, eventProperties]);
        return;
      }
      postHog.capture(eventName, eventProperties);
    },
    [postHog]
  );

  //handle potentially buffered events, send them asap
  useEffect(() => {
    if (!postHog || !eventsRef.current.length) {
      return;
    }
    eventsRef.current.map((bEvent) => capture(bEvent[0], bEvent[1]));
    eventsRef.current = [];
  }, [capture, postHog]);

  const devCapture = useCallback((data, fields) => {
    console.info("[dev] capture", data, JSON.stringify(fields, null, 2));
  }, []);

  if (config.isDev) {
    return devCapture;
  }

  return capture;
}
