import { Tabs, TabsProps } from "@chakra-ui/react";
import { Container } from "@zeet/web-ui";
import React from "react";
import { Helmet } from "react-helmet";
import { DetailHeader } from "./DetailHeader";
import { NavHead, NavTabPanels, NavTabType } from "./NavTab";
import { useCurrentTabIndex } from "./useCurrentTabIndex";

interface TabbedLayoutProps {
  /**
   * The title to place in the page's <title></title> element. If omitted, title will be used.
   */
  pageTitle?: string;

  /**
   * The title to display in the layout's heading
   */
  title: string;

  tabs: NavTabType[];

  /**
   * The optional breadcrumbs to show for this page, latest last.
   */
  breadcrumbs?: {
    /**
     * The desired path of the breadcrumb when clicked.
     */
    to?: string;

    /**
     * The name of the breadcrumb to display
     */
    label: string;
  }[];

  titleDecorator?: React.ReactElement;

  titleElement?: React.ReactElement;

  actions?: React.ReactElement;

  banner?: React.ReactElement;

  withContainer?: boolean;
}

/**
 * A layout that supports tabbed navigation.
 *
 * @example
 * <TabbedLayout title="My Project">
 *  <ZTab name="Tab 1">Tab 1!</ZTab>
 *  <ZTab name="Tab 2">Tab 2!</ZTab>
 *  <ZTab name="Tab 3">Tab 3!</ZTab>
 * </TabbedLayout>
 */
export const TabbedLayout = ({
  pageTitle,
  title,
  tabs,
  titleDecorator,
  titleElement,
  banner,
  actions,
  withContainer,
  ...rest
}: TabbedLayoutProps & Partial<TabsProps>) => {
  const memoizedTabs = React.useMemo(() => tabs, [tabs]);
  const currentTabIndex = useCurrentTabIndex(memoizedTabs);

  return (
    <Tabs
      index={currentTabIndex}
      colorScheme={"success"}
      variant="line"
      width="100%"
      isLazy
      {...rest}
    >
      <Helmet>
        <title>{pageTitle || title}</title>
      </Helmet>

      {banner && <>{banner}</>}

      <DetailHeader
        title={
          titleElement ? (
            titleElement
          ) : (
            <NavHead my={0} mb={0}>
              {title}
              {titleDecorator}
            </NavHead>
          )
        }
        actions={actions}
        tabs={memoizedTabs}
        fullWidth
      />

      {withContainer ? (
        <Container fullWidth>
          <NavTabPanels tabs={memoizedTabs} />
        </Container>
      ) : (
        <NavTabPanels tabs={memoizedTabs} />
      )}
    </Tabs>
  );
};
