import {
  ProjectEnvironmentBasicFragment,
  useProjectEnvironmentRevisionsQuery,
} from "@zeet/web-api/dist/graphql";
import { usePagination } from "@zeet/web-ui";
import { RevisionsList } from "~/components/Revisions";

interface ProjectEnvironmentRevisionsProps
  extends ProjectEnvironmentBasicFragment {
  projectId: string;
}

const Revisions: React.FC<{ env: ProjectEnvironmentRevisionsProps }> = ({
  env,
}) => {
  const { pageInput, setPage } = usePagination();

  const { data, loading } = useProjectEnvironmentRevisionsQuery({
    variables: {
      projectId: env.projectId,
      id: env.id,
      page: pageInput,
    },
    fetchPolicy: "cache-and-network",
  });

  return (
    <RevisionsList
      revisions={{
        nodes: data?.project?.environment?.revisions?.nodes ?? [],
        pageInfo: data?.project?.environment?.revisions?.pageInfo,
      }}
      loading={loading}
      pageChanged={setPage}
    />
  );
};

export default Revisions;
