import {
  Flex,
  FormControl,
  Heading,
  Icon,
  useDisclosure,
} from "@chakra-ui/react";
import { ProjectDetailFragment } from "@zeet/web-api/dist/graphqlv1";
import {
  Button,
  Card,
  CopyCode,
  Flag,
  FormLabel,
  Input,
  useColors,
  useFeatureFlag,
} from "@zeet/web-ui";
import { useFormContext } from "react-hook-form";
import { MdFolder } from "react-icons/md";
import { BreadcrumbSeparator } from "~/components/Header/Breadcrumbs";
import { ProjectSettingsValues } from "../..";
import { SettingsSection } from "../SettingsSection";
import { UpdateGroupSubgroupModal } from "./UpdateGroupSubgroupModal";

interface OrganizeProjectSectionProps {
  project: ProjectDetailFragment;
}

export const OrganizeProjectSection = ({
  project,
}: OrganizeProjectSectionProps) => {
  const { fg } = useColors();
  const { register } = useFormContext<ProjectSettingsValues>();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const isFeatureEnabled = useFeatureFlag(Flag.DeployV2ProjectMove, {
    devEnabled: true,
  });

  return (
    <>
      <SettingsSection name="Organize">
        <FormControl>
          <FormLabel>Project Name</FormLabel>
          <Input width="100%" {...register("project.name")} />
        </FormControl>
        {isFeatureEnabled && (
          <Flex flex="1" flexDirection="column">
            <FormLabel>Group / Sub-Group</FormLabel>
            <Card
              as={Flex}
              flex="1"
              px="4"
              py="2"
              justifyContent="space-between"
              alignItems="center"
            >
              <Heading size="sm" display="flex" flexDirection="column">
                <Flex alignItems="center" gap="1">
                  <Flex alignItems="center" gap="1">
                    <Icon as={MdFolder} color={fg} />
                    {project.group?.name}
                  </Flex>
                  <BreadcrumbSeparator />
                  <Flex alignItems="center" gap="1">
                    <Icon as={MdFolder} color={fg} />
                    {project.subGroup?.name}
                  </Flex>
                </Flex>
              </Heading>
              <Button variant="secondary" size="sm" onClick={onOpen}>
                Move
              </Button>
            </Card>
          </Flex>
        )}
        <FormControl>
          <FormLabel>Project ID</FormLabel>
          <CopyCode value={project.id} width="100%" />
        </FormControl>
      </SettingsSection>
      <UpdateGroupSubgroupModal
        onClose={onClose}
        isOpen={isOpen}
        project={project}
      />
    </>
  );
};
