import { Button, Stack, Text } from "@chakra-ui/react";
import { AnalyticsEvent, useQueryParams, useTrack } from "@zeet/web-ui";
import React, { useEffect } from "react";

export const ConfirmationPage: React.FC = () => {
  const { track } = useTrack();
  const query = useQueryParams();
  const message = query.get("message");
  const next = query.get("next");

  useEffect(() => {
    if (message) {
      track(AnalyticsEvent.CONFIRMATION_PAGE, { message });
    }
  }, [track, message]);

  const messageDisplay = message || "Success!";
  const nextLocation = next || "/dashboard";

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      minHeight="inherit"
      flexDirection="column"
    >
      <Text fontWeight="bold" fontSize="1.3rem">
        {messageDisplay}
      </Text>
      <Button
        onClick={() => {
          window.location.href = nextLocation;
        }}
        colorScheme="brand"
      >
        Continue to Dashboard
      </Button>
    </Stack>
  );
};
