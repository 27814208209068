import { CheckCircleIcon, QuestionIcon } from "@chakra-ui/icons";
import { BoxProps, Flex, ListItem, Text, Tooltip } from "@chakra-ui/react";
import { Badge } from "@zeet/web-ui";
import React from "react";

export const LineItem: React.FC<
  { title: string; desc?: string; isNew?: true; helper?: string } & BoxProps
> = ({ title, desc, isNew, ml, helper }) => {
  return (
    <ListItem ml={ml}>
      <Flex alignItems="center">
        <CheckCircleIcon color="success" fontSize="1.5rem" mt={[1]} mr={3} />
        <Flex flexDirection="column">
          <Text as="h2" fontSize="1rem">
            {title}{" "}
            {helper && (
              <Tooltip
                placement="top"
                hasArrow
                aria-label={`${title} - helper text`}
                label={helper}
              >
                <QuestionIcon color="inherit" fontSize="1rem" mt={-1} ml={1} />
              </Tooltip>
            )}{" "}
            {isNew && (
              <Badge colorScheme="brand" ml={1} mt={-1}>
                New
              </Badge>
            )}
          </Text>
          {desc && (
            <Text fontSize="1rem" opacity={0.6} as="h3">
              {desc}
            </Text>
          )}
        </Flex>
      </Flex>
    </ListItem>
  );
};
