import { IconButtonProps as ChakraIconButtonProps } from "@chakra-ui/react";
import { forwardRef } from "react";
import { useColors } from "..";
import { Button, ButtonProps } from "./Button";

export interface IconButtonProps extends ButtonProps {
  "aria-label": ChakraIconButtonProps["aria-label"];
  icon: ChakraIconButtonProps["icon"];
  isActive?: boolean;
}

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ icon, isActive = false, size, ...buttonProps }, ref) => {
    const { bg, fg3 } = useColors();
    const activeProps = isActive ? { backgroundColor: bg, color: fg3 } : {};

    const getMinW = (size: ButtonProps["size"]) => {
      if (size === "xs") return "6";
      if (size === "sm") return "8";
      if (size === "md") return "10";
      if (size === "lg") return "12";
      return "10";
    };

    return (
      <Button
        padding="0"
        minW={getMinW(size)}
        size={size}
        {...activeProps}
        {...buttonProps}
        ref={ref}
      >
        {icon}
      </Button>
    );
  }
);
