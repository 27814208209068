import {
  BlueprintType,
  GitSourceIntegration,
} from "@zeet/web-api/dist/graphql";
import {
  BlueprintType as BlueprintTypeV2,
  ContainerRegistrySourceInput,
  GitSourceInput,
  GitSourceIntegrationInput,
  HelmRepositorySourceInput,
  TerraformModuleSourceInput,
} from "@zeet/web-api/dist/graphqlv1";

import {
  BlueprintSlug,
  BridgeBlueprint,
  BridgeBlueprintType,
  LegacyBlueprintType,
} from "@zeet/web-ui";
import { useState } from "react";
import { SourceEditor } from "./SourceEditor";
import { SourcePreview } from "./SourcePreview";

export enum SourceKind {
  GitHub = "GitHub",
  GitLab = "GitLab",
  Bitbucket = "Bitbucket",
  PublicGitRepo = "Public git repo",
  TerraformModule = "Terraform Module",
  HelmRepository = "Helm Repository",
  DockerHub = "Docker Hub",
  PrivateContainerRegistry = "Private Registry",
}

const GitSources = [
  SourceKind.GitHub,
  SourceKind.GitLab,
  SourceKind.Bitbucket,
  SourceKind.PublicGitRepo,
];

const TerraformModuleSources = [SourceKind.TerraformModule, ...GitSources];

const HelmRepositorySources = [SourceKind.HelmRepository, ...GitSources];

const KubernetesManifestSources = GitSources;

export const ServerlessFunctionSources = GitSources;

export const ContainerSources = [
  ...GitSources,
  SourceKind.DockerHub,
  SourceKind.PrivateContainerRegistry,
];

export const getSourcesForBlueprintType = (type: BlueprintType) => {
  const sources = {
    [BlueprintType.Helm]: HelmRepositorySources,
    [BlueprintType.Terraform]: TerraformModuleSources,
    [BlueprintType.KubernetesManifest]: KubernetesManifestSources,
  };
  return sources[type];
};

export const getSourcesForBlueprint = (
  blueprint: BridgeBlueprint
): SourceKind[] => {
  const gitSourceBlueprints: BridgeBlueprintType[] = [
    BlueprintTypeV2.AwsSam,
    BlueprintTypeV2.GcpCloudRun,
    BlueprintType.ZeetAwsLambda,
    BlueprintType.ZeetGcpCloudRun,
    BlueprintType.ZeetKubernetes,
    BlueprintType.KubernetesManifest,
  ];
  if (
    blueprint.slug === BlueprintSlug.ServiceContainer ||
    blueprint.slug === BlueprintSlug.JobContainer
  ) {
    return ContainerSources;
  }
  if (gitSourceBlueprints.includes(blueprint.type)) {
    return GitSources;
  }
  if (blueprint.type === BlueprintType.Helm) {
    return HelmRepositorySources;
  }
  if (
    blueprint.type === BlueprintType.Terraform ||
    blueprint.type === LegacyBlueprintType.Terraform
  ) {
    return TerraformModuleSources;
  }
  return [];
};

const makeGitSourceIntegrationInput = (
  integration?: GitSourceIntegration | null
): GitSourceIntegrationInput => {
  return {
    githubInstallationId: integration?.githubInstallationID,
    gitlabIntegrationId: integration?.gitlabIntegrationID,
    bitbucketIntegrationId: integration?.bitbucketIntegrationID,
  };
};

export const makeSourceFromBlueprint = (
  blueprint: BridgeBlueprint
): Source | undefined => {
  if (blueprint.configuration?.__typename === "BlueprintHelmConfiguration") {
    const source = blueprint.configuration.source;
    return {
      kind: SourceKind.HelmRepository,
      helmRepository: source?.helmRepository
        ? {
            chart: source.helmRepository.chart,
            version: source.helmRepository.version,
            repositoryUrl: source.helmRepository.repositoryURL,
          }
        : undefined,
      git: source?.git
        ? {
            repository: source.git.repository,
            ref: source.git.ref,
            path: source.git.path,
            integration: makeGitSourceIntegrationInput(
              source?.git?.integration
            ),
          }
        : undefined,
      containerRegistry: source?.containerRegistry
        ? {
            registryUrl: source?.containerRegistry?.registryURL,
            repository: source?.containerRegistry?.repository ?? "",
            registryId: source?.containerRegistry?.registryID,
            tag: source?.containerRegistry?.tag ?? "latest",
          }
        : undefined,
    };
  }
  if (
    blueprint.configuration?.__typename === "BlueprintTerraformConfiguration"
  ) {
    const source = blueprint.configuration.moduleSource;
    return {
      kind: SourceKind.TerraformModule,
      terraformModule: source
        ? {
            source: source.source,
            version: source.version,
            integration: {
              git: makeGitSourceIntegrationInput(source?.integration?.git),
            },
          }
        : undefined,
    };
  }
  if (
    blueprint.configuration?.__typename === "BlueprintManifestConfiguration"
  ) {
    const source = blueprint.configuration.source;
    return {
      kind: SourceKind.PublicGitRepo,
      git: source?.git
        ? {
            repository: source.git.repository,
            ref: source.git.ref,
            path: source.git.path,
            integration: makeGitSourceIntegrationInput(source?.git.integration),
          }
        : undefined,
    };
  }
  if (blueprint?.source?.containerRegistry) {
    return {
      kind: SourceKind.DockerHub,
      containerRegistry: blueprint.source.containerRegistry,
    };
  }
  if (blueprint?.source?.git) {
    return {
      kind: SourceKind.PublicGitRepo,
      git: {
        repository: blueprint.source.git.repository,
        ref: blueprint.source.git.ref,
        path: blueprint.source.git.path,
      },
    };
  }
  return undefined;
};

export type Source = {
  kind: SourceKind;
  containerRegistry?: ContainerRegistrySourceInput;
  git?: GitSourceInput;
  helmRepository?: HelmRepositorySourceInput;
  terraformModule?: TerraformModuleSourceInput;
};

export interface SourceProps {
  validSources?: SourceKind[];
  onSourceChange?: (source: Source) => void;
  onSourceDelete?: () => void;
  source?: Source;
  isSourceLoading?: boolean;
}

export const SourceManager = (props: SourceProps) => {
  const [isEditing, setIsEditing] = useState(!props.source);
  const [currentSource, setCurrentSource] = useState(props.source);
  const { isSourceLoading } = props;

  const onSourceChange = (source: Source) => {
    setCurrentSource(source);
    props.onSourceChange?.(source);
    setIsEditing((value) => !value);
  };

  const toggleSourceEditing = () => {
    setIsEditing((value) => !value);
  };

  if (isEditing && !isSourceLoading) {
    return (
      <SourceEditor
        {...props}
        source={currentSource}
        onSourceChange={onSourceChange}
        onSourceEditClose={toggleSourceEditing}
      />
    );
  }

  return (
    <SourcePreview
      {...props}
      source={currentSource}
      onSourceEditIntent={toggleSourceEditing}
      onSourceDelete={props.onSourceDelete}
    />
  );
};
