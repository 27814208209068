import { SearchIcon } from "@chakra-ui/icons";
import { IconInput, IconInputProps } from "@zeet/web-ui";
import { ChangeEvent, forwardRef, useState } from "react";
import { useListViewContext } from "./Provider";

export const useListViewFilterState = () => {
  const [filterValue, setFilterValue] = useState("");
  const handleFilterChange = (event: ChangeEvent<HTMLInputElement>) =>
    setFilterValue(event.target.value);

  return { filterValue, handleFilterChange };
};

export const ListViewFilter = forwardRef<
  HTMLInputElement,
  Omit<IconInputProps, "IconComponent">
>(({ inputProps, ...rest }, ref) => {
  const { filterValue, handleFilterChange } = useListViewContext();

  return (
    <IconInput
      {...rest}
      IconComponent={SearchIcon}
      inputProps={{
        placeholder: "Search...",
        value: filterValue,
        onChange: handleFilterChange,
        id: "list-view-filter",
        ...inputProps,
      }}
      ref={ref}
    />
  );
});
