import { Tooltip } from "@chakra-ui/react";
import { Link } from "@zeet/web-ui";

interface CloudResourceIdCellProps {
  fullId: string;
  simpleId: string;
  resourceUrl: string;
}

export const CloudResourceIdCell = ({
  fullId,
  simpleId,
  resourceUrl,
}: CloudResourceIdCellProps) => {
  return (
    <Tooltip label={`Full ARN: ${fullId}`} aria-label="endpoint">
      <Link
        maxWidth="250px"
        overflow="hidden"
        whiteSpace="nowrap"
        isExternal
        to={resourceUrl}
      >
        {simpleId}
      </Link>
    </Tooltip>
  );
};
