import {
  Alert,
  AlertIcon,
  Button,
  Divider,
  Flex,
  Heading,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import {
  CloudProvider,
  RepoDetailFragment,
  useUpdateProjectSettingsMutation,
} from "@zeet/web-api/dist/graphql";
import {
  Card,
  FormSelect,
  Link,
  Tooltip,
  useColors,
  useCurrentTeamUser,
  useCurrentUser,
} from "@zeet/web-ui";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { CloudCostEstimate } from "~/components/CloudCost";
import { shouldDisplayError } from "../util";
import { AdvancedReplicationSettings } from "./ReplicationAdvanced";

type Size = {
  size: string;
  name: string;
  longName?: string;
  text: string;
  price?: number;
  custom?: boolean;
  cpu: string;
  memory: string;
  gpu?: number;
  dedicated?: boolean;
};

const sizeOptions: Record<string, Size> = {
  Tiny: {
    size: "Tiny",
    name: "Spot Tiny",
    longName: "Spot Tiny - Shared Resources",
    cpu: "Shared",
    memory: "Shared",
    text: "Shared instance: Has no resource guarantee. Best for development and testing, limited reliability",
    price: 5,
  },
  Small: {
    size: "Small",
    name: "Spot Small",
    cpu: "1",
    memory: "1",
    text: "Small instance: Great for non-production workloads. Powered by AWS Spot with 95+% availability",
    price: 20,
  },
  Medium: {
    size: "Medium",
    name: "Spot Medium",
    cpu: "2",
    memory: "4",
    text: "Medium instance: Best suited for light production workloads. Powered by AWS Spot with 95+% availability!",
    price: 50,
  },
  Large: {
    size: "Large",
    name: "Spot Large",
    cpu: "4",
    memory: "8",
    text: "Large instance: This is packed with power. Powered by AWS Spot with 95+% availability!",
    price: 100,
  },
  GPU: {
    size: "GPU",
    name: "Standard GPU",
    longName: "Standard GPU - 4-Core - 16G Memory - Nvidia T4 GPU",
    cpu: "4",
    memory: "14",
    gpu: 1,
    dedicated: true,
    text: "GPU instance: Powered by the latest generation Nvidia T4 GPU!",
    price: 505,
    custom: true,
  },
  Custom: {
    size: "Custom",
    name: "Spot Custom",
    longName: "Spot Custom Instance",
    cpu: "2",
    memory: "2",
    text: "Custom instance: Pick your own performance! Powered by AWS Spot with 95+% availability!",
    custom: true,
  },
  Dedicated: {
    size: "Dedicated",
    name: "Standard Custom Instance",
    longName: "Standard Custom Instance",
    cpu: "2",
    memory: "2",
    dedicated: true,
    text: "Standard instance: Pick your own performance. Powered by AWS On-Demand with 99.99% availability!",
    custom: true,
  },
};

type SizeType = keyof typeof sizeOptions;

const getSize = (size: SizeType): Size => {
  return sizeOptions[size]!;
};

const stripRam = (ram?: string | null): string => {
  return ram ? ram.slice(0, ram.length - 1) : "";
};

type FormInputs = {
  size: SizeType;
  cpu: string;
  memory: string;
};

export const ReplicationSettings: React.FC<{ repo: RepoDetailFragment }> = ({
  repo,
}) => {
  const user = useCurrentUser();

  const defaultShowAdvanced =
    (repo?.replication && repo.replication?.length > 1) ||
    repo?.ephemeralStorage ||
    repo?.gpu ||
    repo?.tpu ||
    repo?.autoscaling ||
    repo?.owner?.advanced ||
    user?.advanced;

  const [showAdvanced, setShowAdvanced] = useState(defaultShowAdvanced);

  return (
    <Card>
      <Flex flexDir="column" px={6} pt={6}>
        <Heading mb={3} size="md">
          <Flex justifyContent="space-between">
            <Text>{"Resources & Replication"}</Text>
            <Flex>
              <Button
                style={{
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                  borderRight: "1px solid whiteAlpha.900",
                  opacity: showAdvanced ? "0.5" : "1.0",
                  height: "100%",
                }}
                onClick={() => {
                  setShowAdvanced(false);
                }}
              >
                Simple
              </Button>
              <Button
                style={{
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  opacity: showAdvanced ? "1.0" : "0.5",
                  height: "100%",
                }}
                onClick={() => {
                  setShowAdvanced(true);
                }}
              >
                Advanced
              </Button>
            </Flex>
          </Flex>
        </Heading>

        <Text fontSize="sm" mb={3}>
          {
            "Need to scale horizontally? Add more replicas! Need to scale vertically? You can upgrade your project right here right now!"
          }
        </Text>
      </Flex>
      {showAdvanced ? (
        <AdvancedReplicationSettings repo={repo} />
      ) : (
        <SimpleReplicationSettings repo={repo} />
      )}
    </Card>
  );
};

const SimpleReplicationSettings: React.FC<{ repo: RepoDetailFragment }> = ({
  repo,
}) => {
  const toast = useToast();
  const currentTeamUser = useCurrentTeamUser();

  const { bg } = useColors();

  const [changed, setChanged] = useState(false);

  let defaultSize = "Tiny";
  const curSize = Object.keys(sizeOptions)
    .map(getSize)
    .filter(
      (o) =>
        o.cpu === repo.cpu &&
        o.memory === stripRam(repo.memory) &&
        (o.dedicated || false) === (repo.dedicated || false) &&
        o.gpu === repo.gpu
    );
  if (curSize.length > 0) defaultSize = curSize[0]?.size ?? defaultSize;
  else {
    if (repo.gpu) {
      defaultSize = "GPU";
    } else if (repo.dedicated) {
      defaultSize = "Dedicated";
    } else if (repo.cpu) {
      defaultSize = "Custom";
    }
  }

  const { handleSubmit, register, watch, setValue } = useForm<FormInputs>({
    defaultValues: {
      size: defaultSize,
    },
  });

  const [replicas, setReplicas] = useState(
    repo.replication?.[0]?.replicas || 0
  );

  const size = watch("size") as SizeType;
  const cpu = parseFloat(watch("cpu")) || 0;
  const ram = parseFloat(watch("memory")) || 0;

  const [updateSettings, { error, loading, data }] =
    useUpdateProjectSettingsMutation({
      onCompleted: (data) => {
        setChanged(false);
        if (data) {
          toast({
            title: "Performance Settings Saved",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      },
      onError: (error) => {
        console.error(error);
        setChanged(false);
        if (error)
          toast({
            title: "Failed to save",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
      },
    });

  const onSubmit = (): void => {
    let cpuInput = cpu.toString();
    let memoryInput = ram.toString() + "G";
    let replicaInput = replicas || 0;
    if (size === "Tiny") {
      cpuInput = "";
      memoryInput = "";
      replicaInput = 1;
    }

    updateSettings({
      variables: {
        input: {
          id: repo.id,
          cpu: cpuInput,
          memory: memoryInput,
          gpu: {
            count: sizeOptions[size]?.gpu || 0,
          },
          dedicated: sizeOptions[size]?.dedicated || false,
          replication: [
            {
              replicas: replicaInput,
              region: "",
            },
          ],
        },
      },
    });
  };

  useEffect(() => {
    if (size) {
      if (sizeOptions[size]?.custom) {
        setValue("cpu", repo.cpu || sizeOptions[size]?.cpu || "");
        setValue(
          "memory",
          stripRam(repo.memory) || sizeOptions[size]?.memory || ""
        );
      } else {
        setValue("cpu", sizeOptions[size]?.cpu ?? "");
        setValue("memory", sizeOptions[size]?.memory ?? "");
      }
    }
  }, [repo, setValue, size]);

  useEffect(() => {
    if (size && cpu && ram) {
      if (
        size !== defaultSize ||
        repo.cpu !== cpu.toString() ||
        stripRam(repo.memory) !== ram.toString()
      ) {
        setChanged(true);
      }
    }
  }, [cpu, defaultSize, ram, repo, size]);

  const renderOpt = (opt): string => {
    if (size === opt.size) {
      return opt.name;
    }

    if (opt.longName) {
      return opt.longName;
    }

    return `${opt.name} - ${opt.cpu}-Core - ${opt.memory}G Memory`;
  };

  return (
    <Flex
      as="form"
      onSubmit={(e) => {
        e.stopPropagation();
        e.preventDefault();
        handleSubmit(onSubmit)(e);
      }}
      flexDir="column"
    >
      <Flex flexDir="column" px={6} pb={4}>
        <Flex flexDirection="column">
          <Text mb={3} display="inline-flex" alignItems="center">
            Your project is deployed to
            <Text as="span" fontWeight="bold" ml={1}>
              {repo?.cluster?.name ? (
                <Link
                  to={`/${repo?.owner.login}/console/clusters/${repo?.cluster.id}`}
                >
                  {`${repo?.cluster?.name} - ${repo?.cluster?.region}`}
                </Link>
              ) : (
                repo.replication?.[0]?.region
              )}
            </Text>
            <Tooltip text="Want to deploy into another region? Hit us up in Support!" />
          </Text>
        </Flex>
        <Stack isInline>
          <Flex flex={1} alignItems="center">
            <Text mr={4}> Size </Text>
            <FormSelect
              {...register("size")}
              flex={1}
              options={[
                "Tiny",
                "Small",
                "Medium",
                "Large",
                "GPU",
                "Custom",
                "Dedicated",
              ].map((size) => {
                return { value: size, label: renderOpt(sizeOptions[size]) };
              })}
            />
          </Flex>
          <Flex flex={1} alignItems="center" ml={4}>
            <Text mr={4} whiteSpace="nowrap">
              CPU Cores
            </Text>
            <Input
              isDisabled={!sizeOptions[size]?.custom}
              {...register("cpu")}
              className="nice-digits"
            />
          </Flex>
          <Flex flex={1} alignItems="center" ml={4}>
            <Text mr={4} whiteSpace="nowrap">
              Memory (GB)
            </Text>
            <Input
              isDisabled={!sizeOptions[size]?.custom}
              {...register("memory")}
              className="nice-digits"
            />
          </Flex>
          <Flex flex={1} alignItems="center" ml={4}>
            <Text mr={4}> Replicas </Text>
            <NumberInput
              isDisabled={size === "Tiny"}
              min={0}
              max={currentTeamUser?.isTeam ? 420 : 100}
              keepWithinRange={false}
              value={size === "Tiny" ? 1 : replicas}
              onChange={(_, v) => {
                setChanged(true);
                if (v < 0) {
                  v = 0;
                }
                setReplicas(v);
              }}
              flexGrow={1}
            >
              <NumberInputField name="replicas" />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </Flex>
        </Stack>

        <Text mt={4}>
          {repo?.cluster?.cloudProvider === CloudProvider.Aws
            ? sizeOptions[size]?.text
            : sizeOptions[size]?.text.replace("AWS Spot", "GCP Preemptible")}
        </Text>
        <Text mt={4}>
          {
            "Use at least 3 replicas and upgrade to bigger sizes for production projects"
          }
        </Text>
        {shouldDisplayError(error, data) && (
          <Alert status="error" mt={4}>
            <AlertIcon />
            {error?.message}
          </Alert>
        )}
        <Flex mt={4} alignItems="center">
          <CloudCostEstimate />
        </Flex>
      </Flex>
      <Divider />
      <Flex bg={bg} as="footer" py={3} px={6} align="center">
        <Button
          type="submit"
          isDisabled={!changed}
          isLoading={loading}
          colorScheme="brand"
          px={6}
          size="sm"
          ml="auto"
        >
          {"Save"}
        </Button>
      </Flex>
    </Flex>
  );
};
