import { Button, PropsOf, useColorModeValue } from "@chakra-ui/react";
import { useColors } from "@zeet/web-ui";
import React from "react";
import { FaGithub } from "react-icons/fa";

export const GitHubButton: React.FC<PropsOf<typeof Button>> = ({
  children,
  ...restButtonProps
}) => {
  const color = useColorModeValue("blackAlpha", "whiteAlpha");
  const { bg } = useColors();

  return (
    <Button
      size="lg"
      colorScheme={color}
      bg={`${color}.900`}
      variant="solid"
      color={`${bg} !important`}
      leftIcon={<FaGithub />}
      iconSpacing="4"
      {...restButtonProps}
    >
      {children}
    </Button>
  );
};
