import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuItem,
  MenuList,
  Stack,
  useToast,
} from "@chakra-ui/react";
import {
  ClusterState,
  ContainerResourcesSpecInput,
  GitSubmodulesType,
  RepoDetailFragment,
  UserDeployTargetsQuery,
  useUpdateProjectSettingsMutation,
  useUserDeployTargetsQuery,
} from "@zeet/web-api/dist/graphql";
import {
  CenterLoading,
  FormSelect,
  useCurrentTeamUser,
  ZError,
} from "@zeet/web-ui";
import { default as React, useEffect, useState } from "react";
import { NIL as NIL_UUID } from "uuid";
import { FormCard } from "../../FormCard";
import { shouldDisplayError } from "../util";
import { ZFormLabel } from "./Build";
import { ClusterOption } from "./ClusterOption";
import { ResourcesForm } from "./Resources";

interface IClusterDeployTargetInput {
  onClickFunction: (
    cluster: NonNullable<UserDeployTargetsQuery["user"]["clusters"]>[0]
  ) => void;
  currentClusterId: string | undefined;
  clusters: UserDeployTargetsQuery["user"]["clusters"];
}

const ClusterDeployTargetInput: React.FC<
  MenuButtonProps & IClusterDeployTargetInput
> = ({ onClickFunction, currentClusterId, clusters, ...props }) => {
  return (
    <Menu matchWidth>
      <MenuButton as={Button} rightIcon={<ChevronDownIcon />} {...props}>
        <ClusterOption clusterId={currentClusterId} clusters={clusters} />
      </MenuButton>
      <MenuList>
        {clusters?.map((c) => (
          <MenuItem key={c.id} onClick={() => onClickFunction(c)}>
            <ClusterOption clusterId={c.id} clusters={clusters} />
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export const BuildResourceSettingsBox: React.FC<{
  repo: RepoDetailFragment;
}> = ({ repo }) => {
  const toast = useToast();

  const [updateSettings, { error, loading, data: updateProjectData }] =
    useUpdateProjectSettingsMutation({
      onCompleted: (data) => {
        if (data) {
          toast({
            title: "Build Resources Saved",
            status: "success",
            duration: 2500,
            isClosable: true,
          });
        }
      },
    });

  const [input, setInput] = useState<ContainerResourcesSpecInput>();
  const [customClusterId, setCustomClusterId] = useState<string>("");
  const onClickFunction = (
    cluster: NonNullable<UserDeployTargetsQuery["user"]["clusters"]>[0]
  ) => {
    setCustomClusterId(cluster.id);
  };
  const currentTeamUser = useCurrentTeamUser();

  const { data, loading: userDeployTargetsLoading } = useUserDeployTargetsQuery(
    {
      variables: {
        id: currentTeamUser.id,
      },
    }
  );

  const onSubmit = () => {
    updateSettings({
      variables: {
        input: {
          id: repo.id,
          buildResources: input,
          pipelineClusterID: customClusterId,
        },
      },
    });
  };

  useEffect(() => {
    if (repo.pipelineCluster?.id) {
      setCustomClusterId(repo.pipelineCluster?.id);
    } else {
      setCustomClusterId(NIL_UUID);
    }
  }, [repo.pipelineCluster?.id]);

  if (userDeployTargetsLoading) {
    return <CenterLoading />;
  }

  return (
    <FormCard isLoading={loading} onSubmit={onSubmit} title="Build Resources">
      <ResourcesForm
        spec={repo.buildResources}
        onChange={setInput}
        showSpot={false}
      />
      <Stack>
        <Flex alignItems="center" mt={4}>
          <ZFormLabel>Build Cluster</ZFormLabel>
          <ClusterDeployTargetInput
            onClickFunction={onClickFunction}
            currentClusterId={customClusterId}
            width="calc(100% - 108px)"
            clusters={
              data?.user.clusters && [
                {
                  id: NIL_UUID,
                  name: "zeet managed builder",
                  state: ClusterState.Healthy,
                  updatedAt: new Date(),
                  createdAt: new Date(),
                },
                ...(data?.user.clusters || []),
              ]
            }
          />
        </Flex>
      </Stack>
      {shouldDisplayError(error, updateProjectData) && (
        <ZError mt={2} error={error} />
      )}
    </FormCard>
  );
};

export const AdvancedBuildSettingsBox: React.FC<{
  repo: RepoDetailFragment;
}> = ({ repo }) => {
  const toast = useToast();

  const [updateSettings, { error, loading, data: updateProjectData }] =
    useUpdateProjectSettingsMutation({
      onCompleted: (data) => {
        if (data) {
          toast({
            title: "Advanced Build Settings Saved",
            status: "success",
            duration: 2500,
            isClosable: true,
          });
        }
      },
    });

  const [gitSubmodules, setGitSubmodules] = useState<GitSubmodulesType>(
    repo.gitSubmodules || GitSubmodulesType.None
  );

  const onSubmit = () => {
    updateSettings({
      variables: {
        input: {
          id: repo.id,
          gitSubmodules: gitSubmodules,
        },
      },
    });
  };

  return (
    <FormCard
      isLoading={loading}
      onSubmit={onSubmit}
      title="Advanced Build Settings"
    >
      <Stack>
        <Flex mt={4}>
          <ZFormLabel>Git Submodules Cloning</ZFormLabel>
          <FormSelect
            value={gitSubmodules}
            onChange={(e) => setGitSubmodules(e.target.value)}
            options={[
              {
                value: GitSubmodulesType.None,
                label: "None - No submodules will be cloned",
              },
              {
                value: GitSubmodulesType.Recursive,
                label: "Recursive - All submodules will be cloned recursively",
              },
            ]}
          />
        </Flex>
      </Stack>
      {shouldDisplayError(error, updateProjectData) && (
        <ZError mt={2} error={error} />
      )}
    </FormCard>
  );
};
