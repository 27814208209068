import { Button, Flex, FlexProps, Text } from "@chakra-ui/react";
import {
  GenericWorkflowStepActionType,
  WorkflowRunsQuery,
  WorkflowRunStatus,
  WorkflowRunStepStatus,
} from "@zeet/web-api/dist/graphqlv1";
import { Link, ZWarn } from "@zeet/web-ui";
import { useMemo, useState } from "react";
import { useWorkflowOperations } from "./useWorkflowOperations";

type WorkflowRunsType = NonNullable<
  NonNullable<NonNullable<WorkflowRunsQuery["team"]>["project"]>["workflow"]
>["runs"]["nodes"];

const getApprovalStep = (nodes: WorkflowRunsType | undefined) => {
  const orchestrationDeployStep = nodes?.[0]?.steps?.find(
    (s) => s?.action === GenericWorkflowStepActionType.OrchestrationDeploy
  );

  if (orchestrationDeployStep?.__typename === "DeployRunStep") {
    return orchestrationDeployStep.workflowRun?.steps?.find(
      (s) => s?.action === GenericWorkflowStepActionType.DriverApprove
    );
  }
};

export const isApprovalStepRunning = (
  workflowRuns: WorkflowRunsType | undefined
): boolean => {
  return (
    getApprovalStep(workflowRuns)?.status === WorkflowRunStepStatus.Running &&
    workflowRuns?.[0]?.status !== WorkflowRunStatus.Aborted
  );
};

interface ApprovalBannerProps extends FlexProps {
  data: WorkflowRunsQuery | undefined;
  projectID: string;
  planLink: string;
}

export const WorkflowApprovalBanner = ({
  data,
  projectID,
  planLink,
  ...rest
}: ApprovalBannerProps) => {
  const workflowRuns = data?.team?.project?.workflow?.runs?.nodes;

  const { abort, approveWorkflowRunStep, abortLoading, approveLoading } =
    useWorkflowOperations();
  const approvalWorkflowRunStep = useMemo(() => {
    return getApprovalStep(workflowRuns);
  }, [workflowRuns]);

  const [approveActionExecuted, setApproveActionExecuted] = useState(false);
  const [abortActionExecuted, setAbortActionExecuted] = useState(false);
  const isAbortLoading =
    abortLoading ||
    (abortActionExecuted &&
      getApprovalStep(workflowRuns)?.status === WorkflowRunStepStatus.Running);

  const isApproveLoading =
    approveLoading ||
    (approveActionExecuted &&
      getApprovalStep(workflowRuns)?.status === WorkflowRunStepStatus.Running);

  return (
    <ZWarn {...rest}>
      <Flex alignItems="center" width="100%" justifyContent="space-between">
        <Flex>
          <Text>
            Manual approval of the plan is required to finish deployment. You
            may view the plan{" "}
            <Link textDecor="underline" to={planLink}>
              here
            </Link>
          </Text>
        </Flex>
        <Flex>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              if (workflowRuns?.[0]?.id) {
                setAbortActionExecuted(true);
                abort(projectID, workflowRuns[0].id);
              }
            }}
            isLoading={isAbortLoading}
          >
            Cancel
          </Button>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              if (workflowRuns?.[0]?.id && approvalWorkflowRunStep?.id) {
                setApproveActionExecuted(true);
                approveWorkflowRunStep(
                  projectID,
                  approvalWorkflowRunStep.id,
                  workflowRuns[0].id
                );
              }
            }}
            ml={4}
            isLoading={isApproveLoading}
          >
            Approve
          </Button>
        </Flex>
      </Flex>
    </ZWarn>
  );
};
