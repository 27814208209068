import {
  Button,
  Flex,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useWindowSize } from "@zeet/web-ui";
import Emoji from "a11y-react-emoji";
import React from "react";
import Confetti from "react-confetti";
import Countdown from "react-countdown";

interface OnboardSuccessModalProps {
  isOpen: boolean;
  onClose: (stay: boolean) => void;
}

export const OnboardSuccessModal: React.FC<OnboardSuccessModalProps> = ({
  onClose,
  isOpen,
}) => {
  const { width, height } = useWindowSize();

  return (
    <Modal isOpen={isOpen} size="lg" isCentered onClose={() => onClose(false)}>
      <ModalOverlay />
      {isOpen && (
        <Confetti width={width} height={height} style={{ zIndex: 1400 }} />
      )}
      <ModalContent>
        <ModalHeader fontSize={30}>
          Congratulations <Emoji symbol="🎉" label="tada" />{" "}
        </ModalHeader>
        <Flex ml={6}>
          <Text fontSize={20}>Your Project is Successfully Deployed</Text>
        </Flex>
        <ModalFooter>
          <Button
            ml={2}
            colorScheme="brand"
            onClick={() => onClose(true)}
            minWidth="150px"
            justifyContent="center"
            display="flex"
          >
            Continue Editing
          </Button>
          <Button
            ml={2}
            colorScheme="brand"
            onClick={() => onClose(false)}
            minWidth="150px"
            justifyContent="flex-start"
            display="flex"
          >
            Overview{" "}
            <Countdown
              date={Date.now() + 8000}
              onComplete={() => onClose(false)}
              renderer={({ seconds }) => <span> {` (${seconds}s)`}</span>}
            />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
