import { AddIcon, SettingsIcon, UpDownIcon } from "@chakra-ui/icons";
import {
  Box,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { CenterLoading, useColors } from "@zeet/web-ui";
import React from "react";
import { Link } from "react-router-dom";

interface Props {
  items: Array<{ link: string; name: string; settingsLink?: string }>;
  createItem?: { link?: string; onClick?: () => void; text: string };
  loading: boolean;
  title?: string;
  filter?: string;
  filterOnChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Switcher = ({
  items,
  loading,
  createItem,
  title,
  filter,
  filterOnChange,
}: Props) => {
  const { bg4, bgHover } = useColors();
  return (
    <Menu flip placement="bottom">
      <MenuButton
        variant="ghost"
        size="xs"
        as={IconButton}
        icon={<UpDownIcon />}
      />
      <MenuList
        // to prevent misaligned menu title and menu items https://github.com/chakra-ui/chakra-ui/discussions/5268
        sx={{
          ".chakra-menu__group__title": { mx: 0, px: 3 },
          ".chakra-menu__menuitem": { px: 3 },
        }}
        maxH={96}
        overflowY="auto"
        maxW={224}
        width="100%"
        zIndex={10000}
      >
        <MenuGroup title={title}>
          <>
            {filterOnChange && (
              <Box padding="2" position="sticky" top={-2} zIndex={10}>
                <Input
                  borderRadius="md"
                  autoFocus
                  placeholder={`Filter ${title?.toLowerCase()}`}
                  size="sm"
                  value={filter}
                  onChange={filterOnChange}
                />
              </Box>
            )}
            {loading && <CenterLoading />}
            {!loading &&
              items.map((item, i) => (
                <Link to={item.link} key={i}>
                  <MenuItem
                    fontSize={14}
                    {...(item.settingsLink && {
                      flexDir: "row-reverse",
                      iconSpacing: 0,
                      icon: (
                        <IconButton
                          as={Link}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          ml="auto"
                          bg=""
                          size="xs"
                          aria-label="settings"
                          icon={<SettingsIcon />}
                          to={item.settingsLink}
                        />
                      ),
                    })}
                  >
                    {item.name}
                  </MenuItem>
                </Link>
              ))}
          </>
        </MenuGroup>
        {createItem && (
          <Box position="sticky" bottom={-2} pb="2" bg={bg4}>
            <MenuDivider />

            <MenuItem
              iconSpacing={0}
              as={createItem.link ? Link : undefined}
              to={createItem.link}
              onClick={createItem.onClick}
              bg={bg4}
              _hover={{ bg: bgHover }}
              icon={
                <IconButton
                  pointerEvents="none"
                  aria-label=""
                  ml="auto"
                  bg=""
                  size="xs"
                  icon={<AddIcon />}
                />
              }
              flexDir="row-reverse"
            >
              <Text fontSize="sm">{createItem.text}</Text>
            </MenuItem>
          </Box>
        )}
      </MenuList>
    </Menu>
  );
};

export default Switcher;
