import jwtDecode from "jwt-decode";
import React, { useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import { useLocalStorage } from "../../hooks/useLocalStorage";

//access token
const accessTokenKey = "dockit_jwt";

const authContext = React.createContext<{
  token?: string;
  setToken: (string?) => void;
}>({
  setToken: () => {},
});

export const AuthProvider: React.FC<React.PropsWithChildren> = (props) => {
  const [token, setToken] = useLocalStorage(accessTokenKey);

  return (
    <authContext.Provider
      value={{
        token,
        setToken,
      }}
      {...props}
    />
  );
};

export function useAccessToken(): [
  string | undefined,
  (update?: string) => void
] {
  const { token, setToken } = useContext(authContext);
  return [token, setToken];
}

//temp token
const tempTokenKey = "zeet_anon_id";

interface UserJWT {
  id: string;
  name: string;
  avatar: string;
}

export function getOrCreateTempToken(): string {
  let tempToken = localStorage.getItem(tempTokenKey);

  if (!tempToken || !tempToken.startsWith("temp_")) {
    tempToken = `temp_${uuidv4()}`;
    localStorage.setItem(tempTokenKey, tempToken);
  }

  return tempToken;
}

export function removeTempToken(): void {
  localStorage.removeItem(tempTokenKey);
}

export function getUserId(): string {
  const accessToken = localStorage.getItem(accessTokenKey);
  if (accessToken) {
    try {
      return (jwtDecode(accessToken) as UserJWT).id;
    } catch (err) {
      console.error("failed to decode at", accessToken);
    }
  }
  return "";
}
