import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from "@chakra-ui/react";
import { useColors } from "@zeet/web-ui";
import { FieldValues } from "react-hook-form";
import { InputContainer, InputProps } from "./InputContainer";

interface IntegerInputProps<TFormData> extends InputProps<TFormData> {
  placeholder?: string;
}

export const IntegerInput = <TFormData extends FieldValues>({
  placeholder,
  ...props
}: IntegerInputProps<TFormData>) => {
  const { bg } = useColors();
  return (
    <InputContainer
      {...props}
      render={(p) => (
        <NumberInput {...p} min={0}>
          <NumberInputField placeholder={placeholder} bg={bg} />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      )}
    />
  );
};
