import { BoxProps, Flex } from "@chakra-ui/react";
import { useWindowSize } from "@zeet/web-ui";
import React from "react";

export const FlexContent: React.FC<BoxProps> = (props) => {
  const { width } = useWindowSize();

  return (
    <Flex
      width="100%"
      maxW={
        width && width > 1920 ? "calc(1600px + 2rem)" : "calc(1000px + 2rem)"
      }
      justifyContent="center"
      margin="auto"
      {...props}
    ></Flex>
  );
};
