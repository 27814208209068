import { As, Avatar, Box, Flex, Icon, MenuItem, Text } from "@chakra-ui/react";
import { Link, useTrack } from "@zeet/web-ui";
import React from "react";

export const CreateMenuItem: React.FC<{
  title: string;
  description?: string;
  link: string;
  imageSource?: string;
  icon?: As;
  trackEvent: string;
  currentProductTypeName: string;
  productUnitName: string;
  productSectionName: string;
}> = ({ title, description, link, icon, imageSource, trackEvent }) => {
  const { track } = useTrack();

  return (
    <MenuItem
      onClick={() => {
        track("click_header_new", { type: trackEvent });
      }}
      as={Link}
      to={link}
      paddingInlineStart="8px"
      paddingInlineEnd="8px"
      textDecoration="none !important"
      borderRadius="md"
    >
      <Flex align="center">
        <Flex alignItems="center" mr="12px">
          {icon && <Icon as={icon} boxSize={6} mr={2} />}
          {imageSource && (
            <Avatar
              boxSize={8}
              src={imageSource}
              borderRadius={10}
              bg="#101010"
            />
          )}
        </Flex>
        <Box>
          <Text fontWeight="600" fontSize="14px">
            {title}
          </Text>
          <Text fontSize="12px" opacity="0.7" maxW="300px">
            {description}
          </Text>
        </Box>
      </Flex>
    </MenuItem>
  );
};
