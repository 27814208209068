import { WarningIcon } from "@chakra-ui/icons";
import { Box, Flex, Tooltip } from "@chakra-ui/react";
import {
  CloudProvider,
  useUserDeployTargetsQuery,
} from "@zeet/web-api/dist/graphql";
import { useColors, useCurrentTeamUser } from "@zeet/web-ui";
import { chakraComponents, Select } from "chakra-react-select";

import {
  getCloudOptions,
  getClusterOptions,
} from "../../features/Project/New/ConfigureStep/Targets/ProviderSelectorMenu";
import { ProviderOption } from "../../features/Project/New/context";
import { ProviderLogo } from "./ProviderLogo";

enum ProviderErrors {
  ConnectionIssue = "We're having trouble connecting to your provider.",
  NoProvider = "Please select a provider",
}

export const sortByConnectedStatus = (a: ProviderOption, b: ProviderOption) => {
  return Number(!a.connected) - Number(!b.connected);
};

interface ProviderSelectorProps {
  cloudProviders?: CloudProvider[];
  showClusters?: boolean;
  value?: string;
  onChange?(id?: string): unknown;
  onProviderChange?(option?: ProviderOption | null): unknown;
}

export const CloudProviderSelector: React.FC<ProviderSelectorProps> = ({
  cloudProviders,
  showClusters,
  value,
  onChange,
  onProviderChange,
}) => {
  const { id } = useCurrentTeamUser();
  const { data, loading } = useUserDeployTargetsQuery({
    variables: {
      id,
    },
  });

  const cloudOptions = getCloudOptions(cloudProviders ?? [], data).sort(
    sortByConnectedStatus
  );

  const clusterOptions = showClusters
    ? getClusterOptions(data).sort(sortByConnectedStatus)
    : null;

  const { bg } = useColors();
  return (
    <Flex data-testid="target-provider-selector">
      <Select
        useBasicStyles
        chakraStyles={{
          container: (provided) => ({
            ...provided,
            backgroundColor: bg,
            flex: 1,
          }),
          menuList: (provided) => ({ ...provided, boxShadow: "2xl" }),
        }}
        openMenuOnFocus
        captureMenuScroll={false}
        menuPortalTarget={document.body}
        menuPosition="fixed"
        hasStickyGroupHeaders
        placeholder="Select provider"
        options={[
          {
            label: "Clouds",
            options: cloudOptions ?? [],
          },
          {
            label: "Clusters",
            options: clusterOptions ?? [],
          },
        ]}
        isLoading={loading}
        components={{
          Option: ({ children, ...props }) => (
            <chakraComponents.Option {...props}>
              <Flex alignItems="center" ml="-1">
                <Flex alignItems="center">
                  <ProviderLogo option={props.data} />
                  <Box ml="1">{children}</Box>
                </Flex>
                {!props.data?.connected && (
                  <Tooltip
                    label={`${ProviderErrors.ConnectionIssue} Select to fix this issue.`}
                    placement="right"
                  >
                    <WarningIcon ml="2" color="red.500" />
                  </Tooltip>
                )}
              </Flex>
            </chakraComponents.Option>
          ),
          SingleValue: ({ children, ...props }) => {
            return (
              <chakraComponents.SingleValue {...props}>
                <Flex alignItems="center" ml="-1">
                  <Flex alignItems="center">
                    <ProviderLogo option={props.data} />
                    <Box ml="1">{children}</Box>
                  </Flex>
                  {!props.data?.connected && (
                    <WarningIcon ml="2" color="red.500" />
                  )}
                </Flex>
              </chakraComponents.SingleValue>
            );
          },
        }}
        value={
          cloudOptions.find((o) => o.value === value) ??
          clusterOptions?.find((o) => o.value === value)
        }
        onChange={(v) => {
          onChange?.(v?.value);
          onProviderChange?.(v);
        }}
      />
    </Flex>
  );
};
