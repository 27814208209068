import { IDataRow } from "@zeet/web-ui";
import { FC } from "react";
import CloudIcon from "../../Console/ConsoleClouds/View/CloudIcon";
import { LinkCell } from "./LinkCell";

interface ClusterCloudProviderCellProps {
  clusterCloudProvider: IDataRow["clusterCloudProvider"] | undefined;
}

const getURL = ({
  clusterAWSAccount,
  clusterGCPAccount,
  clusterDOAccount,
  clusterCWAccount,
  clusterLDAccount,
  clusterAzureAccount,
  clusterVultrAccount,
  clusterAWSAccountLink,
  clusterGCPAccountLink,
  clusterDOAccountLink,
  clusterCWAccountLink,
  clusterLDAccountLink,
  clusterAzureAccountLink,
  clusterVultrAccountLink,
}) => {
  if (clusterAWSAccount) return clusterAWSAccountLink;
  if (clusterGCPAccount) return clusterGCPAccountLink;
  if (clusterDOAccount) return clusterDOAccountLink;
  if (clusterCWAccount) return clusterCWAccountLink;
  if (clusterLDAccount) return clusterLDAccountLink;
  if (clusterAzureAccount) return clusterAzureAccountLink;
  if (clusterVultrAccount) return clusterVultrAccountLink;
};

export const ClusterCloudProviderCell: FC<ClusterCloudProviderCellProps> = ({
  clusterCloudProvider,
}) => {
  if (!clusterCloudProvider) return null;
  const url = getURL(clusterCloudProvider);
  const key =
    clusterCloudProvider.clusterAWSAccount ||
    clusterCloudProvider.clusterGCPAccount ||
    clusterCloudProvider.clusterDOAccount ||
    clusterCloudProvider.clusterCWAccount ||
    clusterCloudProvider.clusterLDAccount ||
    clusterCloudProvider.clusterAzureAccount ||
    clusterCloudProvider.clusterVultrAccount;

  return (
    <LinkCell to={url}>
      <CloudIcon
        cloudAccountKey={key}
        cloudProvider={clusterCloudProvider.text}
      />
    </LinkCell>
  );
};
