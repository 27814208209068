import { useUserAllBasicReposQuery } from "@zeet/web-api/dist/graphql";
import { Action, useRegisterActions } from "kbar";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { LanguageIcon } from "../LanguageIcon";
import { repoPath } from "../Project/util";

const userToActions = (user, history): Action[] => {
  const baseActions: Action[] = [
    {
      id: "projects-" + user?.id,
      name: user?.login || "",
      parent: "projects",
    },
    {
      id: "teams-" + user?.id,
      name: `${user?.login}`,
      keywords: `teams ${user?.login} dashboard`,
      parent: "teams",
      perform: () => history.push(`/${user?.login}/console/home`),
    },
  ];

  const projectActions =
    user?.repos?.map((r) => {
      return {
        id: "projects-" + r.id,
        name: `${r.project.name} / ${r.projectEnvironment.name} / ${r.name}`,
        keywords: `projects ${r.owner.login} ${r.name}`,
        parent: `projects-${r.owner.id}`,
        icon: <LanguageIcon height="1rem" width="1rem" language={r.image} />,
        perform: () => history.push(`/${repoPath(r)}`),
      };
    }) || [];

  return [...baseActions, ...projectActions];
};

export const useProjectsActions: () => void = () => {
  // TODO network performance optimization opportunity -- lazy load this
  const history = useHistory();

  const { data } = useUserAllBasicReposQuery();

  const [actions, setActions] = useState<Action[]>([]);

  useEffect(() => {
    const allActions =
      data?.currentUser?.teams
        ?.map((t) => userToActions(t.team?.user, history))
        .reduce((acc, val) => acc.concat(val), []) || [];

    setActions(allActions);
  }, [data, setActions, history]);

  useRegisterActions(actions, [actions]);
};
