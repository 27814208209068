import { Pageview } from "@zeet/web-ui";
import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { ProjectBridge } from "../../ProjectBridge";
import Environment from "../Environment";
import Environments from "../Environments";
import { ProjectEnvProvider, ProjectProvider } from "../Provider";
import {
  LegacyRedirect,
  LegacyRedirectWrapper,
  ProjectEnvRedirect,
} from "../Redirect";

export const ProjectRouter: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <ProjectProvider>
      <Switch>
        <Route path={[`${path}/_default`]}>
          <ProjectEnvRedirect />
        </Route>
        <Route exact path={[path, `${path}/_:tab?`, `${path}/_:tab?/:subTab?`]}>
          <Environments />
        </Route>
        <Route path={`${path}/:envName`}>
          <Switch>
            <Route
              exact
              path={[`${path}/:envName/_:tab?/:subTab?`, `${path}/:envName`]}
            >
              <ProjectEnvProvider>
                <Environment />
              </ProjectEnvProvider>
            </Route>
            <Route
              exact
              path={[`${path}/:envName/:repoName/:tab?/:subTab?/:path*`]}
            >
              <LegacyRedirectWrapper>
                <ProjectEnvProvider>
                  <Pageview pageName="Project" />
                  <ProjectBridge />
                </ProjectEnvProvider>
              </LegacyRedirectWrapper>
            </Route>
          </Switch>
        </Route>
        <Route
          exact
          path={[
            `${path}/metrics(/.*)?`,
            `${path}/settings(/.*)?`,
            `${path}/logs(/.*)?`,
            `${path}/jobs(/.*)?`,
            `${path}/deployments(/.*)?`,
          ]}
        >
          <LegacyRedirect />
        </Route>
      </Switch>
    </ProjectProvider>
  );
};
