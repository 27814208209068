import { Flex, Heading, Text } from "@chakra-ui/react";
import { Button, Container } from ".";

interface NotFoundProps {
  heading: string;
  text: string;
  primaryButtonLink?: string;
  primaryButtonText?: string;
  secondaryButtonLink?: string;
  secondaryButtonText?: string;
}

export const NotFound404: React.FC<NotFoundProps> = ({
  heading,
  text,
  primaryButtonLink,
  primaryButtonText,
  secondaryButtonLink,
  secondaryButtonText,
}) => {
  return (
    <Container mt={{ base: 16, md: 32 }}>
      <Heading
        fontFamily="Manrope,sans-serif"
        fontWeight="800"
        fontSize={{ base: "3rem", md: "4.7rem" }}
        lineHeight="1.1"
      >
        {heading}
      </Heading>
      <Text
        fontFamily="Inter,sans-serif"
        mt={12}
        fontSize="1.5rem"
        lineHeight={1.5}
        maxW="700px"
      >
        {text}
      </Text>
      <Flex gap="4" my="8">
        {primaryButtonLink && primaryButtonText && (
          <Button variant="primary" asLink to={primaryButtonLink}>
            {primaryButtonText}
          </Button>
        )}
        {secondaryButtonLink && secondaryButtonText && (
          <Button variant="secondary" asLink to={secondaryButtonLink}>
            {secondaryButtonText}
          </Button>
        )}
      </Flex>
    </Container>
  );
};
