import {
  ProjectContextFragment,
  ProjectContextWithoutEnvironmentFragment,
  ProjectEnvironmentBasicFragment,
  useProjectEnvironmentQuery,
  useProjectQuery,
} from "@zeet/web-api/dist/graphql";
import { CenterLoading, NotFound404, ZError } from "@zeet/web-ui";
import React, { useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { projectPath } from "./util";

interface ProjectContextData {
  project: ProjectContextFragment | ProjectContextWithoutEnvironmentFragment;
}

const ProjectContext = React.createContext<ProjectContextData>({
  project: {} as ProjectContextFragment,
});

export interface ProjectEnvContextData {
  project: ProjectContextFragment | ProjectContextWithoutEnvironmentFragment;
  env: ProjectEnvironmentBasicFragment;
  setEnv: (env: ProjectEnvironmentBasicFragment) => void;
}

export const ProjectEnvContext = React.createContext<ProjectEnvContextData>({
  project: {} as ProjectContextFragment,
  env: {} as ProjectEnvironmentBasicFragment,
  setEnv: () => {},
});

export const useProject = () => {
  const { project } = useContext(ProjectContext);
  return project;
};
export const useEnv = () => {
  const { env } = useContext(ProjectEnvContext);
  return env;
};
export const useSetEnv = () => {
  const { setEnv } = useContext(ProjectEnvContext);
  return setEnv;
};

export const ProjectProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { teamName, projectName } = useParams<{
    teamName: string;
    projectName: string;
  }>();

  const projectRes = useProjectQuery({
    variables: { path: `${teamName}/${projectName}` },
    skip: !teamName || !projectName,
    fetchPolicy: "cache-and-network",
  });

  const loading = projectRes.loading;
  if (loading) return <CenterLoading />;

  if (!projectRes.data?.project) {
    return (
      <NotFound404
        heading="404, Group not found"
        text="This Group doesn't exist... yet. Why not make what you're looking for?"
        primaryButtonLink="/new?tags=All"
        primaryButtonText="New Project"
        secondaryButtonLink="/"
        secondaryButtonText="View my Projects"
      />
    );
  }

  if (projectRes.data?.project.name !== projectName) {
    return null;
  }

  return (
    <ProjectContext.Provider
      value={{
        project: projectRes.data.project,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

export const ProjectEnvProvider = ({
  children,
  envNameOverride,
}: {
  children: React.ReactNode;
  envNameOverride?: string;
}) => {
  const history = useHistory();

  const { envName, repoName } = useParams<{
    envName: string;
    repoName?: string;
  }>();

  const project = useProject();

  const realEnv = envName || envNameOverride || "";

  const envRes = useProjectEnvironmentQuery({
    variables: { path: projectPath(project), env: realEnv },
    errorPolicy: "all",
  });

  const setEnv = (env: ProjectEnvironmentBasicFragment) => {
    history.push(`/${projectPath(project)}/${env.name}/${repoName}`);
  };

  const loading = envRes.loading;
  const error = envRes.error;

  if (loading) return <CenterLoading />;
  if (error) {
    return <ZError error={error.message} />;
  }

  if (!envRes.data?.project?.environment) {
    return <ZError error="No environment found" />;
  }

  if (envRes.data?.project?.environment?.name !== realEnv) {
    return null;
  }

  return (
    <ProjectEnvContext.Provider
      value={{
        project: project,
        env: envRes.data?.project?.environment,
        setEnv,
      }}
    >
      {children}
    </ProjectEnvContext.Provider>
  );
};
