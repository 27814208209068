import {
  IconProps,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
} from "@chakra-ui/react";
import { forwardRef } from "react";
import { useColors } from "../hooks/useColors";
import { getInputStyleProps } from "./Input";

export interface IconInputProps {
  IconComponent: React.ElementType;
  inputProps?: InputProps;
  iconProps?: IconProps;
}

export const IconInput = forwardRef<HTMLInputElement, IconInputProps>(
  ({ IconComponent, inputProps, iconProps }, ref) => {
    const colors = useColors();
    const inputStyleProps = getInputStyleProps(colors);

    return (
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <IconComponent
            width="12px"
            height="12px"
            opacity="0.5"
            {...iconProps}
          />
        </InputLeftElement>

        <Input
          _placeholder={{ opacity: 0.75 }}
          {...inputStyleProps}
          {...inputProps}
          ref={ref}
        />
      </InputGroup>
    );
  }
);
