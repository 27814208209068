import * as posthog from "posthog-js";
import React from "react";
import { useEffectOnce } from "../hooks/useEffectOnce";
import { useCapture } from "./useCapture";

export const Pageview: React.FC<posthog.Properties> = ({
  //we don't want to forward "children" into the pageview event
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  children,
  ...eventProperties
}) => {
  const capture = useCapture();
  useEffectOnce(() => {
    capture("$pageview", eventProperties);
  });

  return null;
};
