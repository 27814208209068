import {
  ProjectDetailFragment,
  ProjectStatus,
  UpdateProjectInput,
  UpdateWorkflowInput,
  useProjectDetailQuery,
} from "@zeet/web-api/dist/graphqlv1";
import {
  CenterLoading,
  Container,
  NotFound404,
  useCurrentTeamUser,
} from "@zeet/web-ui";
import { useCallback, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { SettingsSections } from "~/components/Layouts/Settings";
import { useUnsavedChangesToast } from "~/hooks/useUnsavedChangesToast";
import { ProjectDetailTabProps } from "..";
import { useSaveProjectSettings } from "../hooks/useSaveProjectSettings";
import { ProjectSettingsProvider } from "./context/ProjectSettingsProvider";
import {
  DeploySettings,
  GeneralSettingsSection,
  WorkflowSettingsSection,
} from "./sections";
import { BlueprintSettings } from "./sections/BlueprintSettings";
import { DangerZone } from "./sections/DangerSettings";

export interface ProjectSettingsValues {
  project: UpdateProjectInput;
  workflow: UpdateWorkflowInput;
}

export interface ProjectSettingsProps {
  project: ProjectDetailFragment;
}

const SettingsTab = ({ project: projectInfo }: ProjectDetailTabProps) => {
  const { id: teamId } = useCurrentTeamUser();
  const { data, loading } = useProjectDetailQuery({
    variables: {
      projectId: projectInfo.id,
      teamId,
    },
  });
  const [saveHandlers, setSaveHandlers] = useState<(() => unknown)[]>([]);
  const methods = useForm<ProjectSettingsValues>({
    mode: "onTouched",
    defaultValues: {
      project: projectInfo,
    },
  });

  const { saveSettings, loading: saveLoading } = useSaveProjectSettings({
    methods,
    project: projectInfo,
  });

  const isDraft = useMemo(
    () => projectInfo.status === ProjectStatus.Draft,
    [projectInfo.status]
  );

  const saveAll = useCallback(() => {
    saveHandlers.forEach((h) => h());
    saveSettings();
  }, [saveHandlers, saveSettings]);

  useUnsavedChangesToast({
    show: saveHandlers.length > 0 || methods.formState.isDirty,
    loading: saveLoading,
    onSave: saveAll,
  });

  if (loading) return <CenterLoading />;
  const project = data?.team?.project;
  if (!project) {
    return (
      <NotFound404
        heading="404, project not found"
        text="This project doesn't exist... yet. Why not make what you're looking for?"
      />
    );
  }

  return (
    <Container fullWidth>
      <FormProvider {...methods}>
        <ProjectSettingsProvider
          isDraft={isDraft}
          setSaveHandlers={setSaveHandlers}
        >
          <SettingsSections>
            <GeneralSettingsSection project={project} />
            <BlueprintSettings project={project} />
            <WorkflowSettingsSection project={project} />
            <DeploySettings project={project} />
            <DangerZone project={project} />
          </SettingsSections>
        </ProjectSettingsProvider>
      </FormProvider>
    </Container>
  );
};

export default SettingsTab;
