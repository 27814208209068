import { Icon } from "@chakra-ui/react";
import { useBuildRunsQuery } from "@zeet/web-api/dist/graphqlv1";
import {
  Button,
  CenterLoading,
  Container,
  useCurrentTeamUser,
  usePagination,
} from "@zeet/web-ui";
import { useMemo } from "react";
import { BiPackage } from "react-icons/bi";
import { useLocation } from "react-router-dom";
import { SearchableListLayout } from "~/components/SearchableList";
import { buildColumnIds, buildColumns } from "./listview";
import { useTriggerBuild } from "./useTriggerBuild";
import { formatBuildRun } from "./utils";

interface BuildListProps {
  projectId: string;
}

export const BuildList = ({ projectId }: BuildListProps) => {
  const { pathname } = useLocation();
  const { id: teamId } = useCurrentTeamUser();
  const { setPage, pageInput } = usePagination();
  const { data: buildQuery, loading } = useBuildRunsQuery({
    variables: {
      teamId,
      projectId,
      page: pageInput,
    },
    pollInterval: 3000,
  });

  const build = buildQuery?.team?.project?.build;
  const { triggerBuild, loading: triggerBuildLoading } = useTriggerBuild(
    build?.id ?? ""
  );

  const allData = useMemo(
    () => ({
      columns: buildColumns,
      data: build?.runs.nodes.map((r) => formatBuildRun(pathname, r)) || [],
      skipReset: false,
    }),
    [build, pathname]
  );

  if (loading) return <CenterLoading />;

  return (
    <Container fullWidth>
      <SearchableListLayout
        id="builds"
        data={allData}
        columns={buildColumns}
        defaultColumnIds={buildColumnIds}
        pageChanged={setPage}
        hasNextPage={!loading && build?.runs.pageInfo.hasNextPage}
        actionButtons={
          <Button
            variant="primary"
            onClick={triggerBuild}
            isLoading={triggerBuildLoading}
          >
            <Icon as={BiPackage} mr={1} /> Trigger Build
          </Button>
        }
      />
    </Container>
  );
};
