import { Flex, Heading } from "@chakra-ui/react";
import {
  GenericWorkflowStepActionType,
  useWorkflowRunStepNestedDetailLogsQuery,
  WorkflowRunStepDetailFragment,
  WorkflowRunStepStatus,
} from "@zeet/web-api/dist/graphqlv1";
import {
  Button,
  Card,
  useCurrentTeamUser,
  useQueryParams,
  ZWarn,
} from "@zeet/web-ui";
import { useMemo, useState } from "react";
import { LogView } from "~/components/LogView";
import { useWorkflowOperations } from "../useWorkflowOperations";
import { workflowActionTitleMap } from "../utils";

interface WorkflowStepSectionProps {
  step: WorkflowRunStepDetailFragment | null;
  nestedStep: WorkflowRunStepDetailFragment | null;
  workflowRunId?: string;
  runId: string;
  projectId: string;
}

const queryActionMap = {
  showPlanLogs: GenericWorkflowStepActionType.DriverPlan,
  showApplyLogs: GenericWorkflowStepActionType.DriverApply,
};

export const WorkflowStepSection = ({
  step,
  nestedStep,
  workflowRunId,
  runId,
  projectId,
}: WorkflowStepSectionProps) => {
  const query = useQueryParams();
  const expanded = useMemo(() => {
    const isThisSectionExpanded = Object.keys(queryActionMap).some((key) => {
      return query.get(key) === "true" && step?.action === queryActionMap[key];
    });

    return (
      isThisSectionExpanded ||
      step?.status === WorkflowRunStepStatus.Running ||
      step?.status === WorkflowRunStepStatus.Error ||
      true
    );
  }, [step, query]);

  const currentTeamUser = useCurrentTeamUser();

  if (!step || !workflowRunId || !nestedStep) return null;

  if (step.action === GenericWorkflowStepActionType.DriverApprove) {
    return (
      <WorkflowStepApproveSection
        projectId={projectId}
        step={step}
        workflowRunId={workflowRunId}
        nestedStep={nestedStep}
      />
    );
  }

  return (
    <>
      {step.executionError && (
        <ZWarn error={step.executionError} borderRadius={0} />
      )}
      <LogView
        title={`${workflowActionTitleMap[step.action]} logs`}
        query={useWorkflowRunStepNestedDetailLogsQuery}
        variables={{
          teamId: currentTeamUser.id,
          projectId,
          runId,
          stepId: nestedStep?.id,
          actionStepId: step?.id,
        }}
        logsResolver={(r) => {
          if (
            (r.team?.project?.workflow?.run?.step.__typename ===
              "DeployRunStep" ||
              r.team?.project?.workflow?.run?.step.__typename ===
                "DestroyRunStep") &&
            r.team?.project?.workflow?.run?.step?.workflowRun?.step
              .__typename === "JobRunStep"
          ) {
            return r.team?.project?.workflow?.run?.step.workflowRun.step.logs;
          }
        }}
        collapsible
        expanded={expanded}
      />
    </>
  );
};

interface WorkflowStepApproveSectionProps {
  step: WorkflowRunStepDetailFragment | null;
  nestedStep: WorkflowRunStepDetailFragment | null;
  workflowRunId: string;
  projectId: string;
}

const WorkflowStepApproveSection = ({
  step,
  workflowRunId,
  nestedStep,
  projectId,
}: WorkflowStepApproveSectionProps) => {
  const { abort, approveWorkflowRunStep, abortLoading, approveLoading } =
    useWorkflowOperations();

  const [abortActionExecuted, setAbortActionExecuted] = useState(false);
  const [approveActionExecuted, setApproveActionExecuted] = useState(false);

  const isAbortLoading =
    abortLoading ||
    (abortActionExecuted &&
      nestedStep?.status === WorkflowRunStepStatus.Running);

  const isApproveLoading =
    approveLoading ||
    (approveActionExecuted && step?.status === WorkflowRunStepStatus.Running);

  const isDisabled = () => {
    return (
      step?.status !== WorkflowRunStepStatus.Running ||
      nestedStep?.status === WorkflowRunStepStatus.Aborted ||
      nestedStep?.status === WorkflowRunStepStatus.Error ||
      nestedStep?.status === WorkflowRunStepStatus.Failed ||
      nestedStep?.status === WorkflowRunStepStatus.Completed ||
      isAbortLoading ||
      isApproveLoading
    );
  };

  const disabled = isDisabled();

  return (
    <Card
      flexDir="column"
      borderRadius="0"
      _first={{ borderTopRadius: "md" }}
      _last={{ borderBottomRadius: "md" }}
    >
      <Flex
        as="button"
        alignItems="center"
        justifyContent="space-between"
        p={3}
        minHeight="56px"
        width="100%"
      >
        <Flex>
          <Heading size="sm" ml={4}>
            Approve next step?
          </Heading>
        </Flex>
        <Flex gap="3">
          <Button
            variant={"secondary"}
            size="sm"
            onClick={(e) => {
              e.stopPropagation();
              abort(projectId, workflowRunId);
              setAbortActionExecuted(true);
            }}
            isDisabled={disabled}
            isLoading={isAbortLoading}
          >
            Cancel
          </Button>
          <Button
            variant={disabled ? "secondary" : "primary"}
            size="sm"
            onClick={(e) => {
              if (!step?.id) return;
              e.stopPropagation();
              approveWorkflowRunStep(projectId, step?.id, workflowRunId);
              setApproveActionExecuted(true);
            }}
            isDisabled={disabled}
            isLoading={isApproveLoading}
          >
            Approve
          </Button>
        </Flex>
      </Flex>
    </Card>
  );
};
