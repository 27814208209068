import {
  ActionTypes,
  DataTypes,
  IDataOption,
  randomColor,
  shortId,
} from "@zeet/web-ui";
import update from "immutability-helper";

// eslint-disable-next-line
export function tableReducer(state, action) {
  const optionIndex = state.columns.findIndex(
    (column) => column.id === action.columnId
  );
  const typeIndex = state.columns.findIndex(
    (column) => column.id === action.columnId
  );
  const index = state.columns.findIndex(
    (column) => column.id === action.columnId
  );
  const leftIndex = state.columns.findIndex(
    (column) => column.id === action.columnId
  );
  const leftId = shortId();
  const rightIndex = state.columns.findIndex(
    (column) => column.id === action.columnId
  );
  const rightId = shortId();
  const deleteIndex = state.columns.findIndex(
    (column) => column.id === action.columnId
  );

  const options: IDataOption[] = [];

  switch (action.type) {
    case ActionTypes.ADD_OPTION_TO_COLUMN:
      return update(state, {
        skipReset: { $set: true },
        columns: {
          [optionIndex]: {
            options: {
              $push: [
                {
                  label: action.option,
                  backgroundColor: action.backgroundColor,
                },
              ],
            },
          },
        },
      });
    case ActionTypes.ADD_ROW:
      return update(state, {
        skipReset: { $set: true },
        data: { $push: [{}] },
      });
    case ActionTypes.POPULATE_DATA:
      return update(state, {
        skipReset: { $set: true },
        data: { $set: action.formattedData.data },
        ready: { $set: !action.loading },
      });
    case ActionTypes.POPULATE_COLUMN:
      return update(state, {
        skipReset: { $set: true },
        columns: { [index]: { label: { $set: action.label } } },
      });
    case ActionTypes.UPDATE_COLUMN_TYPE:
      switch (action.dataType) {
        case DataTypes.NUMBER:
          if (state.columns[typeIndex].dataType === DataTypes.NUMBER) {
            return state;
          } else {
            return update(state, {
              skipReset: { $set: true },
              columns: { [typeIndex]: { dataType: { $set: action.dataType } } },
              data: {
                $apply: (data) =>
                  data.map((row) => ({
                    ...row,
                    [action.columnId]: isNaN(row[action.columnId])
                      ? ""
                      : Number.parseInt(row[action.columnId]),
                  })),
              },
            });
          }
        case DataTypes.SELECT:
          if (state.columns[typeIndex].dataType === DataTypes.SELECT) {
            return state;
          } else {
            state.data.forEach((row) => {
              if (row[action.columnId]) {
                options.push({
                  label: row[action.columnId],
                  backgroundColor: randomColor(),
                });
              }
            });
            return update(state, {
              skipReset: { $set: true },
              columns: {
                [typeIndex]: {
                  dataType: { $set: action.dataType },
                  options: { $push: options },
                },
              },
            });
          }
        case DataTypes.TEXT:
          if (state.columns[typeIndex].dataType === DataTypes.TEXT) {
            return state;
          } else if (state.columns[typeIndex].dataType === DataTypes.SELECT) {
            return update(state, {
              skipReset: { $set: true },
              columns: { [typeIndex]: { dataType: { $set: action.dataType } } },
            });
          } else {
            return update(state, {
              skipReset: { $set: true },
              columns: { [typeIndex]: { dataType: { $set: action.dataType } } },
              data: {
                $apply: (data) =>
                  data.map((row) => ({
                    ...row,
                    [action.columnId]: row[action.columnId] + "",
                  })),
              },
            });
          }
        default:
          return state;
      }
    case ActionTypes.UPDATE_COLUMN_HEADER:
      return update(state, {
        skipReset: { $set: true },
        columns: { [index]: { label: { $set: action.label } } },
      });
    case ActionTypes.UPDATE_CELL:
      return update(state, {
        skipReset: { $set: true },
        data: {
          [action.rowIndex]: { [action.columnId]: { $set: action.value } },
        },
      });
    case ActionTypes.INSERT_POPULATED_COLUMN:
      return update(state, {
        skipReset: { $set: true },
        columns: {
          $splice: [
            [
              action.indexOfRightNeighbor,
              0,
              {
                ...action.column,
              },
            ],
          ],
        },
      });
    case ActionTypes.SHOW_ALL_COLUMNS:
      return update(state, {
        skipReset: { $set: true },
        columns: {
          $set: action.formattedData.columns,
        },
      });
    case ActionTypes.ADD_COLUMN_TO_LEFT:
      return update(state, {
        skipReset: { $set: true },
        columns: {
          $splice: [
            [
              leftIndex,
              0,
              {
                id: leftId,
                label: "Column",
                accessor: leftId,
                dataType: DataTypes.TEXT,
                created: action.focus && true,
                options: [],
              },
            ],
          ],
        },
      });
    case ActionTypes.ADD_COLUMN_TO_RIGHT:
      return update(state, {
        skipReset: { $set: true },
        columns: {
          $splice: [
            [
              rightIndex + 1,
              0,
              {
                id: rightId,
                label: "Column",
                accessor: rightId,
                dataType: DataTypes.TEXT,
                created: action.focus && true,
                options: [],
              },
            ],
          ],
        },
      });
    case ActionTypes.DELETE_COLUMN:
      return update(state, {
        skipReset: { $set: true },
        columns: { $splice: [[deleteIndex, 1]] },
      });
    case ActionTypes.ENABLE_RESET:
      return update(state, { skipReset: { $set: true } });
    default:
      return state;
  }
}

// eslint-disable-next-line
export function propertyReducer(state, action) {
  switch (action.type) {
    case "toggleOn":
      return {
        ...state,
        [action.id]: true,
      };
    case "toggleOff":
      return {
        ...state,
        [action.id]: false,
      };
    case "showAll":
      return {
        ...state,
        ...action.allColumnsState,
      };
    default:
      throw new Error();
  }
}
