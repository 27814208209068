import { Flex } from "@chakra-ui/react";
import { CloudResourceFragment } from "@zeet/web-api/dist/graphqlv1";
import { CenterLoading, DetailItem, DetailLabel } from "@zeet/web-ui";
import ReactTimeago from "react-timeago";
import { ResourceStatusBadge } from "./ResourceStatusBadge";

interface ResourceStatusInfoProps {
  cloudResource?: CloudResourceFragment;
}

export const ResourceStatusInfo: React.FC<ResourceStatusInfoProps> = ({
  cloudResource,
}) => {
  if (!cloudResource) {
    return <CenterLoading />;
  }

  return (
    <>
      <DetailItem>
        <DetailLabel>Status</DetailLabel>
        <ResourceStatusBadge
          fontSize="14px"
          height="22px"
          cloudResource={cloudResource}
          showText
        />
      </DetailItem>
      {cloudResource.createdAt && (
        <DetailItem>
          <DetailLabel>Age</DetailLabel>
          <Flex alignItems="center">
            <ReactTimeago date={cloudResource.createdAt || 0} />
          </Flex>
        </DetailItem>
      )}
    </>
  );
};
