import { ChevronDownIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Flex, Heading } from "@chakra-ui/react";
import React from "react";

export const BuildSectionHeader: React.FC<{
  showDropDown: boolean;
  setShowDropdown: (showDropDown: boolean) => void;
  title: string;
}> = ({ showDropDown, setShowDropdown, title }) => {
  return (
    <Flex
      minHeight="40px"
      onClick={() => setShowDropdown(!showDropDown)}
      alignItems="center"
      as="button"
      width="100%"
    >
      {showDropDown ? (
        <ChevronDownIcon fontSize="lg" />
      ) : (
        <ChevronRightIcon fontSize="lg" />
      )}
      <Heading size="sm">{title}</Heading>
    </Flex>
  );
};
