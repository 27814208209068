import { Text, useDisclosure } from "@chakra-ui/react";
import { ActionProps } from ".";
import { DeleteAction } from "../DeleteAction";
import { DeleteDialog } from "../DeleteDialog";

export const PauseAction = ({ onAction, name }: ActionProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <DeleteAction
      title="Pause this Project"
      message="Retain persistent storage and configuration, but terminate running containers and stop deployments."
      action="Pause Project"
      onAction={onOpen}
    >
      <DeleteDialog
        onClose={onClose}
        onDelete={onAction}
        isLoading={false}
        isOpen={isOpen}
        type="project"
        action={{ pastTense: "paused", presentTense: "pause" }}
        actionMessage="Pause this project"
        warningMessage={
          <Text>
            Do you want to pause{" "}
            <Text fontWeight={"bold"} as="span">
              {name}
            </Text>
            ? You can resume this project again at any time
          </Text>
        }
      />
    </DeleteAction>
  );
};
