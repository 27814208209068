import {
  Box,
  Button,
  Checkbox,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useUpdateEnvironmentEnvironmentVariablesMutation } from "@zeet/web-api/dist/graphql";
import React, { useMemo, useState } from "react";
import { useEnv } from "../Provider";
import { useEnvVarSettingsContext } from "../Settings/EnvVarSettingsProvider";
import { useRebuildReposContext } from "./RebuildReposProvider";

const RebuildReposModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  const toast = useToast();
  const [{ repos }, { dispatch }] = useRebuildReposContext();
  const [{ envs }] = useEnvVarSettingsContext();
  const [isSelectedAll, setIsSelectedAll] = useState(true);

  const env = useEnv();

  const [updateEnvs, { loading }] =
    useUpdateEnvironmentEnvironmentVariablesMutation({
      onCompleted: (data) => {
        if (data) {
          onClose();
          toast({
            title: "Environment Variables Saved",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      },
    });

  const redeployRepoIDs: string[] = useMemo(() => {
    return repos.reduce((acc, curr) => {
      if (curr.isSelected) {
        return [curr.repo.id, ...acc];
      } else {
        return [...acc];
      }
    }, [] as string[]);
  }, [repos]);

  return (
    <Flex flexDir="column">
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="md"
        isCentered
        preserveScrollBarGap={true}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Rebuild these Projects?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex flexDir="column" pb={6}>
              <Flex borderBottom="1px solid white" pb={3}>
                <Checkbox
                  onChange={() => {
                    setIsSelectedAll(!isSelectedAll);
                    if (isSelectedAll) {
                      dispatch({
                        type: "unselectAll",
                      });
                    } else {
                      dispatch({
                        type: "selectAll",
                      });
                    }
                  }}
                  isChecked={isSelectedAll}
                />
                <Text ml={4} fontWeight="bold">
                  Project Name
                </Text>
              </Flex>
              <Box mt={3}>
                {repos.map((r, i) => (
                  <Flex key={i}>
                    <Checkbox
                      isChecked={r.isSelected}
                      value={r.repo.id}
                      onChange={() => {
                        dispatch({
                          type: "toggle",
                          index: i,
                        });
                        setIsSelectedAll(false);
                      }}
                    />
                    <Text ml={4} py={1}>
                      {r.repo.name}
                    </Text>
                  </Flex>
                ))}
              </Box>
              <Flex justifyContent="center">
                <Button
                  width="200px"
                  mt={6}
                  colorScheme="brand"
                  isLoading={loading}
                  onClick={() => {
                    updateEnvs({
                      variables: {
                        input: {
                          id: env.id,
                          redeployRepoIDs,
                          envs: envs
                            .filter((e) => e.name)
                            .map((e) => ({
                              name: e.name.trim(),
                              value: e.value.trim(),
                              sealed: e.sealed,
                            })),
                        },
                      },
                    });
                  }}
                >
                  Confirm
                </Button>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default RebuildReposModal;
