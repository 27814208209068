import { Flex, FormControl } from "@chakra-ui/react";
import {
  CoreWeaveAccount,
  useRemoveCoreWeaveAccountMutation,
} from "@zeet/web-api/dist/graphql";
import { CopyCode, FormLabel } from "@zeet/web-ui";
import { SettingsDisplayName } from "~/components/Layouts/Settings/SettingsDisplayName";
import {
  SettingsSection,
  SettingsSectionDetails,
  SettingsSections,
} from "../../../../Layouts/Settings";
import { YesNoCell } from "../../../../ListViewV2";
import { CloudDangerZone } from "./CloudDangerZone";

interface CoreWeaveAccountProps {
  coreWeaveAccount: CoreWeaveAccount;
  refetch: () => void;
}

export const CoreWeaveDetails: React.FC<CoreWeaveAccountProps> = ({
  coreWeaveAccount,
  refetch,
}) => {
  return (
    <SettingsSections>
      <SettingsSection borderTop="none">
        <SettingsSectionDetails title="General" />
        <Flex gap="4" flexDirection="column">
          <SettingsDisplayName />
          <FormControl>
            <FormLabel>Connected</FormLabel>
            <YesNoCell isYes={coreWeaveAccount.connected ?? false} />
          </FormControl>
          <FormControl>
            <FormLabel>CoreWeave Tenant</FormLabel>
            <CopyCode value={coreWeaveAccount?.namespace ?? ""} width="100%" />
          </FormControl>
          <FormControl>
            <FormLabel>Zeet Cloud ID</FormLabel>
            <CopyCode value={coreWeaveAccount?.id ?? ""} width="100%" />
          </FormControl>
        </Flex>
      </SettingsSection>
      <SettingsSection mt={12}>
        <SettingsSectionDetails title="Danger zone" />

        <CloudDangerZone
          id={coreWeaveAccount.id}
          confirmationString={coreWeaveAccount.namespace ?? coreWeaveAccount.id}
          useRemoveCloudAccountMutation={useRemoveCoreWeaveAccountMutation}
          refetch={refetch}
        />
      </SettingsSection>
    </SettingsSections>
  );
};
