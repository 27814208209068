import {
  Box,
  Flex,
  Heading,
  Icon,
  Spacer,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import {
  DeploymentAlertDetailFragment,
  useDeploymentAlertsQuery,
} from "@zeet/web-api/dist/graphql";
import {
  Card,
  DataTypes,
  IDataRow,
  IZeetData,
  useColors,
  useQueryParams,
  ZError,
} from "@zeet/web-ui";
import React, { useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { ColumnsType } from "~/components/Console/ConsoleClusters/utils";
import {
  LIST_VIEW_TABLE_VIEW_TYPE,
  ListView,
  ListViewTable,
} from "~/components/ListViewV2";
import { BackNavButton } from "~/features/Project/Detail/components/BackNavLayout";
import { DeploymentDetail, deploymentTitle } from ".";
import {
  DeploymentAlertModal,
  DeploymentAlertModalById,
} from "./DeploymentAlert";

const deploymentAlertsListViewId = "deploymentAlerts";

enum ColumnIds {
  ALERT_TYPE = "alertType",
  FIRING_SINCE = "firingSince",
  RESOLVED_AT = "resolvedAt",
}
const columns: ColumnsType = [
  {
    id: ColumnIds.ALERT_TYPE,
    label: "Alert Type",
    minWidth: 700,
    dataType: DataTypes.TEXT,
    options: [],
    order: 1,
  },
  {
    id: ColumnIds.FIRING_SINCE,
    label: "First Seen At",
    minWidth: 200,
    dataType: DataTypes.TIMEAGO,
    options: [],
    order: 3,
  },
  {
    id: ColumnIds.RESOLVED_AT,
    label: "Resolved At",
    minWidth: 100,
    dataType: DataTypes.TIMEAGO,
    options: [],
    order: 3,
  },
];

const alertDataRow = (alert: DeploymentAlertDetailFragment) => {
  if (alert.__typename === "DeploymentPodCrashAlert") {
    return {
      [ColumnIds.ALERT_TYPE]: {
        text: "Pod Crash",
        alert: alert,
      },
      [ColumnIds.FIRING_SINCE]: {
        date: alert.createdAt,
      },
      [ColumnIds.RESOLVED_AT]: {
        date: alert.resolvedAt || undefined,
      },
    };
  }
  return {
    [ColumnIds.ALERT_TYPE]: {
      text: "Unknown Alert Type",
      alert: alert,
    },
    [ColumnIds.FIRING_SINCE]: {
      date: alert.createdAt,
    },
    [ColumnIds.RESOLVED_AT]: {
      date: alert.resolvedAt || undefined,
    },
  };
};

interface AlertsCursor {
  before?: string;
  after?: string;
}

export const DeploymentAlertsList: React.FC<{
  deployment: DeploymentDetail;
  backNavLinkTo: string;
}> = ({ deployment, backNavLinkTo }) => {
  const { brand } = useColors();

  // selecting an alert opens a modal
  const [selectedAlert, setSelectedAlert] =
    useState<DeploymentAlertDetailFragment | null>(null);

  // pagination
  const [cursor, setCursor] = useState<AlertsCursor | null>(null);

  const queryParams = useQueryParams();

  const { loading, data } = useDeploymentAlertsQuery({
    variables: {
      deploymentID: deployment.id,
      page: cursor
        ? {
            after: cursor.after,
            before: cursor.before,
          }
        : undefined,
    },
  });

  const page = data?.currentUser?.deployment?.alerts?.pageInfo;

  const previousPage = () => setCursor({ before: page?.startCursor });
  const nextPage = () => setCursor({ after: page?.endCursor });

  const defaultColumnIds = Object.values(ColumnIds) as ColumnIds[];

  const formattedData: IDataRow[] = (
    data?.currentUser.deployment?.alerts?.nodes || []
  ).map(alertDataRow);
  const filteredData: IZeetData = {
    columns: columns,
    data: formattedData,
    skipReset: false,
  };

  const deploymentAlertId = queryParams.get("alertId");

  return (
    <>
      {selectedAlert && (
        <DeploymentAlertModal
          alert={selectedAlert}
          onClose={() => setSelectedAlert(null)}
        />
      )}
      {deploymentAlertId && (
        <DeploymentAlertModalById
          deploymentId={deployment.id}
          alertId={deploymentAlertId}
        />
      )}
      <Card p={4}>
        <Stack>
          <BackNavButton
            placeSelf={"start"}
            linkText="Back to deployment"
            linkTo={backNavLinkTo}
          />
          <Heading
            mt={2}
            size="md"
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
            maxWidth="30rem"
          >
            {deploymentTitle(deployment)}
          </Heading>
        </Stack>
      </Card>
      <Stack>
        <Text
          fontSize="1.25em"
          fontWeight={600}
          mt={3}
          data-testid="workflow-title"
        >
          Deployment Alerts Timeline
        </Text>
        {loading ? (
          <Spinner />
        ) : (data?.currentUser?.deployment?.alerts?.nodes.length ?? 0) > 0 ? (
          <ListView
            id={deploymentAlertsListViewId}
            formattedData={filteredData}
            columns={columns}
            filterValue={""}
            handleFilterChange={() => {}}
            defaultColumnIds={defaultColumnIds}
            defaultViewMode={LIST_VIEW_TABLE_VIEW_TYPE}
            loading={loading ?? false}
            itemSelected={(row) => {
              setSelectedAlert(row.alertType?.alert || null);
            }}
            keyboardNavigable={true}
          >
            <ListViewTable
              mt={4}
              onClickRow={(row) =>
                setSelectedAlert(row.alertType?.alert || null)
              }
              hoverProps={{ color: brand }}
            />
            <Flex mt="32px">
              {page?.hasPreviousPage && (
                <Box py={2} px={4} cursor={"pointer"} onClick={previousPage}>
                  <Icon as={FaArrowLeft} />
                </Box>
              )}
              <Spacer />
              {page?.hasNextPage && (
                <Box py={2} px={4} cursor={"pointer"} onClick={nextPage}>
                  <Icon as={FaArrowRight} />
                </Box>
              )}
            </Flex>
          </ListView>
        ) : (
          <ZError>No alerts found</ZError>
        )}
      </Stack>
    </>
  );
};
