import {
  ActionTypes,
  IDataRow,
  IZeetData,
  useLocalStorage,
} from "@zeet/web-ui";
import React, { Dispatch, useMemo } from "react";
import { propertyReducer, tableReducer } from "../reducers";
import { ListViewContext, ListViewContextData } from "./ListViewContext";

export interface ListViewProviderProps {
  id: string;
  columns: ListViewContextData["columns"];
  children: React.ReactNode;
  defaultColumnIds: string[];
  filterValue: ListViewContextData["filterValue"];
  handleFilterChange: ListViewContextData["handleFilterChange"];
  formattedData: IZeetData;
  defaultViewMode: ListViewContextData["viewMode"];
  loading?: ListViewContextData["loading"];
  onInfiniteScrollRequestMoreData?: ListViewContextData["onInfiniteScrollRequestMoreData"];
  keyboardNavigable?: boolean;
  itemSelected?: (item: IDataRow) => void;
}

interface ColumnPreference {
  id: string;
  visible: boolean;
}

interface ListViewPreference {
  columns: ColumnPreference[];
}

export type SetViewModeType = Dispatch<string>;

export const ListViewProvider: React.FC<ListViewProviderProps> = ({
  id,
  columns,
  children,
  defaultColumnIds,
  filterValue,
  formattedData,
  handleFilterChange,
  defaultViewMode,
  loading,
  onInfiniteScrollRequestMoreData,
  keyboardNavigable,
  itemSelected,
}) => {
  const [localPreferences, setLocalPreferences] = useLocalStorage(
    `listview:${id}:preferences`,
    "{}"
  );
  const [viewMode, setViewMode] = useLocalStorage(
    `listview:${id}:viewMode`,
    defaultViewMode
  ) as [ListViewProviderProps["defaultViewMode"], SetViewModeType];

  const parseLocalPreferences = (
    localPreferences: string
  ): ListViewPreference => {
    const preferencesJson = JSON.parse(localPreferences);

    if (preferencesJson?.columns) {
      return preferencesJson;
    } else {
      return { columns: [] };
    }
  };

  const initialColumnsDisplayed = useMemo(() => {
    const preferences = parseLocalPreferences(localPreferences);
    const displayedColumns = {};

    columns.forEach((element) => {
      if (defaultColumnIds.includes(element.id)) {
        displayedColumns[element.id] = true;
      } else {
        displayedColumns[element.id] = false;
      }
    });

    if (preferences.columns.length !== 0) {
      preferences.columns.forEach((element: ColumnPreference) => {
        displayedColumns[element.id] = element.visible;
      });
    }
    return displayedColumns;
  }, [columns, defaultColumnIds, localPreferences]);

  const defaultState = {
    columns: formattedData.columns.filter((element) => {
      return initialColumnsDisplayed[element.id];
    }),
    data: formattedData.data,
    skipReset: false,
    ready: false,
  };

  const [state, dispatch] = React.useReducer(tableReducer, defaultState);

  const allColumnsState = {};

  columns.map((element) => {
    allColumnsState[element.id] = true;
  });

  const [propertyState, propertyDispatch] = React.useReducer(
    propertyReducer,
    initialColumnsDisplayed
  );

  const toggleMenuItem = (id) => {
    if (propertyState[id]) {
      propertyDispatch({ type: "toggleOff", id });
      dispatch({ type: ActionTypes.DELETE_COLUMN, columnId: id });
    } else {
      propertyDispatch({ type: "toggleOn", id });
      // order of element to be added
      const column = columns.find((element) => element.id === id);
      //   find right neighbor

      let orderFound = 0;
      for (let i = 0; i < state.columns.length; i++) {
        if (i === state.columns[i].length - 1) {
          orderFound = state.columns[i].order;
          break;
        }
        if (column && column?.order < state.columns[i].order) {
          orderFound = state.columns[i].order;
          break;
        }
      }

      let indexOfRightNeighbor = state.columns.findIndex((element) => {
        return element.order === orderFound;
      });
      //   if index of right neighbor not found, it is at the end of the array.
      if (indexOfRightNeighbor < 0) {
        indexOfRightNeighbor = state.columns.length;
      }
      dispatch({
        type: ActionTypes.INSERT_POPULATED_COLUMN,
        indexOfRightNeighbor,
        column,
      });
    }
  };

  const showAllColumns = () => {
    propertyDispatch({ type: "showAll", allColumnsState });
    dispatch({ type: ActionTypes.SHOW_ALL_COLUMNS, formattedData });
  };

  React.useEffect(() => {
    if (formattedData) {
      dispatch({ type: ActionTypes.POPULATE_DATA, formattedData, loading });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formattedData]);

  React.useEffect(() => {
    const columnPreferences: ColumnPreference[] = Object.keys(
      propertyState
    ).map((key: string): ColumnPreference => {
      return {
        id: key,
        visible: propertyState[key],
      };
    });
    setLocalPreferences(JSON.stringify({ columns: columnPreferences }));
  }, [propertyState, id, setLocalPreferences]);

  return (
    <ListViewContext.Provider
      value={{
        columns,
        dispatch,
        propertyState,
        state,
        showAllColumns,
        toggleMenuItem,
        filterValue,
        handleFilterChange,
        viewMode,
        setViewMode,
        loading: loading ?? false,
        onInfiniteScrollRequestMoreData,
        itemSelected,
        keyboardNavigable,
      }}
    >
      {children}
    </ListViewContext.Provider>
  );
};
