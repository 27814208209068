import { Text } from "@chakra-ui/react";
import { DetailDataBlock, DetailItem, DetailLabel } from "@zeet/web-ui";
import { FaDocker } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useProjectPath } from "../hooks/useProjectPath";
import { parseDockerImage } from "../utils";

interface BuildArtifactDataBlockProps {
  artifacts?: ({ id: string; image: string } | undefined | null)[] | null;
  runCompleted?: boolean;
}

export const BuildArtifactsDetailItem = ({
  artifacts,
  runCompleted,
}: BuildArtifactDataBlockProps) => {
  const path = useProjectPath();
  return (
    <DetailItem>
      <DetailLabel>Artifacts</DetailLabel>
      {artifacts?.map(
        (a) =>
          a && (
            <Link
              to={`${path}/artifacts?query=${parseDockerImage(a.image).image}`}
            >
              <DetailDataBlock
                icon={<FaDocker />}
                textOverflow="ellipsis"
                overflow="hidden"
                whiteSpace="nowrap"
                fontSize="0.9em"
                key={a.id}
              >
                {parseDockerImage(a.image).image}
              </DetailDataBlock>
            </Link>
          )
      )}
      {!artifacts?.length && (
        <Text>
          {runCompleted ? "This run produced no artifacts" : "No artifacts yet"}
        </Text>
      )}
    </DetailItem>
  );
};
