import { Box, Flex, IconButton, Stack } from "@chakra-ui/react";
import { Button, FormLabel, Input, useEffectOnce } from "@zeet/web-ui";
import { useState } from "react";
import { FiMinusCircle } from "react-icons/fi";

interface TerraformOutputsProps {
  value?: string[] | null;
  onChange?: (value: string[]) => void;
}

export const TerraformOutputs = ({
  value,
  onChange,
}: TerraformOutputsProps) => {
  const [excludedOutputs, setExcludedOutputs] = useState<string[]>(value || []);

  const append = (value: string) => {
    setExcludedOutputs([...excludedOutputs, value]);
    onChange?.([...excludedOutputs, value]);
  };

  const update = (index: number, value: string) => {
    const newExcludedOutputs = [...excludedOutputs];
    newExcludedOutputs[index] = value;
    setExcludedOutputs(newExcludedOutputs);
    onChange?.(newExcludedOutputs);
  };

  const remove = (index: number) => {
    const newExcludedOutputs = [...excludedOutputs];
    newExcludedOutputs.splice(index, 1);
    setExcludedOutputs(newExcludedOutputs);
    onChange?.(newExcludedOutputs);
  };

  const defaultValue = "";

  useEffectOnce(() => {
    if (value) {
      setExcludedOutputs(value);
    } else {
      append(defaultValue);
    }
  });

  return (
    <Stack>
      <FormLabel width="100%" mb="0">
        Mark specific outputs as sensitive
      </FormLabel>
      {excludedOutputs.map((value, index) => (
        <Box
          key={index}
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          gap="3"
        >
          <Input
            value={value}
            onChange={(e) => update(index, e.target.value)}
          />
          <IconButton
            aria-label="delete"
            icon={<FiMinusCircle />}
            borderRadius="md"
            variant="outline"
            onClick={() => remove(index)}
          />
        </Box>
      ))}
      <Flex>
        <Button
          variant="secondary"
          size="sm"
          onClick={() => append(defaultValue)}
          mr={4}
        >
          Add output
        </Button>
      </Flex>
    </Stack>
  );
};
