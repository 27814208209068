import {
  Button,
  Divider,
  Flex,
  Input,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import {
  RepoDetailFragment,
  useRemoveProbeMutation,
  useUpdateProjectSettingsMutation,
} from "@zeet/web-api/dist/graphql";
import { FormSelect, Tooltip, ZAlert, ZError } from "@zeet/web-ui";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { shouldDisplayError } from "../util";
import { ZFormLabel } from "./Build";

enum ProbeType {
  NONE = "NONE",
  COMMAND = "COMMAND",
  HTTP = "HTTP",
  TCP = "TCP",
}

const getPort = (repo: RepoDetailFragment): string => {
  if (repo.ports?.length) {
    for (const p of repo.ports) {
      if (p.https) {
        return p.port;
      }
    }
    return repo.ports[0]?.port.split("-")?.[0] ?? "";
  }

  return "";
};

const ProbeSettings: React.FC<{
  repo: RepoDetailFragment;
  probe: "readinessProbe" | "livenessProbe" | "startupProbe";
}> = ({ repo, probe }) => {
  const toast = useToast();

  const { register, watch, setValue } = useForm({
    defaultValues: {
      type: repo[probe]?.command
        ? ProbeType.COMMAND
        : repo[probe]?.http?.port
        ? ProbeType.HTTP
        : repo[probe]?.tcp?.port
        ? ProbeType.TCP
        : ProbeType.NONE,
      command: repo[probe]?.command,
      httpPath: repo[probe]?.http?.path,
      httpPort: repo[probe]?.http?.port,
      tcpPort: repo[probe]?.tcp?.port,
      initialDelaySeconds: repo[probe]?.initialDelaySeconds,
      periodSeconds: repo[probe]?.periodSeconds,
      successThreshold: repo[probe]?.successThreshold,
      failureThreshold: repo[probe]?.failureThreshold,
      timeoutSeconds: repo[probe]?.timeoutSeconds,
    },
  });

  const watcher = watch();

  useEffect(() => {
    if (watcher.type === ProbeType.HTTP) {
      setValue("httpPort", repo[probe]?.http?.port || getPort(repo), {
        shouldDirty: true,
      });
      setValue("httpPath", repo[probe]?.http?.path || "/", {
        shouldDirty: true,
      });
    } else if (watcher.type === ProbeType.TCP) {
      setValue("tcpPort", repo[probe]?.tcp?.port || getPort(repo), {
        shouldDirty: true,
      });
    }
  }, [watcher.type, setValue, repo, probe]);

  const [
    updateSettings,
    { error: updateError, loading: updateLoading, data: updateData },
  ] = useUpdateProjectSettingsMutation({
    onCompleted: (data) => {
      if (data) {
        toast({
          title: "Health Check Settings Saved",
          status: "success",
          duration: 2500,
          isClosable: true,
        });
      }
    },
  });

  const [removeProbe, { error: removeError, loading: removeLoading }] =
    useRemoveProbeMutation({
      onCompleted: (data) => {
        if (data) {
          toast({
            title: "Health Check Removed",
            status: "success",
            duration: 2500,
            isClosable: true,
          });
        }
      },
    });

  const doSave = () => {
    if (watcher.type === ProbeType.COMMAND) {
      updateSettings({
        variables: {
          input: {
            id: repo.id,
            [probe]: {
              command: watcher.command,
              initialDelaySeconds: watcher.initialDelaySeconds || null,
              periodSeconds: watcher.periodSeconds || null,
              timeoutSeconds: watcher.timeoutSeconds || null,
              successThreshold: watcher.successThreshold || null,
              failureThreshold: watcher.failureThreshold || null,
            },
          },
        },
      });
    } else if (watcher.type === ProbeType.HTTP) {
      updateSettings({
        variables: {
          input: {
            id: repo.id,
            [probe]: {
              httpEndpoint: `${watcher.httpPort}:${watcher.httpPath}`,
              initialDelaySeconds: watcher.initialDelaySeconds || null,
              periodSeconds: watcher.periodSeconds || null,
              timeoutSeconds: watcher.timeoutSeconds || null,
              successThreshold: watcher.successThreshold || null,
              failureThreshold: watcher.failureThreshold || null,
            },
          },
        },
      });
    } else if (watcher.type === ProbeType.TCP) {
      updateSettings({
        variables: {
          input: {
            id: repo.id,
            [probe]: {
              tcpEndpoint: watcher.tcpPort,
              initialDelaySeconds: watcher.initialDelaySeconds || null,
              periodSeconds: watcher.periodSeconds || null,
              timeoutSeconds: watcher.timeoutSeconds || null,
              successThreshold: watcher.successThreshold || null,
              failureThreshold: watcher.failureThreshold || null,
            },
          },
        },
      });
    } else {
      removeProbe({
        variables: {
          input: {
            id: repo.id,
            [probe]: true,
          },
        },
      });
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <Stack spacing={2}>
        <Flex flex={1} alignItems="center">
          <ZFormLabel>Check Type</ZFormLabel>
          <FormSelect
            flex={5}
            {...register("type")}
            defaultValue={watcher.type}
            ml={"5px"}
            options={[
              ["Disabled", ProbeType.NONE],
              ["Shell Command", ProbeType.COMMAND],
              ["HTTP Endpoint", ProbeType.HTTP],
              ["TCP Endpoint", ProbeType.TCP],
            ].map(([name, value]) => {
              return { value: value as ProbeType, label: name };
            })}
          />
        </Flex>
        <Stack hidden={watcher.type !== ProbeType.COMMAND} spacing={2}>
          <Flex alignItems="center" width="100%">
            <ZFormLabel>
              Shell Command
              <Tooltip text="Execute a shell command, succeeds if return value is zero" />
            </ZFormLabel>
            <Input
              maxWidth="780px"
              flex={5}
              fontFamily={"mono"}
              {...register("command")}
              placeholder="ls (required)"
            />
          </Flex>
        </Stack>
        <Stack hidden={watcher.type !== ProbeType.HTTP} spacing={2}>
          <Flex alignItems="center" width="100%">
            <ZFormLabel>HTTP Endpoint</ZFormLabel>
            <Input
              maxWidth="780px"
              flex={5}
              fontFamily={"mono"}
              {...register("httpPath")}
              placeholder={"/health (required)"}
            />
          </Flex>
          <Flex alignItems="center" width="100%">
            <ZFormLabel>HTTP Port</ZFormLabel>
            <Input
              maxWidth="780px"
              flex={5}
              fontFamily={"mono"}
              type="number"
              {...register("httpPort")}
              placeholder={"8000 (required)"}
            />
          </Flex>
        </Stack>
        <Stack hidden={watcher.type !== ProbeType.TCP} spacing={2}>
          <Flex alignItems="center" width="100%">
            <ZFormLabel>TCP Port</ZFormLabel>
            <Input
              maxWidth="780px"
              flex={5}
              fontFamily={"mono"}
              type="number"
              {...register("tcpPort")}
              placeholder={"2337 (required)"}
            />
          </Flex>
        </Stack>
        <Stack hidden={watcher.type === ProbeType.NONE} spacing={2}>
          <Flex alignItems="center" width="100%">
            <ZFormLabel>
              Timeout
              <Tooltip text="Number of seconds after which the probe times out. Defaults to 1 second. Minimum value is 1." />
            </ZFormLabel>
            <Input
              maxWidth="780px"
              flex={5}
              fontFamily={"mono"}
              type="number"
              {...register("timeoutSeconds")}
              placeholder={"5 (optional)"}
            />
          </Flex>
          <Flex alignItems="center" width="100%">
            <ZFormLabel>
              Initial Delay
              <Tooltip text="Number of seconds after the container has started before liveness or readiness probes are initiated. Defaults to 0 seconds. Minimum value is 0." />
            </ZFormLabel>
            <Input
              maxWidth="780px"
              flex={5}
              fontFamily={"mono"}
              {...register("initialDelaySeconds")}
              type="number"
              placeholder={"0 (optional)"}
            />
          </Flex>
          <Flex alignItems="center" width="100%">
            <ZFormLabel>
              Interval
              <Tooltip text="How often (in seconds) to perform the probe. Default to 10 seconds. Minimum value is 1" />
            </ZFormLabel>
            <Input
              maxWidth="780px"
              flex={5}
              fontFamily={"mono"}
              {...register("periodSeconds")}
              type="number"
              placeholder={"10 (optional)"}
            />
          </Flex>
          {probe == "readinessProbe" && (
            <Flex alignItems="center" width="100%">
              <ZFormLabel>
                Success Threshold
                <Tooltip text="Minimum consecutive successes for the probe to be considered successful. Defaults to 1. Minimum value is 1." />
              </ZFormLabel>
              <Input
                maxWidth="780px"
                flex={5}
                fontFamily={"mono"}
                {...register("successThreshold")}
                type="number"
                placeholder={"1 (optional)"}
              />
            </Flex>
          )}
          <Flex alignItems="center" width="100%">
            <ZFormLabel>
              Failure Threshold
              <Tooltip text="When a probe fails, Zeet will try this many times before giving up. Defaults to 3. Minimum value is 1." />
            </ZFormLabel>
            <Input
              maxWidth="780px"
              flex={5}
              fontFamily={"mono"}
              {...register("failureThreshold")}
              type="number"
              placeholder={"3 (optional)"}
            />
          </Flex>
        </Stack>
        <ZError
          error={
            (shouldDisplayError(updateError, updateData) && updateError) ||
            removeError
          }
        />
        <Flex>
          <Button
            ml={"auto"}
            colorScheme="brand"
            isLoading={updateLoading || removeLoading}
            type="submit"
            onClick={() => {
              doSave();
            }}
          >
            Save
          </Button>
        </Flex>
      </Stack>
    </form>
  );
};

export const HealthCheckSettings: React.FC<{
  repo: RepoDetailFragment;
}> = ({ repo }) => {
  return (
    <Stack spacing={4}>
      <Text fontWeight="bold" fontSize="1.2rem" mb={1}>
        Health Check Settings
      </Text>
      <Divider />
      <Text fontWeight="bold">Startup Check</Text>
      <Text>
        Startup check will run at application start, health and ready check will
        only start running after Startup Check succeed. This is used to protect
        slow starting application.
      </Text>
      <ProbeSettings repo={repo} probe="startupProbe" />
      <Divider />
      <Text fontWeight="bold">Health Check</Text>
      <Text>
        Running deployments will be automatically restarted if it fails the
        health check.
      </Text>
      <ProbeSettings repo={repo} probe="livenessProbe" />
      <Divider />
      <Text fontWeight="bold">Ready Check</Text>
      <Text>
        Deployment will stop receiving traffic if it fails the ready check, this
        is commonly used to protect resource from traffic spike or transient
        issues.
      </Text>
      {repo.ports?.length ? (
        <ProbeSettings repo={repo} probe="readinessProbe" />
      ) : (
        <ZAlert status="info">
          Only available to project with network endpoints
        </ZAlert>
      )}
    </Stack>
  );
};
