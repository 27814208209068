import {
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  Stack,
  useToast,
} from "@chakra-ui/react";
import {
  RepoDetailFragment,
  useUpdateProjectSettingsMutation,
} from "@zeet/web-api/dist/graphql";
import { Tooltip } from "@zeet/web-ui";
import React, { useState } from "react";
import { NIL as uuidNil } from "uuid";
import { FormCard } from "../../FormCard";
import { shouldDisplayError } from "../util";

interface DockerDeploySettingsProps {
  repo: RepoDetailFragment;
  isOnboarding?: true;
}

export const DockerDeploySettings: React.FC<DockerDeploySettingsProps> = ({
  repo,
}) => {
  const toast = useToast();

  const [dirty, setDirty] = useState(false);

  const [updateSettings, { error, loading, data }] =
    useUpdateProjectSettingsMutation({
      onCompleted: (data) => {
        if (data) {
          setDirty(false);
          toast({
            title: "Project settings saved successfully",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      },
    });

  const registry = repo.containerRegistry?.id || "DEFAULT";

  const [runCommand, setRunCommand] = useState(repo.buildMethod?.runCommand);

  const isDirty = runCommand !== repo.buildMethod?.runCommand || dirty;

  if (error) {
    console.error(error);
  }

  const errorMsg = error?.message;

  const onSubmit = () => {
    updateSettings({
      variables: {
        input: {
          id: repo.id,
          runCommand: runCommand,
          containerRegistryID: registry === "DEFAULT" ? uuidNil : registry,
        },
      },
    });
  };

  return (
    <FormCard
      isDisabled={!isDirty}
      isLoading={loading}
      title="Docker Setup"
      onSubmit={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onSubmit();
      }}
    >
      <FormControl isInvalid={!!errorMsg && shouldDisplayError(error, data)}>
        <Stack spacing={2}>
          <Heading as="h4" size="sm">
            Additional Arguments{" "}
            <Tooltip text="Any extra arguments you want to pass to your project. This is the last part of the docker run command" />
          </Heading>
          <Input
            my={2}
            fontFamily={"mono"}
            value={runCommand || ""}
            onChange={(e) => setRunCommand(e.currentTarget.value)}
            placeholder="--magic --plz-work"
          />
          {shouldDisplayError(error, data) && (
            <FormErrorMessage>{errorMsg}</FormErrorMessage>
          )}
        </Stack>
      </FormControl>
    </FormCard>
  );
};
