import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Switch,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  RepoDetailFragment,
  useCreateRepoPipelineLinkMutation,
  useDeleteRepoPipelineLinkMutation,
  useUpdateRepoPipelineLinkMutation,
} from "@zeet/web-api/dist/graphql";
import { FormSelect, Tooltip, ZError } from "@zeet/web-ui";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { RiDeleteBinLine } from "react-icons/ri";
import { FormCard } from "../../FormCard";
import { ZFormLabel } from "./Build";

enum RepoEvent {
  BuildSucceeded = "BUILD_SUCCEEDED",
  DeploySucceeded = "DEPLOY_SUCCEEDED",
}

enum RepoAction {
  Deploy = "DEPLOY",
}

enum RepoGroupLabels {
  RepoGroupLabelRepoID = "zeet.co/repo-id",
  RepoGroupLabelEnvironmentID = "zeet.co/environment-id",
}

export const RepoPipelineSettings: React.FC<{
  repo: RepoDetailFragment;
}> = ({ repo }) => {
  const toast = useToast();
  const [changed, setChanged] = useState(false);

  const {
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
    isOpen: isDeleteOpen,
  } = useDisclosure();

  // We are only taking the first pipeline link in V1
  const pipeline = repo?.pipelineLinks?.[0];
  const [enabled, setEnabled] = useState(!!pipeline);

  useEffect(() => {
    if (repo) {
      setEnabled(!!pipeline);
    }
  }, [pipeline, repo]);

  const matchLabels = pipeline?.targetGroup?.selector?.matchLabels;
  const targetEnvironmentID =
    matchLabels &&
    JSON.parse(matchLabels)[RepoGroupLabels.RepoGroupLabelEnvironmentID];
  const targetRepoID =
    matchLabels &&
    JSON.parse(matchLabels)[RepoGroupLabels.RepoGroupLabelRepoID];

  type FormValues = {
    sourceEvent: RepoEvent;
    targetAction: RepoAction;
    targetType: string;
    targetID: string;
  };

  const { handleSubmit, register, reset } = useForm<FormValues>({
    defaultValues: {
      targetType: targetEnvironmentID ? "environment" : "repo",
      targetID: targetEnvironmentID || targetRepoID,
    },
  });

  const [updatePipeline, { error: updateError, loading: updateLoading }] =
    useUpdateRepoPipelineLinkMutation({
      onCompleted: (data) => {
        setChanged(false);
        // refetch();
        if (data) {
          toast({
            title: "Pipeline Configuration Saved",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      },
    });

  const [createPipeline, { error, loading }] =
    useCreateRepoPipelineLinkMutation({
      onCompleted: (data) => {
        setChanged(false);
        // refetch();
        if (data) {
          toast({
            title: "Pipeline Configuration Saved",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      },
    });
  const errorMsg = !changed && (error?.message || updateError?.message);

  const onSubmit = (values): void => {
    const target: any = {};
    if (values.targetID) {
      if (values.targetType === "environment") {
        target.targetEnvironmentID = values.targetID;
      } else {
        target.targetRepoID = values.targetID;
      }
    }

    if (pipeline) {
      updatePipeline({
        variables: {
          input: {
            id: pipeline.id,
            sourceRepoID: repo.id,
            sourceEvent: values.sourceEvent,
            ...target,
            targetAction: values.targetAction,
          },
        },
      });
    } else {
      createPipeline({
        variables: {
          input: {
            sourceRepoID: repo.id,
            sourceEvent: values.sourceEvent,
            ...target,
            targetAction: values.targetAction,
          },
        },
      });
    }
  };

  const sourceEventOptions = [
    { name: "Build", value: RepoEvent.BuildSucceeded },
  ];
  const targetActionOptions = [{ name: "Deploy", value: RepoAction.Deploy }];

  return (
    <FormCard
      isLoading={loading || updateLoading}
      title="Pipeline Config"
      description="Configure actions that will run on target resources when events occur on the current repo."
      onSubmit={handleSubmit(onSubmit)}
      isDisabled={!enabled}
    >
      <RepoPipelineDeleteModal
        onDeleted={() => {
          reset({ targetID: "" });
          // refetch();
          onDeleteClose();
        }}
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        uuid={pipeline?.id}
      />
      <FormControl isDisabled={!enabled} isInvalid={!!errorMsg}>
        <Stack spacing={4}>
          <Flex width="100%" justifyContent="space-between">
            <Text>Enable Pipeline</Text>
            <Flex alignItems="center">
              <Switch
                size="lg"
                isChecked={enabled}
                isDisabled={false}
                onChange={(e) => {
                  const isChecked = e.target.checked;
                  if (isChecked) {
                    setEnabled(e.target.checked);
                  } else {
                    // Only allow delete using modal if there already is an existing pipeline
                    if (pipeline) {
                      onDeleteOpen();
                    } else {
                      setEnabled(e.target.checked);
                    }
                  }
                }}
              />
            </Flex>
          </Flex>
          <Flex>
            <ZFormLabel>Source Event</ZFormLabel>
            <Box flex={5.25 /* TODO: broken alignment */}>
              <FormSelect
                {...register("sourceEvent")}
                defaultValue={pipeline?.sourceEvent}
                options={sourceEventOptions.map((sourceEvent) => {
                  return { value: sourceEvent.value, label: sourceEvent.name };
                })}
              />
            </Box>
          </Flex>
          <Flex>
            <ZFormLabel>
              Target Type
              <Tooltip text="Specify the resource type that the target action will be run on!" />
            </ZFormLabel>
            <Box flex={5.25 /* TODO: broken alignment */}>
              <FormSelect
                {...register("targetType")}
                defaultValue="repo"
                options={["repo", "environment"].map((targetType) => {
                  return { value: targetType, label: targetType };
                })}
              />
            </Box>
          </Flex>
          <Flex>
            <ZFormLabel>
              Target ID
              <Tooltip text="Input the resource ID here. For example, if target type is environment, input the environment ID." />
            </ZFormLabel>
            <Input
              flex={5}
              fontFamily={"mono"}
              {...register("targetID")}
              placeholder=""
            />
          </Flex>
          <Flex>
            <ZFormLabel>Target Action</ZFormLabel>
            <Box flex={5.25 /* TODO: broken alignment */}>
              <FormSelect
                {...register("targetAction")}
                defaultValue={pipeline?.targetAction}
                options={targetActionOptions.map((targetAction) => {
                  return {
                    value: targetAction.value,
                    label: targetAction.name,
                  };
                })}
              />
            </Box>
          </Flex>
          <FormErrorMessage>{errorMsg}</FormErrorMessage>
        </Stack>
      </FormControl>
    </FormCard>
  );
};

const RepoPipelineDeleteModal: React.FC<{
  onClose: () => void;
  onDeleted: () => void;
  isOpen: boolean;
  uuid?: string;
}> = ({ onClose, onDeleted, isOpen, uuid }) => {
  const toast = useToast();
  const [deletePipeline, { error, loading }] =
    useDeleteRepoPipelineLinkMutation({
      onCompleted: (data) => {
        if (data) {
          onDeleted();
          toast({
            title: "Pipeline Deleted",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      },
    });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="md"
      isCentered
      preserveScrollBarGap={true}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>
          Are you sure you want to delete the current pipeline?
        </ModalHeader>
        <ModalBody>
          <ZError error={error} />
          <Flex
            justifyContent="center"
            alignItems="center"
            paddingBottom="2rem"
            flexDirection="column"
          >
            <Button
              alignSelf="center"
              leftIcon={<RiDeleteBinLine size="1.5rem" />}
              mt={10}
              shadow="md"
              borderRadius={4}
              display="flex"
              colorScheme="red"
              isLoading={loading}
              onClick={() => {
                if (!uuid) return;
                deletePipeline({
                  variables: {
                    id: uuid,
                  },
                });
              }}
            >
              Delete Pipeline
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
