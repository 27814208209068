import { useLocation } from "react-router-dom";

export function useNextUrl(
  baseUrl: "login" | "signup" | "cloud-onboard" | "team-onboard" = "login"
): string {
  const location = useLocation();
  const next = location.pathname + location.search + location.hash;

  return `/${baseUrl}${
    next !== "/" ? `?yeet=${encodeURIComponent(next)}` : ""
  }`;
}
