import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { SearchableProps, SearchButton, useAccessToken } from "@zeet/web-ui";
import React, { useEffect, useRef, useState } from "react";
import { Terminal } from "xterm";
import { ZTerm } from "~/components/ZTerm";
import config from "~/utils/config";

export const DeploymentTerminal: React.FC<
  {
    deploymentID: string;
    fillContainer?: true;
    type?: "ephemeral" | "default";
    containerName?: string;
  } & SearchableProps
> = ({ deploymentID, fillContainer, type, containerName, ...rest }) => {
  const [accessToken] = useAccessToken();

  const xTermRef = useRef<Terminal>(null);
  const TERMINAL_CLOSE_CODE = 4998;

  const ws = useRef<WebSocket | null>(null);
  useEffect(() => {
    const interval = setInterval(() => {
      if (ws.current?.readyState === WebSocket.OPEN) {
        ws.current.send(
          JSON.stringify({
            Op: "ping",
          })
        );
      }
    }, 10_000);

    return () => clearInterval(interval);
  });

  useEffect(() => {
    const terminal = xTermRef.current;
    if (!accessToken || !terminal) {
      return;
    }

    const params = new URLSearchParams({
      token: accessToken,
      deployment_id: deploymentID,
    });

    if (type) params.set("type", type);
    if (containerName) params.set("container_name", containerName);

    ws.current = new WebSocket(
      config.WS_ANCHOR_URL + "/terminal/ws?" + params.toString()
    );
    ws.current.onopen = () => {
      terminal.clear();
      ws.current?.send(
        JSON.stringify({
          Op: "resize",
          Cols: terminal.cols,
          Rows: terminal.rows,
        })
      );
    };
    ws.current.onclose = (e) => {
      if (e.code === TERMINAL_CLOSE_CODE) return;

      terminal.write(
        "Disconnected! The container you are trying to connect may not exist, is currently being created or in an unhealthy state. Try again if you think this is temporary.\n"
      );
    };
    ws.current.onmessage = (ev) => {
      const msg = JSON.parse(ev.data);
      if (msg.Op === "stdout") {
        terminal.write(msg.Data);
      }
    };

    return () => {
      ws.current?.close(TERMINAL_CLOSE_CODE);
    };
  }, [accessToken, deploymentID, containerName, type]);

  useEffect(() => {
    const terminal = xTermRef.current;
    if (terminal) {
      terminal.focus();
    }
  }, []);

  return (
    <ZTerm
      fillContainer={fillContainer}
      onData={(data) => {
        if (ws.current?.readyState === ws.current?.OPEN) {
          ws.current?.send(
            JSON.stringify({
              Op: "stdin",
              Data: data,
            })
          );
        }
      }}
      onResize={() => {
        if (ws.current?.readyState === ws.current?.OPEN) {
          ws.current?.send(
            JSON.stringify({
              Op: "resize",
              Cols: xTermRef.current?.cols,
              Rows: xTermRef.current?.rows,
            })
          );
        }
      }}
      xterm={xTermRef}
      {...rest}
    />
  );
};

export const useTerminalPopup = ({
  deploymentID,
}: {
  deploymentID?: string;
}): {
  onOpen: () => void;
  isOpen: boolean;
  Terminal: () => JSX.Element | null;
} => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const Terminal = (): JSX.Element | null => {
    const [searching, setSearching] = useState(false);

    return (
      <Modal onClose={onClose} isOpen={isOpen} preserveScrollBarGap>
        <ModalOverlay />
        <ModalContent maxWidth="1000px" mt="5%">
          <ModalHeader>
            <Flex alignItems="center">
              <Text>Zeet Terminal</Text>
              <Spacer />
              <SearchButton searching={searching} setSearching={setSearching} />
              <Button
                ml={2}
                mr={6}
                size="sm"
                colorScheme="brand"
                as={Link}
                _hover={{ textDecoration: "none" }}
                target="_blank"
                href={`/popout/terminal/${deploymentID}`}
                rightIcon={<ExternalLinkIcon marginTop={-1} />}
              >
                Open in new tab
              </Button>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {deploymentID && (
              <DeploymentTerminal
                deploymentID={deploymentID}
                searching={searching}
                setSearching={setSearching}
              />
            )}
          </ModalBody>
          <ModalFooter>
            {/* eslint-disable-next-line zeet/no-disclosure-reuse */}
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };
  return { onOpen, isOpen, Terminal };
};
