import {
  CloudProvider,
  LambdaCloudRunBlueprintsQuery,
  useLambdaCloudRunBlueprintsQuery,
  UserDeployTargetsQuery,
  useUserDeployTargetsQuery,
} from "@zeet/web-api/dist/graphql";
import {
  CenterLoading,
  NewResourceValues,
  useCurrentTeamUser,
} from "@zeet/web-ui";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { sortByConnectedStatus } from "~/components/Cloud/CloudProviderSelector";
import { setDemoFormValues } from "~/components/utils/deploy";
import { getCloudOptions } from "~/features/Project/New/ConfigureStep/Targets/ProviderSelectorMenu";
import { useDeploy } from "~/features/Project/New/useDeploy";

const noop = () => null;

type DemoProject = {
  deployTargetsQueryData?: UserDeployTargetsQuery;
  blueprintsData?: LambdaCloudRunBlueprintsQuery | undefined;
  deployTargetsRefetch: () => void;
  blueprintsRefetch: () => void;
  cloudProvider?: CloudProvider;
  isManualLoading?: boolean;
  deployDemo: () => void;
};

const demoProject = createContext<DemoProject>({
  deployTargetsRefetch: noop,
  blueprintsRefetch: noop,
  deployDemo: noop,
});

const DemoProjectProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const currentTeamUser = useCurrentTeamUser();
  const {
    data: deployTargetsQueryData,
    loading,
    refetch: deployTargetsRefetch,
  } = useUserDeployTargetsQuery({
    variables: {
      id: currentTeamUser.id,
    },
  });

  const { data: blueprintsData, refetch: blueprintsRefetch } =
    useLambdaCloudRunBlueprintsQuery({
      variables: {
        owner: "zeet",
        lambdaBlueprintSlug: "zeet-aws-lambda",
        cloudRunBlueprintSlug: "zeet-gcp-cloud-run",
      },
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
    });

  const { setValue, getValues } = useFormContext<NewResourceValues>();
  const cloudOptions = useMemo(() => {
    return getCloudOptions(
      [CloudProvider.Aws, CloudProvider.Gcp],
      deployTargetsQueryData
    ).sort(sortByConnectedStatus);
  }, [deployTargetsQueryData]);

  const cloudProvider = useMemo(() => {
    if (
      deployTargetsQueryData?.user.awsAccounts?.find((aws) => aws.connected)
    ) {
      return CloudProvider.Aws;
    }
    if (
      deployTargetsQueryData?.user.gcpAccounts?.find((gcp) => gcp.connected)
    ) {
      return CloudProvider.Gcp;
    }
  }, [deployTargetsQueryData]);

  const [initiateDeploy, setInitiateDeploy] = useState(false);
  const [isManualLoading, setIsManualLoading] = useState(false);

  const { deploy, deployIsLoading } = useDeploy();

  useEffect(() => {
    if (initiateDeploy) {
      setIsManualLoading(true);
    }
  }, [initiateDeploy]);

  useEffect(() => {
    setIsManualLoading(deployIsLoading);
  }, [deployIsLoading]);

  useEffect(() => {
    setDemoFormValues(
      cloudProvider,
      blueprintsData,
      cloudOptions,
      setValue,
      currentTeamUser
    );

    if (initiateDeploy && getValues("target.provider")) {
      deploy();
      setInitiateDeploy(false);
    }
  }, [
    cloudProvider,
    cloudOptions,
    blueprintsData,
    initiateDeploy,
    currentTeamUser,
    setValue,
    deploy,
    getValues,
  ]);

  const deployDemo = () => {
    setInitiateDeploy(true);
  };

  if (loading) {
    return <CenterLoading />;
  }

  return (
    <demoProject.Provider
      value={{
        deployTargetsQueryData,
        deployTargetsRefetch,
        blueprintsData,
        blueprintsRefetch,
        cloudProvider,
        deployDemo,
        isManualLoading,
      }}
    >
      {children}
    </demoProject.Provider>
  );
};

export const DemoProjectProviderWrapper: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const currentTeamUser = useCurrentTeamUser();

  const methods = useForm<NewResourceValues>({
    defaultValues: {
      userID: currentTeamUser.id,
      blueprintID: "",
      blueprintSlug: undefined,
      blueprintType: undefined,
      blueprint: {},
      source: {},
      target: {},
      variables: {},
      organize: {},
    },
  });
  return (
    <FormProvider {...methods}>
      <DemoProjectProvider>{children}</DemoProjectProvider>
    </FormProvider>
  );
};

export function useDemoProject(): DemoProject {
  return useContext(demoProject);
}
