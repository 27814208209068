import { Card, Flex, Heading } from "@chakra-ui/react";
import {
  ClusterDetailV1Fragment,
  ObservabilityResourceSelector,
  useResourcesWithLogsQuery,
} from "@zeet/web-api/dist/graphqlv1";
import { CenterLoading, SearchButton, useCurrentTeamUser } from "@zeet/web-ui";
import { useState } from "react";
import { LogsConsole } from "~/components/LogsConsole/LogsConsole";

interface ResourceDetailLogsProps {
  projectId: string;
  selector: ObservabilityResourceSelector;
  cluster: ClusterDetailV1Fragment;
}

export const ResourceDetailLogs: React.FC<ResourceDetailLogsProps> = ({
  projectId,
  selector,
  cluster,
}) => {
  const [isSearching, setSearching] = useState(false);
  const { id: teamId } = useCurrentTeamUser();
  const { data, loading } = useResourcesWithLogsQuery({
    variables: {
      teamId,
      projectId,
      selector,
      clusterId: cluster.id,
    },
    pollInterval: 10000,
  });
  const log = data?.team?.project?.resources?.[0]?.log?.[0];

  if (loading) {
    return <CenterLoading />;
  }

  return (
    <Card flexDir="column" mt="5">
      <Flex alignItems="center" p={3} pl="4" minHeight="56px">
        <Heading size="sm">Container Logs</Heading>
        <Flex ml="auto" gap="2">
          <SearchButton searching={isSearching} setSearching={setSearching} />
        </Flex>
      </Flex>
      {log?.entries ? (
        <LogsConsole
          height="350px"
          lines={log?.entries ?? []}
          isLive={true}
          searching={isSearching}
          setSearching={setSearching}
        />
      ) : (
        <Flex
          p={4}
          py="8"
          opacity="0.5"
          justifyContent="center"
          minHeight="250px"
        >
          No logs found.
        </Flex>
      )}
    </Card>
  );
};
