import { Box } from "@chakra-ui/react";
import { useVirtualizer } from "@tanstack/react-virtual";
import { LogEntry } from "@zeet/web-api/dist/graphql";
import { SearchableProps } from "@zeet/web-ui";
import { parse } from "ansicolor";
import { useAtom } from "jotai";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import stripAnsi from "strip-ansi";
import { useFind } from "~/hooks/useFind";
import { themeAtom } from "../ZTerm";
import themeMap from "../zTermSchemes";
import { ParsedSpanView } from "./ParsedSpanView";
import { SearchBar } from "./SearchBar";
import {
  breakUpRange,
  formatLogLine,
  isMacOs,
  measureCharSize,
  splitLines,
} from "./util";

// ideas borrowed from https://github.com/lensapp/lens/blob/master/packages/core/src/renderer/components/dock/logs/list.tsx#L290
// code is not. theirs is kinda awful
interface LogsConsoleProps extends SearchableProps {
  lines: LogEntry[];
  isLive?: boolean;
  height?: string;
  withBorder?: boolean;
}

export const LogsConsole = ({
  lines,
  isLive,
  searching,
  height,
  setSearching,
  withBorder,
}: LogsConsoleProps) => {
  const lineHeight = 20;
  const [query, setQuery] = useState("");
  const {
    currentIndex,
    currentMatch,
    nextMatch,
    prevMatch,
    total,
    getMatchesForLineIndex,
  } = useFind(query, false, lines);
  const [containerWidth, setContainerWidth] = useState(0);
  const widthOfChar = useMemo(measureCharSize, []);
  const charsPerLine = Math.floor(containerWidth / widthOfChar);
  const [themeId] = useAtom(themeAtom);
  const theme = themeMap[themeId];

  const virtualizer = useVirtualizer({
    count: isLive ? lines.length + 1 : lines.length,
    getScrollElement: () => parentRef.current,
    estimateSize(i) {
      if (i === lines.length || charsPerLine === 0) return lineHeight;

      const intrinsicLines = stripAnsi(lines[i]?.text ?? "").split("\n");
      const numLines = intrinsicLines.reduce(
        (a, c) => a + Math.max(Math.ceil(c.length / charsPerLine), 1),
        0
      );
      return lineHeight * numLines;
    },
    scrollToFn(offset: number) {
      if (!parentRef.current) return;
      parentRef.current.scrollTop = offset;
    },
  });

  const ctrlFListener = (e: React.KeyboardEvent) => {
    const isModifierPressed = isMacOs() ? e.metaKey : e.ctrlKey;
    if (isModifierPressed && e.key.toLowerCase() === "f" && setSearching) {
      e.preventDefault();
      setSearching(!searching);
    }
  };

  const resizeObserver = useMemo(
    () =>
      new ResizeObserver((e) => {
        setContainerWidth(e[0]?.contentRect.width ?? 0);
        virtualizer.measure();
      }),
    [virtualizer]
  );
  const parentRef = useRef<HTMLDivElement | null>(null);
  const parentRefCallback = useCallback(
    (d: HTMLDivElement) => {
      parentRef.current = d;
      if (!d) return;
      setContainerWidth(d.getBoundingClientRect().width);
      resizeObserver.observe(d);
      virtualizer.measure();
    },
    [resizeObserver, virtualizer]
  );

  useEffect(() => {
    if (!parentRef.current) return;
    parentRef.current.scrollTop = parentRef.current.scrollHeight;
  }, [lines, containerWidth]);

  useEffect(() => {
    if (!currentMatch?.lineIndex) return;
    virtualizer.scrollToIndex(currentMatch?.lineIndex, {
      align: "center",
    });
  }, [currentMatch, virtualizer, query]);

  useEffect(() => {
    return () => resizeObserver.disconnect();
  }, [resizeObserver]);

  const withBorderProps = withBorder
    ? {
        borderRadius: "md",
        overflow: "hidden",
        border: "1px solid var(--chakra-colors-chakra-border-color)",
      }
    : {};

  return (
    <Box position="relative" height={height || "500px"} {...withBorderProps}>
      {searching && (
        <SearchBar
          currentIndex={currentIndex}
          nextMatch={nextMatch}
          prevMatch={prevMatch}
          setQuery={setQuery}
          total={total}
          setSearching={setSearching}
        />
      )}
      <Box
        overflow="auto"
        height="100%"
        ref={parentRefCallback}
        px={4}
        onKeyDown={ctrlFListener}
        tabIndex={0}
        overscrollBehavior="contain"
        background={theme?.colors["terminal.background"]}
        color={theme?.colors["terminal.foreground"]}
      >
        <Box
          height={`${virtualizer.getTotalSize()}px`}
          minH="calc(100% + 1px)"
          position="relative"
          width="100%"
        >
          {virtualizer.getVirtualItems().map((item) => {
            // if we're live, add one more element, the cursor
            if (item.index === lines.length) {
              return (
                <Box
                  position="absolute"
                  top={0}
                  left={0}
                  height="15px"
                  marginBottom="3px"
                  transform={`translateY(${item.start}px)`}
                  width={`${widthOfChar}px`}
                  backgroundColor="currentcolor"
                  data-index={item.index}
                  ref={virtualizer.measureElement}
                />
              );
            }
            const logLine = lines[item.index];
            if (!logLine) {
              return;
            }

            const parsed = splitLines(formatLogLine(logLine), charsPerLine).map(
              (l) => parse(l)
            );
            const matchedFinds = getMatchesForLineIndex(item.index);
            return (
              <Box
                key={item.key}
                position="absolute"
                top={0}
                left={0}
                width={widthOfChar * charsPerLine + "px"}
                transform={`translateY(${item.start}px)`}
                fontFamily="courier-new, courier, monospace"
                fontSize="15px"
                lineHeight={`${lineHeight}px`}
                whiteSpace="pre-wrap"
                wordBreak="break-all"
                _hover={{ background: "rgb(255 255 255 / 15%)" }}
                cursor="default"
                data-index={item.index}
                ref={virtualizer.measureElement}
              >
                {matchedFinds
                  .flatMap((f) => breakUpRange(f, charsPerLine))
                  .map((match) => (
                    <Box
                      width={
                        widthOfChar * (match.endIndex - match.startIndex) + "px"
                      }
                      left={widthOfChar * match.startIndex + "px"}
                      top={lineHeight * match.lineOffset + "px"}
                      height={`${lineHeight}px`}
                      background={
                        match.isActive
                          ? "rgb(255 152 0 / 30%)"
                          : "rgb(2 119 189 / 30%)"
                      }
                      position="absolute"
                      key={match.startIndex + "," + match.endIndex}
                      zIndex={-1}
                    />
                  ))}
                {parsed.map((p, i) => (
                  <React.Fragment key={i}>
                    {p.spans.map((s, j) => (
                      <ParsedSpanView
                        span={s}
                        key={j}
                        themeColors={theme?.colors ?? {}}
                      />
                    ))}
                    <br />
                  </React.Fragment>
                ))}
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
