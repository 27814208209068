import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Spacer, Text } from "@chakra-ui/react";
import { IDataRow, IZeetData, useQueryParams as useQuery } from "@zeet/web-ui";
import { useEffect, useMemo, useReducer, useState } from "react";
import { useHistory } from "react-router-dom";
import { Column } from "react-table";
import {
  LIST_VIEW_TABLE_VIEW_TYPE,
  ListView,
  ListViewFilter,
  ListViewTable,
  ListViewTableColumns,
} from "../ListViewV2";

interface SearchableListLayoutProps {
  id: string;
  data: IZeetData;
  columns: Column<IDataRow>[];
  defaultColumnIds: string[];
  loading?: boolean;

  useQueryParams?: boolean;
  hasNextPage?: boolean;
  pageChanged?(page: number): unknown;
  filterChanged?(filter: string): unknown;

  actionButtons?: React.ReactElement;
}

export const SearchableListLayout = ({
  id,
  data,
  columns,
  defaultColumnIds,
  loading,
  useQueryParams,
  hasNextPage,
  pageChanged,
  filterChanged,
  actionButtons,
}: SearchableListLayoutProps) => {
  const [query, setQuery] = useState("");
  const [page, setPage] = useReducer(
    (p: number, n: { action: "increment" | "set"; amt: number }) => {
      if (n.action === "increment") return p + n.amt;
      return n.amt;
    },
    1
  );

  const history = useHistory();
  const params = useQuery();
  const paramPage = params.get("page");
  const paramQuery = params.get("query");
  useEffect(() => {
    if (!useQueryParams) return;
    if (paramPage) {
      const pageInt = parseInt(paramPage);
      if (!isNaN(pageInt)) setPage({ action: "set", amt: pageInt });
    }
    if (paramQuery && useQueryParams) {
      setQuery(paramQuery);
    }
  }, [paramPage, paramQuery, useQueryParams]);

  useEffect(() => {
    if (pageChanged) {
      pageChanged(page);
    }
    if (useQueryParams) {
      if (page > 1) params.set("page", page.toString());
      else params.delete("page");
      history.replace({ search: "?" + params.toString() });
    }
    // hack to prevent conflict with filterChanged useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageChanged, page, useQueryParams]);

  useEffect(() => {
    if (filterChanged) {
      filterChanged(query);
    }
    if (useQueryParams) {
      if (query) params.set("query", query);
      else params.delete("query");
      history.replace({ search: "?" + params.toString() });
    }
    // hack to prevent conflict with pageChanged useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterChanged, query, useQueryParams]);

  const filteredData = useMemo(() => {
    const lowerQuery = query.toLowerCase();
    return {
      columns: data.columns,
      skipReset: data.skipReset,
      data: data.data.filter((r) =>
        Object.values(r).some(
          (val) => "text" in val && val.text.toLowerCase().includes(lowerQuery)
        )
      ),
    };
  }, [query, data]);

  return (
    <ListView
      id={id}
      formattedData={filteredData}
      columns={columns}
      filterValue={query}
      handleFilterChange={(e) => setQuery(e.target.value)}
      defaultColumnIds={defaultColumnIds}
      defaultViewMode={LIST_VIEW_TABLE_VIEW_TYPE}
      loading={loading ?? false}
    >
      <Flex mt={4}>
        <ListViewFilter inputProps={{ mr: 2 }} />
        <ListViewTableColumns />
        {actionButtons && (
          <>
            <Box ml={2} />
            {actionButtons}
          </>
        )}
      </Flex>
      <ListViewTable mt={4} />
      <Flex mt="32px">
        {page > 1 && (
          <Button onClick={() => setPage({ action: "increment", amt: -1 })}>
            <ArrowBackIcon />
            <Text ml="16px">Back</Text>
          </Button>
        )}
        <Spacer />
        {hasNextPage && (
          <Button onClick={() => setPage({ action: "increment", amt: 1 })}>
            <Text mr="16px">Next</Text>
            <ArrowForwardIcon />
          </Button>
        )}
      </Flex>
    </ListView>
  );
};
