import { DeleteIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Badge,
  Button,
  Divider,
  Flex,
  FormControl,
  Heading,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  BitbucketIntegrationAuthType,
  BitbucketIntegration as BitbucketIntegrationType,
  IntegrationType,
  useCreateBitbucketIntegrationMutation,
  useDeleteIntegrationMutation,
  UserIntegrationsDocument,
  UserIntegrationsQuery,
  useUpdateBitbucketIntegrationMutation,
} from "@zeet/web-api/dist/graphql";
import {
  AnalyticsEvent,
  CopyCode,
  FormLabel,
  Input,
  Link,
  useAccessToken,
  useColors,
  useCurrentTeamUser,
  usePopup,
  useTrack,
} from "@zeet/web-ui";
import React, { useEffect, useMemo, useState } from "react";
import { MdRefresh } from "react-icons/md";
import { SiBitbucket } from "react-icons/si";
import { useMutationError } from "~/hooks/useMutationError";
import config from "~/utils/config";

export const getIntegrationName = (integration: BitbucketIntegrationType) => {
  if (integration.name === "Bitbucket" && integration.account?.username) {
    return integration.account?.username;
  }
  return integration.name ?? integration.account?.username ?? "";
};

export const Bitbucket: React.FC<{
  data: UserIntegrationsQuery | undefined;
  refetch: () => void;
}> = ({ data, refetch }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { bg2 } = useColors();
  const integrations = useMemo(() => {
    return data?.user.integrations?.filter(
      (i) => i.type === IntegrationType.Bitbucket
    );
  }, [data]);

  return (
    <Stack
      w="100%"
      p={4}
      spacing={4}
      shadow="md"
      borderWidth="1px"
      bg={bg2}
      borderRadius="md"
      flexDirection="column"
      id="bitbucket-integration-section"
    >
      <Flex alignItems="center">
        <Flex height="2rem" width="2rem" mr={2}>
          <SiBitbucket size={32} />
        </Flex>

        <Text fontWeight="bold">Bitbucket</Text>
      </Flex>
      <Text>
        Connect Bitbucket to deploy your Projects from Bitbucket repositories.
        Other team members will be able to access this integration.
      </Text>
      <Flex justifyContent="space-between">
        <Link
          my={4}
          to="https://docs.zeet.co/source/bitbucket/"
          isExternal={true}
          alignItems="center"
          display="flex"
          fontWeight="bold"
        >
          Help me set it up
          <ExternalLinkIcon ml={1} fontSize="sm" opacity=".8" />
        </Link>
        <Flex>
          <IconButton
            mr={4}
            onClick={() => {
              refetch();
            }}
            aria-label="refresh"
            icon={<Icon as={MdRefresh} boxSize={6} />}
          />
          <Button colorScheme="brand" onClick={onOpen}>
            Install
          </Button>
        </Flex>
      </Flex>
      {(integrations?.length ?? 0) > 0 && (
        <Table variant="simple" bg={bg2} borderRadius="md">
          <Thead>
            <Tr>
              <Th pl="0">Name</Th>
              <Th>Type</Th>
              <Th textAlign="right" pr="0">
                Actions
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {integrations?.map((integration, i) => (
              <BitbucketIntegration
                key={i}
                integration={integration as BitbucketIntegrationType}
                refetch={refetch}
              />
            ))}
          </Tbody>
        </Table>
      )}
      <BitbucketInstallationModal
        isOpen={isOpen}
        onClose={onClose}
        refetch={refetch}
      />
    </Stack>
  );
};

const BitbucketIntegration: React.FC<{
  integration: BitbucketIntegrationType;
  refetch: () => void;
}> = ({ integration }) => {
  const toast = useToast();
  const currentTeamUser = useCurrentTeamUser();
  const {
    isOpen: isDetailOpen,
    onClose: onDetailClose,
    onOpen: onDetailOpen,
  } = useDisclosure();

  const [deleteIntegration, { loading: deleteLoading }] =
    useDeleteIntegrationMutation({
      onCompleted: (data) => {
        if (data) {
          toast({
            title: "Integration deleted successfully",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      },
      refetchQueries: [
        {
          query: UserIntegrationsDocument,
          variables: { id: currentTeamUser.id },
        },
      ],
    });

  return (
    <>
      <Tr>
        <Td pl="0" py="2">
          {getIntegrationName(integration)}
        </Td>
        <Td py="2">
          {integration.authType === BitbucketIntegrationAuthType.Oauth2 ? (
            <Badge>OAuth2</Badge>
          ) : (
            <Badge>Access Token</Badge>
          )}
        </Td>
        <Td py="2" textAlign="right" pr="0">
          <Flex gap="2" justifyContent="end">
            <Button size="sm" onClick={onDetailOpen}>
              View
            </Button>
            <IconButton
              borderRadius="md"
              aria-label="delete"
              icon={<DeleteIcon />}
              size="sm"
              isLoading={deleteLoading}
              onClick={() => {
                deleteIntegration({
                  variables: {
                    input: {
                      id: integration.id,
                      userID: currentTeamUser.id,
                    },
                  },
                });
              }}
            />
          </Flex>
        </Td>
      </Tr>
      <BitbucketIntegrationModal
        isOpen={isDetailOpen}
        onClose={onDetailClose}
        integration={integration}
      />
    </>
  );
};

const BitbucketInstallationModal = ({ isOpen, onClose, refetch }) => {
  const toast = useToast();
  const { track } = useTrack();
  const { openPopup } = usePopup({
    onPopupClose: () => {
      refetch();
      toast({
        title: "Successfully completed installation",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    },
  });
  const [token] = useAccessToken();
  const currentTeamUser = useCurrentTeamUser();
  const [isAdvancedSetup, setIsAdvancedSetup] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const [friendlyName, setFriendlyName] = useState("");

  const [createBitbucketIntegration, { loading }] =
    useCreateBitbucketIntegrationMutation(
      useMutationError("Failed to complete installation, try again")
    );

  const completeAdvancedInstall = () => {
    if (accessToken) {
      createBitbucketIntegration({
        variables: {
          userId: currentTeamUser.id,
          accessToken,
          name: friendlyName,
        },
        onCompleted: (data) => {
          if (data.createBitbucketIntegration) {
            refetch();
            onClose();
            toast({
              title: "Successfully completed installation",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
          }
        },
      });
    }
  };

  useEffect(() => {
    if (isAdvancedSetup && !isOpen) {
      setIsAdvancedSetup(false);
      setAccessToken("");
    }
  }, [isAdvancedSetup, isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      // eslint-disable-next-line zeet/no-disclosure-reuse
      onClose={onClose}
      size="md"
      isCentered
      preserveScrollBarGap={true}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Install Bitbucket</ModalHeader>
        <ModalBody>
          {isAdvancedSetup ? (
            <Stack gap="8">
              <Stack>
                <Heading size="sm">Step 1: Create Access Token</Heading>
                <Link
                  to="https://support.atlassian.com/bitbucket-cloud/docs/create-a-workspace-access-token/"
                  isExternal={true}
                  alignItems="center"
                  display="flex"
                >
                  Learn more
                  <ExternalLinkIcon ml={1} fontSize="sm" opacity=".8" />
                </Link>
              </Stack>
              <Stack>
                <Heading size="sm">Step 2: Add Access Token</Heading>
                <FormControl>
                  <Input
                    placeholder="Friendly Name"
                    value={friendlyName}
                    onChange={(e) => setFriendlyName(e.target.value)}
                  />
                </FormControl>
                <FormControl>
                  <Input
                    placeholder="Access Token"
                    type="password"
                    value={accessToken}
                    onChange={(e) => setAccessToken(e.target.value)}
                  />
                </FormControl>
                <Button
                  colorScheme="brand"
                  mt="2"
                  isDisabled={!accessToken || !friendlyName}
                  isLoading={loading}
                  onClick={completeAdvancedInstall}
                >
                  Finish Install
                </Button>
              </Stack>
            </Stack>
          ) : (
            <>
              <Flex justifyContent="space-between">
                <Stack gap={1}>
                  <Heading size="sm">Quick setup</Heading>
                  <Text opacity={0.6}>For fast installation</Text>
                </Stack>
                <Button
                  colorScheme="brand"
                  onClick={() => {
                    track(AnalyticsEvent.INSTALL_BITBUCKET);
                    openPopup(
                      `${config.ANCHOR_URL}/oauth/bitbucket?token=${token}&team_id=${currentTeamUser.id}`
                    );
                    onClose();
                  }}
                >
                  Continue
                </Button>
              </Flex>
              <Divider my={4} />
              <Flex justifyContent="space-between">
                <Stack gap={1}>
                  <Heading size="sm">Advanced setup</Heading>
                  <Text opacity={0.6}>For fine-grain access control</Text>
                </Stack>
                <Button
                  colorScheme="gray"
                  onClick={() => setIsAdvancedSetup(true)}
                >
                  Continue
                </Button>
              </Flex>
              <Divider my={4} />
            </>
          )}
          <Link
            my={4}
            to="https://docs.zeet.co/source/bitbucket"
            isExternal={true}
            alignItems="center"
            display="flex"
          >
            Help me set it up
            <ExternalLinkIcon ml={1} fontSize="sm" opacity=".8" />
          </Link>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const BitbucketIntegrationModal = ({
  isOpen,
  onClose,
  integration,
}: {
  isOpen: boolean;
  onClose: () => void;
  integration: BitbucketIntegrationType;
}) => {
  const currentTeamUser = useCurrentTeamUser();
  const toast = useToast();
  const [friendlyName, setFriendlyName] = useState(
    getIntegrationName(integration)
  );

  useEffect(() => {
    if (getIntegrationName(integration) !== friendlyName && !isOpen) {
      setFriendlyName(getIntegrationName(integration));
    }
  }, [isOpen, integration, friendlyName]);

  const [updateIntegration, { loading }] =
    useUpdateBitbucketIntegrationMutation(
      useMutationError("Failed to update integration")
    );

  const saveIntegration = () => {
    updateIntegration({
      variables: {
        userId: currentTeamUser.id,
        id: integration.id,
        name: friendlyName,
      },
      onCompleted: () => {
        onClose();
        toast({
          title: "Integration updated successfully",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      },
      refetchQueries: [
        {
          query: UserIntegrationsDocument,
          variables: { id: currentTeamUser.id },
        },
      ],
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      // eslint-disable-next-line zeet/no-disclosure-reuse
      onClose={onClose}
      size="md"
      isCentered
      preserveScrollBarGap={true}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Bitbucket Integration</ModalHeader>
        <ModalBody>
          <Stack gap="4">
            <Stack gap="0">
              <FormLabel>Name</FormLabel>
              <Input
                value={friendlyName}
                onChange={(e) => setFriendlyName(e.target.value)}
              />
            </Stack>

            <Stack gap="0">
              <FormLabel>Webhook URL</FormLabel>
              <CopyCode value={integration.webhookUrl} sensitive />
            </Stack>

            <Stack gap="0">
              <FormLabel>Webhook Secret Token</FormLabel>
              <CopyCode value={integration.webhookToken} sensitive />
            </Stack>

            <Stack gap="0">
              <FormLabel>Integration ID</FormLabel>
              <CopyCode value={integration.id} />
            </Stack>

            <Button
              colorScheme="brand"
              mt="2"
              isLoading={loading}
              onClick={saveIntegration}
            >
              Save
            </Button>
          </Stack>
        </ModalBody>
        <ModalFooter />
      </ModalContent>
    </Modal>
  );
};
