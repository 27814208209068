import { useQueryParams } from "@zeet/web-ui";
import { ProjectDetailTabProps } from "..";
import { BackNavLayout } from "../components/BackNavLayout";
import { useProjectPath } from "../hooks/useProjectPath";
import { JobRunDetail } from "../Jobs/JobRunDetail";
import { JobList } from "./JobList";
import { JobRunList } from "./JobRunList";

const JobsTab = ({ project }: ProjectDetailTabProps) => {
  const query = useQueryParams();
  const jobId = query.get("jobId");
  const runId = query.get("runId");
  const path = useProjectPath();

  if (jobId) return <JobRunList jobId={jobId} />;
  if (!runId) return <JobList projectId={project.id} />;

  return (
    <BackNavLayout linkText="Back to jobs" linkTo={`${path}/jobs`}>
      <JobRunDetail jobRunId={runId} />
    </BackNavLayout>
  );
};

export default JobsTab;
