import { useToast } from "@chakra-ui/react";
import { CloudProvider } from "@zeet/web-api/dist/graphql";
import { AnalyticsEvent, useTrack } from "@zeet/web-ui";
import { UseFormReturn } from "react-hook-form";
import { SettingsNameFormFields } from "../../../Layouts/Settings";
import { mutations } from "./mutations";
import { FormValues } from "./SettingsTab/Form";

interface UserFormSubmissionArgs {
  cloudId: string;
  cloudProvider: CloudProvider;
  refetch: () => void;
  reset: UseFormReturn<FormValues>["reset"];
}

const mutationNoop = () => ({});

export const useFormSubmission = ({
  cloudId,
  refetch,
  reset,
  cloudProvider,
}: UserFormSubmissionArgs) => {
  const toast = useToast();
  const { track } = useTrack();
  const createMutation = mutations[cloudProvider] ?? mutationNoop;
  const [updateCloud, { loading }] = createMutation({
    onError: (error) => {
      if (error) {
        toast({
          title: "Error updating cloud",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        track(AnalyticsEvent.UPDATE_CLOUD_ERROR, { error, cloudProvider });
      }
    },
    onCompleted: (data) => {
      const {
        updateGCPAccount,
        updateAWSAccount,
        updateDOAccount,
        updateCoreWeaveAccount,
        updateLinodeAccount,
        updateVultrAccount,
        updateAzureAccount,
      } = data || {};

      if (
        updateGCPAccount ||
        updateAWSAccount ||
        updateDOAccount ||
        updateCoreWeaveAccount ||
        updateLinodeAccount ||
        updateVultrAccount ||
        updateAzureAccount
      ) {
        reset();
        refetch();
        toast({
          title: "Cloud updated",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        track(AnalyticsEvent.UPDATE_CLOUD_SUCCESS, { cloudProvider });
      }
    },
  });

  const onSubmit = (values: FormValues) => {
    track(AnalyticsEvent.UPDATE_CLOUD_SUBMIT, { cloudProvider });

    updateCloud({
      variables: {
        input: {
          id: cloudId,
          name: values[SettingsNameFormFields.NAME] || null,
        },
      },
    });
  };

  return { onSubmit, isSubmitting: loading };
};
