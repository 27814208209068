import { config } from "../config";

const StagingEnv = {
  name: "Staging",
};

const ZorpEnv = {
  name: "Zorp",
};

const EnterpriseEnv = {
  name: "Enterprise",
};

const LocalEnv = {
  name: "Local",
};

let ThisEnv: Record<string, any> | null;

switch (config.ZEET_ENVIRONMENT) {
  case "staging":
    ThisEnv = StagingEnv;
    break;
  case "zorp":
    ThisEnv = ZorpEnv;
    break;
  case "enterprise":
    ThisEnv = EnterpriseEnv;
    break;
  default:
    if (config.isDev) {
      ThisEnv = LocalEnv;
    } else {
      ThisEnv = null;
    }
    break;
}

const isSpecialEnv: () => boolean = () => ThisEnv !== null;
const getEnvName: () => string = () => (ThisEnv ? ThisEnv.name : "");

const isClientSide = () => typeof window !== "undefined";
const isServerSide = () => !isClientSide();

export { isSpecialEnv, getEnvName, isClientSide, isServerSide };
