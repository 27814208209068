import { useUserCrispAuthQuery } from "@zeet/web-api/dist/graphql";
import { CenterLoading, useCurrentTeamUser } from "@zeet/web-ui";
import React, { useEffect, useMemo } from "react";
import { MainContainer } from "~/components/Account/MainContainer";
import { useBillingOverviewContext } from "../../Account/BillingV2/Providers/BillingOverviewProvider";
import { determineSupportLevel } from "../utils";

const AdvancedSupport: React.FC = () => {
  const teamUser = useCurrentTeamUser();
  const [{ userBillingOverview }] = useBillingOverviewContext();

  const { data, loading } = useUserCrispAuthQuery({
    variables: {
      id: teamUser.id,
    },
  });

  const email = useMemo(() => {
    return data?.user.crispAuth?.email;
  }, [data]);

  useEffect(() => {
    if (userBillingOverview) {
      window.$crisp.push([
        "set",
        "session:segments",
        [[determineSupportLevel(userBillingOverview)]],
      ]);
    }
  }, [userBillingOverview, email]);

  if (loading) {
    return <CenterLoading />;
  }

  return (
    <MainContainer
      height="100vh"
      width="100%"
      data-testid="ticket-center-container"
    >
      <iframe
        title="Ticket Center"
        src={`https://plugins.crisp.chat/urn:crisp.im:ticket-center:0/tickets/af81f149-4578-422b-ab29-0a321a32e4f3?email=${email}&hmac=${data?.user.crispAuth?.hmac}&nickname=${data?.user.crispAuth?.nickname}&participants=${data?.user.crispAuth?.participants}`}
        sandbox="allow-forms allow-popups allow-modals allow-scripts allow-same-origin"
        width="100%"
        height="100%"
      ></iframe>
    </MainContainer>
  );
};

export default AdvancedSupport;
