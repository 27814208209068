import { Box, Flex } from "@chakra-ui/react";
import { Container, useColors } from "@zeet/web-ui";
import { NavHead, NavTabList, NavTabType } from "./NavTab";

export const DetailHeader = ({
  title,
  actions,
  tabs,
  fullWidth = true,
}: {
  title: string | React.ReactNode;
  tabs?: NavTabType[];
  children?: React.ReactNode;
  actions?: React.ReactNode;
  fullWidth?: boolean;
}) => {
  const { appSubNavBg } = useColors();

  return (
    <Box
      borderBottomWidth="1px"
      bg={appSubNavBg}
      pt={6}
      fontFamily={"Manrope, var(--chakra-fonts-heading)"}
    >
      <Container fullWidth={fullWidth}>
        <Flex
          w="100%"
          mb={6}
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex alignItems="center" minH="40px">
            {typeof title === "string" ? (
              <NavHead my="0" mb={0}>
                {title}
              </NavHead>
            ) : (
              title
            )}
          </Flex>
          {actions && actions}
        </Flex>
        {tabs && <NavTabList tabs={tabs} ml="-2" />}
      </Container>
    </Box>
  );
};
