import {
  Box,
  BoxProps,
  Link as CLink,
  Flex,
  useBreakpointValue,
} from "@chakra-ui/react";
import {
  Badge,
  Button,
  Flag,
  HorizontalLogo,
  Link,
  useAuthActions,
  useColors,
  useCurrentTeamUser,
  useFeatureFlag,
  useIsLoggedIn,
  useTrack,
} from "@zeet/web-ui";
import React from "react";
import { useLocation } from "react-router-dom";
import config from "~/utils/config";
import { getEnvName, isSpecialEnv } from "~/utils/environment";
import { NAVBAR_HEIGHT } from "../AdmiralHome";
import { SIDEBAR_WIDTH } from "../Sidebar";
import { isInOnboard } from "../TeamOnboard/utils";
import LoggedInBreadCrumbNav from "./Breadcrumbs";
import { CreateMenu } from "./CreateMenu";
import Notifications from "./Notifications";
import { ProfileButton } from "./ProfileMenu";

interface HeaderWrapperProps {
  children: React.ReactNode;
  banner?: React.ReactNode;
  height: string;
}

interface HeaderProps {
  showSidebar: boolean;
}

const HeaderWrapper: React.FC<HeaderWrapperProps & HeaderProps & BoxProps> = ({
  children,
  banner,
  showSidebar,
  height,
  ...boxProps
}) => {
  const { appBg, appContentBorder, appSubNavBg } = useColors();
  const isDesktop = useBreakpointValue({ base: false, md: true });
  const sidebarWidth = showSidebar && isDesktop ? SIDEBAR_WIDTH : "0.5rem";
  const borderRadius = showSidebar ? "0.75rem 0.75rem 0 0" : "0.75rem";

  return (
    <Box
      pos="sticky"
      top="0rem"
      left={sidebarWidth}
      zIndex={100}
      width={`calc(100% - (${sidebarWidth} + 0.5rem))`}
    >
      <Box bg={appBg} h="1rem" />
      <Box
        bg={showSidebar ? appSubNavBg : "transparent"}
        borderRadius={borderRadius}
        border={showSidebar ? `1px solid ${appContentBorder}` : "none"}
        backdropFilter="blur(10px)"
        mt="-0.5rem"
        {...boxProps}
      >
        <Box px={showSidebar ? "16px" : "0"}>
          <Flex
            height={height}
            alignItems="center"
            justifyContent="space-between"
          >
            {children}
          </Flex>
        </Box>

        {banner}
      </Box>
    </Box>
  );
};

const Header = ({ showSidebar }: HeaderProps): JSX.Element | null => {
  const isLoggedIn = useIsLoggedIn();
  const location = useLocation();

  if (!isLoggedIn) {
    return null;
  }

  return (
    <HeaderWrapper showSidebar={showSidebar} height={NAVBAR_HEIGHT}>
      {isInOnboard(location.pathname) ? (
        <OnboardMenu />
      ) : (
        <Menu showSidebar={showSidebar} />
      )}
    </HeaderWrapper>
  );
};

const Menu = ({ showSidebar }: HeaderProps): JSX.Element | null => {
  const showMenuShortcuts = useBreakpointValue({ base: false, md: true });
  const { track } = useTrack();
  const hasSupportEnabled = useFeatureFlag(Flag.SupportCenter, {
    devEnabled: true,
  });

  return (
    <>
      <LoggedInBreadCrumbNav showSidebar={showSidebar} />
      <Flex h="100%" w="100%" align="center" justify="flex-end" fontSize="15px">
        {isSpecialEnv() ? <Badge>{getEnvName()?.toUpperCase()}</Badge> : <></>}

        {showMenuShortcuts && (
          <>
            {!hasSupportEnabled && (
              <>
                {window.$crisp && (
                  <CLink
                    variant="unstyled"
                    _hover={{ textDecoration: "none", opacity: 1 }}
                    opacity="0.5"
                    px={2}
                    onClick={() => {
                      track("click_nav_contact");
                      window.$crisp.push(["do", "chat:toggle"]);
                    }}
                  >
                    Support
                  </CLink>
                )}
              </>
            )}

            <CLink
              href={config.docsURL}
              target="_blank"
              rel="noopener noreferrer"
              variant="unstyled"
              opacity="0.5"
              _hover={{ textDecoration: "none", opacity: 1 }}
              px={2}
            >
              Docs
            </CLink>
          </>
        )}
        <Notifications />
        <CreateMenu />
        <ProfileButton />
      </Flex>
    </>
  );
};

const OnboardMenu: React.FC = (): JSX.Element | null => {
  const { track } = useTrack();
  const { logout } = useAuthActions();
  const currentTeamUser = useCurrentTeamUser();

  return (
    <>
      <Flex
        to={`/${currentTeamUser?.login || ""}`}
        as={Link}
        height="100%"
        alignItems="center"
      >
        <HorizontalLogo width="260px" />
      </Flex>
      <Flex>
        <Flex alignItems="center">
          <Button
            variant="secondary"
            onClick={() => {
              logout();
              track("click_nav_logout");
            }}
          >
            Logout
          </Button>
        </Flex>
      </Flex>
    </>
  );
};

export default Header;
