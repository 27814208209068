import {
  TeamCloudQuery,
  UserTeamMemberQuery,
  useTeamCloudQuery,
  useUserTeamMemberQuery,
} from "@zeet/web-api/dist/graphql";
import { useCurrentTeam } from "@zeet/web-ui";
import React, { createContext, useContext, useMemo } from "react";

interface ConsoleUsersContextValues {
  teamMembers?: UserTeamMemberQuery;
  teamMembersLoading?: boolean;
  teamCloudData?: TeamCloudQuery;
  teamCloudDataLoading?: boolean;
}

interface ConsoleUsersContextActions {
  refetchTeamMembers: () => void;
  refetchTeamCloud: () => void;
}
type ConsoleUsersContext = [
  ConsoleUsersContextValues,
  ConsoleUsersContextActions
];

const noop = () => null;

const consoleUsersContext = createContext<ConsoleUsersContext>([
  // default values
  {},
  {
    refetchTeamMembers: noop,
    refetchTeamCloud: noop,
  },
]);

export const ConsoleUsersProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const currentTeam = useCurrentTeam();

  const {
    data: teamMemberData,
    refetch: refetchTeamMembers,
    loading: teamMembersLoading,
  } = useUserTeamMemberQuery({
    variables: {
      id: currentTeam?.id,
    },
  });

  const {
    data: teamCloudData,
    refetch: refetchTeamCloud,
    loading: teamCloudDataLoading,
  } = useTeamCloudQuery({
    variables: {
      id: currentTeam?.id,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  const actions: ConsoleUsersContextActions = useMemo(
    () => ({
      refetchTeamMembers,
      refetchTeamCloud,
    }),
    [refetchTeamMembers, refetchTeamCloud]
  );

  return (
    <consoleUsersContext.Provider
      value={[
        {
          teamMembers: teamMemberData,
          teamMembersLoading,
          teamCloudData,
          teamCloudDataLoading,
        },
        actions,
      ]}
    >
      {children}
    </consoleUsersContext.Provider>
  );
};

export function useConsoleUsersContext(): ConsoleUsersContext {
  return useContext(consoleUsersContext);
}
