import { Box, PropsOf } from "@chakra-ui/react";
import React from "react";

export const Card: React.FC<PropsOf<typeof Box>> = ({
  children,
  ...restBoxProps
}) => {
  return (
    <Box borderRadius="lg" overflow="hidden" borderWidth={1} {...restBoxProps}>
      {children}
    </Box>
  );
};
