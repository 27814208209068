"use client";

import * as Sentry from "@sentry/react";
import {
  CloudProvider,
  UserTeamsQuery,
  useUserTeamsAnalyticsQuery,
} from "@zeet/web-api/dist/graphql";
import { useCallback, useEffect } from "react";
import { useCurrentUser } from "../components/Auth";
import { useCurrentTeam } from "../components/Team";
import { AnalyticsEvent, useCapture, usePosthog } from "../posthog-react";
import { isServerSide } from "../utils/environment";
import { scopedLocalStorage } from "../utils/storage-utils";

export function useAnalyticsUser(): void {
  const currentUser = useCurrentUser();

  const { data: userTeams } = useUserTeamsAnalyticsQuery();

  const posthog = usePosthog();

  //koala
  useEffect(() => {
    if (window["ko"] && userTeams?.currentUser) {
      const ko = window["ko"];
      const user = userTeams.currentUser;
      if (user.email) {
        user.teams?.map((userTeam) => {
          ko.identify(user.email, {
            role: userTeam.role,
            $account: {
              group_id: userTeam.team.id,
              name: userTeam.team.name,
              has_healthy_cluster: userTeam.team.clusters.clusters?.length || 0,
              team_members: userTeam.team.members.length,
              ...teamBillingData(userTeam.team.user.billingOverview),
            },
          });
        });
      }
    }
  }, [currentUser]);

  // posthog
  useEffect(() => {
    if (posthog && currentUser?.id) {
      posthog.identify(currentUser.id, {
        id: currentUser.id,
        email: currentUser.email,
        name: currentUser.name,
        login: currentUser.login,
        avatar: currentUser.avatar,
        isTeam: currentUser.isTeam,
      });
    }
  }, [currentUser, posthog]);

  // sentry
  useEffect(() => {
    Sentry.configureScope((scope) => {
      if (currentUser) {
        scope.setUser({
          id: currentUser.id,
          email: currentUser.email || "",
          username: currentUser.login,
        });
      } else {
        scope.setUser(null);
      }
    });
  }, [currentUser]);

  //crisp
  useEffect(() => {
    try {
      if (window["$crisp"] && currentUser && !localStorage.getItem("notrack")) {
        if (currentUser?.login) {
          window["$crisp"].push(["set", "user:nickname", currentUser.login]);
        }
        if (currentUser?.email) {
          window["$crisp"].push(["set", "user:email", currentUser.email]);
        }
        if (currentUser?.avatar) {
          window["$crisp"].push(["set", "user:avatar", currentUser.avatar]);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }, [currentUser]);

  // hubspot
  useEffect(() => {
    const _hsq = (window._hsq = window._hsq || []);
    if (_hsq && currentUser) {
      _hsq.push([
        "identify",
        {
          id: currentUser.id,
          email: currentUser.email,
          name: currentUser.name,
          login: currentUser.login,
          avatar: currentUser.avatar,
          isTeam: currentUser.isTeam,
        },
      ]);
    }
  }, [currentUser]);
}

export function useTrack(): { track: ReturnType<typeof useCapture> } {
  const posthogTrack = useCapture();
  const posthog = usePosthog();
  const currentUser = useCurrentUser();
  const currentTeam = useCurrentTeam();

  const notrack = useCallback(() => {}, []);

  const _track = (posthogTrack, currentUser, eventName, eventProperties) => {
    if (currentUser) {
      eventProperties = Object.assign(
        { user_id: currentUser.id },
        eventProperties
      );
    }

    posthogTrack(eventName, eventProperties);

    if (window.dataLayer && window.dataLayer.push) {
      window.dataLayer.push({
        event: eventName,
        ...eventProperties,
      });
    }
  };

  const track = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (eventName: string, eventProperties?: Record<string, any>) => {
      if (eventName === AnalyticsEvent.CONNECTED_CLOUD_PROVIDER) {
        if (!scopedLocalStorage.getItem(currentTeam?.id, "cloud_connected")) {
          scopedLocalStorage.setItem(
            currentTeam?.id,
            "cloud_connected",
            "true"
          );

          _track(
            posthogTrack,
            currentUser,
            "first_time_cloud_connected",
            eventProperties
          );

          if (
            scopedLocalStorage.getItem(currentTeam?.id, "registration_type") ===
            "cloud"
          ) {
            _track(
              posthogTrack,
              currentUser,
              "user_registration_complete",
              Object.assign({ goal: "cloud" }, eventProperties)
            );
          }
        }

        posthog?.people.set_once({
          cloud_provider_connected: true,
        });

        const cloudProviders = Object.values(CloudProvider);

        if (cloudProviders.includes(eventProperties?.cloudProvider)) {
          posthog?.people.set_once({
            [`cloud_provider_${eventProperties?.cloudProvider?.toLowerCase()}_connected`]:
              true,
          });
        }
      } else if (eventName === "view_app_dashboard") {
        if (
          !scopedLocalStorage.getItem(currentTeam?.id, "view_app_dashboard")
        ) {
          scopedLocalStorage.setItem(
            currentTeam?.id,
            "view_app_dashboard",
            "true"
          );

          _track(
            posthogTrack,
            currentUser,
            "first_time_project_deployed",
            eventProperties
          );

          if (
            scopedLocalStorage.getItem(currentTeam?.id, "registration_type") ===
            "deploy"
          ) {
            _track(
              posthogTrack,
              currentUser,
              "user_registration_complete",
              Object.assign({ goal: "deploy" }, eventProperties)
            );
          }
        }
      } else if (eventName === AnalyticsEvent.DEPLOYED_DEMO) {
        posthog?.people.set_once({
          deployed_demo: true,
        });
      } else if (eventName === AnalyticsEvent.DEPLOYED_REPOSITORY) {
        if (eventProperties?.deployTarget) {
          posthog?.people.set_once({
            [`deployed_on_${eventProperties?.deployTarget?.toLowerCase()}`]:
              true,
          });
        }
      } else if (eventName === AnalyticsEvent.CONNECTED_GITHUB) {
        posthog?.people.set_once({
          github_connected: true,
        });
      }

      _track(posthogTrack, currentUser, eventName, eventProperties);
    },
    [posthogTrack, currentUser, currentTeam, posthog?.people]
  );

  if (isServerSide() || localStorage.getItem("notrack")) {
    return {
      track: notrack,
    };
  }
  return {
    track,
  };
}

// track component
// caveat: this fires for non visible components too :(
export const Track: React.FC<{
  type: string;
  data?: Record<string, unknown>;
}> = ({ type, data }): null => {
  const { track } = useTrack();
  useEffect(() => {
    track(type, data);
  }, [type, data, track]);
  return null;
};

type BillingQuery = NonNullable<
  UserTeamsQuery["currentUser"]["teams"]
>[0]["team"]["user"]["billingOverview"];

const teamBillingData = (billingOverview: BillingQuery) => {
  const projectUsage = billingOverview?.currentProducts
    ?.flatMap((p) => p.usage)
    .find((u) => u.unit == "active project")?.currentUsage;
  return {
    plan_tier_sku: billingOverview?.currentProducts?.find(
      (p) => p.__typename == "PlanTierV2"
    )?.sku,
    project_pack_sku: billingOverview?.currentProducts?.find(
      (p) => p.__typename == "ProjectPack"
    )?.sku,
    support_tier_sku: billingOverview?.currentProducts?.find(
      (p) => p.__typename == "SupportTier"
    )?.sku,
    api_access_sku: billingOverview?.currentProducts?.find(
      (p) => p.__typename == "ApiAccess"
    )?.sku,
    has_upgraded_products:
      billingOverview?.hasUpgradedProducts /* does this team have 1 or more paid skus */,
    is_billing_setup:
      billingOverview?.isBillingSetup /* stripe subscription set up, or billing exempt */,
    num_active_projects: projectUsage,
  };
};
