import { DataTypes } from "@zeet/web-ui";

export enum BuildArtifactColumnId {
  Image = "artifactImage",
  Repository = "artifactRepository",
  CreatedAt = "artifactCreatedAt",
  Run = "artifactRun",
}

export const buildArtifactColumnIds = Object.values(BuildArtifactColumnId);
export const buildArtifactColumns = [
  {
    id: BuildArtifactColumnId.Image,
    dataType: DataTypes.TEXT,
    minWidth: 400,
    order: 1,
    label: "Image",
    options: [],
  },
  {
    id: BuildArtifactColumnId.Repository,
    dataType: DataTypes.TEXT,
    minWidth: 400,
    order: 2,
    label: "Container registry",
    options: [],
  },
  {
    id: BuildArtifactColumnId.CreatedAt,
    dataType: DataTypes.TIMEAGO,
    minWidth: 150,
    order: 3,
    label: "Created",
    options: [],
  },
  {
    id: BuildArtifactColumnId.Run,
    dataType: DataTypes.LINK,
    minWidth: 150,
    order: 4,
    label: "Build Run",
    options: [],
  },
];
