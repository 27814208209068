import {
  DeploymentStatus,
  JobRunState,
  ProjectStatus,
  WorkflowRunStatus,
  WorkflowRunStepStatus,
} from "@zeet/web-api/dist/graphqlv1";
import { theme } from "@zeet/web-ui";

type StatusMap<TKey extends string> = {
  [key in TKey]: {
    text: string;
    themeColor: theme.StatusColor;
  };
};

export const deploymentStatusMap: StatusMap<DeploymentStatus> = {
  [DeploymentStatus.Deprovisioning]: {
    text: "Deprovisioning",
    themeColor: "active",
  },
  [DeploymentStatus.Healthy]: {
    text: "Healthy",
    themeColor: "good",
  },
  [DeploymentStatus.Inactive]: {
    text: "Inactive",
    themeColor: "neutral",
  },
  [DeploymentStatus.Provisioning]: {
    text: "Provisioning",
    themeColor: "active",
  },
  [DeploymentStatus.Unhealthy]: {
    text: "Unhealthy",
    themeColor: "bad",
  },
  [DeploymentStatus.Unknown]: {
    text: "Unknown",
    themeColor: "neutral",
  },
};

export const jobRunStatusMap: StatusMap<JobRunState> = {
  [JobRunState.JobRunSucceeded]: {
    text: "Succeeded",
    themeColor: "good",
  },
  [JobRunState.JobRunFailed]: {
    text: "Failed",
    themeColor: "bad",
  },
  [JobRunState.JobRunPendingApproval]: {
    text: "Pending Approval",
    themeColor: "neutral",
  },
  [JobRunState.JobRunPendingBuild]: {
    text: "Pending Build",
    themeColor: "neutral",
  },
  [JobRunState.JobRunStarting]: {
    text: "Queued",
    themeColor: "waiting",
  },
  [JobRunState.JobRunRunning]: {
    text: "Running",
    themeColor: "active",
  },
};

export const workflowRunStatusMap: StatusMap<WorkflowRunStatus> = {
  [WorkflowRunStatus.Completed]: {
    text: "Succeeded",
    themeColor: "good",
  },
  [WorkflowRunStatus.Failed]: {
    text: "Failed",
    themeColor: "bad",
  },
  [WorkflowRunStatus.Aborted]: {
    text: "Aborted",
    themeColor: "bad",
  },
  [WorkflowRunStatus.Pending]: {
    text: "Queued",
    themeColor: "waiting",
  },
  [WorkflowRunStatus.InProgress]: {
    text: "Running",
    themeColor: "active",
  },
};

export const workflowRunStepStatusMap: StatusMap<WorkflowRunStepStatus> = {
  [WorkflowRunStepStatus.Aborted]: {
    text: "Aborted",
    themeColor: "bad",
  },
  [WorkflowRunStepStatus.Completed]: {
    text: "Succeeded",
    themeColor: "good",
  },
  [WorkflowRunStepStatus.Error]: {
    text: "Error",
    themeColor: "bad",
  },
  [WorkflowRunStepStatus.Failed]: {
    text: "Failed",
    themeColor: "bad",
  },
  [WorkflowRunStepStatus.Pending]: {
    text: "Pending",
    themeColor: "waiting",
  },
  [WorkflowRunStepStatus.Running]: {
    text: "Running",
    themeColor: "active",
  },
};

export const parseDockerImage = (image: string) => {
  const pathIndex = image.indexOf("/");

  if (pathIndex == -1 || !image.substring(0, pathIndex).match(/[.:]/))
    return { repository: "Docker Hub", image: image };
  else
    return {
      repository: image.substring(0, pathIndex),
      image: image.substring(pathIndex + 1),
    };
};

export const isDeletable = (projectStatus?: ProjectStatus) => {
  return (
    projectStatus === ProjectStatus.Draft ||
    projectStatus === ProjectStatus.Paused
  );
};
