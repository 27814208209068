import invariant from "invariant";
import { posthog, PostHog, PostHogConfig } from "posthog-js";
import React, { createContext, useContext, useState } from "react";
import { useEffectOnce } from "../hooks/useEffectOnce";

const POSTHOG_NOT_INITIALIZED = "__not_initialized__";

const PosthogContext = createContext<
  PostHog | null | typeof POSTHOG_NOT_INITIALIZED
>(POSTHOG_NOT_INITIALIZED);

interface PosthogProviderProps {
  readonly token: string;
  readonly config?: Partial<PostHogConfig>;
  readonly name: string;
  readonly getUniqueId: () => string;
  children?: React.ReactNode;
}

export const PosthogProvider: React.FC<PosthogProviderProps> = ({
  token,
  config = {},
  name,
  getUniqueId,

  children,
}) => {
  invariant(
    token,
    "You *need* to pass at least the token to the <PosthogProvider>."
  );
  //just so we are firmly in react land
  const [postHog, setPosthog] = useState<PostHog | null>(null);

  //we only init once, no matter what, the token and config should be static
  useEffectOnce(() => {
    posthog.init(
      token,
      {
        ...config,
        loaded: (instance) => {
          const uniqueId = getUniqueId();
          if (uniqueId) {
            instance.identify(uniqueId);
          }
          instance.capture(`${name}_app_init`);
          setPosthog(instance);
        },
      },
      name
    );
  });

  return (
    <PosthogContext.Provider value={postHog}>
      {children}
    </PosthogContext.Provider>
  );
};

export function usePosthog(): PostHog | null {
  const contextVal = useContext(PosthogContext);
  invariant(
    contextVal !== POSTHOG_NOT_INITIALIZED,
    "Tried to access posthog context from outside the PosthogProvider. Did you forget to wrap in the <PosthogProvider>?"
  );
  return contextVal;
}
