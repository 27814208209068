import { AspectRatio, Flex, Text } from "@chakra-ui/react";
import {
  Maybe,
  MetricAnnotation,
  MetricEntry,
  MetricType,
} from "@zeet/web-api/dist/graphqlv1";
import { ECOption, ZChart } from "@zeet/web-ui";
import { format } from "date-fns";
import {
  getMetricSuffix,
  metricToChartData,
  processAnnotations,
} from "./utils";

interface MetricSeries {
  name?: string;
  entries?: Maybe<MetricEntry[]>;
  annotations?: Maybe<MetricAnnotation[]>;
}

interface ResourceDetailMetricChartProps {
  name?: string;
  metrics: (MetricSeries | undefined)[];
  metricType?: Maybe<MetricType>;
  chartOverride?: ECOption;
}

export const ResourceDetailMetricChart: React.FC<
  ResourceDetailMetricChartProps
> = ({ name, metrics, metricType, chartOverride }) => {
  const chartOptions: ECOption = {
    title: {},
    grid: {
      top: 25,
      bottom: 5,
      left: 45,
      ...(chartOverride?.grid ?? {}),
    },
    series: metrics?.map((metric) => {
      const annotationOptions = processAnnotations(metric?.annotations) || {};

      return {
        name: metric?.name,
        type: "line",
        smooth: false,
        symbol: "none",
        data: metricToChartData(metricType, metric?.entries),
        markLine: {
          data: annotationOptions.markLine?.data,
          symbol: ["none", "none"],
          label: {
            formatter: (params) =>
              (
                params.name ||
                params.value + getMetricSuffix(metricType, metric?.entries)
              )?.toString() || "",
            position: "end",
            fontStyle: "normal",
            fontWeight: "normal",
            color: "gray",
            overflow: "truncate",
            ellipsis: "...",
          },
          lineStyle: {
            width: 1,
            color: "gray",
          },
        },
        markPoint: annotationOptions.markPoint,
      };
    }),
    xAxis: {
      type: "time",
      boundaryGap: false,
      axisLabel: { show: true },
      ...(chartOverride?.xAxis ?? {}),
    },
    yAxis: [
      {
        type: "value",
        boundaryGap: [0, "33%"],
        axisLabel: {
          formatter:
            "{value} " + getMetricSuffix(metricType, metrics?.[0]?.entries),
        },
      },
    ],
    tooltip: {
      trigger: "axis",
      formatter: (params) => {
        return `
        <h4 style="font-weight: 700; font-size: 1rem;">${format(
          params[0].axisValue,
          "MMM dd - HH:mm"
        )}</h4>
        <hr style="margin: 0 -10px;"/>
        ${params
          .map((val, index) => {
            return `<p><strong style="color: ${params[index].color};">
          ${params[index].data[1]} ${
              metrics.length > 1
                ? params[index].seriesName
                : getMetricSuffix(metricType, metrics?.[0]?.entries)
            }
          </strong></p>
          `;
          })
          .join("")}
        `;
      },
      ...(chartOverride?.tooltip ?? {}),
    },
  };

  return (
    <Flex flex="1" direction="column" alignItems="center">
      <AspectRatio ml="3" minHeight="200px" width="100%" ratio={16 / 9}>
        <ZChart options={chartOptions} />
      </AspectRatio>
      <Text mt="3">{name ?? metricType}</Text>
    </Flex>
  );
};
