import {
  GithubUserType,
  TeamGithubReposQuery,
} from "@zeet/web-api/dist/graphql";
import { OpenPopupFn, useOrgStorage } from "@zeet/web-ui";
import { useEffect, useMemo } from "react";
import { GithubOrg } from "./github";
import { useGithubContext } from "./githubContext";

interface useGithubOrgType {
  orgs: GithubOrg[];
  activeOrg?: GithubOrg;
  orgsWithType: InstallationWithType[];
  activeOrgWithType?: InstallationWithType;
  setActiveOrg: React.Dispatch<React.SetStateAction<string>>;
  manageOrgs: OpenPopupFn;
}

export interface InstallationWithType {
  installation: NonNullable<
    NonNullable<TeamGithubReposQuery["team"]>["user"]["githubInstallations"]
  >[0];
  type: InstallationType;
}

export enum InstallationType {
  USER = "user",
  TEAM = "team",
}

export function useGithubOrg(): useGithubOrgType {
  const [lastActiveOrg, setActiveOrg] = useOrgStorage("");
  const { data, loading, openPopup, teamData } = useGithubContext();

  const installations = useMemo(() => {
    let output: GithubOrg[] = [];
    if (data?.currentUser?.githubInstallations) {
      output = [...output, ...data.currentUser.githubInstallations];
    }
    if (teamData?.team?.user.githubInstallations) {
      output = [...output, ...teamData.team.user.githubInstallations];
    }
    return output;
  }, [data, teamData]);

  const installationsWithType = useMemo(() => {
    let output: InstallationWithType[] = [];
    if (data?.currentUser?.githubInstallations) {
      const temp: InstallationWithType[] = [];
      data.currentUser.githubInstallations.map((gi) => {
        const newGi: InstallationWithType = {
          installation: gi,
          type: InstallationType.USER,
        };
        temp.push(newGi);
      });

      output = [...output, ...temp];
    }
    if (teamData?.team?.user.githubInstallations) {
      const temp: InstallationWithType[] = [];
      teamData.team.user.githubInstallations.map((gi) => {
        const newGi: InstallationWithType = {
          installation: gi,
          type: InstallationType.TEAM,
        };
        temp.push(newGi);
      });

      output = [...output, ...temp];
    }
    return output;
  }, [data, teamData]);

  useEffect(() => {
    if (installations.length > 0 && !lastActiveOrg) {
      setActiveOrg(installations[0]?.account?.login);
    }
  }, [installations, lastActiveOrg, setActiveOrg]);

  const orgs = useMemo(() => {
    const userLogin = data?.currentUser.login;
    return loading || !userLogin
      ? []
      : installations.slice().sort((a, b) => {
          if (a?.account?.login === lastActiveOrg) {
            return -2;
          }
          if (userLogin === a?.account?.login) {
            return -1;
          }
          if (a?.account?.type === b?.account?.type) {
            return 0;
          }
          return a?.account?.type === GithubUserType.User ? -1 : 1;
        }) || [];
  }, [data?.currentUser.login, loading, installations, lastActiveOrg]);

  const activeOrg = useMemo(() => {
    return (
      orgs.filter((org) => org.account.login === lastActiveOrg)[0] || orgs[0]
    );
  }, [orgs, lastActiveOrg]);

  const orgsWithType = useMemo(() => {
    const userLogin = data?.currentUser.login;
    return loading || !userLogin
      ? []
      : installationsWithType.slice().sort((a, b) => {
          if (a?.installation.account?.login === lastActiveOrg) {
            return -2;
          }

          if (userLogin === a?.installation.account?.login) {
            //me first
            return -1;
          }

          if (a?.installation.account?.type === b?.installation.account?.type) {
            return 0;
          }

          return a?.installation.account?.type === GithubUserType.User ? -1 : 1;
        }) || [];
  }, [lastActiveOrg, data, loading, installationsWithType]);

  const activeOrgWithType = useMemo(() => {
    return (
      installationsWithType.filter(
        (org) => org.installation.account.login === lastActiveOrg
      )[0] || orgsWithType[0]
    );
  }, [installationsWithType, lastActiveOrg, orgsWithType]);

  return {
    orgs,
    activeOrg,
    orgsWithType,
    activeOrgWithType,
    setActiveOrg,
    manageOrgs: openPopup,
  };
}
