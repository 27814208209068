import { HStack, useDisclosure } from "@chakra-ui/react";
import {
  FilterCriterionOperatorType,
  useProjectEnvironmentsBasicQuery,
  useProjectsQuery,
  useUserBasicProjectsQuery,
} from "@zeet/web-api/dist/graphql";
import { Link, useCurrentTeamUser, useDebounce } from "@zeet/web-ui";
import { useState } from "react";
import CreateGroupSubgroupModal from "~/components/Project/Environments/CreateGroupSubgroupModal";
import { projectPath } from "../../Project/util";
import Switcher from "./Switcher";

interface Match<P = Record<string, string>> {
  params: P;
  url: string;
}

export const ProjectBreadcrumb = ({
  match,
}: {
  match: Match<{ teamName: string; projectName: string }>;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const currentTeamUser = useCurrentTeamUser();

  const { data, loading, error } = useUserBasicProjectsQuery({
    variables: { id: currentTeamUser.id },
  });
  const projects = data?.user.projects.nodes;

  if (error) {
    console.error(error);
  }

  return (
    <>
      <CreateGroupSubgroupModal isOpen={isOpen} onClose={onClose} />
      <HStack>
        <Link to={match.url} noUnderline noOfLines={1} wordBreak="break-all">
          {match.params.projectName}
        </Link>
        <Switcher
          title="Projects"
          loading={loading}
          items={
            projects?.map((project) => ({
              name: project.name,
              link: `/${projectPath(project)}/_default`,
              settingsLink: `/${projectPath(project)}/_settings`,
            })) || []
          }
          createItem={{
            text: "New Group",
            onClick: onOpen,
          }}
        />
      </HStack>
    </>
  );
};

export const EnvBreadcrumb = ({
  match,
}: {
  match: Match<{ teamName: string; projectName: string; envName: string }>;
}) => {
  const currentTeamUser = useCurrentTeamUser();

  const { data, loading, error } = useProjectEnvironmentsBasicQuery({
    skip: match.params.envName === "_default",
    variables: { path: `${match.params.teamName}/${match.params.projectName}` },
  });
  const environments = data?.project?.environments;

  if (error) {
    console.error(error);
  }

  if (match.params.envName === "_default") {
    return null;
  }

  return (
    <HStack>
      <Link to={match.url} noUnderline noOfLines={1} wordBreak="break-all">
        {match.params.envName}
      </Link>
      {!error && (
        <Switcher
          title="Sub-Groups"
          loading={loading}
          items={
            environments?.map((env) => ({
              name: env.name,
              link: `/${currentTeamUser.login}/${match.params.projectName}/${env.name}`,
              settingsLink: `/${currentTeamUser.login}/${match.params.projectName}/${env.name}/_settings`,
            })) || []
          }
          createItem={{
            link: `/${currentTeamUser.login}/${match.params.projectName}?create=true`,
            text: "New Sub-Group",
          }}
        />
      )}
    </HStack>
  );
};

export const RepoBreadcrumb = ({
  match,
}: {
  match: Match<{
    teamName: string;
    projectName: string;
    envName: string;
    repoName: string;
  }>;
}) => {
  const currentTeamUser = useCurrentTeamUser();
  const [afterCursor] = useState("0");
  const [filter, setFilter] = useState("");
  const debouncedFilter = useDebounce(filter, 250);

  const { data, error, loading } = useProjectsQuery({
    variables: {
      userId: currentTeamUser?.id as string,
      filterInput: {
        filter: {
          criterion: {
            resourceAdapterFilter: {
              projectName: {
                operator: FilterCriterionOperatorType.Equals,
                value: match.params.projectName,
              },
              environmentName: {
                operator: FilterCriterionOperatorType.Equals,
                value: match.params.envName,
              },
              name: {
                operator: FilterCriterionOperatorType.Contains,
                value: debouncedFilter,
              },
            },
          },
        },
        page: {
          first: 100,
          after: afterCursor,
        },
      },
    },
    errorPolicy: "all",
  });

  const projects = data?.user.projectV3Adapters?.nodes;

  return (
    <HStack>
      <Link to={match.url} noUnderline noOfLines={1} wordBreak="break-all">
        {match.params.repoName}
      </Link>
      {!error && (
        <Switcher
          title="Resources"
          loading={loading}
          items={
            projects?.map((project) => {
              const path = `/${match.params.teamName}/${match.params.projectName}/${match.params.envName}/${project.name}`;
              return {
                name: project.name,
                link: path,
                settingsLink: `${path}/settings`,
              };
            }) || []
          }
          createItem={{
            text: "New Project",
            link: `/new`,
          }}
          filter={filter}
          filterOnChange={(e) => {
            setFilter(e.currentTarget.value);
          }}
        />
      )}
    </HStack>
  );
};
