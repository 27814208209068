import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import { Box, Flex, useColorMode } from "@chakra-ui/react";
import { PropsOf } from "@emotion/react";
import {
  ChakraStylesConfig,
  GroupBase,
  SingleValueProps,
  ValueContainerProps,
} from "chakra-react-select";
import React from "react";
import { Option, Select } from "..";

export const ThemeSwitcher: React.FC<
  Omit<PropsOf<typeof Box>, "className"> & { chakraStyles?: ChakraStylesConfig }
> = (boxProps, chakraStyles) => {
  const { colorMode, setColorMode } = useColorMode();

  const ValueContainer = ({
    children,
    ...props
  }: ValueContainerProps<
    Option<"light" | "dark">,
    false,
    GroupBase<Option<"light" | "dark">>
  >) => {
    return (
      <Flex alignItems="center" pl={1} pr={2}>
        {props.hasValue && props.getValue()[0]?.value === "dark" ? (
          <MoonIcon w="1.5rem" mr={0.5} />
        ) : (
          <SunIcon w="1.5rem" mr={0.5} />
        )}
        {children}
      </Flex>
    );
  };

  const SingleValue = ({
    children,
  }: SingleValueProps<
    Option<"light" | "dark">,
    false,
    GroupBase<Option<"light" | "dark">>
  >) => {
    return <span>{children}</span>;
  };

  return (
    <Box onClick={(e) => e.stopPropagation()} ml={2}>
      <Select
        {...boxProps}
        {...chakraStyles}
        focusBorderColor="brandVar"
        borderRadius="md"
        pointerEvents="all"
        size="sm"
        value={colorMode}
        onChange={(e) => setColorMode(e?.value)}
        variant="outline"
        isSearchable={false}
        components={{
          ValueContainer,
          SingleValue,
        }}
        options={[
          { value: "dark", label: "Dark" },
          { value: "light", label: "Light" },
        ]}
      />
    </Box>
  );
};
