import { useColorMode } from "@chakra-ui/react";
import Color from "color";
import { useMemo } from "react";

interface HoverStates<TProperty extends string> {
  _hover: { [key in TProperty]: string };
  _active: { [key in TProperty]: string };
}

export const useHoverStates = <TProperty extends string = "backgroundColor">(
  color: string,
  property?: TProperty,
  hoverFactor = 0.2,
  activeFactor = 0.4
): HoverStates<TProperty> => {
  const { colorMode } = useColorMode();
  const isDark = colorMode === "dark";

  const colorObject = useMemo(() => Color(color), [color]);
  const hoverColor = useMemo(
    () =>
      (isDark
        ? colorObject.lighten(hoverFactor)
        : colorObject.darken(hoverFactor)
      ).hex(),
    [colorObject, hoverFactor, isDark]
  );

  const activeColor = useMemo(
    () =>
      (isDark
        ? colorObject.lighten(activeFactor)
        : colorObject.darken(activeFactor)
      ).hex(),
    [colorObject, activeFactor, isDark]
  );

  return {
    _hover: {
      [property || "backgroundColor"]: hoverColor,
    },
    _active: {
      [property || "backgroundColor"]: activeColor,
    },
  } as HoverStates<TProperty>;
};
