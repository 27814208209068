import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { DeploymentDetailFragment } from "@zeet/web-api/dist/graphql";
import React from "react";
import { BuildTemplates } from "./BuildTemplates";

export const DockerfileModal: React.FC<{
  onClose: () => void;
  isOpen: boolean;
  deployment: DeploymentDetailFragment;
}> = ({ onClose, isOpen, deployment }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      preserveScrollBarGap={true}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Dockerfile </ModalHeader>
        <ModalBody>
          <BuildTemplates deployment={deployment} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
