import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Box, Flex, Stack, Text } from "@chakra-ui/react";
import { CopyCode, Link, Tooltip, useColors } from "@zeet/web-ui";
import React from "react";

interface RowProps {
  code: string;
  text: string;
}

const Row: React.FC<RowProps> = ({ code, text }) => {
  const requiresTooltip = (text: string): boolean => {
    return ["project", "environment", "repo"].includes(text.toLowerCase());
  };

  const generateTooltipText = (text: string): string => {
    const map: Record<string, string> = {
      project:
        "Project (Legacy naming still used in API) refers to a Project Group",
      environment:
        "Environment (Legacy naming still used in API) refers to a Sub-Group",
      repo: "Repo (Legacy naming still used in API) refers to a Zeet Project",
    };
    return map[text.toLowerCase()] ?? "";
  };

  return (
    <Flex width="100%" alignItems="center">
      <Flex flex={2}>
        <Text>{text}</Text>
        {requiresTooltip(text) && <Tooltip text={generateTooltipText(text)} />}
      </Flex>
      <CopyCode flex={8} value={code} />
    </Flex>
  );
};

interface APIIdentifiersSettings {
  identifiers: Array<{ title: string; value?: string }>;
}

export const APIIdentifiersSettings: React.FC<APIIdentifiersSettings> = ({
  identifiers,
}) => {
  const { bg2 } = useColors();

  return (
    <Box width="100%">
      <Box p={4} shadow="md" borderWidth="1px" bg={bg2} borderRadius="md">
        <Text fontWeight="bold" fontSize="1.2rem">
          API Identifiers
        </Text>

        <Text mt={2}>
          Use API identifiers to interact with the
          <Link
            display="inline-flex"
            alignItems="center"
            ml={1}
            isExternal
            to="https://docs.zeet.co/graphql/"
          >
            Zeet API.
            <ExternalLinkIcon ml={1} fontSize="sm" opacity=".8" />
          </Link>
        </Text>

        <Flex mt={4}>
          <Stack width="100%" spacing={1}>
            {identifiers.map((identifier) => (
              <Row
                key={identifier.title}
                text={identifier.title}
                code={identifier.value ?? ""}
              />
            ))}
          </Stack>
        </Flex>
      </Box>
    </Box>
  );
};
