import { useProjectV3Context } from "~/components/Project/ProjectV3Provider";
import { ResourcesTab } from ".";

export const RepoResourceTab = () => {
  const { projectInfoData } = useProjectV3Context();
  const project = projectInfoData?.team?.project;

  if (!project) {
    return null;
  }

  return <ResourcesTab project={project} />;
};
