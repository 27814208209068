import { Switch } from "@chakra-ui/react";
import { BlueprintType } from "@zeet/web-api/dist/graphql";
import {
  BlueprintDriverWorkflowStepAction,
  ProjectDetailFragment,
  UpdateDeployInput,
} from "@zeet/web-api/dist/graphqlv1";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { SettingsSection } from "../SettingsSection";

const isDefaultChecked = (
  project: ProjectDetailFragment,
  values: UpdateDeployInput
) => {
  return (
    (project.blueprint?.type === BlueprintType.Helm ||
      project.blueprint?.type === BlueprintType.Terraform ||
      project.blueprint?.type === BlueprintType.KubernetesManifest) &&
    values.configuration?.defaultWorkflowSteps?.includes(
      BlueprintDriverWorkflowStepAction.DriverApprove
    )
  );
};

export const makeDefaultSteps = (requireApproval: boolean) => {
  if (requireApproval) {
    return [
      BlueprintDriverWorkflowStepAction.DriverPlan,
      BlueprintDriverWorkflowStepAction.DriverApprove,
      BlueprintDriverWorkflowStepAction.DriverApply,
    ];
  }
  return [
    BlueprintDriverWorkflowStepAction.DriverPlan,
    BlueprintDriverWorkflowStepAction.DriverApply,
  ];
};

export const DeployProtection: React.FC<{
  project: ProjectDetailFragment;
}> = ({ project }) => {
  const { setValue, getValues } = useFormContext<UpdateDeployInput>();

  // determine if the blueprint type has an approval step
  // if it has an approval step and the approval step is missing, it should default to false
  const values = getValues();
  const defaultChecked = useMemo(() => {
    if (isDefaultChecked(project, values)) {
      return true;
    }

    return false;
  }, [values, project]);

  return (
    <SettingsSection
      name="Deploy protection"
      description="Require manual approval before each deployment"
    >
      <Switch
        name="spot"
        size="lg"
        onChange={(e) => {
          setValue(
            "configuration.defaultWorkflowSteps",
            makeDefaultSteps(e.target.checked),
            {
              shouldDirty: true,
            }
          );
        }}
        colorScheme="brand"
        defaultChecked={defaultChecked}
        data-testid="require-approval-toggle"
      />
    </SettingsSection>
  );
};
