import { CloudProvider } from "@zeet/web-api/dist/graphql";
import { FieldValues, useFormContext } from "react-hook-form";
import { CloudProviderView } from "~/components/Cloud/CloudProviderView";
import { InputContainer, InputProps } from "./InputContainer";
import { useInputContext } from "./InputProvider";

interface CloudProviderInputProps<TFormData>
  extends Omit<InputProps<TFormData>, "path"> {
  awsPath?: string;
  doPath?: string;
  gcpPath?: string;
  linodePath?: string;
}

export const CloudProviderInput = <TFormData extends FieldValues>(
  props: CloudProviderInputProps<TFormData>
) => {
  const cloud = useGetCloudInfo(props);
  if (!cloud) return null;
  return (
    <InputContainer
      {...props}
      path={cloud.path}
      render={(p) => (
        <CloudProviderView
          accountId={cloud.id}
          provider={cloud.provider}
          data-testid={p["data-testid"]}
        />
      )}
    />
  );
};

const useGetCloudInfo = <TFormData extends FieldValues>(
  props: CloudProviderInputProps<TFormData>
) => {
  const { resolvePath } = useInputContext();
  const { getValues } = useFormContext();
  const awsPath = props.awsPath || "awsAccountId";
  const gcpPath = props.gcpPath || "gcpAccountId";
  const doPath = props.doPath || "doAccountId";
  const linodePath = props.linodePath || "linodeAccountId";
  const awsValue = getValues(resolvePath(awsPath));
  const gcpValue = getValues(resolvePath(gcpPath));
  const doValue = getValues(resolvePath(doPath));
  const linodeValue = getValues(resolvePath(linodePath));

  if (awsValue) {
    return { path: awsPath, id: awsValue, provider: CloudProvider.Aws };
  }
  if (gcpValue) {
    return { path: gcpPath, id: gcpValue, provider: CloudProvider.Gcp };
  }
  if (doValue) {
    return { path: doPath, id: doValue, provider: CloudProvider.Do };
  }
  if (linodeValue) {
    return {
      path: linodePath,
      id: linodeValue,
      provider: CloudProvider.Linode,
    };
  }

  return null;
};
