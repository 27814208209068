import { ErrorPolicy } from "@apollo/client";
import { useToast } from "@chakra-ui/react";

export const useMutationError = (
  message: string,
  errorPolicy: ErrorPolicy = "none"
) => {
  const toast = useToast();
  return {
    onError() {
      toast({
        title: message,
        status: "error",
      });
    },
    errorPolicy,
  };
};
