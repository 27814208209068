import { Grid, GridItem } from "@chakra-ui/react";
import { MetricStreamV1Fragment } from "@zeet/web-api/dist/graphqlv1";
import { ResourceDetailMetricChart } from "./ResourceDetailMetricChart";

interface ResourceDetailMetricsProps {
  metrics: MetricStreamV1Fragment[];
}

export const ResourceDetailMetrics: React.FC<ResourceDetailMetricsProps> = ({
  metrics,
}) => {
  return (
    <Grid gap={4} alignItems="center" templateColumns="repeat(2, 1fr)">
      {metrics
        .filter((metric) => metric.entries?.length)
        .map((metric, i) => (
          <GridItem key={i}>
            <ResourceDetailMetricChart
              chartOverride={{
                grid: {
                  left: 50,
                  right: 20,
                },
                xAxis: {
                  axisLabel: { show: false },
                },
              }}
              metrics={[metric]}
              metricType={metric.type}
            />
          </GridItem>
        ))}
    </Grid>
  );
};
