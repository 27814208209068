import { BoxProps, Flex, Text } from "@chakra-ui/react";
import { RepoBasicFragment } from "@zeet/web-api/dist/graphql";
import { useColors } from "@zeet/web-ui";
import React from "react";
import { FaDocker } from "react-icons/fa";
import { LanguageIcon } from "./LanguageIcon";

interface EnvRepoItemProps {
  repo: RepoBasicFragment;
  onClick?(): void;

  children?: React.ReactNode;
}

export const EnvRepoItem: React.FC<EnvRepoItemProps & BoxProps> = (
  props: EnvRepoItemProps & BoxProps
) => {
  const { repo } = props;
  const colors = useColors();

  let repoIcon: React.ReactElement;
  if (repo.image === "docker") {
    repoIcon = <FaDocker size="1.5rem" color="#0db7ed" />;
  } else {
    repoIcon = <LanguageIcon language={repo.image} w="1.5rem" h="1.5rem" />;
  }

  return (
    <Flex
      my={3}
      padding={3}
      borderRadius={5}
      backgroundColor={colors.bg2}
      shadow="md"
      alignItems="center"
      {...props}
    >
      <Flex flex={3} minWidth={0}>
        {repoIcon}
        <Text ml={2} fontWeight="600">
          {`${repo?.project?.name}/${repo?.projectEnvironment?.name}/${repo.name}`}
        </Text>
      </Flex>
      {props.children}
    </Flex>
  );
};
