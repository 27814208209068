import { CloudProvider } from "@zeet/web-api/dist/graphql";
import {
  TerraformTargetConfigurationInput,
  UpdateDeployInput,
} from "@zeet/web-api/dist/graphqlv1";
import { useFormContext } from "react-hook-form";
import { CloudProviderInfo } from "~/features/Project/Detail/components/CloudProviderInfo";
import { useProjectSettings } from "../context/ProjectSettingsProvider";

// todo: migrate to a more general "ProviderInput" component.
export const TerraformProviderInput = () => {
  const { getValues, setValue } = useFormContext<UpdateDeployInput>();
  const config = getValues("configuration.terraform.target");
  if (!config) return null;

  const setProviderValue = (
    key: "doAccountId" | "awsAccountId" | "gcpAccountId" | "linodeAccountId",
    value: string
  ) => {
    setValue(`configuration.terraform.target.provider.${key}`, value, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  return (
    <CloudProviderInfo
      config={config}
      regionPath="configuration.terraform.target.provider.region"
      onProviderChange={(v) => {
        if (v?.provider === CloudProvider.Aws) {
          setProviderValue("awsAccountId", v?.value);
        }
        if (v?.provider === CloudProvider.Gcp) {
          setProviderValue("gcpAccountId", v?.value);
        }
        if (v?.provider === CloudProvider.Do) {
          setProviderValue("doAccountId", v?.value);
        }
        if (v?.provider === CloudProvider.Linode) {
          setProviderValue("linodeAccountId", v?.value);
        }
      }}
    />
  );
};

export const TerraformBackendProviderInput = () => {
  const { getValues, setValue } = useFormContext<UpdateDeployInput>();
  const config = getValues("configuration.terraform.target");
  const stateBackend = getValues("configuration.terraform.target.stateBackend");
  const { isDraft } = useProjectSettings();

  if (!config) return null;

  const stateBackendConfig: TerraformTargetConfigurationInput = {
    stateBackend: {},
    provider: {
      awsAccountId: stateBackend?.s3Bucket?.awsAccountId,
      gcpAccountId: stateBackend?.gcsBucket?.gcpAccountId,
      linodeAccountId: stateBackend?.linodeBucket?.linodeAccountId,
    },
  };

  let regionPath =
    "configuration.terraform.target.stateBackend.s3Bucket.region";
  if (stateBackendConfig.provider.gcpAccountId) {
    regionPath =
      "configuration.terraform.target.stateBackend.gcsBucket.location";
  }

  if (stateBackendConfig.provider.linodeAccountId) {
    regionPath =
      "configuration.terraform.target.stateBackend.linodeBucket.region";
  }

  return (
    <CloudProviderInfo
      config={stateBackendConfig}
      regionPath={regionPath}
      editDisabled={!isDraft}
      onProviderChange={(provider) => {
        if (provider?.provider === CloudProvider.Aws) {
          setValue(
            "configuration.terraform.target.stateBackend.s3Bucket.awsAccountId",
            provider?.value,
            { shouldDirty: true, shouldValidate: true }
          );
        } else if (provider?.provider === CloudProvider.Gcp) {
          setValue(
            "configuration.terraform.target.stateBackend.gcsBucket.gcpAccountId",
            provider?.value,
            { shouldDirty: true, shouldValidate: true }
          );
        } else if (provider?.provider === CloudProvider.Linode) {
          setValue(
            "configuration.terraform.target.stateBackend.linodeBucket.linodeAccountId",
            provider?.value,
            { shouldDirty: true, shouldValidate: true }
          );
        }
      }}
    />
  );
};
