import {
  FormControl,
  Heading,
  HStack,
  Input,
  Stack,
  Switch,
  useToast,
} from "@chakra-ui/react";
import {
  RepoDetailFragment,
  useUpdateProjectSettingsMutation,
} from "@zeet/web-api/dist/graphql";
import { ZError } from "@zeet/web-ui";
import React, { useEffect, useState } from "react";
import { FormCard } from "../../FormCard";
import { shouldDisplayError } from "../util";

export const AwsRdsSettings: React.FC<{
  repo: RepoDetailFragment;
}> = ({ repo }) => {
  const toast = useToast();
  const [isDirty, setIsDirty] = useState(false);

  const [updateSettings, { error, loading, data }] =
    useUpdateProjectSettingsMutation({
      onCompleted: (data) => {
        if (data) {
          toast({
            title: "AWS RDS Settings Saved",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      },
    });

  const [version, setVersion] = useState(repo?.terraformVersion || "");
  const [values, setValues] = useState(
    JSON.parse(repo?.terraformVariables || "{}")
  );

  useEffect(() => {
    setVersion(repo?.terraformVersion || "latest");
  }, [repo?.terraformVersion]);

  return (
    <FormCard
      isDisabled={!isDirty}
      isLoading={loading}
      title="Amazon RDS Configuration"
      onSubmit={(e) => {
        e.stopPropagation();
        e.preventDefault();
        updateSettings({
          variables: {
            input: {
              id: repo.id,
              terraformVersion: version,
              terraformVariables: JSON.stringify(values),
            },
          },
        });
      }}
    >
      <FormControl>
        <Stack spacing={2} mt={2}>
          <Heading as="h4" size="sm">
            Database Engine
          </Heading>
          <Input isDisabled={true} value={repo?.databaseEngine || ""} />
          <Heading as="h4" size="sm">
            Database Version
          </Heading>
          <Input
            data-testid="database-version"
            isDisabled={true}
            value={repo?.databaseVersion || ""}
          />
          <Heading as="h4" size="sm">
            Instance Size
          </Heading>
          <Input
            value={values?.instance_class || ""}
            onChange={(e) => {
              setValues({ ...values, instance_class: e.target.value });
              setIsDirty(true);
            }}
          />
          <HStack>
            <Heading as="h4" size="sm">
              Expose to Public
            </Heading>
            <Switch
              isChecked={values?.publicly_accessible || false}
              onChange={(e) => {
                setValues({ ...values, publicly_accessible: e.target.checked });
                setIsDirty(true);
              }}
            />
          </HStack>
          {shouldDisplayError(error, data) && <ZError error={error} />}
        </Stack>
      </FormControl>
    </FormCard>
  );
};
