import { Switch } from "@chakra-ui/react";
import { FC } from "react";

interface SwitchCellProps {
  onChange?: (checked: boolean) => void;
  isChecked?: boolean;
}

export const SwitchCell: FC<SwitchCellProps> = ({ onChange, isChecked }) => {
  return (
    <Switch
      isChecked={isChecked}
      onChange={(e) => onChange?.(e.target.checked)}
    />
  );
};
