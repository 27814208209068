import React, { createContext, useCallback, useMemo } from "react";

const InputContext = createContext({
  basePath: "",
  resolvePath(path: string) {
    return path;
  },
});

interface InputProviderProps extends React.PropsWithChildren {
  basePath: string;
}

export const useInputContext = () => React.useContext(InputContext);

export const InputProvider = ({ basePath, children }: InputProviderProps) => {
  const { resolvePath } = useInputContext();
  const combinedBase = resolvePath(basePath);

  const resolvePathImpl = useCallback(
    (path: string) => {
      if (!path) return combinedBase;
      if (!combinedBase) return path;
      return `${combinedBase}.${path}`;
    },
    [combinedBase]
  );

  const contextValue = useMemo(
    () => ({ basePath: combinedBase, resolvePath: resolvePathImpl }),
    [combinedBase, resolvePathImpl]
  );

  return (
    <InputContext.Provider value={contextValue}>
      {children}
    </InputContext.Provider>
  );
};
