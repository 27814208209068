import { MdSearch } from "react-icons/md";
import { Button } from ".";

export interface SearchableProps {
  searching?: boolean;
  setSearching?(searching: boolean): unknown;
}

export const SearchButton = ({ searching, setSearching }: SearchableProps) => {
  const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    if (setSearching) setSearching(!searching);
  };

  return (
    <Button
      size="lg"
      p={1}
      width={8}
      minWidth={8}
      height={8}
      fontSize={"1em"}
      aria-label="Search"
      variant={searching ? "primary" : "ghost"}
      onClick={onClick}
    >
      <MdSearch />
    </Button>
  );
};
