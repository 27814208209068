import { ApolloError } from "@apollo/client";
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { Button, Link, ZError } from "@zeet/web-ui";
import { useForm } from "react-hook-form";

interface FormData {
  token: string;
}

interface TokenModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleSubmit: (e: string) => void;
  link: string;
  loading: boolean;
  error: ApolloError | undefined;
}

export const TokenModal: React.FC<TokenModalProps> = ({
  isOpen,
  onClose,
  link,
  handleSubmit,
  loading,
  error,
}) => {
  const {
    register,
    formState: { errors: formErrors },
    getValues,
  } = useForm<FormData>({ reValidateMode: "onBlur" });
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="md"
      isCentered
      blockScrollOnMount={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>New Token</ModalHeader>
        <ModalBody pb={6}>
          <Box>
            {error?.message && (
              <Box pb={8}>
                <ZError error={error.message} />
              </Box>
            )}

            <form
              onSubmit={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleSubmit(getValues("token"));
              }}
            >
              <FormControl
                isInvalid={!!formErrors.token}
                as={Flex}
                flexDir="column"
              >
                <FormLabel htmlFor={"name"}>
                  Zeet will automatically reconnect to your cloud with the new
                  token. Need{" "}
                  <Link textDecoration="underline" to={link}>
                    help
                  </Link>{" "}
                  finding your token?
                </FormLabel>
                <Input
                  placeholder={"Token"}
                  {...register("token", {
                    required: "Token is required",
                  })}
                  mt={3}
                />
                <FormErrorMessage>
                  {formErrors.token && formErrors.token.message}
                </FormErrorMessage>
              </FormControl>
              <Flex mt={4} justifyContent={"right"} gap={4} w={"100%"}>
                <Button
                  colorScheme={"brand"}
                  isLoading={loading}
                  type={"submit"}
                  disabled={loading}
                >
                  Replace
                </Button>
              </Flex>
            </form>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
