import {
  Box,
  Divider,
  Flex,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import {
  Maybe,
  ObservabilityConfig,
  ObservabilityResourceSelectorLabel,
  ResourceType,
  useUpdateProjectMutation,
} from "@zeet/web-api/dist/graphqlv1";
import { Button, EmptyBox, IconButton, Input, useColors } from "@zeet/web-ui";
import { useFieldArray, useForm } from "react-hook-form";
import { FiMinusCircle } from "react-icons/fi";
import { SettingsCard } from "../SettingsTab/sections/SettingsCard";

interface ResourceSettingsModalProps {
  projectId: string;
  isOpen: boolean;
  onClose: (refetch?: boolean) => void;
  config?: Maybe<ObservabilityConfig>;
}

export const ResourceSettingsModal: React.FC<ResourceSettingsModalProps> = ({
  projectId,
  isOpen,
  onClose,
  config,
}) => {
  const { bg } = useColors();
  const toast = useToast();

  const defaultLabels =
    config?.selector?.labels?.map((input) => ({
      name: input.name,
      value: input.value,
    })) ?? [];

  const defaultLabel = {
    name: "",
    value: "",
  };

  const { register, control, reset, getValues } = useForm({
    defaultValues: { labels: defaultLabels },
  });

  const { fields, append, update, remove } = useFieldArray({
    control,
    name: "labels",
  });

  const [updateProject, { loading }] = useUpdateProjectMutation({
    onCompleted: () => {
      onClose();
    },
  });

  const closeAndResetForm = (refetch?: boolean) => {
    reset();
    onClose(refetch);
  };

  const removeOrReset = (index: number) => {
    if (index === 0 && getValues().labels.length === 1) {
      update(index, defaultLabel);
    } else {
      remove(index);
    }
  };

  const handleSave = async () => {
    const labelsToSave: ObservabilityResourceSelectorLabel[] = getValues(
      "labels"
    )
      .filter((label) => label.name && label.value)
      .map((label) => ({
        name: label.name,
        value: label.value,
      }));

    if (!labelsToSave.length) {
      toast({
        title: "At least one valid label must be provided",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    await updateProject({
      variables: {
        id: projectId,
        input: {
          observabilityConfig: {
            selector: {
              type: ResourceType.Pod,
              labels: labelsToSave,
            },
          },
        },
      },
    });

    closeAndResetForm(true);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeAndResetForm}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      isCentered
      preserveScrollBarGap={true}
      size="2xl"
    >
      <ModalOverlay bg="blackAlpha.700" />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Resources config</ModalHeader>
        <ModalBody pb="8">
          <SettingsCard
            title="Labels"
            description="These labels serve as the criteria for fetching the list of Kubernetes objects for the resources tab."
          >
            <Box mb="4">
              {fields.length === 0 ? (
                <EmptyBox
                  minHeight="112px"
                  flexDirection="column"
                  gap="2"
                  borderRadius="md"
                  mt="0"
                >
                  <Box>No labels have been added.</Box>
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={() => append(defaultLabel)}
                  >
                    Add label
                  </Button>
                </EmptyBox>
              ) : (
                <>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    pr="12"
                  >
                    <Box display="inline-flex" alignItems="center" flex="1">
                      <FormLabel width="50%">Name</FormLabel>
                      <FormLabel width="50%">Value</FormLabel>
                    </Box>
                  </Box>
                  {fields.map((field, index) => (
                    <Box
                      key={field.id}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Box display="inline-flex" alignItems="center" flex="1">
                        <FormLabel width="50%">
                          <Input
                            backgroundColor={bg}
                            {...register(`labels.${index}.name`)}
                          />
                        </FormLabel>
                        <FormLabel width="50%">
                          <Input
                            backgroundColor={bg}
                            {...register(`labels.${index}.value`)}
                          />
                        </FormLabel>
                      </Box>
                      <IconButton
                        variant="secondary"
                        aria-label="delete"
                        icon={<FiMinusCircle />}
                        borderRadius="md"
                        onClick={() => removeOrReset(index)}
                        mb="2"
                        mr="3"
                      />
                    </Box>
                  ))}
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={() => append(defaultLabel)}
                  >
                    Add label
                  </Button>
                </>
              )}
            </Box>
          </SettingsCard>
          <Divider my="4" />
          <Flex justifyContent="flex-end" gap="4">
            <Button variant="secondary" onClick={() => closeAndResetForm()}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleSave} isLoading={loading}>
              Save
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
