import React from "react";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import { useIsLoggedIn, useLastLoginTime } from ".";
import { useNextUrl } from "../../hooks/useNextUrl";

export const RequireAuth: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [lastLoginTime] = useLastLoginTime();
  const isLoggedIn = useIsLoggedIn();
  const loginUrl = useNextUrl(lastLoginTime ? "login" : "signup");

  if (!isLoggedIn) {
    return <Redirect to={loginUrl} />;
  }

  return (
    <>
      <Helmet>
        <meta name="robots" content="none" />
      </Helmet>
      {children}
    </>
  );
};
