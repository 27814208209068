import { Flex } from "@chakra-ui/react";
import { ProjectSettingsProps } from "../..";
import { SettingsSection } from "../SettingsSection";
import { DuplicateProjectSection } from "./DuplicateProjectSection";
import { OrganizeProjectSection } from "./OrganizeProjectSection";

export const GeneralSettingsSection = ({ project }: ProjectSettingsProps) => {
  return (
    <SettingsSection name="General" border="none">
      <Flex gap="4" flexDirection="column">
        <OrganizeProjectSection project={project} />
        <DuplicateProjectSection project={project} />
      </Flex>
    </SettingsSection>
  );
};
