import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Accordion as ChakraAccordion,
} from "@chakra-ui/react";

interface AccordionProps {
  title: string;
  children: React.ReactNode;
}

export const Accordion = ({ children, title }: AccordionProps) => {
  return (
    <ChakraAccordion
      border="1px solid"
      borderColor="inherit"
      allowToggle
      mt="6"
      mr="3"
      borderRadius="md"
    >
      <AccordionItem border="none">
        <AccordionButton pl="2" pt="4" pb="4">
          <AccordionIcon />
          <Box as="span" flex="1" textAlign="left" fontWeight="medium">
            {title}
          </Box>
        </AccordionButton>
        <AccordionPanel py="4" px="7">
          {children}
        </AccordionPanel>
      </AccordionItem>
    </ChakraAccordion>
  );
};
