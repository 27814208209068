import { useEffect, useRef, useState } from "react";
import { useTrack } from "./Analytics";

export type OpenPopupFn = (url: string) => void;

type PopupOptions = {
  onPopupClose?: () => void;
};

export const usePopup = (
  options?: PopupOptions
): {
  updated: string;
  openPopup: OpenPopupFn;
} => {
  const { track } = useTrack();
  const popup = useRef<Window | null>(null);
  const openPopup: OpenPopupFn = (url: string): void => {
    track("open_github_popup");
    popup.current = window.open(
      url,
      "_blank",
      `toolbar=no, menubar=no, width=1200, height=800, top=${
        (window.screen.height - 800) / 2
      }, left=${(window.screen.width - 1200) / 2}`
    );
  };
  const [updated, setUpdated] = useState("");

  useEffect(() => {
    const cb = (e): void => {
      if (e.data === "ZEET_CLOSE") {
        track("close_github_popup");
        track("login", { method: "github", popup: true });
        setUpdated(new Date().toISOString());
        popup.current?.close();
        options?.onPopupClose?.();
      }
    };

    window.addEventListener("message", cb);
    return () => {
      window.removeEventListener("message", cb);
    };
  }, [options, track]);

  return { updated, openPopup };
};
