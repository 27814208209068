export default function saveAsFile(data: string, filename: string) {
  const element = document.createElement("a");
  const file = new Blob([data], { type: "text/plain" });
  const url = URL.createObjectURL(file);
  element.setAttribute("href", url);
  element.setAttribute("download", filename);
  element.style.display = "none";
  document.body.appendChild(element);
  element.click();
  URL.revokeObjectURL(url);
  document.body.removeChild(element);
}
