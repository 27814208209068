import {
  BlueprintType,
  BuildType,
  CloudProvider,
  CreateResourceAlphaInput,
  DatabaseDeployTargetInput,
  DatabaseEngineType,
  DatabaseProviderType,
  DeployTarget,
  EnvVarInput,
  PortInput,
  PortProtocol,
  VolumeInput,
} from "@zeet/web-api/dist/graphql";
import {
  BlueprintSlug,
  BridgeBlueprintInputId,
  BuildTemplate,
  isLegacyDatabaseBlueprint,
  LegacyBlueprintType,
} from "@zeet/web-ui";
import config from "~/utils/config";
import { NewResourceValues } from "./context";

type OrganizeSettings = {
  userID: string;
  name: string;
  environmentID?: string;
  environmentName?: string;
  projectName?: string;
  projectID?: string;
};

const buildTypes = {
  [BuildTemplate.NextJs]: BuildType.NodeNextjs,
  [BuildTemplate.NodeJs]: BuildType.Node,
  [BuildTemplate.React]: BuildType.NodeStatic,
  [BuildTemplate.Python]: BuildType.Python,
  [BuildTemplate.Ubuntu]: BuildType.Ubuntu,
  [BuildTemplate.Dockerfile]: BuildType.Docker,
  [BuildTemplate.Django]: BuildType.PythonDjango,
  [BuildTemplate.Golang]: BuildType.GolangModules,
  [BuildTemplate.Elixir]: BuildType.ElixirPhoenix,
  [BuildTemplate.Buildpacks]: BuildType.Buildpacks,
};

const legacyDatabaseEngineTypes = {
  [BlueprintSlug.LegacyPostgresKubernetes]: DatabaseEngineType.Postgres,
  [BlueprintSlug.LegacyPostgresRds]: DatabaseEngineType.Postgres,
  [BlueprintSlug.LegacyMySqlKubernetes]: DatabaseEngineType.Mysql,
  [BlueprintSlug.LegacyMySqlRds]: DatabaseEngineType.Mysql,
  [BlueprintSlug.LegacyRedisKubernetes]: DatabaseEngineType.Redis,
  [BlueprintSlug.LegacyMongoDb]: DatabaseEngineType.Mongodb,
};

const getDatabaseEngineVersion = (version?: string): string => {
  return version?.split("v")[1] ?? "";
};

const makePorts = (data: NewResourceValues): PortInput[] => {
  const { variables } = data;

  const isHttpService = variables[
    BridgeBlueprintInputId.NetworkIsHttpService
  ] as boolean;

  if (isHttpService) {
    return [
      {
        port: variables[BridgeBlueprintInputId.NetworkPort] as string,
        protocol: PortProtocol.Tcp,
        public: true,
        https: true,
      },
    ];
  }

  return [];
};

const makeEnvs = (data: NewResourceValues): EnvVarInput[] => {
  const { variables } = data;
  const envs = variables[BridgeBlueprintInputId.EnvironmentVariables];

  if (Array.isArray(envs)) {
    return envs
      .map((env) => ({
        name: env[BridgeBlueprintInputId.EnvironmentVariableKey],
        value: env[BridgeBlueprintInputId.EnvironmentVariableValue],
      }))
      .filter((env) => env.name && env.value);
  }

  return [];
};

const makeVolumes = (data: NewResourceValues): VolumeInput[] => {
  const { variables } = data;
  const volumes = variables[BridgeBlueprintInputId.PersistentVolumes];

  if (Array.isArray(volumes)) {
    return volumes
      .map((volume) => ({
        mountPath: volume[BridgeBlueprintInputId.PersistentVolumePath],
        size: parseInt(
          volume[BridgeBlueprintInputId.PersistentVolumeSize] ?? "0"
        ),
      }))
      .filter((volume) => volume.mountPath && volume.size);
  }

  return [];
};

const makeTerraformVariables = (
  data: NewResourceValues
): { [key: string]: unknown } => {
  const { variables } = data;
  const terraformVariables =
    variables[BridgeBlueprintInputId.TerraformVariables];

  if (Array.isArray(terraformVariables)) {
    return terraformVariables.reduce((acc, variable) => {
      if (
        variable[BridgeBlueprintInputId.TerraformVariableKey] &&
        variable[BridgeBlueprintInputId.TerraformVariableValue]
      ) {
        acc[variable[BridgeBlueprintInputId.TerraformVariableKey]] =
          variable[BridgeBlueprintInputId.TerraformVariableValue];
        return acc;
      }
      return acc;
    }, {});
  }

  return {};
};

const makeOrganizeSettings = (data: NewResourceValues): OrganizeSettings => {
  const { name, environmentName, environmentID, projectName, projectID } =
    data.organize;

  return {
    userID: data.userID,
    name,
    ...(environmentID ? { environmentID } : { environmentName }),
    ...(projectID ? { projectID } : { projectName }),
  };
};

const makeDatabaseDeployTarget = (
  data: NewResourceValues
): DatabaseDeployTargetInput => {
  if (data.target.provider?.kubernetes) {
    return {
      clusterID: data.target.provider?.value,
      provider: DatabaseProviderType.Docker,
    };
  }

  if (data.target.provider?.provider === CloudProvider.Aws) {
    return {
      awsAccountID: data.target.provider?.value,
      provider: DatabaseProviderType.AwsRds,
      region: data.target.region,
    };
  }

  return {} as DatabaseDeployTargetInput;
};

const makeDatabaseSettings = (
  data: NewResourceValues
): CreateResourceAlphaInput["database"] => {
  const { blueprintSlug, variables } = data;

  if (isLegacyDatabaseBlueprint(blueprintSlug)) {
    return {
      deployTarget: makeDatabaseDeployTarget(data),
      engine: legacyDatabaseEngineTypes[blueprintSlug],
      version: getDatabaseEngineVersion(
        variables[BridgeBlueprintInputId.DatabaseEngineVersion] as string
      ),
      options: {
        database: variables[BridgeBlueprintInputId.DatabaseName] as string,
        password: variables[BridgeBlueprintInputId.DatabasePassword] as string,
        username: variables[BridgeBlueprintInputId.DatabaseUsername] as string,
      },
    };
  }
};

const makeServerlessSettings = (
  data: NewResourceValues
): CreateResourceAlphaInput["serverless"] => {
  const { blueprintType, target } = data;

  if (
    blueprintType !== BlueprintType.ZeetAwsLambda &&
    blueprintType !== BlueprintType.ZeetGcpCloudRun
  ) {
    return undefined;
  }

  if (target.provider?.provider === CloudProvider.Aws) {
    return {
      deployTarget: {
        awsAccountID: target.provider?.value,
        deployTarget: DeployTarget.AwsSam,
        region: target.region,
      },
      envs: makeEnvs(data),
      ports: makePorts(data),
    };
  }

  if (target.provider?.provider === CloudProvider.Gcp) {
    return {
      deployTarget: {
        gcpAccountID: target.provider?.value,
        deployTarget: DeployTarget.GcpCloudRun,
        region: target.region,
      },
      envs: makeEnvs(data),
      ports: makePorts(data),
    };
  }
};

const makeBuildSettings = (
  data: NewResourceValues
): CreateResourceAlphaInput["build"] => {
  const { blueprintType, blueprintSlug, variables, source } = data;

  // const isJob = blueprintSlug === BlueprintSlug.JobContainer;

  if (
    (blueprintType === BlueprintType.ZeetAwsLambda ||
      blueprintType === BlueprintType.ZeetGcpCloudRun ||
      blueprintType === BlueprintType.ZeetKubernetes) &&
    !isLegacyDatabaseBlueprint(blueprintSlug) &&
    !source?.containerRegistry
  ) {
    return {
      build: {
        buildType:
          buildTypes[
            variables[BridgeBlueprintInputId.BuildTemplate] as BuildTemplate
          ],
        buildCommand: variables[BridgeBlueprintInputId.BuildCommand] as string,
        dockerfilePath: variables[
          BridgeBlueprintInputId.DockerfilePath
        ] as string,
        golangVersion: variables[BridgeBlueprintInputId.GoVersion] as string,
        nodejsVersion: variables[BridgeBlueprintInputId.NodeVersion] as string,
        pythonVersion: variables[
          BridgeBlueprintInputId.PythonVersion
        ] as string,
        runCommand: variables[BridgeBlueprintInputId.RunCommand] as string,
        staticPath: variables[
          BridgeBlueprintInputId.BuildOutputDirectory
        ] as string,
        workingDirectory: source?.git?.path,
      },
    };
  }
};

const makeKubernetesSettings = (
  data: NewResourceValues
): CreateResourceAlphaInput["kubernetes"] => {
  const { blueprintType, target, variables, blueprintSlug } = data;

  const isJob = blueprintSlug === BlueprintSlug.JobContainer;

  if (
    blueprintType === BlueprintType.ZeetKubernetes &&
    !isLegacyDatabaseBlueprint(data.blueprintSlug)
  ) {
    return {
      deployTarget: {
        deployTarget: DeployTarget.Kubernetes,
        clusterID: target.provider?.value,
      },
      app: {
        deployJob: isJob,
        deployService: !isJob,
        volumes: makeVolumes(data),
        envs: makeEnvs(data),
        ports: makePorts(data),
        resources: {
          cpu: parseFloat(
            variables[BridgeBlueprintInputId.ComputeCpu] as string
          ),
          memory: parseFloat(
            variables[BridgeBlueprintInputId.ComputeMemory] as string
          ),
          spot: variables[
            BridgeBlueprintInputId.ComputeIsSpotInstance
          ] as boolean,
        },
        runCommand: variables[BridgeBlueprintInputId.KubernetesRunCommand],
      },
    };
  }
};

const makeTerraformSettings = (
  data: NewResourceValues
): CreateResourceAlphaInput["terraform"] => {
  const { blueprintType, blueprintSlug, target } = data;

  if (
    blueprintType === LegacyBlueprintType.Terraform &&
    !isLegacyDatabaseBlueprint(blueprintSlug)
  ) {
    return {
      deployTarget: {
        deployTarget: DeployTarget.Terraform,
        awsAccountID: target.provider?.value,
      },
      variables: JSON.stringify(makeTerraformVariables(data)),
    };
  }
};

const makeSourceSettings = (
  data: NewResourceValues
): CreateResourceAlphaInput["source"] => {
  const { source, blueprintSlug, blueprint } = data;

  if (blueprint?.source) {
    return {
      containerRegistry: blueprint.source?.containerRegistry,
    };
  }

  if (!isLegacyDatabaseBlueprint(blueprintSlug)) {
    return source;
  }
};

const makeWorkflowSettings = (
  data: NewResourceValues
): CreateResourceAlphaInput["workflow"] => {
  const { blueprintType, blueprintSlug } = data;

  if (
    blueprintType === LegacyBlueprintType.Terraform &&
    !isLegacyDatabaseBlueprint(blueprintSlug)
  ) {
    return {
      manualDeploy: true,
    };
  }
};

const makeCreateDemoProjectInput = (
  data: NewResourceValues,
  options?: { enabled?: boolean }
): CreateResourceAlphaInput => {
  return {
    ...makeOrganizeSettings(data),
    blueprintID: config.CONTAINER_BLUEPRINT_ID,
    source: {
      git: {
        repository: "https://github.com/zeet-demo/node-express-demo.git",
      },
    },
    build: {
      build: {
        buildType: BuildType.Node,
        buildCommand: "npm --production=false install",
        nodejsVersion: "18",
        runCommand: "npm start",
      },
    },
    kubernetes: {
      deployTarget: {
        deployTarget: DeployTarget.Kubernetes,
        clusterID: data.target.provider?.value,
      },
      app: {
        deployJob: false,
        deployService: true,
        volumes: [],
        envs: [],
        ports: [
          {
            port: "3000",
            protocol: PortProtocol.Tcp,
            public: true,
            https: true,
          },
        ],
        resources: {
          cpu: 1,
          memory: 1,
          spot: false,
        },
      },
    },
    enabled: options?.enabled ?? true,
  };
};

const makeCreateResourceAlphaMutationInput = (
  data: NewResourceValues,
  options?: { enabled?: boolean }
): CreateResourceAlphaInput => {
  if (data.blueprint?.slug === BlueprintSlug.DemoProject) {
    return makeCreateDemoProjectInput(data, options);
  }

  return {
    ...makeOrganizeSettings(data),
    blueprintID: data.blueprintID,
    workflow: makeWorkflowSettings(data),
    source: makeSourceSettings(data),
    database: makeDatabaseSettings(data),
    serverless: makeServerlessSettings(data),
    build: makeBuildSettings(data),
    kubernetes: makeKubernetesSettings(data),
    terraform: makeTerraformSettings(data),
    enabled: options?.enabled ?? true,
  };
};

export { makeCreateResourceAlphaMutationInput };
