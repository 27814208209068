import { useDisclosure, useToast } from "@chakra-ui/react";
import {
  EnvVarDetailFragment,
  useUpdateEnvironmentEnvironmentVariablesMutation,
} from "@zeet/web-api/dist/graphql";
import React from "react";
import { useEnv, useProject } from "../Provider";
import { MenuButtons } from "../Settings/EnvMenuButtons";
import { useEnvVarSettingsContext } from "../Settings/EnvVarSettingsProvider";
import { EnvVarSettings } from "../Settings/EnvVarsSettings";
import { projectPath } from "../util";
import RebuildReposModal from "./RebuildReposModal";
import { useRebuildReposContext } from "./RebuildReposProvider";

interface EnvsProps {
  projectEnvs?: EnvVarDetailFragment[] | null;
}

export const Envs = ({ projectEnvs }: EnvsProps) => {
  const [{ envs }, { parseEnv }] = useEnvVarSettingsContext();
  const [{ repos }] = useRebuildReposContext();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const toast = useToast();
  const env = useEnv();
  const project = useProject();

  const [updateEnvs, { loading, error }] =
    useUpdateEnvironmentEnvironmentVariablesMutation({
      onCompleted: (data) => {
        if (data) {
          onClose();
          toast({
            title: "Environment Variables Saved",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      },
    });

  const onSubmit = () => {
    if (repos.length > 0) {
      onOpen();
    } else {
      updateEnvs({
        variables: {
          input: {
            id: env.id,
            redeployRepoIDs: [],
            envs: envs
              .filter((e) => e.name)
              .map((e) => ({
                name: e.name.trim(),
                value: e.value.trim(),
                sealed: e.sealed,
              })),
          },
        },
      });
    }
  };

  const menuButtons = React.useMemo(() => {
    return <MenuButtons handleEnvFile={parseEnv}></MenuButtons>;
  }, [parseEnv]);

  return (
    <>
      <EnvVarSettings
        actionButtons={menuButtons}
        onSubmit={onSubmit}
        error={error}
        loading={loading}
        inherited={[
          {
            type: "group",
            path: `/${projectPath(project)}/_settings`,
            name: project.name,
            vars: projectEnvs,
          },
        ]}
      />
      <RebuildReposModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};
