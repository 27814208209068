import { Tooltip } from "@chakra-ui/react";
import { IconButton, IconButtonProps } from "@zeet/web-ui";
import { ListViewContextData, useListViewContext } from "./Provider";

interface ListViewViewToggleProps {
  icon: IconButtonProps["icon"];
  label: string;
  viewMode: ListViewContextData["viewMode"];
}

export const ListViewViewToggle = ({
  icon,
  label,
  viewMode,
}: ListViewViewToggleProps) => {
  const { viewMode: activeViewMode, setViewMode } = useListViewContext();

  return (
    <Tooltip label={label} placement="top" hasArrow>
      <IconButton
        variant="secondary"
        aria-label={label}
        icon={icon}
        isActive={activeViewMode === viewMode}
        onClick={() => setViewMode(viewMode)}
      />
    </Tooltip>
  );
};
