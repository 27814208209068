import { Badge, BadgeProps, ThemeTypings, Tooltip } from "@chakra-ui/react";
import {
  CloudResourceFragment,
  ResourceStatus,
} from "@zeet/web-api/dist/graphqlv1";

interface ResourceStatusBadgeProps {
  showText?: boolean;
  cloudResource?: CloudResourceFragment;
  size?: string;
}

export const ResourceStatusBadge: React.FC<
  ResourceStatusBadgeProps & BadgeProps
> = ({ showText, cloudResource, size, ...props }) => {
  if (!cloudResource) {
    return null;
  }

  const badgeSize = size ?? "24px";

  return (
    <Tooltip
      placement="top"
      label={showText ? undefined : cloudResource.status}
    >
      <Badge
        borderRadius="6px"
        fontWeight="600"
        height={badgeSize}
        fontSize="1em"
        mt="1"
        width={showText ? "" : badgeSize}
        colorScheme={getStatusColor(cloudResource.status)}
        backgroundColor={showText ? undefined : "var(--badge-color)"}
        {...props}
      >
        {showText && cloudResource.status}
      </Badge>
    </Tooltip>
  );
};

const getStatusColor = (
  status: ResourceStatus
): ThemeTypings["colorSchemes"] => {
  switch (status) {
    case ResourceStatus.Failed:
      return "red";
    case ResourceStatus.Pending:
    case ResourceStatus.Waiting:
      return "orange";
    case ResourceStatus.Running:
    case ResourceStatus.Succeeded:
      return "brand";
    case ResourceStatus.Terminated:
      return "blue";
    case ResourceStatus.Unknown:
      return "gray";
  }

  return "";
};
