import { AspectRatio, Flex, Text } from "@chakra-ui/react";
import { MetricStreamV1Fragment } from "@zeet/web-api/dist/graphqlv1";
import { ECOption, ZChart } from "@zeet/web-ui";
import { formatLatestMetricData, metricToChartData } from "./utils";

interface ResourceClusterMiniChartProps {
  metric?: MetricStreamV1Fragment;
}

export const ResourceClusterMiniChart: React.FC<
  ResourceClusterMiniChartProps
> = ({ metric }) => {
  if (!metric) {
    return null;
  }

  const chartOptions: ECOption = {
    title: {},
    grid: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
    series: [
      {
        type: "line",
        smooth: false,
        symbol: "none",
        data: metricToChartData(metric.type, metric.entries),
      },
    ],
    xAxis: {
      type: "time",
      show: false,
    },
    yAxis: [
      {
        show: false,
      },
    ],
  };

  return (
    <Flex ml="5">
      <Text>{formatLatestMetricData(metric)}</Text>
      <AspectRatio
        ml="3"
        borderLeft="1px solid"
        borderBottom="1px solid"
        height="24px"
        width="100px"
      >
        <ZChart options={chartOptions} />
      </AspectRatio>
    </Flex>
  );
};
