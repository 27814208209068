import { InputGroup, InputProps } from "@chakra-ui/react";
import React, { RefCallback, useRef } from "react";

export const FileUpload = React.forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    const { name, accept, multiple, children, ...rest } = props;
    const inputRef = useRef<HTMLInputElement | null>(null);
    const handleClick = () => inputRef?.current?.click();
    return (
      <InputGroup onClick={handleClick} {...rest}>
        <input
          ref={(r) => {
            inputRef.current = r;
            ref && (ref as RefCallback<HTMLInputElement>)(r);
          }}
          name={name}
          type={"file"}
          multiple={multiple || false}
          accept={accept}
          hidden
          data-testid="file-upload-input"
        />
        <>{children}</>
      </InputGroup>
    );
  }
);
