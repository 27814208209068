import { Box, Icon, Spinner, Tooltip } from "@chakra-ui/react";
import {
  GenericWorkflowStepActionType,
  WorkflowRunDetailFragment,
  WorkflowRunStatus,
  WorkflowRunStepDetailFragment,
  WorkflowRunStepStatus,
} from "@zeet/web-api/dist/graphqlv1";
import { useColors } from "@zeet/web-ui";
import { IconType } from "react-icons";
import { AiFillMinusCircle } from "react-icons/ai";
import { GoSkipFill } from "react-icons/go";
import { MdCancel, MdCheckCircle, MdWarning } from "react-icons/md";

interface StatusIconProps {
  step: WorkflowRunStepDetailFragment;
  workflowRun: WorkflowRunDetailFragment;
}

interface StatusIconComponent {
  component?: React.FC;
  icon?: IconType;
  color?: string;
  description?: string;
}

export const WorkflowStepStatusIcon = ({
  step,
  workflowRun,
}: StatusIconProps) => {
  const { brand } = useColors();
  const componentMap: Record<
    WorkflowRunStepStatus | "SKIPPED" | "PENDING_APPROVAL",
    StatusIconComponent
  > = {
    [WorkflowRunStepStatus.Aborted]: {
      icon: AiFillMinusCircle,
      color: "gray.400",
    },
    [WorkflowRunStepStatus.Completed]: { icon: MdCheckCircle, color: brand },
    [WorkflowRunStepStatus.Error]: { icon: MdCancel, color: "red.400" },
    [WorkflowRunStepStatus.Failed]: { icon: MdCancel, color: "red.400" },
    [WorkflowRunStepStatus.Running]: {
      component: () => <Spinner boxSize="4" speed="1.2s" />,
    },
    [WorkflowRunStepStatus.Pending]: {
      component: () => (
        <Box
          borderRadius="50%"
          borderWidth={2}
          borderColor="orange.400"
          height={"20px"}
          minHeight="20px"
          width={"20px"}
          minWidth="20px"
        />
      ),
    },
    SKIPPED: {
      component: () => (
        <Icon as={GoSkipFill} boxSize="1.15rem" color="gray.400" />
      ),
    },
    PENDING_APPROVAL: {
      component: () => (
        <Icon as={MdWarning} boxSize="1.25rem" color="orange.400" />
      ),
      description: "Pending manual approval",
    },
  };

  const makeStatusIcon = () => {
    if (!step?.status) {
      return null;
    }
    if (
      step?.status !== WorkflowRunStepStatus.Completed &&
      step?.status !== WorkflowRunStepStatus.Error &&
      step?.status !== WorkflowRunStepStatus.Failed &&
      (workflowRun?.status === WorkflowRunStatus.Aborted ||
        workflowRun?.status === WorkflowRunStatus.Failed)
    ) {
      return componentMap.SKIPPED;
    }
    if (
      (step.status === WorkflowRunStepStatus.Running &&
        step.action === GenericWorkflowStepActionType.DriverApprove) ||
      (step.action === GenericWorkflowStepActionType.OrchestrationDeploy &&
        "workflowRun" in step &&
        step?.workflowRun?.steps?.some(
          (s) =>
            s?.status === WorkflowRunStepStatus.Running &&
            s?.action === GenericWorkflowStepActionType.DriverApprove
        ))
    ) {
      return componentMap.PENDING_APPROVAL;
    }
    return componentMap[step?.status];
  };

  const statusIcon = makeStatusIcon();

  if (!statusIcon) {
    return null;
  }

  if (statusIcon.component) {
    return (
      <Tooltip label={statusIcon.description} zIndex={999}>
        <statusIcon.component />
      </Tooltip>
    );
  }

  return <Icon as={statusIcon.icon} color={statusIcon.color} fontSize={20} />;
};
