"use client";

import { useApolloClient } from "@apollo/client";
import {
  CurrentUserQuery,
  useCurrentUserQuery,
  UserDetailFragment,
} from "@zeet/web-api/dist/graphql";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { config } from "../../config";
import { useTrack } from "../../hooks/Analytics";
import { useLocalStorageOnce } from "../../hooks/useLocalStorage";
import { AnalyticsEvent, usePosthog } from "../../posthog-react";
import { CenterLoading } from "../Loading";
import { useAccessToken } from "./auth";

//last-login-time
const lastLoginTimeKey = "zeet_last_login_time";

export function useLastLoginTime(): [number, () => void | Promise<void>] {
  const [lastLoginTime, setLastLoginTime] =
    useLocalStorageOnce(lastLoginTimeKey);

  const value = lastLoginTime ? Number(lastLoginTime) : 0;
  return [value, () => setLastLoginTime(Date.now().toString())];
}

//wasEverLoggedIn
export function useWasEverLoggedIn(): boolean {
  const [time] = useLastLoginTime();
  return time !== 0;
}

export type CurrentUserType = CurrentUserQuery["currentUser"];

export interface UserContextType {
  currentUser?: CurrentUserType;
}

export const userContext = React.createContext<UserContextType>({});

export const CurrentUserProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [accessToken] = useAccessToken();
  const [loaded, setLoaded] = useState(false);
  const [user, setData] = useState<CurrentUserQuery["currentUser"] | null>();
  const { data, loading, error } = useCurrentUserQuery({
    skip: !accessToken,
  });

  useEffect(() => {
    if (!accessToken) {
      setData(null);
      setLoaded(true);
    } else if (data) {
      setData(data?.currentUser || null);
      setLoaded(true);
    } else if (!loading && !data) {
      // error state
      setData(null);
      setLoaded(true);
    }
  }, [accessToken, data, loading, setData]);

  if (error) {
    console.error("failed to get currentUser", error);
  }

  if (loading || !loaded) {
    return <CenterLoading />;
  }

  return (
    <userContext.Provider value={{ currentUser: user || undefined }}>
      {children}
    </userContext.Provider>
  );
};

export const useCurrentUser = (): UserDetailFragment | undefined => {
  const { currentUser } = useContext(userContext);
  return currentUser;
};

//login state
export function useIsLoggedIn(): boolean {
  const { currentUser } = useContext(userContext);
  return !!currentUser;
}

//auth actions
export function useAuthActions(): {
  login: () => void;
  loginGoogle: (string) => void;
  logout: () => void;
} {
  const postHog = usePosthog();
  const { track } = useTrack();
  const client = useApolloClient();
  const [, setAt] = useAccessToken();

  const login = useCallback(() => {
    window.location.href = `${config.ANCHOR_URL}/login/github`;
  }, []);

  const loginGoogle = useCallback(
    async (idToken) => {
      const res = await fetch(`${config.ANCHOR_URL}/login/google`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: idToken,
        }),
      });
      const json = await res.json();
      track("login", { method: "google" });
      setAt(json.results?.[0]?.auth_token);
      if (json.results?.[0]?.signup) {
        track(AnalyticsEvent.USERS_NEW_SIGNUP, { method: "google" });
      }
    },
    [setAt, track]
  );

  const logout = useCallback(() => {
    fetch(`${config.ANCHOR_URL}/login/logout`, {
      method: "POST",
    });
    setAt("");
    client.cache.reset();
    if (postHog) {
      postHog.reset();
    }
    if (window["ko"]) {
      window["ko"].reset();
    }
    location.href = "https://zeet.co";
  }, [setAt, client.cache, postHog]);

  return { login, loginGoogle, logout };
}

export {
  getOrCreateTempToken,
  getUserId,
  removeTempToken,
  useAccessToken,
} from "./auth";
