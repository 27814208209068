import { useParams } from "react-router-dom";

export const useProjectPath = () => {
  const { teamName, projectName, envName, repoName } = useParams<{
    teamName: string;
    projectName: string;
    envName: string;
    repoName: string;
  }>();

  return `/${teamName}/${projectName}/${envName}/${repoName}`;
};
