const parseGitUri = (url?: string): { owner?: string; repo: string } => {
  if (!url) {
    return { repo: "" };
  }

  const githubUri = url.match(
    /^https:\/\/github.com\/(?<owner>[^/]+)\/(?<repo>[^/]+)$/
  );
  if (githubUri) {
    const output = githubUri.groups as { owner: string; repo: string };
    // parse repo if it contains .git
    if (output.repo.includes(".git")) {
      const revisedUri = url.match(
        /^https:\/\/github.com\/(?<owner>[^/]+)\/(?<repo>[^/]+)(\.git\S+|\.git)$/
      );
      return revisedUri?.groups as { owner: string; repo: string };
    } else {
      return githubUri.groups as { owner: string; repo: string };
    }
  }

  const gitlabUri = url.match(
    /^https:\/\/gitlab.com\/(?<owner>[^/]+)\/(?:.*\/)*(?<repo>[^/]+)$/
  );
  if (gitlabUri) {
    const output = gitlabUri.groups as { owner: string; repo: string };
    if (output.repo.endsWith(".git")) {
      output.repo = output.repo.slice(0, -4);
    }
    return output;
  }

  const bitbucketUri = url.match(
    /^https:\/\/bitbucket.org\/(?<owner>[^/]+)\/(?:.*\/)*(?<repo>[^/]+)$/
  );
  if (bitbucketUri) {
    const output = bitbucketUri.groups as { owner: string; repo: string };
    if (output.repo.endsWith(".git")) {
      output.repo = output.repo.slice(0, -4);
    }
    return output;
  }

  const gitUri = url.match(
    /^https?:\/\/[^/]+(:\d+)?\/(?:.*\/)*(?<repo>[^/]+)\.git\/?$/
  );
  if (gitUri?.groups) {
    return gitUri.groups as { repo: string };
  }

  return { repo: "" };
};

export { parseGitUri };
