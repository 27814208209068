import { Box, Flex, Text } from "@chakra-ui/react";
import { UserTeamMemberQuery } from "@zeet/web-api/dist/graphql";
import { Avatar } from "@zeet/web-ui";
import { FC } from "react";
import { Column } from "react-table";

interface UserUsernameProps {
  member: NonNullable<UserTeamMemberQuery["currentUser"]["team"]>["members"][0];
  column: Column;
}

export const UserUsername: FC<UserUsernameProps> = ({ member, column }) => {
  const getMaxColumnWidth = (width?: string | number, minWidth?: number) => {
    const widthNumber = parseInt((width ?? "0") as string);
    const minWidthNumber = minWidth ?? 0;
    const paddingRight = 60;

    if (widthNumber < minWidthNumber) {
      return minWidthNumber - paddingRight;
    }
    return widthNumber - paddingRight;
  };

  return (
    <Flex>
      <Flex alignItems="center">
        <Avatar size="sm" user={member.user} />
      </Flex>
      <Box ml="10px">
        <Text
          fontSize="14px"
          lineHeight="14px"
          maxW={getMaxColumnWidth(column.width, column.minWidth)}
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          {member.user.name}
        </Text>
        <Text
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
          display="block"
          maxW={getMaxColumnWidth(column.width, column.minWidth)}
          mt="1.5"
          fontSize="12px"
          opacity="0.6"
          lineHeight="12px"
        >
          {member.user.login}
        </Text>
      </Box>
    </Flex>
  );
};
