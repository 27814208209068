import {
  As,
  Badge,
  Button,
  ButtonProps,
  Flex,
  Icon,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link, useColors, useLocalStorage } from "@zeet/web-ui";
import React, { useEffect } from "react";

interface NavButtonProps extends ButtonProps {
  icon?: As;
  imageSourceWhite?: string;
  imageSourceBlack?: string;
  label: string;
  link: string;
  isActive: boolean;
  isNew?: boolean;
}

export const NavButton: React.FC<NavButtonProps> = (props) => {
  const {
    icon,
    label,
    isActive,
    imageSourceWhite,
    imageSourceBlack,
    isNew,
    ...buttonProps
  } = props;
  const { brand, activeBg, activeBorder } = useColors();
  const [touchedNewFeature, setTouchedNewFeature] = useLocalStorage(
    `touched-new-feature:${props.link}`,
    "false"
  );
  const showNewBadge = isNew && touchedNewFeature === "false";
  const color = useColorModeValue("blackAlpha", "whiteAlpha");

  useEffect(() => {
    if (isActive && showNewBadge) {
      setTouchedNewFeature("true");
    }
  }, [isActive, showNewBadge, setTouchedNewFeature]);

  return (
    <Button
      boxShadow="none"
      justifyContent="start"
      px="2"
      _hover={{ opacity: "1", textDecoration: "none" }}
      _active={{ bg: "transparent" }}
      w="100%"
      as={Link}
      to={props.link}
      display="inline-block"
      opacity={isActive ? 1 : 0.4}
      bg={isActive ? activeBg : "transparent"}
      border={isActive ? `1px solid ${activeBorder}` : "1px solid transparent"}
      borderRadius="lg"
      position="relative"
      data-testid={`${label}-menu-button-${isActive ? "active" : "inactive"}`}
      {...buttonProps}
    >
      {showNewBadge && (
        <Badge
          borderRadius="md"
          fontSize="10"
          ml="1"
          p="1"
          position="absolute"
          top="3"
          right="2"
          bg={brand}
          color="#101010"
        >
          New
        </Badge>
      )}
      <Flex height="100%" alignItems="center" gap="2">
        {icon ? (
          <Icon
            color={color === "whiteAlpha" ? "white" : "black"}
            as={icon}
            height="24px"
            width="24px"
          />
        ) : color === "whiteAlpha" ? (
          <Image height="24px" width="24px" src={imageSourceWhite} />
        ) : (
          <Image height="24px" width="24px" src={imageSourceBlack} />
        )}
        <Text
          color={color === "whiteAlpha" ? "white" : "black"}
          fontSize="14px"
        >
          {label}
        </Text>
      </Flex>
    </Button>
  );
};
