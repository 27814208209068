export const scopedLocalStorage: {
  getItem: (scope: string, key: string) => string | null;
  setItem: (scope: string, key: string, value: string) => void;
} = {
  getItem: (scope: string, key: string): string | null => {
    return localStorage.getItem(`${scope}:::${key}`);
  },
  setItem: (scope: string, key: string, value: string): void => {
    return localStorage.setItem(`${scope}:::${key}`, value);
  },
};
