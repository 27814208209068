import {
  RepoDetailFragment,
  useRepoDeployRevisionsQuery,
} from "@zeet/web-api/dist/graphql";
import { TabWrapper } from ".";
import { RevisionsList } from "../Revisions";

interface RevisionsProps {
  repo: RepoDetailFragment;
}

export const RevisionsTab = () => {
  return <TabWrapper component={Revisions} />;
};

const Revisions = ({ repo }: RevisionsProps) => {
  const { data, loading } = useRepoDeployRevisionsQuery({
    variables: {
      repoId: repo.id,
    },
  });

  const revisions = data?.currentUser.repo?.deploymentConfigurationRevisions;
  return <RevisionsList revisions={revisions} loading={loading} />;
};
