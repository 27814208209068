import { useCloudRevisionsQuery } from "@zeet/web-api/dist/graphqlv1";
import { useCurrentTeamUser } from "@zeet/web-ui";
import { RevisionsList } from "../../../../Revisions";

interface RevisionsTabProps {
  cloudId: string;
}

const RevisionsTab = ({ cloudId }: RevisionsTabProps) => {
  const currentUser = useCurrentTeamUser();
  const { data: cloudRevisionsData, loading } = useCloudRevisionsQuery({
    variables: {
      cloudId: cloudId,
      teamId: currentUser.id,
    },
  });

  const revisions =
    cloudRevisionsData?.team?.cloudAccount?.configurationRevisions;
  return <RevisionsList revisions={revisions} loading={loading} />;
};

export default RevisionsTab;
