import { Flex, FormControl } from "@chakra-ui/react";
import {
  AzureAccount,
  useRemoveAzureAccountMutation,
} from "@zeet/web-api/dist/graphql";
import { CopyCode, FormLabel } from "@zeet/web-ui";
import { SettingsDisplayName } from "~/components/Layouts/Settings/SettingsDisplayName";
import {
  SettingsSection,
  SettingsSectionDetails,
  SettingsSections,
} from "../../../../Layouts/Settings";
import { YesNoCell } from "../../../../ListViewV2";
import { CloudDangerZone } from "./CloudDangerZone";

interface AzureDetailsProps {
  azureAccount: AzureAccount;
  refetch: () => void;
}

export const AzureDetails: React.FC<AzureDetailsProps> = ({
  azureAccount,
  refetch,
}) => {
  return (
    <SettingsSections>
      <SettingsSection borderTop="none">
        <SettingsSectionDetails title="General" />
        <Flex gap="4" flexDirection="column">
          <SettingsDisplayName />
          <FormControl>
            <FormLabel>Connected</FormLabel>
            <YesNoCell isYes={azureAccount.connected ?? false} />
          </FormControl>
          <FormControl>
            <FormLabel>Azure Subscription ID</FormLabel>
            <CopyCode value={azureAccount?.subscriptionID ?? ""} width="100%" />
          </FormControl>
          <FormControl>
            <FormLabel>Azure Tenant ID</FormLabel>
            <CopyCode value={azureAccount?.tenantID ?? ""} width="100%" />
          </FormControl>
          <FormControl>
            <FormLabel>Azure Application (Client) ID</FormLabel>
            <CopyCode value={azureAccount?.clientID ?? ""} width="100%" />
          </FormControl>
          <FormControl>
            <FormLabel>Zeet Cloud ID</FormLabel>
            <CopyCode value={azureAccount?.id ?? ""} width="100%" />
          </FormControl>
        </Flex>
      </SettingsSection>

      <SettingsSection mt={12}>
        <SettingsSectionDetails title="Danger zone" />

        <CloudDangerZone
          id={azureAccount.id}
          confirmationString={azureAccount.id}
          useRemoveCloudAccountMutation={useRemoveAzureAccountMutation}
          refetch={refetch}
        />
      </SettingsSection>
    </SettingsSections>
  );
};
