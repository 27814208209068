import { ExternalLinkIcon } from "@chakra-ui/icons";
import { AspectRatio, Button, Flex } from "@chakra-ui/react";
import {
  RepoDetailFragment,
  useCombinedAwsRdsMetricsUpdatedSubscription,
} from "@zeet/web-api/dist/graphql";
import { Card, ECOption, Link, ZChart } from "@zeet/web-ui";
import React, { useMemo } from "react";
import { getEmptyMetricsArray } from "../../utils/array";
import { MetricsSkeleton } from "../MetricsSkeleton";
import { baseOptions } from "./index";

// []string{"CPUUtilization", "DatabaseConnections", "FreeStorageSpace", "FreeableMemory", "ReadLatency", "WriteLatency"}

export const AwsRdsMetrics: React.FC<{
  repo: RepoDetailFragment;
  deploymentID: string;
}> = ({ repo, deploymentID }) => {
  const combinedDeploymentId =
    deploymentID || repo.productionDeployment?.id || "";

  const { data } = useCombinedAwsRdsMetricsUpdatedSubscription({
    variables: { deploymentID: combinedDeploymentId },
    skip: !combinedDeploymentId,
  });

  const CPUUtilization: ECOption = useMemo(() => {
    return {
      ...baseOptions,
      title: {
        text: "CPU Utilization",
      },
      series: [
        {
          name: "CPU",
          // type: "line",
          smooth: false,
          symbol: "none",
          areaStyle: {},
          data:
            data?.deploymentUpdated.CPUUtilization &&
            data.deploymentUpdated.CPUUtilization.length
              ? data.deploymentUpdated.CPUUtilization.map((d) => [
                  new Date(d.timestamp),
                  (d.value || 0).toFixed(3),
                ])
              : getEmptyMetricsArray(),
        },
      ],
      yAxis: [
        {
          type: "value",
          boundaryGap: [0, "33%"],
          axisLabel: {
            formatter: "{value}%",
          },
          name: "CPU",
        },
      ],
    };
  }, [data]);

  const DatabaseConnections: ECOption = useMemo(() => {
    return {
      ...baseOptions,
      title: {
        text: "Database Connections",
      },
      series: [
        {
          name: "Database Connections",
          // type: "line",
          smooth: false,
          symbol: "none",
          areaStyle: {},
          data:
            data?.deploymentUpdated.DatabaseConnections &&
            data.deploymentUpdated.DatabaseConnections.length
              ? data.deploymentUpdated.DatabaseConnections.map((d) => [
                  new Date(d.timestamp),
                  d.value || 0,
                ])
              : getEmptyMetricsArray(),
        },
      ],
      yAxis: [
        {
          type: "value",
          boundaryGap: [0, "33%"],
          axisLabel: {
            formatter: "{value}",
          },
          name: "Connections",
        },
      ],
    };
  }, [data]);

  const FreeStorageSpace: ECOption = useMemo(() => {
    return {
      ...baseOptions,
      title: {
        text: "Free Storage Space",
      },
      series: [
        {
          name: "Disk",
          // type: "line",
          smooth: false,
          symbol: "none",
          areaStyle: {},
          data:
            data?.deploymentUpdated.FreeStorageSpace &&
            data.deploymentUpdated.FreeStorageSpace.length
              ? data.deploymentUpdated.FreeStorageSpace.map((d) => [
                  new Date(d.timestamp),
                  ((d.value || 0) / 1024 / 1024 / 1024).toFixed(2),
                ])
              : getEmptyMetricsArray(),
        },
      ],
      yAxis: [
        {
          type: "value",
          boundaryGap: [0, "33%"],
          axisLabel: {
            formatter: "{value} GB",
          },
          name: "Disk",
        },
      ],
    };
  }, [data]);

  const FreeableMemory: ECOption = useMemo(() => {
    return {
      ...baseOptions,
      title: {
        text: "Free Memory",
      },
      series: [
        {
          name: "Free Memory",
          // type: "line",
          smooth: false,
          symbol: "none",
          areaStyle: {},
          data:
            data?.deploymentUpdated.FreeableMemory &&
            data.deploymentUpdated.FreeableMemory.length
              ? data.deploymentUpdated.FreeableMemory.map((d) => [
                  new Date(d.timestamp),
                  ((d.value || 0) / 1024 / 1024 / 1024).toFixed(2),
                ])
              : getEmptyMetricsArray(),
        },
      ],
      yAxis: [
        {
          type: "value",
          boundaryGap: [0, "33%"],
          axisLabel: {
            formatter: "{value} GB",
          },
          name: "Memory",
        },
      ],
    };
  }, [data]);

  const ReadLatency: ECOption = useMemo(() => {
    return {
      ...baseOptions,
      title: {
        text: "Read Latency",
      },
      series: [
        {
          name: "Read Latency",
          // type: "line",
          smooth: false,
          symbol: "none",
          areaStyle: {},
          data:
            data?.deploymentUpdated.ReadLatency &&
            data.deploymentUpdated.ReadLatency.length
              ? data.deploymentUpdated.ReadLatency.map((d) => [
                  new Date(d.timestamp),
                  ((d.value || 0) * 1000).toFixed(3),
                ])
              : getEmptyMetricsArray(),
        },
      ],
      yAxis: [
        {
          type: "value",
          boundaryGap: [0, "33%"],
          axisLabel: {
            formatter: "{value} ms",
          },
          name: "Milliseconds",
        },
      ],
    };
  }, [data]);

  const WriteLatency: ECOption = useMemo(() => {
    return {
      ...baseOptions,
      title: {
        text: "Write Latency",
      },
      series: [
        {
          name: "Write Latency",
          // type: "line",
          smooth: false,
          symbol: "none",
          areaStyle: {},
          data:
            data?.deploymentUpdated.WriteLatency &&
            data.deploymentUpdated.WriteLatency.length
              ? data.deploymentUpdated.WriteLatency.map((d) => [
                  new Date(d.timestamp),
                  ((d.value || 0) * 1000).toFixed(3),
                ])
              : getEmptyMetricsArray(),
        },
      ],
      yAxis: [
        {
          type: "value",
          boundaryGap: [0, "33%"],
          axisLabel: {
            formatter: "{value} ms",
          },
          name: "Milliseconds",
        },
      ],
    };
  }, [data]);

  if (!data) {
    return <MetricsSkeleton />;
  }

  return (
    <Card p={2} color="brandVar">
      <Flex width="100%" mb={2}>
        {data?.deploymentUpdated.awsLinks?.cloudwatchMetrics && (
          <Button
            size="sm"
            ml="auto"
            as={Link}
            _hover={{ textDecoration: "none" }}
            to={data?.deploymentUpdated.awsLinks?.cloudwatchMetrics}
            isAbsolute
            rightIcon={<ExternalLinkIcon />}
          >
            View in AWS CloudWatch
          </Button>
        )}
      </Flex>
      <AspectRatio minHeight="300px" width="100%" ratio={16 / 9}>
        <ZChart options={CPUUtilization} />
      </AspectRatio>
      <AspectRatio minHeight="300px" width="100%" ratio={16 / 9}>
        <ZChart options={DatabaseConnections} />
      </AspectRatio>
      <AspectRatio minHeight="300px" width="100%" ratio={16 / 9}>
        <ZChart options={FreeStorageSpace} />
      </AspectRatio>
      <AspectRatio minHeight="300px" width="100%" ratio={16 / 9}>
        <ZChart options={FreeableMemory} />
      </AspectRatio>
      <AspectRatio minHeight="300px" width="100%" ratio={16 / 9}>
        <ZChart options={ReadLatency} />
      </AspectRatio>
      <AspectRatio minHeight="300px" width="100%" ratio={16 / 9}>
        <ZChart options={WriteLatency} />
      </AspectRatio>
    </Card>
  );
};
