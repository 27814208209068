import { FormControl } from "@chakra-ui/react";
import { FormLabel, Input } from "@zeet/web-ui";

interface GitWorkingDirectoryProps {
  value?: string;
  onChange?: (value: string) => void;
}

export const GitWorkingDirectory = ({
  value,
  onChange,
}: GitWorkingDirectoryProps) => {
  return (
    <FormControl mt="4">
      <FormLabel>Working Directory</FormLabel>
      <Input
        placeholder="./"
        value={value}
        onChange={(e) => onChange?.(e.target.value)}
      />
    </FormControl>
  );
};
